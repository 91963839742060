import React, { useEffect, useState } from 'react'
import "react-modal-video/css/modal-video.css";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import travel from "../../../../models/travel";
import notistack from "../../../../plugin/notistack";
import useValidator from '../../../../plugin/validator';
import { country } from "../../../../models";

const defaultData = {
  type_of_traveler: '',
  title: '',
  country_departure: '',
  country_arrival: '',
  state_departure: '',
  state_arrival: '',
  city_departure: '',
  city_arrival: '',
  pickup_place: '',
  delivery_place: '',
  departure_date: '',
  arrival_date: '',
  departure_time: '',
  arrival_time: '',
  carriage_capacity: '',
  price_per_kg: '',
  trip_type: '',
  height: '',
  weight: '',
  length: '',
  transport_type: '',
  packing_instruction: '',
  description: '',
  type_of_travel:'2'
}
const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

export default function Travels() {
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState(defaultData);
  const [details, setDetails] = useState(format);
  const [validator, showMessage] = useValidator();

  const error = {
    email: validator.message(t('auth.email'), data.email, 'required|email|string|max:200'),
    password: validator.message(t('auth.password'), data.password, 'required|password|max:200'),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleSubmit = (event) => {
    travel.create(data).then((response) => {
      history.push(`/sellArticle/${response.id}/${data.type_of_travel}`);
      setData(defaultData);
      console.log(data);
      notistack.success(t('form.add_submited'));
      
    })
  }
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details)
        handleData("arriavalCountry", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  const getDepartureStateData = (id) => {
    country
      .state(id)
      .then((details) => {
        handleData("departureState", details)
      })
  };
  const getArrivalStateData = (id) => {
    country
      .state(id)
      .then((details) => {
        handleData("arriavalState", details)
      })
  };
  const getDepartureCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("departureCity", details)
      })
  };
  const getArrivalCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("arriavalCity", details)
      })
  };
  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}
      action="#"
      method="post">
      <div className="">
        <div className="contact-form-wrap">
          <h4>{t("form.travel_ads")}</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.type_of_traveler")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="type_of_traveler"
                  onChange={handleChange}
                  name='type_of_traveler'
                  value={data.type_of_traveler}
                >
                  <option value="" selected disabled>{t("form.type_of_traveler")}</option>
                  <option value={"1"}>Regular</option>
                  <option value={"2"}>{t("form.periodical")}</option>
                  <option value={"3"}>{t("form.one_off")}</option>
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.title")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.title")}
                  id="title"
                  onChange={handleChange}
                  name='title'
                  value={data.title}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.country_Of_departure")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="SelectElement"
                  onChange={(e) => {
                    handleChange(e, "country_of_Departure");
                    handleSetData("state_departure", "");
                    handleSetData("city_departure", "");
                    getDepartureStateData(e.target.value);
                  }}
                  name='country_departure'
                  value={data.country_departure}
                >
                  <option value="" selected disabled>{t("form.country_Of_departure")}</option>
                  {details.departureCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.country_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="country_arrival"
                  onChange={(e) => {
                    handleChange(e, "country_arrival");
                    handleSetData("state_arrival", "");
                    handleSetData("city_arrival", "");
                    getArrivalStateData(e.target.value);
                  }}
                  name='country_arrival'
                  value={data.country_arrival}
                >
                  <option value="" selected disabled>{t("form.country_of_arrival")}</option>
                  {details.arriavalCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.state_of_departure")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="state_departure"
                  onChange={(e) => {
                    handleChange(e, "state_of_Departure");
                    handleSetData("city_departure", "");
                    getDepartureCity(e.target.value);
                  }}
                  name='state_departure'
                  value={data.state_departure}
                >
                  <option value="" selected disabled>{t("form.state_of_departure")}</option>
                  {details.departureState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.state_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="state_arrival"
                  onChange={(e) => {
                    handleChange(e, "state_arrival");
                    handleSetData("city_arrival", "");
                    getArrivalCity(e.target.value);
                  }}
                  name='state_arrival'
                  value={data.state_arrival}
                >
                  <option value="" selected disabled>{t("form.state_of_arrival")}</option>
                  {details.arriavalState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.city_of_departure")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="city_departure"
                  onChange={(e) => {
                    handleChange(e, "city_of_Departure");
                  }}
                  name='city_departure'
                  value={data.city_departure}
                >
                  <option value="" selected disabled>{t("form.city_of_departure")}</option>
                  {details.departureCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.city_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  required
                  id="city_arrival"
                  onChange={(e) => {
                    handleChange(e, "city_arrival");
                  }}
                  name='city_arrival'
                  value={data.city_arrival}
                >
                  <option value="" selected disabled>{t("form.city_of_arrival")}</option>
                  {details.arriavalCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.pick_up_place")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.enter_pick_up_place")}
                  id="pickup_place"
                  onChange={handleChange}
                  name='pickup_place'
                  value={data.pickup_place}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.delivery_place")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.enter_delivery_place")}
                  id="delivery_place"
                  onChange={handleChange}
                  name='delivery_place'
                  value={data.delivery_place}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.departure_date")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="Date"
                  id="departure_date"
                  onChange={handleChange}
                  name='departure_date'
                  value={data.departure_date}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.arrival_date")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="Date"
                  id="arrival_date"
                  onChange={handleChange}
                  name='arrival_date'
                  value={data.arrival_date}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.type_of_transport")}<span style={{ color: 'red' }}>*</span></label>
                  <select
                    required
                    id="transport_type"
                    onChange={handleChange}
                    name='transport_type'
                    value={data.transport_type}>
                    <option value="" selected disabled>{t("form.type_of_transport")}</option>
                    <option>{t("form.plane")}</option>
                    <option>Bus</option>
                    <option>{t("form.metro")}</option>
                    <option>Motobike</option>
                    <option>Scooter</option>
                    <option>TGV</option>
                    <option>Tramway</option>
                    <option>{t("form.car")}</option>
                    <option>{t("form.kick_scooter")}</option>
                    <option>{t("form.boat")}</option>
                    <option>{t("form.bike")}</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.departure_time")}<span style={{ color: 'red' }}>*</span></label>
                  <input
                    required
                    type="time"
                    id="departure_time"
                    onChange={handleChange}
                    name='departure_time'
                    value={data.departure_time}
                  />
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group ">
                  <label htmlFor="name">{t("form.arrival_time")}<span style={{ color: 'red' }}>*</span></label>
                  <input
                    required
                    type="time"
                    id="arrival_time"
                    onChange={handleChange}
                    name='arrival_time'
                    value={data.arrival_time}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.carriage_capacity")}<span style={{ color: 'red' }}>*</span></label>
                  <input
                    required
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder=""
                    id="carriage_capacity"
                    onChange={handleChange}
                    name='carriage_capacity'
                    value={data.carriage_capacity}
                  />
                  <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>kg</span>
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.price_per_kg")}<span style={{ color: 'red' }}>*</span></label>
                  <input
                    required
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder=""
                    id="price_per_kg"
                    onChange={handleChange}
                    name='price_per_kg'
                    value={data.price_per_kg}
                  />
                  <span style={{ position: 'absolute', right: 20, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>€</span>
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.trip_type")}</label>
                  <select
                    id="trip_type"
                    onChange={handleChange}
                    name='trip_type'
                    value={data.trip_type}>
                    <option value="" selected disabled>{t("form.select_trip_type")}</option>
                    <option>{t("form.single_trip")}</option>
                    <option>{t("form.round_trip")}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }} >
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.height")}</label>
                  <input
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder=""
                    id="height"
                    onChange={handleChange}
                    name='height'
                    value={data.height}
                  />
                  <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span>
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.weight")}</label>
                  <input
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder=""
                    id="weight"
                    onChange={handleChange}
                    name='weight'
                    value={data.weight}
                  />
                  <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>kg</span>
                </div>
              </div>
              <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.length")}</label>
                  <input
                    type="number"
                    onWheel={numberInputOnWheelPreventChange}
                    placeholder=""
                    id="length"
                    onChange={handleChange}
                    name='length'
                    value={data.length}
                  />
                  <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className=" col-12 col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.packaging_instruction")}</label>
                <textarea rows={4}
                  id="packing_instruction"
                  onChange={handleChange}
                  name='packing_instruction'
                  value={data.packing_instruction}>
                </textarea>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">Description</label>
                <textarea rows={4}
                  id="description"
                  onChange={handleChange}
                  name='description'
                  value={data.description}>
                </textarea>
              </div>
            </div>
          </div>
          <div className="custom-input-group row col-lg-12">
            <div className=" col-sm-6 col-md-6 col-lg-">
              <div className="submite-btn" >
                {/* <Link to={`${process.env.PUBLIC_URL}/sellArticle`}>   */}
                <button type="submit" onClick={handleSubmit} style={{ fontSize: '14px' }}>{t("form.submit")} Cobagage</button>
                {/* </Link> */}
                <Link to={`/`}> <button type="submit" style={{ fontSize: '14px', marginLeft: '2px' }}>{t("form.cancle")}</button></Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
