import { Link } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";

export default function BradcomeContact(props) {
    const { t } = useTranslation();
    return (
        <div className="breadcrumb breadcrumb-style-sixteen">
            <div className="container">
                <div className="col-lg-12 text-center">
                    <h1 className="breadcrumb-title" style={{ color: "white" }}>{props.name}</h1>
                    <ul className="d-flex justify-content-center breadcrumb-items">
                        <li className="breadcrumb-item">
                            <Link to={`${process.env.PUBLIC_URL}/`}> {t("menu.home")}</Link>
                        </li>
                        <li className="breadcrumb-item active" style={{ color: "white" }}>{props.name}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
