import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from "react-bootstrap";
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import request from "../../../../../models/request";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";
import luggage from "../../../../../models/luggage";

function LuggageRequestWrapperArea() {
  const { id, product_id } = useParams();
  const { type } = useParams();
  const { request_type } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  const [validator, showMessage] = useValidator();
  const [CancleValidator, showCancleMessage] = useValidator();
  const [isShow, setIsShow] = useState();
  const [data, setData] = useState({
    request_id: id,
    request_type: type,
    status: "",
    reason_to_reject_luggage: "",
    reason_to_cancel: "",
    cancel_by: "2",
  });
  const error = {
    reason_to_reject_luggage: validator.message(
      "Reason to Reject",
      data.reason_to_reject_luggage,
      (isShow == 1 ? "required|" : "") + "string|max:200"
    ),
  };
  const cancleError = {
    reason_to_cancel: CancleValidator.message(
      "Reason to Reject",
      data.reason_to_cancel,
      (isShow == 4 ? "required|" : "") + "string|max:200"
    ),
  };
  const handleClose = () => {
    if (isShow == 3) {
      getData();
    }
    setIsShow(1);
    showMessage(false);
    showCancleMessage(false);
    handleSetData("reason_to_cancel", "");
    handleSetData("reason_to_reject_luggage", "");
    setShow(false);
  };
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    if (isShow === 2) {
      requestReceived(data);
    }
  }, [isShow]);

  const getData = () => {
    if (request_type == 1) {
      request
        .getRequestReceived(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      request
        .get(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch((error) => {
          console.log("error", error);
        });
      luggage
        .get(product_id)
        .then((item) => {
          setLuggageData(item);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  const requestReceived = (data) => {
    if (validator.allValid()) {
      request
        .respondToRequestReceived(data)
        .then((item) => {
          if (isShow == 1) {
            setIsShow(3);
          } else {
            getData();
          }
        })
        .catch((error) => {
          console.log("error", error);
          if (error.data.message) {
            notistack.error(error.data.message);
            setIsShow(1);
          }
        });
    } else {
      showMessage(true);
    }
  };
  const cancleOrder = () => {
    if (CancleValidator.allValid()) {
      request
        .cancle(data)
        .then((item) => {
          setIsShow(5);
          setLuggageRequest(item);
        })
        .catch((error) => {
          console.log("error", error);
          notistack.error(error?.message);
        });
    } else {
      showCancleMessage(true);
    }
  };
  const tabPanelValue = () => {
    contextObj.setTabValue(5);
    history.push("/myProfile");
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const acceptRequest = () => {
    handleSetData("status", 1);
    setIsShow(2);
  };
  return (
    <div className="container">
      <div class="p-4 px-lg-0">
        <div className="row pb-110">
          <div className="col-lg-8">
            <Button
              style={{
                backgroundColor: "#ff4838",
                borderColor: "#ff4838",
                borderRadius: "5px",
                margin: "2px",
              }}
              onClick={() => tabPanelValue()}
            >
              <i class="bi bi-arrow-left-circle" />{" "}
              {t("profile.back_to_travel_requests")}
            </Button>
            <div className="row">
              <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                <div class="earning pt-3">
                  <div class="plan" style={{ background: "white" }}>
                    <table class="table">
                      <tbody class="border-0">
                        <tr>
                          <th className="border-0 d-flex align-items-center ps-1 gap-1">
                            <img
                              src={LuggageRequest?.departure_flag}
                              style={{ objectFit: "contain", width: "30px", height: "17px", }}
                            ></img>
                            {LuggageRequest?.city_departure_name}
                          </th>
                          <th class="border-0">
                            <i class="bi bi-arrow-right" />
                          </th>
                          <th className="border-0 d-flex align-items-center  ps-1 gap-1">
                            <img
                              src={LuggageRequest?.arrival_flag}
                              style={{ objectFit: "contain", width: "30px", height: "17px", }}
                            ></img>
                            {LuggageRequest?.city_arrival_name}
                          </th>
                        </tr>
                        <tr class="border-0">
                          <td class="border-0 fw-normal">
                            <i
                              class="bi bi-calendar"
                              style={{ color: "#f39c12" }}
                            />{" "}
                            {LuggageRequest?.departure_date} |{" "}
                            {LuggageRequest?.departure_time}
                          </td>
                          <td class="border-0"></td>
                          <td class="border-0 fw-normal">
                            <i
                              class="bi bi-calendar"
                              style={{ color: "#f39c12" }}
                            />{" "}
                            {LuggageRequest?.arrival_date} |{" "}
                            {LuggageRequest?.arrival_time}
                          </td>
                        </tr>
                        <tr class="border-0">
                          <td class="border-0 fw-normal">
                            {" "}
                            {t("profile.departure")}
                          </td>
                          <td class="border-0"></td>
                          <td class="border-0 fw-normal">
                            {" "}
                            {t("profile.arrival")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.luggage_title")}:
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.title
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.type_of_luggage")}:
                        </td>
                        <td class="border-0 fw-bold">
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.type == 1
                            ? t("form.New_Never_Used")
                            : t("form.Used_Secound_hand")}
                        </td>
                      </tr>
                    </tbody>
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 text-decoration-underline">
                          {t("profile.luggage_dimensions")}:
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.height")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.height
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.height
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.length")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.length
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.length
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Width")}</td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.width
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.width
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.weight")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.weight
                          }{" "}
                          KG
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Colour")}</td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.colour
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.colour
                            ? (request_type == 1 ? LuggageRequest : luggageData)
                              ?.colour
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Volume")}</td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.volume
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.volume
                            ? "cubic inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.current_price_estimate")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.luggage_estimation
                          }{" "}
                          {LuggageRequest?.currency}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 text-decoration-underline">
                          {t("profile.luggage_receivers_details")}:
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.name_of_receiver")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_name
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.phone")}/Contact #
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_phone_code
                          }
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_contact_number
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.your_relationship_with_receiver")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_relation
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.luggage_location")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.location
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <h4 class="fs-5 pb-4">{t("profile.luggage_images")} :</h4>
              {(request_type == 1
                ? LuggageRequest?.image[0]
                : luggageData?.image[0]) && (
                  <div class="col-lg-3 col-md-6">
                    <h6>{t("profile.Exterior_1")}</h6>
                    <img
                      src={
                        request_type == 1
                          ? LuggageRequest?.image[0]
                          : luggageData?.image[0]
                      }
                      style={{
                        width: "98%",
                        aspectRatio: "3/2",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              {(request_type == 1
                ? LuggageRequest?.image[1]
                : luggageData?.image[1]) && (
                  <div class="col-lg-3 col-md-6">
                    <h6>{t("profile.Exterior_2")}</h6>
                    <img
                      src={
                        request_type == 1
                          ? LuggageRequest?.image[1]
                          : luggageData?.image[1]
                      }
                      style={{
                        width: "98%",
                        aspectRatio: "3/2",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              {(request_type == 1
                ? LuggageRequest?.image[2]
                : luggageData?.image[2]) && (
                  <div class="col-lg-3 col-md-6">
                    <h6>{t("profile.Interior_1")}</h6>
                    <img
                      src={
                        request_type == 1
                          ? LuggageRequest?.image[2]
                          : luggageData?.image[2]
                      }
                      style={{
                        width: "98%",
                        aspectRatio: "3/2",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
              {(request_type == 1
                ? LuggageRequest?.image[3]
                : luggageData?.image[3]) && (
                  <div class="col-lg-3 col-md-6">
                    <h6>{t("profile.Interior_2")}</h6>
                    <img
                      src={
                        request_type == 1
                          ? LuggageRequest?.image[3]
                          : luggageData?.image[3]
                      }
                      style={{
                        width: "98%",
                        aspectRatio: "3/2",
                        objectFit: "contain",
                      }}
                    />
                  </div>
                )}
            </div>
          </div>
          {/* <div className="col-" /> */}
          <div className="col-lg-4">
            <div className="custom-input-group row">
              <div className="col-sm-6 col-md-6 col-lg-12">
                {request_type != 2 && LuggageRequest?.status == 0 && (
                  <div className="submite-btn d-lg-flex d-sm-none d-md-flex gap-lg-2 pb-lg-5 pb-md-1">
                    <Button
                      onClick={() => {
                        handleSetData("status", 2);
                        setIsShow(1);
                        handleShow();
                      }}
                      style={{
                        // backgroundColor: "#ff4838",
                        // borderColor: "#ff4838",
                        borderRadius: "5px",
                        margin: "2px",
                      }}
                    >
                      {t("profile.reject_request")}
                    </Button>
                    <Button
                      onClick={() => {
                        setIsShow(6);
                        handleShow();
                      }}
                      style={{
                        backgroundColor: "#63a103",
                        borderColor: "#63a103",
                        borderRadius: "5px",
                        margin: "2px",
                      }}
                    >
                      {t("profile.accept_request")}
                    </Button>
                  </div>
                )}
                <Modal show={show} onHide={handleClose} size="lg" centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {isShow == 1
                        ? t("profile.reject_confirmation")
                        : isShow == 6
                          ? t("profile.acceptance_request")
                          : isShow == 4
                            ? t("profile.cancel_order_confirmation")
                            : isShow == 2 ?
                              t("profile.success") : t("profile.success")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <div class="container-fluid">
                        <div class="row d-flex justify-content-center align-items-center pb-4 pt-1">
                          {isShow == 1 ? (
                            <div class=" col mb-lg-0">
                              <h5 className="text-center fw-none">
                                {t(
                                  "profile.your_are_about_to_reject_the_request"
                                )}
                              </h5>
                              <h5 className="text-center fw-none">
                                {t(
                                  "profile.please_select_a_reason_for_rejection_and_confirm_your_action"
                                )}
                              </h5>
                              <div class="pt-2" style={{ borderRadius: "8px" }}>
                                <div class=" justify-content-center d-lg-flex d-md-flex">
                                  <div class="">
                                    <select
                                      name="reason_to_reject_luggage"
                                      onChange={handleChange}
                                      style={{ borderColor: "#ff4838" }}
                                    >
                                      <option value={"0"} disabled selected>
                                        -{t("profile.select_reasone_to_reject")}
                                        -
                                      </option>
                                      <option value={"1"}>
                                        {t("profile.overWeight")}
                                      </option>
                                      <option value={"2"}>
                                        {t("profile.i_changed_my_travel_plan")}
                                      </option>
                                      <option value={"3"}>
                                        {t("profile.improper")}
                                      </option>
                                      <option value={"4"}>
                                        {t(
                                          "profile.luggage_not_taken_into_account"
                                        )}
                                      </option>
                                    </select>
                                    <span style={{ color: "red" }}>
                                      {error.reason_to_reject_luggage}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : isShow == 6 ? (
                            <div class=" col mb-lg-0">

                              {LuggageRequest?.remaining_capacity >= LuggageRequest?.weight ? <div>
                                <h5 className="text-center fw-none">
                                  {t("profile.travel_confirm_text")}
                                </h5>
                                <div
                                  class="pt-2 mt-3"
                                  style={{ borderRadius: "8px" }}
                                >
                                  <div class=" justify-content-center d-lg-flex d-md-flex">
                                    <h2>
                                      {LuggageRequest?.remaining_capacity - LuggageRequest?.weight} kg{" "}
                                      {t("profile.out_of")}{" "}
                                      {LuggageRequest?.carriage_capacity} kg{" "}
                                      {t("profile.remaining")}
                                    </h2>
                                  </div>
                                </div>
                              </div> : <div>
                                <h5 className="text-center fw-none">
                                  {t("profile.Your_transport_capacity_weight")}
                                </h5>
                                <h2 className="text-center mt-3">
                                  {LuggageRequest?.remaining_capacity} kg{" "}
                                  {t("profile.out_of")}{" "}
                                  {LuggageRequest?.carriage_capacity} kg{" "}
                                  {t("profile.remaining")}
                                </h2>
                              </div>}
                            </div>
                          ) : isShow == 4 ? (
                            <div class="col mb-lg-0">
                              <h5 className="text-center fw-none pb-3">
                                {t(
                                  "profile.your_are_about_to_cancel_the_luggage_sending_request"
                                )}
                              </h5>
                              <div class=" justify-content-center d-lg-flex d-md-flex">
                                <div class="">
                                  <select
                                    style={{ border: "1px solid #ff4838" }}
                                    onChange={handleChange}
                                    name="reason_to_cancel"
                                  >
                                    <option selected disabled>
                                      - {t("profile.select_reasone_to_reject")}{" "}
                                      -
                                    </option>
                                    <option value={5}>
                                      {t("profile.price_too_high")}
                                    </option>
                                    <option value={6}>
                                      {t("profile.I_changed_my_mind")}
                                    </option>
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {cancleError.reason_to_cancel}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : isShow == 5 ? (
                            <div class="col mb-lg-0">
                              <h5 className="text-center fw-none pb-3">
                                {t(
                                  "profile.the_traveller_successfully_notified_cancelation"
                                )}
                              </h5>
                            </div>
                          ) : isShow == 2 ? (
                            <div class="col mb-lg-0">
                              <h5 className="text-center fw-none pb-3">
                                {t("profile.tavelText1")}
                              </h5>
                              {/* <h5 className="text-center fw-none pb-3 mt-2">
                                {t("profile.tavelText2")}
                              </h5> */}
                              {/* <h5 className="text-center fw-none pb-3 mt-2">
                                {t("profile.tavelText3")}
                              </h5> */}
                              <h5 className="text-center fw-none pb-3 mt-3" style={{ textDecoration: 'underline' }}>
                                {t("profile.tavelText4")}
                              </h5>
                              <h5 className="text-center fw-none pb-3 mt-3">
                                {t("profile.tavelText5")}
                              </h5>
                              <h5 className="text-center fw-none pb-3 mt-2">
                                {t("profile.tavelText6")}
                              </h5>
                              <h5 className="text-center fw-none pb-3 mt-2">
                                {t("profile.tavelText7")}
                              </h5>
                              <h5 className="text-center fw-none pb-3 mt-3">
                                {t("profile.tavelText8")}
                              </h5>
                            </div>
                          ) : (
                            <div class=" col mb-lg-0">
                              <h5 className="text-center fw-none">
                                {t("profile.about_your_rejection_with_reason")}
                              </h5>
                              <br />
                              <h5 className="text-center fw-none">
                                {t("profile.happy_travelling_while_earning")}
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center">
                    <Button
                      onClick={() =>
                        isShow == 1
                          ? requestReceived(data)
                          : isShow == 6
                            ? LuggageRequest?.remaining_capacity >= LuggageRequest?.weight ? acceptRequest() : handleClose()
                            : isShow == 4
                              ? cancleOrder()
                              : handleClose()
                      }
                      style={{
                        // backgroundColor: "#ff4838",
                        borderColor: "#ff4838",
                      }}
                    >
                      {isShow == 1
                        ? t("profile.reject_request")
                        : isShow == 6
                          ? LuggageRequest?.remaining_capacity >= LuggageRequest?.weight ? t("profile.i_accept_confirm") : t("close")
                          : isShow == 4
                            ? t("profile.cancle_order")
                            : t("close")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="row">
              {request_type != 1 ? (
                <div class="col-lg-12 col-md-6 col-12  pt-2">
                  <div
                    class="earning "
                    style={{
                      backgroundColor: "white",
                      borderColor: "#ff4838",
                      border: "2px solid",
                    }}
                  >
                    <div class="plan" style={{ background: "white" }}>
                      <header style={{ padding: "11px 10px" }}>
                        <i class="bi bi-currency-dollar1"></i>
                        <h6 class="plan-title" style={{ color: "black" }}>
                          {t("profile.status")}:
                        </h6>
                        <div class="plan-cost">
                          <span
                            class="plan-price fs-6 d-flex align-items-center "
                            style={{
                              color:
                                LuggageRequest?.status == 1
                                  ? "#63a103"
                                  : LuggageRequest?.status == 2
                                    ? "red"
                                    : "#f39c12",
                            }}
                          >
                            {" "}
                            {LuggageRequest?.status !== 0 && (
                              <i
                                class={
                                  LuggageRequest?.status == 1
                                    ? "bi bi-check-circle"
                                    : LuggageRequest?.status == 2
                                      ? "bi bi-x-circle "
                                      : ""
                                }
                                style={{
                                  color:
                                    LuggageRequest?.status == 1
                                      ? " #63a103"
                                      : "red",
                                  fontSize: "30px",
                                }}
                              />
                            )}
                            {LuggageRequest?.status == 1
                              ? t("profile.accepted")
                              : LuggageRequest?.status == 2
                                ? t("profile.rejected")
                                : LuggageRequest?.status == 0
                                  ? t("profile.Pending_acceptance")
                                  : ""}
                          </span>
                        </div>
                      </header>
                    </div>
                    {LuggageRequest?.reason_of_cancel == 0 && (
                      <div className="submite-btn">
                        {request_type == 2 &&
                          (LuggageRequest?.status == 0 ||
                            LuggageRequest?.status == 1) && (
                            <Button
                              onClick={() => {
                                setIsShow(4);
                                handleShow();
                              }}
                              style={{
                                backgroundColor: "#ff4838",
                                borderColor: "#ff4838",
                                borderRadius: "5px",
                                margin: "10px",
                              }}
                            >
                              {t("profile.cancle_order")}
                            </Button>
                          )}
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <div class="col-lg-12 col-md-6 col-12 pt-2">
                  <div class="earning">
                    <div class="plan" style={{ background: "white" }}>
                      <header>
                        <i class="bi bi-currency-dollar" />
                        <h6 class="plan-title" style={{ color: "black" }}>
                          {t("profile.earning_oppurtunity")}
                        </h6>
                        <div class="plan-cost">
                          <span class="plan-price">
                            {LuggageRequest?.fees} {LuggageRequest?.currency}
                          </span>
                          <span class="plan-type"></span>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="row pt-5">
              <p class="fs-5 pb-3 text-decoration-underline">
                {request_type == 1 ? `${t("profile.request_received_from")}:` : `${t("profile.request_sent_to")}:`}
              </p>
              <h6 class="pb-1">
                <i class="bi bi-person-fill"></i>{" "}
                <span style={{ color: "#1e98d7" }}>
                  {request_type == 1
                    ? LuggageRequest?.request_user_name
                    : LuggageRequest?.luggager_name}
                </span>
              </h6>
              <p style={{ color: 'black' }}>
                <i class="bi bi-person-fill" style={{ visibility: 'hidden' }}></i>{" "}
                <span style={{ color: "black", fontSize: '14px' }}>{t('form.Member_since')}:{" "}
                  {request_type == 1
                    ? LuggageRequest?.member_since_sender?.slice(0, 10)
                    : LuggageRequest?.member_since_receiver?.slice(0, 10)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LuggageRequestWrapperArea;
