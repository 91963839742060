import React from 'react'
import BradcomeShipment from '../../common/BradcomeShipment'
import InternationalWrapp from './InternationalWrapp'
import { useTranslation } from "react-i18next";

export default function International() {
    const { t } = useTranslation();
    return (
        <div>
            <BradcomeShipment
                name={t("homePage.international_shipment")}
            />
            <InternationalWrapp/>
        </div>
    )
}
