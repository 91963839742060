import axios from '../plugin/axios';

const luggage = {
    list(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Luggage/AvailableLuggage', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    allListCount(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get(`Rating/GetCounts/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    allData() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Luggage/GetByUser')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Luggage/Post', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Luggage/GetById/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    StatusReject(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Luggage/GetStatus/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Luggage/Put', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Luggage/Delete', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Luggage/ValidatePost', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default luggage;
