const cobagage = {
    luggage_ads:"Luggage Ads",
    cobagage_ads:"Cobagage Ads",
    recent_listing: "Recent Listings",
    default_sorting: "Default Sorting",
    newest_first:"Newest First",
    oldest_first:"Oldest First",
    lowest_price_first:"Lowest Price First",
    highest_price_first:"Highest Price First",
    select_arrival_country:"Select arrival country",
    unverified:"Unverified",
    verified:"Verified",
    per:'per',
    from:"FROM",
    to:"TO",
    carriage_capacity:"CARRIAGE CAPACITY",
    departure_date:"DEPARTURE DATE",
    arrival_date:"ARRIVAL DATE",
    article_for_sell:"ARTICLE FOR SELL",
    article_donation:"ARTICLE DONATION",
    type_of_traveler:"TYPE OF TRAVELER",
    member_since:"MEMBER SINCE",
    trips_made:"Trip(S) Made",
    luggages_sent:"LUGGAGE(S) SENT",
    user_rating:"USER RATING",
    detail:"DETAIL",
    payment:"Payment is done"


}
export default cobagage;