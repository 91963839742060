import React from 'react'
import { useTranslation } from 'react-i18next'

export default function EcoresponsiveWrap() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='pt-110  pb-110'>
                <h1 className='text-center ' style={{ fontWeight: 'bold' }}>{t('form.Éco_responsable')}</h1>
                <p className='pt-4 fs-5' style={{ fontWeight: '500' }}>
                    {t('form.En_cobagageur')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.La_tend')} <strong> {t('form.réduire_C02')}</strong> {t('form.par_cobagage')}.
                </p>
                <h2 className='text-center mt-5' style={{ fontWeight: 'bold' }}>{t('form.Pourquoi_émissions')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.L_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.L_émissions')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.A_constater')}<strong> {t('form.un_environnement')}</strong> ?
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.La_destination')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_avantages')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Que_agnante')}
                </p>
                <h2 className='mt-5 text-center' style={{ fontWeight: 'bold' }}>{t('form.Privilégier_propres')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Pour_recommander')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Par_permet')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cependant_locomotion')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Prenons_bagage')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Au_responsable')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Sur_temps')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Néanmoins_bagage')}
                </p>
                <p className='fs-5 pt-2'>
                    <strong> {t('form.La_écoresponsable')}</strong> {t('form.afin_C02')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Une_sain')}
                </p>
                <h2 className='mt-5 text-center' style={{ fontWeight: 'bold' }}>"{t('form.Devenir_service')}"</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Plus_quotidienne')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_réels')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_simplicité')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_envoi')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_services')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Devenez_cobagageurs')} <strong>{t('form.la_environnement')}</strong>.
                </p>
            </div>
        </div>
    )
}
