import axios from '../plugin/axios';

const category = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Category/GetAll')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default category;
