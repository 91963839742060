import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import DatePicker from "react-datepicker";
import { country } from "../../../../models";
import travel from "../../../../models/travel";
import luggage from "../../../../models/luggage";
import AppContext from "../../../../store/context/app-context";
const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_stopover: {},
  state_of_stopover: {},
  city_of_stopover: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  stopoverCountry: [],
  stopoverState: [],
  stopoverCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

export default function MyTravelEditPopup({ props, show, close }) {
  const { t, i18n } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState(props.item);
  const [filed, setFiled] = useState(0);
  const [details, setDetails] = useState(format);
  const [isShow, setIsShow] = useState(true);
  const [isRadio, setIsRadio] = useState(true);
  const [open, setOpen] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);
  const contextObj = useContext(AppContext);
  useEffect(() => {
    // setData(props.item);
    getData();
    getCurrency();
    handleSetData("insurance", true);
    handleSetData("stopover_in_trip", false);
    getDepartureStateData(props?.item?.country_departure);
    getDepartureCity(props?.item?.state_departure);
    getArrivalStateData(props?.item?.country_arrival);
    getArrivalCity(props?.item?.state_arrival);
    getStopoverStateData(props?.item?.country_stopover);
    getStopoverCity(props?.item?.state_stopover);
  }, []);
  const error = {
    title: validator.message(t("title"), data?.title, "required|max:200"),
    country_departure: validator.message(
      t("country_departure"),
      data?.country_departure,
      "required"
    ),
    trip_type: validator.message(t("trip_type"), data?.trip_type, "required"),
    country_arrival: validator.message(
      t("country_arrival"),
      data?.country_arrival,
      "required"
    ),
    state_departure: validator.message(
      t("state_departure"),
      data?.state_departure,
      "required"
    ),
    state_arrival: validator.message(
      t("state_arrival"),
      data?.state_arrival,
      "required"
    ),
    city_departure: validator.message(
      t("city_departure"),
      data?.city_departure,
      "required"
    ),
    city_arrival: validator.message(
      t("city_arrival"),
      data?.city_arrival,
      "required"
    ),
    pickup_place: validator.message(
      t("pickup_place"),
      data?.pickup_place,
      "required"
    ),
    delivery_place: validator.message(
      t("delivery_place"),
      data?.delivery_place,
      "required"
    ),
    departure_date: validator.message(
      t("departure_date"),
      data?.departure_date,
      "required"
    ),
    arrival_date: validator.message(
      t("arrival_date "),
      data?.arrival_date,
      "required"
    ),
    departure_time: validator.message(
      t("departure_time "),
      data?.departure_time,
      "required"
    ),
    arrival_time: validator.message(
      t("arrival_time "),
      data?.arrival_time,
      "required"
    ),
    carriage_capacity: validator.message(
      t("carriage_capacity "),
      data?.carriage_capacity,
      "required"
    ),
    price_per_kg: validator.message(
      t("price_per_kg"),
      data?.price_per_kg,
      "required"
    ),
    vehicle_type: validator.message(
      t("vehicle_type"),
      data?.vehicle_type,
      "required"
    ),
    stopover_in_trip: validator.message(
      t("stopover_in_trip"),
      data?.stopover_in_trip,
      "required"
    ),
    traveller_type: validator.message(
      t("traveller_type"),
      data?.traveller_type,
      "required"
    ),
  };

  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getStopoverCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("stopoverCity", details);
      });
  };

  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
        handleData("arriavalCountry", details);
        handleData("stopoverCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getDepartureStateData = (id) => {
    if (id)
      country.state(id).then((item) => {
        handleData("departureState", item);
      });
  };

  const getArrivalStateData = (id) => {
    if (id)
      country.state(id).then((details) => {
        handleData("arriavalState", details);
      });
  };

  const getDepartureCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("departureCity", details);
      });
  };

  const getArrivalCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("arriavalCity", details);
      });
  };

  const getStopoverStateData = (id) => {
    if (id)
      country.state(id).then((details) => {
        handleData("stopoverState", details);
      });
  };

  const returnError = {
    return_departure_date: validator.message(
      t("return_departure_date"),
      data?.return_departure_date,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_arrival_date: validator.message(
      t("return_arrival_date"),
      data?.return_arrival_date,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_departure_time: validator.message(
      t("return_departure_time"),
      data?.return_departure_time,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_arrival_time: validator.message(
      t("return_arrival_time"),
      data?.return_arrival_time,
      data?.trip_type == 2 ? "required" : ""
    ),
  };

  const showError = () => {
    if (validator.allValid()) {
      showMessage(false);
    } else {
      showMessage(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    if (props.type == 4) {
      if (validator.allValid()) {
        // setLoad(true);
        travel
          .update(props?.item?.id, data)
          .then((response) => {
            notistack.success(t("form.updated_Successfully"));
            setData(response);
            props.getData();
            handleClose();
          })
          .catch((error) => {
            console.log("error", error);
            // setLoad(false);
            notistack.error(error?.data?.message);
          });
      } else {
        showMessage(true);
      }
    } else {
      luggage
        .update(props?.item?.id, data)
        .then((response) => {
          notistack.success(t("form.updated_Successfully"));
          setData(response);
          props.getData();
          handleClose();
        })
        .catch((error) => {
          console.log("error", error);
          // setLoad(false);
          notistack.error(error?.data?.message);
        });
    }
  };

  const handleClose = () => {
    close();
    // setData(props.item);
  };

  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
  };

  const getCurrency = () => {
    country.currency().then((respons) => {
      setCurrencyData(respons);
    })
  }
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("form.Travel_Announcement")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      onInput={onInputUppercase}
                      value={data?.title}
                    />
                    <span style={{ color: "red" }}>{error?.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.trip_type")}</label>
                    <select
                      id="trip_type"
                      onChange={(e) => {
                        handleChange(e);
                        handleSetData("vehicle_type", 0);
                      }}
                      name="trip_type"
                      value={data?.trip_type}
                    >
                      <option value="" selected disabled>
                        {t("form.select_trip_type")}
                      </option>
                      <option value={1}>{t("form.one_way")}</option>
                      <option value={2}>{t("form.round_trip")}</option>
                    </select>

                    <span
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom: error.trip_type ? "37px" : "15px",
                      }}
                    >
                      <i class="bi bi-caret-down-fill"></i>
                    </span>
                    <span style={{ color: "red" }}>{error.trip_type}</span>
                  </div>
                </div>
              </div>
              {data?.trip_type == 2 ? (
                <div className="row d-flex justify-content-center align-items-center pt-50">
                  <div className="package-details-wrapper">
                    <div className="tour-package-details">
                      <div className="package-details-tabs">
                        <ul
                          className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                          id="pills-tab"
                          role="tablist"
                          style={{ marginBottom: "0px" }}
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              id="pills-package1"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body1"
                              type="button"
                              role="tab"
                              aria-controls="pill-body1"
                              aria-selected="true"
                              style={{
                                fontSize: "16px",
                                borderRadius: "0px",
                              }}
                              onClick={() => {
                                setFiled(0);
                              }}
                              className={
                                filed == 1 ? "nav-link" : "nav-link active"
                              }
                            >
                              {t("form.Onward_Journey_Details")}
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              id="pills-package2"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body2"
                              type="button"
                              role="tab"
                              aria-controls="pill-body2"
                              aria-selected="false"
                              style={{
                                fontSize: "16px",
                                borderRadius: "0px",
                              }}
                              onClick={() => {
                                setFiled(1);
                              }}
                              className={
                                filed == 1 ? "nav-link active" : "nav-link"
                              }
                            >
                              {t("form.Return_Journey_Details")}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_Of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_departure"
                      onChange={(e) => {
                        handleChange(e, "country_of_Departure");
                        handleSetData(filed===1?"state_arrival":"state_departure", "");
                        handleSetData(filed===1?"city_arrival":"city_departure", "");
                        filed===1?getArrivalStateData(e.target.value): getDepartureStateData(e.target.value);
                      
                      }}
                      name={filed===1?"country_arrival":"country_departure"}
                      value={filed===1?data?.country_arrival:data?.country_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.country_Of_departure")}
                      </option>
                      {
                        filed===1?(details?.arriavalCountry?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.departureCountry?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span
                      style={{
                        position: "absolute",
                        right: 0,
                        bottom: error.trip_type ? "37px" : "15px",
                      }}
                    >
                      <i class="bi bi-caret-down-fill"></i>
                    </span>
                    <span style={{ color: "red" }}>
                      {error.country_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_arrival"
                      onChange={(e) => {
                        handleChange(e, "country_arrival");
                        handleSetData(filed===1?"state_departure":"state_arrival", "");
                        handleSetData(filed===1?"city_departure":"city_arrival", "");
                        filed===1?getDepartureStateData(e.target.value): getArrivalStateData(e.target.value);
                      }}
                      name={filed===1?"country_departure":"country_arrival"}
                      value={filed===1?data?.country_departure:data?.country_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.country_of_arrival")}
                      </option>
                      
                      {
                        filed===1?(details?.departureCountry?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.arriavalCountry?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>
                      {error.country_arrival}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.state_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_departure"
                      onChange={(e) => {
                        handleChange(e, "state_of_Departure");
                        handleSetData(filed===1?"city_arrival":"city_departure", "");
                       filed===1?getArrivalCity(e.target.value): getDepartureCity(e.target.value);
                      }}
                      name={filed===1?"state_arrival":"state_departure"}
                      value={filed===1?data?.state_arrival:data?.state_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_departure")}
                      </option>
                       
                      {
                        filed===1?(details?.arriavalState?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.departureState?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>
                      {error.state_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.state_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_arrival"
                      onChange={(e) => {
                        handleChange(e, "state_arrival");
                        handleSetData(filed===1?"city_departure":"city_arrival", "");
                       filed===1?getDepartureCity(e.target.value): getArrivalCity(e.target.value);
                      }}
                      name={filed===1?"state_departure":"state_arrival"}
                      value={filed===1?data?.state_departure:data?.state_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_arrival")}
                      </option>
                  
                      {
                        filed===1?(details?.departureState?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.arriavalState?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.state_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.city_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_departure"
                      onChange={(e) => {
                        handleChange(e, "city_of_Departure");
                      }}
                      name={filed===1?"city_arrival":"city_departure"}
                      value={filed===1?data?.city_arrival:data?.city_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_departure")}
                      </option>
                      {
                        filed===1?(details?.arriavalCity?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.departureCity?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.city_departure}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.city_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_arrival"
                      onChange={(e) => {
                        handleChange(e, "city_arrival");
                      }}
                      name={filed===1?"city_departure":"city_arrival"}
                      value={filed===1?data?.city_departure:data?.city_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_arrival")}
                      </option>
                      
                      {
                        filed===1?(details?.departureCity?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        ))):(details?.arriavalCity?.map((item) => (
                          <option value={item.id} key={item.id}>
                            {item.name}
                          </option>
                        )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.city_arrival}</span>
                  </div>
                </div>
              </div>
              {filed == 1 ? (
                <>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name" style={{ display: "block" }}>
                          {t("form.return_departure_date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            selected={data.return_departure_date && moment(data.return_departure_date, 'DD/MM/YYYY').toDate()}// Use a format that DatePicker can parse
                            dateFormat={
                              i18n.language === "frFR"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            } // Display format
                            onChange={(date) =>
                              handleSetData(
                                "return_departure_date",
                                moment(date).format("DD/MM/YYYY")
                              )
                            }
                            // minDate={new Date()}
                            minDate={new Date()}
                            maxDate={moment(
                              data.return_arrival_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            className="px-3"
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {returnError.return_departure_date}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group ">
                        <label htmlFor="name" style={{ display: "block" }}>
                          {t("form.return_arrival_date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            selected={data.return_arrival_date && moment(data.return_arrival_date, 'DD/MM/YYYY').toDate()} // Use a format that DatePicker can parse
                            dateFormat={
                              i18n.language === "frFR"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            } // Display format
                            onChange={(date) =>
                              handleSetData(
                                "return_arrival_date",
                                moment(date).format("DD/MM/YYYY")
                              )
                            }
                            minDate={moment(
                              data?.return_departure_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            className="px-3"
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {returnError.return_arrival_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.Return_departure_time")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="time"
                          id="return_departure_time"
                          onChange={handleChange}
                          name="return_departure_time"
                          value={data?.return_departure_time}
                        />
                        <span style={{ color: "red" }}>
                          {returnError.return_departure_time}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group ">
                        <label htmlFor="name">
                          {t("form.return_arrival_time")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="time"
                          id="return_arrival_time"
                          onChange={handleChange}
                          name="return_arrival_time"
                          value={data?.return_arrival_time}
                        />
                        <span style={{ color: "red" }}>
                          {returnError.return_arrival_time}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name" style={{ display: "block" }}>
                          {t("form.departure_date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            selected={moment(
                              data?.departure_date,
                              "DD/MM/YYYY"
                            ).toDate()} // Use a format that DatePicker can parse
                            dateFormat={
                              i18n.language === "frFR"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            } // Display format
                            onChange={(date) =>
                              handleSetData(
                                "departure_date",
                                moment(date).format("DD/MM/YYYY")
                              )
                            }
                            minDate={new Date()}
                            maxDate={moment(
                              data.arrival_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            className="px-3"
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {error.departure_date}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group ">
                        <label htmlFor="name" style={{ display: "block" }}>
                          {t("form.arrival_date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <div className="customDatePickerWidth">
                          <DatePicker
                            selected={moment(
                              data?.arrival_date,
                              "DD/MM/YYYY"
                            ).toDate()} // Use a format that DatePicker can parse
                            dateFormat={
                              i18n.language === "frFR"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            } // Display format
                            onChange={(date) =>
                              handleSetData(
                                "arrival_date",
                                moment(date).format("DD/MM/YYYY")
                              )
                            }
                            minDate={moment(
                              data?.departure_date,
                              "DD/MM/YYYY"
                            ).toDate()}
                            className="px-3"
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {error.arrival_date}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.departure_time")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="time"
                          id="departure_time"
                          onChange={handleChange}
                          name="departure_time"
                          value={data?.departure_time}
                        />
                        <span style={{ color: "red" }}>
                          {error.departure_time}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group ">
                        <label htmlFor="name">
                          {t("form.arrival_time")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="time"
                          id="arrival_time"
                          onChange={handleChange}
                          name="arrival_time"
                          value={data?.arrival_time}
                        />
                        <span style={{ color: "red" }}>
                          {error.arrival_time}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.carriage_capacity")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      required
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder=""
                      id="carriage_capacity"
                      onChange={handleChange}
                      name="carriage_capacity"
                      value={data?.carriage_capacity}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.carriage_capacity ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      kg
                    </span>
                    <span style={{ color: "red" }}>
                      {error.carriage_capacity}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.price_per_kg")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      required
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder=""
                      id="price_per_kg"
                      onChange={handleChange}
                      name="price_per_kg"
                      value={data?.price_per_kg}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 20,
                        bottom: error.carriage_capacity ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      {contextObj?.loginUser?.symbol}
                    </span>
                    <span style={{ color: "red" }}>{error.price_per_kg}</span>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                  <div className="custom-input-group ">
                    <label htmlFor="name">{t("form.currency")}<span style={{ color: 'red' }}>*</span></label>
                    <select
                      required
                      id="currency_id"
                      onChange={(e) => {
                        handleChange(e, "currency_id");
                      }}
                      name='currency_id'
                      value={data?.currency_id}
                    >
                      <option value="" selected disabled>{t("form.Select_currency")}</option>
                      {currencyData?.map((item) => <option value={item.id} key={item.id}>{item.code} ({item.symbol})</option>)}
                    </select>
                    <span style={{ color: "red" }}>{error.currency}</span>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>{t("form.Is_a_stopover")}</label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="stopover_in_trip"
                                name="stopover_in_trip"
                                required
                                onChange={(e) => {
                                  setIsRadio(true);
                                  handleSetData("stopover_in_trip", false);
                                  setOpen(false);
                                  showMessage(false);
                                }}
                                checked={isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="stopover_in_trip"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="stopover_in_trip"
                                name="stopover_in_trip"
                                required
                                onChange={(e) => {
                                  setIsRadio(false);
                                  handleSetData("stopover_in_trip", true);
                                  setOpen(true);
                                  showMessage(false);
                                }}
                                checked={!isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="stopover_in_trip"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group"></div>
                </div>
              </div>
              {open ? (
                <>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.country_Of_stopper")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="SelectElement"
                          onChange={(e) => {
                            handleChange(e, "country_Of_stopper");
                            handleSetData("state_stopover", "");
                            handleSetData("city_stopover", "");

                            getStopoverStateData(e.target.value);
                          }}
                          name="country_stopover"
                          value={data?.country_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.country_Of_stopper")}
                          </option>
                          {details.stopoverCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.state_Of_stopper")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="state_stopover"
                          onChange={(e) => {
                            handleChange(e, "state_of_stopover");
                            handleSetData("city_stopover", "");
                            getStopoverCity(e.target.value);
                          }}
                          name="state_stopover"
                          value={data?.state_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.state_Of_stopper")}
                          </option>
                          {details.stopoverState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.city_of_stopover")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="city_stopover"
                          onChange={(e) => {
                            handleChange(e, "city_of_stopover");
                          }}
                          name="city_stopover"
                          value={data?.city_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.city_of_stopover")}
                          </option>
                          {details.stopoverCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <span>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.pick_up_place")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_pick_up_place")}
                      id="pickup_place"
                      onChange={handleChange}
                      name="pickup_place"
                      value={data?.pickup_place}
                    />
                    <span style={{ color: "red" }}>{error.pickup_place}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.delivery_place")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_delivery_place")}
                      id="delivery_place"
                      onChange={handleChange}
                      name="delivery_place"
                      value={data?.delivery_place}
                    />
                    <span style={{ color: "red" }}>{error.delivery_place}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.vehicle_type")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="vehicle_type"
                      onChange={handleChange}
                      name="vehicle_type"
                      value={data?.vehicle_type ? data?.vehicle_type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.type_of_transport")}
                      </option>
                      {
                        i18n.language === 'frFR' ?
                          (contextObj.vehicleTypeFr?.map((item, index) => (
                            <option value={item.value} key={index}>{item.name}</option>
                          ))) :
                          (contextObj.vehicleTypeEn?.map((item, index) => (
                            <option value={item.value} key={index}>{item.name}</option>
                          )))
                      }
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.vehicle_type}</span>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="custom-input-group">
                    {data?.vehicle_type == 0 ? (
                      false
                    ) : (
                      <img
                        src={
                          data?.vehicle_type == 1
                            ? "/images/vehicles/Bike.png"
                            : data?.vehicle_type == 2
                              ? "/images/vehicles/boat.png"
                              : data?.vehicle_type == 3
                                ? "/images/vehicles/bus.png"
                                : data?.vehicle_type == 4
                                  ? "/images/vehicles/car.png"
                                  : data?.vehicle_type == 5
                                    ? "/images/vehicles/kickscooter.png"
                                    : data?.vehicle_type == 6
                                      ? "/images/vehicles/train.png"
                                      : data?.vehicle_type == 7
                                        ? "/images/vehicles/MoterBike.png"
                                        : data?.vehicle_type == 8
                                          ? "/images/vehicles/plan.png"
                                          : data?.vehicle_type == 9
                                            ? "/images/vehicles/scooter.png"
                                            : data?.vehicle_type == 10
                                              ? "/images/vehicles/Tvg.png"
                                              : data?.vehicle_type == 11
                                                ? "/images/vehicles/Tramway.png":
                                                data?.vehicle_type == 12 ?
                                                "/images/vehicles/train.png"
                                                : ""
                        }
                        height="100%"
                        width="100%"
                      />
                    )}
                  </div>
                </div>
                {data?.vehicle_type == 0 ? (
                  false
                ) : (
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {data?.vehicle_type == 1
                          ? ""
                          : data?.vehicle_type == 2
                            ? t("form.Boat")
                            : data?.vehicle_type == 3
                              ? t("form.Bus")
                              : data?.vehicle_type == 4
                                ? t("form.Car_Registration")
                                : data?.vehicle_type == 5
                                  ? t("form.kick_scooter")
                                  : data?.vehicle_type == 6
                                    ? t("form.Train_Number")
                                    : data?.vehicle_type == 7
                                      ? t("form.MoterBike")
                                      : data?.vehicle_type == 8
                                        ? t("form.Flight_Number")
                                        : data?.vehicle_type == 9
                                          ? t("form.Scooter")
                                          : data?.vehicle_type == 10
                                            ? t("form.Tgv")
                                            : data?.vehicle_type == 11
                                              ? t("form.Tramway")
                                              : ""}
                      </label>
                      {data?.vehicle_type == 1 ? (
                        " "
                      ) : (
                        <input
                          required
                          type="text"
                          placeholder={
                            data?.vehicle_type == 1
                              ? ""
                              : data?.vehicle_type == 2
                                ? t("form.Boat")
                                : data?.vehicle_type == 3
                                  ? t("form.Bus")
                                  : data?.vehicle_type == 4
                                    ? t("form.Car_Registration")
                                    : data?.vehicle_type == 5
                                      ? t("form.kick_scooter")
                                      : data?.vehicle_type == 6
                                        ? t("form.Train_Number")
                                        : data?.vehicle_type == 7
                                          ? t("form.MoterBike")
                                          : data?.vehicle_type == 8
                                            ? t("form.Flight_Number")
                                            : data?.vehicle_type == 9
                                              ? t("form.Scooter")
                                              : data?.vehicle_type == 10
                                                ? t("form.Tgv")
                                                : data?.vehicle_type == 11
                                                  ? t("form.Tramway")
                                                  : ""
                          }
                          id="vehicle_details"
                          onChange={handleChange}
                          name="vehicle_details"
                          value={data?.vehicle_details}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>{t("form.travel_insurance")}</label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="insurance"
                                name="insurance"
                                required
                                onChange={(e) => {
                                  setIsShow(false);
                                  handleSetData("insurance", false);
                                }}
                                checked={!isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="insurance"
                                name="insurance"
                                required
                                onChange={(e) => {
                                  setIsShow(true);
                                  handleSetData("insurance", true);
                                }}
                                checked={isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.traveller_type")}</label>
                    <select
                      id="traveller_type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="traveller_type"
                      value={data?.traveller_type}
                    >
                      <option value="" selected disabled>
                        {t("form.traveller_type")}
                      </option>
                      <option value={1}>{t("form.Regular")}</option>
                      <option value={2}>{t("form.Periodic")}</option>
                      <option value={3}>{t("form.Oneoff")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.traveller_type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className=" col-12 col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.packaging_instruction")}
                    </label>
                    <textarea
                      rows={4}
                      id="packing_instruction"
                      onChange={handleChange}
                      name="packing_instruction"
                      value={data?.packing_instruction}
                      maxLength={160}
                    ></textarea>
                    {/* <span style={{ color: "red" }}>{error.packing_instruction}</span> */}
                  </div>
                </div>
                <div className="col-12 col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Description")}</label>
                    <textarea
                      rows={4}
                      id="description"
                      onChange={handleChange}
                      name="description"
                      value={data?.description}
                      maxLength={160}
                    ></textarea>
                    {/* <span style={{ color: "red" }}>{error.description}</span> */}
                  </div>
                </div>
              </div>
              {data?.trip_type == 1 ? (
                <div className="package-sidebar">
                  <aside
                    className="package-widget widget-tour-categoris mt-30"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="widget-body">
                      <div className="">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate"
                          onChange={(e) => {
                            handleSetData(
                              "terms_and_condition",
                              e.target.checked ? true : false
                            );
                          }}
                          checked={data?.terms_and_condition}
                        />{" "}
                        <span>{t("form.Terms_and_condition")}</span>
                      </div>
                    </div>
                  </aside>
                </div>
              ) : filed == 1 ? (
                <div className="">
                  <div
                    class="form-check form-check-inline d-flex pt-50"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div class="d-flex">
                      <input
                        class=""
                        type="checkbox"
                        id="inlineCheckbox1"
                        style={{ width: "15px", height: "15px" }}
                        name="oneWayTicket"
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                        checked={data?.terms_and_condition}
                      />
                      <label style={{ fontSize: "14px" }}>
                        {t("profile.i_would_like_to_publish")}
                      </label>
                    </div>
                  </div>
                </div>
              ) : null}
              {data?.trip_type &&
                (data?.trip_type == 1 ? (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        {/* <Link to={`${process.env.PUBLIC_URL}/sellArticle`}>   */}
                        <button
                          type="submit"
                          disabled={!data?.terms_and_condition}
                          onClick={handleSubmit}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.submit")}
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                ) : filed == 1 ? (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        <button
                          type="submit"
                          disabled={!data?.terms_and_condition}
                          onClick={handleSubmit}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Preview_Advert")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        <button
                          type="submit"
                          onClick={() => {
                            setFiled(1);
                            showError();
                          }}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Submit_and_fill")}
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button onClick={handleClose}>{t("close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
