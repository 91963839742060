import axios from '../plugin/axios';

const Wallet = {

    getBuyUserID() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Wallet_history/GetByUserId')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    WithdrawRequestBuyUserID() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Wallet_history/WithdrawRequestByUserId')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    totalEarning() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Wallet_history/TotalEarning')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Wallet_history/CreateWithdrawRequest', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    ReceivedPayments() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Wallet_history/ReceivedPayments')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }

}
export default Wallet