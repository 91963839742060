import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import {
  useHistory, useParams
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth, country, user } from "../../../../models";
import AppContext from "../../../../store/context/app-context";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import Loading from "../../../common/Loading";
import i18n from "../../../../plugin/i18n";

const defaultData = {
  address: '',
  address2: '',
  video_link: '',
  city: '',
  state: '',
  postal_code: '',
  identity_id: '',
  identity_images: [],
  base64Image: '',
}
const format = {
  state_of_Departure: {},
  city_of_Departure: {},
  departureState: [],
  departureCity: [],
};

const otherDataCity = {
  state_id: "",
  city_name: "",
};

function ProfileInfoWrapperArea() {
  const { t } = useTranslation();
  const { country_id } = useParams();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState(defaultData);
  const [otherCityData, setOtherCityData] = useState(otherDataCity);
  const history = useHistory();
  const [details, setDetails] = useState(format);
  const [isOpen, setIsOpen] = useState(false);
  const [idShow, setIdShow] = useState(false);
  const contextObj = useContext(AppContext);
  const [load, setLoad] = useState(false);

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);


  const error = {
    address: validator.message(t("profile.address"), data.address, 'required|string|max:200'),
    state: validator.message(t("profile.state"), data.state, 'required|string|max:200'),
    city: validator.message(t("profile.city"), data.city, 'required|string|max:200'),
    postal_code: validator.message(t("profile.zipcode"), data.postal_code, 'required|string|max:200'),
    // identity_id: validator.message(t("profile.document_type"), data.identity_id, idShow ? 'required|string|max:200' : ''),
  };

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    country
      .state(country_id)
      .then((details) => {
        handleData("departureState", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const getDepartureCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("departureCity", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const convertToBase64 = (e, type) => {
    let tempFile = e.target.files[0];

    getBase64Image(tempFile).then(responseBase64 => {
      let arr = data?.identity_images ? [...data?.identity_images] : ['0', '0'];
      if (type == 1) {
        arr[0] = responseBase64
      }
      else {
        arr[1] = responseBase64
      }
      handleSetData('identity_images', arr);

    }).catch((err) => {
      console.log('Error While convert to base64: ', err);
    })
  }

  const getBase64Image = (fileParam) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    })
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      if (isOpen) {
        setLoad(true);
        auth.userProfileUpdate(data).then((item) => {
          contextObj.functionsetloginUser();
          contextObj.functionLogin();
          localStorage.setItem("loginUserType", item.login_type)
          localStorage.setItem("loginUsercountry", item.country_id)
          history.push("/myProfile");
          // history.push(`/travelDetails/${window.localStorage.getItem("id")}/${window.localStorage.getItem("type")}`);
          setLoad(false);
        })
          .catch((error) => {
            setLoad(false);
            console.log(error);
          })
      }
      else if (!isOpen) {
        setIsOpen(true);
        setIdShow(true);
      }
    }
    else {
      showMessage(true);
    }

  }
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const handleSetDataOtherCity = (name, value) => {
    setOtherCityData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmitOtherCity = () => {
    country
      .addOtherCity(otherCityData)
      .then((response) => {
        data?.city == "other_city" &&
          handleSetData("city", "");
        getDepartureCity(otherCityData?.state_id);
        // getArrivalCity(otherCityDataArrwal?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div className="container">
          <form onSubmit={handleSubmit}
            action="#"
            method="post">
            <div className='package-details-wrapper pb-110 ' style={{ paddingTop: '30px' }}>
              <div className='tour-package-details'>
                <div className="package-details-tabs">
                  <ul
                    className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                    id="pills-tab"
                    role="tablist"
                    style={{ marginTop: '10px', }}
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        disabled
                        className={!isOpen ? "nav-link active" : "nav-link"}
                        id="pills-package1"
                        data-bs-toggle="pill"
                        data-bs-target="#pill-body1"
                        type="button"
                        // role="tab"
                        // aria-controls="pill-body1"
                        // aria-selected="true"
                        onClick={() => setIsOpen(false)}
                        style={{ fontSize: '18px', borderRadius: "5px" }}
                      >
                        {t("profile.profile_information")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        disabled
                        className={!isOpen ? "nav-link" : "nav-link active"}
                        id="pills-package2"
                        data-bs-toggle="pill"
                        data-bs-target="#pill-body2"
                        type="button"
                        // role="tab"
                        // aria-controls="pill-body2"
                        // aria-selected="false"  
                        style={{ fontSize: '18px', borderRadius: "5px" }}
                      >
                        {t("profile.id_verification")}
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    {/* Profile Information */}
                    <div
                      className={!isOpen ? "tab-pane fade show active package-info-tab" : "tab-pane fade package-info-tab"}
                      id="pill-body1"
                      role="tabpanel"
                      aria-labelledby="pills-package1"
                    >
                      <div className="row" style={{ overflow: 'hidden' }}>
                        <div className="col-lg-6 px-0">
                          <img src="/images/registration/rG5.png" style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: '100%' }} />
                        </div>
                        <div className="contact-form-wrap pt-0  col-lg-6" >
                          <div>
                            <p style={{ color: "red" }}>{t("profile.Note_residence")}</p>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <label htmlFor="name">{t("profile.address")}
                                  <span style={{ color: 'red' }}>*</span>
                                </label>
                                <input
                                  required
                                  type="text"
                                  placeholder={t("profile.address")}
                                  id="address"
                                  onChange={handleChange}
                                  name='address'
                                  value={data.address}
                                />
                                <span style={{ color: "red" }}>{error.address}</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="custom-input-group ">
                                <label htmlFor="name">{t("profile.address2")}</label>
                                <input
                                  required
                                  type="text"
                                  placeholder={t("profile.address2")}
                                  id="address2"
                                  onChange={handleChange}
                                  name='address2'
                                  value={data.address2}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
                              <div className="col-12 col-sm-6 " style={{ paddingRight: '9px' }}>
                                <div className="custom-input-group">
                                  <label htmlFor="name">{t("profile.state")}<span style={{ color: 'red' }}> *</span></label>
                                  <select
                                    required
                                    id="state_departure"
                                    onChange={(e) => {
                                      handleChange(e, "state_of_Departure");
                                      handleSetData("city", "");
                                      getDepartureCity(e.target.value);
                                      handleSetDataOtherCity("state_id", e.target.value);
                                    }}
                                    name='state'
                                    value={data.state}
                                  >
                                    <option value="" selected disabled>{t("profile.state")}</option>
                                    {details?.departureState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                  </select>
                                  <span style={{ color: "red" }}>{error.state}</span>
                                </div>
                              </div>
                              <div className="col-12 col-sm-6 " style={{ paddingRight: '0px' }}>
                                <div className="custom-input-group">
                                  <label htmlFor="name">{t("profile.city")}<span style={{ color: 'red' }}> *</span></label>
                                  <select
                                    required
                                    id="city_departure"
                                    onChange={(e) => {
                                      handleChange(e, "city_of_Departure");
                                    }}
                                    name='city'
                                    value={data.city}
                                  >
                                    <option value="" selected disabled>{t("profile.city")}</option>
                                    {details?.departureCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                    {otherCityData?.state_id && (
                                      <option value="other_city">
                                        {t("form.Add_your_City")}
                                      </option>
                                    )}
                                  </select>
                                  <span style={{ color: "red" }}>{error.city}</span>
                                </div>
                              </div>

                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-10">
                              {data.city == "other_city" && (
                                <div className="custom-input-group">
                                  <label htmlFor="name">{t("form.Add_your_City")}</label>
                                  <input
                                    required
                                    disabled={!otherCityData?.state_id}
                                    type="text"
                                    placeholder={t("form.Add_your_City")}
                                    id="city_name"
                                    onChange={(e) => {
                                      handleSetDataOtherCity("city_name", e.target.value);
                                    }}
                                    name="city_name"
                                    value={otherCityData?.city_name}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="col-lg-2"
                              style={{ display: "flex", alignItems: "end", }}
                            >
                              {data.city == "other_city" && (
                                <div className="custom-input-group">
                                  <button
                                    type="submit"
                                    onClick={handleSubmitOtherCity}
                                    // className="btn btn-secondary"

                                    style={{
                                      fontSize: "14px",
                                      padding: "16px 21px"
                                    }}
                                  >
                                    {t("form.Add")}
                                  </button>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="row">

                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <label htmlFor="name">{t("profile.zipcode")} <span style={{ color: 'red' }}>*</span></label>
                                <br className={`${i18n.language === 'frFR' ? 'd-md-block' : 'd-none'}`} /> <br className={`${i18n.language === 'frFR' ? 'd-md-block' : 'd-none'}`} />
                                <input
                                  required
                                  type="number"
                                  className='numberType'
                                  onWheel={numberInputOnWheelPreventChange}
                                  placeholder={t("profile.zipcode")}
                                  id="postal_code"
                                  onChange={handleChange}
                                  name='postal_code'
                                  value={data.postal_code}
                                />
                                <span style={{ color: "red" }}>{error.postal_code}</span>
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <label htmlFor="name">{t("profile.youTube_video_link_for_showing_your_presentation")}</label>
                                <input
                                  type="text"
                                  placeholder={t("profile.video_link")}
                                  id="video_link"
                                  onChange={handleChange}
                                  name='video_link'
                                  value={data.video_link}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="custom-input-group">
                            <div className="submite-btn">
                              <button type="submit" onClick={handleSubmit}>{t("save")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Id verification */}
                    <div
                      className={!isOpen ? "tab-pane fade package-info-tab" : "tab-pane fade show active package-info-tab"}
                      id="pill-body2"
                      role="tabpanel"
                      aria-labelledby="pills-package2"

                    >
                      <div className="row" style={{ overflow: 'hidden' }}>
                        <div className="col-lg-5 contact-form-wrap px-0">
                          <img src="/images/registration/rG5.png" style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: '100%' }} />

                        </div>
                        <div className="col-lg-7 contact-form-wrap" style={{ overflow: 'hidden' }}>
                          <h5>{t("profile.verify_your_id")}
                            {/* <span style={{ color: 'red' }}>*</span> */}
                          </h5>
                          <p>
                            <span style={{ fontWeight: '600' }}> {t("profile.please_upload_your_ID_verification_document_you_may_upload_any_of_the_below_listed_documents")} :</span><br />
                            {t("profile.national_identity_card")} <br />
                            {t("profile.passport")} <br />
                            {t("profile.residence_card")}<br />
                            {t("profile.green_card")} <br />
                            {t("profile.drive_licence")}<br />
                            {t("profile.residence_permit")}<br />
                            {t("profile.other_valide_document")}
                          </p>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <select
                                  id="identity_id"
                                  onChange={handleChange}
                                  name='identity_id'
                                // value={data.identity_id}
                                >
                                  <option value="" selected disabled>-{t("profile.select_document_type")}-</option>
                                  <option value={'1'}>{t("profile.national_identity_card")}</option>
                                  <option value={'2'}> {t("profile.passport")}</option>
                                  <option value={'3'}> {t("profile.residence_card")}</option>
                                  <option value={'4'}>{t("profile.green_card")}</option>
                                  <option value={'5'}> {t("profile.drive_licence")}</option>
                                  <option value={'6'}>{t("profile.residence_permit")}</option>
                                  <option value={'7'}> {t("profile.other_valide_document")}</option>
                                </select>
                                {/* <span style={{ color: "red" }}>{error.identity_id}</span> */}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <label htmlFor="name">{t("profile.front_page")}</label>
                                <input
                                  type="file"
                                  placeholder={t("profile.front_page")}
                                  id="identity_images"
                                  onChange={(e) => convertToBase64(e, 1)}
                                  name='identity_images'
                                  style={{ border: 'none', backgroundColor: 'white' }}
                                // value={data.full_name}
                                />
                              </div>
                            </div>
                            <div className="col-lg-6">
                              <div className="custom-input-group">
                                <label htmlFor="email">{t("profile.back_page")}</label>
                                <input
                                  type="file"
                                  placeholder={t("profile.back_page")}
                                  id="identity_images"
                                  onChange={(e) => convertToBase64(e, 2)}
                                  name='identity_images'
                                  style={{ border: 'none', backgroundColor: 'white' }}
                                // value={data.email}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="custom-input-group">
                            <div className="submite-btn">
                              <button type="submit" onClick={handleSubmit} >{t("profile.Skip_and_go_my_profile")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      )}
    </>
  );
}

export default ProfileInfoWrapperArea;
