const menu = {
    home: 'Home',
    contact_us: 'Contact Us',
    about_us: 'About Us',
    submit_an_ad: 'Submit an Advert',
    my_account: 'Profile',
    login: 'Sign In',
    registration:'Sign Up',
    logout: 'Sign Out',
    travels: "Travels",
    luggages: "Luggages",
    sell_article: "Sell Article",
    articles: "Articles",
    article_donation: "Article Donation",
    Total_Amount_paid: "Total Amount paid",

    // footer
    follow_us_on: 'Follow Us On',
    rate_our_website: 'Rate our website',
    legals: 'Legal Notice',
    more_on_cobagage: ' More on Cobagage',
    cobagage_as_a_job: ' Cobagage as a job',
    privacy_policy: ' Privacy Policy',
    gallery: 'Gallery',
    cobagage_copyright_2019_All_rights_reserved: '©Cobagage Copyright 2019-2024 All rights reserved.',
    Earnings_Report: "Earnings Report",
    Spending_Reports: "Spending Reports",
    Received: "Received",
    Total_Net_Earnings: "Total Net Earnings",
    Transaction_Date: "Transaction Date &",
    Description: "Description",
    Cobagage_fees: "Cobagage fees",
    Net_Amount_earned: "Net Amount earned",
    Total_Spendings: "Total Spendings",
    Sent: "Sent",
    Paid_to:"Paid to",
    Luggage_carrying_cost:"Total cost of item sold",

    // Tips_And_Advice
    Tips_And_Advice:"Tips And Advice",
    Tips_And_AdviceText1:"Welcome everyone to the Cobagage site, the site that turns travelers into businessmen (business man, business woman). in this section we will give you tips and advice for successful transactions:",
    Tips_And_AdviceText2:"monetize your trip as much as possible while remaining eco-friendly!",
    Tips_And_AdviceText3:"YOU (TRAVEL OR BAGGAGE SENDER)",
    Tips_And_AdviceText4:"Your registration is validated by a code sent simultaneously to your email and your unique telephone number. then you will have to download proof of identity in order to have a verified profile (visible on all your ads on the site).",
    Tips_And_AdviceText5:"ACCEPTANCE OR REFUSAL OF TRANSACTION REQUESTS (SENDING BAGGAGE OR TRANSPORT OF LUGGAGE AS A TRAVELER)",
    Tips_And_AdviceText6:"You post your ads and you will have requests from certain users. You must then either accept or deny the requests. If you accept the author of the request will just have to pay the transaction costs: shipment of luggage by price fixed per kg, purchase of goods, transport of luggage as a traveler.",
    Tips_And_AdviceText7:"Very important",
    Tips_And_AdviceText8:"Only look at users who only have profiles marked “Verified”. These are profiles for which users have uploaded their proof of identity (identity card, resident card, green card, residence permit, etc.)",
    Tips_And_AdviceText9:"APPOINTMENT FOR PICK UP/RECOVERY OF THE BAGGAGE TO BE TRANSPORTED (YOU, THE TRAVELER)",
    Tips_And_AdviceText10:"In your advertisement for the sale of kilos of luggage as a traveler, you must indicate the exact location to collect the luggage with which you must travel. Be precise and simple to make luggage recovery easier. Don't make anything complicated. A public place where you will be comfortable.",
    Tips_And_AdviceText11:"LUGGAGE TO BE CHECKED",
    Tips_And_AdviceText12:"Have a measuring or weighing instrument to measure the total weight of the luggage. You must absolutely verify that the total weight paid online is the same weight that the shipper presents to you. Check unclosed and easy-to-check luggage. If in doubt, simply cancel the transaction so that the refund can be made to the owner of the luggage.",
    Tips_And_AdviceText13:"Do not be responsible for transporting questionable luggage.",
    Tips_And_AdviceText14:"The photos posted online for the luggage must absolutely be the same as those you see in front of you.",
    Tips_And_AdviceText15:"TRIP OR TRAVEL",
    Tips_And_AdviceText16:'From the "FOLLOW MY TRIPS" section, you can update your journey by posting photos of all your stopovers or stops made.',
    DELIVERY:"DELIVERY",
    Tips_And_AdviceText17:"The receiver of the luggage or item (in the event of an item sale) must give you a delivery code in order to validate or finalize the transaction in progress.",
    Tips_And_AdviceText18:"NOTE AND COMMENT",
    Tips_And_AdviceText19:"At the end of the delivery, you can rate and comment on the user with whom you finalized or completed the transaction.",
    Tips_And_AdviceText20:"COBAGAGE WALLET",
    Tips_And_AdviceText21:"Upon acceptance of the request you received, the user (luggage shipper or buyer of item for sale) will pay the full amount online. You will have in your “Cobagage Wallet” your counterparty for the transaction. You just have to make a deposit request or payment on a bank card that you have entered.",
    Tips_And_AdviceText22:"In Conclusion, be in contact with users with verified profiles and never take closed, doubtful luggage that does not conform to what was posted on the site, avoid luggage with excess kilos (number of kilos inconsistent with what was posted on the site). was announced - you declare 10 kilos online and the traveler measures 15 kg).",
    Tips_And_AdviceText23:"You are solely responsible for everything you transport, Cobagage only provides connections.",
    Tips_And_AdviceText24:"If in doubt, simply cancel the transaction as there is no obligation to carry luggage on the Cobagage website.",
    Finally_on_Cobagage_you_can:"Finally on Cobagage, you can",
    finallyText1:"Sell kilos of luggage when you travel",
    finallyText2:"Sell items when you travel",
    finallyText3:"Donate items, an ecological act of giving objects a second life",
    Excellent_transactions_Cobagage_website:"Excellent transactions on the Cobagage website!",
    Article_Deleted_Successfully:"Article Deleted Successfully",
    Travle_Deleted_Successfully:"Travel Deleted Successfully",
    Donation_Deleted_Successfully:"Donation Deleted Successfully",
    Luggage_Deleted_Successfully:"Luggage Deleted Successfully"

};
export default menu;