import React from 'react'
import { useTranslation } from 'react-i18next'
import BradcomePrivacyPolicy from '../../common/BradcomePrivacyPolicy'
import PrivacyPolicyWrap from './PrivacyPolicyWrap'

export default function PrivacyPolicy() {
    const { t } = useTranslation()
    return (
        <div>
            <BradcomePrivacyPolicy
                name={t('privacyPolicy.title')}
            />
            <PrivacyPolicyWrap/>
        </div>
    )
}
