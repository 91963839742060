import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { country } from "../../../../models";
import useValidator from "../../../../plugin/validator";
import AppContext from "../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import notistack from "../../../../plugin/notistack";
import luggage from "../../../../models/luggage";
import Loading from "../../../common/Loading";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
const restData = {
  title: "",
  terms_and_condition: "",
  type: "",
  country_departure: "",
  state_departure: "",
  city_departure: "",
  country_arrival: "",
  state_arrival: "",
  city_arrival: "",
  location: "",
  luggage_estimation: "",
  weight: "",
  height: "",
  width: "",
  volume: "",
  colour: "",
  warranty: "",
  receiver_name: "",
  receiver_phone_code: "",
  receiver_contact_number: "",
  receiver_relation: "",
  self_pickup: "",
  sender_type: "",
  description: "",
  images: [],
  departure_date: moment().format("DD/MM/YYYY"),
  arrival_date: "",
  // currency_id: '',
};

const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_stopover: {},
  state_of_stopover: {},
  city_of_stopover: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  stopoverCountry: [],
  stopoverState: [],
  stopoverCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

const otherDataCity = {
  state_id: "",
  city_name: "",
};

const otherDataCityArray = {
  state_id: "",
  city_name: "",
};

function PostLuggageRequest() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [data, setData] = useState(restData);
  const [details, setDetails] = useState(format);
  const [image, setImage] = useState([]);
  const [validator, showMessage] = useValidator();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [isRadio, setIsRadio] = useState(true);
  const [isShow, setShow] = useState(false);
  const [load, setLoad] = useState(false);
  const [imageArray, setImageArray] = useState(["", "", "", ""]);
  const [imgmassage, setImgmassage] = useState(["", "", "", ""]);
  const [currencyData, setCurrencyData] = useState([]);
  const [otherCityData, setOtherCityData] = useState(otherDataCity);
  const [otherCityDataArrwal, setOtherCityDataArrwal] =
    useState(otherDataCityArray);

  useEffect(() => {
    getData();
    // handleSetData('receiver_phone_code', "+93");
    handleSetData("warranty", false);
    handleSetData("self_pickup", true);
    getCurrency();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
        handleData("arriavalCountry", details);
        handleData("stopoverCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getDepartureStateData = (id) => {
    country.state(id).then((details) => {
      handleData("departureState", details);
    });
  };
  const getArrivalStateData = (id) => {
    country.state(id).then((details) => {
      handleData("arriavalState", details);
    });
  };
  const getDepartureCity = (id) => {
    country.city(id).then((details) => {
      handleData("departureCity", details);
    });
  };
  const getArrivalCity = (id) => {
    country.city(id).then((details) => {
      handleData("arriavalCity", details);
    });
  };
  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  // const convertToBase64 = (e) => {
  //     let tempFile = e.target.files[0];
  //     getBase64Image(tempFile).then(responseBase64 => {
  //         let arr = data?.images ? [...data?.images] : ['0', '0'];
  //         arr.push(responseBase64);
  //         handleSetData('images', arr);

  //     }).catch((err) => {
  //         console.log('Error While convert to base64: ', err);
  //     })
  // }

  const convertToBase641 = (e, index) => {
    let array = [...imageArray];
    let arrayMsg = [...imgmassage];
    let tempFile = e.target.files[0];
    if (tempFile.size > 4000000) {
      arrayMsg[index] = t("form.File_allowed");
      setImgmassage(arrayMsg);
      // array[index] = "";
      // setImageArray(array);
      // let remove = data?.images ? [...data?.images] : ["", "", "", ""];
      // remove[index] = "";
      // handleSetData("images", remove);
      return false;
    } else {
      let tempFiles = URL.createObjectURL(e.target.files[0]);
      array[index] = tempFiles;
      setImageArray(array);
      getBase64Image(tempFile)
        .then((responseBase64) => {
          let arr = data?.images ? [...data?.images] : ["", "", "", ""];
          arr[index] = responseBase64;
          handleSetData("images", arr);
          arrayMsg[index] = " ";
          setImgmassage(arrayMsg);
        })
        .catch((err) => {
          console.log("Error While convert to base64: ", err);
        });
    }
  };

  const getBase64Image = (fileParam) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        // console.log("Called", reader);
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
    });
  };

  const error = {
    title: validator.message(t("title"), data.title, "required"),
    type: validator.message(t("type"), data.type, "required"),
    country_departure: validator.message(
      t("country_departure"),
      data.country_departure,
      "required"
    ),
    country_arrival: validator.message(
      t("country_arrival"),
      data.country_arrival,
      "required"
    ),
    state_departure: validator.message(
      t("state_departure"),
      data.state_departure,
      "required"
    ),
    state_arrival: validator.message(
      t("state_arrival"),
      data.state_arrival,
      "required"
    ),
    city_departure: validator.message(
      t("city_departure"),
      data.city_departure,
      "required"
    ),
    city_arrival: validator.message(
      t("city_arrival"),
      data.city_arrival,
      "required"
    ),
    location: validator.message(t("location"), data.location, "required"),
    luggage_estimation: validator.message(
      t("luggage_estimation"),
      data.luggage_estimation,
      "required"
    ),
    weight: validator.message(t("weight"), data.weight, "required"),
    warranty: validator.message(t("warranty"), data.warranty, "required"),
    receiver_name: validator.message(
      t("receiver_name"),
      data.receiver_name,
      "required"
    ),
    receiver_contact_number: validator.message(
      t("receiver_contact_number "),
      data.receiver_contact_number,
      "required|numeric|max:20"
    ),
    receiver_phone_code: validator.message(
      t("receiver_phone_code"),
      data.receiver_phone_code,
      "required"
    ),
    receiver_relation: validator.message(
      t("receiver_relation"),
      data.receiver_relation,
      "required"
    ),
    self_pickup: validator.message(
      t("self_pickup"),
      data.self_pickup,
      "required"
    ),
    sender_type: validator.message(
      t("sender_type"),
      data.sender_type,
      "required"
    ),
    departure_date: validator.message(
      t("departure_date"),
      data.departure_date,
      "required"
    ),
    arrival_date: validator.message(
      t("arrival_date "),
      data.arrival_date,
      "required"
    ),
    // currency_id: validator.message(t('currency'), data.currency_id, 'required'),
    // description: validator.message(t('description'), data.description, 'required'),
  };

  const handleSubmit = (event) => {
    if (validator.allValid()) {
      if (data.images.length >= 3) {
        setLoad(true);
        scrollTop();
        luggage
          .create(data)
          .then((response) => {
            history.push(`/travelDetails/${response.id}/1`);
          })
          .catch((error) => {
            setLoad(false);
            notistack.error(error.data.message);
          });
      } else {
        notistack.error(t("form.minimum_3maximum_4images"));
      }
    } else {
      showMessage(true);
    }
  };
  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
  };

  const getCurrency = () => {
    country.currency().then((respons) => {
      setCurrencyData(respons);
    });
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  const handleSubmitOtherCity = () => {
    country
      .addOtherCity(otherCityData)
      .then((response) => {
        data?.city_departure == "other_city" &&
          handleSetData("city_departure", "");
        getDepartureCity(otherCityData?.state_id);
        // getArrivalCity(otherCityDataArrwal?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };
  const handleSubmitOtherCityArrawl = () => {
    country
      .addOtherCity(otherCityDataArrwal)
      .then((response) => {
        data?.city_arrival == "other_city" && handleSetData("city_arrival", "");
        getArrivalCity(otherCityDataArrwal?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };

  const handleSetDataOtherCity = (name, value) => {
    setOtherCityData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSetDataOtherCityArrawl = (name, value) => {
    setOtherCityDataArrwal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <h5 style={{ fontWeight: "bold" }}>{t("form.luggage_ad")}</h5>
              <div className="pt-3">
                <h6 style={{ color: "red" }}>
                  {t("form.PLEASE_VERIFIED_PROFILES")}
                </h6>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      onInput={onInputUppercase}
                      value={data.title}
                    />
                    <span style={{ color: "red" }}>{error.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Type_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="type"
                      onChange={handleChange}
                      name="type"
                      value={data.type ? data.type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.Type_luggage")}
                      </option>
                      <option value={1}>{t("form.New_Never_Used")}</option>
                      <option value={2}>{t("form.Used_Secound_hand")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: error.type ? "10px" : "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_Of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_departure"
                      onChange={(e) => {
                        handleChange(e, "country_of_Departure");
                        handleSetData("state_departure", "");
                        handleSetData("city_departure", "");
                        getDepartureStateData(e.target.value);
                      }}
                      name="country_departure"
                      value={data.country_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.country_Of_departure")}
                      </option>
                      {details.departureCountry?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.country_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_arrival"
                      onChange={(e) => {
                        handleChange(e, "country_arrival");
                        handleSetData("state_arrival", "");
                        handleSetData("city_arrival", "");
                        getArrivalStateData(e.target.value);
                        const findPhoneCode = () => {
                          return details.arriavalCountry.find((item) =>
                            item.id == e.target.value ? item : ""
                          ).phone_code;
                        };
                        handleSetData("receiver_phone_code", findPhoneCode());
                      }}
                      name="country_arrival"
                      value={data.country_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.country_of_arrival")}
                      </option>
                      {details.arriavalCountry?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.country_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_arrival}
                    </span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.state_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_departure"
                      onChange={(e) => {
                        handleChange(e, "state_of_Departure");
                        handleSetData("city_departure", "");
                        getDepartureCity(e.target.value);
                        handleSetDataOtherCity("state_id", e.target.value);
                      }}
                      name="state_departure"
                      value={data.state_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_departure")}
                      </option>
                      {details.departureState?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.state_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.state_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.state_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_arrival"
                      onChange={(e) => {
                        handleChange(e, "state_arrival");
                        handleSetData("city_arrival", "");
                        getArrivalCity(e.target.value);
                        handleSetDataOtherCityArrawl(
                          "state_id",
                          e.target.value
                        );
                      }}
                      name="state_arrival"
                      value={data.state_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_arrival")}
                      </option>
                      {details.arriavalState?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.state_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.state_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.city_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_departure"
                      onChange={(e) => {
                        handleChange(e, "city_of_Departure");
                      }}
                      name="city_departure"
                      value={data.city_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_departure")}
                      </option>
                      {details.departureCity?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                      {otherCityData?.state_id && (
                        <option value="other_city">
                          {t("form.Add_your_City")}
                        </option>
                      )}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.city_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.city_departure}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.city_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_arrival"
                      onChange={(e) => {
                        handleChange(e, "city_arrival");
                      }}
                      name="city_arrival"
                      value={data.city_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_arrival")}
                      </option>
                      {details.arriavalCity?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                      {otherCityDataArrwal?.state_id && (
                        <option value="other_city">
                          {t("form.Add_your_City")}
                        </option>
                      )}
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.city_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.city_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4 col-12">
                  {data.city_departure == "other_city" && (
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.Add_your_City")}</label>
                      <input
                        required
                        disabled={!otherCityData?.state_id}
                        type="text"
                        placeholder={t("form.Add_your_City")}
                        id="city_name"
                        onChange={(e) => {
                          handleSetDataOtherCity("city_name", e.target.value);
                        }}
                        name="city_name"
                        value={otherCityData?.city_name}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="col-lg-2 col-12"
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {data.city_departure == "other_city" && (
                    <div className="custom-input-group">
                      <button
                        type="submit"
                        onClick={handleSubmitOtherCity}
                        // className="btn btn-secondary"

                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {t("form.Add")}
                      </button>
                    </div>
                  )}
                </div>
                {data.city_arrival == "other_city" && (
                  <>
                    <div className="col-lg-4 col-12">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.Add_your_City")}</label>
                        <input
                          required
                          disabled={!otherCityDataArrwal?.state_id}
                          type="text"
                          placeholder={t("form.Add_your_City")}
                          id="city_name"
                          onChange={(e) => {
                            handleSetDataOtherCityArrawl(
                              "city_name",
                              e.target.value
                            );
                          }}
                          name="city_name"
                          value={otherCityDataArrwal?.city_name}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-12"
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <div className="custom-input-group">
                        <button
                          type="submit"
                          onClick={handleSubmitOtherCityArrawl}
                          // className="btn btn-secondary"

                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Add")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name" style={{ display: "block" }}>
                      {t("form.departure_date")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input
                                            required
                                            type="Date"
                                            id="departure_date"
                                            onChange={handleChange}
                                            name='departure_date'
                                            value={data.departure_date}
                                            min={new Date().toISOString().split('T')[0]}
                                            max={data.arrival_date}
                                        /> */}
                    <div className="customDatePickerWidth">
                      <DatePicker
                        selected={moment(
                          data.departure_date,
                          "DD/MM/YYYY"
                        ).toDate()} // Use a format that DatePicker can parse
                        dateFormat={
                          i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                        } // Display format
                        onChange={(date) =>
                          handleSetData(
                            "departure_date",
                            moment(date).format("DD/MM/YYYY")
                          )
                        }
                        minDate={new Date()}
                        maxDate={moment(
                          data.arrival_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        className="px-3"
                      />
                      {/* <DatePicker selected={data.departure_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data.departure_date} onChange={(date)=>{handleSetData("departure_date",date)}} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={new Date()} maxDate={data.arrival_date} className="px-3"/> */}
                    </div>
                    <span style={{ color: "red" }}>{error.departure_date}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name" style={{ display: "block" }}>
                      {t("form.arrival_date")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input
                                            required
                                            type="Date"
                                            id="arrival_date"
                                            onChange={handleChange}
                                            name='arrival_date'
                                            value={data.arrival_date}
                                            min={data?.departure_date ? data.departure_date : new Date().toISOString().split('T')[0]}
                                        /> */}
                    <div className="customDatePickerWidth">
                      <DatePicker
                        placeholderText={
                          i18n.language === "frFR" ? "dd/mm/yyyy" : "mm/dd/yyyy"
                        }
                        disabled={!data.departure_date}
                        selected={
                          data.arrival_date &&
                          moment(data.arrival_date, "DD/MM/YYYY").toDate()
                        }
                        dateFormat={
                          i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                        } // Display format
                        onChange={(date) =>
                          handleSetData(
                            "arrival_date",
                            moment(date).format("DD/MM/YYYY")
                          )
                        }
                        minDate={moment(
                          data.departure_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        className="px-3"
                      />
                      {/* <DatePicker selected={data.arrival_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data.arrival_date} onChange={(date)=>{
                                        handleSetData("arrival_date",date)
                                        }} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={data.departure_date} className="px-3"/> */}
                      {/* <DatePicker selected={data.arrival_date}  dateFormat={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} value={data.arrival_date} onChange={(value)=>handleSetData("arrival_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={data.departure_date} className="px-3"/> */}
                    </div>
                    <span style={{ color: "red" }}>{error.arrival_date}</span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <label htmlFor="name">{t("form.Luggage_Dimensions")}:</label>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.height")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.height")}
                      id="height"
                      onChange={handleChange}
                      name="height"
                      value={data.height}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.carriage_capacity ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.height}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.length")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.length")}
                      id="length"
                      onChange={handleChange}
                      name="length"
                      value={data.length}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.length}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.width")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.width")}
                      id="width"
                      onChange={handleChange}
                      name="width"
                      value={data.width}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.width}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Weight")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.Weight")}
                      id="weight"
                      onChange={handleChange}
                      name="weight"
                      value={data.weight}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.weight ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      kg
                    </span>
                    <span style={{ color: "red" }}>{error.weight}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Colour")}</label>
                    <input
                      type="text"
                      placeholder={t("form.Colour")}
                      id="colour"
                      onChange={handleChange}
                      name="colour"
                      value={data.colour}
                    />
                    {/* <span style={{ color: "red" }}>{error.colour}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Volume")}</label>
                    <input
                      className="numberType"
                      type="number"
                      placeholder={t("form.Volume")}
                      id="volume"
                      onChange={handleChange}
                      name="volume"
                      value={data.volume}
                    />
                    {/* <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span> */}
                    {/* <span style={{ color: "red" }}>{error.volume}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.contents_under_warranty")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ps-0">
                          <ul className="row">
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  setIsRadio(true);
                                  handleSetData("warranty", false);
                                }}
                                checked={isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  setIsRadio(false);
                                  handleSetData("warranty", true);
                                }}
                                checked={!isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.estimate_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.estimate_of_luggage")}
                      id="luggage_estimation"
                      onChange={handleChange}
                      name="luggage_estimation"
                      value={data.luggage_estimation}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 20,
                        bottom: error.luggage_estimation ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      {contextObj?.loginUser?.symbol}
                    </span>
                    <span style={{ color: "red" }}>
                      {error.luggage_estimation}
                    </span>
                  </div>
                </div>
                {/* <div className="col-lg-2">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name">{t("form.currency")}<span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            required
                                            id="currency_id"
                                            onChange={(e) => {
                                                handleChange(e, "currency_id");
                                            }}
                                            name='currency_id'
                                            value={data.currency_id}
                                        >
                                            <option value="" selected disabled>{t("form.Select_currency")}</option>
                                            {currencyData.map((item) => <option value={item.id} key={item.id}>{item.code} ({item.symbol})</option>)}
                                        </select>
                                        <span style={{ color: "red" }}>{error.currency_id}</span>
                                    </div>
                                </div> */}
              </div>
              <div className="row">
                <label htmlFor="name">{t("form.receiver_details")}:</label>
                <div className="col-12 col-lg-5">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Name_of_receiver")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      // placeholder={t("form.enter_pick_up_place")}
                      id="receiver_name"
                      onChange={handleChange}
                      name="receiver_name"
                      onInput={onInputUppercase}
                      value={data.receiver_name}
                    />
                    <span style={{ color: "red" }}>{error.receiver_name}</span>
                    <div style={{ marginTop: '2px' }}>
                      <span style={{ color: "red", }}>{t('form.toreceive_code')}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-2 icons_container">
                  <div className="custom-input-group">
                    <div className="icons_container icon facebook">
                      <label htmlFor="name">+###</label>
                      <div class="tooltip">{t("form.Phone_code")}</div>
                    </div>
                    <select
                      required
                      id="receiver_phone_code"
                      onChange={(e) => {
                        handleChange(e, "receiver_phone_code");
                      }}
                      disabled={data.receiver_phone_code}
                      name="receiver_phone_code"
                      value={data.receiver_phone_code}
                    >
                      <option value="" selected disabled>
                        {t("form.Phone_code")}
                      </option>
                      {details.arriavalCountry?.map((item) => (
                        <option value={item.phone_code} key={item.id}>
                          {item.phone_code}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>
                      {error.receiver_phone_code}
                    </span>
                  </div>
                </div>
                <div className="col-12 col-lg-5">
                  <div
                    className="custom-input-group "
                  // style={{ marginTop: i18n.language === 'frFR' ? "5px" : "28px" }}
                  >
                    <label htmlFor="name">
                      {t("form.Phone_Contact_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      id="receiver_contact_number"
                      onChange={handleChange}
                      name="receiver_contact_number"
                      value={data.receiver_contact_number}
                    />
                    {/* <span>{t("form.Delivery_communicated")}</span> */}
                    <span style={{ color: "red" }}>
                      {error.receiver_contact_number}
                    </span>
                    <div style={{ marginTop: '2px' }}>
                      <span style={{ color: "red", }}>{t('form.toreceive_code')}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.getting_your_luggage")}?
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body  ps-0">
                          <ul className="row">
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", false);
                                  setShow(true);
                                }}
                                checked={isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", true);
                                  setShow(false);
                                }}
                                checked={!isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Your_relationship_receiver")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      id="receiver_relation"
                      onChange={handleChange}
                      name="receiver_relation"
                      value={data.receiver_relation}
                    />
                    <span style={{ color: "red" }}>
                      {error.receiver_relation}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Location_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.location")}
                      id="location"
                      onChange={handleChange}
                      name="location"
                      onInput={onInputUppercase}
                      value={data.location}
                    />
                    <span style={{ color: "red" }}>{error.location}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Luggage_Sender_Type")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="sender_type"
                      onChange={handleChange}
                      name="sender_type"
                      value={data.sender_type ? data.sender_type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.Luggage_Sender_Type")}
                      </option>
                      <option value={1}>
                        {t("form.RegularI_send_luggages_reguler")}
                      </option>
                      <option value={2}>
                        {t("form.Periodic_send_luggages_periodically")}
                      </option>
                      <option value={3}>
                        {t("form.One_off_luggage_sender")}
                      </option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.sender_type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.detail_content_luggage_carried")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      rows={3}
                      id="description"
                      onChange={handleChange}
                      name="description"
                      value={data.description}
                      placeholder="Description"
                      maxLength={160}
                      style={{ borderRadius: "5px" }}
                    ></textarea>
                    {/* <span style={{ color: "red" }}>{error.description}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-lg-3 mt-lg-3">
                  <p>{t("profile.Luggage_images")}</p>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Exterior_1")}
                  </div>
                  <div
                    className="col-lg-12 "
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[0] ? (
                      <img
                        src={imageArray[0]}
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file"
                      onChange={(e) => {
                        convertToBase641(e, 0);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[0]}
                    </label>
                  </div>
                  {imageArray[0] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Exterior_2")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[1] ? (
                      <img
                        src={imageArray[1]}
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file2"
                      onChange={(e) => {
                        convertToBase641(e, 1);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[1]}
                    </label>
                  </div>
                  {imageArray[1] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Interior_1")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[2] ? (
                      <img
                        src={imageArray[2]}
                        for="file3"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file3"
                      onChange={(e) => {
                        convertToBase641(e, 2);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[2]}
                    </label>
                  </div>
                  {imageArray[2] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Interior_2")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[3] ? (
                      <img
                        src={imageArray[3]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        for="file"
                        role="button"
                      ></img>
                    ) : (
                      <label
                        for="file4"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file4"
                      onChange={(e) => {
                        convertToBase641(e, 3);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[3]}
                    </label>
                  </div>
                  {imageArray[3] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file4"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="package-sidebar">
                <aside
                  className="package-widget widget-tour-categoris mt-30"
                  style={{ boxShadow: "none" }}
                >
                  <div className="widget-body">
                    <div className="">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cate"
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                        checked={data.terms_and_condition}
                      />{" "}
                      <span>{t("form.I_CONFIRM_THAT")}</span>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="custom-input-group row col-lg-12">
                <div className=" col-sm-6 col-md-6 col-lg-">
                  <div className="submite-btn">
                    <button
                      type="submit"
                      disabled={!data.terms_and_condition}
                      onClick={handleSubmit}
                      className="btn btn-secondary"
                    >
                      {t("form.Preview_Advert")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12  mt-2">
              <p>
                {t("form.Note")}
                <br />
                {t("form.aerosol")}
                <br />
                {t("form.firearm")}
                <br />
                {t("form.ammunition")}
                <br />
                {t("form.weapon")}
                <br />
                {t("form.general")}
              </p>
            </div>
          </form>
        </>
      )}
    </>
  );
}
export default PostLuggageRequest;
