import axios from '../plugin/axios';

const payPal = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('User/GetUsers')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(id, data) {
        console.log("data",data);
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('PayPalsPayment/CreatePayPalId/' + id ,data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    CreateSubscribeHistory(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('PayPalsPayment/CreateSubscribeHistory', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createShopping(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('PayPalsPayment/CreatePayment/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createPaypalHistory(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('PayPalsPayment/ExecutePayment', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    // create(id,data) {
    //     return new Promise((resolve, reject) => {
    //         axios
    //             .post('PayPalsPayment/CreatePayPalId/'+id, data)
    //             .then(({ data }) => {
    //                 resolve(data);
    //             })
    //             .catch(({ response }) => {
    //                 reject(response);
    //             });
    //     });
    // },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('PayPalsPayment/GetPAyPalId', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('PayPalsPayment/UpdatePAyPalId', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Card_details/DeleteCard', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    basketMobileMoney(id){
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Payment_sent/ByBasketMobileMoney/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default payPal;
