import React from "react";
import { useTranslation } from "react-i18next";
import Breadcrumb from "../../common/Breadcrumb";
import ViewAdDetails from "./ViewAdDetails";
import { useParams } from "react-router-dom";

function Details() {
    const { id, type } = useParams();
    const { t } = useTranslation();
    
    return (
        <>
            <Breadcrumb
                name={
                    type === "4" ? t('form.Travel_Announcement_Details') : type === '5' ? t('form.Luggage_Request_Details') : type === '6' ? t('form.Articles_Sale') : type === '7' ? t('form.Cobagage_Articles_Donations') : ""
                }

            />
            <ViewAdDetails />
        </>
    );
}

export default Details;