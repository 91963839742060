import axios from '../plugin/axios';

const viewCount = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('SubscribeAds/Get')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(id, type) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('ViewCount/CreateViewCount/' + id + '/' + type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`ViewCount/GetAllCount/` + id, type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Article_donation', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation/ValidatePost', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default viewCount;