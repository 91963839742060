import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import { Button, Modal, } from 'react-bootstrap';
import moment from 'moment/moment';
import { useState } from 'react';
import Wallet from '../../../../models/Wallet';
import paymentSent from '../../../../models/paymentSent';
import AppContext from '../../../../store/context/app-context';

export default function EarnReports() {
    const { t } = useTranslation();
    const contextObj = useContext(AppContext);
    const [receivedItem, setReceivedItem] = useState([]);
    const [show, setShow] = useState(false);
    const [walletData, setWalletData] = useState();
    const [spendData, setSpendData] = useState([]);
    const [SpendMoney, setspendMoeny] = useState();
    const [totalErning, setTotalErning] = useState();
    const [isShow, setIsShow] = useState(false);

    useEffect(() => {
        WalletReceivedPayments();
        WalletGetByUser();
        getSpendData();
        getSpendMoeny();
        getTotalErning();
    }, [])

    const handleClose = () => setShow(false);
    const handleIsClose = () => setIsShow(false);


    const WalletReceivedPayments = () => {
        Wallet.ReceivedPayments().then((item) => {
            setReceivedItem(item);
        }).catch(error => {
            console.log("error", error);
        })
    }

    const WalletGetByUser = () => {
        Wallet.getBuyUserID().then((data) => {
            setWalletData(data);
        }).catch(error => {
            console.log("error", error);
        })
    }
    const getSpendData = () => {
        paymentSent.list().then((item) => {
            setSpendData(item);
        })
    }

    const getSpendMoeny = () => {
        paymentSent.SpendMoney().then((item) => {
            setspendMoeny(item);
        })
    }

    const getTotalErning = () => {
        Wallet.totalEarning().then((data) => {
            setTotalErning(data);
        }).catch(error => {
            console.log("error", error);
        })
    }

    const handleShow = (data) => {
        setShow(data);
    };
    const handleIsShow = (data) => { setIsShow(data) };

    return (
        <div>
            <div className='package-details-wrapper p-0' >
                <div className='tour-package-details'>
                    <div className="package-details-tabs row">
                        <ul
                            className="nav nav-pills  gap-xxl-4 gap-3"
                            id="pills-tab"
                            role="tablist"
                            style={{ marginBottom: '0px' }}

                        >
                            <li className="nav-item travel" role="presentation">
                                <button
                                    className="nav-link active"
                                    id="pills-package1"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pill-body1"
                                    type="button"
                                    role="tab"
                                    aria-controls="pill-body1"
                                    aria-selected="true"
                                    style={{ fontSize: '15px', borderRadius: '5px', padding: '8px 30px' }}
                                >
                                    {t("menu.Earnings_Report")}
                                </button>
                            </li>
                            <li className="nav-item travel" role="presentation">
                                <button
                                    className="nav-link"
                                    id="pills-package2"
                                    data-bs-toggle="pill"
                                    data-bs-target="#pill-body2"
                                    type="button"
                                    role="tab"
                                    aria-controls="pill-body2"
                                    aria-selected="false"
                                    style={{ fontSize: '15px', borderRadius: '5px' }}
                                >
                                    {t("menu.Spending_Reports")}
                                </button>
                            </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                            {/*   Earnings Report*/}
                            <div
                                className="tab-pane fade show active package-info-tab pt-4"
                                id="pill-body1"
                                role="tabpanel"
                                aria-labelledby="pills-package1"
                            >
                                <h5 className='fw-bold'>{t("menu.Total_Net_Earnings")}: {totalErning?.totle_errning.toFixed(2)}{contextObj?.loginUser?.symbol}</h5>
                                {receivedItem && <div class="container package-card-delta card mt-5 pb-2" style={{ overflowX: 'auto' }}>
                                    <table class="table table-borderless table-responsive card-1 p-4 mt-4">
                                        {receivedItem.map((item) => {
                                            return (
                                                <thead>
                                                    <tr class="border-bottom">
                                                        <th className='d-flex justify-content-center'>
                                                            <i class="bi bi-arrow-down-circle fw-bold" style={{ color: 'green', fontSize: '32px' }} />
                                                        </th>
                                                        <th>
                                                            <span class="ml-2" style={{ color: 'green' }}> {t("menu.Received")}</span><br />
                                                            <span class="ml-2 fw-normal">{moment(item.created_at).format('YYYY-MM-DD')}</span>
                                                        </th>
                                                        <th>
                                                            <span class="ml-2" style={{ color: 'green' }}>+ {item.net_amount} {contextObj?.loginUser?.symbol}</span><br />
                                                            <span class="ml-2 fw-normal">{t("profile.Money_received_from")} {item.sender_name}</span>
                                                        </th>
                                                        <th>
                                                            <span class="ml-2 d-flex justify-content-center mb-2"><i onClick={() => { handleShow(item) }} class="bi bi-eye-fill" role='button' /></span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            )
                                        })}

                                    </table>
                                    {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" >1</a></li>
                                            <li class="page-item"><a class="page-link" >2</a></li>
                                            <li class="page-item"><a class="page-link" >3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>}
                                <Modal show={show} onHide={handleClose}
                                    size="lg"
                                    centered
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title><i class="bi bi-arrow-down-circle-fill" style={{ color: "green", fontSize: "30px" }} /> {' '}{t('profile.received')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className=''>
                                            <div className='row'>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('menu.Transaction_Date')} {t('profile.time')}:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{moment(show?.updated_at)?.format('DD/MM/YYYY')} | {moment(show?.updated_at)?.format('hh:mm a')}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>Description:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{show?.request_type == 1 ? t('profile.money_received_for_carrying_luggage') : t('profile.money_received_from_sold_article')}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('profile.received_from')}:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{show?.sender_name}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('profile.amount')} {t("menu.Received")}:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{show?.amount_recived}{contextObj?.loginUser?.symbol}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}> {t("menu.Cobagage_fees")}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{show?.cobagage_fess} {contextObj?.loginUser?.symbol}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t("menu.Net_Amount_earned")}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{show?.net_amount} {contextObj?.loginUser?.symbol}</div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                            {/*  Spending Reports */}
                            <div
                                className="tab-pane fade package-info-tab pt-4"
                                id="pill-body2"
                                role="tabpanel"
                                aria-labelledby="pills-package2"
                                style={{ overflowX: 'auto' }}
                            >
                                <h5 className='fw-bold'>{t('menu.Total_Spendings')} {SpendMoney?.spendingMoney.toFixed(2)} {contextObj?.loginUser?.symbol}</h5>
                                {spendData && <div class="container package-card-delta card mt-5 pb-2" style={{ overflowX: 'auto' }}>
                                    <table class="table table-borderless table-responsive card-1 p-4 mt-4">
                                        {spendData.map((item) => {
                                            return (
                                                <thead>
                                                    <tr class="border-bottom">
                                                        <th>
                                                            <i class="bi bi-arrow-up-circle fw-bold" style={{ color: 'red', fontSize: '32px' }} />
                                                        </th>
                                                        <th>
                                                            <span class="ml-2" style={{ color: 'red' }}>{t('menu.Sent')}</span><br />
                                                            <span class="ml-2 fw-normal">{moment(item.created_at).format('YYYY-MM-DD')}</span>
                                                        </th>
                                                        <th>
                                                            <span class="ml-2" style={{ color: 'red' }}>{item.payer_amount} {contextObj?.loginUser?.symbol}</span><br />
                                                            <span class="ml-2 fw-normal">{t('profile.Money_paid_for_sending')}{" "}{item?.product_type == 1 ? t('menu.luggages') : item?.product_type == 2 ? t('menu.travels') : item?.product_type == 3 ? t('menu.sell_article') : item?.product_type == 4 ? t('menu.article_donation') : ""}</span>
                                                        </th>
                                                        <th>
                                                            <span class="ml-2 d-flex justify-content-center mb-2"><i class="bi bi-eye-fill" style={{ cursor: 'pointer' }} onClick={() => { handleIsShow(item) }} /></span>
                                                        </th>
                                                    </tr>
                                                </thead>
                                            )
                                        })}
                                    </table>

                                    {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" >1</a></li>
                                            <li class="page-item"><a class="page-link" >2</a></li>
                                            <li class="page-item"><a class="page-link" >3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav> */}
                                </div>}
                                <Modal show={isShow} onHide={handleIsClose}
                                    size="lg"
                                    centered
                                >
                                    {console.log("isShow",isShow)}
                                    <Modal.Header closeButton>
                                        <Modal.Title><i class="bi bi-arrow-up-circle fw-bold" style={{ color: "red", fontSize: "30px" }} /> {' '}{t('menu.Sent')}</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div className=''>
                                            <div className='row'>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('menu.Transaction_Date')}{t('profile.time')}:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{moment(isShow?.updated_at)?.format('DD/MM/YYYY')} | {moment(receivedItem?.updated_at)?.format('hh:mm a')}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>Description:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{t('profile.Money_paid_for_sending')}{" "}{isShow?.product_type == 1 ? t('menu.luggages') : isShow?.product_type == 2 ? "travel" : isShow?.product_type == 3 ? t('menu.sell_article') : isShow?.product_type == 4 ? "artical_donation" : ""}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('menu.Paid_to')}:</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{isShow?.paid_to}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('menu.Luggage_carrying_cost')}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{isShow?.price}{contextObj?.loginUser?.symbol}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}> {t("menu.Cobagage_fees")}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{isShow?.transaction_charges}{contextObj?.loginUser?.symbol}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>{t('menu.Total_Amount_paid')}</div>
                                                <div className='col-lg-6' style={{ padding: "5px", }}>{isShow?.payer_amount}{contextObj?.loginUser?.symbol}</div>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                </Modal>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
