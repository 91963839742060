
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PaymentWrap() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='pt-110  pb-110'>
                <h1 style={{ fontWeight: 'bold' }} className='text-center '>{t('form.Paiement_sécurisé')}</h1>
                <p className='pt-4 fs-5' style={{ fontWeight: '500' }}>
                    "{t('form.En_transactions')}".
                </p>

                <p className='fs-5 pt-2'>
                    {t('form.Parce_circonstances')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Nous_assurer')} <span className='fs-5' style={{ fontWeight: '500' }}> {t('form.un_fiable')}</span> {t('form.et_contrainte')}.
                </p>
                <h2 className='fs-5 pt-2'>
                    {t('form.Comment_sécurisé')}
                </h2>
                <p className='fs-5 pt-2'>
                    {t('form.Il_risque')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Chez_honneur')}<span className='fs-5' style={{ fontWeight: "bold" }}>{t('form.sécuriser_clients')}</span>
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Tous_de')} <strong>{t('form.earn_recipient')}</strong>.
                </p>
                <h2 className='mt-5' style={{ fontWeight: 'bold' }}>{t('form.Faire_cobagage')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Gagner_site_Cobagage')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Il_coûteux')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Calling_cost')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.De_par')} <strong>{t('form.le_transport')}</strong>.
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Par_bagages')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_prix_HT')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_HTcommission')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Une_côté_tarifs')}
                </p>
                <h2 className='mt-5' style={{ fontWeight: 'bold' }}>{t('form.Les_conditions')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Les_envois')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Néanmoins_confiscation')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Les_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    <strong>{t('form.L_international')}</strong> {t('form.n_Cobagage')}
                </p>
            </div>
        </div>
    )
}
