import React from "react";
import LuggageCarryingWrapperArea from "./LuggageCarryingWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
function LuggageCarrying() {
  const { id, request_type, sent, order, product_id } = useParams();
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={order=="3"?t('profile.Luggage_sending_order_Details_In_progress'):t('profile.luggage_carrying_order_detailes_In_Progress')}/>
      <LuggageCarryingWrapperArea />
    </>
  );
}

export default LuggageCarrying;
