import React from "react";
import BradcomeContact from "../../common/BradcomeContact";
import ContactWrapperArea from "./ContactWrapperArea";

function Contact() {
  return (
    <>
      <BradcomeContact name="Contact" />
      <ContactWrapperArea />
    </>
  );
}

export default Contact;
