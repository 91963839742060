import React from 'react'
import { useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import notistack from "../../../plugin/notistack";
import articlesDonation from '../../../models/articlesDonation';

const defaultData = {
    Price: '',
    Ads_Category: '',
    Quantity: '',
    Weight: '',
    Type: '',
    Tags: '',
    Status: '',
    First_Owner: '',
    Material: '',
    Color: '',
    Height: '',
    Width: '',
    Length: '',
    Description: '',
    title: '',
    location: ''
}
export default function Article_Donation() {
    const { id } = useParams();
    const { type_of_travel } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [data, setData] = useState(defaultData);

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetData(name, value);
    }
    const handleSetData = (name, value) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const handleSubmit = (event) => {
        data.type_of_travel = type_of_travel
        articlesDonation.create(id, data).then(() => {
            setData(defaultData);
            console.log(data);
            notistack.success("Article Submitted...");
            history.push(type_of_travel == 1 ? `/luggageAds` : `/cobagageAds`);
        })
    }
    const numberInputOnWheelPreventChange = (e) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
          e.target.focus()
        }, 0)
      }
    return (
        <div className="">
            <div className="contact-form-wrap">
                <h4>{t("form.add_article_donation")}</h4>
                {/* <p>
          {t("auth.required_fields_are_marked")} *
        </p> */}
                <div className="row">
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.title")}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                required
                                type="text"
                                placeholder={t("form.title")}
                                id="title"
                                onChange={handleChange}
                                name='title'
                                value={data.title}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.location")}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                required
                                type="text"
                                placeholder=""
                                id="length"
                                onChange={handleChange}
                                name='location'
                                value={data.location}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.price_per_item")}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                required
                                type="number"
                                onWheel={numberInputOnWheelPreventChange}
                                // placeholder="Title"
                                id="Price"
                                onChange={handleChange}
                                name='Price'
                                value={data.Price}
                                // defaultValue={0}
                                readOnly
                            />
                            <span style={{ position: 'absolute', right: 20, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>€</span>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.ads_category")}</label>
                            <select
                                id="Ads_Category"
                                onChange={handleChange}
                                name='Ads_Category'
                                value={data.Ads_Category}
                            >
                                <option value={''} selected disabled>{t("form.select_ads_category")}</option>
                                <option>Accessoires de beauté pour hommes</option>
                                <option>Accessoires de beauté pour femmes</option>
                                <option>Œuvres D'art</option>
                                <option>Objets Connectés</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.quantity_number_of_item")}<span style={{ color: 'red' }}>*</span></label>
                            <input
                                required
                                type="number"
                                onWheel={numberInputOnWheelPreventChange}
                                placeholder=""
                                id="Quantity"
                                onChange={handleChange}
                                name='Quantity'
                                value={data.Quantity}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="custom-input-group ">
                            <label htmlFor="name">{t("form.weight_kg")}</label>
                            <input
                                required
                                type="number"
                                onWheel={numberInputOnWheelPreventChange}
                                placeholder=""
                                id="weight"
                                onChange={handleChange}
                                name='Weight'
                                value={data.Weight}
                            />
                            <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>kg</span>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.type")}</label>
                            <input
                                required
                                type="text"
                                placeholder=""
                                id="Type"
                                onChange={handleChange}
                                name='Type'
                                value={data.Type}
                            />
                        </div>
                    </div>
                    <div className="col-12 col-lg-6">
                        <div className="custom-input-group ">
                            <label htmlFor="name">{t("form.tags")}</label>
                            <input
                                required
                                type="text"
                                placeholder=""
                                id="Tags"
                                onChange={handleChange}
                                name='Tags'
                                value={data.Tags}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.status")}</label>
                            <select
                                id="Status"
                                onChange={handleChange}
                                name='Status'
                                value={data.Status}
                            >
                                <option value={''} selected disabled>{t("form.status")}</option>
                                <option>{t("form.new")}</option>
                                <option>{t("form.used")}</option>
                            </select>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="custom-input-group ">
                            <label htmlFor="name">{t("form.first_owner")}</label>
                            <select
                                id="First_Owner"
                                onChange={handleChange}
                                name='First_Owner'
                                value={data.First_Owner}
                            >
                                <option value={''} selected disabled>{t("form.first_owner")}</option>
                                <option>{t("form.yes")}</option>
                                <option>{t("form.no")}</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="custom-input-group">
                            <label htmlFor="name">{t("form.material")}</label>
                            <input
                                required
                                type="text"
                                // placeholder="Enter Pick Up Place"
                                id="Material"
                                onChange={handleChange}
                                name='Material'
                                value={data.Material}
                            />
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="custom-input-group ">
                            <label htmlFor="name">{t("form.color")}</label>
                            <input
                                required
                                type="text"
                                // placeholder="Enter Delivery Place"
                                id="Color"
                                onChange={handleChange}
                                name='Color'
                                value={data.Color}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
                        <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }} >
                            <div className="custom-input-group">
                                <label htmlFor="name">{t("form.height")}</label>
                                <input
                                    required
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    placeholder=""
                                    id="height"
                                    onChange={handleChange}
                                    name='Height'
                                    value={data.Height}
                                />
                                <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                            <div className="custom-input-group">
                                <label htmlFor="name">{t("form.width")}</label>
                                <input
                                    required
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    placeholder=""
                                    id="Width"
                                    onChange={handleChange}
                                    name='Width'
                                    value={data.Width}
                                />
                                <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span>
                            </div>
                        </div>
                        <div className="col-12 col-sm-4 " style={{ paddingRight: '0px' }}>
                            <div className="custom-input-group">
                                <label htmlFor="name">{t("form.length")}</label>
                                <input
                                    required
                                    type="number"
                                    onWheel={numberInputOnWheelPreventChange}
                                    placeholder=""
                                    id="length"
                                    onChange={handleChange}
                                    name='Length'
                                    value={data.Length}
                                />
                                <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="custom-input-group">
                            <label htmlFor="name">Description</label>
                            <textarea rows={4}
                                id="Description"
                                onChange={handleChange}
                                name='Description'
                                value={data.Description}
                            >
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="custom-input-group">
                            <h3>{t("menu.gallery")} ({t("form.Upload_image_in_1280_720_dimension")}) </h3><br />
                            <label htmlFor="name" style={{ color: '#ff4838' }}>{t("form.You_can_add_4_images_maximum")}</label>
                            <input
                                style={{ height: "130px", borderStyle: 'dashed' }}
                                onChange={handleChange}
                                // value={data.Description}
                                type="file"
                                multiple accept="gif|jpg|png|jpeg" />
                            {/* <span style={{ position: 'absolute', right: "50%", bottom: '25%', fontWeight: '500', opacity: 0.65 }}> Click or drag images here</span>
              <span class="bi bi-plus-circle" style={{ position: 'absolute', right: "63%", bottom: '55px', fontWeight: '500' }}></span> */}
                        </div>
                    </div>
                </div>
                <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-12 col-md-12 ">
                        <div className="submite-btn" >
                            <button type="submit" style={{ fontSize: '13px', margin: '2px' }}>{t("form.submit_article_donation_and_add_more")}</button>
                            <button type="submit" onClick={handleSubmit} style={{ fontSize: '13px', margin: '5px' }}>{t("form.confirm_the_gift")}</button>
                            <Link to={`/cobagageAds`}> <button type="submit" style={{ fontSize: '13px', margin: '5px' }}>{t("form.exit")}</button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
