import React, { useState } from "react";
import LuggageAdsWrapperArea from "./LuggageAdsWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function LuggageAds() {
  const { t } = useTranslation();


  return (
    <>
      <Breadcrumb name={t("cobagage.luggage_ads")}/>
      <LuggageAdsWrapperArea/>

    </>
  );
}

export default LuggageAds;
