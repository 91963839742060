import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";

export default function FaqPoster(props) {
    const { t } = useTranslation();

    return (
        <div className="breadcrumb breadcrumb-style-ten">
            <div className="container">
                <div className="col-lg-12 text-center">
                    <h1 className="breadcrumb-title">{props.name}</h1>
                    <ul className="d-flex justify-content-center breadcrumb-items">
                        <li className="breadcrumb-item">
                            <Link to={`${process.env.PUBLIC_URL}/`}> {t("menu.home")}</Link>
                        </li>
                        <li className="breadcrumb-item active">{props.name}</li>
                    </ul>
                </div>
            </div>
        </div>
    )
}
