const common = {
    action: 'Action',
    delete: 'Delete',
    duplicate: 'Duplicate',
    cancel: 'Cancel',
    save: 'Save',
    update: 'Update',
    edit:'Edit',
    close:'Close',

    disagree: 'Disagree',
    agree: 'Agree',
    delete_data: 'Delete data?',
    delete_data_message: 'Are you sure you want to delete this data?',
   
   
};
export default common;
