import React, { useState } from "react";
import BlogArea3 from "../homeThree/BlogArea3";
import MoreUpdate from "../homeThree/MoreUpdate";
import OfferPackage from "../homeThree/OfferPackage";
import Testimonial3 from "../homeThree/Testimonial3";
import TopDestination from "../homeThree/TopDestination";
import Tourguide3 from "../homeThree/Tourguide3";
import UpcomingTour from "../homeThree/UpcomingTour";
import HeroFourArea from "./HeroFourArea";
import PackageArea from "./PackageArea";
import SearchBarFour from "./SearchBarFour";
import Adcomponent from "./Adcomponent";
import NewHomePage from "./NewHomePage";


function HomeFour() {
  // const [open, setopen] = useState(false)
  // const [frist, setFristPage] = useState(window.sessionStorage.getItem('firstPage'))

  return (
    <>

      <>
        <HeroFourArea />
        <SearchBarFour />
        <PackageArea
        // open={open}
        // frist={frist}
        />
        <MoreUpdate />
        <UpcomingTour />
        <Testimonial3 />
        <TopDestination />
        <Tourguide3 />
        <BlogArea3 />
        <Adcomponent />
      </>


      
      {/* <OfferPackage /> */}

    </>
  );
}

export default HomeFour;
