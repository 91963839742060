import React, { useContext, useEffect, useState, useRef } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from "react-bootstrap";
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "../../../../../models/request";
import luggage from "../../../../../models/luggage";
import user from "../../../../../models/user";
import travel from "../../../../../models/travel";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";
import { useReactToPrint } from "react-to-print";
import moment from "moment";
function InvoiceWrapperArea() {
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const { order } = useParams();
  const { t } = useTranslation();
  const { id } = useParams();
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });
  const { request_type } = useParams();
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  const [show, setShow] = useState(false);
  const [open, SetIsOpen] = useState();
  const [validator, showMessage] = useValidator();
  const [otpValidator, showMessageOtp] = useValidator();
  const [users, setUser] = useState();
  const [travelData, setTravel] = useState();
  const [data, setData] = useState({
    request_id: id,
    request_type: request_type,
    status: "",
    reason_to_cancel: "",
    cancel_by: "2",
    Delivery_otp: "",
    traveler_signature: "",
  });
  const error = {
    reason_to_cancel: validator.message(
      "Reason to Reject",
      data.reason_to_cancel,
      (open == 1 ? "required|" : "") + "string|max:200"
    ),
  };
  const OtpError = {
    Delivery_otp: otpValidator.message(
      "OTP",
      data.Delivery_otp,
      (order == 2 ? "required|" : "") + "string|max:200"
    ),
  };
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    if (request_type == 1) {
      if (order == 1) {
        request
          .getRequestReceived(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
            getUser(item.travel_user_id);
            getTravel(item.travel_id);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else if (order == 2) {
        request
          .get(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
            getUser(item.travel_user_id);
            getTravel(item.travel_id);
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    } else {
      if (order == 2) {
        request
          .getRequestReceived(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
            getUser(item.travel_user_id);
            getTravel(item.travel_id);
          })
          .catch((error) => {
            console.log("error", error);
          });
      } else {
        request
          .get(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
            // getUser(item.traveler_user_id)
            // getTravel(item.travel_id)
          })
          .catch((error) => {
            console.log("error", error);
          });
      }
    }
  };
  const cancleOrder = () => {
    if (validator.allValid()) {
      request
        .cancle(data)
        .then((item) => {
          SetIsOpen(2);
        })
        .catch((error) => {
          notistack.error(error?.response?.data?.message);
          console.log("error", error);
        });
    } else {
      showMessage(true);
    }
  };
  const handleSignature = () => {
    request
      .uploadSignature(data?.request_id, data?.request_type)
      .then((response) => {})
      .catch((error) => {
        notistack.error(error.response.data.message);
      });
  };
  const handleOtp = () => {
    if (otpValidator.allValid()) {
      request
        .otpVerify(data?.Delivery_otp, data?.request_id, data?.request_type)
        .then((response) => {})
        .catch((error) => {
          notistack.error(error?.response?.data?.message);
        });
    } else {
      showMessageOtp(true);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setShow(false);
    handleSetData("reason_to_cancel", "");
    showMessage(false);
  };
  const handleShow = () => setShow(true);
  const tabPanelValue = () => {
    contextObj.setTabValue(7);
    history.push("/myProfile");
  };

  const getUser = (id) => {
    user
      .get(id)
      .then((item) => {
        setUser(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getTravel = (id) => {
    travel
      .get(id)
      .then((item) => {
        setTravel(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <>
      <div class="p-lg-5 p-md-4 p-sm-1">
        <div class="container">
          <div className="text-end pb-2 pt-2 d-flex justify-content-between">
            <Button
              style={{
                backgroundColor: "#ff4838",
                borderColor: "#ff4838",
                borderRadius: "5px",
                border: "0px",
                color: "white",
                marginLeft: "0px",

              }}
              onClick={() => tabPanelValue()}
            >
              <i class="bi bi-arrow-left-circle" />{" "}
              {t("profile.back_to_luggage_carrying_order_list")}
            </Button>
            <a
              class="btn btn-success text-end"
              data-abc="true"
              onClick={handlePrint}
            >
              <i class="fa fa-usd"></i> {t("profile.Print_Invoice")}
            </a>
          </div>

          <div data-select2-id="ui-view">
            <div class="card" ref={componentRef}>
              <div class="invoice-header">
                <span className="fw-bold fs-4">
                  {order == 1
                    ? t("profile.Invoice_for_carrying_luggage")
                    : t("profile.Invoice_for_sending_luggage")}
                </span>
                <br />
                <span>
                  {t("profile.Invoice_Date")}:
                  <span className="fw-bold">
                    {moment(new Date()).format("DD/MM/YYYY")}
                  </span>
                </span>
              </div>
              <div class="card-body">
                <div class="row mb-4">
                  <div class="col-sm-4">
                    {/* <h6 class="mb-3">From:</h6> */}
                    <img
                      src={
                        t("profile.luggages") == "Luggages"
                          ? "/images/CobagageLogo.png"
                          : "/images/CobagageLogo4.png"
                      }
                      height={"45px"}
                      style={{ marginBottom: "10px" }}
                    />
                    <div>
                      <strong>
                        <a
                          href="www.cobagage.com"
                          target="_blank"
                          style={{ color: "#212529" }}
                        >
                          www.cobagage.com
                        </a>
                      </strong>
                    </div>
                    <div>cobagagecontact@cobagage.com</div>
                    <div>
                      78 Av. des Champs-Élysées <br />
                      Paris 75008 – France
                    </div>
                    {/* <div>Email: admin@bbbootstrap.com</div>
                      <div>Phone: +48 123 456 789</div> */}
                  </div>
                  <div class="col-sm-4" />
                  <div class="col-sm-4">
                    <h6 class="mb-3">To:</h6>
                    {LuggageRequest?.travel_user_name ? (
                      <div>
                        <div>
                          {order == 1 ? (
                            <strong>
                              {LuggageRequest?.travel_user_name} from{" "}
                              {travelData?.title}
                            </strong>
                          ) : (
                            <strong>{users?.full_name}</strong>
                          )}
                        </div>
                        <div>{users?.email}</div>
                        <div>{users?.contact_number}</div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          {order == 1 ? (
                            <strong>
                              {LuggageRequest?.traveler_name} from{" "}
                              {LuggageRequest?.title}
                            </strong>
                          ) : (
                            <strong>{LuggageRequest?.traveler_name}</strong>
                          )}
                        </div>
                        <div>{LuggageRequest?.email}</div>
                        <div>{LuggageRequest?.contact_number}</div>
                      </div>
                    )}
                  </div>
                </div>

                <div class="row">
                  <div class="table-responsive-sm col-6">
                    <div class="mb-4 thead">
                      <p>
                        {t("profile.Invoice_Amount")}:{" "}
                        {order == 1
                          ? LuggageRequest?.earning
                          : LuggageRequest?.paid_amount
                          ? LuggageRequest?.paid_amount
                          : LuggageRequest?.total_fees}
                        {LuggageRequest?.currency}
                      </p>
                    </div>
                    <table class="table table-striped">
                      <tr>
                        <th>{t("profile.Luggage_Title")}</th>
                        <td>
                          {LuggageRequest?.title
                            ? LuggageRequest?.title
                            : LuggageRequest?.luggage_title}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("profile.Drop_off_Destination")}</th>
                        <td>
                          {LuggageRequest?.location
                            ? LuggageRequest?.location
                            : LuggageRequest?.delivery_place}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("profile.Delivery_Date")}</th>
                        <td>{LuggageRequest?.updated_at?.slice(0, 10)}</td>
                      </tr>
                      <tr>
                        <th class="right">
                          {order == 1
                            ? t("profile.Luggage_Sender")
                            : t("profile.Luggage_sent_by")}
                        </th>
                        <td class="right">
                          {LuggageRequest?.request_user_name
                            ? LuggageRequest?.request_user_name
                            : LuggageRequest?.luggager_name}
                        </td>
                      </tr>
                    </table>
                  </div>
                  <div class="col-6">
                    <div class="mb-4 thead">
                      <p>
                        {t("profile.Invoice_type")}:{" "}
                        {order == 1 ? "credit" : "debit"}
                      </p>
                    </div>
                    <table class="table table-striped">
                      <tr>
                        <th>{t("profile.Luggage_Weight")}</th>
                        <td>
                          {LuggageRequest?.weight
                            ? LuggageRequest?.weight
                            : LuggageRequest?.luggage_weight}
                          Kg
                        </td>
                      </tr>
                      <tr>
                        <th>{t("profile.Unit_Price_Kg")}</th>
                        <td>
                          {LuggageRequest?.price_per_kg}
                          {LuggageRequest?.currency}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("profile.Cost_to_carry_Luggage")}</th>
                        <td>
                          {LuggageRequest?.fees}
                          {LuggageRequest?.currency}
                        </td>
                      </tr>
                      <tr>
                        <th>{t("profile.Admin_Fees")}</th>
                        <td>
                          {order == 1
                            ? LuggageRequest?.service_fee_travel
                              ? LuggageRequest?.service_fee_travel
                              : LuggageRequest?.service_fee
                            : LuggageRequest?.service_fee}
                          {LuggageRequest?.currency}
                        </td>
                      </tr>
                      <tr>
                        <th>
                          {order == 1
                            ? t("profile.Net_Earnings")
                            : t("profile.Total_Paid_Amount")}
                        </th>
                        <th>
                          {order == 1
                            ? LuggageRequest?.earning
                            : LuggageRequest?.paid_amount
                            ? LuggageRequest?.paid_amount
                            : LuggageRequest?.total_fees}
                          {LuggageRequest?.currency}
                        </th>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default InvoiceWrapperArea;
