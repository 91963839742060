import React, { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import contact from "../../../models/contact";
import { auth } from '../../../models';
import notistack from "../../../plugin/notistack";
import { useTranslation } from 'react-i18next';
import useValidator from "../../../plugin/validator";
import AppContext from "../../../store/context/app-context";
function ContactWrapperArea() {
  const contextObj = useContext(AppContext)

  const resetData = {
    name: "",
    email: "",
    subject: "",
    message: ""
  }
  const [data, setData] = useState({
    name: "",
    email: "",
    subject: "",
    message: ""
  })
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  useEffect(() => {
    getAuthUser()
  }, [])
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const error = {
    name: validator.message(t('name'), data.name, 'required'),
    email: validator.message(t('email'), data.email, 'required'),
    subject: validator.message(t('subject'), data.subject, 'required'),
    message: validator.message(t('message'), data.message, 'required'),
  }

  const handleSubmite = () => {
    if (validator.allValid()) {
      contact.create(data).then((respons) => {
        notistack.success(t('form.Request_successfully'))
        setData({
          subject: "",
          message: ""
        })
        getAuthUser();
      })
        .catch((error) => {
          console.log("contact error", error);
        })
    }
    else {
      showMessage(true);
    }
  }
  const getAuthUser = () => auth.getUser().then((data) => {
    handleSetData("name", data.full_name)
    handleSetData("email", data.email)
  }).catch((error) => {
    console.log('Here is error in app context');
  })
  return (
    <>
      <div className="contact-wrapper pt-110">
        <div className="container">
          <div className="row align-items-center justify-content-lg-center gy-5">
            <div className="col-lg-6">
              <div className="contatc-intro-figure">
                <img
                  src={process.env.PUBLIC_URL + "/images/banner/contact-bg.png"}
                  alt="ContactBannerIMG"
                  className="img-fluid"
                />
              </div>
            </div>
            <div className="col-lg-5">
              <div className="contact-info">
                <h3>{t('form.Contact_Info')}</h3>
                <ul>
                  <li>
                    <h6>{t('form.Lets_Talk')}</h6>
                    <span><i class="bi bi-envelope" />{' '}cobagagecontact@cobagage.com</span>
                    <span><i class="bi bi-whatsapp" /> +336 75 68 10 28<br />USA: +1 334 635 0647</span>
                    <span>{' '} {t('form.For_networks')} : reseausocial@cobagage.com</span>
                    <span
                    // rel="noopener noreferrer"
                    // target="_blank"
                    // href="tel:+336 51 67 00 90"
                    >
                      {t('form.For_payments')} :{' '}<i class="bi bi-credit-card" />{' '} networkpayment@cobagage.com
                    </span>
                    <span
                    >
                      {t("form.Direction")} :{' '}{' '} lyao@cobagage.com
                    </span>
                  </li>
                  <li>
                    <h6><i class="bi bi-geo-alt" />{' '}{t('form.Head_office')}</h6>
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href="https://maps.app.goo.gl/koUzWZ8gS3ymrKxz7"
                    >
                      {t("form.address2")} <br />
                      <i class="bi bi-telephone" />{' '}: +1 334 635 0647 <br />
                      CEO : Ludovic YAO
                    </a>
                  </li>
                  <li>
                    <h6><i class="bi bi-geo-alt" />{' '}{t('form.Address')}:</h6>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/Qyk8fWGRvZ781dM57"
                    >
                      {t("form.address1")}
                    </a>
                  </li>
                  <li>
                    <h6><i class="bi bi-geo-alt" />{' '}{t('form.Address')}:</h6>
                    <a
                      target="_blank"
                      href="https://maps.app.goo.gl/cWDHgCsXDB6tqZzWA"
                    >
                      {t("form.address3")}
                    </a>
                  </li>
                  <li>
                    <h6>{t('form.Visit_Us')}.</h6>
                    <div className="row">
                      <div className="col-2 mt-4">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/"
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/facebook.png"
                            }
                            alt="facebook Logo"
                            height={35}
                            width={35}
                          />
                        </a>
                      </div>
                      <div className="col-2 mt-4">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/cobagage_officiel/?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr"
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/instagram.png"
                            }
                            alt="Instagram Logo"
                            height={35}
                            width={35}
                          />
                        </a>
                      </div>
                      <div className="col-2 mt-4">
                        <a
                          rel="noopener noreferrer"
                          href="https://twitter.com/CobagageSite"
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL + "/images/icons/twitter.png"
                            }
                            alt="Twitter Logo"
                            height={35}
                            width={35}
                          />
                        </a>
                      </div>
                      <div className="col-2 mt-4">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/in/cobagage-network-11abb427a/"
                          target="_blank"
                          to={"#"}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/linkedin.png"
                            }
                            alt="linkedin Logo"
                            height={35}
                            width={35}
                          />
                        </a>
                      </div>
                    </div>



                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="container-fluid p-0">
          <div className="contact-map mt-120">
            <div className="mapouter">
              <div className="gmap_canvas">
                <iframe
                  title="map"
                  id="gmap_canvas"
                  src={
                    "https://maps.google.com/maps?q=2880%20Broadway,%20New%20York&t=&z=9&ie=UTF8&iwloc=&output=embed"
                  }
                />
                <Link to={"https://123movies-to.org"} />
                <br />
              </div>
            </div>
          </div>
        </div> */}
        <div className="container pt-110">
          <form onSubmit={(e) => e.preventDefault()} id="contact_form">
            <div className="contact-form-wrap">
              <h4>{t('form.Contact_us')}</h4>
              {/* <p>
                Your email address will not be published. Required fields are
                marked *
              </p> */}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t('form.Name')}<span style={{ color: 'red' }}> *</span></label>
                    <input
                      required
                      name="name"
                      type="text"
                      placeholder={t('form.Name')}
                      id="name"
                      onChange={handleChange}
                      value={data.name}
                    />
                    <span style={{ color: "red" }}>{error.name}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="email">{t('profile.email')}<span style={{ color: 'red' }}> *</span></label>
                    <input
                      required
                      type="text"
                      placeholder={t('profile.email')}
                      id="email"
                      name="email"
                      onChange={handleChange}
                      value={data.email}
                    />
                    <span style={{ color: "red" }}>{error.email}</span>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="custom-input-group">
                    <label htmlFor="">{t('form.Subject')}<span style={{ color: 'red' }}> *</span></label>
                    <input
                      required
                      type="text"
                      placeholder={t('form.Subject')}
                      id="subject"
                      name="subject"
                      onChange={handleChange}
                      value={data.subject}
                    />
                    <span style={{ color: "red" }}>{error.subject}</span>
                  </div>
                </div>
              </div>
              <div className="custom-input-group">
                <textarea
                  cols={20}
                  rows={6}
                  id="message"
                  name="message"
                  placeholder="Message"
                  maxLength={160}
                  onChange={handleChange}
                  value={data.message}
                  style={{ borderRadius: "5px" }}
                />
                <span style={{ color: "red" }}>{error.message}</span>
              </div>
              <div className="custom-input-group">
                <div className="submite-btn">
                  <button type="submit"
                    onClick={handleSubmite}
                  >
                    {t('form.Send_Message')}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default ContactWrapperArea;
