const auth = {
    email: 'Email ',
    password: 'Mot de passe',
    old_password: 'Ancien mot de passe',
    new_Password:'Nouveau mot de passe',
    confirm_password: 'Confirmez votre mot de passe',
    enter_confirm_password: 'Confirmez le mot de passe',
    enter_your_email:'Saisissez votre adresse e-mail',
    login: 'Connexion',
    same_as_password:'Identique au mot de passe',
    first_time_login:'Première connexion',
    regular_user:" Utilisateur régulier",
    required_fields_are_marked: 'Les champs obligatoires sont marqués ',
    remember_me: 'Se souvenir de moi',
    login_forgot_password: 'Connexion mot de passe oublié',
    forgot_password: 'Mot de passe oublié',
    already_have_an_account: 'Vous avez déjà un compte ?',
    password_reset: 'Réinitialisation du mot de passe',
    email_verify_message: "Merci de vous être inscrit ! Avant de commencer, pourriez-vous vérifier votre adresse électronique en cliquant sur le lien que nous venons de vous envoyer ? Si vous n'avez pas reçu d'e-mail, nous vous en enverrons un autre avec plaisir.",
    resend_verification_email: "Renvoyer l'e-mail de vérification",
    password_changed_successfully: 'Le mot de passe a été modifié avec succès',
    profile_updated_successfully: 'Mise à jour du profil réussi',
    reset_link_is_sent_to_your_email: 'Un lien de réinitialisation est envoyé à votre adresse électronique',
    enter_password: 'Saisir le mot de passe',
    login_through_social_media: 'Se connecter via les médias sociaux',
    register_through_socil_media: "S'inscrire via les médias sociaux",
    click_here: 'Cliquez ici.',
    register: "S'inscrire",
    what_type_of_account_would_you_like_to_create:"Quel type de compte souhaitez-vous créer ?",
    individual_account:"Compte particulier",
    company_account:"Compte entreprise",
    full_Name: 'Nom complet',
    country: 'Pays',
    enter_country_name: 'Saisir le nom du pays',
    user_name: "Nom de l'utilisateur",
    enter_user_name: "Entrer le nom d'utilisateur",
    phone: 'Téléphone',
    mobile_phone :'Téléphone mobile ',
    fixed_phone_landline:'Téléphone fixe/ligne fixe',
    company_name:"Nom de l'entreprise",
    company_contacts_name:"Nom du contact de l'entreprise",
    official_comapany_number_wich_can_be_verified:"Numéro officiel de l'entreprise qui peut être vérifié",
    contact_number: 'Numéro de contact',
    by_clicking: 'En cliquant',
    button_you_agree_with_our: "Vous acceptez nos conditions d'utilisation.",
    terms_and_condition: "Conditions générales d'utilisation.",
    sign_in: "S'inscrire",
    send_request:'Envoyer une demande',
    cancel:'Annuler',
    account_type :'Type de compte',
    verify_your_account:'Vérifier votre compte',
    please_enter_the_OTP_you_received_through_SMS_and_email:'Veuillez saisir le code OTP que vous avez reçu par SMS et par e-mail.',
    for_verifying_your_account:'Pour vérifier votre compte',
    Enter_OTP_received_on_SMS:'Saisissez l OTP reçu par SMS/E-mail',
    resend_OTP_code_through_SMS:'Renvoyer le code OTP par SMS',
    enter_OTP_received_on_email:'Saisir le code OTP reçu par courriel',
    resend_OTP_code_through_email:'Renvoyer le code OTP par e-mail',
    create_new_account:"S'inscrire",
    patter:"(Majuscule, minuscule, @,&,+,*,%,#,£,$,¥...)"

};
export default auth;