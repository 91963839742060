import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from "react-simple-star-rating";
import RecentTravelCard from "../profile/swipersData/RecentTravelCard";
import { useMediaQuery } from "@mui/material";
import travel from "../../../models/travel";
import { Link, useHistory } from "react-router-dom";
import RecentLuggageCard from "../profile/swipersData/RecentLuggageCard";
import luggage from "../../../models/luggage";
import { useContext } from "react";
import AppContext from "../../../store/context/app-context";
import moment from "moment";

export default function PackageArea(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [TravelAnnouncement, setTravelAnnouncements] = useState();
  const [luggageRequest, setLuggageRequest] = useState();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const mediumViewport = useMediaQuery("(min-width:860px)");
  const [isOpen, setIsOpen] = useState(true);
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    travel
      .list(userId)
      .then((item) => {
        let temp = item?.filter((item) => item?.isComplete == false);
        temp.sort((a, b) => b.id - a.id);
        setTravelAnnouncements(temp);
      })
      .catch((error) => {
        console.log("error", error);
      });
    luggage
      .list(userId)
      .then((item) => {
        setLuggageRequest(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const viewDetails = () => {
    history.push("/cobagage_travel_announcements");
  };
  const viewDetailsluggage = () => {
    history.push("/cobagage_luggage_announcements");
  };

  const addTraveller = (radioData) => {
    contextObj.setTabValue(0);
    scrollTop();
    contextObj.setRadioValue(radioData);
    history.push({ pathname: "/myProfile" });
  };

  const setTologin = () => {
    scrollTop();
    history.push("/login")
  }

  return (
    <div>
      <div
        class="package-area package-style-two chain chain22"
        // style={{ marginTop: props.open || props.frist ? mediumViewport ? "130px" : null : "", background: '#f4f4f4', paddingBottom: '20px' }}
        style={{
          marginTop: mediumViewport ? "130px" : null,
          background: "#f4f4f4",
          paddingBottom: "20px",
        }}
      >
        <div className="container">
          <div className="tour-package-details">
            <div className="package-details-tabs pt-4">
              {/* <ul
                className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                id="pills-tab"
                role="tablist"
                style={{ marginBottom: "0px", background: "#f4f4f4" }}
              >
                <li className="nav-item" role="presentation">
                  <button
                    className={isOpen ? "nav-link active" : "nav-link"}
                    id="pills-package1"
                    data-bs-toggle="pill"
                    data-bs-target="#pill-body1"
                    type="button"
                    role="tab"
                    aria-controls="pill-body1"
                    aria-selected="true"
                    onClick={() => setIsOpen(true)}
                    style={{
                      borderRadius: "5px",
                      color: "#fff",
                      border: "1px solid #ee9200",
                      backgroundColor: "#ee9200",
                      padding: "8px 25px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    {t("homePage.Travels")}
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className={!isOpen ? "nav-link active" : "nav-link"}
                    id="pills-package1"
                    data-bs-toggle="pill"
                    data-bs-target="#pill-body1"
                    type="button"
                    role="tab"
                    aria-controls="pill-body1"
                    aria-selected="true"
                    onClick={() => setIsOpen(false)}
                    style={{
                      borderRadius: "5px",
                      border: "1px solid #53bde2",
                      color: "#fff",
                      backgroundColor: "#53bde2",
                      padding: "8px 25px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    {t("homePage.Luggages")}
                  </button>
                </li>
              </ul> */}
              <div className="tab-content" id="pills-tabContent">
                {/* Travel */}
                <div
                  className="tab-pane fade show active package-info-tab "
                  id="pill-body1"
                  role="tabpanel"
                  aria-labelledby="pills-package1"
                >
                  {isOpen ? (
                    <div class="">
                      <div className="row d-flex justify-content-center  pt-4">
                        <div
                          className="testimonial-area testimonial-style-three  p-0"
                          style={{ backgroundImage: "none" }}
                        >
                          <div className="container">
                            <div className="row d-flex justify-content-center align-items-center">
                              <div className="col-lg-6 col-sm-10 px-0">
                                <div className="section-head-alpha text-lg-start text-center pb-0">
                                  {/* <h2 class='fs-5'>Recent travel announcements</h2> */}
                                  <p class="fs-4 fw-bold">
                                    {t("homePage.Recent_announcements")}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                <div
                                  className="d-flex d-lg-block d-none"
                                  style={{ marginRight: "20px" }}
                                >
                                  <button
                                    className="nav-link active"
                                    type="button"
                                    onClick={() => {
                                      contextObj.login
                                        ? addTraveller(1)
                                        : setTologin();
                                    }}
                                    style={{
                                      borderRadius: "5px",
                                      color: "#fff",
                                      border: "1px solid rgb(238, 146, 0)",
                                      backgroundColor: "rgb(238, 146, 0)",
                                      padding: "8px 25px",
                                      boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    }}
                                  >
                                    {t("homePage.Add_New_card")}
                                  </button>
                                </div>
                                {/* <div className="slider-arrows text-center d-lg-flex flex-row align-items-center d-none gap-3">
                                  <div
                                    className="testi-prev4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Previous slide"
                                  >
                                    {" "}
                                    <i className="bi bi-arrow-left" />
                                  </div>
                                  <div
                                    className="testi-next4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Next slide"
                                  >
                                    <i className="bi bi-arrow-right" />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div class="row p-3">
                              <div class=" col-12 text-end">
                                <button
                                  class="fs-6"
                                  // onClick={viewDetails}
                                  onClick={() => (viewDetails(), scrollTop())}
                                  style={{
                                    color: "#ff4838",
                                    border: "0px",
                                    backgroundColor: "#fff",
                                    background: "#f4f4f4",
                                  }}
                                >
                                  {t("homePage.View_all_travel_announcements")}{" "}
                                  <i class="bi bi-arrow-right-circle" />
                                </button>
                              </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                              <div className="col-lg-12 p-0">
                                <Swiper
                                  className="swiper testimonial-slider-three"
                                  slidesPerView={1}
                                  speed={500}
                                  spaceBetween={5}
                                  loop={false}
                                  roundLengths={true}
                                  autoplay={{
                                    delay: 5000,
                                  }}
                                  navigation={{
                                    nextEl: ".testi-next4",
                                    prevEl: ".testi-prev4",
                                  }}
                                  breakpoints={{
                                    480: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                    },
                                    992: {
                                      slidesPerView: 2,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                  }}
                                >
                                  <div className="swiper-wrapper">
                                    {/* {TravelAnnouncement?.map(item => (
                                        <SwiperSlide className="swiper-slide" style={{ padding: "10px" }}>
                                          <RecentTravelCard item={item} />
                                        </SwiperSlide>
                                      ))} */}
                                    {TravelAnnouncement?.map((item, index) => {
                                      return (
                                        <SwiperSlide
                                          className="swiper-slide"
                                          style={{ padding: "10px" }}
                                          key={index}
                                        >
                                          <RecentTravelCard item={item} />
                                        </SwiperSlide>
                                      );
                                    })}
                                  </div>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="row d-flex justify-content-center  pt-4">
                        <div
                          className="testimonial-area testimonial-style-three  p-0"
                          style={{ backgroundImage: "none" }}
                        >
                          <div className="container">
                            <div className="row d-flex justify-content-center align-items-center p-0">
                              <div className="col-lg-6 col-sm-10 px-0">
                                <div className="section-head-alpha text-lg-start text-center pb-0">
                                  {/* <h2 className='fs-5'>Recent travel announcements</h2> */}
                                  <p className="fs-4 fw-bold">
                                    {t(
                                      "profile.recent_luggage_sending_requests"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                <div
                                  className="d-flex d-lg-block d-none"
                                  style={{ marginRight: "20px" }}
                                >
                                  <button
                                    className="nav-link active"
                                    type="button"
                                    onClick={() => {
                                      contextObj.login
                                        ? addTraveller(2)
                                        : setTologin();;
                                    }}
                                    style={{
                                      borderRadius: "5px",
                                      color: "#fff",
                                      border: "1px solid #53bde2",
                                      backgroundColor: "#53bde2",
                                      padding: "8px 25px",
                                      boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    }}
                                  >
                                    {t("homePage.l_send_luggages")}
                                  </button>
                                </div>
                                {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                  <div
                                    className="testi-prev4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Previous slide"
                                  >
                                    {" "}
                                    <i className="bi bi-arrow-left" />
                                  </div>
                                  <div
                                    className="testi-next4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Next slide"
                                  >
                                    <i className="bi bi-arrow-right" />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="row p-3">
                              <div className=" col-12 text-end">
                                <button
                                  className="fs-6"
                                  onClick={viewDetailsluggage}
                                  style={{
                                    color: "#ff4838",
                                    border: "0px",
                                    backgroundColor: "#fff",
                                    background: "#f4f4f4",
                                  }}
                                >
                                  {t("profile.view_all_luggage_requests")}{" "}
                                  <i className="bi bi-arrow-right-circle" />
                                </button>
                              </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                              <div className="col-lg-12 p-0">
                                <Swiper
                                  className="swiper testimonial-slider-three"
                                  slidesPerView={1}
                                  speed={500}
                                  spaceBetween={5}
                                  loop={false}
                                  roundLengths={true}
                                  autoplay={{
                                    delay: 5000,
                                  }}
                                  navigation={{
                                    nextEl: ".testi-next4",
                                    prevEl: ".testi-prev4",
                                  }}
                                  breakpoints={{
                                    480: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                    },
                                    992: {
                                      slidesPerView: 2,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                  }}
                                >
                                  <div className="swiper-wrapper">
                                    {luggageRequest?.map((item) => (
                                      <SwiperSlide
                                        className="swiper-slide"
                                        style={{ padding: "10px" }}
                                      >
                                        <RecentLuggageCard
                                          item={item}
                                          data={"viewAllLuggageRequest"}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </div>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
              <div className="mt-2 mb-3 d-flex justify-content-end d-lg-none d-block">
                {isOpen ? (
                  <button
                    className="nav-link active"
                    type="button"
                    onClick={() => {
                      contextObj.login
                        ? addTraveller(1)
                        : setTologin();;
                    }}
                    style={{
                      borderRadius: "5px",
                      color: "#fff",
                      border: "1px solid rgb(238, 146, 0)",
                      backgroundColor: "rgb(238, 146, 0)",
                      padding: "8px 25px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    {t("homePage.Add_New_card")}
                  </button>
                ) : (
                  <button
                    className="nav-link active"
                    type="button"
                    onClick={() => {
                      contextObj.login
                        ? addTraveller(2)
                        : setTologin();
                    }}
                    style={{
                      borderRadius: "5px",
                      color: "#fff",
                      border: "1px solid #53bde2",
                      backgroundColor: "#53bde2",
                      padding: "8px 25px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    {t("homePage.l_send_luggages")}
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
