import axios from '../plugin/axios';

const paymentSent = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Payment_sent/GetByUserId')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Payment_sent/ByNow/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    paymentHistory(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Payment_sent/Payment_Send_History', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    SpendMoney() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Payment_sent/SpendMoney')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default paymentSent;