import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Box, MenuItem, Stack } from "@mui/material";
import { CustomMenu } from "../../../UI";
import { languages } from "../../../../lang";

export default function Language() {
  const menu = useRef(null);
  const { i18n } = useTranslation();
  const { t } = useTranslation();
  const handleChange = (lan) => {
    i18n.changeLanguage(lan);
    menu.current();
  };

  return (
    <CustomMenu
      id="locales-menu"
      size="medium"
      color="inherit"
      icon={
        <img
          src={languages[i18n.language]?.icon}
          alt={languages[i18n.language]?.name}
        />
      }
      childFunc={menu}
      sx={{
        width: 35,
        height: 35,
      }}
    >
      <Stack spacing={0.75}>
        {Object.keys(languages).map((key) => (
          <MenuItem
            key={key}
            selected={i18n.language === key}
            onClick={() => {
              handleChange(key)}}
          >
            <Box
              component="img"
              alt={languages[key].name}
              src={languages[key].icon}
              sx={{ width: 28, mr: 2 }}
            />
            {t(`profile.${languages[key].name}`)}
          </MenuItem>
        ))}
      </Stack>
    </CustomMenu>
  );
}
