import axios from '../plugin/axios';

const subscribeHistory = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Subscribe_history/GetByUserId')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetById', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getAllTransction(){
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Subscribe_history/GetAllTransction')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default subscribeHistory;
