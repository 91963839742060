import React, { useContext, useEffect, useState } from "react";
import Loading from "../../common/Loading";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { t } from "i18next";
import travel from "../../../models/travel";
import AppContext from "../../../store/context/app-context";
import luggage from "../../../models/luggage";
import articles from "../../../models/articles";
import articlesDonation from "../../../models/articlesDonation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Modal, Form } from "react-bootstrap";
import LuggageToTraveller from "./LuggageToTraveller";
import LuggageData from "./LuggageData";
import TravellerToLuggage from "./TravellerToLuggage";
import TravllerData from "./TravllerData";
import useValidator from "../../../plugin/validator";
import request from "../../../models/request";
import notistack from "../../../plugin/notistack";
import RecentArticleCard from "../profile/swipersData/RecentArticleCard";
import RatingComp from "../profile/Rating/RatingComp";
import moment from "moment";
import ShareLinkTwitter from 'react-twitter-share-link'
import ShareLinkLinkedIn from 'react-linkedin-share-link'
import { useShare } from 'react-facebook';
import { BiWorld } from "react-icons/bi";

export default function ViewAdDetails() {
  const { share, isLoading, error } = useShare();
  const style = {
    copyContainer: {
      border: '1px solid blue',
      background: 'rgb(0,0,0,0.7)',
      display: 'none'
    },
  };
  const contextObj = useContext(AppContext);
  const { id, type } = useParams();
  const [allData, setAllData] = useState();
  const history = useHistory();
  const [validator, showMessage] = useValidator();
  const [load, setLoad] = useState(false);
  const { t, i18n } = useTranslation();
  const [show, setShow] = useState(false);
  const [luggageIdData, setLuggageIdData] = useState();
  const [travelIdData, setTravelIdData] = useState();
  const [Hide, setHide] = useState(1);
  const [image, setImage] = useState([]);
  const [showQuantity, setShowQuantity] = useState();
  const [view, setView] = useState();
  const [isOpen, setIsOpen] = useState(true);
  const [articaleOnSale, setArticaleOnSale] = useState();
  const [donationsData, setDonationsData] = useState();
  const [allStatus, setAllStatus] = useState();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const resetData = {
    request_id: "",
    article_id: "",
    article_quantity: "",
    requester_location: "",
  };
  const [data, setData] = useState(resetData);
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
    if (type === "4") {
      getTravlerData();
      getRejectStatusData();
    } else if (type === "5") {
      getLuggageData();
      getRejectStatusData();
    } else if (type === "6") {
      getArticalData();
      getRejectStatusData();
    } else if (type === "7") {
      getArticalDonationData();
      getRejectStatusData();
    }
  }, [id]);

  useEffect(() => {

  }, [i18n.language])

  useEffect(() => {
    userData();
  }, [allData, id]);
  const onInputUppercase1 = (e) => {
    e.target.value = e.target.value.replace(/[^0-9]/g, '');
    handleChange(e);
  };

  // async function handleShare() {
  //   await share({
  //     href: "https://cobagage.com/",
  //     hashtag: "Shivraj Hippargi's post message is here",
  //     quote: "Shivraj Hippargi's post message is here",
  //     redirectUri: "www.google.com",
  //     display: "popup"
  //   }).then(() => { });
  // }
  const handleShare = () => {
    if (!allData) {
      console.error("Article data not available");
      return;
    }

    const shareContent = {
      method: 'share',
      href: "https://cobagage.com/",
      quote: `
        Title: ${allData.title}
        User: ${allData.user_name}
        Location: ${allData.location}
        Quantity: ${allData.quantity}
        Delivery Date: ${allData.delivery_date}
        Delivery Place: ${allData.delivery_place}
        Rating: ${allData.rating}
      `,
    };

    // Check if the Facebook SDK is loaded
    if (window.FB) {
      window.FB.ui(shareContent);
    } else {
      console.error("Facebook SDK not loaded");
    }
  };

  const vehicleTypeData = [
    { id: 1, vehicleName: t("form.bike"), imgPath: "/images/vehicles/Bike.png" },
    { id: 2, vehicleName: t("form.boat"), imgPath: "/images/vehicles/boat.png" },
    { id: 3, vehicleName: t("form.Bus"), imgPath: "/images/vehicles/bus.png" },
    { id: 4, vehicleName: t("form.car"), imgPath: "/images/vehicles/car.png" },
    { id: 5, vehicleName: t("form.kick_scooter"), imgPath: "/images/vehicles/kickscooter.png" },
    { id: 6, vehicleName: t("form.metro"), imgPath: "/images/vehicles/Tramway.png" },
    { id: 8, vehicleName: t("form.plane"), imgPath: "/images/vehicles/plan.jpg" },
    { id: 9, vehicleName: t("form.Scooter"), imgPath: "/images/vehicles/MoterBike.png" },
    { id: 10, vehicleName: t("form.Tgv"), imgPath: "/images/vehicles/Tvg.png" },
    { id: 11, vehicleName: t("form.Tramway"), imgPath: "/images/vehicles/Tramway.png" }

  ]
  const errorVariable = {
    article_quantity: validator.message(
      t("article_quantity"),
      data.article_quantity,
      "required"
    ),
    requester_location: validator.message(
      t("requester_location"),
      data?.requester_location,
      "required"
    ),
  };

  const getLuggageData = () => {
    luggage.get(id).then((data) => {
      // delete data['images']
      setView(data.image[0]);
      setAllData(data);
    });
  };

  const getTravlerData = () => {
    travel.get(id).then((data) => {
      setAllData(data);
    });
  };

  const getArticalData = () => {
    articles
      .get(id)
      .then((data) => {
        setView(data?.images[0]);
        setAllData(data);
        handleSetData("article_id", data.id);
      })
      .catch((error) => {
        console.log("view ad details article_id", error);
      });
  };

  const getArticalDonationData = () => {
    articlesDonation
      .get(id)
      .then((data) => {
        setView(data?.images[0]);
        setAllData(data);
      })
      .catch((error) => {
        console.log("view ad details articlesDonation", error);
      });
  };
  const userData = () => {
    articles
      .GetByUser(allData?.user_id)
      .then((data) => {
        setArticaleOnSale(data);
      })
      .catch((error) => {
        console.log("view ad details articles", error);
      });
    articlesDonation
      .GetByUser(allData?.user_id)
      .then((data) => {
        setDonationsData(data);
      })
      .catch((error) => {
        console.log("view ad details articlesDonation", error);
      });
  };

  const getRejectStatusData = () => {
    request.StatusReject(type, id).then((respons) => {
      setAllStatus(respons)
    }).catch((error) => {
      console.log("getArticleRejectStatusData", error);
    })
  }


  const handleClose = () => {
    setHide(1);
    setShow(false);
    setLuggageIdData("");
    setTravelIdData("");
    showMessage(false);
    contextObj.setRequestedLuggage("");
    contextObj.setRequestedTraveller("");
    setImage("");
    setData(resetData);
    // getArticalData();
  };

  const handleShow = () => setShow(true);

  const openpop = () => {
    handleShow();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const vieImage = (item) => {
    setView(item);
  };

  const handleSubmit = () => {
    if (type == 6) {
      request
        .createArticle(data, id)
        .then((response) => {
          setShowQuantity("");
          notistack.success(t("form.Request_successfully"));
          handleClose();
        })
        .catch((error) => {
          notistack.error(error?.data?.message);
          console.log("error", error);
        });
    } else {
      request
        .createArticleDonar(data, id)
        .then((response) => {
          setShowQuantity("");
          notistack.success(t("form.Request_successfully"));
          handleClose();
        })
        .catch((error) => {
          notistack.error(error?.data?.message);
          console.log("error", error);
        });
    }
  };

  const testimonial3slide = {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 5,
    loop: true,
    roundLengths: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".testi-next4",
      prevEl: ".testi-prev4",
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };
  const testimonial4slide = {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 5,
    // loop: true,
    roundLengths: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: ".testi-next4",
      prevEl: ".testi-prev4",
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div>
          <div className="container mt-3">
            <Button
              style={{
                backgroundColor: "#ff4838",
                borderColor: "#ff4838",
                borderRadius: "5px",
                margin: "2px",
              }}
              onClick={() => history.goBack()}
            >
              <i class="bi bi-arrow-left-circle" />{" "}
              {type === "4"
                ? t("profile.Back_to_travel_announcements")
                : type === "5"
                  ? t("profile.Back_to_luggage_listing_page")
                  : type === "6"
                    ? t("profile.Back_to_listing_page")
                    : type === "7" && t("profile.Back_to_listing_page")}
            </Button>
          </div>
          <div className="package-details-wrapper mt-4">
            <div className="container">
              <div className="col-12">
                {type === "6" || type === "7" ? (
                  " "
                ) : (
                  <div className="row mb-lg-3">
                    <div className="col-lg-3 col-6 row">
                      <div className="col-3">
                        {" "}
                        <i
                          class="bi bi-arrow-repeat"
                          style={{ fontSize: "35px" }}
                        ></i>
                      </div>
                      <div className="col-9">
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {type == 4
                            ? t("form.Trip_Type")
                            : type == 5
                              ? t("form.Luggage_Sender_type")
                              : ""}
                        </span>
                        <br></br>
                        <span>
                          {type == 4 ? allData?.trip_type == 1 ? t("form.one_way") : t("form.Round_Way")
                            : type == 5 ? allData?.sender_type == 1 ? t("form.Regular") : allData?.sender_type == 2 ? t("form.periodical") : allData?.sender_type == 3 ? t("form.Oneoff") : "" : ""}
                          {/* {} */}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-3 col-6 row">
                      <div className="col-3">
                        <i
                          class="bi bi-briefcase-fill"
                          style={{ fontSize: "35px" }}
                        ></i>
                      </div>
                      <div className="col-9">
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {type == 4
                            ? t("form.Traveller_Type")
                            : type == 5
                              ? t("form.Luggage_Wort")
                              : ""}
                        </span>
                        <br></br>
                        <span>
                          {type == 4 ? allData?.traveller_type == 1 ? t("form.Regular") : allData?.traveller_type == 2 ? t("form.periodical") : allData?.traveller_type == 3 ? t("form.Oneoff") : ""
                            : type == 5 ? allData?.luggage_estimation + allData?.currency : ""}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-5 col-12 row">
                      <div className="col-2">
                        <i
                          class="bi bi-cart3 me-2"
                          style={{ fontSize: "35px" }}
                        ></i>
                      </div>
                      <div className="col-10 TotalCapacity">
                        <span style={{ fontSize: "12px", fontWeight: "bold" }}>
                          {type == 4
                            ? t("form.Total_Carriage_Capacity")
                            : type == 5
                              ? t("form.Luggage_Weigh")
                              : ""}
                        </span>
                        <br></br>
                        <span>
                          {type == 4 && allData?.trip_type == 2 ? (
                            <span>
                              {allData?.total_carriage_capacity} Kg{" "}
                              <i class="bi bi-chevron-compact-left"></i>
                              {t("form.Onward")} - {allData?.carriage_capacity}
                              Kg + {t("form.Return")} -{" "}
                              {allData?.carriage_capacity}Kg
                              <i class="bi bi-chevron-compact-right"></i>
                            </span>
                          ) : (
                            <span>
                              {type == 4
                                ? allData?.carriage_capacity
                                : type == 5
                                  ? allData?.weight
                                  : ""}
                              kg
                            </span>
                          )}
                          {/* {type == 4 ? allData?.carriage_capacity : type == 5 ? allData?.weight : ""}kg */}
                        </span>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-8">
                  <div className="tour-package-details">
                    <div className="pd-header">
                      {type === "4" && (
                        <div class="package-card-delta">
                          {allData?.verification === false ? (
                            <div class="ribbon red">
                              <span>{t("form.not_verified")}</span>
                            </div>
                          ) : (
                            <div class="ribbon red">
                              <span>{t("form.verified")}</span>
                            </div>
                          )}
                          {allData?.vehicle_type && (
                            <img
                              src={
                                allData?.vehicle_type == 1
                                  ? "/images/vehicles/Bike.png"
                                  : allData?.vehicle_type == 2
                                    ? "/images/vehicles/boat.png"
                                    : allData?.vehicle_type == 3
                                      ? "/images/vehicles/bus.png"
                                      : allData?.vehicle_type == 4
                                        ? "/images/vehicles/car.png"
                                        : allData?.vehicle_type == 5
                                          ? "/images/vehicles/kickscooter.png"
                                          : allData?.vehicle_type == 6
                                            ? "/images/vehicles/train.png"
                                            : allData?.vehicle_type == 7
                                              ? "/images/vehicles/MoterBike.png"
                                              : allData?.vehicle_type == 8
                                                ? "/images/vehicles/plan.png"
                                                : allData?.vehicle_type == 9
                                                  ? "/images/vehicles/scooter.png"
                                                  : allData?.vehicle_type == 10
                                                    ? "/images/vehicles/Tvg.png"
                                                    : allData?.vehicle_type == 11
                                                      ? "/images/vehicles/Tramway.png"
                                                      : allData?.vehicle_type == 12 ?
                                                        "/images/vehicles/train.png"
                                                        : ""
                              }
                              // src={traveler?.vehicle_type == 1 ? "/images/vehicles/train.png" : traveler?.vehicle_type == 2 ? "/images/vehicles/car.png" : traveler?.vehicle_type == 3 ? "/images/vehicles/plan.png" : traveler?.vehicle_type == 4 ? "/images/vehicles/bus.png" : traveler?.vehicle_type == 5 ? "/images/vehicles/boat.png" : traveler?.vehicle_type == 6 ? "/images/vehicles/kickscooter.png" : traveler?.vehicle_type == 7 ? "/images/vehicles/scooter.png" : traveler?.vehicle_type == 8 ? "/images/vehicles/Tvg.png" : traveler?.vehicle_type == 9 ? "/images/vehicles/Tramway.png" : traveler?.vehicle_type == 10 ? "/images/vehicles/bike.png" : ""}
                              alt="PackageIMG"
                              style={{
                                aspectRatio: "4/2",
                                objectFit: "contain",
                                width: "100%",
                              }}
                            />
                          )}
                        </div>
                      )}
                      {type === "5" ? (
                        <div>
                          <div class="package-card-delta">
                            {allData?.verification === false ? (
                              <div class="ribbon red">
                                <span>{t("form.not_verified")}</span>
                              </div>
                            ) : (
                              <div class="ribbon red">
                                <span>{t("form.verified")}</span>
                              </div>
                            )}
                            <img
                              src={view}
                              style={{
                                aspectRatio: "4/2",
                                objectFit: "contain",
                                width: "100%",
                              }}
                            ></img>
                          </div>
                          <div className="row pt-4">
                            {allData?.image?.map((item) => {
                              return (
                                <div
                                  className="col-lg-2 col-md-6 "
                                  style={{
                                    marginLeft: "5px",
                                    border: "1px solid",
                                    borderRadius: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                  }}
                                >
                                  <img
                                    src={item}
                                    style={{ height: "100px", width: "100px" }}
                                    onMouseOver={() => vieImage(item)}
                                  ></img>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : type === "6" || type === "7" ? (
                        <div>
                          <div class="package-card-delta">
                            {allData?.verification === false ? (
                              <div class="ribbon red">
                                <span>{t("form.not_verified")}</span>
                              </div>
                            ) : (
                              <div class="ribbon red">
                                <span>{t("form.verified")}</span>
                              </div>
                            )}
                            <img
                              src={view}
                              style={{
                                aspectRatio: "4/2",
                                objectFit: "contain",
                                width: "100%",
                              }}
                            ></img>
                          </div>
                          <div className="row pt-4">
                            {allData?.images?.map((item) => {
                              return (
                                <div
                                  className="col-lg-2 col-md-6 "
                                  style={{
                                    marginLeft: "5px",
                                    border: "1px solid",
                                    borderRadius: "20px",
                                    display: "flex",
                                    justifyContent: "center",
                                    padding: "5px",
                                  }}
                                >
                                  <img
                                    src={item}
                                    style={{ height: "100px", width: "100px" }}
                                    onMouseOver={() => vieImage(item)}
                                  ></img>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {type === "4" && (
                        <div className="header-bottom">
                          <div className="pd-lavel d-flex  align-items-center flex-wrap gap-2">
                            <h5>{t("form.Drop_Off_Location")} : </h5>
                            <span>{allData?.pickup_place}</span>
                          </div>
                          <div className="pd-lavel d-flex  align-items-center flex-wrap gap-2">
                            <h5>{t("form.Delivery_Location")} : </h5>
                            <span>{allData?.delivery_place}</span>
                          </div>
                        </div>
                      )}

                      <div
                        className="header-bottom row"
                        style={{
                          backgroundColor: "#e9e7e6",
                          marginTop: "20px",
                        }}
                      >
                        <div
                          className={
                            type == 5
                              ? "col-6"
                              : type == 6 || type == 7
                                ? "col-lg-12"
                                : "col-12 col-lg-3 row"
                          }
                        >
                          {type == 6 || type == 7 ? (
                            <div className="col-12">
                              {t("form.More_Donation")} :
                            </div>
                          ) : (
                            ""
                          )}
                          <div className="col-12">{allData?.user_name}</div>
                          <div className="col-12">
                            <span
                              style={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              {t("form.Member_since")}<br></br>{i18n.language === "enUS" ? moment(allData?.member_since).format("MM/DD/YYYY") : moment(allData?.member_since).format("DD/MM/YYYY")}
                            </span>
                          </div>
                        </div>
                        {type == 4 ? (
                          <div className="col-12 col-lg-3">
                            <i
                              class="bi bi-globe2"
                              style={{ color: "black" }}
                            ></i>{" "}
                            {t("form.Traveller_Type")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {allData?.traveller_type == 1
                                ? t("form.Regular")
                                : allData?.traveller_type == 2
                                  ? t("form.periodical")
                                  : allData?.traveller_type == 3
                                    ? t("form.Oneoff")
                                    : ""}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {type == 4 ? (
                          <div className="col-12 col-lg-3">
                            <i
                              class="bi bi-arrow-clockwise"
                              style={{ color: "black" }}
                            ></i>{" "}
                            {t("cobagage.trips_made")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {allData?.trips_made}
                            </span>
                          </div>
                        ) : (
                          ""
                        )}
                        {type == 6 || type == 7 ? (
                          ""
                        ) : (
                          <div
                            className={type == 5 ? "col-6" : "col-12 col-lg-3"}
                          >
                            <i
                              class="bi bi-briefcase-fill"
                              style={{ color: "black" }}
                            ></i>{" "}
                            {t("form.Luggages_sents")}:{" "}
                            <span style={{ fontWeight: "bold" }}>
                              {allData?.luggages_sents}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  className="col-lg-4"
                // style={{ marginTop: type === '4' || type === '5' ? "66px" : "" }}
                >
                  <div>
                    {allStatus?.status == 2 ? (<p style={{ color: "red" }}>
                      {t("form.Request_Reject")}
                    </p>) : ""
                    }

                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="package-sidebar">
                        <aside className="package-widget-style-2 widget-form">
                          {type === "6" || type === "7" ? (
                            ""
                          ) : (
                            <div className="widget-title text-center d-flex justify-content-between">
                              <h4>{t("form.Way_to")}</h4>
                              <h3 className="widget-lavel">
                                {type === "4" || type === "5"
                                  ? allData?.city_arrival_name
                                  : ""}
                              </h3>
                            </div>
                          )}
                          <div className="widget-body">
                            <form
                              onSubmit={(e) => e.preventDefault()}
                              action="#"
                              method="post"
                              id="booking-form"
                            >
                              <div className="booking-form-wrapper">
                                <div className="custom-input-group">
                                  <div className="col-12 icons_container">
                                    <h4 className={allData?.title?.length > 20 && "icons_container icon facebook"} style={{ color: "#ff4838" }}>{allData?.title?.length > 20 ? (allData?.title?.slice(0, 20).trimEnd() + '...') : allData?.title}
                                      {allData?.title?.length > 20 && <div class="tooltip">{allData?.title}</div>}
                                    </h4>
                                  </div>
                                  {type === "4" || type === "5" ? (
                                    <div
                                      className="col-12"
                                      style={{ marginLeft: "-21px" }}
                                    >
                                      <RatingComp rate={allData?.rating} />
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="">
                                  {type === "5" && (
                                    <div className="pt-2 pb-2">
                                      <spna
                                        style={{ color: "rgb(255, 72, 56)" }}
                                      >
                                        <h6>{t("form.Shipping_Detail")}:</h6>
                                      </spna>
                                    </div>
                                  )}
                                  {type === "4" && allData?.trip_type == 1 && (
                                    <div className="pt-2 pb-2">
                                      <spna
                                        style={{ color: "rgb(255, 72, 56)" }}
                                      >
                                        <h6>{t("form.Journey_Details")}:</h6>
                                      </spna>
                                    </div>
                                  )}
                                  {type === "4" && allData?.trip_type == 2 && (
                                    <div className="pt-2 pb-2">
                                      <span
                                        style={{ color: "rgb(255, 72, 56)" }}
                                      >
                                        <h6>
                                          {t("form.Onward_Journey_Details")}:
                                        </h6>
                                      </span>
                                    </div>
                                  )}
                                  {type === "6" || type === "7" ? (
                                    <div className="pt-2 pb-2">
                                      <span
                                        style={{ color: "rgb(255, 72, 56)" }}
                                      >
                                        <h6>
                                          {t("form.Geolocation")}: {''}
                                          {allData?.location}
                                        </h6>
                                      </span>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {type === "6" || type === "7" ? (
                                    ""
                                  ) : type === "4" ? (
                                    <div className="row">
                                      <div className="col-5 d-flex">
                                        <div className="col-3">
                                          <img
                                            src={allData?.departure_flag}
                                            style={{
                                              aspectRatio: "4/2",
                                              objectFit: "contain",
                                              width: "70%",
                                              height: "60%",
                                              marginTop: "-7px",
                                            }}
                                          ></img>
                                        </div>
                                        <div className="col-8">
                                          <h6>
                                            {allData?.city_departure_name}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <h5>
                                          <i class="bi bi-arrow-right"></i>
                                        </h5>
                                      </div>
                                      <div className="col-5 d-flex">
                                        <div className="col-3">
                                          <img
                                            src={allData?.arrival_flag}
                                            style={{
                                              aspectRatio: "4/2",
                                              objectFit: "contain",
                                              width: "70%",
                                              height: "60%",
                                              marginTop: "-7px",
                                            }}
                                          ></img>
                                        </div>
                                        {/* <div className="col-8"><h6>{allData?.city_arrival_name?.length > 8 ? (allData?.city_arrival_name?.slice(0, 8) + '....') : (allData?.city_arrival_name)}</h6></div> */}
                                        <div className="col-8">
                                          <h6>{allData?.city_arrival_name}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="row">
                                      <div className="col-5 d-flex">
                                        <div className="col-3">
                                          <img
                                            src={allData?.departure_flag}
                                            style={{
                                              aspectRatio: "4/2",
                                              objectFit: "contain",
                                              width: "70%",
                                              height: "60%",
                                              marginTop: "-7px",
                                            }}
                                          ></img>
                                        </div>
                                        <div className="col-8">
                                          <h6>
                                            {allData?.city_departure_name}
                                          </h6>
                                        </div>
                                      </div>
                                      <div className="col-2">
                                        <h5>
                                          <i class="bi bi-arrow-right"></i>
                                        </h5>
                                      </div>
                                      <div className="col-5 d-flex">
                                        <div className="col-3">
                                          <img
                                            src={allData?.arrival_flag}
                                            style={{
                                              aspectRatio: "4/2",
                                              objectFit: "contain",
                                              width: "70%",
                                              height: "60%",
                                              marginTop: "-7px",
                                            }}
                                          ></img>
                                        </div>
                                        <div className="col-8">
                                          <h6>{allData?.city_arrival_name}</h6>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div className="row gap-2 mt-2">
                                  {type === "4" || type === "5" ? (
                                    <div className="col-6 row">
                                      <div className="">
                                        <i class="bi bi-calendar-month-fill me-2" />{" "}
                                        {i18n.language === "enUS" ? moment(allData?.departure_date, "DD/MM/YYYY").format("MM/DD/YYYY") : allData?.departure_date}
                                        <br />
                                        {allData?.departure_time}{type === "4" && "hrs"}
                                        {type === "4" && <br />}
                                        {t("form.Departure")}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {type === "4" || type === "5" ? (
                                    <div className="col-6 row">
                                      <div className="ArrivalMargin">
                                        <i class="bi bi-calendar-month-fill me-2" />
                                        {i18n.language === "enUS" ? moment(allData?.arrival_date, "DD/MM/YYYY").format("MM/DD/YYYY") : allData?.arrival_date} <br />
                                        {allData?.arrival_time}{type === "4" && "hrs"}
                                        {type === "4" && <br />}
                                        {t("form.Arrival")}
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div></div>
                                {type === "4" ? (
                                  <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                    <div className="col-12 row">
                                      <div className="d-flex">
                                        <i
                                          class="bi bi-cart3 me-2"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                        <span>
                                          {t(
                                            "form.Carrying_Capacity_remaining"
                                          )}
                                          <br />
                                          {allData?.remaining_capacity} kg
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 row">
                                      <div className="d-flex">
                                        <i
                                          class="bi bi-tag-fill  me-2"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                        <span>
                                          {t("form.Unit_Price")}
                                          <br />
                                          {allData?.price_per_kg}{" "}
                                          {allData?.currency}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : type === "6" ? (
                                  <div className="row pd-lavel d-flex  align-items-center flex-wrap mb-2">
                                    <div className="col row">
                                      <div className="col d-flex">
                                        <span>
                                          {t("form.Product_Weight")}
                                          <br />
                                          {allData?.weight} kg
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col row">
                                      <div className="col d-flex" style={{ background: 'rgb(243, 156, 18)', padding: '5px 8px', borderRadius: '5px' }}>
                                        <span>
                                          {t("form.Product_Price_Unit")}
                                          <br />
                                          {allData?.price} {allData?.currency}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : type === "7" ? (
                                  <div className="row pd-lavel d-flex  align-items-center flex-wrap mb-2">
                                    <div className="col row">
                                      <div className="col d-flex">
                                        <span>
                                          {t("form.Product_Weight")}
                                          <br />
                                          {allData?.weight} kg
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                                {/* <div className="pt-2">
                                                                {type === '4' &&
                                                                    <div className="col-12">
                                                                        <span><h6>{t("form.vehical_Detiles")}:{allData?.vehicle_details}</h6></span>
                                                                    </div>
                                                                }
                                                            </div> */}

                                {type === "5" && (
                                  <div className="col-12 mt-2">
                                    <span>
                                      <h6>
                                        {t("form.Luggage_Location")}:{" "}
                                        {allData?.location}
                                      </h6>
                                    </span>
                                  </div>
                                )}
                                {type === "6" && (
                                  <div className="col-12 mb-2">
                                    <span>
                                      <h6>
                                        {t("form.Item_Location")}:{" "}
                                        {allData?.location}
                                      </h6>
                                    </span>
                                  </div>
                                )}
                                {type === "5" && (
                                  <div className="col-12 mt-2">
                                    <span>
                                      <h6>
                                        {t("form.receiver_name")}:{" "}
                                        {allData?.receiver_name}
                                      </h6>
                                    </span>
                                  </div>
                                )}
                                {type === "6" || type === "7" ? (
                                  <>
                                    <div className="col-12">
                                      <span>
                                        <h6>
                                          {t("form.Available_Quantity")}:{" "}
                                          {allData?.remaining_quantity === 0
                                            ? t("form.Out_stock")
                                            : allData?.remaining_quantity}
                                        </h6>
                                      </span>
                                    </div>
                                    <div className="col-12 mt-3">
                                      <h6 className="col-12 fw-bold">
                                        {t("form.Article_Delivery_Details")}:
                                      </h6>
                                      <div className="col-12 mt-1 mx-0 px-0">
                                        <div className="col-12 row  align-items-center mx-0 px-0">
                                          <div className="col-2 mx-0 px-0">
                                            <BiWorld style={{ fontSize: "22px" }} />
                                          </div>
                                          <div className="col-10 mx-0 px-0">
                                            <p>{t("form.delivery_country")}{' '}:{" "}{allData?.country_name}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mt-1 mx-0 px-0">
                                        <div className="col-12 row  align-items-center mx-0 px-0">
                                          <div className="col-2 mx-0 px-0">
                                            <i
                                              class="bi bi-geo-alt-fill"
                                              style={{ fontSize: "22px" }}
                                            ></i>
                                          </div>
                                          <div className="col-10 mx-0 px-0">
                                            <p>{t("form.delivery_place")}{' '}:{" "}{allData?.delivery_place}</p>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-12 mt-1 mx-0 px-0">
                                        <div className="col-12 row  align-items-center mx-0 px-0">
                                          <div className="col-2 mx-0 px-0">
                                            <i
                                              class="bi bi-calendar"
                                              style={{ fontSize: "22px" }}
                                            ></i>
                                          </div>
                                          <div className="col-10 mx-0 px-0">
                                            <p>{t("form.delivery_date")}{' '}:{' '}{allData?.delivery_date}</p>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-12 row d-flex align-items-center mt-1">

                                        <div className="col-5">
                                          <p>{t("form.Delivery_by")}:</p>
                                          {vehicleTypeData?.map((item) => item.id == allData?.vehicle_type && <p>{item.vehicleName}</p>)}
                                        </div>
                                        <div className="col-4">
                                          {vehicleTypeData?.map((item) => item.id == allData?.vehicle_type && <img src={item.imgPath} height="60px" width="70px" />)}
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                ) : type === "7" ? (
                                  <>
                                    <div className="col-12">
                                      <span>
                                        <h6>
                                          {t("form.remaining_quantity")}:{" "}
                                          {allData?.remaining_quantity === 0
                                            ? t("form.Out_stock")
                                            : allData?.remaining_quantity}
                                        </h6>
                                      </span>
                                    </div>
                                  </>
                                ) : (
                                  ""
                                )}
                                {contextObj?.loginUser?.id ===
                                  allData?.user_id ||
                                  allData?.remaining_quantity === 0 || allStatus?.status == 2
                                  ? ""
                                  : (type === "6" || type === "7") && (
                                    <div className="col-12">
                                      <label className="fw-bold p-1">
                                        {t("form.Quanatity_require")}
                                        <span style={{ color: "red" }}>
                                          {" "}
                                          *
                                        </span>
                                      </label>
                                      <div className="col-12">
                                        <input
                                          className="numberType"
                                          style={{
                                            border: "1px solid red",
                                            width: "70px",
                                          }}
                                          type="text"
                                          onInput={onInputUppercase1}
                                          onWheel={numberInputOnWheelPreventChange}
                                          value={data.article_quantity}
                                          onChange={handleChange}
                                          name="article_quantity"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errorVariable.article_quantity}
                                        </span>
                                      </div>
                                      <div className="col-12">
                                        <label className="fw-bold">
                                          {t("form.Requester_Location")}
                                          <span style={{ color: "red" }}>
                                            {" "}
                                            *
                                          </span>
                                        </label>
                                        <input
                                          style={{ border: "1px solid red" }}
                                          onChange={handleChange}
                                          value={data.requester_location}
                                          name="requester_location"
                                        />
                                        <span style={{ color: "red" }}>
                                          {errorVariable.requester_location}
                                        </span>
                                      </div>
                                    </div>
                                  )}

                                {/* {type === '5' &&
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.receiver_contact_number")}:{allData?.receiver_contact_number}</h6></span>
                                                                </div>

                                                            } */}
                                {type === "4" && allData?.trip_type == 2 && (
                                  <hr></hr>
                                )}
                                {type === "4" && allData?.trip_type == 2 && (
                                  <div className="col-12 pt-1">
                                    <span style={{ color: "rgb(255, 72, 56)" }}>
                                      <h6>
                                        {t("form.Return_Journey_Details")}:
                                      </h6>
                                    </span>
                                  </div>
                                )}
                                {type === "4" && allData?.trip_type == 2 && (
                                  <div className="row pt-2">
                                    <div className="col-5 d-flex">
                                      <div className="col-3">
                                        <img
                                          src={allData?.arrival_flag}
                                          style={{
                                            aspectRatio: "4/2",
                                            objectFit: "contain",
                                            width: "70%",
                                            height: "60%",
                                            marginTop: "-7px",
                                          }}
                                        ></img>
                                      </div>
                                      <div className="col-8">
                                        <h6>{allData?.city_arrival_name}</h6>
                                      </div>
                                    </div>
                                    <div className="col-2">
                                      <h5>
                                        <i class="bi bi-arrow-right"></i>
                                      </h5>
                                    </div>
                                    <div className="col-5 d-flex">
                                      <div className="col-3">
                                        <img
                                          src={allData?.departure_flag}
                                          style={{
                                            aspectRatio: "4/2",
                                            objectFit: "contain",
                                            width: "70%",
                                            height: "60%",
                                            marginTop: "-7px",
                                          }}
                                        ></img>
                                      </div>
                                      <div className="col-8">
                                        <h6>{allData?.city_departure_name}</h6>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                <div className="">
                                  <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                    {type === "4" && allData?.trip_type == 2 ? (
                                      <div className="col row">
                                        <div className="col">
                                          <i class="bi bi-calendar-month-fill me-2" />{" "}
                                          {i18n.language === "enUS" ? moment(allData?.return_departure_date, "DD/MM/YYYY").format("MM/DD/YYYY") : allData?.return_departure_date}
                                          <br />
                                          {allData?.return_departure_time}hrs<br />
                                          {t("form.Departure")}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                    {type === "4" && allData?.trip_type == 2 ? (
                                      <div className="col row">
                                        <div
                                          className="col"
                                          style={{ marginLeft: "25px" }}
                                        >
                                          <i class="bi bi-calendar-month-fill me-2" />
                                          {i18n.language === "enUS" ? moment(allData.return_arrival_date, "DD/MM/YYYY").format("MM/DD/YYYY") : allData.return_arrival_date} <br />
                                          {allData?.return_arrival_time} hrs<br />
                                          {t("form.Arrival")}
                                        </div>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </div>
                                </div>
                                {type === "4" && allData?.trip_type == 2 && (
                                  <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                    <div className="col-12 row">
                                      <div className="d-flex">
                                        <i
                                          class="bi bi-cart3 me-2"
                                          style={{ fontSize: "30px" }}
                                        ></i>
                                        <span>
                                          {t(
                                            "form.Carrying_Capacity_remaining"
                                          )}
                                          <br />
                                          {allData?.remaining_capacity} kg
                                        </span>
                                      </div>
                                    </div>
                                    <div className="col-12 row">
                                      <div className="d-flex">
                                        <i
                                          class="bi bi-tag-fill"
                                          style={{ fontSize: "35px" }}
                                        ></i>
                                        <span>
                                          {t("form.Unit_Price")}
                                          <br />
                                          {allData?.price_per_kg}{" "}
                                          {allData?.currency}
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                )}
                                {/* {type === '4' && allData?.trip_type == 2 &&
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.vehical_Detiles")}:{allData.vehicle_details}</h6></span>
                                                                </div>
                                                            } */}
                                {contextObj?.loginUser?.id ===
                                  allData?.user_id ||
                                  allData?.remaining_quantity === 0 || allStatus?.status == 2 ? (
                                  ""
                                ) : (
                                  <div className="custom-input-group">
                                    <div className="submite-btn">
                                      <button
                                        type="submit"
                                        style={{ padding: "10px 6px" }}
                                        onClick={() => {
                                          if (type === "6" || type === "7") {
                                            if (validator.allValid()) {
                                              openpop();
                                            } else {
                                              showMessage(true);
                                            }
                                          } else {
                                            openpop();
                                          }
                                        }}
                                      >
                                        {type === "4"
                                          ? t("form.advert_sending_luggage")
                                          : type === "5"
                                            ? t("form.Send_travel_luggage_sender")
                                            : type === "6"
                                              ? t("form.Send_request_buy_item")
                                              : type == "7"
                                                ? t("form.i_want_this")
                                                : ""}
                                      </button>
                                    </div>
                                    {type == 4 && (
                                      <Modal
                                        show={show}
                                        onHide={() => (handleClose(), scrollTop())}
                                        size="lg"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            {Hide == 1
                                              ? t(
                                                "form.Send_Luggage_Request_to_Traveller"
                                              )
                                              : Hide == 2
                                                ? t("form.Review_Your_request")
                                                : t("profile.success")}
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          {Hide == 2 ? (
                                            <LuggageData
                                              allData={allData}
                                              luggageIdData={luggageIdData}
                                              setHide={setHide}
                                            // requestData={contextObj.requestedLuggage}
                                            />
                                          ) : Hide == 1 ? (
                                            <LuggageToTraveller
                                              allData={allData}
                                              setLuggageIdData={
                                                setLuggageIdData
                                              }
                                              luggageIdData={luggageIdData}
                                              setHide={setHide}
                                            // image={image}
                                            // setImage={setImage}
                                            />
                                          ) : (
                                            <div>
                                              <p className="text-center pb-3 pt-3">
                                                {t(
                                                  "form.Your_luggage_request_successfully_traveller"
                                                )}
                                              </p>
                                              <p className="text-center pb-3 pt-3">
                                                {t("form.You_Luggages_section")}
                                              </p>
                                            </div>
                                          )}
                                        </Modal.Body>
                                        <Modal.Footer className="justify-content-center">
                                          {/* <Button onClick={handleClose} >
                                                                                {t('close')}
                                                                            </Button> */}
                                        </Modal.Footer>
                                      </Modal>
                                    )}
                                    {type == 5 && (
                                      <Modal
                                        show={show}
                                        onHide={() => (handleClose(), scrollTop())}
                                        size="lg"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          <Modal.Title>
                                            {Hide == 1
                                              ? t("form.Send_Luggage_Sender")
                                              : Hide == 2
                                                ? t("form.Review_Your_request")
                                                : t("profile.success")}
                                          </Modal.Title>
                                        </Modal.Header>
                                        <Modal.Body>
                                          {Hide == 2 ? (
                                            <TravllerData
                                              allData={allData}
                                              travelIdData={travelIdData}
                                              setHide={setHide}
                                            />
                                          ) : Hide == 1 ? (
                                            <TravellerToLuggage
                                              allData={allData}
                                              setTravelIdData={setTravelIdData}
                                              travelIdData={travelIdData}
                                              setHide={setHide}
                                            // image={image}
                                            // setImage={setImage}
                                            />
                                          ) : (
                                            <div>
                                              <p className="text-center pb-3 pt-3">
                                                {t(
                                                  "form.Your_travel_luggage_sender"
                                                )}
                                              </p>
                                              <p className="text-center pb-3 pt-3">
                                                {t(
                                                  "form.You_My_Travels_section"
                                                )}
                                              </p>
                                            </div>
                                          )}
                                        </Modal.Body>
                                        <Modal.Footer className="justify-content-center">
                                          {/* <Button onClick={handleClose} >
                                                                                {t('close')}
                                                                            </Button> */}
                                        </Modal.Footer>
                                      </Modal>
                                    )}
                                    {(type == 6 || type == 7) && (
                                      <Modal
                                        show={show}
                                        onHide={() => (handleClose(), scrollTop())}
                                        size="lg"
                                        centered
                                      >
                                        <Modal.Header closeButton>
                                          {type == 6 && (
                                            <Modal.Title>
                                              {Hide == 1
                                                ? t(
                                                  "form.send_request_to_article1"
                                                )
                                                : Hide == 2
                                                  ? t(
                                                    "profile.Review_Your_request"
                                                  )
                                                  : t("profile.success")}
                                            </Modal.Title>
                                          )}
                                          {type == 7 && (
                                            <Modal.Title>
                                              {t(
                                                "form.Send_Request_Article_Donation"
                                              )}
                                            </Modal.Title>
                                          )}
                                        </Modal.Header>
                                        <Modal.Body>
                                          <section
                                            class="h-100"
                                            style={{ backgroundColor: "#eee" }}
                                          >
                                            <div class="container h-100 py-5">
                                              <div class="row d-flex justify-content-center align-items-center h-100">
                                                <div class="col-10">
                                                  <div class="d-flex justify-content-between align-items-center mb-4">
                                                    <h3 class="fw-normal mb-0 text-black">
                                                      {t(
                                                        "form.Items_request_basket"
                                                      )}
                                                    </h3>
                                                  </div>
                                                  <div class="card rounded-3 mb-4">
                                                    <div class="card-body p-4">
                                                      <div class="row d-flex justify-content-between align-items-center">
                                                        <div class="col-md-2 col-lg-2 col-xl-2">
                                                          <img
                                                            src={
                                                              allData && allData.images && allData?.images[0]
                                                            }
                                                            class="img-fluid rounded-3"
                                                            alt="Cotton T-shirt"
                                                          />
                                                        </div>
                                                        <div class="col-md-3 col-lg-6 col-xl-3">
                                                          <p class="fw-normal mb-2">
                                                            {t(
                                                              "form.Requester_Location"
                                                            )}
                                                            :
                                                          </p>
                                                          <p className="fw-bold">
                                                            {
                                                              data?.requester_location
                                                            }
                                                          </p>
                                                        </div>
                                                        {type == 6 && (
                                                          <div class="col-md-3 col-lg-3 col-xl-2">
                                                            <p class="fw-normal mb-2">
                                                              {t("form.Price_Unit")}
                                                            </p>
                                                            <p className="fw-bold">
                                                              {allData?.price}{" "}
                                                              {
                                                                allData?.currency
                                                              }
                                                            </p>
                                                          </div>
                                                        )}
                                                        <div class="col-md-12 col-lg-6 col-xl-2">
                                                          <p class="fw-normal mb-2">
                                                            {t(
                                                              "form.Article_Quantity"
                                                            )}
                                                          </p>
                                                          <p className="fw-bold">
                                                            {
                                                              data?.article_quantity
                                                            }
                                                          </p>
                                                        </div>
                                                        <div class="col-md-3 col-lg-6 col-xl-3">
                                                          <p class="fw-normal mb-2">
                                                            {t(
                                                              "form.delivery_place"
                                                            )}
                                                            :
                                                          </p>
                                                          <p className="fw-bold">
                                                            {
                                                              allData?.delivery_place
                                                            }
                                                          </p>
                                                        </div>
                                                        <div class="col-md-1 col-lg-1 col-xl-1 text-end">
                                                          <a
                                                            href="#!"
                                                            class="text-danger"
                                                          >
                                                            <i class="fas fa-trash fa-lg"></i>
                                                          </a>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div class="card">
                                                    <div class="card-body">
                                                      <button
                                                        type="button"
                                                        class="btn btn-warning btn-block btn-lg"
                                                        onClick={handleSubmit}
                                                      >
                                                        {type == 6
                                                          ? t(
                                                            "form.confirm_request"
                                                          )
                                                          : t(
                                                            "form.confirm_request"
                                                          )}
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          </section>
                                        </Modal.Body>
                                        <Modal.Footer className="justify-content-center">
                                          <Button onClick={() => (handleClose(), scrollTop())}>
                                            {t("close")}
                                          </Button>
                                        </Modal.Footer>
                                      </Modal>
                                    )}
                                  </div>
                                )}
                              </div>
                            </form>
                          </div>
                        </aside>
                      </div>
                    </div>
                    <div className="row d-flex justify-content-center">
                      <div className="col-2 mt-4" style={{ cursor: "pointer" }} disabled={isLoading} onClick={handleShare}>
                        {/* <button type="button" > */}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icons/facebook.png"
                          }
                          alt="facebook Logo"
                          height={35}
                          width={35}
                        />
                        {/* </button> */}
                      </div>
                      {false && <div className="col-2 mt-4">
                        <a
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/cobagage_officiel/?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr"
                          target="_blank"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/images/icons/instagram.png"
                            }
                            alt="Instagram Logo"
                            height={35}
                            width={35}
                          />
                        </a>
                      </div>}
                      <div className="col-2 mt-4">
                        <ShareLinkTwitter link={"https://cobagage.com/"} text={"Message for share post on twitter: "}>
                          {link => <a
                            rel="noopener noreferrer"
                            href={link}
                            target="_blank"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/images/icons/twitter.png"
                              }
                              alt="Twitter Logo"
                              height={35}
                              width={35}
                            />
                          </a>}
                        </ShareLinkTwitter>
                      </div>
                      <div className="col-2 mt-4">
                        <ShareLinkLinkedIn link={"https://cobagage.com/"}>
                          {link => (
                            <a href={link} target='_blank'>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/images/icons/linkedin.png"
                                }
                                alt="linkedin Logo"
                                height={35}
                                width={35}
                              />
                            </a>
                          )}
                        </ShareLinkLinkedIn>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* <div class="package-area package-style-two chain"> */}
              {/* <div className='tour-package-details'> */}
              <div className="pt-4">
                <ul
                  className="nav nav-pills  gap-xxl-4 gap-lg-3 gap-sm-3 gap-1  p-3 p-sm-0 p-lg-0"
                  id="pills-tab"
                  role="tablist"
                  style={{ marginBottom: "0px" }}
                >
                  <li className="nav-item travel" role="presentation">
                    <button
                      className={isOpen ? "nav-link active" : "nav-link"}
                      id="pills-package1"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body1"
                      type="button"
                      role="tab"
                      aria-controls="pill-body1"
                      aria-selected="true"
                      onClick={() => setIsOpen(true)}
                      style={{
                        borderRadius: "5px",
                        // color: "#fff",
                        // backgroundColor: "#ff4838",
                        fontSize: "15px",
                        padding: "8px 25px",
                      }}
                    >
                      {t("homePage.item_sale")}
                    </button>
                  </li>
                  <li className="nav-item travel" role="presentation">
                    <button
                      className={!isOpen ? "nav-link active" : "nav-link"}
                      id="pills-package1"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body1"
                      type="button"
                      role="tab"
                      aria-controls="pill-body1"
                      aria-selected="true"
                      onClick={() => setIsOpen(false)}
                      style={{
                        borderRadius: "5px",
                        // color: "#fff",
                        // backgroundColor: "#ff4838",
                        fontSize: "15px",
                        padding: "8px 25px",
                      }}
                    >
                      {t("homePage.item_Donation")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  {/* Travel */}
                  <div
                    className="tab-pane fade show active package-info-tab "
                    id="pill-body1"
                    role="tabpanel"
                    aria-labelledby="pills-package1"
                  >
                    {isOpen ? (
                      <div class="">
                        <div className="row d-flex justify-content-center  pt-4">
                          <div
                            className="testimonial-area testimonial-style-three  p-lg-0"
                            style={{ backgroundImage: "none" }}
                          >
                            <div className="row align-items-center justify-content-center">
                              <div className="col-lg-12">
                                <Swiper
                                  className="swiper testimonial-slider-three"
                                  // {...heroslide4}
                                  {...(articaleOnSale?.length >= 4
                                    ? testimonial3slide
                                    : testimonial4slide)}
                                >
                                  <div className="swiper-wrapper">
                                    {console.log("articaleOnSale: ", articaleOnSale)}
                                    {articaleOnSale && articaleOnSale?.map((item) => item.id != id && (
                                      <SwiperSlide
                                        className="swiper-slide"
                                        style={{ padding: "10px" }}
                                      >
                                        <RecentArticleCard
                                          item={item}
                                          data={"articleSale"}
                                        />
                                      </SwiperSlide>
                                    ))}
                                  </div>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="">
                        <div className="row d-flex justify-content-center  pt-4">
                          <div
                            className="testimonial-area testimonial-style-three  p-lg-0"
                            style={{ backgroundImage: "none" }}
                          >
                            <div className="row align-items-center justify-content-center">
                              <div className="col-lg-12">
                                <Swiper
                                  className="swiper testimonial-slider-three"
                                  {...(donationsData?.length >= 4
                                    ? testimonial3slide
                                    : testimonial4slide)}
                                >
                                  <div className="swiper-wrapper">
                                    {donationsData?.map((item) => {
                                      return item.id != id && (
                                        <SwiperSlide
                                          className="swiper-slide"
                                          style={{ padding: "10px" }}
                                        >
                                          <RecentArticleCard
                                            item={item}
                                            data={"hideButton"}
                                          />
                                        </SwiperSlide>
                                      )
                                    })}
                                  </div>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* </div> */}
              {/* </div> */}
            </div>
          </div>
        </div>
      )}
    </>
  );
}
