const auth = {
    email: 'Email ',
    password: 'Password',
    old_password: 'Old Password',
    new_Password:'New Password',
    confirm_password: 'Confirm Password',
    enter_confirm_password: 'Enter Confirm Password',
    enter_your_email:'Enter Your Email',
    login: 'Sign In',
    same_as_password:'same as password',
    first_time_login:'First Time Login',
    regular_user:" Regular User",
    required_fields_are_marked: 'Required fields are marked ',
    remember_me: 'Remember me',
    login_forgot_password: 'Forgot password',
    forgot_password: 'Forgot Password',
    already_have_an_account: 'Already have an account?',
    password_reset: 'Password Reset',
    email_verify_message: 'Thanks for signing up! Before getting started, could you verify your email address by clicking on the link we just emailed to you? If you didn\'t receive the email, we will gladly send you another.',
    resend_verification_email: 'Resend Verification Email',
    password_changed_successfully: 'Password Changed Successfully',
    profile_updated_successfully: 'Profile Updated Successfully',
    reset_link_is_sent_to_your_email: 'Reset link is sent to your email',
    enter_password: 'Enter Password',
    login_through_social_media: 'Login through Social Media ',
    register_through_socil_media: 'Register through Social Media',
    click_here: 'Click here.',
    register: 'Sign Up',
    what_type_of_account_would_you_like_to_create:"What type of account would you like to create?",
    individual_account:"Individual Account",
    company_account:"Company Account",
    full_Name: 'Full Name',
    country: 'Country',
    enter_country_name: 'Enter Country name',
    user_name: 'User Name ',
    enter_user_name: 'Enter User Name',
    phone: 'Phone',
    mobile_phone :'Mobile Phone ',
    fixed_phone_landline:'Fixed Phone/Landline',
    company_name:"Company Name",
    company_contacts_name:'Company Contact’s Name',
    official_comapany_number_wich_can_be_verified:'Official comapany number which can be verified',
    contact_number: 'Contact Number',
    by_clicking: 'By clicking',
    button_you_agree_with_our: 'button, you agree with our',
    terms_and_condition: ' Terms & Conditions.',
    sign_in: 'Sign In',
    send_request:'Send Request',
    cancle:'Cancel',
    account_type :'Account Type',
    verify_your_account:'Verify your account',
    please_enter_the_OTP_you_received_through_SMS_and_email:'Please enter the OTP you received through SMS and email',
    for_verifying_your_account:'for verifying your account',
    Enter_OTP_received_on_SMS:'Enter OTP received on SMS/Email',
    resend_OTP_code_through_SMS:'Resend OTP',
    enter_OTP_received_on_email:'Enter OTP received on email',
    resend_OTP_code_through_email:'Resend OTP code  through email',
    create_new_account:'Sign Up',
    patter:"(Uppercase, lowercase, @,&,+,*,%,#,£,$,¥...)"
};
export default auth;