import React from 'react'
import { useTranslation } from 'react-i18next'

export default function MoreCobagageWrap() {
    const { t } = useTranslation()

    return (
        <div className='container'>
            <div className='pt-110  pb-110'>
                <h1 className='text-center ' style={{ fontWeight: 'bold' }}>{t('form.Plus_Cobagage')}</h1>
                <p className='pt-4 fs-5'>
                    {t('form.Vous_sujet')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cette_fonctionnement')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Devenez_rêves')}
                </p>
                <h2 className='text-center mt-5' style={{ fontWeight: 'bold' }}>{t('form.Trouver_idéal')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.vous_éclaircies')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_relation')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Avec_moindre')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Avec_vides')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Par_besoins')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Chaque_simplement')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Celle_encore')}<strong>{t('form.la_transporter')}</strong>.
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cintermédiaire')}
                </p>
                <h2 className='mt-5 text-center' style={{ fontWeight: 'bold' }}> {t('form.Le_voyager')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Pratiquer_jamais')} <strong>{t('form.une_façon')}</strong>.
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_serré')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_auriez')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.La_dans')} <strong>{t('form.le_cobagage')}</strong> :
                    {t('form.simple_effort')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Pour_rendezvous')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Grâcerentabiliser_voyage')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_venir')}
                </p>
                <h2 className='mt-5 text-center' style={{ fontWeight: 'bold' }}>{t('form.Choisir_transactions')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.En_passant_transactions')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Vous_plateforme')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_sérénité')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Que_rencontrée')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_montant_final')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.La_signature')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Chaque_sujet')}( {t('form.voir_page')}<a href="/faq">FAQ</a> {t('form.pour_sujet')}).
                </p>
            </div>
        </div>
    )
}
