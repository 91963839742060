import React from "react";
import LuggageCompletedWrapperArea from "./LuggageCompletedWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function LuggageCompleted() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t('profile.luggage_carrying_order_detailes')}/>
      <LuggageCompletedWrapperArea />
    </>
  );
}

export default LuggageCompleted;
