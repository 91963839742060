import React, { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useValidator from "../../../plugin/validator";
import { auth } from "../../../models";
import notistack from "../../../plugin/notistack";
import Loading from "../../common/Loading";

function ForgotPasswordWrapperArea() {
  const history = useHistory();
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState({
    email: ''
  });
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 1500);
  }, []);

  const error = {
    email: validator.message(t("auth.email"), data.email, 'required|email|string|max:200'),
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleCancel = () => {
    history.push('/login');
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      setLoad(true);
      auth.forgotPassword(data).then(() => {
        setLoad(false);
        showMessage(false);
      })
        .catch((error) => {
          console.log(error);
          setLoad(false);
        })
    }
    else {
      showMessage(true);
    }
  }
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div className=' pt-110 pb-110'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <div className='package-sidebar'>
                  <aside className='package-widget-style-2 widget-form'>
                    <div className='widget-title text-center d-flex justify-content-between'>
                      <h4>{t("auth.forgot_password")}</h4>
                    </div>
                    <div className='widget-body'>
                      <form onSubmit={(e) => handleSubmit(e)} >
                        <div className='booking-form-wrapper'>
                          <div className='custom-input-group'>
                            <input
                              type='email'
                              id="email"
                              name="email"
                              value={data.email}
                              placeholder={t("auth.enter_your_email")}
                              className={!!error.email ? 'is-invalid' : ''}
                              onChange={handleChange}
                            />
                            <div className='invalid-feedback'>{error.email}</div>
                          </div>
                          <div className='custom-input-group'>
                            <div className='submite-btn d-flex'>
                              <button type="button" className='button-fill-round me-2' onClick={handleSubmit}>{t("auth.send_request")}</button>
                              <button type='button' className='button-fill-round' onClick={handleCancel} >{t('cancel')}</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPasswordWrapperArea;
