const cobagage={
    luggage_ads:"Annonces de bagages",
    cobagage_ads:"Annonces Cobagage",
    recent_listing:"Annonces récentes",
    default_sorting: "Tri par défaut",
    newest_first:"Le plus récent d'abord",
    oldest_first:"Le plus ancien en premier",
    lowest_price_first:"Prix ​​le plus bas en premier",
    highest_price_first:"Prix ​​le plus élevé en premier",
    select_arrival_country:"Sélectionnez le pays d'arrivée",
    unverified:"non vérifié",
    verified:"Vérifié",
    per:'par',
    from:"DEPUIS",
    to:"POUR",
    carriage_capacity:"CAPACITÉ DE TRANSPORT",
    departure_date:"DATE DE DÉPART",
    arrival_date:"DATE D'ARRIVÉE",
    article_for_sell:"ARTICLE A VENDRE",
    article_donation:"DON D'ARTICLES",
    type_of_traveler:"TYPE DE VOYAGEUR",
    member_since:"MEMBRE DEPUIS",
    trips_made:"VOYAGE(S) EFFECTUÉ(S)",
    luggages_sent:"BAGAGE(S) ENVOYÉ",
    user_rating:"NOTE DE L'UTILISATEUR",
    detail:"DÉTAIL",
    payment:"Le paiement est effectué",


}
export default cobagage;