import React, { useContext } from "react";
import Breadcrumb from "../../../common/Breadcrumb";
import Orderes from "../../../common/Orderes";
import { useTranslation } from "react-i18next";
import ShoppingCardWrapperArea from "./ShoppingCardWrapperArea";
import AppContext from "../../../../store/context/app-context";

function ShoppingCard() {
  const { t } = useTranslation();
  const contextObj = useContext(AppContext);

  return (
    <>
      <Orderes name={t("profile.ShoppingCart")} />
      <ShoppingCardWrapperArea />
    </>
  );
}

export default ShoppingCard;
