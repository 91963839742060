import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import PostTravelAnnouncement from "./PostTravelAnnouncement";
import PostLuggageRequest from "./PostLuggageRequest";
import PostArticleDonation from "./PostArticleDonation";
import PostArticlesale from "./PostArticlesale";
import { useMediaQuery } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../../../../store/context/app-context";
function PostAnAdvert() {
    const [radio, setRadio] = useState(1);
    const { t } = useTranslation();
    const mediumViewport = useMediaQuery('(max-width:1002px)');
    // const routeData = state && state.data;
    const history = useHistory();
    const contextObj = useContext(AppContext);


    useEffect(() => {
        // history.location?.state?.data && setRadio(history.location?.state?.data);
        contextObj.radioValue && setRadio(contextObj.radioValue)
    },
        // [history.location?.state?.data]
    )

    return (
        <div className="">
            <div className="contact-form-wrap pb-110">
                <h4>{t("form.Post_Advertisement")}</h4>
                <div className="custom-input-group mt-0">
                    <div
                        className="package-sidebar"
                        style={{ display: history.location?.state?.data || contextObj.radioValue ? 'none' : 'block' }}
                    >
                        <aside className="package-widget widget-duration " style={{ boxShadow: 'none' }} >
                            <div className="widget-body  ps-0">
                                <ul className="row">
                                    <li className="deration-check col-lg-6 col-md-6 col-sm-6 p-2 "
                                        onClick={() => {
                                            setRadio(1);
                                        }}
                                    >
                                        <input className="form-check-input "
                                            type="radio"
                                            id="dura1"
                                            name="account_type"
                                            checked={radio === 1}
                                        />
                                        <label className="advert" style={{ fontWeight: '600', }}>
                                            {t("form.Post_a_travel_announcement")}
                                        </label>
                                    </li>
                                    <li className="deration-check col-lg-6 col-md-6 col-sm-6 p-2 px-md-0"
                                        onClick={() =>
                                            setRadio(2)
                                        }
                                    >
                                        <input className="form-check-input"
                                            type="radio"
                                            id="dura1"
                                            name='account_type'
                                            checked={radio === 2}

                                        />
                                        <label className="advert" style={{ fontWeight: '600', }}>
                                            {t("form.Post_luggage_sending_request")}
                                        </label>
                                    </li>
                                    <li className="deration-check col-lg-6 col-md-6 col-sm-6 p-2"
                                        onClick={() => {
                                            setRadio(3);
                                        }}
                                    >
                                        <input className="form-check-input"
                                            type="radio"
                                            id="dura1"
                                            name="account_type"
                                            checked={radio === 3}
                                        />
                                        <label className="advert" style={{ fontWeight: '600', }}>
                                            {t("form.Post_article_sale")}
                                        </label>
                                    </li>
                                    <li className="deration-check col-lg-6 col-md-6 col-sm-6 p-2 px-md-0 "
                                        onClick={() => {
                                            setRadio(4);
                                        }}
                                    >
                                        <input className="form-check-input"
                                            type="radio"
                                            id="dura1"
                                            name='account_type'
                                            checked={radio === 4}

                                        />
                                        <label className="advert" style={{ fontWeight: '600', }}>
                                            {t("form.Post_article_donation")}
                                        </label>
                                    </li>
                                </ul>
                            </div>
                        </aside>
                    </div>
                </div>
                {radio === 1 && <PostTravelAnnouncement />}
                {radio === 2 && <PostLuggageRequest />}
                {radio === 3 && <PostArticlesale />}
                {radio === 4 && <PostArticleDonation />}
            </div >
        </div >
    )
}
export default PostAnAdvert;