import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import request from "../../../../models/request";
import Loading from "../../../common/Loading";
import AppContext from "../../../../store/context/app-context";

export default function MyPendingPayments() {
  const { t } = useTranslation();
  const [received, setReceived] = useState(1);
  const [receivedLuggage, setReceivedLuggage] = useState();
  const [sentLuggage, setSentLuggage] = useState();
  const [articleReceived, setArticleReceived] = useState();
  const [load, setLoad] = useState(false);
  const contextObj = useContext(AppContext);
  // const LocalTime = new Date()
  // console.log("localtime : ", LocalTime);
  // const timeZoneoffset = LocalTime.getTimezoneOffset();
  // console.log("timeZoneoffset : ", timeZoneoffset);
  // const utctime = new Date(LocalTime.getTime() + (timeZoneoffset * 60000))
  // console.log("utcTime  : ", utctime);
  // const receviedTime = new Date(utctime.getTime() - (timeZoneoffset * 60000))
  // console.log("reciredTime :", receviedTime);

  useEffect(() => {
    getDetails();
    requestSendDetails();
  }, []);

  const getDetails = () => {
    setLoad(true);
    request
      .allData(1)
      .then((item) => {
        setReceivedLuggage(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
    request
      .allData(3)
      .then((item) => {
        setArticleReceived(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const requestSendDetails = () => {
    request
      .allRequestSend(2)
      .then((item) => {
        setSentLuggage(item);
        setLoad(false);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const filterLugggageDetails = () => {
    return (
      received == 1
        ? receivedLuggage
        : received == 3
          ? articleReceived
          : sentLuggage
    )
      ?.filter((item) => item?.status == 1)
      .filter((item) => item?.payment_status == false);
  };
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div>
          <div className="package-details-wrapper p-0">
            <div className="tour-package-details">
              <div className="package-details-tabs row">
                <ul
                  className="nav nav-pills gap-xxl-4 gap-3"
                  id="myTab"
                  role="tablist"
                  style={{ marginBottom: "0px" }}
                >
                  <li className="nav-item travel" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-package1"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body1"
                      type="button"
                      role="tab"
                      aria-controls="pill-body1"
                      aria-selected="true"
                      onClick={() => setReceived(1)}
                      style={{
                        fontSize: "15px",
                        borderRadius: "5px",
                        padding: "8px 30px",
                      }}
                    >
                      {t("profile.travels")}
                    </button>
                  </li>
                  <li className="nav-item article" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-package2"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body2"
                      type="button"
                      role="tab"
                      aria-controls="pill-body2"
                      aria-selected="false"
                      onClick={() => setReceived(3)}
                      style={{ fontSize: "15px", borderRadius: "5px",background:received===3&&'gray' }}
                    >
                      Articles({t("profile.sales")})
                    </button>
                  </li>
                </ul>
                <div className="tab-content p-0" id="pills-tabContent">
                  {/*  Travel*/}
                  <div
                    className="tab-pane fade show active package-info-tab pt-4 "
                    id="pill-body1"
                    role="tabpanel"
                    aria-labelledby="pills-package1"
                  >
                    <div
                      class="container pt-2 h-100 package-card-delta p-3"
                      style={{ boxShadow: "none" }}
                    >
                      <h4 className="fs-5">
                        {t("profile.payments_due_receiving")}
                      </h4>
                      <ul
                        className="nav nav-pills gap-xxl-4 gap-3 pt-3"
                        id="pills-tab"
                        role="tablist"
                        style={{ marginBottom: "0px" }}
                      >
                        <li className="nav-item travel" >
                          <button
                            className={
                              received == 1 ? "nav-link active" : "nav-link"
                            }
                            style={{
                              fontSize: "15px",
                              borderRadius: "5px",
                              padding: "7px 19px",
                            }}
                            onClick={() => {
                              setReceived(1);
                            }}
                          >
                            {t("profile.i_received")}
                          </button>
                        </li>
                        <li className="nav-item travel">
                          <button
                            className={
                              received == 2 ? "nav-link active" : "nav-link"
                            }
                            style={{
                              fontSize: "15px",
                              borderRadius: "5px",
                              padding: "7px 32px",
                            }}
                            onClick={() => {
                              setReceived(2);
                            }}
                          >
                            {t("profile.i_sent")}
                          </button>
                        </li>
                      </ul>
                      <hr />
                      <div class="row d-flex justify-content-center align-items-center h-100 pt-1">
                        <div class=" col mb-4 mb-lg-0">
                          {filterLugggageDetails()?.map((item) => (
                            <div
                              class="package-card-delta card mb-3"
                              style={{ borderRadius: "5px" }}
                            >
                              <div class="row g-0">
                                <h4
                                  className="p-2"
                                  style={{ backgroundColor: "" }}
                                >
                                  {received == 1
                                    ? item?.title
                                    : item?.luggage_title}
                                </h4>
                                <hr class="mt-0 mb-4" />
                                <div class="col-md-7">
                                  <div class="card-body pt-0 pb-0">
                                    <div class="row ">
                                      <div class="col-4 mb-3">
                                        <h6 className="d-flex">
                                          <img
                                            src={item?.departure_flag}
                                            style={{ width: "25px", height: "20px", marginRight: '5px' }} />
                                          {item?.city_departure_name}
                                        </h6>
                                      </div>
                                      <div class="col-2 mb-3">
                                        <h6>
                                          <i class="bi bi-arrow-right" />
                                        </h6>
                                      </div>
                                      <div class="col-4 mb-3">
                                        <h6 className="d-flex">
                                          <img
                                            src={item?.arrival_flag
                                            }
                                            style={{ width: "25px", height: "20px", marginRight: '5px' }} />
                                          {item?.city_arrival_name}
                                        </h6>
                                      </div>
                                    </div>
                                    <div class="row pt-1">
                                      <div class="col-6 mb-3">
                                        <h6>
                                          <i class="bi bi-calendar-event" />{" "}
                                          {t("profile.departure")}
                                        </h6>
                                        <p class="text-muted">
                                          {item?.departure_date} |{" "}
                                          {item?.departure_time}
                                        </p>
                                      </div>
                                      <div class="col-6 mb-3">
                                        <h6>
                                          <i class="bi bi-calendar-event" />{" "}
                                          {t("profile.arrival")}
                                        </h6>
                                        <p class="text-muted">
                                          {" "}
                                          {item?.arrival_date} |{" "}
                                          {item?.arrival_time}
                                        </p>
                                      </div>
                                    </div>
                                    <div class="row pt-1">
                                      <div class="col-6 mb-3">
                                        <h6>
                                          <i class="bi bi-cart-fill" />{" "}
                                          {t("profile.weight")}:{" "}
                                          {received == 1
                                            ? item?.weight
                                            : item?.luggage_weight}{" "}
                                          Kg
                                        </h6>
                                      </div>
                                      <div class="col-12 mb-3">
                                        <h6 className="fw-bold">
                                          <i
                                            class="bi bi-arrow-down-left-circle-fill"
                                            style={{ color: "#ff4838" }}
                                          ></i>{" "}
                                          {t("profile.luggage_sender")} :{" "}
                                          <span style={{ color: "#1e98d7" }}>
                                            {received == 1
                                              ? item?.request_user_name
                                              : item?.luggager_name}
                                          </span>
                                          {/* {t('profile.from')} <span style={{ color: '#1e98d7' }}>shiprr</span> */}
                                        </h6>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-md-5 col-lg-5 col-12">
                                  <div class="card-body pt-0 pb-0">
                                    <div class="earning">
                                      <div class="plan">
                                        <header>
                                          <i class="bi bi-currency-dollar" />
                                          <h6 class="plan-title">
                                            {t("profile.payment_due")}
                                          </h6>
                                          <div class="plan-cost">
                                            <span class="plan-price fs-4">
                                              {item?.fees} {item?.currency}
                                            </span>
                                          </div>
                                        </header>
                                      </div>
                                      <div class="plan-select pt-2">
                                        <a
                                          style={{
                                            cursor: "pointer",
                                            padding: "11px 12px",
                                          }}
                                          href={`luggageCarrying/${item?.id}/${
                                            received == 1
                                              ? item?.request_type
                                              : item?.product_type
                                          }/${item?.request_type == 1?'1':'2'}/1`}
                                        >
                                          {t("profile.view_order_details")}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                  {/*  Articles(Sale) */}
                  <div
                    className="tab-pane fade package-info-tab"
                    id="pill-body2"
                    role="tabpanel"
                    aria-labelledby="pills-package2"
                    style={{ overflowX: "auto" }}
                  >
                    <div
                      class="container pt-2 h-100 package-card-delta p-3"
                      style={{ boxShadow: "none" }}
                    >
                      <h4 className="fs-5 pt-3">
                        {t(
                          "profile.Payments_due_receiving_from_article_buyers"
                        )}
                      </h4>
                      <hr />
                      <div class="container p-0 h-100">
                        <div class="row d-flex justify-content-center align-items-center h-100">
                          <div class=" col mb-4 mb-lg-0">
                            {filterLugggageDetails()?.map((item) => (
                              <div
                                class="package-card-delta card mb-3"
                                style={{ borderRadius: "5px" }}
                              >
                                <div class="row g-0">
                                  <div class="col-md-4">
                                    <img
                                      src={
                                        (received == 3 || received == 1) &&
                                        item?.image[0]
                                      }
                                      alt="..."
                                      style={{
                                        aspectRatio: "4/2",
                                        objectFit: "contain",
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    />
                                  </div>
                                  <div class="col-md-8">
                                    <div class="card-body  package-sidebar pb-0">
                                      <aside className="">
                                        <div className=" text-center d-flex justify-content-between">
                                          <h4 className="fw-bold">
                                            {item?.title}
                                          </h4>
                                          <h3
                                            className="widget-lavel"
                                            style={{ color: "#ff4838" }}
                                          >
                                            
                                            {(item?.article_price *
                                              item?.article_quantity).toFixed(2)}{contextObj?.loginUser?.symbol}{" "}
                                          </h3>
                                        </div>
                                      </aside>
                                      <hr class="mt-0 mb-4" />
                                      <div class="row">
                                        <div class="col-12 col-lg-6 col-md-6 mb-3">
                                          <p>
                                            <i
                                              class="bi bi-cart-fill fs-6"
                                              style={{ color: "#212529" }}
                                            ></i>{" "}
                                            {t("profile.weight")}:
                                            {item?.article_weight} kg
                                          </p>
                                          <p className="pt-1 fs-6">
                                            <i
                                              class="bi bi-cart-fill"
                                              style={{ color: "#212529" }}
                                            ></i>{" "}
                                            {t("profile.quantity_requested")} :{" "}
                                            {item?.article_quantity}
                                          </p>
                                          <div className="d-lg-block d-flex  d-md-block">
                                            <p>
                                              <i
                                                class="bi bi-geo-alt-fill"
                                                style={{
                                                  color: "#ff4838",
                                                  fontWeight: "500",
                                                }}
                                              />{" "}
                                              {t("form.delivery_place")}:
                                              {item?.requester_location}
                                            </p>
                                            <p class="fw-bold">
                                              <i class="bi bi-person-fill" />{" "}
                                              <span>
                                                {t("form.Requester_Name")}:
                                              </span>{" "}
                                              <sapn
                                                style={{ color: "#1e98d7" }}
                                              >
                                                {" "}
                                                {item?.requester_user_name}
                                              </sapn>
                                            </p>
                                          </div>
                                        </div>
                                        <div class="col-12 col-lg-6 col-md-6 mb-3">
                                          <div
                                            class="earning"
                                            style={{
                                              backgroundColor: "white",
                                              borderColor: "#ff4838",
                                              border: "2px solid",
                                            }}
                                          >
                                            <div
                                              class="plan"
                                              style={{
                                                background: "white",
                                                width: "100%",
                                                margin: "5px",
                                              }}
                                            >
                                              <header>
                                                <i class="bi bi-currency-dollar1"></i>
                                                <h6
                                                  class="plan-title"
                                                  style={{ color: "black" }}
                                                >
                                                  {t("profile.status")}:
                                                </h6>
                                                <div class="plan-cost">
                                                  <span class="plan-price fs-6">
                                                    {t(
                                                      "profile.payment_Pending_from_buyer"
                                                    )}
                                                  </span>
                                                  <span class="plan-type"></span>
                                                </div>
                                              </header>
                                            </div>
                                          </div>
                                          <div
                                            class="earning"
                                            style={{
                                              backgroundColor: "white",
                                              borderColor: "#ff4838",
                                              border: "2px solid",
                                            }}
                                          >
                                            <div
                                              class="plan"
                                              style={{
                                                background: "white",
                                                width: "100%",
                                                margin: "5px",
                                              }}
                                            >
                                              <header>
                                                <i class="bi bi-currency-dollar1" />
                                                <h6
                                                  class="plan-title"
                                                  style={{ color: "black" }}
                                                >
                                                  {t("profile.due_amount")}:
                                                </h6>
                                                <div class="plan-cost">
                                                  <span class="plan-price fs-5">
                                                  {(item?.article_price *
                                              item?.article_quantity).toFixed(2)} {contextObj.loginUser?.symbol}
                                                  </span>
                                                  <span class="plan-type"></span>
                                                </div>
                                              </header>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
