import React from "react";
import ViewArticlesWrapperArea from "./ViewArticlesWrapperArea";
import Breadcrumb from "../../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function ViewArticles() {
  const { t } = useTranslation();
  const { id } = useParams();
  return (
    <>
      <Breadcrumb name={id == 1 ? t('form.Cobagage_Sale') : t('form.Cobagage_Donations')} />
      <ViewArticlesWrapperArea />
    </>
  );
}

export default ViewArticles;
