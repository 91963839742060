import React from "react";
import InvoiceWrapperArea from "./InvoiceWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function Invoice() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("profile.Invoice")}/>
      <InvoiceWrapperArea />
    </>
  );
}

export default Invoice;
