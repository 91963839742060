import React from "react";
import RateOurWebsitesWrapperArea from "./RateOurWebsitesWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
function RateOurWebsite() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("profile.Rate_our_website")} />
      <RateOurWebsitesWrapperArea/>
     
    </>
  );
}

export default RateOurWebsite;
