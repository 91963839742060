import React from 'react'
import BradcomeMoreCobagage from '../../common/BradcomeMoreCobagage'
import MoreCobagageWrap from './MoreCobagageWrap'
import { useTranslation } from 'react-i18next';

export default function MoreCobagage() {
    const { t } = useTranslation();

    return (
        <div>
            <BradcomeMoreCobagage name={t('profile.More_cobagage')} />
            <MoreCobagageWrap />
        </div>
    )
}
