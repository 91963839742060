import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import { resources } from '../lang';
const defaultLang = localStorage.getItem("i18nextLng");
i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        debug: false,
        lng: defaultLang ? defaultLang : 'frFR',
        fallbackLng: 'frFR',
        resources
    });
export default i18n;