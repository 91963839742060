import React from "react";
import SendingRequestWrapperArea from "./SendingRequestWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
function SendingRequest() {
  const { t } = useTranslation();
  const { request_type} = useParams();
  return (
    <>
      <Breadcrumb name={request_type==2?t('profile.Luggage_sending_request'):t('profile.Luggage_sending_request2')}/>
      <SendingRequestWrapperArea />
    </>
  );
}

export default SendingRequest;
