import React from "react";
import AboutWrapperArea from "./AboutWrapperArea";
import BlogArea from "./BlogArea";
import GuideArea from "../../common/GuideArea";
import TestimonialArea from "../../common/TestimonialArea";
import Breadcrumb from "../../common/Breadcrumb";
import AboutPage from "../../common/AboutPage";
import { useTranslation } from "react-i18next";
function About() {
  const { t } = useTranslation();
  return (
    <>
      <AboutPage name={t("menu.about_us")} />
      <AboutWrapperArea />
      {/* <TestimonialArea />
      <GuideArea />
      <BlogArea /> */}
    </>
  );
}

export default About;
