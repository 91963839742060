const privacyPolicy={
  title:'Privacy Policy',
  applicable_from:'Applicable from December 1, 2023',
  preamble:'PREAMBLE',
  this_confedentially_policy_informs:"This confidentiality policy informs you about the way in which Cobagage uses and protects the information that you transmit to us, if applicable, when you use this site accessible from the following URL: www.cobagage.com (hereinafter the “Site”).Please note that this confidentiality policy may be modified or supplemented at any time by Cobagage, in particular with a view to complying with any legal or technological developments. In such a case, the date of its update will be clearly identified at the top of this policy. These modifications bind you as soon as they are put online. You should therefore consult this confidentiality policy regularly in order to be aware of these possible modifications.",
  article1_particle:'ARTICLE 1. PARTIES',
  this_confidential_policy_applicable_between:'This confidentiality policy is applicable between the publisher of the Site, hereinafter',
  cobagage:"“Cobagage”,",
  any_person_connecting:'and any person connecting to the Site, hereinafter',
  the_user:"“ the user”",
  site_content:"Site Content:",
  element_of_any_kind:'elements of any kind published on the Site, whether or not protected by intellectual property rights, such as texts, images, designs, presentations, videos, diagrams, structures, databases or software.',
  user:"User:",
  any_person_to_site:"any person connecting to the Site.",
  site:" Website :",
  website_accessible:'website accessible at the URL www.cobagage.com, as well as the subsites, mirror sites, portals and URL variations relating thereto.',
  article3:'ARTICLE 3. SCOPE OF APPLICATION',
  browsing_the_site_will_constitute:'This confidentiality policy is applicable to all Users. Simply connecting and/or browsing the Site will constitute your full acceptance of this confidentiality policy. Furthermore, clicking on “I accept” in the information banner relating to cookies displayed on the Site confirms your acceptance, while allowing you to personalize the cookies that will or will not be applied to you. You acknowledge at the same time that you have read them fully and accept them without restriction.',
  the_user_recognizes:"The User recognizes the evidentiary value of Cobagage's automatic check-in systems and, unless he provides proof to the contrary, he waives the right to contest them in the event of a dispute.",
  acceptance_of_this_confedential_policy:'Acceptance of this confidentiality policy assumes on the part of Users that they have the necessary legal capacity for this or that they are at least 16 years old, or failing that, that they have the authorization of a guardian or curator if they are incapable, of their legal representative if they are under 16 years of age, or that they hold a mandate if they act on behalf of a legal entity.',
  article4:'ARTICLE 4. PERSONAL DATA',
  in_accordance_with_general_data:'In accordance with the General Data Protection Regulation (GDPR) adopted by the European Parliament on April 14, 2016 and the national legislation in force, Cobagage provides you with the following information:',
  identy_of_data_controller:'Identity of the data controller',
  the_person_responsible_for_connection_of:"The person responsible for the collection and data processed on the Site is Cobagage, 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, telephone: +336 75 68 10 28, email: lyao@cobagage.com",
  identy_of_data_protection_officer:'Identity of the Data Protection Officer',
  the_data_protection_delegates:"The data protection delegate is Maître Etienne Deshoulières, 121 boulevard de Sébastopol, 75002 Paris, email address: contact@deshoulieres-avocats.com, telephone number: 01 77 62 82 03.",
  data_collection_by_cobagage:'Data collection by Cobagage',
  data_collected:'Data collected',
  data_collection_during_navigation:'Data collected during navigation and through cookies',
  user_consent_to:'When browsing the Site, the User consents to Cobagage collecting information relating to: use of the Site; the content they view and click on; its demographic data; the device used and its software environment; to its location; to their connection data (times, pages viewed, IP address, etc.); to web pages visited before or after using the Site.For any additional information relating to the configuration and use of cookies, we invite you to refer to our cookies policy www.cobagage.com/cookies',
  data_collected_while_creating_user_account:'Data collected when creating the user account',
  as_part_of_user_registration:"As part of the User's registration on the Site, Cobagage may be required to collect and process: name, first name, date of birth, email address, postal address, telephone number, banking information.",
  user_who_do_not_wish_to_provide:'Users who do not wish to provide the information required to create a User account will not be able to request registration on the Site.',
  data_collected_using_contact_form:'Data collected when using the contact form',
  the_use_of_contact_form:'The use of the contact form by the User requires the collection by Cobagage of the following personal data: name, first name, date of birth, telephone number, email address, IP address, as well as any information voluntarily transmitted by the User. User (free field).',
  data_collected_when_using_newsletter_form:'Data collected when using the newsletter form',
  as_part_of_use_of_newsletter:'As part of the use of the newsletter form, Cobagage may collect and process: your email address.',
  purpose_of_collecting_personal_data:'Purposes of collecting personal data',
  data_collected_via_cookies:'The data collected during navigation and via cookies is subject to automated processing with the aim of:',
  verify_identy_of_users:'Verify the identity of Users;',
  ensure_and_improve:'Ensure and improve the security of the Site;',
  develops_oprates_improve:'Develop, operate, improve, provide and manage the Site;',
  send_information_and_contact_the_user:'Send information and contact the User, including by email, telephone and push notification;',
  share_content_from_site:'Share content from the Site with other people or make these other people aware of your consultation or opinion, via the sharing buttons on social networks;',
  improve_user_experience:'Contextualize and improve the User experience;',
  target:'Target advertising content in particular by transmitting the IP address and URL of the pages visited to social networks;',
  avoid_any_illicit_or_illegal:'Avoid any illicit or illegal activity;',
  enforce_the_condition:'Enforce the conditions relating to the use of the Site.',
  automate_processing_with_aim_of:'The data collected during the creation of the user account is subject to automated processing with the aim of:',
  verify_identity_of_people:'Verify the identity of people;',
  communicate_with_people:'Communicate with people;',
  proceed_with_user_registration:'Proceed with User registration and carry out our service provision;',
  ensure_and_improve_service:'Ensure and improve the security of the services provided;',
  share_the_content_from_site_with_other_people:'Share content from the Site with other people or make these other people aware of your consultation or opinion;',
  contact_form_proceeded_for_the_purpose_of:'The data collected when using the contact form is processed for the purpose of:',
  data_collected_when_subscribing:'The data collected when subscribing to the newsletter is processed for the purpose of:',
  communicate_about_new_features:'Communicate about new features of the Site;',
  communicate_about_cobagae_product:'Communicate about Cobagage products and services;',
  legal_bases_for_processing:'Legal bases for processing',
  data_collected_during_navigation_via_cookies:"The data collected during navigation and via cookies has the legal basis of Cobagage's legitimate interest, namely to carry out an analysis of behavior on the Site and to obtain improved security and operation of the Site. When these data come from cookies, they may have the consent of individuals as their legal basis.The legal basis for the data collected when using the contact form is the consent of the persons concerned.",
  data_collected_during_creation_of_user_account:'The data collected during the creation of the User account has a contractual relationship as its legal basis.',
  data_collected_legal_basis:'The legal basis for the data collected when using the newsletter form is the consent of the persons concerned.',
  data_recipent:'Data recipients',
  data_collected_can_only_be_viewed:'The data collected can only be viewed by Cobagage, and is never made freely viewable by a third party.',
  duration_of_retention_of_personal_data:'Duration of retention of personal data',
  personal_data_collected_during_navigation_kept_reasonable_period:"Personal data collected during navigation and via cookies are kept for a reasonable period necessary for the proper administration of the Site and for a maximum of 13 months, or until the withdrawal of consent of the persons concerned. The personal data collected when using the contact form are kept for a reasonable period necessary for the proper management of the User's request, and for a maximum of 12 months.",
  data_collected_during_user_creation_kept_duration:'The data collected during the creation of the User account is kept for the duration of the contractual relationship between Cobagage and the User, and for the period during which Cobagage may be held liable.',
  data_collected_from_newssletter_kept_until:'The data collected when using the newsletter form is kept until the consent of the persons concerned is withdrawn.After these retention periods, Cobagage undertakes to permanently delete the data of the persons concerned.',
  security_and_confidentiality_of_personal_data:'Security and confidentiality of personal data',
  personal_data_is_stored_in_secure_condition:'Personal data is stored in secure conditions, according to current technical means, in compliance with the provisions of the General Data Protection Regulations and the national legislation in force.',
  minimization_of_personal_data:'Minimization of personal data',
  cobagage_may_also_collect_and_process:'Cobagage may also collect and process any data voluntarily transmitted by a User, in particular through the use of the contact email address.',
  cobagage_guides_users_as_much_as_possible:'Cobagage guides Users as much as possible when they provide unnecessary or superfluous personal data.',
  cobagage_undertakes_to_only:'Cobagage undertakes to only retain and process data strictly necessary for its activities, and will delete any non-useful data received as quickly as possible.',
  respect_for_rights:'Respect for rights',
  you_have_the_following_right:'You have the following rights regarding your personal data, which you can exercise by writing to us at our postal address or by completing our online contact form.',
  right_to_information:'Right to information, access and communication of data',
  you_have_the_possiblity_of_accessing:"You have the possibility of accessing personal data which concerns you.Due to Cobagage's obligation of security and confidentiality in the processing of personal data, your request will only be processed if you provide proof of your identity, in particular by producing a scan of your passport. valid identity (in the case of a request via our dedicated electronic form) or a signed photocopy of your valid identity document (in the case of a request made in writing), both accompanied by the words “I attest on the honor that the copy of this identity document conforms to the original. Done at…on…”, followed by your signature.To help you in your process, you will find here a letter template developed by the CNIL.",
  right_torectification:'Right to rectification, deletion and right to forgetting of data',
  you_have_the_possiblity_of_requesting:'You have the possibility of requesting the rectification, updating, blocking or even erasure of your personal data which may prove to be inaccurate, erroneous, incomplete or obsolete.',
  you_can_also_define_a_general:'You can also define general and specific guidelines relating to the fate of personal data after your death. If applicable, the heirs of a deceased person may demand to take the death of their loved one into consideration and/or to make the necessary updates.',
  to_help_you_in_your_process:'To help you in your process, you will find here a letter template developed by the CNIL.',
  right_to_object_data_processing:'Right to object to data processing',
  you_have_the_possiblity_to_object_the_processing_of:'You have the possibility to object to the processing of your personal data.To do this, you should send an email to the following address: contact@cobagage.fr. In this email, you will need to specify the data you wish to have deleted as well as the reasons justifying this request, except in the case of commercial prospecting.',
  right_to_data_portablity:'Right to data portability',
  you_have_the_right_to_receive_personal_data:'You have the right to receive the personal data you have provided to us in a transferable, open and readable format.',
  right_to_restriction_of:'Right to restriction of processing',
  you_have_right_that_requesting_processing_of_you:'You have the right to request that the processing of your personal data by Cobagage be limited. Thus, your data can only be kept and no longer used by Cobagage.',
  reply_duration:'Reply duration',
  cobagage_undertakes_to_respond:'Cobagage undertakes to respond to your request for access, rectification or opposition or any other additional request for information within a reasonable period of time which cannot exceed 1 month from receipt of your request.',
  complaint_to_the_competent_authoruty:'Complaint to the competent authority',
  if_you_consider_that_cobagage_is_not_responding:'If you consider that Cobabage is not respecting its obligations with regard to your personal information, you can send a complaint or a request to the competent authority. In France, the competent authority is the CNIL to which you can send a request here.',
  transfer_of_collected_data:'Transfer of collected data',
  transfer_to_partners:'Transfer to partners',
  cobagage_informs_that_we_use_authorized:'Cobagage informs you that we use authorized service providers to facilitate the collection and processing of the data that you have communicated to us.',
  cobagage_has_previously_ensured_the_implementation:'Cobagage has previously ensured the implementation by its service providers of adequate guarantees and compliance with strict conditions in terms of confidentiality, use and protection of data.',
  our_site_uses_social_media_plugin:'Our Site uses social media plug-ins (see table below). When a plugin is integrated into a page of the site, the social network logo appears on the page. When you visit a page of the Site containing a plug-in, we transmit your IP address and the URL address of the page visited on the Site to the social network concerned.',
  user_consent_to_data_collected:'The User consents to the data collected being transmitted by Cobagage to its partners and being processed by these partners within the framework of third-party services, namely:',
  partner_directory:'Partner Directory',
  partner:'Partner',
  quality:'Quality',
  recipent_country:'Recipient country',
  treatment_carried_out:'Treatment carried out',
  garanties:'Guarantees',
  sub_contractor:'Subcontractor',
  web_hosting:'Website hosting',
  sub_contractor_and_co_contractor:'Subcontractor and co-contractor',
  management_of_people:"Management of people's opinions on products, services or content.",
  development_of_commercial:'Development of commercial statistics in order to generate reports on User interactions',
  co_responsable:'Co-responsible',
  video_hosting:'Video hosting.',
  digital_marketing_and_web_development:'Digital Marketing and web development.',
  transfer_upon_requsition_or_juditial_decesion:'Transfer upon requisition or judicial decision',
  the_user_is_also_consent_to:'The User also consents to Cobagage communicating the data collected to any person, upon requisition by a state authority or by judicial decision.',
  transfer_in_context_of_merger:'Transfer in the context of a merger or acquisition',
  if_cobagage_is_involved_in_merger:"Si Cobagage est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation ou banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, L’Utilisateur consent à ce que les données collectées soient transmises par Cobagage à cette société et que cette société opère les traitements de données personnelles visés dans la présente politique confidentialité au lieu et place de Cobagage.",
  intellectual_property:'INTELLECTUAL PROPERTY',
  legal_protection_of_site:'Legal protection of Site Content',
  the_content_of_site_may_be_protected_by:'The Content of the Site may be protected by copyright and database law. Any representation, reproduction, translation, adaptation or transformation, in whole or in part, carried out illegally and without the consent of Cobagage or its successors or assigns constitutes a violation of Books I and III of the Intellectual Property Code and will be likely to give give rise to legal proceedings for counterfeiting',
  contractual_protection_of_software_content:'Contractual protection of Software Content',
  the_user_undertakes_contactually_with_cobagage:'The User undertakes contractually with Cobagage not to use, reproduce or represent, in any way whatsoever, the Content of the Site, whether or not they are protected by an intellectual property right, at any time. purpose other than that of their reading by a robot or a browser. This prohibition does not apply to indexing robots whose sole purpose is to scan the content of the Site for indexing purposes.',
  final_stipulation:'FINAL STIPULATIONS',
  this_privacy_policy_modified_at_any_time:"This Privacy Policy may be modified at any time by Cobagage. The conditions applicable to the User are those in force at the time of their connection to the Software, any new connection to the Software entailing acceptance, where applicable, of the new conditions.",
  entirely:'Entirety',
  the_nulity_of_one_of_the_clauses:'The nullity of one of the clauses of this contract will not result in the nullity of the other clauses of the contract or of the contract as a whole, which will retain their full effect and scope. In such a case, the parties must, as far as possible, replace the canceled stipulation with a valid stipulation corresponding to the spirit and purpose hereof.',
  non_waiver:'Non-waiver',
  the_absence_of_exercise_by:'The absence of exercise by Cobagage of the rights granted to it hereby cannot under any circumstances be interpreted as a waiver of the right to assert said rights.',
  LANGUAGES:'LANGUAGES',
  this_privacy_policy_available_in_french:'This privacy policy is available in French.',
  unfair_clauses:'Unfair clauses',
  the_stipulity_of_confedentiality:'The stipulations of this confidentiality policy apply subject to compliance with the mandatory provisions of the Consumer Code concerning unfair clauses in contracts concluded between a professional and a consumer.',
  disputes:'DISPUTES',
  applicable_right:'Applicable right',
  privacy_policy_subjected_to:'This Privacy Policy is subject to the application of French law and European regulations, in particular the European Data Protection Regulation.',
  Litiges:'disputes',
  pursuant_to_order_no:'Pursuant to Order No. 2015-1033 of August 20, 2015, any disputes which may arise in the context of the execution of this confidentiality policy and for which a solution cannot previously be found amicably between the parties must be submitted to Medicys',
  arbitration:'Arbitration',
  any_dispute_related_to_this:'Any dispute relating to this contract or in connection with it will be resolved by arbitration in accordance with the FastArbitre rules of the Digital Institute of Arbitration and Mediation.',



}
export default privacyPolicy;