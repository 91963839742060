import axios from '../plugin/axios';

const articlesDonation = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Article_donation/GetAllArticalDonation')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

    create(data) {
        console.log("Create data is here: ", data);
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Article_donation/Post', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetById', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    StatusReject(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetStatus/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    GetByUserId() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetByUserId')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    GetByUser(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetByUser', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    userData(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetByUserId/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation/Put', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Article_donation/Delete', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation/ValidatePost', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default articlesDonation;
