const cgvu = {
    title: 'Conditions générales – Expéditeurs   du site internet www.cobagage.com   applicables à compter du 01 décembre 2023 ',
    the_general_conditions_are_concluded: "Les présentes conditions générales sont conclues entre Cobagage Network, SASU, capital social : 1000 €, RCS de Paris : 848 506 861, siège social : 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, téléphone : +336 75 68 10 28,                           email : lyao@cobagage.com, n° de TVA intracommunautaire : FR44914381116, ci-après dénommé « Cobagage Network » et toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site pour expédier un Bien et/ou acheter un Produit, ci-après dénommée « l’Expéditeur ».",
    good: '« Bien » ',
    property_of_any_kind_held_by_sender: 'bien de toute nature détenu par l’Expéditeur et adressé à un Destinataire par l’intermédiaire d’un Voyageur',
    site_content: '« Contenus du Site » ',
    element_of_any_kind_published_on_site: 'éléments de toute nature publiée sur le Site, protégés ou non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas, structures, bases de données ou logiciels. ',
    recipient: '« Destinataire » ',
    any_person_natural_legal: 'toute personne, physique ou morale, de droit privé ou de droit public, destinataire finale du Bien expédiée par l’Expéditeur et transporté par le Voyageur.',
    traveler: '« Voyageur » ',
    any_person_natural_or_legal_registered_on_site: 'toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site pour transporter des Biens et/ou vendre des Produits.',
    internet_user: '« Internaute » ',
    any_person_connecting_to_site: 'toute personne, physique ou morale, de droit privé ou de droit public, se connectant au Site.',
    product: '« Produit » ',
    goods_of_any_kind: 'bien de toute nature vendu sur le Site par un Voyageur à un Expéditeur.',
    service: '« Service » ',
    service_offered_by_cobagage_on_site: 'service proposé par Cobagage sur le Site et consistant en la mise à disposition d’une plateforme en ligne permettant l’expédition de Biens et /ou la vente de Produits entre Voyageurs et Expéditeur.',
    site: '« Site » ',
    website_accessible_at_url: 'site internet accessible à l’URL www.Cobagage.com, ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.',
    scope: 'CHAMP D’APPLICATION',
    the_site_is_freely_accessible_to_any: "Le Site est d'accès libre et gratuit à tout Expéditeur. La navigation sur le Site suppose l'acceptation par tout Expéditeur des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des présentes conditions générales. Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de cocher la case correspondant à la phrase suivante : « Je reconnais avoir lu et accepté les conditions générales de vente et d'utilisation ». L'Expéditeur reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.",
    checking_the_above_mentioned_boxes_will_be_deemed: "Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part de l’Expéditeur. L'Expéditeur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de Cobagage et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.",
    these_general_conditions_are_applicable_to: 'Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles de l’Expéditeur.',
    acceptance_of_these_general_conditions: "L'acceptation des présentes conditions générales suppose de la part des Expéditeurs qu'ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.",
    purpose_of_the_site: 'OBJET DU SITE',
    the_purpose_of_site_is_to_provide_an: 'Le Site a pour objet la mise à disposition d’une plateforme électronique en ligne permettant l’expédition de Biens et/ou la vente de Produits entre Expéditeurs et Voyageurs. ',
    cobagaging_obligation: 'OBLIGATIONS DE COBAGAGE',
    provision_of_the_platform: 'Mise à disposition de la plateforme',
    cobagage_undertakes_to_provide_the_traveller_with_an: 'Cobagage s’engage à mettre à disposition du Voyageur une plateforme en ligne lui permettant de proposer le transport de Biens et/ou de vendre ses Produits',
    site_maintenance: 'Maintenance du Site',
    cobagage_undertakes_to_host_an_site: 'Cobagage s’engage à héberger et à assurer la maintenance du Site à tout moment, en dehors des périodes de maintenance.',
    in_this_regard_cobagage_will_bear_all: 'Cobagage supportera à cet égard tous les frais exposés à l’occasion de l’exécution du présent contrat. Cobagage fera son affaire personnelle et supportera la charge de tous impôts et taxes fiscaux et parafiscaux, ainsi que toutes les cotisations sociales lui incombant à ce titre.',
    obligation_of_sender: 'OBLIGATIONS DE L’EXPEDITEUR',
    publication_of_an_announcement: 'Publication of an announcement requesting the transport of a Good',
    publication_announcement: 'Publication de l’annonce',
    publication_of_add_is_open_to: "La publication d’une annonce est ouverte à tout Expéditeur. Les annonces devront être rédigées dans un français correct et compréhensible. Elles devront se conformer aux dispositions légales en vigueur et devront être dépourvues de tout critère discriminatoire quant à la qualité de l'éventuel cocontractant.",
    the_sender_acknowledges: 'L’Expéditeur reconnaît que la publication de son annonce sur le présent Site emporte automatiquement et sans intervention de Cobagage publication de ladite annonce sur les sites partenaires et affiliés.',
    it_is_expressively_brought_to_attention: "Il est expressément porté à la connaissance des Expéditeurs que Cobagage ne garantit en aucun cas l'exactitude des données contenues dans les annonces. Il appartient à tout Voyageur intéressé par l’annonce de s'assurer de l'exactitude de toutes ces données préalablement à toute transaction effective. ",
    add_content: 'Contenu de l’annonce',
    the_sender_must_specify_the_following_request: 'L’Expéditeur devra préciser les informations suivantes sur l’annonce :',
    the_place_and_approximate_date_of_departure: 'Le lieu et la date approximative du départ ;',
    the_possible_mode_of_transport_desired: 'Le mode de transport éventuellement souhaité ;',
    the_place_time_and_date_of_arrival: 'Le lieu, la date et l’heure de l’arrivée ;',
    the_identity_of_recipient: 'L’identité du Destinataire ;',
    the_number_of_killogram_necessary: 'Le nombre de kilogrammes nécessaires pour le transport du Bien',
    purchase_of_product_by_shipper: 'Achat d’un Produit par un Expéditeur',
    purchase_of_product: 'Achat d’un Produit',
    the_purchase_of_product_on_site_is: 'L’achat d’un Produit sur le Site se fait uniquement auprès d’un Voyageur en répondant à une annonce. En cliquant sur le bouton prévu à cet effet sur le Site, l’Expéditeur peut acheter un Produit au prix proposé par le Voyageur.',
    aquisition_of_product_as_part_of: 'Acquisition d’un Produit dans le cadre d’un don',
    the_sender_has_the_possiblity_of_aquiring: 'L’Expéditeur a la possibilité d’acquérir un Produit dans le cadre d’un don. Dans ce cas, l’Expéditeur doit être titulaire d’un abonnement spécifique souscrit sur le Site dans les conditions prévues aux présentes conditions générales.',
    validation_of_purchase: 'Validation de l’achat',
    once_order_has_been_placed: "Une fois la commande réalisée, les Expéditeurs pourront la valider. Ils accéderont alors à un formulaire sur lequel ils pourront soit saisir leurs identifiants de connexion s'ils en possèdent déjà, soit s'inscrire sur le Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.",
    payment_by_sender: 'Paiement par l’Expéditeur',
    as_soon_as_they_are_connected: "Dès lors qu'ils seront connectés ou après qu'ils auront parfaitement complété le formulaire d’inscription, les Expéditeurs seront invités à contrôler ou modifier leurs coordonnées de livraison et de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet sur l'interface de paiement sécurisée comportant la mention « commande avec obligation de paiement » ou toute formule analogue. ",
    confirmation_of_order_by_cobagage: 'Confirmation de la commande par Cobagage',
    once_payment_has_actually_been_received: "Une fois le paiement effectivement reçu par Cobagage, ce dernier s'engage à en accuser réception à l’Expéditeur par voie électronique, dans un délai maximal de 24 heures. Dans le même délai, Cobagage s'engage à adresser à l’Expéditeur un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant toutes les informations y afférant.",
    Delivery: 'Livraison',
    delivery_of_the_product_will_be_made:'La livraison du Produit sera réalisée directement par le Voyageur.',
    taking_out_a_subscription:'Souscription d’un abonnement',
    subscription_purchase:'Souscription de l’abonnement',
    in_order_to_benifit_from_certain_service:'Afin de profiter de certains Services proposés par Cobagage, les Expéditeurs doivent souscrire un abonnement. Dans ce cadre, l’Expéditeur devra renseigner ses informations de facturation sur le formulaire prévu à cet effet sur le Site. Les caractéristiques de l’abonnement (notamment son prix et sa durée initiale) sont précisées sur le Site.',
    subscription_duraion:'Durée de l’abonnement',
    the_subscription_takes_effect_from:'L’abonnement prend effet à compter de sa souscription par l’Expéditeur sur le Site. La durée de l’abonnement est celle mentionnée sur le Site au jour de la souscription.  L’abonnement est conclu pour une durée déterminée. A l’arrivée du terme, il ne sera pas tacitement reconduit ',
    early_termination_of_subscription:'Résiliation anticipée de l’abonnement',
    in_the_event_of_failure_by_shipper:'En cas de manquement de l’Expéditeur à l’une des stipulations prévues aux articles 6, 7 et 9 des présentes conditions générales, Cobagage pourra résilier l’abonnement et bloquer, le cas échéant, l’accès à l’espace personnel de l’Expéditeur jusqu’à résolution dudit manquement. La résiliation de l’abonnement au tort de l’Expéditeur est sans préjudice des éventuels dommages-intérêts auxquels Cobagage pourrait prétendre du fait du manquement de l’Expéditeur.',
    declaration_and_obligation:'DECLARATIONS ET OBLIGATIONS DE L’EXPÉDITEUR',
    sender_information:'Information de l’Expéditeur',
    the_shipper_declares_that:'L’Expéditeur déclare avoir reçu de Cobagage toutes les explications et précisions utiles pouvant lui permettre d’utiliser les Services objet du présent contrat.',
    the_shipper_acknowledges_that_its_needs:'L’Expéditeur reconnaît que ses besoins et les Services proposés par Cobagage sont en adéquation et qu’il a souscrit au contrat en connaissance de cause et en disposant de toutes les informations nécessaires lui permettant de produire un consentement libre et éclairé. ',
    the_shipper_undertakes_to_inform:'L’Expéditeur s’engage à informer Cobagage de toute circonstance susceptible d’affecter l’exécution du présent contrat dès qu’il en a connaissance.',
    authorization_and_declarion:'Autorisations et déclarations',
    the_sender_is_solely_responsible_for:'L’Expéditeur est seul responsable des autorisations et déclarations relatives à l’utilisation des Services. L’Expéditeur déclare qu’il dispose des droits et autorisations nécessaires à cette fin. Le cas échéant, l’Expéditeur déclare avoir effectué au préalable toute démarche nécessaire, telle que demandes d’autorisations et déclarations administratives. ',
    the_lack_of_such_declarion:'Le défaut de telles déclarations et autorisations ne pourra en aucun cas remettre en cause la validité du présent contrat. L’Expéditeur restera notamment tenu de régler à Cobagage les sommes dues. L’Expéditeur garantit Cobagage contre tout recours qui serait entrepris à son encontre en cas de défaut de telles déclarations et autorisations.',
    charter_of_good_conductor:'Charte de bonne conduite',
    the_sender_undertakes_not_to_publish:'L’Expéditeur s’engage à ne pas publier d’annonce sur le Site pouvant :',
    harm_or_make_comment:"porter atteinte ou avoir de propos contraires à l'ordre public, aux bonnes mœurs ou pouvant heurter la sensibilité des mineurs ;",
    infringe_in_any_right:"porter atteinte de quelque manière que ce soit aux droits à la réputation, à la vie privée, au droit ou à l'image d’un tiers ;",
    be_denigrating_defamatory:"être dénigrant, diffamatoire, porter atteinte à l'image, à la réputation d'une marque ou d'une quelconque personne physique ou morale, de quelque manière que ce soit ;",
    make_comment_of_a_pedophilic:'avoir des propos à caractère pornographique ou pédophile ;',
    undermine_the_security_or_integraty:"porter atteinte à la sécurité ou à l'intégrité d'un Etat ou d'un territoire, quel qu'il soit",
    allows_third_parties_to_obtain:'permettre à des tiers de se procurer des logiciels piratés, des numéros de série de logiciels ou tout logiciel pouvant nuire ou porter atteinte, de quelque manière que ce soit, aux droits ou aux biens des tiers ;',
    the_intellectual_property:'porter atteinte aux droits de propriété intellectuelle de quelque personne que ce soit ;',
    insite_hatred_voilence:"inciter à la haine, à la violence, au suicide, au racisme, à l'antisémitisme, à la xénophobie, à l'homophobie, faire l'apologie des crimes de guerre ou des crimes contre l'humanité ;",
    insite_to_commit_a_crome:'inciter à commettre un crime, un délit ou un acte de terrorisme ;',
    insite_descrimination_against:"inciter à la discrimination d'une personne ou d'un groupe de personnes en raison de son appartenance à une ethnie, à une religion, à une race, ou du fait de son orientation sexuelle ou de son handicap.",
    advice_a_questionable:' conseiller une pratique douteuse ou frauduleuse.',
    any_processing_transmission:"Tout traitement, transmission, publication, diffusion ou représentation des annonces par l’Expéditeur est effectué sous sa seule et entière responsabilité. L’Expéditeur s'engage à ne pas entraver ou perturber le Site et les serveurs de Cobagage et à se conformer aux conditions requises, aux procédures, aux règles générales qui lui sont communiquées par Cobagage pour la publication des annonces.",
    any_illegal_or_generally:"Tout usage illégal ou de manière générale non autorisé du Site entrainera la suppression immédiate du compte de l’Expéditeur, sans préjudice des éventuels dommages-intérêts auxquels Cobagage pourrait prétendre. ",
    the_shipper_therefore_guarentees:'L’Expéditeur garantit en conséquence Cobagage contre tout dommage susceptible de lui être causé du fait de son utilisation du Site, en ce compris les éventuels frais d’avocat et de procédure, et s’engage à ce titre à intervenir à toute instance judiciaire engagée à son encontre du fait de son utilisation du Site.',
    price_and_payment:'PRIX - PAIEMENT',
    price:'Prix',
    the_applicable_price_are_those:"Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à tout moment par les Voyageurs s’agissant des Produits et par Cobagage s’agissant des abonnements. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas effet pour l'avenir.",
    the_price_indicated_on_site:'Les prix indiqués sur le Site sont entendus en euros, toutes taxes comprises, hors frais de livraison.',
    payment_terms:'Modalité de paiement',
    shpper_can_make_payment_by_credit_card:"L’Expéditeur peut effectuer son règlement par carte bancaire. Les paiements par carte bancaire se font au moyen de transactions sécurisées fournies par Qonto et/ou Revolut. Dans le cadre des paiements par carte bancaire, Cobagage n'a accès à aucune donnée relative aux moyens de paiement de l’Expéditeur. Le paiement est effectué directement entre les mains de l'établissement bancaire. ",
    billing:'Facturation',
    cobagage_will_make_or_send:'Cobagage adressera ou mettra à disposition de l’Expéditeur une facture par voie électronique après chaque paiement. L’Expéditeur accepte expressément de recevoir les factures par voie électronique.',
    failure_to_pay:'Défaut de paiement',
    agreed_payment_date_can_not_be_delayed:'Les dates de paiement convenues ne peuvent être retardées sous quelque prétexte que ce soit, y compris en cas de litige.',
    any_sum_not_paid_on_due_date:'Toute somme non payée à l’échéance donnera lieu, de plein droit et sans mise en demeure, à l’application de pénalités de retard calculées sur la base d’un taux égal à 3 fois le taux d’intérêt légal, sans que cette pénalité nuise à l’exigibilité des sommes dues en principal.',
    in_addition_any_late_payment_will_result:"En outre, tout retard de paiement aura pour conséquence la facturation à l’Expéditeur défaillant de frais de recouvrement d'un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restantes dues quels que soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la possibilité de résilier le contrat unilatéralement au tort de l’Expéditeur. La présente clause s’inscrit dans le cadre des dispositions de l’article 1152 du code civil permettant au juge de réduire l’indemnité si le juge estime qu’elle est excessive.",
    complaint_withdrawal:'RECLAMATION - RETRACTATION',
    customer_service:'Service clientèle',
    site_customer_service_is_accessible_from:'Le service clientèle du Site est accessible du +336 75 68 10 28  de 10 heures du matin à 18 heures du soir ou par courrier électronique',
    or_by_post_to_the_address:"ou par courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. Dans ces deux derniers cas, Cobagage s'engage à apporter une réponse sous 2 jours ouvrés.",
    right_withdrawal_distance:'Droit de rétractation – Vente à distance',
    the_sell_being_made_between:'La vente étant réalisée entre Expéditeurs et Voyageurs non professionnels, aucun droit de rétractation ne peut être exercé.',
    personal_space:'ESPACE PERSONNEL',
    creation_of_personal_space:'Creation of personal space',
    creation_of_personal_space_is_an_essential:"La création d'un espace personnel est un préalable indispensable à toute publication d’une annonce par un Expéditeur sur le Site. A cette fin, l’Expéditeur sera invité à fournir un certain nombre d'informations personnelles. Certaines de ces informations sont réputées indispensables à la création de l'espace personnel. Le refus par un Expéditeur de fournir lesdites informations aura pour effet d'empêcher la création de l'espace personnel ainsi que, incidemment, la validation de l’annonce. ",
    when_creating_personal_space:"Lors de la création de l'espace personnel, l’Expéditeur est invité à choisir un mot de passe. Ce mot de passe constitue la garantie de la confidentialité des informations contenues dans l’espace personnel. L’Expéditeur s'interdit donc de le transmettre ou de le communiquer à un tiers. A défaut, Cobagage ne pourra être tenu pour responsable des accès non autorisés à l’espace personnel d'un Expéditeur.",
    the_sender_undertakes_to_carry_out:'L’Expéditeur s’engage à procéder à une vérification régulière des données qui le concernent et à procéder en ligne, depuis son espace personnel, aux actualisations et modifications nécessaires.',
    content_of_personal_space:'Contenu de l’espace personnel',
    generalities:'Généralité',
    the_personal_space_allows_the_sender:'L’espace personnel permet à l’Expéditeur de consulter et de suivre toutes ses annonces publiées sur le Site.',
    the_pages_relating_to_personal_spaces_are:"Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un tribunal. Elles n'ont qu'un caractère informatif destiné à assurer une gestion efficace de ses commandes par l’Expéditeur.",
    cobagage_undertakes_to_securely_stire_all:"Cobagage s'engage à conserver de façon sécurisée tous les éléments contractuels dont la conservation est requise par la loi ou la réglementation en vigueur.",
    posting_a_public_profile:"Publication d'un profil public ",
    sender_are_offered_option_of_creating:"Les Expéditeurs se voient offrir la faculté de se constituer un profil public sur le Site. Dans ce cadre, les Expéditeurs pourront renseigner un certain nombre d'informations personnelles les concernant. Tous les profils publics seront soumis à validation préalable de Cobagage ou de son équipe de modérateurs avant leur première mise en ligne. ",
    it_is_expressively_understood_that_the_sender:"Il est expressément entendu que l’Expéditeur, en décidant de souscrire aux services offerts par Cobagage et en complétant la fiche relative à son profil public, reconnaît que ces données, et notamment sa photographie, sont susceptibles d'être publiées sur le Site et diffusées à ce titre auprès des autres Expéditeurs et Internautes. Le simple fait de fournir ces informations et de compléter les champs de la fiche de création du profil public emportera manifestation expresse de la volonté de l’Expéditeur de publier lesdites informations sur le Site. Les Expéditeurs disposeront de la faculté de faire cesser la diffusion de leur profil public. Ils devront, pour cela, demander la suppression de leur compte Expéditeur et la résiliation des services, en suivant la procédure prévue à cet effet. La suspension de la diffusion du profil public sera effective dans un délai maximal de 3 jours ouvrés à compter de la réception de la demande par Cobagage.",
    internal_messeging:'Messagerie interne',
    an_internal_private_messeging_service:'Il est mis à disposition des Expéditeurs un service de messagerie privée interne. Ce système est réservé aux Expéditeurs et le secret des correspondances lui est applicable. ',
    the_content_of_inboxes_and_outgoings:"Le contenu des boîtes de réception et d'envoi ne fait l'objet d'aucune garantie de conservation de la part de Cobagage et il appartient aux Expéditeurs de sauvegarder lesdits contenus. La perte de ces contenus, quelle qu'en soit la cause, ne pourra constituer un dommage pour l’Expéditeur qui ne pourra prétendre à aucune indemnité de ce fait.",
    any_sender_who_is_victim_of_abuse:"Tout Expéditeur qui sera victime d'abus (spams, publicités non souhaitées ou autre) pourra en informer Cobagage qui prendra toutes mesures utiles. ",
    contribution_from_sender:'Contributions des Expéditeurs',
    shipper_are_offered_the_option_of:'Les Expéditeurs se voient offrir la faculté de contribuer au contenu du Site par la publication de commentaires sur leur utilisation des Produits et leur relation avec Cobagage. Les commentaires devront être apportés en français. Ils seront soumis à validation de Cobagage ou de son équipe de modérateurs.',
    by_clicking_on_the_validate_tab:"En cliquant sur l'onglet « Valider », afin de publier son commentaire, l’Expéditeur accorde une licence de droit d'auteur non exclusive à titre gratuit à Cobagage portant sur ledit commentaire. A ce titre, l’Expéditeur autorise Cobagage à communiquer au public en ligne, en tout ou partie, son commentaire sur le Site, les newsletters de Cobagage et les sites des partenaires de Cobagage. L’Expéditeur autorise Cobagage à reproduire son commentaire aux fins de le communiquer au public en ligne et de réaliser des supports de communication et de promotion du Site. L’Expéditeur autorise Cobagage à traduire dans toute langue son commentaire aux fins de le communiquer au public en ligne et de le reproduire sur ses supports commerciaux et promotionnels. Le droit d'adaptation comprend également le droit d'effectuer les modifications techniquement nécessaires à l'exploitation du commentaire dans d'autres formats. La présente licence est accordée pour une utilisation commerciale et publicitaire. Elle est accordée pour toute la durée des droits, pour une exploitation en France et à l'étranger.",
    the_sender_declares_that_it_has_the:"L’Expéditeur déclare disposer de la propriété intellectuelle et des autorisations nécessaires pour procéder à la publication de son commentaire. L’Expéditeur s'engage à intervenir sur toute demande de Cobagage à toute instance engagée contre cette dernière du fait de son commentaire et à le garantir des éventuels frais et condamnations prononcés à son encontre de ce fait, en ce compris les éventuels frais d'avocat.",
    deletion_of_personal_space:'Suppression de l’espace personnel',
    cobagage_reserves_right_to_delete:'Cobagage se réserve le droit de supprimer le compte de tout Expéditeur qui contrevient aux présentes conditions générales, notamment lorsque l’Expéditeur fournit des informations inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace personnel d’un Expéditeur sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute de Cobagage ou un dommage pour l’Expéditeur exclu, qui ne pourra prétendre à aucune indemnité de ce fait.',
    this_exclusion_is_without:"Cette exclusion est sans préjudice de la possibilité, pour Cobagage, d'entreprendre des poursuites d'ordre judiciaire à l'encontre de l’Expéditeur, lorsque les faits l'auront justifié.",
    personal_data:'DONNEES PERSONNELLES',
    as_part_of_service_cobagage_will_require:'Dans le cadre de sa prestation, Cobagage va être amené à traiter des données à caractère personnel de ses Expéditeurs.',
    identity_of_data_controller:'Identité du responsable du traitement',
    the_person_responsible_fora_collection_of:'Le responsable de la collecte et des données traitées sur le Site est Cobagage.',
    identity_of_data_protection_officer:'Identité du Délégué à la protection des données',
    data_protection_officer_is:"Le délégué à la protection des données est : Etienne Deshoulières, 121 boulevard de Sébastopol 75002 Paris, contact@deshoulieres-avocats.com, 01 77 62 82 03, www.deshoulieres-avocats.com en prenant toujours soin de créer un lien hypertexte sur l'URL de notre site",
    data_collected:'Données collectées',
    data_collected_from_shippers:'Données collectées auprès des Expéditeurs',
    as_part_of_contractual_relationships:'Dans le cadre de ses relations contractuelles, Cobagage peut être amené à collecter et traiter des informations de ses Expéditeurs, à savoir : les noms, prénoms, fonction, nom de la société, numéro de téléphone, adresses postales, adresses mails, coordonnées bancaires, historique des contrats.',
    purpose_of_collecting_data:'Finalités de la collecte de données personnelles',
    data_collected_during_contractual_relationships:"Les données collectées lors de la relation contractuelle font l'objet d'un traitement automatisé ayant pour finalité de :",
    executes_contractual_commitments:'exécuter les engagements contractuels ;',
    contact_the_shipper:'contacter les Expéditeurs ; ',
    avoid_any_illicit_or:'éviter toute activité illicite ou illégale ; ',
    enfores_the_general_conditon:'faire respecter les conditions générales ;',
    initiates_legal_proceeding:'engager des procédures judiciaires ;',
    verify_identity_of_sender:"vérifier l'identité des Expéditeurs ;",
    legal_bases_for_proceedings:'Bases juridiques du traitement',
    the_data_collected_has_legal_basis_of_contractual:'Les données collectées ont pour base juridique une relation contractuelle.',
    data_recipient:'Destinataires des données',
    data_collected_can_only_be_consulted:'Les données collectées sont consultables uniquement par Cobagage dans les limites strictement nécessaires à l’exécution des engagements contractuels.',
    the_data_whether_in_individual:'Ces données, que ce soit sous forme individuelle ou agrégée, ne sont jamais rendues librement visualisables par une personne physique tierce.',
    duration_of_retention_of_personal:'Durée de conservation des données personnelles',
    ther_personal_data_personal_collected:'Les données personnelles collectées sont conservées pendant le temps de la relation contractuelle, et pendant le temps durant lequel la responsabilité de Cobagage peut être engagée. ',
    after_the_retention_period:'Passé le délai de conservation, Cobagage s’engage à supprimer définitivement les données des personnes concernées sans en conserver une copie.',
    security_and_confidentiality_of_data:'Sécurité et confidentialité des données personnelles',
    personal_data_is_stored_in_secure:'Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions du Règlement général sur la protection des données et de la législation nationale en vigueur. L’accès aux locaux de Cobagage est également sécurisé.',
    data_minimization:'Minimisation des données',
    cobagage_may_also_collect_and_process:'Cobagage peut également collecter et traiter toute donnée transmise volontairement par ses Expéditeurs.',
    cobagage_directs_it:'Cobagage oriente ses Expéditeurs afin qu’il fournisse des données à caractère personnel strictement nécessaires à l’exécution des engagements contractuels. ',
    cobagage_undertakes_only_to_retain:'Cobagage s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités professionnelles, et supprimera toute donnée reçue non utile à ses activités dans les plus brefs délais.',
    respect_for_rights:'Respect des droits',
    cobagage_shippers_have_following_rights:'Les Expéditeurs de Cobagage disposent des droits suivants concernant leurs données personnelles, qu’ils peuvent exercer en écrivant à l’adresse postale de Cobagage  ou en remplissant le formulaire contact en ligne',
    right_of_information_access:'Droit d’information, d’accès et de communication des données',
    cobagage_shipper_have_possiblity:'Les Expéditeurs de Cobagage ont la possibilité d’accéder aux données personnelles qui les concernent',
    due_to_cobagage_obligation_of_security:'En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui incombe à Cobagage, les demandes seront uniquement traitées si les Expéditeurs rapportent la preuve de leur identité, notamment par la production d’un scan de leur titre d’identité valide (en cas de demande par le formulaire électronique dédié) ou d’une photocopie signée de leur titre d’identité valide (en cas de demande adressée par écrit), tous deux accompagnés de la mention « j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait à … le … », suivie de leur signature.',
    to_help_them_in_their_process:'Pour les aider dans leur démarche, les Expéditeurs trouveront ici un modèle de courrier élaboré par la Cnil.',
    right_to_rectification_deletion:'Droit de rectification, de suppression et droit à l’oubli des données',
    cobagage_shippers_have_possiblity_of_requesting:'Les Expéditeurs de Cobagage ont la possibilité de demander la rectification, la mise à jour, le verrouillage ou encore l’effacement de leurs données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.',
    cobagage_shippers_may_also_define_general:'Les Expéditeurs de Cobagage peuvent également définir des directives générales et particulières relatives au sort des données à caractère personnel après leur décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.',
    right_to_object_to_data_processing:'Droit d’opposition au traitement de données',
    cobagage_shippers_have_possiblity_to_object:'Les Expéditeurs de Cobagage ont la possibilité de s’opposer à un traitement de leurs données personnelles. ',
    right_to_data_portablity:'Droit à la portabilité des données',
    cobagage_shippers_have_right_to:'Les Expéditeurs de Cobagage ont le droit de recevoir les données personnelles qu’ils ont fournies à Cobagage dans un format transférable, ouvert et lisible.',
    right_to_retriction_of_processing:'Droit à la limitation du traitement',
    cobagage_shippers_have_rights_to_request_their:'Les Expéditeurs de Cobagage ont le droit de demander que le traitement de leurs données personnelles par Cobagage soit limité. Ainsi, leurs données ne pourront qu’être conservées et non plus utilisées par Cobagage.',
    reply_duration:'Délais de réponse ',
    cobagage_responds_to_undertakes_to_any_request:"Cobagage s’engage à répondre à toute demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception de la demande.",
    complaint_to_the_competent_authority:'Plainte auprès de l’autorité compétente',
    if_the_cobagage_shippers_consider_that_cobagage:'Si les Expéditeurs de Cobagage considèrent que Cobagage ne respecte pas ses obligations au regard de leurs données à caractère personnel, ils peuvent adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la Cnil à laquelle ils peuvent adresser une demande ici.',
    transfer_of_collected_data:'Transfert des données collectées ',
    transfer_to_partner:'Transfert à des partenaires ',
    cobagage_uses_authorized:'Cobagage a recours à des prestataires habilités pour faciliter la collecte et le traitement des données de ses Expéditeurs. Ces prestataires peuvent être situés en dehors de l’Union Européenne.',
    cobagage_has_previously_ensured:'Cobagage s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par exemple via le Privacy Shield états-unien.',
    cobagage_uses_following_subcontractor:'Cobagage fait appel aux sous-traitants suivants :',
    transfer_upon_requisition:'Transfert sur réquisition ou décision judiciaire',
    the_shipper_also_consent_to:'Les Expéditeurs consentent également à ce que Cobagage communique les données collectées à toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire. ',
    transfer_in_context_of_merger:"Transfert dans le cadre d'une fusion ou d'une acquisition",
    if_cobagage_involved_in_merger_sale_of_asset:"Si Cobagage est impliqué dans une fusion, une vente d'actifs, une opération de financement, une liquidation ou banqueroute ou dans une acquisition de tout ou partie de son activité par une autre société, les Expéditeurs consentent à ce que les données collectées soient transmises par Cobagage à cette société et que cette société opère les traitements de données personnelles visés dans les présentes Conditions générales de service au lieu et place de Cobagage.",
    cobagaging_responsiblity:'RESPONSABILITE DE COBAGAGE',
    nature_of_cobagaging_application:'Nature des obligations de Cobagage',
    cobagage_undertakes_to_provide_diligence:"Cobagage s'engage à apporter le soin et la diligence nécessaire à la fourniture de Service de qualité conforme aux spécifications des présentes Conditions générales. Cobagage ne répond que d'une obligation de moyens concernant les services objet des présentes.",
    forces_majeur_part_of_shipper:'Force majeure - Faute de l’Expéditeur',
    cobagage_will_not_be_held_liable:"Cobagage n'engagera pas sa responsabilité en cas de force majeure ou de faute de l’Expéditeur, telles que définies au présent article :",
    for_the_purpose_of_these_general_condition:"Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable à l’Expéditeur tout empêchement, limitation ou dérangement du Service du fait d'incendie, d'épidémie, d'explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d'accès, de défaillance des réseaux de transmission, d'effondrement des installations, d'utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis à l’Expéditeur, de piratage informatique, d'une faille de sécurité imputable à l'hébergeur du Site ou aux développeurs, d'inondation, de panne d'électricité, de guerre, d'embargo, de loi, d'injonction, de demande ou d'exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances hors du contrôle raisonnable de Cobagage. Dans de telles circonstances, Cobagage sera dispensé de l'exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.",
    fault_of_sender:'Faute de l’Expéditeur',
    for_the_purpose_of_these_general_conditons_any_misuse:'Au sens des présentes Conditions générales, sera considéré comme une faute de l’Expéditeur opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par Cobagage sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et références de l’Expéditeur, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une faute de l’Expéditeur la mise en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.',
    technical_problems_hyperlink:'Problèmes techniques - Liens hypertextes',
    in_the_event_of_impossiblity_of:"En cas d'impossibilité d'accès au Site, en raison de problèmes techniques de toutes natures, l’Expéditeur ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité. L'indisponibilité, même prolongée et sans aucune durée limitative, d'un ou plusieurs services en ligne, ne peut être constitutive d'un préjudice pour les Expéditeurs et ne peut aucunement donner lieu à l'octroi de dommages et intérêts de la part de Cobagage.  Les liens hypertextes présents sur le Site peuvent renvoyer sur d'autres sites internet. La responsabilité de Cobagage ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la responsabilité de Cobagage ne saurait être engagée si la visite, par l'Expéditeur, de l'un de ces sites, lui causait un préjudice.",
    damages_payable_by_cobagages:'Dommages-intérêts à la charge de Cobagage',
    in_the_absense_of_legal_or_regulatory:"A défaut de dispositions légales ou réglementaires contraires, la responsabilité de Cobagage est limitée au préjudice direct, personnel et certain subi par l’Expéditeur et lié à la défaillance en cause. Cobagage ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les pertes de commandes, les atteintes à l'image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts mis à la charge de Cobagage ne pourra en tout état de cause excéder le prix du Produit commandé.",
    hypertext_link_and_context_of_site:'Liens hypertextes et contenus du Site',
    the_content_of_site_is_published:"Les Contenus du Site sont publiés à titre indicatif, sans garantie d'exactitude. Cobagage ne peut en aucun cas être tenu responsable du fait d'une omission, d'une inexactitude ou de toute erreur contenue dans ces informations et qui serait à l'origine d'un dommage direct ou indirect causé au Voyageur.",
    responsiblity_as_host:"Responsabilité en qualité d'hébergeur",
    data_in_perticular_comment:'Les données, notamment commentaires, publiées sur le Site par l’Expéditeur le sont sous sa propre responsabilité.',
    in_this_context_cobagage_will_benifits:"Dans ce cadre, Cobagage bénéficiera du statut d'hébergeur des données au sens de l'article 6-I-2 de la Loi pour la confiance dans l'économie numérique du 21 juin 2004. Conformément à l'alinéa 3 du même article, Cobagage ne pourra voir sa responsabilité civile ou pénale engagée à raison de ces commentaires, à moins que, dès le moment où il a eu connaissance de l'activité ou de l'information illicite, il n'ait agi promptement pour retirer ces informations ou en rendre l'accès impossible",
    intectual_properties:'PROPRIETE INTELLECTUELLE ',
    legal_protection_of_site_content:'Protection légale des Contenus du Site',
    the_content_of_site_may_be_procted:"Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données. Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de Cobagage ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires pour contrefaçon. ",
    conntractual_process_of_site_content:'Protection contractuelle des Contenus du Site',
    the_internet_user_undertakes:"L'Internaute s'engage contractuellement à l'égard de Cobagage à ne pas utiliser, reproduire ou représenter, de quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins d'indexation.",
    final_stupulation:'STIPULATIONS FINALES',
    applicable_right:'Droit applicable',
    these_general_condition_subjected_to:"Les présentes conditions générales sont soumises à l'application du droit français. ",
    modification_these_general_condition:'Modifications des présentes conditions générales',
    these_general_conditon_may_be_modified_at:"Les présentes conditions générales peuvent être modifiées à tout moment par Cobagage. Les conditions générales applicables à l’ Expéditeur sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles conditions générales. ",
    disputes:'Litiges',
    pursuance_to_ordinance:"En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de l'exécution des présentes conditions générales et dont la solution n’aura pu être trouvée préalablement à l’amiable entre les parties devra être soumis à Medicys : ",
    in_addition_the_sender_is_informed:'En outre, l’Expéditeur est informé de l’existence de la plateforme de règlement en ligne des litiges, accessibles à l’adresse URL suivante : ',
    any_disputes_related_to_this_contract:'Tout litige relatif au présent contrat ou en relation avec celui-ci sera tranché par voie d’arbitrage conformément au règlement de l’Institut digital d’arbitrage et de médiation : ',
    entirely:'Entirety',
    the_nullity_of_one_of_the_claauses:"La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l'esprit et à l'objet des présentes.",
    non_waiver:'Non-renonciation',
    the_absence_of_exercise_by_cobagage:"L'absence d'exercice par Cobagage des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.",
    telephone_canvacassing:'Démarchage téléphonique',
    the_sender_is_informed_that_he_has_possiblity:"L’Expéditeur est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage téléphonique à l’adresse ",
    lanuages_of_the_general_conditions:'Langues des présentes conditions générales',
    these_general_contions_are_offered_in:'Les présentes conditions générales sont proposées en français.',
    the_stipulation_of_these_general_condition:"Les stipulations des présentes conditions générales s'appliquent sous réserve du respect des dispositions impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un professionnel et un consommateur.",
    order_cancellation_procedure:'MODALITE D’ANNULATION DE LA COMMANDE EN CAS DE VENTE A DISTANCE AUPRES D’UN EXPÉDITEUR',
    in_the_event_of_distance_selling:"En cas de vente à distance, telle que définie par l’article L. 221-1 du Code de la consommation, dans les quatorze jours, jours fériés compris, à compter de la commande ou de l'engagement d'achat, l’Expéditeur a la faculté d'y renoncer par lettre recommandée avec accusé de réception. Si ce délai expire normalement un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.",
    any_clauses_in_contract_by_which:"Toute clause du contrat par laquelle l’Expéditeur abandonne son droit de renoncer à sa commande ou à son engagement d'achat est nulle et non avenue. Le présent article ne s'applique pas aux contrats conclus dans les conditions prévues à l'article L. 221-2.",
    if_you_cancel_your_order:'Si vous annulez votre commande, vous pouvez utiliser le formulaire détachable ci-contre.',
    order_cancellation:'ANNULATION DE COMMANDE',
    consumer_court:'Code de la consommation art. L. 221-5',
    complete_and_sign_this_form:'compléter et signer ce formulaire',
    send_it_by_registered_letter:'l’envoyer par lettre recommandée avec accusé de réception',
    use_the_address_in_article_1:'utiliser l’adresse figurant à l’article 1',
    ship_it_no_later_than:'l’expédier au plus tard le quatorzième jour à partir du jour de la commande ou, si ce délai expire normalement un samedi, un dimanche ou un jour férié ou chômé, le premier jour ouvrable suivant.',
    i_the_under_signed_declare:'Je soussigné, déclare annuler la commande ci-après :',
    nature_of_goods:'Nature de la marchandise ou du service commandé :',
    date_of_order:'Date de la commande ',
    date_of_recipt_of_order:'Date de réception de la commande ',
    sender_name:'Nom de l’Expéditeur :',
    expeditor_address:"Adresse de l’Expéditeur : ",
    signature_of_sender:'Signature de l’Expéditeur :',
    // Travel Tab
    travel:'Voyageurs',
    bagage:'Expéditeurs',
    general_condition:'Conditions générales – Voyageur du site internet www.cobagage.com applicables à compter du 01 décembre 2023',
    part:'Parties',
    the_general_conditions_are_concluded:' Les présentes conditions générales sont conclues entre Cobagage Network, SASU, capital social : 1000 €, RCS de Paris : 848 506 861, siège social : 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, téléphone : +336 75 68 10 28,email : lyao@cobagage.com, n° de TVA intracommunautaire : FR44914381116, ci-après dénommé « Cobagage Network » et toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site pour expédier un Bien et/ou acheter un Produit, ci-après dénommée « le Voyageur ».',
    sender:'« Expéditeur » ',
    any_person_registered_on_site_ship:'toute personne, physique ou morale, de droit privé ou de droit public, inscrite sur le Site pour expédier un Bien et/ou acheter un Produit.',
    option:'« Option » ',
    paid_model_that_can_be_added_to_service:'module payant pouvant être ajouté à un Service par le Voyageur.',
    site_is_freely_accesible_to_all_traveller:"Le Site est d'accès libre et gratuit à tout Voyageur. La navigation sur le Site suppose l'acceptation par tout Voyageur des présentes conditions générales. La simple connexion au Site, par quelque moyen que ce soit, notamment par l'intermédiaire d'un robot ou d'un navigateur, emportera acceptation pleine et entière des présentes conditions générales.Lors de l’inscription sur le Site, cette acceptation sera confirmée par le fait de cocher la case correspondant à la phrase suivante : « Je reconnais avoir lu et accepté les conditions générales de vente et d'utilisation ». Le Voyageur reconnaît du même fait en avoir pris pleinement connaissance et les accepter sans restriction.Le fait de cocher la case susvisée sera réputé avoir la même valeur qu'une signature manuscrite de la part du Voyageur. Le Voyageur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de Cobagage et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.Les présentes conditions générales sont applicables aux relations entre les parties à l’exclusion de toutes autres conditions, et notamment celles du Voyageur.L'acceptation des présentes conditions générales suppose de la part des Voyageurs qu'ils jouissent de la capacité juridique nécessaire pour cela, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils sont mineurs, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.",
    traveler_obligation:'OBLIGATIONS DU VOYAGEUR',
    publication_of_advertizement_transfering:'Publication d’une annonce proposant le transport d’un Bien',
    publication_of_advertizement_is_opent_to:" La publication d’une annonce est ouverte à tout Voyageur. Les annonces devront être rédigées dans un français correct et compréhensible. Elles devront se conformer aux dispositions légales en vigueur et devront être dépourvues de tout critère discriminatoire quant à la qualité de l'éventuel cocontractant.",
    traveller_acknowledes_that:'Le Voyageur reconnaît que la publication de son annonce sur le présent Site emporte automatiquement et sans intervention de Cobagage publication de ladite annonce sur les sites partenaires et affiliés.',
    it_is_expressively_brought_to:"Il est expressément porté à la connaissance des Expéditeurs que Cobagage ne garantit en aucun cas l'exactitude des données contenues dans les annonces. Il appartient à tout Expéditeur intéressé par l’annonce de s'assurer de l'exactitude de toutes ces données préalablement à toute transaction effective.",
    the_traveller_must_specify:'Le Voyageur devra préciser les informations suivantes sur l’annonce :',
    the_place_date_time:'Le lieu, la date et l’heure du départ ;',
    the_mode_of_transport:'Le mode de transport ;',
    number_of_killogram_available_for_transporting:'Le nombre de kilogrammes disponibles pour le transport du Bien ;',
    the_asking_price:'Le prix demandé.',
    the_posiblity_of_subscring:'Le Voyageur a la possibilité de souscrire des Option lui permettant notamment la mise en avant de son annonce sur le Site. Le détail de chaque Option est précisé sur le Site.',
    delivery_of_goods:'Livraison du Bien ',
    upon_acceptance_of_an_announcement:'Dès acceptation d’une annonce, le Voyageur s’engage à apporter le Bien objet de l’annonce au Destinataire ainsi désigné, dans les conditions et délais précisés entre le Voyageur et l’Expéditeur.',
    sale_or_donation:'Vente ou don de Produit ',
    traveler_also_has_the_possiblity:' Le Voyageur a également la possibilité de vendre ou donner un Produit',
    publication_of_an_ad:' Le Voyageur a également la possibilité de vendre ou donner un Produit',
    traveller_will_have_possiblity_to_download:" Les Voyageur auront la possibilité de télécharger des photographies relatives au Produit objet de l'annonce. Le nombre de photographies n'est pas limité. Les photographies téléchargées devront être au format jpg, d'une taille maximale ne pouvant excéder 2 megaoctets. Au cours de la publication de l'annonce, l'annonceur disposera, via son espace personnel, de la faculté de modifier la fiche descriptive de l’annonce. Ces modifications seront possibles tout au long de la publication de l'annonce.",
    internet_user_expressly_informed:"Il est expressément porté à la connaissance des Internautes que Cobagage ne garantit en aucun cas l'exactitude des données contenues dans les annonces. Il appartient à tout Internaute intéressé par l’annonce de s'assurer de l'exactitude de toutes ces données préalablement à toute transaction effective. De même, Cobagage ne garantit en aucun cas aux annonceurs la vente ou la location effective des Produits visés par l'annonce.",
    sending_of_product_by:'Envoi du Produit par le Voyageur à l’Internaute',
    in_the_event_of_that:' Dans l’hypothèse où le Produit serait acheté par un Internaute, le Voyageur doit adresser directement le Produit à l’Internaute dans le délai précisé dans l’annonce et au plus tard dans les 7 jours ouvrés.',
    right_of_withdrawal_and_guarentee:'Droit de rétractation et garanties',
    traveler_remain_solely_responsible:'Le Voyageur demeure seul responsable de l’application d’un éventuel droit de rétractation et des garanties liées au Produit, vis-à-vis de l’Internaute.',
    traveler_payment_terms:'Modalités de paiement du Voyageur',
    payment_made_by_internet_user:'Le paiement effectué par l’Internaute est effectué directement entre les mains de Qonto ou Revolt, prestataires de paiement du Site. Une fois le Produit adressé à l’Internaute par le Voyageur, le prestataire de paiement concerné reverse au Voyageur sa rémunération, déduction faite des commissions de fonctionnement et/ou de transaction dues à Cobagage. Les montants de ces commissions sont indiqués sur le Site.',
    declaration_and_obligation_of_traveller:'DECLARATIONS ET OBLIGATIONS DU VOYAGEUR',
    traveller_information:'Information du Voyageur',
    traveller_declares_to_have:'Le Voyageur déclare avoir reçu de Cobagage toutes les explications et précisions utiles pouvant lui permettre d’utiliser les Services objet du présent contrat.',
    the_traveller_acknowledges_that_he_need:'Le Voyageur reconnaît que ses besoins et les Services proposés par Cobagage sont en adéquation et qu’il a souscrit au contrat en connaissance de cause et en disposant de toutes les informations nécessaires lui permettant de produire un consentement libre et éclairé. ',
    the_traveller_undertake_to_inform:' Le Voyageur s’engage à informer Cobagage de toute circonstance susceptible d’affecter l’exécution du présent contrat dès qu’il en a connaissance.',
    the_traveller_is_solely_responsible_for:'Le Voyageur est seul responsable des autorisations et déclarations relatives à l’utilisation des Services. Le Voyageur déclare qu’il dispose des droits et autorisations nécessaires à cette fin. Le cas échéant, le Voyageur déclare avoir effectué au préalable toute démarche nécessaire, telle que demandes d’autorisations et déclarations administratives.',
    lack_of_such_declarion_traveller:'Le défaut de telles déclarations et autorisations ne pourra en aucun cas remettre en cause la validité du présent contrat. Le Voyageur restera notamment tenu de régler à Cobagage les sommes dues. Le Voyageur garantit Cobagage contre tout recours qui serait entrepris à son encontre en cas de défaut de telles déclarations et autorisations.',
    the_traveller_undertakes_not_to_publish:'Le Voyageur s’engage à ne pas publier d’annonce sur le Site pouvant ',
    undermine_the_security:" porter atteinte à la sécurité ou à l'intégrité d'un Etat ou d'un territoire, quel qu'il soit ;",
    any_processing_transmission_traveller:"Tout traitement, transmission, publication, diffusion ou représentation des annonces par le Voyageur est effectué sous sa seule et entière responsabilité. Le Voyageur s'engage à ne pas entraver ou perturber le Site et les serveurs de Cobagage et à se conformer aux conditions requises, aux procédures, aux règles générales qui lui sont communiquées par Cobagage pour la publication des annonces.",
    any_illigal_or_general:" Tout usage illégal ou de manière générale non autorisé du Site entrainera la suppression immédiate du compte du Voyageur, sans préjudice des éventuels dommages-intérêts auxquels Cobagage pourrait prétendre.",
    the_traveller_therefore_guarantees:'Le Voyageur garantit en conséquence Cobagage contre tout dommage susceptible de lui être causé du fait de son utilisation du Site, en ce compris les éventuels frais d’avocat et de procédure, et s’engage à ce titre à intervenir à toute instance judiciaire engagée à son encontre du fait de son utilisation du Site.',
    step_in_ordering:' ETAPES DE LA COMMANDE D’OPTIONS',
    order:'Commande',
    in_order_to_place_an_order:'Afin de passer commande, les Voyageurs pourront sélectionner une ou plusieurs Options et les ajouter à leur panier. Lorsque leur commande sera complète, ils pourront accéder à leur panier en cliquant sur le bouton prévu à cet effet.',
    validation_of_order_by_traveller:' 8.2.	Validation de la commande par le Voyageur',
    by_consulting_their_basket:"En consultant leur panier, les Voyageurs auront la faculté de vérifier le nombre ainsi que la nature des Options qu'ils auront choisies et pourront vérifier leur prix unitaire, ainsi que leur prix global. Ils auront la possibilité de retirer une ou plusieurs Options de leur panier.",
    if_their_oder_suits_them:" Si leur commande leur convient, les Voyageurs pourront la valider. Ils accéderont alors à un formulaire sur lequel ils pourront soit saisir leurs identifiants de connexion s'ils en possèdent déjà, soit s'inscrire sur le Site en complétant le formulaire d’inscription au moyen des informations personnelles les concernant.",
    payment_by_traveller:' 8.3.	Paiement par le Voyageur',
    as_soon_as_they_are_connected:"Dès lors qu'ils seront connectés ou après qu'ils auront parfaitement complété le formulaire d’inscription, les Voyageurs seront invités à contrôler ou modifier leurs coordonnées de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet sur l'interface de paiement sécurisée comportant la mention « commande avec obligation de paiement » ou toute formule analogue.",
    confirmation_of_order:' 8.4.	Confirmation de la commande par Cobagage',
    once_paymet_actually_received_by_cobagage_traveller:"Une fois le paiement effectivement reçu par Cobagage, ce dernier s'engage à en accuser réception au Voyageur par voie électronique, dans un délai maximal de 24 heures. Dans le même délai, Cobagage s'engage à adresser au Voyageur un courrier électronique récapitulatif de la commande et lui en confirmant le traitement, reprenant toutes les informations y afférant.",
    price_payment:'ARTICLE 9.	PRIX - PAIEMENT',
    article_9:' 9.1.	Prix',
    applicable_price_are_those_displayed:" Les prix applicables sont ceux affichés sur le Site au jour de la commande. Ces prix peuvent être modifiés à tout moment par Cobagage. Les prix affichés ne sont valables qu'au jour de la commande et ne portent pas effet pour l'avenir.,",
    article9_payment_term:'9.2.	Modalité de paiement',
    traveller_can_make_payment_by_credit:' Le Voyageur peut effectuer son règlement par carte bancaire. Les paiements par carte bancaire se font au moyen de transactions sécurisées fournies par Qonto ou Revolut.,',
    in_this_context_of_bank_card:" Dans le cadre des paiements par carte bancaire, Cobagage n'a accès à aucune donnée relative aux moyens de paiement du Voyageur. Le paiement est effectué directement entre les mains de l'établissement bancaire.",
    cobagage_will_send_available:'Cobagage adressera ou mettra à disposition du Voyageur une facture par voie électronique après chaque paiement. Le Voyageur accepte expressément de recevoir les factures par voie électronique.',
    in_addition_to_late_paymet_traveler:" En outre, tout retard de paiement aura pour conséquence la facturation au Voyageur défaillant de frais de recouvrement d'un montant de 40 euros, l’exigibilité immédiate de toutes les sommes restant dues quels que soient les délais convenus, majorées d’une indemnité de 20 % du montant à titre de clause pénale, ainsi que la possibilité de résilier le contrat unilatéralement au tort du Voyageur. La présente clause s’inscrit dans le cadre des dispositions de l’article 1152 du code civil permettant au juge de réduire l’indemnité si le juge estime qu’elle est excessive.",
    compalint_withdrawal_guarantee:'RECLAMATION - RETRACTATION – GARANTIE',
    sites_customer_service_accessible:"Le service clientèle du Site est accessible du +336 75 68 10 28  de 10 heures du matin à 18 heures du soir ou par courrier électronique : contact@cobagage.com ou par courrier postal à l’adresse indiquée à l’article 1 des présentes conditions générales. Dans ces deux derniers cas, Cobagage s'engage à apporter une réponse sous 2 jours ouvrés.",
    conditions_for_exercising:'Conditions d’exercice du droit de rétractation',
    in_accordance_with_legistation:"Conformément à la législation en vigueur en matière de vente à distance, le Voyageur dispose d'un délai de quatorze jours francs pour exercer son droit de rétractation sans avoir à justifier de motifs ni à payer de pénalités, à l'exception, le cas échéant, des frais de retour.",
    periodmentioned_in_proceeding:" Le délai mentionné à l'alinéa précédent court à compter du jour où le contrat à distance est conclu pour les contrats relatifs à la fourniture d’une prestation de service et/ou à la fourniture d’un contenu numérique non fourni sur un support matériel.",
    when_the_fourteen_period:"Lorsque le délai de quatorze jours expire un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.",
    the_withdrawal_decision:'La décision de rétractation devra être notifiée à Cobagage aux coordonnées indiquées à l’article 1 des présentes conditions générales au moyen d’une déclaration dénuée d’ambigüité. Le Voyageur a, par exemple, la possibilité d’utiliser le formulaire-type fourni à la fin des présentes conditions générales. En tout état de cause, Cobagage adressera au Voyageur au plus tôt un accusé de réception de ladite rétractation par courrier électronique.',
    effect_of_the_right_of_the_withdrawal:'Effets du droit de rétractation',
    when_the_right_of_withdrawal:"Lorsque le droit de rétractation est exercé, le professionnel est tenu de rembourser le Voyageur de la totalité des sommes versées, dans les meilleurs délais et au plus tard dans les quatorze jours suivant la date à laquelle ce droit a été exercé. Au-delà, la somme due est, de plein droit, productive d'intérêts au taux légal en vigueur, tel que précisé à l’article L. 242-4 du Code de la consommation.",
    where_applicable_the_professional:" Le cas échéant, le professionnel effectue le remboursement en utilisant le même moyen de paiement que celui utilisé par le Voyageur pour la transaction initiale, sauf accord exprès du Voyageur pour l’utilisation d’un autre moyen de paiement et dans la mesure où le remboursement n'occasionne pas de frais pour le Voyageur.",
    condition_deadlines:" Les conditions, délais et modalités d’exercice du droit de rétractation sont exposés dans le formulaire-type fourni à la fin des présentes conditions générales.",
    exclusion_from_right_of_withdrawal:"Exclusions du droit de rétractation",
    right_of_withdrawal_does_not_apply:"Le droit de rétractation ne s’applique pas, notamment, aux contrats",
    for_the_provision_services:" de fourniture de services pleinement exécutés avant la fin du délai de rétractation et dont l'exécution a commencé après accord préalable exprès du Voyageur et renoncement exprès à son droit de rétractation ;",
    for_the_provision_of_digital_content:"de fourniture d'un contenu numérique non fourni sur un support matériel dont l'exécution a commencé après accord préalable exprès du Voyageur et renoncement exprès à son droit de rétractation.",
    likewise_right_of_withdrawal:"De même, le droit de rétractation n’est pas applicable aux contrats exécutés intégralement par les deux parties à la demande expresse du Voyageur avant que ce dernier n'exerce son droit de rétractation.",
    when_validating_the_for_digital:"Lors de la validation de la commande d’un  contenu numérique indépendant de tout support matériel avant l'expiration du délai de rétractation, le renoncement du Voyageur au droit de rétractation sera manifesté par le fait de cocher la case correspondant à la phrase suivante :",
    i_expressively_waive:"« Je renonce expressément à mon droit de rétractation de 14 jours pour les Produits livrés ». Le Voyageur recevra alors une confirmation par email de son renoncement au droit de rétractation",
    the_traveller_wh_has_exercised_his_right:"Le Voyageur qui a exercé son droit de rétractation d'un contrat de prestation de services dont l'exécution a commencé, à sa demande expresse, avant la fin du délai de rétractation verse au professionnel un montant correspondant au service fourni jusqu'à la communication de sa décision de se rétracter, ce montant étant proportionnel au prix total de la prestation convenu dans le contrat.",
    the_personal_space_allows_the_traveller:"L’espace personnel permet au Voyageur de consulter et de suivre toutes ses annonces publiées sur le Site.",
    pages_related_to_personal_space:" Les pages relatives aux espaces personnels sont librement imprimables par le titulaire du compte en question, mais ne constituent nullement une preuve admissible par un tribunal. Elles n'ont qu'un caractère informatif destiné à assurer une gestion efficace de ses commandes par le Voyageur.",
    publishing_profile:"Publication d'un profil",
    traveller_offered_the_option:" Les Voyageurs se voient offrir la faculté de se constituer un profil public sur le Site. Dans ce cadre, les Voyageurs pourront renseigner un certain nombre d'informations personnelles les concernant. Tous les profils publics seront soumis à validation préalable de Cobagage ou de son équipe de modérateurs avant leur première mise en ligne.",
    it_is_expressively_understood_that:"Il est expressément entendu que le Voyageur, en décidant de souscrire aux services offerts par Cobagage et en complétant la fiche relative à son profil public, reconnaît que ces données, et notamment sa photographie, sont susceptibles d'être publiées sur le Site et diffusées à ce titre auprès des autres Voyageurs et Internautes. Le simple fait de fournir ces informations et de compléter les champs de la fiche de création du profil public emportera manifestation expresse de la volonté du Voyageur de publier lesdites informations sur le Site.",
    traveler_will_have_option_of:" Les Voyageurs disposeront de la faculté de faire cesser la diffusion de leur profil public. Ils devront, pour cela, demander la suppression de leur compte Voyageur et la résiliation des services, en suivant la procédure prévue à cet effet. La suspension de la diffusion du profil public sera effective dans un délai maximal de 3 jours ouvrés à compter de la réception de la demande par Cobagage.",
    an_internal_private_messeging_travel:"Il est mis à disposition des Voyageurs un service de messagerie privée interne. Ce système est réservé aux Voyageurs et le secret des correspondances lui est applicable.",
    the_content_of_inboxxing_and_outgoing_traveller:"Le contenu des boîtes de réception et d'envoi ne fait l'objet d'aucune garantie de conservation de la part de Cobagage et il appartient aux Voyageurs de sauvegarder lesdits contenus. La perte de ces contenus, quelle qu'en soit la cause, ne pourra constituer un dommage pour le Voyageur qui ne pourra prétendre à aucune indemnité de ce fait.",
    any_traveller_who_is_victim:"Tout Voyageur qui sera victime d'abus (spams, publicités non souhaitées ou autre) pourra en informer Cobagage qui prendra toutes mesures utiles.",
    contribution_from_traveller:"Contributions des Voyageurs",
    traveller_are_offered_the_option_of:" Les Voyageurs se voient offrir la faculté de contribuer au contenu du Site par la publication de commentaires sur leur utilisation des Produits et leur relation avec Cobagage. Les commentaires devront être apportés en français. Ils seront soumis à validation de Cobagage ou de son équipe de modérateurs.",
    by_clicking_on_the_validate_tab_traveller:"En cliquant sur l'onglet « Valider », afin de publier son commentaire, le Voyageur accorde une licence de droit d'auteur non exclusive à titre gratuit à Cobagage portant sur ledit commentaire. A ce titre, le Voyageur autorise Cobagage à communiquer au public en ligne, en tout ou partie, son commentaire sur le Site, les newsletters de Cobagage et les sites des partenaires de Cobagage. Le Voyageur autorise Cobagage à reproduire son commentaire aux fins de le communiquer au public en ligne et de réaliser des supports de communication et de promotion du Site. Le Voyageur autorise Cobagage à traduire dans toute langue son commentaire aux fins de le communiquer au public en ligne et de le reproduire sur ses supports commerciaux et promotionnels. Le droit d'adaptation comprend également le droit d'effectuer les modifications techniquement nécessaires à l'exploitation du commentaire dans d'autres formats. La présente licence est accordée pour une utilisation commerciale et publicitaire. Elle est accordée pour toute la durée des droits, pour une exploitation en France et à l'étranger",
    cobagage_reserves_the_right:" Cobagage se réserve le droit de supprimer le compte de tout Voyageur qui contrevient aux présentes conditions générales, notamment lorsque le Voyageur fournit des informations inexactes, incomplètes, mensongères ou frauduleuses, ainsi que lorsque l’espace personnel d’un Voyageur sera resté inactif depuis au moins une année. Ladite suppression ne sera pas susceptible de constituer une faute de Cobagage ou un dommage pour le Voyageur exclu, qui ne pourra prétendre à aucune indemnité de ce fait.",
    these_exclusion_without:"Cette exclusion est sans préjudice de la possibilité, pour Cobagage, d'entreprendre des poursuites d'ordre judiciaire à l'encontre du Voyageur, lorsque les faits l'auront justifié.",
    as_part_of_this_servece_cobagage_will_be_requred:"Dans le cadre de sa prestation, Cobagage va être amené à traiter des données à caractère personnel de ses Voyageurs.",
    data_collected_from_traveller:"Données collectées auprès des Voyageurs",
    as_part_of_contractual_relationships_traveller:"Dans le cadre de ses relations contractuelles, Cobagage peut être amené à collecter et traiter des informations de ses Voyageurs, à savoir : les noms, prénoms, fonction, nom de la société, numéro de téléphone, adresses postales, adresses mails, coordonnées bancaires, historique des contrats.",
    contact_traveler:"	contacter les Voyageurs ; ",
    verify_identity_of_traveller:"vérifier l'identité des Voyageurs ;",
    cobagage_may_also_collect_traveler:"Cobagage peut également collecter et traiter toute donnée transmise volontairement par ses Voyageurs.",
    cobagage_direct_it_to_traveller:"Cobagage oriente ses Voyageurs afin qu’il fournisse des données à caractère personnel strictement nécessaires à l’exécution des engagements contractuels. ,",
    cobagage_traveller_have_the_following:"Les Voyageurs de Cobagage disposent des droits suivants concernant leurs données personnelles, qu’ils peuvent exercer en écrivant à l’adresse postale de Cobagage  ou en remplissant le formulaire contact en ligne.",
    cobagage_traveller_possiblity:"Les Voyageurs de Cobagage ont la possibilité d’accéder aux données personnelles qui les concernent.,",
    personal_nature:"caractère personnel qui incombe à Cobagage, les demandes seront uniquement traitées si les Voyageurs rapportent la preuve de leur identité, notamment par la production d’un scan de leur titre d’identité valide (en cas de demande par le formulaire électronique dédié) ou d’une photocopie signée de leur titre d’identité valide (en cas de demande adressée par écrit), tous deux accompagnés de la mention « j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait à … le … », suivie de leur signature.Pour les aider dans leur démarche, les Voyageurs trouveront ici un modèle de courrier élaboré par la Cnil.",
    cobaggage_traveler_have_possiblity_of:" Les Voyageurs de Cobagage ont la possibilité de demander la rectification, la mise à jour, le verrouillage ou encore l’effacement de leurs données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.",
    cobagage_traveller_can_also:" Les Voyageurs de Cobagage peuvent également définir des directives générales et particulières relatives au sort des données à caractère personnel après leur décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.",
    to_help_them_in_their_process_traveller:"Pour les aider dans leur démarche, les Voyageurs trouveront ici un modèle de courrier élaboré par la Cnil.",
    cobagage_traveller_have_possiblity_to_object:" Les Voyageurs de Cobagage ont la possibilité de s’opposer à un traitement de leurs données",
    cobagage_traveller_have_right_to_receive:"   Les Voyageurs de Cobagage ont le droit de recevoir les données personnelles qu’ils ont fournies à Cobagage dans un format transférable, ouvert et lisible.",
    cobagage_traveller_right_to_receive_data:"Les Voyageurs de Cobagage ont le droit de demander que le traitement de leurs données personnelles par Cobagage soit limité. Ainsi, leurs données ne pourront qu’être conservées et non plus utilisées par Cobagage.",
    if_cobagage_traveller_consider_that_cobagage:"Si les Voyageurs de Cobagage considèrent que Cobagage ne respecte pas ses obligations au regard de leurs données à caractère personnel, ils peuvent adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la Cnil à laquelle ils peuvent adresser une demande ici.",
    cobagage_uses_authorized_traveller:"Cobagage a recours à des prestataires habilités pour faciliter la collecte et le traitement des données de ses Voyageurs. Ces prestataires peuvent être situés en dehors de l’Union Européenne.",
    cobagage_has_previously_ensured_traveller:" Cobagage s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données, par exemple via le Privacy Shield états-unien. Cobagage fait appel aux sous-traitants suivants :",
    traveller_also_consent:"Les Voyageurs consentent également à ce que Cobagage communique les données collectées à toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire.",
    if_cobagage_is_involved_in:"Si Cobagage est impliqué dans une fusion, une vente d'actifs, une opération de financement,",
    force_majour_fault_of_traveller:"Force majeure - Faute du Voyageur",
    cobagage_will_not_held_liable_traveller:"Cobagage n'engagera pas sa responsabilité en cas de force majeure ou de faute du Voyageur, telles que définies au présent article :",
    force_maheur_traveler:"Force majeure",
    for_the_purpose_of_these_general_condition_traveller:"  Au sens des présentes conditions générales, sera considéré comme un cas de force majeure opposable au Voyageur tout empêchement, limitation ou dérangement du Service du fait d'incendie, d'épidémie, d'explosion, de tremblement de terre, de fluctuations de la bande passante, de manquement imputable au fournisseur d'accès, de défaillance des réseaux de transmission, d'effondrement des installations, d'utilisation illicite ou frauduleuse des mots de passe, codes ou références fournis au Voyageur, de piratage informatique, d'une faille de sécurité imputable à l'hébergeur du Site ou aux développeurs, d'inondation, de panne d'électricité, de guerre, d'embargo, de loi, d'injonction, de demande ou d'exigence de tout gouvernement, de réquisition, de grève, de boycott, ou autres circonstances hors du contrôle raisonnable de Cobagage. Dans de telles circonstances, Cobagage sera dispensé de l'exécution de ses obligations dans la limite de cet empêchement, de cette limitation ou de ce dérangement.",
    fault_of_the_traveller:"Faute du Voyageur",
    as_the_purpose_of_these_general_conditions_traveller:" Au sens des présentes Conditions générales, sera considéré comme une faute du Voyageur opposable à ce dernier toute mauvaise utilisation du Service, faute, négligence, omission ou défaillance de sa part ou de celle de ses préposés, non-respect des conseils donnés par Cobagage sur son Site, toute divulgation ou utilisation illicite du mot de passe, des codes et références du Voyageur, ainsi que le renseignement d’informations erronées ou l’absence de mise à jour de telles informations dans son espace personnel. Sera également considérée comme une faute du Voyageur la mise en œuvre de tout procédé technique, tels que des robots, ou des requêtes automatiques, dont la mise en œuvre contreviendrait à la lettre ou à l’esprit des présentes conditions générales de vente.",
    if_it_is_impossible_traveller:"En cas d'impossibilité d'accès au Site, en raison de problèmes techniques de toutes natures, le Voyageur ne pourra se prévaloir d'un dommage et ne pourra prétendre à aucune indemnité. L'indisponibilité, même prolongée et sans aucune durée limitative, d'un ou plusieurs services en ligne, ne peut être constitutive d'un préjudice pour les Voyageurs et ne peut aucunement donner lieu à l'octroi de dommages et intérêts de la part de Cobagage.",
    hypertext_link_present_on_site:" Les liens hypertextes présents sur le Site peuvent renvoyer sur d'autres sites internet. La responsabilité de Cobagage ne saurait être engagée si le contenu de ces sites contrevient aux législations en vigueur. De même la responsabilité de Cobagage ne saurait être engagée si la visite, par le Voyageur, de l'un de ces sites, lui causait un préjudice.",
    damage_payable_by_cobagage_traveller:"A défaut de dispositions légales ou réglementaires contraires, la responsabilité de Cobagage est limitée au préjudice direct, personnel et certain subi par le Voyageur et lié à la défaillance en cause. Cobagage ne pourra en aucun cas être tenu responsable des dommages indirects tels que, notamment les pertes de données, les préjudices commerciaux, les pertes de commandes, les atteintes à l'image de marque, les troubles commerciaux et les pertes de bénéfices ou de clients. De même et dans les mêmes limites, le montant des dommages et intérêts mis à la charge de Cobagage ne pourra en tout état de cause excéder le prix de la commission perçue.",
    content_of_site_published_on_site_traveller:" Les Contenus du Site sont publiés à titre indicatif, sans garantie d'exactitude. Cobagage ne peut en aucun cas être tenu responsable du fait d'une omission, d'une inexactitude ou de toute erreur contenue dans ces informations et qui serait à l'origine d'un dommage direct ou indirect causé au Voyageur.",
    data_published_by_traveller:" Les données, notamment commentaires, publiées sur le Site par le Voyageur le sont sous sa propre responsabilité.",
    in_this_context_cobagage_will_benit_traveler:" Dans ce cadre, Cobagage bénéficiera du statut d'hébergeur des données au sens de l'article 6-I-2 de la Loi pour la confiance dans l'économie numérique du 21 juin 2004. Conformément à l'alinéa 3 du même article, Cobagage ne pourra voir sa responsabilité civile ou pénale engagée à raison de ces commentaires, à moins que, dès le moment où il a eu connaissance de l'activité ou de l'information illicite, il n'ait agi promptement pour retirer ces informations ou en rendre l'accès impossible.",
    these_general_condition_may_be_modified_traveller:"Les présentes conditions générales peuvent être modifiées à tout moment par Cobagage. Les conditions générales applicables au Voyageur sont celles en vigueur au jour de sa commande ou de sa connexion sur le présent Site, toute nouvelle connexion à l'espace personnel emportant acceptation le cas échéant des nouvelles conditions générales.",
    in_addition_traveller_is_informed:"En outre, le Voyageur est informé de l’existence de la plateforme de règlement en ligne des litiges, accessibles à l’adresse URL suivante :",
    traveler_is_informed_that:" Le Voyageur est informé qu’il a la possibilité de s’inscrire sur la liste d'opposition au démarchage téléphonique à l’adresse http://www.bloctel.gouv.fr/.",
    unfair_clauses:"Clauses abusives",
    order_cancellation_procedure_traveler:"MODALITE D’ANNULATION DE LA COMMANDEEN CAS DE VENTE A DISTANCE AUPRES D’UN VOYAGEUR",
    in_the_event_of_distance_selling_traveller:"En cas de vente à distance, telle que définie par l’article L. 221-1 du Code de la consommation, dans les quatorze jours, jours fériés compris, à compter de la commande ou de l'engagement d'achat, le Voyageur a la faculté d'y renoncer par lettre recommandée avec accusé de réception. Si ce délai expire normalement un samedi, un dimanche ou un jour férié ou chômé, il est prorogé jusqu'au premier jour ouvrable suivant.",
    any_clauses_of_contract_traveller:"Toute clause du contrat par laquelle le Voyageur abandonne son droit de renoncer à sa commande ou à son engagement d'achat est nulle et non avenue. Le présent article ne s'applique pas aux contrats conclus dans les conditions prévues à l'article L. 221-2."



    


}
export default cgvu;