import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import travel from "../../../../../models/travel";
import luggage from "../../../../../models/luggage";
import articles from "../../../../../models/articles";
import articlesDonation from "../../../../../models/articlesDonation";
import { Swiper, SwiperSlide } from "swiper/react";
import RecentLuggageCard from "../../swipersData/RecentLuggageCard";
import RecentTravelCard from "../../swipersData/RecentTravelCard";
import AppContext from "../../../../../store/context/app-context";

function Submit() {
  const { t } = useTranslation();
  const { id, type } = useParams();
  console.log("type", type);
  const [postAdvertData, setPostAdvertData] = useState();
  const [allData, setAllData] = useState();
  const contextObj = useContext(AppContext);
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );

  useEffect(() => {
    PostAdertData();
    AllPostAdertData();
  }, []);

  const testimonial3slide = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 5,
    loop: true,
    roundLengths: true,
    autoplay: {
      delay: 15000,
    },
    navigation: {
      nextEl: ".testi-next4",
      prevEl: ".testi-prev4",
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };
  const testimonial4slide = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 5,
    // loop: true,
    roundLengths: true,
    autoplay: {
      delay: 15000,
    },
    navigation: {
      nextEl: ".testi-next4",
      prevEl: ".testi-prev4",
    },
    breakpoints: {
      480: {
        slidesPerView: 1,
      },
      768: {
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 2,
      },
      1200: {
        slidesPerView: 4,
      },
    },
  };

  const PostAdertData = () => {
    if (type === "0") {
      travel
        .get(id)
        .then((data) => {
          setPostAdvertData(data);
        })
        .catch((error) => {
          console.log("submit page travel", error);
        });
    } else if (type === "1") {
      luggage
        .get(id)
        .then((data) => {
          setPostAdvertData(data);
        })
        .catch((error) => {
          console.log("submit page luggage", error);
        });
    }
  };

  const AllPostAdertData = () => {
    if (type === "1") {
      travel
        .list(userId)
        .then((data) => {
          setAllData(data);
        })
        .catch((error) => {
          console.log("submit page luggage", error);
        });
    } else if (type === "0") {
      luggage
        .list(userId)
        .then((data) => {
          setAllData(data);
        })
        .catch((error) => {
          console.log("submit page luggage", error);
        });
    }
  };

  const AllDataFilter = () => {
    console.log("filter vdata is here: ", allData?.filter(
      (item) =>
        item?.city_arrival_name === postAdvertData?.city_arrival_name &&
        item?.city_departure_name === postAdvertData?.city_departure_name
    ));
    return allData?.filter(
      (item) =>
        item?.city_arrival_name === postAdvertData?.city_arrival_name &&
        item?.city_departure_name === postAdvertData?.city_departure_name
    );
  };

  return (
    <div className="container">
      <div className="package-details-wrapper text-center p-5">
        <div className="">
          <h3>{t("form.Congratulations")}!</h3>
        </div>
        <div className="pt-3 pb-3">
          <h6> {t("form.Your_advertisement_successfully")}</h6>
        </div>
        <div>
          <h6>
            {type === "1"
              ? t("form.Your_may_Luggage_advertisement")
              : t("form.Your_may_view_advertisement")}
          </h6>
        </div>
        {/* <div>
                    <h6>{t("form.Travel_Announcement")}</h6>
                </div> */}
        <div>
          <h6>{type === "1" ?  '' : t("form.section")}</h6>
          <br />
        </div>
        {AllDataFilter()?.length > 0 && (
          <>
            <div className="pt-3">
              <h5>
                {type === "0"
                  ? t("form.sender_directly")
                  : t("form.sending_requests_Travel")}
              </h5>
            </div>
          </>
        )}
      </div>
      <div className="row align-items-center justify-content-center">
        <div className="col-lg-12 p-0">
          <Swiper
            className="swiper testimonial-slider-three"
            {...(AllDataFilter()?.length >= 4
              ? testimonial3slide
              : testimonial4slide)}
          >
            <div className="swiper-wrapper">
              {AllDataFilter()?.map((item) => (
                <SwiperSlide
                  className="swiper-slide"
                  style={{ padding: "10px" }}
                >
                  {type === "0" ? (
                    <RecentLuggageCard
                      item={item}
                      data={"viewAllLuggageRequest"}
                    />
                  ) : type === "1" && !item.isComplete ? (
                    <RecentTravelCard item={item} />
                  ) : (
                    ""
                  )}
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
        </div>
      </div>
    </div>
  );
}
export default Submit;
