const works={
title:'How It Works',
have_you_arrived:'Have you arrived on the Cobagage platform and would like to know how it works?',
whether_are_you_professional:'Whether you are a professional or an individual, the Cobagage site aims to connect co-baggers with each other in a simple, quick and secure way.',
becoming_a_cobaggage:'Becoming a co-baggage: What is it?',
saving_money_by_sharing:'Saving Money By Sharing',
sharing_a_car_journey:'Sharing a car journey or renting a room to save money - a new way of thinking about everyday life that makes life easier for millions of people.',
sharing_between_individual:'Today, sharing between individuals and/or professionals turns out to be an excellent way to earn money on one side and save money on the other.',
travellers_rent_free:'Travellers - Rent Free Luggage Space',
this_principle_also_applies_to:'This principle also applies to renting free luggage space during a trip, to transport one or more items to its recipient.',
the_traveller_can_take_advantage_of:'The traveler can then take advantage of the remaining space in their luggage by monetizing it, which will allow him to make part of his stay profitable.',
luggage_sender:'Luggage Sender/Recipient',
for_this_part_recipient_can_have:'For his part, the recipient can have one or more packages delivered quickly at rates much more affordable than if he had had to use a freight company.',
being_a_cobaggage_allows_you_to:'Being a co-baggage allows you to',
becoming_a_traveller:'Become A Traveller',
by_monitizing_your_kilos:'by monetizing your kilos of empty luggage empty',
post_a_travel_plan:'Post a travel plan',
become_a_luggage_sender:'Become A Luggage Sender',
by_creating_a_transport_request:'by creating a transport request.',
post_a_luggage_sending_request:'Post a luggage sending request',
step_by_step_operation:'Step by step operation',
the_cobaggage_platform_intended:'The Cobagage platform is intended to connect co-baggers between them. It is necessary to follow several simple and quick steps to find a co-baggage and secure your transaction here is how to proceed step by step',
step:'Step ',
do_you_have_free_space_in_your_luggage:'Do you have free space in your luggage and want to monetize it? Simply go to the area dedicated to announcements to complete the corresponding form.',
you_will_need_to_provide_information_about_your_trip:'You will need to provide information about your trip, the number of kilos available in your luggage as well as the price per kilo. Once your ad has been written, it will appear on the site in the “Announce a trip” section.',
the_sender_fill_out_a_form:'The sender fills out a form to put their ad online on the site, specifying information relating to your baggage in the “Send baggage” section.',
he_makes_payment_the_payment_directly_on_platform:'He makes the payment directly on the platform, which is then put on hold for the time of the finalization of the transaction on Cobagage.',
the_traveller_and_sender_are_put_in_contact:'The traveler and the sender are put in contact via the Cobagage site and sign an order order. The latter must be signed by both parties and will serve as a guarantee concerning responsibility for the baggage.',
once_the_connection_has_been_made:'Once the connection has been made, the traveler will transport the item(s) in their luggage and will show up at the meeting place to deliver the package to the recipient.',
the_recipient_comes_to_the_meeting_place:'The recipient comes to the meeting place to collect their package. Ratings, reviews and comments can then be left on the platform to evaluate the sender and the traveler.',
a_complete_secure_transport_solution:'A completely secure transport solution',
one_of_the_legitimate_question:'One of the legitimate questions that we can ask ourselves before taking the step of cobagaging is to know if this transaction is secure.',
parcel_transport_through_cobaggage:'Parcel transport through Cobagage  is one of the most secure ways to send and receive your items.',
as_a_platform_for_connecting_co_baggagers:'As a platform for connecting co-baggers, we stay in permanent touch with the traveler holding your package.',
serveral_safety_rules:'Several safety rules nevertheless remain to be observed in order to guarantee perfect progress of transport.',
the_traveller_and_sender_have_obligation:'The traveler and the sender have the obligation to jointly check the contents of the package reception.',
the_sender_must_complete_the_registration:'The sender must also complete the registration forms with the documents requested on the platform, and take a photo of the contents of the package.',
for_their_part_the_traveller_must_follow:'For their part, the traveler must follow the same procedure by completing the form registration.',
he_must_also_provide_a_photocopy:'He must also provide a photocopy of his identity card, residence permit and passport by through the site.',
becoming_a_cobaggager_is_assuarance_of:'Becoming a Co-baggage is the assurance of being able to quickly obtain a package, or to make a profit part of his trip by monetizing the empty kilos of his luggage.',
this_service_applies_to_all_kind_of_travel:'This service applies to all kinds of travel, whether by plane, train, car or metro or any other means of transport allowing you to go from one place to another.'
}
export default works;