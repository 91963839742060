import { Box, Tab, Tabs, Typography, useMediaQuery } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";
import ChangeCircleIcon from "@mui/icons-material/ChangeCircle";
import PaymentsOutlinedIcon from "@mui/icons-material/PaymentsOutlined";
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart";
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined";
import FavoriteOutlinedIcon from "@mui/icons-material/FavoriteOutlined";
import StarRoundedIcon from "@mui/icons-material/StarRounded";
import WalletTwoToneIcon from "@mui/icons-material/WalletTwoTone";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PropTypes from "prop-types";
import MyProfile from "../myProfile/MyProfile";
import PostAnAdvert from "../postanadvert/PostAnAdvert";
import ChangePassword from "../changePassword/ChangePassword";
import SubscribeAds from "../subscribeAds/SubscribeAds";
import { makeStyles } from "@mui/styles";
import MyTravels from "../myTravels/MyTravels";
import MyLuggages from "../myLuggages/MyLuggages";
import MyArticles from "../myArticles/MyArticles";
import EarnReports from "../myTrackingTravels/EarnReports";
import CobagageWallet from "../cobagageWallet/CobagageWallet";
import MyArticleDonations from "../myArticleDonations/MyArticleDonations";
import SubscriptionBalance from "../subscriptionBalance/SubscriptionBalance";
import PaymentsReceived from "../paymentsReceived/PaymentsReceived";
import SubscribeHistory from "../subscribeHistory/SubscribeHistory";
import EuroIcon from "@mui/icons-material/Euro";
import MyOrders from "../myOrders/MyOrders";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../store/context/app-context";
import { useContext } from "react";
import DashBoard from "../dashBoard/DashBoard";
import MyPendingPayments from "../myPendingPayments/MyPendingPayments";
import MyRequests from "../myRequests/MyRequests";
import ShoppingCard from "../shoppingCart/ShoppingCard";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Cookies from "js-cookie";
import CookiesPage from "../../cookie/CookiesPage";
import i18n from "../../../../plugin/i18n";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const useStyles = makeStyles((theme) => ({
  customTabRoot: {
    color: "",
  },
  customTabIndicator: {
    backgroundColor: "#ff4838",
  },
}));

function ProfileWrapperArea() {
  const contextObj = useContext(AppContext);
  const classes = useStyles();
  const { t } = useTranslation();
  const history = useHistory();
  const [value, setValue] = React.useState(
    contextObj?.tabValue ? contextObj?.tabValue : 0
  );

  useEffect(() => {
    setValue(contextObj?.tabValue);
  }, [contextObj?.tabValue]);

  // const [showCookie, setShowCookie] = useState(!!Cookies.get('cookiesAccepted') ? Cookies.get('cookiesAccepted') : !!Cookies.get('cookiesAccepted'));

  // useEffect(() => {
  //   if (history.location?.state?.tab === 0) {
  //     setValue(history.location?.state?.tab)
  //   }
  //   else {
  //     setValue(contextObj?.tabValue ? contextObj?.tabValue : 0)
  //   }
  // }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue);
    contextObj.setRadioValue();
    contextObj.setTabValue(newValue);
  };
  const mediumViewport = useMediaQuery("(min-width:900px)");

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // const handleCookiesAcceptance = (accept) => {
  //   Cookies.set('cookiesAccepted', accept);
  //   setShowCookie(accept == 'Refuse' ? true : accept);
  // }

  return (
    <div className="container">
      {/* {contextObj.login ? <div style={{ position: 'fixed', bottom: 0, display: showCookie ? showCookie !== 'Refuse' ? 'none' : 'block' : 'block', zIndex: 9999 }}>
        <CookiesPage
          setShowCookie={handleCookiesAcceptance}
        />
      </div> : ""} */}
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          display: mediumViewport ? "flex" : "",
        }}
      >
        <Tabs
          className={classes.customTabs}
          value={value}
          onChange={handleChange}
          variant="scrollable"
          orientation={mediumViewport ? "vertical" : "horizontal"}
          scrollButtons="auto"
          textColor="primary"
          alignItems="start"
          align="start"
          // style={{border:'1px solid'}}
          // indicatorColor='secondary'
          sx={{ minWidth: i18n.language === "frFR" ? 320 : 250 }}
          classes={{
            root: classes.customTabRoot,
            indicator: classes.customTabIndicator,
          }}
          aria-label="scrollable auto tabs example"
        >
          <Tab
            label={t("profile.post_an_advert")}
            onClick={scrollTop}
            style={{
              color: value === 0 ? "#ff4838" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PersonIcon />}
            iconPosition="start"
            {...a11yProps(0)}
          />
          <Tab
            label={t("profile.dashboard")}
            onClick={scrollTop}
            style={{
              color: value === 1 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PersonIcon />}
            iconPosition="start"
            {...a11yProps(1)}
          />
          <Tab
            label={t("profile.my_profile")}
            onClick={scrollTop}
            style={{
              color: value === 2 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PersonIcon />}
            iconPosition="start"
            {...a11yProps(2)}
          />
          <Tab
            label={t("profile.change_password")}
            onClick={scrollTop}
            style={{
              color: value === 3 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<ChangeCircleIcon />}
            iconPosition="start"
            {...a11yProps(3)}
          />
          <Tab
            label={t("profile.my_subscription_plan")}
            onClick={scrollTop}
            style={{
              color: value === 4 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PaymentsOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(4)}
          />
          <Tab
            label={t("profile.my_requests")}
            onClick={scrollTop}
            style={{
              color: value === 5 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PersonIcon />}
            iconPosition="start"
            {...a11yProps(5)}
          />
          <Tab
            label={t("profile.my_pending_payments")}
            onClick={scrollTop}
            style={{
              color: value === 6 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PaymentsOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(6)}
          />
          <Tab
            label={t("profile.my_orders")}
            onClick={scrollTop}
            style={{
              color: value === 7 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<StarRoundedIcon />}
            iconPosition="start"
            {...a11yProps(7)}
          />
          <Tab
            label={t("profile.my_travels")}
            onClick={scrollTop}
            style={{
              color: value === 8 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<LocalShippingOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(8)}
          />
          <Tab
            label={t("profile.my_luggages")}
            onClick={scrollTop}
            style={{
              color: value === 9 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<LocalShippingOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(9)}
          />
          <Tab
            label={t("profile.my_articles")}
            onClick={scrollTop}
            style={{
              color: value === 10 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<FavoriteOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(10)}
          />
          <Tab
            label={t("profile.my_article_donations")}
            onClick={scrollTop}
            style={{
              color: value === 11 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PaymentsOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(11)}
          />
          <Tab
            label={t("profile.cobagage_wallet")}
            onClick={scrollTop}
            style={{
              color: value === 12 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<PaymentsOutlinedIcon />}
            iconPosition="start"
            {...a11yProps(12)}
          />
          <Tab
            label={t("profile.earn_spends_report")}
            onClick={scrollTop}
            style={{
              color: value === 13 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<WalletTwoToneIcon />}
            iconPosition="start"
            {...a11yProps(13)}
          />
          <Tab
            label={t("profile.subscription_balance")}
            onClick={scrollTop}
            style={{
              color: value === 14 ? "red" : " ",
              justifyContent: "start",
              paddingLeft: "0px",
            }}
            icon={<EuroIcon />}
            iconPosition="start"
            {...a11yProps(14)}
          />
          {/* <Tab label={t("profile.ShoppingCard")} style={{ color: value === 15 ? "red" : " " }} icon={<AddShoppingCartIcon />} iconPosition="start" {...a11yProps(15)} /> */}
        </Tabs>

        <TabPanel value={value} index={0} style={{ width: "100%" }}>
          <PostAnAdvert />
        </TabPanel>
        <TabPanel
          value={value}
          index={1}
          style={{ width: "100%", overflow: "hidden" }}
        >
          <DashBoard />
        </TabPanel>
        <TabPanel value={value} index={2} style={{ width: "100%" }}>
          <MyProfile User_id={history.location?.state?.User_id} />
        </TabPanel>
        <TabPanel value={value} index={3} style={{ width: "100%" }}>
          <ChangePassword />
        </TabPanel>
        <TabPanel value={value} index={4} style={{ width: "100%" }}>
          <SubscribeAds />
        </TabPanel>
        <TabPanel value={value} index={5} style={{ width: "100%" }}>
          <MyRequests />
        </TabPanel>
        <TabPanel value={value} index={6} style={{ width: "100%" }}>
          <MyPendingPayments />
        </TabPanel>
        <TabPanel value={value} index={7} style={{ width: "100%" }}>
          <MyOrders />
        </TabPanel>
        <TabPanel value={value} index={8} style={{ width: "100%" }}>
          <MyTravels
            setValue={setValue}
            // setRadioValue={setRadioValue}
          />
        </TabPanel>
        <TabPanel value={value} index={9} style={{ width: "100%" }}>
          <MyLuggages
            setValue={setValue}
            // setRadioValue={setRadioValue}
          />
        </TabPanel>
        <TabPanel value={value} index={10} style={{ width: "100%" }}>
          <MyArticles setValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={11} style={{ width: "100%" }}>
          <MyArticleDonations setValue={setValue} />
        </TabPanel>
        <TabPanel value={value} index={12} style={{ width: "100%" }}>
          <CobagageWallet />
        </TabPanel>
        <TabPanel value={value} index={13}>
          <EarnReports />
        </TabPanel>
        <TabPanel value={value} index={14}>
          <SubscriptionBalance />
        </TabPanel>
        {/* <TabPanel value={value} index={15} style={{ width: '100%' }}>
          <ShoppingCard />
        </TabPanel> */}
      </Box>
    </div>
  );
}

export default ProfileWrapperArea;
