import React, { useState } from 'react'
import Article_Donation from "./Article_Donation";
import Article from './Article';
import { useTranslation } from 'react-i18next';


function SellArticleWrapperArea() {
  const { t } = useTranslation();
  const [travels, settravels] = useState(true);
  const [luggage, setluggage] = useState(false);

  function ShowLuggage() {
    var articlesbtncolor = document.getElementById("articlesbtncolor");
    var articleDonationbtncolor = document.getElementById("articleDonationbtncolor");
    settravels(false);
    setluggage(true);
    articleDonationbtncolor.style.backgroundColor = "white"
    articleDonationbtncolor.style.color = "#ff4838"
    articlesbtncolor.style.backgroundColor = "#ff4838"
    articlesbtncolor.style.color = "white"
  }
  function ShowTravel() {
    var articlesbtncolor = document.getElementById("articlesbtncolor");
    var articleDonationbtncolor = document.getElementById("articleDonationbtncolor");
    settravels(true);
    setluggage(false);
    articlesbtncolor.style.backgroundColor = "white"
    articlesbtncolor.style.color = "#ff4838"
    articleDonationbtncolor.style.backgroundColor = "#ff4838"
    articleDonationbtncolor.style.color = "white"
  }

  return (
    <>
      <div className="custom-input-group row">
        <div className="submite-btn" style={{ position: 'absolute', textAlign: 'center', bottom: '30px', zIndex: "1" }}>
          <button type="submit" id="articlesbtncolor" onClick={ShowTravel} style={{ backgroundColor: 'white', color: '#ff4838', borderRadius: '5px' }}>{t("menu.articles")}</button>
          <button type="submit" id="articleDonationbtncolor" onClick={ShowLuggage} style={{ margin: '10px' }}>{t("menu.article_donation")}</button>
        </div>
      </div>
      {travels && <Article />}
      {luggage && <Article_Donation />}
    </>
  );
}

export default SellArticleWrapperArea;
