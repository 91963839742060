import React from 'react'
import OtppageWrapperArea from './OtppageWrapperArea'
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

export default function OtpaPage() {
  const { t } = useTranslation();

    return (
        <div>
            <Breadcrumb name ={t('profile.Otp_Verification')}/>
            <OtppageWrapperArea />
        </div>
    )
}
