import React from "react";
import RegistrationWrapperArea from "./RegistrationWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function Registration() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("auth.register")}/>
      <RegistrationWrapperArea />
     
    </>
  );
}

export default Registration;
