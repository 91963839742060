import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";

function TermsConditionsWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="">
        <div className="contact-form-wrap " >
          <div className="row">
            <div className="col-lg-1">
            </div>
            <div className="col-lg-10">
              <div className="custom-input-group">
                <h2 > <span style={{ color: '#f0592b', textDecoration: 'underline' }} >General terms and conditions - Shippers</span></h2><br /><br />
                <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 1. PARTIES</h5>
                <p style={{ opacity: .7 }}>
                  These general conditions are concluded between Cobagage, SAS, share capital: $1000, RCS de Paris: 879 955 045, head office: 7 rue Castellane, 75008 Paris, France, telephone: +33675681028, email: contact@cobagage.fr, intra-community VAT number: FR59879955045, hereinafter referred to as 'Cobagage' and any person, individual or legal entity, under private or public law, registered on the Site to dispatch a Good and/or buy, sell or give away a Product, hereinafter referred to as 'the Shipper'.
                </p><br />
                <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 2. DEFINITIONS</h5>
                <p style={{ opacity: .7 }}>
                  <b>'Good' :</b>  property of any kind held by the Shipper and sent to a Recipient through a Traveller.<br /><br />
                  <b>'Site Content' :</b>  elements of any kind published on the Site, whether or not protected by an intellectual property right, such as texts, images, designs, presentations, videos, diagrams, structures, databases or software.<br /><br />
                  <b>'Recipient' :</b> any person, natural person or legal entity, under private or public law, the final recipient of the Good sent by the Shipper and transported by the Traveller.<br /><br />
                  <b>'Traveller' :</b> any person or legal entity, under private or public law, registered on the Site to transport Goods and/or sell Products.<br /><br />
                  <b>'Product' :</b> goods of any kind sold on the Site by a Traveller to a Shipper.<br /><br />
                  <b>'Service' :</b> service offered by Cobagage on the Site and consisting of the provision of an online platform enabling the shipment of Goods and/or the sale of Products between Passengers and Shippers.<br /><br />
                  <b>'Site' :</b> website accessible at the URL www.Cobagage.com , as well as sub-sites, mirror sites, portals and variations of URLs related thereto.<br /><br />
                  <b>'User' : </b>  Traveller or Shipper.<br /><br />
                </p>
                <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 3. SCOPE OF APPLICATION</h5>
                <p style={{ opacity: .7 }}>
                  The Site is freely accessible to any Shipper. Browsing on the Site implies acceptance by any Shipper of these general terms and conditions. Simply connecting to the Site, by any means whatsoever, in particular by means of a robot or browser, shall imply full and complete acceptance of these general terms and conditions.
                  When registering on the Site, this acceptance will be confirmed by ticking the box corresponding to the following sentence: 'I acknowledge having read and accepted the general terms and conditions of sale and use'. By the same token, the Shipper acknowledges having read them in full and accepting them without restriction.
                  The fact of ticking the aforementioned box shall be deemed to have the same value as a handwritten signature on the part of the Shipper. The Shipper acknowledges the evidential value of Cobagage's automatic recording systems and, unless it can prove otherwise, it waives the right to contest them in the event of a dispute.
                  These general terms and conditions are applicable to the relations between the parties to the exclusion of all other conditions, and in particular those of the Shipper.
                  Acceptance of these general terms and conditions presupposes on the part of the Shippers that they have the necessary legal capacity to do so, or failing this, that they have the authorisation of a tutor or curator if they are incapable, of their legal representative if they are minors, or that they hold a power of attorney if they are acting on behalf of a legal entity.
                </p><br />
                <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 4. PURPOSE OF THE WEBSITE</h5>
                <p style={{ opacity: .7 }}>
                  The purpose of the Site is to provide an online electronic platform for the shipment of Goods and/or the sale or donation of Products between Shippers and Travellers.
                </p><br />
                <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 5. COBAGAGE’S OBLIGATIONS</h5>
                <p style={{ opacity: .7 }}>
                  <br />
                  <b>5.1. Provision of the platform</b>
                  <br />
                  <br />
                  Cobagage undertakes to provide the Traveller with an online platform enabling him to offer the transport of Goods and/or to sell his Products.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsConditionsWrapperArea;
