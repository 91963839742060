import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import {
  Link,
  useHistory
} from "react-router-dom";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../plugin/validator";
import AppContext from "../../../../store/context/app-context";
import notistack from "../../../../plugin/notistack";
import Loading from "../../../common/Loading";
import { Button, Modal } from 'react-bootstrap';
import basket from "../../../../models/basket";
import paymentSent from "../../../../models/paymentSent";
import payPal from "../../../../models/payPal";


const reset = {
  email: '',
  password: '',
  role: "User"
}

export default function ShoppingCardWrapperArea() {
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState(reset);
  const [showPassword, setShowPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const [show, setShow] = useState(false);
  const [basketData, setBasketArticle] = useState([]);
  const [travelBasket, setTavelBasket] = useState([]);
  const style = `.custom-input-group i {
    top:16%
  }`
  const [PaymentMode, setPaymentMode] = useState('')
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
    getbasketData();
  }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const getbasketData = () => {
    basket.list().then((resp) => {
      setTavelBasket(resp?.filter((item) => item.product_type < 3));
      setBasketArticle(resp?.filter((item) => item.product_type == 3))
    }).catch((error) => {
      console.log('Here is error shoppingCard page');
    })
  }

  //   if (validator.allValid()) {
  //     setLoad(true);
  //     auth.login(data).then((response) => {
  //       console.log(response);
  //       history.push(response.user.login_type ? "/" : `/ProfileInfo/${response.user.country_id}`);
  //       contextObj.functionsetloginUser();
  //       contextObj.functionLogin();
  //       setLoad(false);
  //     })
  //       .catch((error) => {
  //         setLoad(false);
  //         if (error?.data.message) {
  //           notistack.error(error?.data.message);
  //         }
  //       })
  //   } else {
  //     showMessage(true);
  //   }
  // };
  const handleClose = () => {
    setShow(false);
    setPaymentMode('');
  };
  const handleShow = (id) => {
    return (
      setShow(id)
    )
  }

  const handleDeleteBasket = (id) => {
    basket.delete(id).then((resp) => {
      notistack.success(t('form.basket_delete'))
      getbasketData();
    }).catch((error) => {
      console.log('Here is error shoppingCard page');
    })
  }

  const PaymentProsees = () => {
    if (PaymentMode == 1) {
      stripData(show);
    }
    else if (PaymentMode == 2) {
      PayPalData(show);
    }
  }

  const stripData = (show) => {
    setLoad(true)
    paymentSent.create(show.id).then((resp) => {
      window.location = resp.url;
      window.localStorage.setItem("sessionId", resp.id)
      setLoad(false)
    }).catch((error) => {
      setLoad(false)
      console.log('Here is error shoppingCard page', error);
    })
  }

  const PayPalData = (show) => {
    setLoad(true)
    payPal.basketMobileMoney(show.id).then((item) => {
      window.localStorage.setItem('paymentId', item.transaction_id)
      window.localStorage.setItem('subscription_id', show.id)
      window.location = item.data.payment_url;
      setLoad(false)
    }).catch((error) => {
      setLoad(false)
      console.log('Here is error shoppingCardPaypal page', error);
    })
  }

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <div class="container mt-5 mb-5">
            <style>{style}</style>
            <div class="d-flex justify-content-center  row">

              {travelBasket?.map((item, index) =>
                <div class="pb-2">
                  {index == 0 && <div className="mt-2 mb-3"><h2>{t('profile.Luggages_I_want_to_sent')}</h2></div>}
                  <div class="row container mx-0 pt-2 pb-2 bg-white border rounded">
                    <div class="col-md-3 mt-1">
                      <img
                        src={item.image[0]}
                        alt="PackageIMG"
                        style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", }}
                      />
                    </div>

                    <div class="col-md-6 mt-1">
                      <h5>{item.title}</h5>
                      <div class="d-flex flex-row">
                        <div class="ratings m-2"></div>
                      </div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.Quatity')}: {item?.quantity}</span></div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.Unit_Price')}: {item?.unit_price}{item?.currency}</span></div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.carry_luggage')}: <span style={{ fontWeight: 'bold' }}>{item?.total_price}{item?.currency}</span></span></div>
                    </div>
                    <div class="align-items-center align-content-center col-md-3 border-left mt-1">
                      <div class="d-flex flex-row align-items-center">
                        <p>{t('profile.traveller')}:</p>
                      </div>
                      <p>{item.travel_username} {t('profile.froms')} {item.travel_name}</p>
                      <div class="d-flex flex-column mt-4">
                        <button class="btn btn-outline-primary btn-sm" type="button" onClick={() => handleShow(item)}>{t('form.Proceed_payments')}</button>
                        <div onClick={() => handleDeleteBasket(item?.id)} class="mt-2" style={{ cursor: 'pointer', color: '#d9001b', display: 'flex', alignItems: "center", justifyContent: "center", gap: '3px' }}><div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#d9001b', color: 'white', borderRadius: '50%', height: '1px !important', width: "6px !important" }}><i class="bx bx-x"></i></div><div>{t('form.Remove_cart')}</div></div>

                      </div>
                    </div>
                  </div>
                </div>)}

              {basketData?.map((item, index) =>
                <div class="pb-2">
                  {index == 0 && <div className="mt-2 mb-3"><h2>{t('profile.Articles_I_want_to_buy')}</h2></div>}
                  <div class="row container mx-0 pt-2 pb-2 bg-white border rounded">
                    <div class="col-md-3 mt-1">
                      <img
                        src={item.image[0]}
                        alt="PackageIMG"
                        style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", }}
                      />
                    </div>

                    <div class="col-md-6 mt-1">
                      <h5>{item.title}</h5>
                      <div class="d-flex flex-row">
                        <div class="ratings m-2"></div>
                      </div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.Quatity')}: {item?.quantity}</span></div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.Unit_Price')}: {item?.unit_price}{item?.currency}</span></div>
                      <div class="mt-1 mb-1 spec-1"><span>{t('form.carry_luggage')}: <span style={{ fontWeight: 'bold' }}>{item?.total_price}{item?.currency}</span></span></div>
                    </div>
                    <div class="align-items-center align-content-center col-md-3 border-left mt-1">
                      <div class="d-flex flex-row align-items-center">
                        <p>{t("profile.Seller")}:</p>
                      </div>
                      <p>{item.seller_name}</p>
                      <div class="d-flex flex-column mt-4">
                        <button class="btn btn-outline-primary btn-sm" type="button" onClick={() => handleShow(item)}>{t('form.Proceed_payments')}</button>
                        <div onClick={() => handleDeleteBasket(item?.id)} class="mt-2" style={{ cursor: 'pointer', color: '#d9001b', display: 'flex', alignItems: "center", justifyContent: "center", gap: '3px' }}><div className="d-flex justify-content-center align-items-center" style={{ backgroundColor: '#d9001b', color: 'white', borderRadius: '50%', height: '1px !important', width: "6px !important" }}><i class="bx bx-x"></i></div><div>{t('form.Remove_cart')}</div></div>

                      </div>
                    </div>
                  </div>
                </div>)}
              <Modal show={show} onHide={handleClose}
                // size="lg"
                centered
              >
                <Modal.Header closeButton>
                  <Modal.Title> {t("form.order_summary")}</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                  <table className='col-lg-12 col-12 col-md-12 col-sm-12' style={{ fontSize: '16px', fontWeight: '600' }}>
                    <tr>
                      <td style={{ color: 'black', fontWeight: 'bold', fontSize: '18px' }}>{show?.title}</td>
                      {/* <td class='text-end fw-normal' >136 €</td> */}
                    </tr>
                    <tr className="mt-4">
                      <td style={{ color: '#ff4838', fontWeight: '500' }}>{t('form.carry_luggage')}:</td>
                      <td class='text-end fw-normal' >{show?.total_price}{show?.currency}</td>
                    </tr>
                    <tr>
                      <td style={{ color: '#ff4838', fontWeight: '500' }}>{t('form.site_service_fees')}{' '}{show?.product_type == 1 ? t('form.Luggage') : show?.product_type == 3 ? t('form.Article') : show?.product_type == 4 ? t('form.Article_Donation') : t('form.Luggage')}</td>
                      <td class='text-end fw-normal' >{show?.transaction_charges} {show?.currency}</td>
                    </tr>
                    <tr>
                      <td style={{ color: '#ff4838' }}>{t('form.total_payable_amount')}:</td>
                      <td class='text-end' >{show?.final_price}{show?.currency}</td>
                    </tr>
                    <tr style={{ marginTop: '10px' }}>
                      <td colSpan={2}>
                        <div className="col-lg-12">
                          <div className="custom-input-group">
                            <select
                              id="traveller_type"
                              onChange={(e) => { setPaymentMode(e.target.value) }}
                              name='traveller_type'
                            // value={data.traveller_type}
                            >
                              <option value="" selected disabled>{t("profile.Select_payment_method")}</option>
                              <option value={1}>{t("form.Stripe")}</option>
                              <option value={2}>{t("form.Mobile_Moeny")}</option>
                            </select>
                            <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>

                          </div>
                        </div>
                      </td>
                    </tr>
                    {/* <tr>
                      <td className="">
                        <img
                          src="/images/vehicles/stripe.png"
                          width="57%"
                        // style={{ cursor: 'pointer' }}
                        // onClick={() => stripData(show)}
                        // className="paymentPop"
                        ></img>
                      </td>
                      <td style={{ marginTop: "10px", display: "flex", justifyContent: "center" }}>
                        <img
                          src="/images/vehicles/paypal.png"
                          width="59%"
                        // style={{ cursor: 'pointer' }}
                        // onClick={() => PayPalData(show)}
                        // className="paymentPop"
                        // style={{ border: "1px solid black"}}
                        ></img>
                      </td>
                    </tr> */}
                  </table>
                  <div className="custom-input-group col-lg-12" style={{ marginTop: "15px" }}>
                    <div className="col-12">
                      <div className="submite-btn d-flex justify-content-center">
                        <button
                          type="submit"
                          disabled={!PaymentMode == 1 || !PaymentMode == 2}
                          onClick={() => {
                            // setFiled(1);
                            // showError()
                            PaymentProsees()
                          }}
                          className="btn btn-secondary"
                          style={{
                            fontSize: '14px'
                          }}>
                          {t("form.Procesed_payment")}
                        </button>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className=''>

                </Modal.Footer>
              </Modal>
            </div>
          </div>
        </>
      )}
    </>
  );
}


