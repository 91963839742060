import axios from '../plugin/axios';

const basket = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Basket/GetAll')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Basket/Delete', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default basket;