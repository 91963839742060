import React, { useContext, useEffect, useState } from "react";
import subscribeAds from "../../../../models/subscribeAds";
import stripeSubscribe from "../../../../models/stripeSubscribe";
import { useHistory } from "react-router-dom";
import Loading from "../../../common/Loading";
import { useTranslation } from "react-i18next";
import payPal from "../../../../models/payPal";
import { Modal, Button } from "react-bootstrap";
import AppContext from "../../../../store/context/app-context";

export default function UpgradeMemberShip() {
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [load, setLoad] = useState(false);
  const [details, setDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentMode, setPaymentMode] = useState();
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );
  const [buyId, setBuyId] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    subscribeAds
      .list(userId)
      .then((item) => {
        setDetails(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const PaymentProsees = () => {
    if (paymentMode == 1) {
      stripData();
    }
    else if (paymentMode == 2) {
      PayPalData();
    }
  }

  const stripData = (id) => {
    setLoad(true);
    if (buyId) {
      stripeSubscribe
        .create(buyId)
        .then((item) => {
          // contextObj.setSessionId(item.id)
          window.localStorage.setItem("sessionId", item.id);
          window.location = item.url;
          // setLoad(false);
          setBuyId(null);
          handleClose();
        })
        .catch((error) => {
          setLoad(false);
          console.log("error", error);
        });
    }
  };

  const PayPalData = () => {
    if (contextObj.login == true) {
      setLoad(true);
      stripeSubscribe.mobileMoney(buyId).then((item) => {
        window.localStorage.setItem('paymentId', item.transaction_id)
        window.localStorage.setItem('subscription_id', buyId)
        window.location = item.data.payment_url;
      }).catch((error) => {
        setLoad(false);
        console.log('Here is error shoppingCardPaypal page', error);
      })
    } else {
      history.push("/login");
    }
  };

  const handleShow = (id) => {
    setBuyId(id);
    setShow(true);
  };
  const handleClose = () => setShow(false);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div class="">
          <div class="">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h5 class="text-start pb-2 fw-bold">
                  {t("homePage.Become_service_fees")}
                </h5>
              </div>
              <div className="package-details-wrapper p-0">
                <div className="tour-package-details">
                  <div className="package-details-tabs">
                    <ul
                      className="nav nav-pills tab-switchers gap-xxl-4 gap-3 ms-3 pb-4"
                      id="pills-tab"
                      role="tablist"
                      style={{ marginBottom: "0px" }}
                    >
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link active"
                          id="pills-package1"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body1"
                          type="button"
                          role="tab"
                          aria-controls="pill-body1"
                          aria-selected="true"
                          style={{ fontSize: "15px", borderRadius: "5px" }}
                        >
                          {/* {t("profile.profile_information")} */}
                          {t("homePage.SILVER")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-package2"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body2"
                          type="button"
                          role="tab"
                          aria-controls="pill-body2"
                          aria-selected="false"
                          style={{ fontSize: "15px", borderRadius: "5px" }}
                        >
                          {/* {t("profile.id_verification_documents")} */}
                          {t("homePage.GOLD")}
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link"
                          id="pills-package3"
                          data-bs-toggle="pill"
                          data-bs-target="#pill-body3"
                          type="button"
                          role="tab"
                          aria-controls="pill-body3"
                          aria-selected="false"
                          style={{ fontSize: "15px", borderRadius: "5px" }}
                        >
                          <i class="bi bi-gem" /> {t("homePage.DIAMOND")}
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="pills-tabContent">
                      <div
                        className="tab-pane fade show active package-info-tab "
                        id="pill-body1"
                        role="tabpanel"
                        aria-labelledby="pills-package1"
                      >
                        <div class="justify-content-center pe-0 pt-3 row">
                          <div class="silverCard col-lg-6 pb-3 col-12  col-xl-4">
                            <div
                              class="card  shadow rounded-xs"
                              style={{
                                borderColor: "#0095b6",
                                background: "#0095b6",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.BASIC")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#f39c12",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[0]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#f39c12" }}>
                                        {details[0]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#0095b6'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold" >{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#0095b6' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  >{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#0095b6",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[0]?.kg_limit}{" "}
                                          {t(
                                            "homePage.articles_included_applied"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  //   onClick={() =>
                                  //     handleShow(details[0]?.subscribe_id)
                                  //   }
                                  onClick={() =>
                                    handleShow(details[0]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#4997d0",
                                background: "#4997d0",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.INTERMEDIATE")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#f39c12",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[1]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#f39c12" }}>
                                        {details[1]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#4997d0'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold" >{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#4997d0'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold" >{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#4997d0",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[1]?.kg_limit}{" "}
                                          {t(
                                            "homePage.articles_included_applied"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[1]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card  shadow rounded-xs"
                              style={{
                                borderColor: "#2a52be",
                                background: "#2a52be",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.EXPERT")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#f39c12",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[2]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#f39c12" }}>
                                        {details[2]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#2a52be'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold" >{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#2a52be' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold" >{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#2a52be",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[2]?.kg_limit}{" "}
                                          {t(
                                            "homePage.articles_included_applied"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[2]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade package-info-tab"
                        id="pill-body2"
                        role="tabpanel"
                        aria-labelledby="pills-package2"
                      >
                        <div class="justify-content-center pe-0 pt-3 row ">
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#ffbf00",
                                background: "#ffbf00",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "black", fontSize: "32px" }}
                                  >
                                    {t("homePage.BASIC")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[3]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "black" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[3]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#ffbf00'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#ffbf00' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#ffbf00",
                                      }}
                                    >
                                      <div class="">
                                        <p
                                          className="pt-0 fw-bold"
                                          style={{ color: "black" }}
                                        >
                                          {details[3]?.kg_limit}{" "}
                                          {t("homePage.articles_included_applied")}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[3]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card  shadow rounded-xs"
                              style={{
                                borderColor: "#ff4838",
                                backgroundColor: "#EDDD59",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "black", fontSize: "32px" }}
                                  >
                                    {t("homePage.INTERMEDIATE")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[4]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "black" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[4]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#EDDD59'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#EDDD59' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#EDDD59",
                                      }}
                                    >
                                      <div class="">
                                        <p
                                          className="pt-0 fw-bold"
                                          style={{ color: "black" }}
                                        >
                                          {details[4]?.kg_limit}{" "}
                                          {t(
                                            "homePage.articles_included_applied"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary "
                                  onClick={() =>
                                    handleShow(details[4]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#ff4838",
                                backgroundColor: "#ffff91",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "black", fontSize: "32px" }}
                                  >
                                    {t("homePage.EXPERT")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[5]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "black" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[5]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#ffff91'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#ffff91' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold"  style={{color:'black'}}>{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#ffff91",
                                      }}
                                    >
                                      <div class="">
                                        <p
                                          className="pt-0 fw-bold"
                                          style={{ color: "black" }}
                                        >
                                          {details[5]?.kg_limit}{" "}
                                          {t(
                                            "homePage.articles_included_applied"
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[5]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade package-info-tab"
                        id="pill-body3"
                        role="tabpanel"
                        aria-labelledby="pills-package3"
                      >
                        <div class="justify-content-center pe-0 pt-3 row ">
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#4b0082",
                                backgroundColor: "#4b0082",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.BASIC")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[6]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[6]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#4b0082'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#4b0082' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#4b0082",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[6]?.kg_limit}{" "}
                                          {t("homePage.articles_included_applied")}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[6]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#c8a2c8",
                                backgroundColor: "#c8a2c8",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.INTERMEDIATE")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[7]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[7]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#c8a2c8'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#c8a2c8' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#c8a2c8",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[7]?.kg_limit}{" "}
                                          {t("homePage.articles_included_applied")}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[7]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="silverCard col-lg-6 pb-3 col-12 col-xl-4">
                            <div
                              class="card shadow rounded-xs"
                              style={{
                                borderColor: "#da70d6",
                                backgroundColor: "#da70d6",
                              }}
                            >
                              <div class="card-body">
                                <div class="d-flex flex-column align-items-center justify-content-center">
                                  <p
                                    class="t-white"
                                    style={{ color: "#fff", fontSize: "32px" }}
                                  >
                                    {t("homePage.EXPERT")}
                                  </p>
                                  <p
                                    class="t-white"
                                    style={{
                                      color: "#ff4838",
                                      fontSize: "22px",
                                    }}
                                  >
                                    {details[8]?.price}{" "}
                                    {contextObj?.loginUser?.symbol}
                                    <span
                                      class="ps-2"
                                      style={{ color: "#fff" }}
                                    >
                                      {" "}
                                      {t("homePage.for")}{" "}
                                      <span style={{ color: "#ff4838" }}>
                                        {details[8]?.kg_limit} kg
                                      </span>
                                    </span>
                                  </p>
                                </div>
                                {/* <div class="mb-3 pt-2"> <a href="#">
                                                                    <div class="btn btn-primary" style={{backgroundColor:'#da70d6'}}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_fees_luggages')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div>
                                                                <div class="mb-3"> <a href="#">
                                                                    <div class="btn btn-primary" style={{ height: '110px',backgroundColor:'#da70d6' }}>
                                                                        <div class="">
                                                                            <p className="pt-0 fw-bold">{t('homePage.service_earnings_customer')}</p>
                                                                        </div>
                                                                    </div>
                                                                </a> </div> */}
                                <div class="mb-3">
                                  <a href="#">
                                    <div
                                      class="btn btn-primary"
                                      style={{
                                        height: "98px",
                                        backgroundColor: "#da70d6",
                                      }}
                                    >
                                      <div class="">
                                        <p className="pt-0 fw-bold">
                                          {details[8]?.kg_limit}{" "}
                                          {t("homePage.articles_included_applied")}
                                        </p>
                                      </div>
                                    </div>
                                  </a>
                                </div>
                                <div
                                  class="order btn btn-primary"
                                  onClick={() =>
                                    handleShow(details[8]?.subscribe_id)
                                  }
                                >
                                  {t("homePage.Proceed_Buy")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {show && (
            <Modal show={show} onHide={handleClose} size="lg" centered>
              <Modal.Header closeButton>
                <Modal.Title>{t("profile.payment_method")}</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-flex flex-column justify-content-center align-items-center">
                  <div className="col-6 d-flex justify-content-center align-items-center">
                    {/* <img
                        src="/images/vehicles/stripe.png"
                        height="76%"
                        width="35%"
                        onClick={() => stripData(show)}
                        className="paymentPop"
                        style={{ cursor: "pointer" }}
                      // style={{ border: "1px solid black", borderRadius: "10px", padding: "7px" }}
                      ></img> */}
                    <div className="row" style={{ justifyContent: "center" }}>
                      <div className="col-2">
                        <img
                          src={"/images/vehicles/visa.png"}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="col-2">
                        <img
                          src={"/images/vehicles/mscard.jpg"}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="col-2">
                        <img
                          src={"/images/vehicles/maestro.png"}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="col-2">
                        <img
                          src={"/images/vehicles/cb.jpg"}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                      <div className="col-2">
                        <img
                          src={"/images/vehicles/card.png"}
                          alt=""
                          width="100%"
                          height="100%"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <select
                        id="traveller_type"
                        onChange={(e) => {
                          setPaymentMode(e.target.value);
                        }}
                        name="traveller_type"
                      >
                        <option value="" selected disabled>
                          {t("profile.Select_payment_method")}
                        </option>
                        <option value={1}>{t("form.Stripe")}</option>
                        <option value={2}>{t("form.Mobile_Moeny")}</option>
                      </select>
                      <i class="bi bi-caret-down-fill"></i>
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-center">
                <Button onClick={() => PaymentProsees()} disabled={!paymentMode}>
                  {t("profile.proceed")}
                </Button>
              </Modal.Footer>
            </Modal>
          )}
        </div>
      )}
    </>
  );
}
