import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Modal, Form } from "react-bootstrap";
import { Rating } from "react-simple-star-rating";
import CompletedTravelLuggage from "./common/CompletedTravelLuggage";
import InprogressTravelLugggage from "./common/InprogressTravelLugggage";
import CancelledLuggage from "./common/CancelledLuggage";
import { useEffect } from "react";
import request from "../../../../models/request";
import Loading from "../../../common/Loading";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import DatePicker from "react-datepicker";
import i18n from "../../../../plugin/i18n";
import moment from "moment";
import Chat from "../../../common/Chat";
import AppContext from "../../../../store/context/app-context";
import ratingSent from "../../../../models/ratingSent";
const reset = {
    received_by: "",
    received_date: "",
    request_id: "",
    request_type: "",
};
const Raise = {
    request_id: "",
    request_type: "",
    raise_dispute: "",
};
export default function MyOrders() {
    const { t } = useTranslation();
    const [data, setData] = useState(reset);
    const [received, setReceived] = useState(1);
    const [articleISold, setArticleISold] = useState(1);
    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [show1, setShow1] = useState(false);
    const [iReceived, setIReceived] = useState([]);
    const [iSent, setISent] = useState([]);
    const [validator, showMessage] = useValidator();
    const [rating, setRating] = useState(0);
    const [load, setLoad] = useState(false);
    const [modalData, setModalData] = useState();
    const [raisedispute, setRaisedispute] = useState(Raise);
    const [tab, setTab] = useState(1);
    const contextObj = useContext(AppContext);
    const style = `    .react-datepicker__input-container input{
        border: 1px solid #ff4838;
    }
    .react-datepicker__input-container:after{
        top: 11px;
        right: 11px;
    }`
    const error = {
        received_by: validator.message(
            "received_by",
            data.received_by,
            "required|string|max:200"
        ),
        received_date: validator.message(
            "received_date",
            data.received_date,
            "required|string|max:200"
        ),
    };
    useEffect(() => {
        getDetails();
        requestSendDetails();
    }, []);

    const getDetails = () => {
        setLoad(true);
        let arr = [];
        request
            .allData(1)
            .then((item) => {
                arr.push(item);
                request.allData(2).then((item) => {
                    arr.push(item);
                    request.allData(3).then((item) => {
                        arr.push(item);
                        request.allData(4).then((item) => {
                            arr.push(item);
                        });
                    });
                });
            })
            .catch((error) => {
                console.log("error", error);
            });
        setIReceived(arr);
    };
    const requestSendDetails = () => {
        let arr = [];
        request
            .allRequestSend(2)
            .then((item) => {
                arr.push(item);
                request.allRequestSend(1).then((item) => {
                    arr.push(item);
                    request.allRequestSend(3).then((item) => {
                        arr.push(item);
                        request.allRequestSend(4).then((item) => {
                            arr.push(item);
                            setLoad(false);
                        });
                    });
                });
            })
            .catch((error) => {
                console.log("error", error);
            });
        setISent(arr);
    };
    const handleClose = () => {
        if (articleISold == 6 && received == 3) {
            setArticleISold(1);
            setShow(false);
        } else if (articleISold == 7 && received == 4) {
            setArticleISold(3);
            setShow(false);
        } else {
            setShow(false);
        }
        showMessage(false);
        setShow1(false)
        setShow2(false)
        setData(reset);
        setRaisedispute(Raise)
    };
    const handleShow = () => setShow(true);

    const handleRating = (rate) => {
        setRating(rate);
    };

    const handleSubmitRating = (value) => {
        const formateData = {
            rating: rating,
            product_id: show2.product_id,
            product_type: show2.product_type,
            user_id: show2.requester_user_id || show2.requestee_id,
        }
        ratingSent.create(formateData).then((resp) => {
            handleClose()
            notistack.success(t("profile.Rating_sent_sucessfully"))
        }).catch((error) => {
            notistack.error(t("profile.You_have_already_rated_this_product"));

        })
    }

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetData(name, value);
    };

    const handleRaisChange = (e) => {
        const { name, value } = e.target;
        handleRaisSetData(name, value);
    }

    const handleRaisSetData = (name, value) => {
        setRaisedispute((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    useEffect(() => {
        handleRaisSetData("request_id", modalData?.id)
        handleRaisSetData("request_type", modalData?.product_type)
    }, [modalData])

    const handleSetData = (name, value) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };
    const handleSubmit = () => {
        if (validator.allValid()) {
            request
                .soldItem(data)
                .then((item) => {
                    getDetails();
                    requestSendDetails();
                    if (articleISold == 1) {
                        setArticleISold(6);
                    }
                    if (articleISold == 3) {
                        setArticleISold(7);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    notistack.error(error?.response?.data?.message);
                });
        } else {
            showMessage(true);
        }
    };

    const confirmDeliveryOrder = () => {
        if (validator.allValid()) {
            request
                .confirmDelivery(data)
                .then((response) => {
                    handleClose();
                    getDetails();
                    requestSendDetails();
                })
                .catch((error) => {
                    notistack.error(error.response.data.message);
                })
        } else {
            showMessage(true);
        }
    };
    const filterInprogressOrder = () => {
        if (received == 3) {
            return (
                received == 3
                    ? articleISold == 1 || articleISold == 6
                        ? iReceived[2]
                        : iSent[2]
                    : articleISold == 3
                        ? iReceived[3]
                        : iSent[3]
            )?.filter(
                (item) =>
                    (received == 3 ? item?.is_completed : item?.isComplete) == false &&
                    item?.status == 1 &&
                    item?.payment_status == true
            );
        } else {
            return (
                received == 3
                    ? articleISold == 1 || articleISold == 6
                        ? iReceived[2]
                        : iSent[2]
                    : articleISold == 3
                        ? iReceived[3]
                        : iSent[3]
            )?.filter((item) => item?.isComplete == false && item?.status == 1);
        }
    };

    const filterCompletedOrder = () => {
        return (
            received == 3
                ? articleISold == 1 || articleISold == 6
                    ? iReceived[2]
                    : iSent[2]
                : articleISold == 3
                    ? iReceived[3]
                    : iSent[3]
        )?.filter(
            (item) => (received == 3 ? item?.is_completed : item?.isComplete) == true
        );
    };

    const filterCancelledOrder = () => {
        return (
            received == 3
                ? articleISold == 1 || articleISold == 6
                    ? iReceived[2]
                    : iSent[2]
                : articleISold == 3
                    ? iReceived[3]
                    : iSent[3]
        )?.filter((item) => item?.status == 2);
    };

    const handleRaisSubmit = () => {
        request.raisedisputeData(raisedispute).then((respons) => {
            notistack.success(t("profile.Your_ticket_has_business_days"))
            handleClose()
            setRaisedispute(Raise)
        }).catch((error) => {
            console.log("error", error);
        })
    }

    const handlesShowChat = (articleValue) => {
        setShow1(articleValue)
    }

    const handleShowRatting = (ratingValue) => {
        setShow2(ratingValue)
    }

    return (
        <>
            {load ? (
                <Loading />
            ) : (
                <div>
                    <style>{style}</style>
                    <div className="package-details-wrapper p-0 ">
                        <div className="tour-package-details">
                            <div className="package-details-tabs row">
                                <ul
                                    className="nav nav-pills  gap-xxl-4 gap-3 d-lg-flex justify-content-lg-evenly"
                                    id="myTab"
                                    role="tablist"
                                    style={{ marginBottom: "0px" }}
                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link travel active"
                                            id="pills-package1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body1"
                                            aria-selected="true"
                                            style={{ fontSize: "13px", borderRadius: "5px", background: tab === 1 && 'rgb(238, 146, 0)' }}
                                            onClick={() => setTab(1)}
                                        >
                                            {t("profile.in_progress")}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link travel"
                                            id="pills-package2"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body2"
                                            aria-selected="false"
                                            style={{ fontSize: "13px", borderRadius: "5px", background: tab === 2 && 'rgb(238, 146, 0)' }}
                                            onClick={() => setTab(2)}
                                        >
                                            {t("profile.completed")}
                                        </button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link travel"
                                            id="pills-package3"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body3"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body3"
                                            aria-selected="false"
                                            style={{ fontSize: "13px", borderRadius: "5px", background: tab === 3 && 'rgb(238, 146, 0)' }}
                                            onClick={() => setTab(3)}
                                        >
                                            {t("profile.cancelled")}
                                        </button>
                                    </li>
                                </ul>
                                <div className="col-lg-3 col-12 p-0">
                                    <ul
                                        className="nav  nav-pills gap-xxl-4 gap-3 pt-3"
                                        id="myTab"
                                        role="tablist"
                                        style={{ marginBottom: "0px" }}
                                    >
                                        <li className="nav-item">
                                            <button
                                                className={
                                                    `travel ${received == 1 ? "nav-link active" : "nav-link"}`
                                                }
                                                style={{
                                                    fontSize: "13px",
                                                    borderRadius: "5px",
                                                    padding: "8px 30px",
                                                    width: "151px",
                                                    background: received === 1 && 'rgb(238, 146, 0)'
                                                }}
                                                onClick={() => {
                                                    setReceived(1);
                                                    setArticleISold(1);
                                                }}
                                            >
                                                {t("profile.travels")}
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={
                                                    `luggage ${received == 2 ? "nav-link active" : "nav-link"}`
                                                }
                                                style={{
                                                    fontSize: "13px",
                                                    borderRadius: "5px",
                                                    padding: "8px 25px",
                                                    width: "151px",
                                                    background: received === 2 && 'rgb(83, 189, 226)'
                                                }}
                                                onClick={() => {
                                                    setReceived(2);
                                                    setArticleISold(1);
                                                }}
                                            >
                                                {t("profile.luggages")}
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={
                                                    `article ${received == 3 ? "nav-link active" : "nav-link"}`
                                                }
                                                style={{
                                                    fontSize: "13px",
                                                    borderRadius: "5px",
                                                    width: "151px",
                                                    background: received === 3 && 'gray'
                                                }}
                                                onClick={() => {
                                                    setReceived(3);
                                                    setArticleISold(1);
                                                }}
                                            >
                                                Articles
                                            </button>
                                        </li>
                                        <li className="nav-item">
                                            <button
                                                className={
                                                    `donation ${received == 4 ? "nav-link active" : "nav-link"}`
                                                }
                                                style={{ fontSize: "13px", width: "151px", borderRadius: "5px", background: received === 4 && 'black' }}
                                                onClick={() => {
                                                    setReceived(4);
                                                    setArticleISold(3);
                                                }}
                                            >
                                                Donation (Articles)
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div
                                    className="tab-content col-lg-9 col-12 p-0"
                                    id="pills-tabContent"
                                >
                                    {/*   In Progress*/}
                                    <div
                                        className="tab-pane fade show active package-info-tab pt-4"
                                        id="pill-body1"
                                        role="tabpanel"
                                        aria-labelledby="pills-package1"
                                    >
                                        <div
                                            class="container  pt-2 h-100 package-card-delta p-3"
                                            style={{ boxShadow: "none", border: "1px solid" }}
                                        >
                                            {(received == 1 || received == 2) && (
                                                <ul
                                                    className="nav nav-pills gap-xxl-4 gap-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Received_Request")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Sent_Request")}
                                                            {/* {t('profile.articles_I_bought')} */}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 3 && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_sold")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_bought")}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 4 && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 3 || articleISold == 7
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(3)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_confirmed_to_donate")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 4
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(4)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t(
                                                                "profile.articles_I_requested_to_have_in_donation"
                                                            )}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            <h4 className="fs-5 pt-2">
                                                {received == 1
                                                    ? t("profile.my_In_Progress_luggage_carrying_orders")
                                                    : received == 2
                                                        ? t("profile.my_Pending_luggage_orders")
                                                        : received == 3 && articleISold == 1
                                                            ? t("profile.payments_received_from_article_buyers")
                                                            : articleISold == 2 || articleISold == 5
                                                                ? t("profile.items_I_bought_pending_delivery")
                                                                : received == 4 && articleISold == 3
                                                                    ? t("profile.requests_I_confirmed_to_donate_my_items")
                                                                    : articleISold == 4
                                                                        ? t(
                                                                            "profile.items_I_want_in_donation_pending_delivery"
                                                                        )
                                                                        : ""}
                                            </h4>
                                            <hr />
                                            {received == 1 || received == 2 ? (
                                                <InprogressTravelLugggage
                                                    received={received}
                                                    iReceived={iReceived}
                                                    iSent={iSent}
                                                    articleISold={articleISold}
                                                />
                                            ) : (
                                                <div class="container p-0 h-100">
                                                    <div class="row d-flex justify-content-center align-items-center h-100">
                                                        <div class="col mb-4 mb-lg-0">
                                                            {filterInprogressOrder()?.length > 0 &&
                                                                filterInprogressOrder()?.map((item) => (
                                                                    <div
                                                                        class="package-card-delta card mb-3"
                                                                        style={{ borderRadius: "8px" }}
                                                                    >
                                                                        <div class="row g-0">
                                                                            <div class="col-md-4">
                                                                                <img
                                                                                    src={item?.image[0]}
                                                                                    style={{
                                                                                        aspectRatio: "4/2",
                                                                                        objectFit: "contain",
                                                                                        width: "100%",
                                                                                        height: "100%"
                                                                                    }}
                                                                                    class="card-img"
                                                                                    alt="..."
                                                                                />
                                                                            </div>
                                                                            <div class="col-md-8">
                                                                                <div class="card-body  package-sidebar pb-0">
                                                                                    <div class="row">
                                                                                        <div class="col-12 col-lg-6 col-md-6 mb-3">
                                                                                            <aside className="">
                                                                                                <div className="justify-content-between">
                                                                                                    {received == 3 ? (
                                                                                                        <h4 className="fw-bold">
                                                                                                            {item?.title}
                                                                                                        </h4>
                                                                                                    ) : (
                                                                                                        <h4 className="fw-bold fs-6">
                                                                                                            {item?.title}
                                                                                                        </h4>
                                                                                                    )}
                                                                                                    {received != 4 && (
                                                                                                        <h3
                                                                                                            className="widget-lavel"
                                                                                                            style={{
                                                                                                                color: "#ff4838",
                                                                                                            }}
                                                                                                        >
                                                                                                            {item?.article_price}{" "}
                                                                                                            {item.currency}{" "}
                                                                                                            <span></span>
                                                                                                        </h3>
                                                                                                    )}
                                                                                                </div>
                                                                                            </aside>
                                                                                            <p className="pt-0">
                                                                                                <i
                                                                                                    class="bi bi-cart-fill fs-6"
                                                                                                    style={{ color: "#212529" }}
                                                                                                ></i>{" "}
                                                                                                {t("profile.weight")}:
                                                                                                {received == 3
                                                                                                    ? item?.article_weight
                                                                                                    : item?.weight}{" "}
                                                                                                kg
                                                                                            </p>
                                                                                            <p className="pt-1 fs-6">
                                                                                                <i
                                                                                                    class="bi bi-cart-fill"
                                                                                                    style={{ color: "#212529" }}
                                                                                                ></i>{" "}
                                                                                                {t(
                                                                                                    "profile.quantity_requested"
                                                                                                )}{" "}
                                                                                                :{" "}
                                                                                                {received == 3
                                                                                                    ? item?.article_quantity
                                                                                                    : item?.quantity}
                                                                                            </p>
                                                                                            <p
                                                                                                className="pt-1 fs-6 fw-bold"
                                                                                                style={{ color: "#212529" }}
                                                                                            >
                                                                                                {articleISold == 1
                                                                                                    ? t("profile.item_sold_to")
                                                                                                    : articleISold == 2 ||
                                                                                                        articleISold == 5
                                                                                                        ? t(
                                                                                                            "profile.item_bought_from"
                                                                                                        )
                                                                                                        : articleISold == 3
                                                                                                            ? t("profile.item_given_to")
                                                                                                            : t(
                                                                                                                "profile.i_want_in_donation_from"
                                                                                                            )}
                                                                                            </p>
                                                                                            <div className=" d-flex  d-md-block">
                                                                                                <p class="fw-bold pt-0">
                                                                                                    <i class="bi bi-person-fill" />{" "}
                                                                                                    <sapn
                                                                                                        style={{ color: "#1e98d7" }}
                                                                                                    >
                                                                                                        {received == 3
                                                                                                            ? articleISold == 1
                                                                                                                ? item?.requester_user_name
                                                                                                                : item?.requestee_user_name
                                                                                                            : articleISold == 3
                                                                                                                ? item?.requester_name
                                                                                                                : item?.requestee_name}
                                                                                                    </sapn>
                                                                                                </p>
                                                                                                <p className="pt-0">
                                                                                                    <i
                                                                                                        class="bi bi-geo-alt-fill"
                                                                                                        style={{
                                                                                                            color: "#ff4838",
                                                                                                            fontWeight: "500",
                                                                                                        }}
                                                                                                    />{" "}
                                                                                                    {item?.requester_location}
                                                                                                </p>
                                                                                                {received == 3 && articleISold == 1 && <p>{t("profile.Phone_Number")}{' '}:{' '}{received == 3 && articleISold == 1 && item?.payment_status == true && item?.buyer_contact_number}</p>}
                                                                                                {articleISold == 2 || articleISold == 5 ? <p>{t("profile.Phone_Number")}{' '}:{' '}{articleISold == 2 || articleISold == 5 ? item?.payment_status == true && item?.seller_contact_number : ""}</p> : ""}
                                                                                                {received == 4 && articleISold == 3 && <p>{t("profile.Phone_Number")}{' '}:{' '}{received == 4 && articleISold == 3 && item?.buyer_contact_number}</p>}
                                                                                                {articleISold == 4 ? <p>{t("profile.Phone_Number")}{' '}:{' '}{articleISold == 4 && item?.seller_contact_number}</p> : ""}
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-12 col-lg-6 col-md-6 mb-3">
                                                                                            <div
                                                                                                class="earning"
                                                                                                style={{
                                                                                                    backgroundColor: "white",
                                                                                                    borderColor: "#ff4838",
                                                                                                    border: "2px solid",
                                                                                                }}
                                                                                            >
                                                                                                <div
                                                                                                    class="plan"
                                                                                                    style={{
                                                                                                        background: "white",
                                                                                                        width: "100%",
                                                                                                    }}
                                                                                                >
                                                                                                    {articleISold == 1 ||
                                                                                                        articleISold == 3 ? (
                                                                                                        <header
                                                                                                            style={{
                                                                                                                padding:
                                                                                                                    "0px 10px 12px",
                                                                                                            }}
                                                                                                        >
                                                                                                            <i class="bi bi-currency-dollar1"></i>
                                                                                                            <h6
                                                                                                                class="plan-title"
                                                                                                                style={{
                                                                                                                    color: "black",
                                                                                                                }}
                                                                                                            >
                                                                                                                {t("profile.status")}:
                                                                                                            </h6>
                                                                                                            <div class="plan-cost">
                                                                                                                <span class="plan-price fs-6">
                                                                                                                    {articleISold == 1
                                                                                                                        ? t(
                                                                                                                            "profile.payment_received_from_buyer"
                                                                                                                        )
                                                                                                                        : t(
                                                                                                                            "profile.confirmation_sent_to_requester"
                                                                                                                        )}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                        </header>
                                                                                                    ) : (
                                                                                                        <header
                                                                                                            style={{
                                                                                                                padding:
                                                                                                                    "0px 10px 12px",
                                                                                                            }}
                                                                                                        >
                                                                                                            <i class="bi bi-currency-dollar1"></i>
                                                                                                            <h6
                                                                                                                class="plan-title"
                                                                                                                style={{
                                                                                                                    color: "black",
                                                                                                                }}
                                                                                                            >
                                                                                                                {t("profile.status")}:
                                                                                                            </h6>
                                                                                                            <div class="plan-cost">
                                                                                                                <span
                                                                                                                    class="plan-price fs-6"
                                                                                                                    style={{
                                                                                                                        color:
                                                                                                                            item?.received_date ==
                                                                                                                                null
                                                                                                                                ? "#f39c12"
                                                                                                                                : "#63a103",
                                                                                                                    }}
                                                                                                                >
                                                                                                                    {item?.received_date ==
                                                                                                                        null
                                                                                                                        ? t(
                                                                                                                            "profile.item_pending_delivery"
                                                                                                                        )
                                                                                                                        : t(
                                                                                                                            "profile.item_delivered"
                                                                                                                        )}
                                                                                                                </span>
                                                                                                            </div>
                                                                                                            {item?.received_date !=
                                                                                                                null && (
                                                                                                                    <h6
                                                                                                                        class="plan-title"
                                                                                                                        style={{
                                                                                                                            color: "black",
                                                                                                                        }}
                                                                                                                    >
                                                                                                                        {t(
                                                                                                                            "profile.delivered_to"
                                                                                                                        )}
                                                                                                                        :
                                                                                                                    </h6>
                                                                                                                )}
                                                                                                            {item?.received_date !=
                                                                                                                null && (
                                                                                                                    <div class="plan-cost">
                                                                                                                        <span class="plan-price fs-6 fw-normal">
                                                                                                                            {item?.received_by}
                                                                                                                        </span>
                                                                                                                    </div>
                                                                                                                )}
                                                                                                            {item?.received_date !=
                                                                                                                null && (
                                                                                                                    <p className="pt-0">
                                                                                                                        {t(
                                                                                                                            "profile.delivery_date"
                                                                                                                        )}
                                                                                                                        :{" "}
                                                                                                                        {item?.received_date}
                                                                                                                    </p>
                                                                                                                )}
                                                                                                        </header>
                                                                                                    )}
                                                                                                </div>
                                                                                                <div class="plan-select pt-2">
                                                                                                    <a
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                            padding: "10px 14px",
                                                                                                            margin: "9px 0px 9px",
                                                                                                            fontWeight: "700",
                                                                                                        }}
                                                                                                        onClick={() => {
                                                                                                            handleShow();
                                                                                                            handleSetData(
                                                                                                                "request_id",
                                                                                                                item?.id
                                                                                                            );
                                                                                                            handleSetData(
                                                                                                                "request_type",
                                                                                                                item?.product_type
                                                                                                            );
                                                                                                            setModalData(item);
                                                                                                            if (articleISold == 5) {
                                                                                                                setArticleISold(2);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        {articleISold == 1 ||
                                                                                                            articleISold == 3
                                                                                                            ? t(
                                                                                                                "profile.mark_this_item_as_sent"
                                                                                                            )
                                                                                                            : t(
                                                                                                                "profile.confirm_receipt_of_item"
                                                                                                            )}
                                                                                                    </a>

                                                                                                    {/* <p
                                                                                                        style={{
                                                                                                            cursor: "pointer",
                                                                                                            padding: "10px 14px",
                                                                                                            margin: "9px 0px 9px",
                                                                                                            color: "blue"
                                                                                                        }}
                                                                                                        onClick={() => handlesShowChat(item)}
                                                                                                    >
                                                                                                        {articleISold == 1 ||
                                                                                                            articleISold == 3
                                                                                                            ? t("profile.send_the_message")
                                                                                                            : ""}
                                                                                                    </p> */}

                                                                                                    {(articleISold == 2 ||
                                                                                                        articleISold == 5) && (
                                                                                                            <a
                                                                                                                style={{
                                                                                                                    cursor: "pointer",
                                                                                                                    padding: "8px 14px",
                                                                                                                    margin: "0px",
                                                                                                                    borderRadius: "10px",
                                                                                                                    background: "#1e98d7",
                                                                                                                    fontWeight: "500",
                                                                                                                }}
                                                                                                                onClick={() => {
                                                                                                                    handleShow();
                                                                                                                    setModalData(item);
                                                                                                                    setArticleISold(5);
                                                                                                                }}
                                                                                                            >
                                                                                                                {" "}
                                                                                                                {t(
                                                                                                                    "profile.Raise_a_dispute"
                                                                                                                )}
                                                                                                            </a>
                                                                                                        )}
                                                                                                </div>

                                                                                                {/* <div class="plan-select pt-2">
                                                                                                    {articleISold == 1 ||
                                                                                                        articleISold == 3
                                                                                                        ? t(
                                                                                                            "profile.mark_this_item_as_sent"
                                                                                                        ) : ""
                                                                                                    }
                                                                                                </div> */}

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*  Completed */}
                                    <div
                                        className="tab-pane fade package-info-tab pt-4"
                                        id="pill-body2"
                                        role="tabpanel"
                                        aria-labelledby="pills-package2"
                                        style={{ overflowX: "auto" }}
                                    >
                                        <div
                                            class="container  pt-2 h-100 package-card-delta p-3"
                                            style={{ boxShadow: "none", border: "1px solid" }}
                                        >
                                            {(received == 1 || received == 2) && (
                                                <ul
                                                    className="nav nav-pills   gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Received_Request")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Sent_Request")}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 3 && (
                                                <ul
                                                    className="nav nav-pills   gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_sold")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_bought")}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 4 && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 3 || articleISold == 7
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(3)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_confirmed_to_donate")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 4
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(4)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t(
                                                                "profile.articles_I_requested_to_have_in_donation"
                                                            )}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            <h4 className="fs-5 pt-2">
                                                {received == 1
                                                    ? t("profile.my_Completed_travels_orders")
                                                    : received == 2
                                                        ? t("profile.my_completed_luggage_orders")
                                                        : ""}
                                            </h4>
                                            {(received == 1 || received == 2) && <hr />}
                                            {received == 1 || received == 2 ? (
                                                <CompletedTravelLuggage
                                                    received={received}
                                                    iReceived={iReceived}
                                                    iSent={iSent}
                                                    articleISold={articleISold}
                                                />
                                            ) : (
                                                <div class="container p-0 h-100">
                                                    <div class="row d-flex justify-content-center align-items-center h-100">
                                                        <div class=" col mb-4 mb-lg-0">
                                                            {filterCompletedOrder()?.map((item) => (
                                                                <div
                                                                    class="package-card-delta card mb-3"
                                                                    style={{ borderRadius: "8px" }}
                                                                >
                                                                    <div class="row g-0">
                                                                        {
                                                                            <div class="col-md-4">
                                                                                <img
                                                                                    src={item?.image[0]}
                                                                                    style={{
                                                                                        aspectRatio: "4/2",
                                                                                        objectFit: "contain",
                                                                                        width: "100%",
                                                                                        height: "100%"
                                                                                    }}
                                                                                    class="card-img"
                                                                                    alt="..."
                                                                                />
                                                                            </div>
                                                                        }
                                                                        <div class="col-md-8">
                                                                            <div class="card-body  package-sidebar pb-0">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-lg-6 col-md-6 mb-3">
                                                                                        <aside className="">
                                                                                            <div className="justify-content-between">
                                                                                                {received == 3 ? (
                                                                                                    <h4 className="fw-bold">
                                                                                                        {item?.title}
                                                                                                    </h4>
                                                                                                ) : (
                                                                                                    <h4 className="fw-bold fs-6">
                                                                                                        {item?.title}
                                                                                                    </h4>
                                                                                                )}
                                                                                                {received != 4 && (
                                                                                                    <h3
                                                                                                        className="widget-lavel"
                                                                                                        style={{ color: "#ff4838" }}
                                                                                                    >
                                                                                                        {item?.article_price}{" "}
                                                                                                        {item.currency}{" "}
                                                                                                        <span></span>
                                                                                                    </h3>
                                                                                                )}
                                                                                            </div>
                                                                                        </aside>
                                                                                        <p className="pt-0">
                                                                                            <i
                                                                                                class="bi bi-cart-fill fs-6"
                                                                                                style={{ color: "#212529" }}
                                                                                            ></i>{" "}
                                                                                            {t("profile.weight")}:
                                                                                            {received == 3
                                                                                                ? item?.article_weight
                                                                                                : item?.weight}{" "}
                                                                                            kg
                                                                                        </p>
                                                                                        <p className="pt-1 fs-6">
                                                                                            <i
                                                                                                class="bi bi-cart-fill"
                                                                                                style={{ color: "#212529" }}
                                                                                            ></i>{" "}
                                                                                            {t("profile.quantity_requested")}{" "}
                                                                                            :{" "}
                                                                                            {received == 3
                                                                                                ? item?.article_quantity
                                                                                                : item?.quantity}
                                                                                        </p>
                                                                                        <p
                                                                                            className="pt-1 fs-6 fw-bold"
                                                                                            style={{ color: "#212529" }}
                                                                                        >
                                                                                            {articleISold == 1
                                                                                                ? t("profile.item_sold_to")
                                                                                                : articleISold == 2 ||
                                                                                                    articleISold == 5
                                                                                                    ? t("profile.item_bought_from")
                                                                                                    : articleISold == 3
                                                                                                        ? t("profile.item_given_to")
                                                                                                        : t(
                                                                                                            "profile.i_received_in_donation_from"
                                                                                                        )}
                                                                                        </p>
                                                                                        <div className="d-flex  d-md-block">
                                                                                            <p class="fw-bold pt-0">
                                                                                                <i class="bi bi-person-fill" />{" "}
                                                                                                <sapn
                                                                                                    style={{ color: "#1e98d7" }}
                                                                                                >
                                                                                                    {received == 3
                                                                                                        ? articleISold == 1
                                                                                                            ? item?.requester_user_name
                                                                                                            : item?.requestee_user_name
                                                                                                        : articleISold == 3
                                                                                                            ? item?.requester_name
                                                                                                            : item?.requestee_name}
                                                                                                </sapn>
                                                                                            </p>
                                                                                            <p className="pt-0">
                                                                                                <i
                                                                                                    class="bi bi-geo-alt-fill"
                                                                                                    style={{
                                                                                                        color: "#ff4838",
                                                                                                        fontWeight: "500",
                                                                                                    }}
                                                                                                />{" "}
                                                                                                {item?.requester_location}
                                                                                            </p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-12 col-lg-6 col-md-6 mb-3">
                                                                                        <div
                                                                                            class="earning"
                                                                                            style={{
                                                                                                backgroundColor: "white",
                                                                                                borderColor: "#ff4838",
                                                                                                border: "2px solid",
                                                                                            }}
                                                                                        >
                                                                                            <div
                                                                                                class="plan"
                                                                                                style={{
                                                                                                    background: "white",
                                                                                                    width: "100%",
                                                                                                }}
                                                                                            >
                                                                                                <header
                                                                                                    style={{
                                                                                                        padding: "0px 5px 12px",
                                                                                                        textAlign: "center",
                                                                                                    }}
                                                                                                >
                                                                                                    <i class="bi bi-currency-dollar1"></i>
                                                                                                    <h6
                                                                                                        class="plan-title"
                                                                                                        style={{ color: "black" }}
                                                                                                    >
                                                                                                        {t("profile.delivery_date")}
                                                                                                        :
                                                                                                    </h6>
                                                                                                    <div class="plan-cost">
                                                                                                        <span class="plan-price fs-6 fw-normal">
                                                                                                            {item?.received_date
                                                                                                                ? item?.received_date
                                                                                                                : item?.updated_at?.slice(
                                                                                                                    0,
                                                                                                                    10
                                                                                                                )}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    <h6
                                                                                                        class="plan-title"
                                                                                                        style={{ color: "black" }}
                                                                                                    >
                                                                                                        {t("profile.delivered_to")}:
                                                                                                    </h6>
                                                                                                    <div class="plan-cost">
                                                                                                        <span class="plan-price fs-6 fw-normal">
                                                                                                            {item?.received_by
                                                                                                                ? item?.received_by
                                                                                                                : item?.requester_name}
                                                                                                        </span>
                                                                                                    </div>
                                                                                                    {/* <p className='pt-0'>(brother of Elodie Dumas)</p> */}
                                                                                                </header>
                                                                                            </div>
                                                                                        </div>
                                                                                        {articleISold == 2 ? <div>
                                                                                            <Button style={{ backgroundColor: '#1e98d6', borderColor: '#1e98d6', borderRadius: '5px', border: '0px', color: 'white', margin: '10px' }}
                                                                                                onClick={() => handleShowRatting(item)}
                                                                                            >
                                                                                                {' '}  {t('profile.rate_the_Article')}
                                                                                            </Button>
                                                                                        </div> : ""}
                                                                                        {articleISold == 4 || articleISold == 3 ? <div>
                                                                                            <Button style={{ backgroundColor: '#1e98d6', borderColor: '#1e98d6', borderRadius: '5px', border: '0px', color: 'white', margin: '10px' }}
                                                                                                onClick={() => handleShowRatting(item)}
                                                                                            >
                                                                                                {' '}  {t('profile.rate_the_Donation')}
                                                                                            </Button>
                                                                                        </div> : ""}
                                                                                    </div>

                                                                                    {item.confirm_status == 1 && articleISold !== 1 ? <div class="col-12 col-lg-6 col-md-6 mb-3">
                                                                                        <a
                                                                                            style={{
                                                                                                cursor: "pointer",
                                                                                                padding: "8px 14px",
                                                                                                margin: "0px",
                                                                                                borderRadius: "10px",
                                                                                                background: "#1e98d7",
                                                                                                fontWeight: "500",
                                                                                                color: "white"
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                handleShow();
                                                                                                setModalData(item);
                                                                                                setArticleISold(5);
                                                                                            }}
                                                                                        >
                                                                                            {" "}
                                                                                            {t(
                                                                                                "profile.Raise_a_dispute"
                                                                                            )}
                                                                                        </a>
                                                                                    </div> : ""}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    {/*  Cancelled*/}
                                    <div
                                        className="tab-pane fade package-info-tab pt-4"
                                        id="pill-body3"
                                        role="tabpanel"
                                        aria-labelledby="pills-package3"
                                        style={{ overflowX: "auto" }}
                                    >
                                        <div
                                            class="container  pt-2 h-100 package-card-delta p-3"
                                            style={{ boxShadow: "none", border: "1px solid" }}
                                        >
                                            {(received == 1 || received == 2) && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Received_Request")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.Sent_Request")}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 3 && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 1 || articleISold == 6
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(1)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_sold")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 2 || articleISold == 5
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(2)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_bought")}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            {received == 4 && (
                                                <ul
                                                    className="nav nav-pills  gap-xxl-4 gap-3 pb-3"
                                                    id="myTab"
                                                    role="tablist"
                                                    style={{ marginBottom: "0px" }}
                                                >
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 3 || articleISold == 7
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(3)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t("profile.articles_I_confirmed_to_donate")}
                                                        </button>
                                                    </li>
                                                    <li className="nav-item" role="presentation">
                                                        <button
                                                            className={
                                                                `travel ${articleISold == 4
                                                                    ? "nav-link active"
                                                                    : "nav-link"}`
                                                            }
                                                            onClick={() => setArticleISold(4)}
                                                            style={{
                                                                borderRadius: "5px",
                                                                // border: "1px solid",
                                                                // borderColor: "#ff4838",
                                                                fontSize: "13px",
                                                            }}
                                                        >
                                                            {t(
                                                                "profile.articles_I_requested_to_have_in_donation"
                                                            )}
                                                        </button>
                                                    </li>
                                                </ul>
                                            )}
                                            <h4 className="fs-5 pt-2">
                                                {received == 1
                                                    ? t("profile.my_cancelled_travels_orders")
                                                    : received == 2
                                                        ? t("profile.my_cancelled_luggage_orders")
                                                        : ""}
                                            </h4>
                                            {(received == 1 || received == 2) && <hr />}
                                            {received == 1 || received == 2 ? (
                                                <CancelledLuggage
                                                    received={received}
                                                    iReceived={iReceived}
                                                    iSent={iSent}
                                                    articleISold={articleISold}
                                                />
                                            ) : (
                                                <div class="container p-0 h-100">
                                                    <div class="row d-flex justify-content-center align-items-center h-100">
                                                        <div class=" col mb-4 mb-lg-0">
                                                            {filterCancelledOrder()?.map((item) => (
                                                                <div
                                                                    class="package-card-delta card mb-3"
                                                                    style={{ borderRadius: "8px" }}
                                                                >
                                                                    <div class="row g-0">
                                                                        <div class="col-md-4">
                                                                            <img
                                                                                src={item?.image[0]}
                                                                                style={{
                                                                                    aspectRatio: "4/2",
                                                                                    objectFit: "contain",
                                                                                    width: "100%",
                                                                                    height: "100%"
                                                                                }}
                                                                                class="card-img"
                                                                                alt="..."
                                                                            />
                                                                        </div>
                                                                        <div class="col-md-8">
                                                                            <div class="card-body package-sidebar pb-0">
                                                                                <div class="row">
                                                                                    <div class="col-12 col-lg-7 col-md-6 mb-3">
                                                                                        <aside className="">
                                                                                            <div className="justify-content-between">
                                                                                                {received == 3 ? (
                                                                                                    <h4 className="fw-bold">
                                                                                                        {item?.title}
                                                                                                    </h4>
                                                                                                ) : (
                                                                                                    <h4 className="fw-bold fs-6">
                                                                                                        {item?.title}
                                                                                                    </h4>
                                                                                                )}
                                                                                                {received != 4 && (
                                                                                                    <h3
                                                                                                        className="widget-lavel"
                                                                                                        style={{ color: "#ff4838" }}
                                                                                                    >
                                                                                                        {item?.article_price}{" "}
                                                                                                        {item.currency}
                                                                                                        <span></span>
                                                                                                    </h3>
                                                                                                )}
                                                                                            </div>
                                                                                        </aside>
                                                                                        <p className="pt-0">
                                                                                            <i
                                                                                                class="bi bi-cart-fill fs-6"
                                                                                                style={{ color: "#212529" }}
                                                                                            ></i>{" "}
                                                                                            {t("profile.weight")}:
                                                                                            {received == 3
                                                                                                ? item?.article_weight
                                                                                                : item?.weight}{" "}
                                                                                            kg
                                                                                        </p>
                                                                                        <p className="pt-1 fs-6">
                                                                                            <i
                                                                                                class="bi bi-cart-fill"
                                                                                                style={{ color: "#212529" }}
                                                                                            ></i>{" "}
                                                                                            {t("profile.quantity_requested")}{" "}
                                                                                            :{" "}
                                                                                            {received == 3
                                                                                                ? item?.article_quantity
                                                                                                : item?.quantity}
                                                                                        </p>
                                                                                        <p
                                                                                            className="pt-1 fs-6 fw-bold"
                                                                                            style={{ color: "#212529" }}
                                                                                        >
                                                                                            {articleISold == 1
                                                                                                ? t("profile.item_sold_to")
                                                                                                : articleISold == 2 ||
                                                                                                    articleISold == 5
                                                                                                    ? t("profile.item_bought_from")
                                                                                                    : articleISold == 3
                                                                                                        ? t("profile.item_given_to")
                                                                                                        : t(
                                                                                                            "profile.i_received_in_donation_from"
                                                                                                        )}
                                                                                        </p>
                                                                                        <div className="d-flex  d-md-block">
                                                                                            <p class="fw-bold pt-0">
                                                                                                <i class="bi bi-person-fill" />{" "}
                                                                                                <sapn
                                                                                                    style={{ color: "#1e98d7" }}
                                                                                                >
                                                                                                    {received == 3
                                                                                                        ? articleISold == 1
                                                                                                            ? item?.requester_user_name
                                                                                                            : item?.requestee_user_name
                                                                                                        : articleISold == 3
                                                                                                            ? item?.requester_name
                                                                                                            : item?.requestee_name}
                                                                                                </sapn>
                                                                                            </p>
                                                                                            <p className="pt-0">
                                                                                                <i
                                                                                                    class="bi bi-geo-alt-fill"
                                                                                                    style={{
                                                                                                        color: "#ff4838",
                                                                                                        fontWeight: "500",
                                                                                                    }}
                                                                                                />{" "}
                                                                                                {item?.requester_location}
                                                                                            </p>

                                                                                        </div>
                                                                                    </div>
                                                                                    <div class="col-12 col-lg-5 col-md-6 mb-3">
                                                                                        <div
                                                                                            class="earning justify-content-start"
                                                                                            style={{
                                                                                                backgroundColor: "white",
                                                                                                borderColor: "#ff4838",
                                                                                                border: "2px solid",
                                                                                            }}
                                                                                        >
                                                                                            <div class="plan-select pt-2">
                                                                                                <a
                                                                                                    style={{
                                                                                                        cursor: "pointer",
                                                                                                        padding: "8px 35px",
                                                                                                        fontWeight: "700",
                                                                                                        backgroundColor: "#d9001b",
                                                                                                        borderRadius: "10px",
                                                                                                        margin: "0px",
                                                                                                    }}
                                                                                                >
                                                                                                    {t("profile.cancelled")}
                                                                                                </a>
                                                                                            </div>
                                                                                            <div className="plan-select">
                                                                                                <p>
                                                                                                    {t(
                                                                                                        "profile.cancellation_date"
                                                                                                    )}
                                                                                                    : <br />
                                                                                                    <span className="fw-bold">
                                                                                                        {item?.updated_at?.slice(
                                                                                                            0,
                                                                                                            10
                                                                                                        )}
                                                                                                    </span>{" "}
                                                                                                </p>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*------ Dialogue Box --------*/}
                    <Modal
                        show={show}
                        onHide={handleClose}
                        size="lg"
                        // aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>
                                {articleISold == 1
                                    ? t("profile.confirm_delivery_of_sold_item")
                                    : articleISold == 2
                                        ? t("profile.confirm_receipt_of_item_you_bought")
                                        : articleISold == 3
                                            ? t("profile.confirm_delivery_of_donated_item")
                                            : articleISold == 4
                                                ? t("profile.confirm_receipt_of_donated_item")
                                                : articleISold == 5
                                                    ? t("profile.raise_dispute")
                                                    : articleISold == 6 || articleISold == 7
                                                        ? t("profile.success")
                                                        : ""}
                            </Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Form>
                                <div class="container ">
                                    <div class="row d-flex justify-content-center align-items-center">
                                        <div class="col mb-lg-0">
                                            <h5 className="text-center fw-normal fs-6">
                                                {articleISold == 1
                                                    ? t(
                                                        "profile.your_are_about_to_confirm_that_the_sold_item"
                                                    )
                                                    : articleISold == 2
                                                        ? t("profile.confirm_that_the_item_you_bought")
                                                        : articleISold == 3
                                                            ? t(
                                                                "profile.confirm_that_the_donated_item_has_been_successfully_delivered"
                                                            )
                                                            : articleISold == 4
                                                                ? t(
                                                                    "profile.confirm_that_the_item_you_wanted_to_have_in_donation"
                                                                )
                                                                : articleISold == 5
                                                                    ? t(
                                                                        "profile.your_are_about_to_raise_a_dispute_for_the_item"
                                                                    )
                                                                    : articleISold == 6 || articleISold == 7
                                                                        ? t("profile.congratulations")
                                                                        : ""}
                                            </h5>
                                            {(articleISold == 6 || articleISold == 7) && <br />}
                                            <h5 className="text-center  fw-normal fs-6">
                                                {articleISold == 5
                                                    ? t("profile.admin_will_be_notified")
                                                    : articleISold == 6
                                                        ? t(
                                                            "profile.buyer_receiving_the_order_along_with_leaving_his_feedback"
                                                        )
                                                        : articleISold == 7
                                                            ? t(
                                                                "profile.user_has_been_notified_about_your_delivery"
                                                            )
                                                            : ""}
                                            </h5>
                                            {(articleISold == 6 || articleISold == 7) && <br />}
                                            {(articleISold == 6 || articleISold == 7) && (
                                                <h5 className="text-center  fw-normal fs-6">
                                                    {t("profile.happy_travelling_while_earning")}
                                                </h5>
                                            )}
                                            {(articleISold != 6 || articleISold != 7) && (
                                                <div class="pt-2" style={{ borderRadius: "8px" }}>
                                                    {(articleISold == 1 ||
                                                        articleISold == 3 ||
                                                        articleISold == 4) && (
                                                            <div>
                                                                <div class="justify-content-center d-lg-flex d-md-flex my-3">
                                                                    <div class="">
                                                                        <img
                                                                            src={modalData?.image[0]}
                                                                            class="card-img"
                                                                            alt="..."
                                                                            style={{
                                                                                aspectRatio: "4/2",
                                                                                objectFit: "contain",
                                                                                width: "100%",
                                                                                height: "100%"
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div class="">
                                                                        <div class="card-body">
                                                                            <h4 className="fs-5 fw-bold">
                                                                                {modalData?.title}{" "}
                                                                            </h4>
                                                                            {articleISold == 1 && (
                                                                                <h4
                                                                                    className="fs-5 fw-bold"
                                                                                    style={{ color: "#ff4838" }}
                                                                                >
                                                                                    {modalData?.article_price}{" "}
                                                                                    {modalData?.currency}
                                                                                </h4>
                                                                            )}
                                                                            {/* <hr class="mt-0 mb-4" /> */}
                                                                            <div class="  pt-2 ">
                                                                                <div class=" mb-3">
                                                                                    <h6>
                                                                                        <i class="bi bi-cart-fill"></i>{" "}
                                                                                        {t("profile.weight")}
                                                                                    </h6>
                                                                                    <p class="text-muted">
                                                                                        {received == 3
                                                                                            ? modalData?.article_weight
                                                                                            : modalData?.weight}{" "}
                                                                                        kg
                                                                                    </p>
                                                                                </div>
                                                                                <div class=" mb-3">
                                                                                    <h6>
                                                                                        <i class="bi bi-cart-fill"></i>{" "}
                                                                                        {t("profile.quantity_requested")}
                                                                                    </h6>
                                                                                    <p class="text-muted">
                                                                                        {received == 3
                                                                                            ? modalData?.article_quantity
                                                                                            : modalData?.quantity}
                                                                                    </p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="row">
                                                                    <div className="col-lg-4 col-sm-12 col-md-12">
                                                                        <h4 className="fs-5 fw-bold">
                                                                            {articleISold == 3 || articleISold == 4
                                                                                ? t("profile.donated_to") + ":"
                                                                                : t("profile.sold_to") + ":"}
                                                                        </h4>
                                                                        <p>
                                                                            <i class="bi bi-person-fill" />{" "}
                                                                            <sapn style={{ color: "#1e98d7" }}>
                                                                                {articleISold == 4
                                                                                    ? modalData?.requester_name
                                                                                    : articleISold == 3
                                                                                        ? modalData?.requester_name
                                                                                        : modalData?.requester_user_name}
                                                                            </sapn>
                                                                        </p>
                                                                        <p>
                                                                            <i
                                                                                class="bi bi-geo-alt-fill"
                                                                                style={{ color: "#ff4838" }}
                                                                            />{" "}
                                                                            {modalData?.requester_location}
                                                                        </p>
                                                                    </div>
                                                                    {articleISold == 1 || articleISold == 3 ? (
                                                                        <div className="col-lg-4 col-sm-12 col-md-6">
                                                                            <label className="fs-5 fw-bold">
                                                                                {t("profile.received_by")}:
                                                                            </label>
                                                                            <input
                                                                                name="received_by"
                                                                                onChange={handleChange}
                                                                                style={{ borderColor: "#ff4838" }}
                                                                            />
                                                                            <span style={{ color: "red" }}>
                                                                                {error.received_by}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="col-lg-4 col-sm-12 col-md-6">
                                                                            <h4 className="fs-5 fw-bold">
                                                                                {t("profile.donated_by")}:
                                                                            </h4>
                                                                            <p>
                                                                                <i class="bi bi-person-fill" />{" "}
                                                                                <sapn style={{ color: "#1e98d7" }}>
                                                                                    {modalData?.requestee_name}
                                                                                </sapn>
                                                                            </p>
                                                                            <p>
                                                                                <i
                                                                                    class="bi bi-geo-alt-fill"
                                                                                    style={{ color: "#ff4838" }}
                                                                                />{" "}
                                                                                {modalData?.requestee_location}
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {articleISold == 1 || articleISold == 3 ? (
                                                                        <div className="col-lg-4 col-sm-12 col-md-6">
                                                                            <label className="fs-5 fw-bold">
                                                                                {t("profile.received_date")}:
                                                                            </label>
                                                                            <DatePicker
                                                                                placeholderText={
                                                                                    i18n.language === "frFR" ? "dd/mm/yyyy" : "mm/dd/yyyy"
                                                                                }
                                                                                dateFormat={
                                                                                    i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                                                                                }
                                                                                selected={data.received_date ? moment(data.received_date, "DD/MM/YYYY").toDate() : null}
                                                                                onChange={(date) => {
                                                                                    const formattedDate = moment(date).format("DD/MM/YYYY");
                                                                                    handleSetData("received_date", formattedDate);
                                                                                }}
                                                                                maxDate={new Date()}
                                                                                className="px-3"
                                                                            />

                                                                            <span style={{ color: "red" }}>
                                                                                {error.received_date}
                                                                            </span>
                                                                        </div>
                                                                    ) : (
                                                                        <div className="col-lg-4 col-sm-12 col-md-6">
                                                                            <h4 className="fs-5 fw-bold">
                                                                                {t("profile.received_by")}:
                                                                            </h4>
                                                                            <p>
                                                                                <i class="bi bi-person-fill" />{" "}
                                                                                <sapn style={{ color: "#1e98d7" }}>
                                                                                    {modalData?.requester_name}
                                                                                </sapn>
                                                                            </p>
                                                                        </div>
                                                                    )}
                                                                    {(articleISold == 4 && <>
                                                                        <>
                                                                            <div className="col-lg-4 col-sm-12 col-md-6 mt-3">
                                                                                <label className="fs-5 fw-bold">
                                                                                    {t("profile.received_by")}:
                                                                                </label>
                                                                                <input
                                                                                    name="received_by"
                                                                                    onChange={handleChange}
                                                                                    style={{ borderColor: "#ff4838" }}
                                                                                />
                                                                                <span style={{ color: "red" }}>
                                                                                    {error.received_by}
                                                                                </span>
                                                                            </div>
                                                                            <div className="col-lg-4 col-sm-12 col-md-6 mt-3">
                                                                                <label className="fs-5 fw-bold">
                                                                                    {t("profile.received_date")}:
                                                                                </label>
                                                                                <DatePicker
                                                                                    placeholderText={
                                                                                        i18n.language === "frFR" ? "dd/mm/yyyy" : "mm/dd/yyyy"
                                                                                    }
                                                                                    dateFormat={
                                                                                        i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                                                                                    }
                                                                                    selected={data.received_date ? moment(data.received_date, "DD/MM/YYYY").toDate() : null}
                                                                                    onChange={(date) => {
                                                                                        const formattedDate = moment(date).format("DD/MM/YYYY");
                                                                                        handleSetData("received_date", formattedDate);
                                                                                    }}
                                                                                    maxDate={new Date()}
                                                                                    className="px-3"
                                                                                />

                                                                                <span style={{ color: "red" }}>
                                                                                    {error.received_date}
                                                                                </span>
                                                                            </div>
                                                                        </>
                                                                    </>)}
                                                                </div>
                                                            </div>
                                                        )}
                                                    {(articleISold == 2 || articleISold == 5) && (
                                                        <div>
                                                            <div class="row justify-content-center">

                                                                <div className="col-lg-3">
                                                                    <img
                                                                        src={modalData?.image[0]}
                                                                        class="card-img"
                                                                        alt="..."
                                                                        style={{
                                                                            aspectRatio: "4/2",
                                                                            objectFit: "contain",
                                                                            width: "100%",
                                                                            height: "100%"
                                                                        }}

                                                                    />
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div class="card-body">
                                                                        <h4 className="fs-5 fw-bold">
                                                                            {modalData?.title}{" "}
                                                                        </h4>
                                                                        <h4
                                                                            className="fs-5 fw-bold"
                                                                            style={{ color: "#ff4838" }}
                                                                        >
                                                                            {modalData?.article_price}{" "}
                                                                            {modalData?.currency}
                                                                        </h4>
                                                                        {/* <hr class="mt-0 mb-4" /> */}
                                                                        <div class="pt-2">
                                                                            <div class="mb-3">
                                                                                <h6>
                                                                                    <i class="bi bi-cart-fill"></i>{" "}
                                                                                    {t("profile.weight")}
                                                                                </h6>
                                                                                <p class="text-muted">
                                                                                    {modalData?.article_weight} kg
                                                                                </p>
                                                                            </div>
                                                                            <div class="mb-3">
                                                                                <h6>
                                                                                    <i class="bi bi-cart-fill"></i>{" "}
                                                                                    {t("profile.quantity_requested")} :{" "}{modalData?.article_quantity}
                                                                                </h6>
                                                                                {/* <p class="text-muted">
                                                                                    {modalData?.article_quantity}
                                                                                </p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-4">
                                                                    <div class="card-body">
                                                                        <h4 className="fs-5 fw-bold">
                                                                            {t("profile.bought_from")}:
                                                                        </h4>
                                                                        <div class="pt-2">
                                                                            <div class="mb-3">
                                                                                <p>
                                                                                    <i class="bi bi-person-fill" />{" "}
                                                                                    <sapn style={{ color: "#1e98d7" }}>
                                                                                        {modalData?.requestee_user_name}
                                                                                    </sapn>
                                                                                    <span>
                                                                                        {" "}
                                                                                        <i
                                                                                            class="bi bi-geo-alt-fill"
                                                                                            style={{ color: "#ff4838" }}
                                                                                        />{" "}
                                                                                        {modalData?.requester_location}
                                                                                    </span>
                                                                                </p>
                                                                            </div>
                                                                            <div class="mb-3">
                                                                                <h4 className="fs-5 fw-bold">
                                                                                    {t("profile.received_by")}:
                                                                                </h4>
                                                                                <p style={{ color: "#1e98d7" }}>
                                                                                    {modalData?.requester_user_name}
                                                                                </p>
                                                                                {/* <p>John Doe (brother of Elodie Dumas)</p> */}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                {
                                                                    articleISold == 2 && (<>
                                                                        <div className="col-lg-4 col-sm-12 col-md-6 mt-3">
                                                                            <label className="fs-5 fw-bold">
                                                                                {t("profile.received_by")}:
                                                                            </label>
                                                                            <input
                                                                                name="received_by"
                                                                                onChange={handleChange}
                                                                                style={{ borderColor: "#ff4838" }}
                                                                            />
                                                                            <span style={{ color: "red" }}>
                                                                                {error.received_by}
                                                                            </span>
                                                                        </div>
                                                                        <div className="col-lg-4 col-sm-12 col-md-6 mt-3">
                                                                            <label className="fs-5 fw-bold">
                                                                                {t("profile.received_date")}:
                                                                            </label>
                                                                            <DatePicker
                                                                                placeholderText={
                                                                                    i18n.language === "frFR" ? "dd/mm/yyyy" : "mm/dd/yyyy"
                                                                                }
                                                                                dateFormat={
                                                                                    i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                                                                                }
                                                                                selected={data.received_date ? moment(data.received_date, "DD/MM/YYYY").toDate() : null}
                                                                                onChange={(date) => {
                                                                                    const formattedDate = moment(date).format("DD/MM/YYYY");
                                                                                    handleSetData("received_date", formattedDate);
                                                                                }}
                                                                                maxDate={new Date()}
                                                                                className="px-3"
                                                                            />

                                                                            <span style={{ color: "red" }}>
                                                                                {error.received_date}
                                                                            </span>
                                                                        </div>
                                                                    </>)
                                                                }
                                                            </div>
                                                            {articleISold != 5 ? (
                                                                <div className="row justify-content-center text-center">
                                                                    {/* <h4>Rate your purchase</h4>
                                                                                                                                                <Rating
                                                                                                                                                    onClick={handleRating} /> */}
                                                                </div>
                                                            ) : (
                                                                <div class="row d-flex justify-content-center align-items-center">
                                                                    <div class=" col  mb-lg-0">
                                                                        <div
                                                                            class="pt-2"
                                                                            style={{ borderRadius: "8px" }}
                                                                        >
                                                                            <div class=" justify-content-center d-lg-flex d-md-flex">
                                                                                <div class="">
                                                                                    <select
                                                                                        className=""
                                                                                        style={{ borderColor: "#ff4838" }}
                                                                                        id="raise_dispute"
                                                                                        name="raise_dispute"
                                                                                        onChange={handleRaisChange}
                                                                                        value={raisedispute.raise_dispute ? raisedispute.raise_dispute : 0}
                                                                                    >
                                                                                        <option
                                                                                            value={0}
                                                                                            disabled
                                                                                            selected
                                                                                        >
                                                                                            -
                                                                                            {t(
                                                                                                "profile.select_reasone_to_reject"
                                                                                            )}
                                                                                            -
                                                                                        </option>
                                                                                        <option value={1}>
                                                                                            {t(
                                                                                                "profile.I_did_not_received_the_item_at_all"
                                                                                            )}
                                                                                        </option>
                                                                                        <option value={2}>
                                                                                            {t(
                                                                                                "profile.item_is_not_as_described"
                                                                                            )}
                                                                                        </option>
                                                                                        <option value={3}>
                                                                                            {t("profile.item_is_defective")}
                                                                                        </option>
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            )}
                                                        </div>
                                                    )}

                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className="justify-content-center">
                            <Button
                                onClick={() =>
                                    articleISold == 1
                                        ? handleSubmit()
                                        : articleISold == 3
                                            ? handleSubmit()
                                            : articleISold == 2 || articleISold == 4
                                                ? confirmDeliveryOrder()
                                                : articleISold == 5 ? handleRaisSubmit() : articleISold == 6 || articleISold == 7 ? handleClose() : handleClose()
                                }
                                style={{
                                    backgroundColor: articleISold == 5 ? "#1e98d7" : "#ff4838",
                                    borderColor: articleISold == 5 ? "#1e98d7" : "#ff4838",
                                }}
                            >
                                {articleISold == 5
                                    ? t("form.submit")
                                    : articleISold == 6 || articleISold == 7
                                        ? t("close")
                                        : t("profile.i_accept_confirm")}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                    {/* -------------------------- */}

                    {/* {----chat box-----} */}
                    <Modal
                        show={show1}
                        onHide={handleClose}
                        size="small"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{t("profile.Chat_box")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: "0px", margin: "0px" }}>
                            <Chat
                                senderID={
                                    show1.requester_user_id == contextObj.loginUser.id
                                        ? show1.requester_user_id
                                        : show1.requestee_user_id
                                }
                                receiverID={
                                    show1.requestee_user_id == contextObj.loginUser.id
                                        ? show1.requester_user_id
                                        : show1.requestee_user_id
                                }
                                requestedby={show1.requester_user_id}
                                requestedto={show1.requestee_user_id}
                                product_type={show1.product_type || show1.request_type}
                                product_id={show1.product_id}
                            />
                        </Modal.Body>
                    </Modal>
                    {/* {---Ratting Box----} */}
                    <Modal show={show2} onHide={handleClose}
                        // size="lg"
                        centered
                    >
                        {console.log("show2", show2)}
                        <Modal.Header closeButton>
                            <Modal.Title>{t('profile.rate_your_experience')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Form>
                                <div class="container">
                                    <p className="pb-2">{t('profile.please_rate_your_experience_with_Article_sender')}</p>
                                    <p className="pb-3"><span><i class="bi bi-person-fill" /></span><span className="fw-bold" style={{ color: '#1e98d7' }}>
                                        {/* {LuggageRequest?.travel_user_name} */}
                                    </span> {t("profile.from")} <span></span><span className="fw-bold" style={{ color: '#1e98d7' }}>
                                            {show2?.requestee_user_name || show2?.requestee_name}
                                        </span></p>
                                    <h5>{t('profile.rating')}</h5>
                                    <Rating
                                        onClick={handleRating}
                                    />
                                    <p>{t('profile.comment')}</p>
                                    <textarea></textarea>
                                </div>
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center' >
                            <Button style={{ backgroundColor: '#1e98d7', borderColor: '#1e98d7' }} onClick={() => handleSubmitRating()}>
                                {t('form.submit')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>
            )}
        </>
    );
}
