const form = {
  travel_ads: "Travel Ads",
  type_of_traveler: "Type of traveler",
  periodical: "Periodical",
  one_off: "One-off",
  title: "Title",
  country_Of_departure: "Country of Departure",
  country_of_arrival: "Country of Arrival",
  state_of_departure: "State of Departure",
  state_of_arrival: "State of Arrival",
  city_of_departure: "City of Departure",
  city_of_arrival: "City of Arrival",
  pick_up_place: "Pick Up Place",
  enter_pick_up_place: "Enter Pick Up Place",
  delivery_place: "Delivery Place",
  Requester_Name: "Requester Name",
  enter_delivery_place: "Enter Delivery Place",
  departure_date: "Departure Date",
  arrival_date: "Arrival Date",
  type_of_transport: "Type Of Transport ",
  plane: "Plane",
  metro: "Metro",
  car: "Car",
  kick_scooter: "Kick-scooter",
  boat: "Boat",
  bike: "Bike",
  departure_time: "Departure Time",
  arrival_time: "Arrival Time",
  carriage_capacity: "Carriage Capacity",
  price_per_kg: "Price per kg",
  price: "Unit price",
  quantity: "Quantity",
  trip_type: "Trip Type",
  select_trip_type: "Select trip type",
  single_trip: "Single trip",
  round_trip: "Round trip",
  height: "Height",
  weight: "Weight",
  length: "Length",
  packaging_instruction: "Packaging Instruction",
  submit: "Submit",
  cancle: "Cancel",
  country_Of_stopper: "Country Of stopover",
  state_Of_stopper: "State Of stopover",
  city_of_stopover: "City of stopover",
  vehicle_type: "Transport",
  Preview_Advert: "Preview Advert",
  Is_a_stopover: "Is a stopover planned in the middle of your trip ?",
  train: "Metro",
  Flight: "Plane",
  Bus: "Bus",
  Train_Number: "Metro Number",
  Car_Registration: "Car Registration Number & Make + Mode",
  Flight_Number: "Plane Number",
  Bus_Number: "Bus Number",
  travel_insurance: "Does your trip benefit from travel insurance ?",
  Description: "Description",
  Terms_and_condition: "Terms and condition",
  Post_a_travel_announcement: "Post kg of luggage for sale(Traveler)",
  Travel_Announcement: "Travel Announcement",
  PLEASE_CARRY_SHIPPER:
    "PLEASE CARRY SHIPPER'S LUGGAGES WITH VERIFIED PROFILES",
  Trip_Type: "Trip Type",
  Total_Carriage_Capacity: "Total Carriage Capacity",
  Carrying_Capacity_remaining: "Remaining Carriage Capacity",
  Remaining_Capacity: "Remaining Capacity",
  Drop_Off_Location: "Luggage Pick Up Location",
  Delivery_Location: "Luggage Delivery Location",
  Earning_Oppurtunity: "Earning Opportunity",
  Journey_Details: "Journey Details",
  Departure: "Departure",
  Arrival: "Arrival",
  Send_Luggage_Request_to_Traveller: "Send a luggage request to the traveler",
  Review_Your_request: "Review Your request",
  Unit_Price: "Unit Price",
  Send_Luggage_Sender: "Send Travel Plan To Luggage Sender",
  Congratulations: "Congratulations",
  Your_advertisement_successfully:
    "Your advertisement has been posted successfully",
  Your_may_view_advertisement:
    "You may view the live advertisement in Travel Announcement",
  Your_may_Luggage_advertisement:
    "You may view the live advertisement in Luggage Announcement",
  Travel_Announcement: "Travel Announcement",
  section: "Section",
  sectionL: "Section",
  I_Validate_Post_Advert: "I Validate & Post Advert",
  one_way: "One way",
  Submit_and_fill: "Submit and fill in return journey details",
  sending_requests:
    "Below are some of matching luggage sending requests we found",
  sending_requests_Travel:
    "Below are some of matching travel sending requests we found",
  sender_directly:
    "You may contact the luggage sender directly for getting in touch with you to send their luggages",
  sender_directly_Travel:
    "You may contact the travel sender directly for getting in touch with you to send their luggages",
  Onward_Journey_Details: "Onward Journey Details",
  Return_Journey_Details: "Return Journey Details",
  Return_departure_time: "Return departure time",
  return_arrival_time: "Return arrival time",
  return_departure_date: "Return departure date",
  return_arrival_date: "Return arrival date",
  Type_luggage: "Type or nature of luggage",
  Luggage_Dimensions: "Luggage Dimensions",
  Width: "Width",
  Weight: "Total Weight",
  Colour: "Colour",
  Volume: "Volume",
  contents_under_warranty: "Is the product or contents still under warranty",
  estimate_of_luggage: "Current price estimate",
  receiver_details: "Luggage receiver’s details",
  Name_of_receiver: "Luggage receiver",
  Phone_code: "Phone code",
  Phone_Contact_number: "Phone number",
  Delivery_communicated: "Delivery code should be communicated to hi",
  getting_your_luggage: "Will you be getting your own luggage",
  Your_relationship_receiver: "Your relationship or link with luggage receiver",
  Location_of_luggage: "Pickup Location of Luggage",
  Location_of_article: "Location of the article",
  Luggage_Sender_Type: "Luggage Sender Type",
  detail_content_luggage_carried:
    "Please detail the content of this luggage to be carried",
  I_CONFIRM_THAT:
    "I CONFIRM THAT THE CONTENT IS NOT ILLEGAL AND DANGEROUS (DRUGS - DANGEROUS PRODUCTS - TOXIC)",
  Boat: "Boat",
  Kick_scooter: "Kick scooter",
  Scooter: "Scooter",
  Tgv: "Tgv",
  Tramway: "Tramway",
  Luggage_Location: "Luggage Location",
  Luggage_Sender_type: "Luggage Sender type",
  Traveller_Type: "Traveller Type",
  Traveller: "Traveller",
  from: "From",
  Luggage_Wort: "Luggage Worth",
  Luggage_Weigh: "Luggage Weight",
  Shipping_Detail: "Shipping Detail",
  receiver_name: "Receiver name",
  receiver_contact_number: "Receiver contact number",
  RegularI_send_luggages_reguler: "Regular-I send luggages reguler",
  Periodic_send_luggages_periodically: "Periodic-I send luggages periodically",
  One_off_luggage_sender: "One off-I am just one-off luggage sender",
  Type_nature_luggage: "Type or nature of luggage",
  New_Never_Used: "New-Never Used",
  Used_Secound_hand: "Used-Second hand",
  Trip_Typ: "Trip Type",
  vehical_Detiles: "Vehical Details",
  Return_Journey_Details: "Return Journey Details",
  Please_upload:
    "Please upload a minimum of 3 and a maximum of 4 images. Please try again.",
  bike: "Bike",
  Geolocation: "Geolocation",
  Product_Price_Unit: "Product Price / Unit",
  Product_Weight: "Product Weight/Unit",
  Item_Location: "Item Location",
  Available_Quantity: "Available Quantity",
  Quanatity_require: "Quantity you require",
  Item_Location_Addres: "Item Location or Address",
  Article_Announcement: "Article For Sale Announcement",
  MoterBike: "MoterBike",
  Way_to: "Final destination",
  Article_Donation_Announcement: "Article For Donation Announcement",
  delivery_date: "Delivery date",
  Delivery_Place: "Delivery Place",
  Delivery_by: "Delivery By",

  luggage_ad: "Luggage Sending Request Announcement",
  PLEASE_VERIFIED_PROFILES:
    "PLEASE SEND INQUIRIES TO TRAVELERS WITH VERIFIED PROFILES",
  title_of_luggage: "Title of Luggage",
  type_of_packer: "Type of Packer",
  send_date: "Send Date",
  luggage_estimation: "Luggage Estimation",
  enter_luggage_estimation: "Enter Luggage Estimation",
  total_kilos_of_luggage: "Total kilos of Luggage",
  enter_total_kilos_of_luggage: "Enter Total kilos of Luggage",
  luggage_receiver_name: "Luggage Receiver Name",
  enterluggage_receiver_name: "EnterLuggage Receiver Name",
  luggage_receiver_telephone: "Luggage Receiver Telephone",
  enter_luggage_receiver_telephone: "Enter Luggage Receiver Telephone",
  description_and_other_information: "Description And Other Information",
  enter_description_and_other_information:
    "Enter Description And Other Information",
  Upload_image_in_1280_720_dimension: "Upload image in 1280*720 Dimension",
  You_can_add_4_images_maximum: "You can add 4 images maximum",
  enter_description_and_other_information:
    "Enter Description And Other Information",
  Description: "Description",

  add_an_article: "Add an Article",
  location: "Location",
  price_per_item: "Price (Per Item)",
  ads_category: "Ads Category",
  select_ads_category: "Select ads category",
  quantity_number_of_item: "Quantity (Number of item)",
  weight_kg: "Weight (Kg)",
  type: "Type",
  tags: "Tags",
  status: "Status",
  very_good_status: "Very Good Status",
  good_condition: "Good Condition",
  average_state: "Average state",
  first_owner: "First Owner",
  yes: "Yes",
  no: "No",
  material: "Material",
  color: "Color",
  width: "Width",
  submit_article_and_add_more: "Submit Article And Add More",
  confirm_the_sale: "Confirm The Sale",
  exit: "Exit",

  add_article_donation: "Add Article Donation",
  new: "New",
  used: "Used",
  submit_article_donation_and_add_more: "Submit Article Donation And Add More",
  confirm_the_gift: "Confirm The Gift",

  Do_luggage_sending_requirement:
    "Do you see a match to your luggage sending requirement?",
  advert_sending_luggage: "Book this advert for sending luggages",
  travel_info_luggage_sender: "Send your travel info to the luggage sender",
  digital_signature: "Digital signature",
  Save_review_request: "Save and proceed to review your request",
  edit_luggage_details: "Back to edit luggage details",
  validate_send_request: "I validate and send request",
  request_buy: "Request to buy",
  Request_get_item: "Request to get this item",
  Send_Request: "Send Request",
  Post_Advertisement: "Post an Advertisement",
  DELIVERY_INFOS: "Delivery Information",
  Updated_At: "Updated At",
  Send_travel_luggage_sender: "Send your travel info to the luggage sender",
  Send_request_buy_item: "Send request for buying",
  Luggage_data:
    " You are about to send a request to traveller for sending luggage to specified destination. Please note that If the traveller accepts your request, we will charge your credit card for the required trip amount.",
  Travel_Announcement_Details: "Travel Announcement Details",
  Luggage_Request_Details: "Luggage Request Details",
  Articles_Sale: "Articles on Sale",
  Cobagage_Articles_Donations: "Cobagage Articles For Donations",
  Weight_carriage_capacity: "Weight carriage capacity",
  Price_arry_weight: "Price to carry weight",
  planned_middle_trip: "Is a stopover planned in middle of your trip?",
  Luggage_collection_place: "Luggage collection place",
  Luggage_drop_place: "Luggage drop off place",
  Does_benefit_travel_insurance:
    "Does your trip benefit from travel insurance?",
  Your_luggage_instructions: "Your luggage instructions",
  Lorem_ipsum:
    " Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.",
  Departure: "Departure",
  Arrival: "Arrival",
  Posted_By: "Posted By",
  packer_type: "Packer type",
  Posted_Date: "Posted Date",
  Member_since: "Member since",
  delivery_place: "Delivery Place",
  Views: "Views",
  View_Trip_Details: "View Trip Details",
  view_details: "View Details",
  Note: " Note** - any liquid product, perfume, shower gel, shampoo, lotion, toothpaste, beyond a bottle of 100ml each",
  aerosol:
    "Any aerosol and cosmetic whose capacity would exceed 100 ml per bottle",
  firearm: "Any firearm (obviously...)",
  ammunition:
    "Any ammunition or cartridge intended for hunting or sporting weapons (obviously...)",
  weapon:
    "Any edged weapon, and more generally any blunt or cutting object, whether metallic or not (scissors, blade, box cutter, Swiss army knife, etc.)",
  general:
    "In general, sharp objects, liquids, weapons and gel can only travel in the holds.",
  Description: "Description",
  Post_luggage_sending_request: "Post a luggage sending request",
  Post_article_sale: "Post an article on sale",
  Post_article_donation: "Post an article for donation",
  title: " Title",
  Type_Article: "Type or Article",
  traveller_type: "Traveller type",
  Regular: "Regular",
  Periodic: "Periodic",
  Oneoff: "One-off",
  Luggages_sents: "Luggage(s) sent",
  site_ookies: "This site uses cookies",
  We_third_similar: "We and selected third parties use cookies (or similar",
  technologies_marketing_efforts:
    "technologies)for technical purposes, to enhance and analyze site usage to support our marketing efforts",
  Accept: "Accept",
  Decline: "Decline",
  carry_luggage: "Price of delivery/Sale of the item ",
  Remove_cart: "Remove this item from cart",
  Proceed_payments: "Proceed to make payments",
  complete_your_Transaction: "For complete your Transaction",
  Click_button: "Click on continue button",
  Continue_Order: "Continue Your Order",
  minimum_3maximum_4images:
    "Please upload a minimum of 3 and a maximum of 4 images. Please try again.",
  updated_Successfully: "Updated Successfully...",
  Your_deleted_Successfully: "deleted Successfully...",
  With_maximum_allowed: "File with maximum size of 4MB is allowed",
  //New changes
  Article_Quantity: "Article Quantity",
  Requester_Location: "Requester Location",
  Your_luggage_request_successfully_traveller:
    "Your luggage request has been successfully sent to the traveller. He will review the request detail and will either accept/reject your request. If your request gets accepted, we will charge your card for the trip cost.",
  You_Luggages_section:
    "You may also see the current status of request under “My Requests/Luggages”.section.",
  Your_travel_luggage_sender:
    "Your travel plan has been successfully sent to the luggage sender. He will review the travel plan and will either accept/reject your request. If your request gets accepted, we will charge his card for the trip cost and you will be notified further action.",
  You_My_Travels_section:
    "You may also see the current status of request under “My resquest” section.",
  Send_Request_Article_Donation: "Send Request to Article Donation",
  Items_request_basket: "Items in your request basket",
  File_Size: "File with maximum size of 4MB is allowed",
  Total_Quantity: "Total Quantity",
  remaining_quantity: "Remaining quantity",
  More_Donation: "More Items On Sale & Donation (posted by)",
  File_allowed: "File with maximum size of 4MB is allowed",
  Select_country: "-Select Country-",
  select_price_range: "Select Price Range",
  below: "Below ",
  Select_trip_type: "-Select Trip Type-",
  select_traveller_type: "-Select Traveller Type-",
  traveller_status: "-Traveller Status-",
  sort_by: "-Sort By-",
  less_than: "Less than",
  between: "Between",
  More_than: "More than",
  Individual: "Individual",
  Professional: "Professional",
  verified: "Verified",
  Unverified: "Unverified",
  by_ascending_price: "By ascending price",
  by_decreasing_price: "By decreasing price",
  per_kg_increasing: "Per kg increasing",
  per_kg_decreasing: "Per kg descending",
  recent_announcement: "Recent announcements",
  shipment: "Environmentally friendly",
  Faire_voyageurs:
    "Co-bagging takes many forms, both through the means of transport and the various destinations travelers travel to.",
  La_société:
    "The Cobagage company is in fact very active in the field of ecology and tends to",
  réduire_C02: "reduce C02 emissions",
  par_cobagage: "par l'intermédiaire d'un service simple, celui du cobagage",
  Pourquoi_C02: "Why becoming a co-baggage person helps reduce C02 emissions?",
  Lorsque_avion:
    "When we think of traveling or shipping packages, the first thought that comes to mind is air travel",
  Il_trajets:
    "It is true that this means of transport is the fastest and most practical for long journeys.",
  Pour_transporter:
    "To transport your luggage from one point to another, whether nationally or internationally, the plane is not the only possible means of transport, far from it!",
  Lavantage_transport:
    "The advantage of co-luggage is being able to take luggage from point A to point B via all kinds of transport",
  La_envisagées:
    "The car or the train also remain two ideal means of traveling and co-luggage, but other solutions can also be considered.",
  Ainsi_trottinette:
    "So, for shorter journeys it is possible to use the RER, the metro, the motorbike, the scooter, the bus or even the scooter!",
  Tous_de:
    "All means of transport are welcome to practice co-bagging and allow",
  earn_recipient: "earn money by bringing luggage to its recipient",
  Faire_cobagage: "Co-luggage internationally",
  Gagner_site_Cobagage:
    "Earning money by traveling internationally or simply shipping luggage long distance represents the very essence of the services offered by the Cobagage site.",
  Il_coûteux:
    "It must be said that using a freight company to ship luggage to a distant destination can quickly become particularly expensive!",
  Calling_cost:
    "Calling on a traveler to transport their luggage proves to be a winning operation on all levels: the luggage arrives quickly and at a controlled cost.",
  De_par:
    "De son côté, le voyageur pourra rentabiliser une partie de son voyage longue distance par",
  le_transport: "international baggage transport",
  Par_bagages:
    "For example: A traveler leaves for Moscow and has 25 kilos available in his luggage.",
  Le_prix_HT:
    "The price per kilo was set by the traveler at €15 excluding tax.",
  Le_HTcommission:
    "The traveler will therefore be able to have the sum of €375 excluding tax (€348.75 excluding tax after commission)",
  Une_côté_tarifs:
    "A nice sum which will be welcome to fully enjoy your trip to Moscow while the sender will have been able to benefit from a reliable and advantageous service in terms of prices",
  Les_conditions: "Conditions to respect for international shipping",
  Les_envois:
    "International shipments require the same precautions as for a domestic shipment.",
  Néanmoins_confiscation:
    "However, some baggage may be subject to customs control, it is important to always ensure that you comply with the legislation of the country to which the baggage will be sent in order to avoid any confiscation.",
  En_destinataire:
    "As a traveler, the latter has the obligation to go to the appointment to collect the luggage from the sender and return it in perfect condition to the receiver/recipient.",
  Les_destinataire:
    "The two protagonists as well as the receiver will then be the guarantors of the baggage throughout the procedure and must sign the order ensuring the good condition of the latter and its delivery to the recipient.",
  L_international: "Sending luggage internationally",
  n_Cobagage: "has never been so inexpensive and practical as with Cobagage!",
  Round_Way: "Round Way",
  select_Weight: "Select Weight",
  Select_Country: "Select Country",
  Select_Type: "Select Trip Type",
  Traveller_Status: "Traveller Status",
  Sort_By: "Sort By",
  Paiement_sécurisé: "Secure payment",
  En_transactions:
    "By choosing Cobagage, you have the assurance of a secure payment platform for your transactions",
  Parce_circonstances:
    "Because each transaction between co-baggage passes through our site, you have the assurance of secure payment in all circumstances.",
  Nous_assurer:
    "We favor payments by Paypal and credit card on Cobagage to assure you",
  un_fiable: "a reliable payment service",
  et_contrainte: "and without constraint.",
  Comment_sécurisé: "How do I know if my payment is secure?",
  Il_risque:
    "There are so many sites on the internet that it is not always easy to find your way around and know if it is possible to make payments without any risk.",
  Chez_honneur: "At Cobagage, we make a point of honor",
  sécuriser_clients: "secure the transactions of each of our clients",
  Éco_responsable: "Environmentally friendly",
  En_cobagageur:
    "By becoming a co-baggage, you directly participate in reducing the carbon footprint.",
  La_tend:
    "The Cobagage company is in fact very active in the field of ecology and tends to",
  réduire_C02: "reduce C02 emissions",
  par_cobagage: "through a simple service, that of co-baggage",
  Pourquoi_émissions:
    "Why becoming a co-baggage person helps reduce C02 emissions?",
  L_destinataire:
    "The initiative of the cobagage site is to allow two parties to conclude a contract in which one must hand over a baggage, and the second transport it during its trip to a recipient.",
  L_émissions:
    "The advantage of this service is to benefit from a reduction in C02 emissions.",
  un_environnement: "a favorable impact on the environment",
  La_destination:
    "The reason is very simple. If we take the distinct example of a person traveling to New York, whether for pleasure or business, and a person wishing to ship luggage to a recipient at this same destination.",
  En_avantages:
    "By co-bagging, you can have a positive impact on the environment, while enjoying many benefits.",
  Que_agnante:
    "Whether you want to earn money while traveling or ship your luggage at reduced costs, co-baggage remains the winning solution.",
  Privilégier_propres: "Favor clean means of transport",
  Pour_recommander:
    "To benefit from a favorable impact on the environment, so-called clean means of transport are recommended.",
  Par_permet:
    "By clean means of transport, we mean electric means of transport such as the car or the scooter when the distance of the journey allows it.",
  Cependant_locomotion:
    "However, it is entirely possible to consider reducing its impact on the environment by emitting less C02 by adapting its means of transport.",
  Prenons_bagage:
    "Let's take the example of a Paris-Marseille journey for which a traveler will have to transport and return luggage.",
  Au_responsable:
    "Instead of taking a plane, which involves releasing tons of C02 into the air, a trip by train or car will ensure you enjoy an eco-responsible journey.",
  Sur_temps:
    "For long-distance transport, the plane remains of course essential most of the time.",
  Néanmoins_bagage:
    "However, by taking charge of a shipper's baggage, you help reduce the C02 emissions that could have been generated by sending this baggage.",
  La_écoresponsable:
    "The Cobagage company was designed in an eco-responsible way",
  afin_C02:
    "in order to guarantee each traveler the possibility of participating in the reduction of the C02 emission rate.",
  Une_sain: "A real breakthrough for a healthier world!",
  Devenir_service:
    "Become eco-responsible by focusing on a trip combining pleasure and service",
  Plus_quotidienne:
    "More than ever, the impact we have on the environment, each at our own level, must be taken into account in the context of our daily lives.",
  En_réels:
    "By becoming a co-baggage carrier, you can participate in this desire to reduce C02 emissions while benefiting from real services and advantages.",
  Vous_simplicité:
    "Are you a traveler? Don't just travel without using your empty luggage kilos and earn money with ease.",
  Vous_envoi:
    "Are you a sender? Choose a simple and efficient transport solution that allows you to take advantage of a traveler's journey to reach your recipient and save on shipping costs.",
  Devenez_cobagageurs: "Become co-baggage and participate in",
  la_environnement: "la préservation de l'environnement",
  Request_successfully: "Request Sent successfully",
  Remaining_Qty: "Remaining Qty",
  Contact_Info: "Contact Info.",
  Lets_Talk: "Let’s Talk",
  For_networks: "For social networks",
  For_payments: "For payments",
  Address: "Address",
  Contact_us: "Contact us",
  Head_office: "Head office",
  Visit_Us: "Visit us on social media",
  Name: "Name",
  Subject: "Subject",
  message: "Message",
  Send_Message: " Send Message",
  Cobagage_as_Job: "Cobagage as a Job",
  Cobagage_as_Job_text1: "A new way to earn a living through travel",
  Cobagage_as_Job_text2:
    "Using Cobagage is the assurance of being able to benefit from attractive advantages, whether you are a shipper or a traveler.",
  Cobagage_as_Job_text3:
    "Co-bagging applies both to individuals who wish to recoup part of their costs, and to professionals.",
  Cobagage_as_Job_text4: "Practicing co-luggage for professional purposes",
  Cobagage_as_Job_text5:
    "is ideal for considering a new way of earning a living.",
  Cobagage_as_Job_text6: "Make your travels profitable as an individual",
  Cobagage_as_Job_text7:
    "The Cobagage site was designed to make life easier both for those who wish to ship their luggage easily and at a lower cost, and for those who wish to make part of their trips profitable by transporting luggage.",
  Cobagage_as_Job_text8:
    "Earning money thanks to the Cobagage site is an excellent solution for individuals who want to earn money simply and thus reduce expenses related to their trip.",
  Cobagage_as_Job_text9:
    "By monetizing their empty kilos and selling their items if they wish, the traveler is assured of benefiting from a final sum allowing them to save on their stay costs.",
  Cobagage_as_Job_text10:
    "Co-bagging as an individual is ideal for earning money, but it can also become",
  Cobagage_as_Job_text11: "a full-time activity",
  Cobagage_as_Job_text12:
    "Professionalizing yourself in the world of co-luggage is indeed possible in order to make it a full-fledged profession allowing you to earn a living.",
  Cobagage_as_Job_text13:
    "Individuals and professionals alike can use the Cobagage site to find shippers wishing to send their luggage to a specific destination.",
  Cobagage_as_Job_text14:
    "The Cobagage platform not only ensures connection but also secures your transactions.",
  Cobagage_as_Job_text15:
    "To do this, the traveler, the sender and the receiver of the baggage must sign an order order.",
  Cobagage_as_Job_text16:
    "By using Cobagage to put you in touch with a shipper or traveler, you have the assurance of finding the ideal partner and benefiting from secure payment.",
  Cobagage_as_Job_text17: "Using Cobagage as a professional: Cobagage as a job",
  Cobagage_as_Job_text18:
    "Most people who choose to become co-baggage do so in order to take advantage of their trip to make money from their kilos of empty luggage.",
  Cobagage_as_Job_text19:
    "This solution can also be an opportunity to think about co-luggage as a way of earning a living through traveling throughout the year.",
  Cobagage_as_Job_text20: "Is it really possible to consider doing",
  Cobagage_as_Job_text21: "co-baggage as a profession",
  Cobagage_as_Job_text22: "in its own right?",
  Cobagage_as_Job_text23:
    "The answer is yes and can prove to be particularly interesting from a financial point of view.",
  Cobagage_as_Job_text24:
    "Let's take an example: You take a trip to Rome with the aim of transporting luggage that you monetize by the kilo.",
  Cobagage_as_Job_text25:
    "You have 30 kilos available which you charge at the price of 10 euros per kilo.",
  Cobagage_as_Job_text26:
    "For this trip, you will be able to earn 300 euros (25% commission excluding tax being deducted from this amount by the Cobagage site), i.e. a net total of 225 euros excluding tax.",
  Cobagage_as_Job_text27:
    "Knowing that it is also possible to sell or donate items, this amount can quickly add up.",
  Cobagage_as_Job_text28:
    "As you will have understood, the more empty kilos you have to offer, the more you will be able to benefit from significant remuneration.",
  Cobagage_as_Job_text29:
    "Using the Cobagage site as a job is therefore accessible to all those who like to travel and have the opportunity to transport luggage from one point to another.",
  Cobagage_as_Job_text30:
    "Rigor and punctuality are two essential qualities for considering co-luggage as a profession.",
  Cobagage_as_Job_text31:
    "If you think you have the required qualities, the experience of co-luggage is available to you to earn a living through your travels.",
  Travel_Details: "Travel Announcement Details",
  Cobagage_Announcements: "Cobagage Luggage Announcements",
  Cobagage_Sale: "Cobagage Articles On Sale",
  results_found: "results found",
  Cobagage_Donations: "Cobagage Articles For Donations",
  send_buying_request: "Send Buying Request",
  confirm_request: "Confirm Request",
  time: "Time",
  i_want_this: "I want this",
  not_verified: "Not verified",
  verified: "Verified",
  send_request_to_article1: "Send Request to Article",
  your_earning: "Your earnings",
  price_to_pay: "Price to pay",
  traveller_fees: "Traveller fees",
  service_fees: "Service fees",
  total_fees: "Total fees",
  Plus_Cobagage: "More about Cobagage",
  Vous_sujet:
    "Are you arriving on the Cobagage platform and would like to know more about us?",
  Cette_fonctionnement:
    "This page is intended to teach you more about our company as well as our operation",
  Devenez_rêves:
    "Become a co-baggage person and immerse yourself in the world of co-baggage to make your daily life easier and bring your dreams to life",
  Trouver_idéal: "Find the ideal co-baggage",
  vous_éclaircies:
    "If you have never practiced co-bagging before, our platform certainly requires certain small gray areas to be clarified.",
  Le_relation: "The Cobagage site aims to help you get in touch with:",
  Avec_moindre:
    "- With a traveler if you are a sender and want to ship objects/parcels/luggage at a lower cost.",
  Avec_vides:
    "- With a shipper if you are a traveler and looking to make money from your kilos of empty luggage.",
  Par_besoins:
    "Through Cobagage, you can be put in touch with the ideal person according to your needs.",
  Chaque_simplement:
    "Each user simply needs to register and then fill out an ad.",
  Celle_encore:
    "This will include the description of the journey taken, the date as well as the empty kilos accompanied by the price per kilo, or even",
  Cintermédiaire:
    "It is through this announcement that co-baggers are put in contact with each other by Cobagage. A detailed ad is therefore the assurance of finding the ideal person.",
  Le_voyager: "Co-baggage, a new way of traveling",
  Pratiquer_jamais: "Practicing co-baggage is more than ever",
  une_façon: "A new way of thinking about traveling",
  Vous_serré:
    "Do you want to enjoy a well-deserved vacation but your budget is tight?",
  Vous_auriez:
    "Vous auriez aimé pouvoir disposer de plus de temps ou d'argent afin de profiter de vos vacances ?",
  La_dans: "The perfect solution to solve these problems lies in",
  le_cobagage: "Co-baggage",
  simple_effort:
    "simple, fast, accessible and secure, it will provide you with an additional budget effortlessly.",
  Pour_rendezvous:
    "To do this, you just need to follow a few very simple instructions: a sender gives you their luggage (corresponding to the empty kilos that you have put up for sale), and you will simply have to give this luggage to the recipient or receiver upon your arrival after having agreed of an appointment.",
  Grâcerentabiliser_voyage:
    "Thanks to this service, you will be able to have an additional amount of money to make your trip profitable.",
  Vous_venir:
    "You may then have the opportunity to extend your stay, make more expenses on site or simply put aside for your future projects.",
  Choisir_transactions: "Choose Cobagage to ensure your transactions",
  En_passant_transactions:
    "By using the Cobagage site, you are guaranteed to benefit from numerous services and secure your transactions.",
  Vous_plateforme:
    "Vous avez peur de ne pas être payé par un cobagageur ? Grâce à Cobagage, chaque transaction s'effectue directement par le biais de la plateforme.",
  Que_rencontrée:
    "Whether you are an individual or a professional, finding a co-baggage can quickly become an obstacle course, with no guarantee of the seriousness of the person you meet.",
  La_signature:
    "The signing of the order order constitutes the very heart of our site, ensuring the conformity of the baggage by the three parties concerned (traveler, sender and receiver)",
  Chaque_sujet:
    "Each payment is due provided that the baggage has been handed over and then transmitted to the receiver without incident",
  voir_page: "see the page",
  pour_sujet: "to learn more about this",
  En_sérénité:
    "By trusting our site, you will be able to count on the seriousness and availability of our team and thus carry out each of your co-baggage missions with complete peace of mind.",
  la_transporter: "the nature of the baggage to be carried",

  Article_Images:
    "Article Images (Upload image in 1280 px * 720 px dimensions)",
  click_drag_image_here: "+ click or drag image here",
  you_are_about_send_travel_plan_to_luggage_sender_shipper:
    " You are about to send your travel plan to luggage sender",
  you_are_about_send_travel_plan_to_luggage_sender_shipper1:
    "who wishes to send their shipment to the area where you intend to travel. Please note that If the luggage sender accepts your request, we will charge their credit card for the required trip amount. You will receive the amount displayed under “Your Earnings” after 3 days of delivery completion.",
  basket_delete: "Basket Delete the succesfully",
  order_summary: "Order Summary",
  site_service_fees: " Site service fees on",
  Luggage:"Luggage",
  Article:"Article",
  Article_Donation:"Don d'articles",
  total_payable_amount: "Total payable amount",
  buyer_has_been_notified:
    "Buyer has been notified about your acceptance. Please wait for the payment to come in and then ship the item to buyer’s location",
  next_step: "Next Steps",
  buyer_will_pay:
    "Buyer will pay the requested amount for the item. Till then you may see the order status in  “My Pending Payments - Articles” section",
  once_the_buyer_pays:
    "Once the buyer pays the requested amount, you may send the item through a traveller to the buyer’s destination",
  happy_travelling_by_earning: "Happy Travelling while Earning",
  user_has_been_notified:
    "User has been notified about your acceptance for donating the item.",
  please_make_agreement:
    "Please make arrangements to send the donated item to a mutually agreed-upon location.",
  the: "The",
  has_been_notified: "Your refusal has been successfully sent to the user",
  giver_status: "Giver Status",
  seller_status: "Seller Status",
  newly_posted: "Newly posted",
  relavance: "Relevance",
  updated_successfully: "Update Successfully...",
  signature_valid: "Signature valid by",
  add_submited: "Ad Submitted...",
  data_created_succesfully: "Data created succesfully",
  Price_of_Item: "Luggage carrying cost",
  Donation_page: "Donation Detail page",
  subscribe_message:
    "You currently do not have any subscription plans. Please get an upgraded subscription and enjoy zero service charges!",
  Out_stock: "Out of stock",
  Stripe: "Credit card",
  Mobile_Moeny:"Mobile money",
  Procesed_payment: "Proceed to payment",
  article_dimension: "Article Dimensions",
  currency: "Currency",
  Select_currency: "Select currency ",
  Account_deleted_successfully: "Account deleted successfully",
  Direction: "Direction",
  Success: "Success!",
  Your_advertisement_has_been_posted_successfully:
    "Your advertisement has been posted successfully",
  Your_view_advertisement_in: "You may view the live advertisement in",
  Article_on_sale: "Article on sale",
  Articles_For_Donation: "Articles For Donation",
  announcements_section: "Announcements section.",
  Please_Continue_Your_Order:
    "Please Don't Press Back Button Or Don't Close this Window Please Continue Your Order",
  Onward: "Onward",
  Return: "Return",
  address1:
    "Ivory Coast Abidjan - Cocody - Les II Plateaux, 7th Tranche, After the station towards Zinsou, opposite the Saint Georges Clinic: 28 BP 1676 ABIDJAN 28,Manager:Brice Delagneau +225 0101262488",
  address2:
    "USA COBAGAGE NETWORK, Inc. Delaware C Corporation 1111B S Governors Ave STE 7830 USA",
  address3:
    "COBAGAGE NETWORK - France 78 avenue des Champs Elysées 75008 - Paris",
  Please_select_advertisement:
    "Please select one of your travel plan advertisement",
  Click_Payment: "Click here to Complete the Payment",
  Article_Delivery_Details: "Article Delivery Details",
  Other_Number: "Other Number",
  Company_Number: "Company Number",
  Passwords_are_not_same: "Passwords are not same",
  Price_Unit: "Price / Unit",
  Add: "Add",
  Add_your_City: "Add your City",
  En_montant_final:
    "By carrying out each of your transactions on Cobagage, you can benefit from a reliable service, the site's commission amounting to only 25% excluding tax of the final amount.",
  toreceive_code: "To receive the delivery code",
  withdraw_message: "Withdrawal request sent successfully",
  delivery_country: "Delivery Country",
  all: "All",
  Quatity:"Quantity",
  INPI_2022014480:"INPI – e-Soleau DSO registration 2022014480",
  Request_Reject:"Note:You cannot send the request because the user has already declined your request"
};
export default form;
