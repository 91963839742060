import React, { useState } from 'react'
import { Link } from 'react-router-dom'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectFade]);

function HeroFourArea() {
  const { t } = useTranslation();
  const [show, setShow] = useState(true);

  const heroslide4 = {
    slidesPerView: 1,
    speed: 1500,
    loop: true,
    spaceBetween: 0,
    centeredSlides: true,
    roundLengths: true,
    effect: 'fade',
    navigation: {
      nextEl: '.hero-next3',
      prevEl: '.hero-prev3',
    },

    autoplay: {
      delay: 5000
    },
    pagination: {
      el: ".hero-two-pagination",
      clickable: true,
      renderBullet: function (index, className) {
        return '<span class="' + className + '">' + 0 + (index + 1) + "</span>";
      }
    }
  }

  const handleClose = () => {
    setShow(false);
  };
  return (
    <>
      <div className="hero-area hero-style-four">
        <img src={process.env.PUBLIC_URL + "/images/banner/banner-plane.svg"} className="img-fluid banner-plane" alt="hero img" />
        {/* <img src={process.env.PUBLIC_URL + "/images/banner/banner-star1.svg"} className="banner-star1" alt="hero img" /> */}
        {/* <img src={process.env.PUBLIC_URL + "/images/banner/banner-star2.svg"} className="banner-star2" alt="hero img" /> */}
        {/* <img src={process.env.PUBLIC_URL + "/images/banner/banner-star3.svg"} className="banner-star3" alt="hero img" /> */}
        <div className="hero-main-wrapper position-relative">
          <Swiper className="swiper hero-slider-four" {...heroslide4}>
            <div className="swiper-wrapper">
              <SwiperSlide className="swiper-slide" >
                <div className="slider-bg-1">
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="slider-bg-2">
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="slider-bg-3">
                </div>
              </SwiperSlide>
              <SwiperSlide className="swiper-slide">
                <div className="slider-bg-4">
                </div>
              </SwiperSlide>
            </div>
          </Swiper>
        </div>
      </div>
      <Swiper className="swiper hero-slider-four" {...heroslide4}>
        <div className="swiper-wrapper">
          <SwiperSlide className="swiper-slide" >
            <div className="">
              <div className="multi-main-searchber" style={{ background: "#ff4838", borderRadius: "0px", height: "98px", zIndex: "1",display: "flex", justifyContent: "center",alignItems:"center",}}>
                <h4 style={{  color: "white", fontWeight:"bolder" }}>{t("homePage.here_only_travelers_can_decide_what_to_transport")}</h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide" >
            <div className="">
              <div className="multi-main-searchber" style={{ background: "#ff4838", borderRadius: "0px", height: "98px", zIndex: "1",display: "flex", justifyContent: "center",alignItems:"center" }}>
                <h4 style={{  color: "white",fontWeight:"bolder" }}>{t("homePage.cobagage_the_first_website_where_travelers_earn_money")}</h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide" >
            <div className="">
              <div className="multi-main-searchber" style={{ background: "#ff4838", borderRadius: "0px", height: "98px", zIndex: "1",display: "flex", justifyContent: "center",alignItems:"center" }}>
                <h4 style={{  color: "white",fontWeight:"bolder" }}>{t("homePage.here_you_can_travel_sell_deliver")}</h4>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide className="swiper-slide" >
            <div className="">
              <div className="multi-main-searchber" style={{ background: "#ff4838", borderRadius: "0px", height: "98px", zIndex: "1",display: "flex", justifyContent: "center",alignItems:"center" }}>
                <h4 style={{ color: "white",fontWeight:"bolder" }}>{t("homePage.type_of_transports_used_plane_bus_boat_car_bike_rain")}</h4>
              </div>
            </div>
          </SwiperSlide>
        </div>
      </Swiper>
    </>
  )
}

export default HeroFourArea

