import React from 'react'
import Breadcrumb from "../../common/Breadcrumb";
import ShoppingStripDetails from './shoppingStripDetails';
import { useTranslation } from 'react-i18next';

export default function ShoppingStrip() {
    const { t } = useTranslation()
    return (
        <div>
            <Breadcrumb name={t('profile.Payments')} />
            <ShoppingStripDetails />
        </div>
    )
}
