import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import paymentSent from "../../../models/paymentSent";
import notistack from "../../../plugin/notistack";
import payPal from "../../../models/payPal";
import AppContext from "../../../store/context/app-context";


export default function ShoppingPaypalDetails() {
    const contextObj = useContext(AppContext);
    const id = window.location.href.split('PayerID=')[1]
    const { t } = useTranslation();
    const history = useHistory();
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const [data, setData] = useState(
        {
            "paymentId ": window.localStorage.getItem('paymentId'),
            "subscription_id": window.localStorage.getItem('subscription_id'),
            // "payerId  ": id,
        }
    );

    const paymentHistroy = () => {
        payPal.createPaypalHistory(data).then((resp) => {
            notistack.success(t("cobagage.payment"))
            window.localStorage.removeItem('paymentId')
            window.localStorage.removeItem('subscription_id')
            contextObj.setTabValue(7);
            history.push('/myProfile');
        }).catch((error) => {
            console.log('shopping paypal payment', error);
        })
    }

    return (
        <div className='' style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className='d-flex justify-content-center'>
                <i class='bi bi-hourglass-split' style={{ fontSize: "85px", color: "orange" }} />
            </div>
            <div className='d-flex justify-content-center pt-3'>
                <h4>{t('form.complete_your_Transaction')}</h4>
            </div>
            <div className='d-flex justify-content-center pt-2'>
                <h4>{t('form.Click_button')}</h4>
            </div>
            <div className='d-flex justify-content-center'>
                <Button style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: '5px', marginTop: "34px" }}
                    onClick={() => paymentHistroy()}
                >
                    {t('form.Continue_Order')}{' '}<i class="bi bi-arrow-right-circle" />
                </Button>
            </div>
        </div>
    )
}
