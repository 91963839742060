import React, { useContext, useState } from 'react'
import stripeSubscribe from '../../../models/stripeSubscribe';
import { Modal, Button, Form } from 'react-bootstrap';
import { BsFillCheckCircleFill } from 'react-icons/bs';
import AppContext from '../../../store/context/app-context';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import { useTranslation } from 'react-i18next';


export default function StripPaymentDetails() {
    const { id } = useParams();
    const { t } = useTranslation();
    const contextObj = useContext(AppContext);
    const history = useHistory();
    const [data, setData] = useState(
        { "subscription_id ": id }
    );
    const [sessionId, setSessionId] = useState(window.localStorage.getItem('sessionId'))

    const tabPanelValue = () => {
        stripeSubscribe.paymetData(sessionId, data).then((resp) => {
            window.localStorage.removeItem('sessionId')
            contextObj.setTabValue(14);
            history.push('/myProfile');
        }).catch(error => {
            console.log("error", error);
        })
    }

    return (
        <div className='' style={{ paddingTop: "50px", paddingBottom: "50px" }}>
            <div className='d-flex justify-content-center'>
                <i class='bi bi-hourglass-split' style={{ fontSize: "85px", color: "orange" }} />
            </div>
            <div className='d-flex justify-content-center pt-3'>
                <h4>{t('form.complete_your_Transaction')}</h4>
            </div>
            <div className='d-flex justify-content-center pt-2'>
                <h4>{t('form.Click_button')}</h4>
            </div>
            <div className='d-flex justify-content-center'>
                <Button style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: '5px', marginTop: "34px" }}
                    onClick={() => tabPanelValue()}
                >
                    {t('form.Continue_Order')}{' '}<i class="bi bi-arrow-right-circle" />
                </Button>
            </div>
        </div>
    )
}
