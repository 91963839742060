import React, { useEffect, useState } from 'react'
import "react-modal-video/css/modal-video.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import luggage from "../../../../models/luggage";
import notistack from "../../../../plugin/notistack";
import { country } from "../../../../models";

const luggagedefaultData = {
  packer: '',
  title: '',
  country_departure: '',
  country_arrival: '',
  state_departure: '',
  state_arrival: '',
  city_departure: '',
  city_arrival: '',
  pick_up: '',
  delivery_place: '',
  departure_date: '',
  arrived_date: '',
  luggage_estimation: '',
  weight: '',
  receiver_name: '',
  receiver_contact_number: '',
  description: '',
  images: '',
  type_of_travel:'1'
}
const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

export default function Luggages() {
  const history = useHistory();
  const { t } = useTranslation();
  const [details, setDetails] = useState(format)
  const [luggageData, setluggageData] = useState(luggagedefaultData);
  const [image, setImage] = useState();


  const handleLuggageChange = (e) => {
    const { name, value } = e.target;
    handleSetluggageData(name, value);
  }
  const handleSetluggageData = (name, value) => {
    setluggageData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleluggageSubmit = (event) => {
    luggage.create(luggageData).then((response) => {
      history.push(`/sellArticle/${response.id}/${luggageData.type_of_travel}`);
      setluggageData(luggagedefaultData);
      console.log("luggageData", luggageData);
      notistack.success(t('form.add_submited'));

    })
  }

  const selectImgage = (e) => {
    setImage(URL.createObjectURL(e.target.files[0]))
    const { files } = e.target;
    handleSetluggageData('images', files[0]);
  }
 // ------------------------------------------------------------
  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details)
        handleData("arriavalCountry", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  const getDepartureStateData = (id) => {
    country
      .state(id)
      .then((details) => {
        handleData("departureState", details)
      })
  };
  const getArrivalStateData = (id) => {
    country
      .state(id)
      .then((details) => {
        handleData("arriavalState", details)
      })
  };
  const getDepartureCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("departureCity", details)
      })
  };
  const getArrivalCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("arriavalCity", details)
      })
  };
  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <form onSubmit={(e) => e.preventDefault()}
      action="#"
      method="post">
      <div className="">
        <div className="contact-form-wrap">
          <h4>{t("form.luggage_ad")}</h4>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.title_of_luggage")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.title_of_luggage")}
                  id="title"
                  onChange={handleLuggageChange}
                  name='title'
                  value={luggageData.title}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.type_of_packer")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="packer"
                  onChange={handleLuggageChange}
                  name='packer'
                  value={luggageData.packer}>
                  <option value="" selected disabled>{t("form.type_of_packer")}</option>
                  <option value={"1"} >{t("form.Regular")}</option>
                  <option value={"2"}>{t("form.periodical")}</option>
                  <option value={"3"}>{t("form.Oneoff")}</option>
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.country_Of_departure")}</label>
                <select
                  id="country_departure"
                  onChange={(e) => {
                    handleLuggageChange(e, "country_of_Departure");
                    handleSetluggageData("state_departure", "");
                    handleSetluggageData("city_departure", "");
                    getDepartureStateData(e.target.value);
                  }}
                  name='country_departure'
                  value={luggageData.country_departure}
                >
                  <option value='' selected disabled>{t("form.country_Of_departure")}</option>
                  {details.departureCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.country_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="country_arrival"
                  onChange={(e) => {
                    handleLuggageChange(e, "country_arrival");
                    handleSetluggageData("state_arrival", "");
                    handleSetluggageData("city_arrival", "");
                    getArrivalStateData(e.target.value);
                  }}
                  name='country_arrival'
                  value={luggageData.country_arrival}
                >
                  <option value='' selected disabled>{t("form.country_of_arrival")}</option>
                  {details.arriavalCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.state_of_departure")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="state_departure"
                  onChange={(e) => {
                    handleLuggageChange(e, "state_departure");
                    handleSetluggageData("city_departure", "");
                    getDepartureCity(e.target.value);
                  }}
                  name='state_departure'
                  value={luggageData.state_departure}
                >
                  <option value='' selected disabled>{t("form.state_of_departure")}</option>
                  {details.departureState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.state_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="state_arrival"
                  onChange={(e) => {
                    handleLuggageChange(e, "state_arrival");
                    handleSetluggageData("city_arrival", "");
                    getArrivalCity(e.target.value);
                  }}
                  name='state_arrival'
                  value={luggageData.state_arrival}
                >
                  <option value='' selected disabled>{t("form.state_of_arrival")}</option>
                  {details.arriavalState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.city_of_departure")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="city_departure"
                  onChange={(e) => {
                    handleLuggageChange(e, "city_departure");
                  }}
                  name='city_departure'
                  value={luggageData.city_departure}
                >
                  <option value='' selected disabled>{t("form.city_of_departure")}</option>
                  {details.departureCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.city_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                <select
                  id="city_arrival"
                  onChange={(e) => {
                    handleLuggageChange(e, "city_arrival");
                  }}
                  name='city_arrival'
                  value={luggageData.city_arrival}
                >
                  <option value='' selected disabled>{t("form.city_of_arrival")}</option>
                  {details.arriavalCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.pick_up_place")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.enter_pick_up_place")}
                  id="pick_up"
                  onChange={handleLuggageChange}
                  name='pick_up'
                  value={luggageData.pick_up}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.delivery_place")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="text"
                  placeholder={t("form.enter_delivery_place")}
                  id="delivery_place"
                  onChange={handleLuggageChange}
                  name='delivery_place'
                  value={luggageData.delivery_place}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.send_date")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="Date"
                  id="departure_date"
                  onChange={handleLuggageChange}
                  name='departure_date'
                  value={luggageData.departure_date}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.arrival_date")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="Date"
                  id='arrived_date'
                  onChange={handleLuggageChange}
                  name='arrived_date'
                  value={luggageData.arrived_date}
                  min={new Date().toISOString().split('T')[0]}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.luggage_estimation")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  placeholder={t("form.enter_luggage_estimation")}
                  id="luggage_estimation"
                  onChange={handleLuggageChange}
                  name='luggage_estimation'
                  value={luggageData.luggage_estimation}
                />
                <span style={{ position: 'absolute', right: 20, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>€</span>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.total_kilos_of_luggage")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  placeholder={t("form.enter_total_kilos_of_luggage")}
                  id="weight"
                  onChange={handleLuggageChange}
                  name='weight'
                  value={luggageData.weight}
                />
                <span style={{ position: 'absolute', right: 20, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>kg</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.luggage_receiver_name")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type=""
                  placeholder={t("form.enterluggage_receiver_name")}
                  id="receiver_name"
                  onChange={handleLuggageChange}
                  name='receiver_name'
                  value={luggageData.receiver_name}
                />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="custom-input-group ">
                <label htmlFor="name">{t("form.luggage_receiver_telephone")}<span style={{ color: 'red' }}>*</span></label>
                <input
                  required
                  type="number"
                  onWheel={numberInputOnWheelPreventChange}
                  placeholder={t("form.enter_luggage_receiver_telephone")}
                  id="contact_number"
                  onChange={handleLuggageChange}
                  name='receiver_contact_number'
                  value={luggageData.receiver_contact_number}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-input-group">
                <label htmlFor="name">{t("form.description_and_other_information")}</label>
                <textarea rows={4}
                  id="description"
                  placeholder={t("form.enter_description_and_other_information")}
                  onChange={handleLuggageChange}
                  name='description'
                  value={luggageData.description}>
                </textarea>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-12">
              <div className="custom-input-group">
                <h3>{t("menu.gallery")} ({t("form.Upload_image_in_1280_720_dimension")})</h3><br />
                <label htmlFor="name" style={{ color: '#ff4838' }}>{t("form.You_can_add_4_images_maximum")}</label>
                <input
                  id="images"
                  // placeholder=" Enter Description And Other Information"
                  // onChange={handleLuggageChange}
                  onChange={selectImgage}
                  name='images'
                  // value={luggageData.images}
                  style={{ height: "130px", borderStyle: 'dashed' }}
                  type="file" multiple accept="gif|jpg|png|jpeg" />
                {/* <span style={{ position: 'absolute', right:"50%", bottom: '25%', fontWeight: '500',opacity:0.65 }}> Click or drag images here</span>
               <span class="bi bi-plus-circle" style={{ position: 'absolute', right:"63%", bottom: '55px', fontWeight: '500' }}></span> */}
              </div>
            </div>
          </div>
          <div className="custom-input-group row col-lg-12">
            <div className=" col-sm-6 col-md-6 col-lg-">
              <div className="submite-btn" >
                <button type="submit" onClick={handleluggageSubmit} >{t("form.submit")}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  )
}
