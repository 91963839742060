import { logDOM } from "@testing-library/react";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import viewCount from "../../../../models/viewCount";
import AppContext from "../../../../store/context/app-context";
import RatingComp from "../Rating/RatingComp";
import moment from "moment";
import i18n from "../../../../plugin/i18n";

export default function RecentTravelCard(props) {
  const history = useHistory();
  const { t, i18n } = useTranslation();
  const contextObj = useContext(AppContext);
  const [details, setDetails] = useState();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    viewCount
      .get(props.item.id, 2)
      .then((item) => {
        setDetails(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const createViews = () => {
    viewCount
      .create(props.item.id, 2)
      .then((item) => {
        // setDetails(item);
        getData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  return (
    <div
      className="package-card-delta"
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        history.push(
          contextObj.login == true
            ? `${process.env.PUBLIC_URL}/ad_details/${props.item.id}/4`
            : "/login"
        );
      }}
    >
      <div className="package-thumb">
        <div className="guide-card-beta ">
          <div className="guide-image">
            <Link
              to={
                contextObj.login == true
                  ? `/ad_details/${props.item.id}/4`
                  : "/login"
              }
            >
              <img
                src={
                  props.item.vehicle_type == 1
                    ? "/images/vehicles/Bike.png"
                    : props.item.vehicle_type == 2
                    ? "/images/vehicles/boat.png"
                    : props.item.vehicle_type == 3
                    ? "/images/vehicles/bus.png"
                    : props.item.vehicle_type == 4
                    ? "/images/vehicles/car.png"
                    : props.item.vehicle_type == 5
                    ? "/images/vehicles/kickscooter.png"
                    : props.item.vehicle_type == 6
                    ? "/images/vehicles/train.png"
                    : props.item.vehicle_type == 7
                    ? "/images/vehicles/MoterBike.png"
                    : props.item.vehicle_type == 8
                    ? "/images/vehicles/plan.png"
                    : props.item.vehicle_type == 9
                    ? "/images/vehicles/scooter.png"
                    : props.item.vehicle_type == 10
                    ? "/images/vehicles/Tvg.png"
                    : props.item.vehicle_type == 12
                    ? "/images/vehicles/train.png"
                    : "/images/vehicles/Tramway.png"
                }
                alt=""
                style={{
                  aspectRatio: "3/2",
                  objectFit: "contain",
                  padding: "15px",
                  borderRadius: "20px",
                }}
              />
            </Link>
            <p
              className="card-lavel me-2"
              style={{
                fontSize: "10px",
                textAlign: "end",
                minWidth: "10px",
                borderRadius: "10px",
                padding: "0px 6px 0px 6px",
              }}
            >
              {props.item.verification == true
                ? t("cobagage.verified")
                : t("cobagage.unverified")}
            </p>
            <p
              className="px-4"
              style={{ marginTop: "-40px", fontWeight: "700" }}
            >
              {props?.item?.title?.length > 18
                ? props?.item?.title?.slice(0, 18).trimEnd() + "..."
                : props?.item.title}
            </p>
            <p
              className="px-4"
              style={{ fontSize: "12px", fontStyle: "italic" }}
            >
              {props?.item?.traveller_account_type == 2
                ? t("profile.Professional_Travellers")
                : t("profile.Individual_Travellers")}
            </p>
            <span>
              <RatingComp
                // userid={props?.item.user_id}
                rate={props.item.rating}
              />
            </span>
            <div style={{ alignItems: "flex-start", display: "flex" }}>
              <div
                style={{
                  backgroundColor: "rgb(243, 156, 18)",
                  borderRadius: "7px",
                  marginLeft: "24px",
                  marginTop: "10px",
                  marginBottom: "1px",
                  padding: "8px 20px 8px",
                }}
              >
                <h3
                  className="p-card-title"
                  style={{
                    fontSize: "14px",
                    fontWeight: "500",
                    color: "#FFFFFF",
                  }}
                >
                  {props?.item?.price_per_kg} {props?.item?.currency}{" "}
                  {t("cobagage.per")} kg
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="package-card-body p-2">
        <div class="pb-2 row icons_container">
          <div className="row">
            <div className="col-2">
              <img
                src={props.item.departure_flag}
                style={{ width: "100%" }}
              ></img>
            </div>
            <div
              className="col-3 icons_container icon facebook"
              style={{ paddingLeft: "0px" }}
            >
              {props?.item.city_departure_name.length > 4
                ? props?.item.city_departure_name.slice(0, 4) + ".."
                : props?.item.city_departure_name}
              <div class="tooltip">{props?.item?.city_departure_name}</div>
            </div>
            <div className="col-2">
              <i className="bx bxs-right-arrow-alt" />
            </div>
            <div className="col-2">
              <img
                src={props.item.arrival_flag}
                style={{ width: "100%" }}
              ></img>
            </div>
            <div
              className="col-3  icons_container icon facebook"
              style={{ paddingLeft: "0px" }}
            >
              {props?.item.city_arrival_name.length > 4
                ? props?.item.city_arrival_name.slice(0, 4) + ".."
                : props?.item.city_arrival_name}
              <div class="tooltip">{props?.item?.city_arrival_name}</div>
            </div>
          </div>
        </div>
        <div class="pb-0">
          <table
            className="col-lg-12 col-12 col-md-12 col-sm-12"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            <tr>
              <td style={{ fontSize: "11px" }}>
                <i class="bi bi-calendar" /> {i18n.language === "enUS" ? moment(props?.item?.departure_date, "DD/MM/YYYY").format("MM/DD/YYYY") : props?.item?.departure_date}
              </td>
              <td className="travelCard" style={{ fontSize: "11px" }}>
                <i class="bi bi-calendar" /> {i18n.language === "enUS" ? moment(props?.item?.arrival_date, "DD/MM/YYYY").format("MM/DD/YYYY") : props?.item?.arrival_date}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "11px", fontWeight: "600" }}>
                {t("form.Departure")}
              </td>
              <td
                className="travelCard"
                style={{ fontSize: "11px", fontWeight: "600" }}
              >
                {t("form.Arrival")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "11px" }}>
                <i class="bi bi-stopwatch"></i> {props?.item?.departure_time}hrs
              </td>
              <td className="travelCard" style={{ fontSize: "11px" }}>
                <i class="bi bi-stopwatch"></i> {props?.item?.arrival_time}hrs
              </td>
            </tr>
          </table>
          <hr style={{ color: "rgb(243 83 18)", height: "2px" }}></hr>
        </div>
        <div class="pb-2">
          <table
            className="col-lg-12 col-12 col-md-12 col-sm-12"
            style={{ fontSize: "12px", fontWeight: "600" }}
          >
            <tr>
              <td style={{ color: "#ff4838", opacity: 0.6 }}>
                {t("form.trip_type")}:
              </td>
              <td class="text-end" style={{ opacity: 0.8 }}>
                {props?.item?.trip_type == 1
                  ? t("form.one_way")
                  : t("form.Round_Way")}
              </td>
            </tr>
            <tr>
              <td style={{ color: "#ff4838", opacity: 0.6 }}>
                {t("form.Remaining_Capacity")}:
              </td>
              <td class="text-end" style={{ opacity: 0.8 }}>
                {props?.item?.remaining_capacity} kg
              </td>
            </tr>
          </table>
          <hr style={{ color: "rgb(243 83 18)", height: "2px" }}></hr>
        </div>
        <div style={{ marginTop: "-11px" }}>
          <table
            className="col-lg-12 col-12 col-md-12 col-sm-12"
            style={{ fontSize: "14px", fontWeight: "700" }}
          >
            <tr>
              <td class="text-decoration-underline">{t("form.Posted_By")}:</td>
              <td class="text-end">
                <i class="bi bi-person-fill" />{" "}
                <span style={{ color: "#1e98d7" }}>
                  {props?.item?.user_name}
                </span>
              </td>
            </tr>
            <tr>
              <td>
                <i class="bi bi-calendar" />{" "}
                <span>{t("form.Member_since")}:</span>
              </td>
              <td class="text-end">
              {i18n.language === "enUS" ? moment(props.item.member_since).format("MM/DD/YYYY") : moment(props.item.member_since).format("DD/MM/YYYY")}
              </td>
            </tr>
            <tr>
              <td>
                <i class="bi bi-eye-fill" /> {t("form.Views")}:
              </td>
              <td class="text-end"> {details?.totalcount}</td>
            </tr>
          </table>
        </div>
        <div className="p-card-bottom">
          <div className="book-btn">
            <Link
              to={
                contextObj.login == true
                  ? `${process.env.PUBLIC_URL}/ad_details/${props.item.id}/4`
                  : "/login"
              }
              style={{
                fontSize: "9px",
                fontWeight: "600",
                borderRadius: "5px",
              }}
              onClick={() => {
                if (contextObj.login == true) {
                  createViews();
                  scrollTop();
                } else {
                  scrollTop();
                }
              }}
            >
              {t("profile.view_trip_details")}{" "}
              <i className="bx bxs-right-arrow-alt" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
