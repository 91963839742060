import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useValidator from "../../../plugin/validator";
import { auth } from "../../../models";
import AppContext from "../../../store/context/app-context";
import notistack from "../../../plugin/notistack";
import Loading from "../../common/Loading";

const reset = {
  email: "",
  password: "",
  role: "User",
};

function LoginWrapperArea() {
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState(reset);
  const [showPassword, setShowPassword] = useState(false);
  const [load, setLoad] = useState(false);
  const location = useLocation();


  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);

  // useEffect(() => {
  //   checkURL();
  // }, [contextObj.login]);

  // const checkURL = () => {
  //   if (!contextObj.login && location.pathname === "/myProfile") {
  //     history.push("/login");
  //   }
  // };

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const error = {
    email: validator.message(
      t("auth.email"),
      data.email,
      "required|email|string|max:200"
    ),
    password: validator.message(
      t("auth.password"),
      data.password,
      "required|password|max:200"
    ),
    // otp: validator.message(t('auth.email'), login.otp, isOpen ? 'required' : " "),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const OtpVerified = () => {
    history.push("/otp")
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      setLoad(true);
      auth
        .login(data)
        .then((response) => {
          !response.otp_verified && OtpVerified();
          window.sessionStorage.setItem("contact_number", response.contact_number);
          window.sessionStorage.setItem("email", response.email);
          contextObj.setLoginUserType(response.user.login_type);
          contextObj?.setLoginUserCountry(response.user.country_id);
          localStorage.setItem("loginUserType", response.user.login_type);
          localStorage.setItem("loginUsercountry", response.user.country_id);
          history.push(
            response.user.login_type
              ? "/"
              : `/ProfileInfo/${response.user.country_id}`
          );
          contextObj.functionsetloginUser();
          contextObj.functionLogin();
          // setLoad(false);
        })
        .catch((error) => {
          console.log("login page", error);
          notistack.error(error?.data?.message)
          setLoad(false);
          // if (error?.data.message) {
          //   notistack.error(error?.data.message);
          // }
        });
    } else {
      showMessage(true);
    }
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <div class="contact-form-wrap ">
            <div class="row">
              <div class="col-lg-6 col-12 col-md-6">
                <img
                  src="/images/registration/rG5.png"
                  style={{ width: "80%", height: "80%" }}
                />
              </div>
              <div class="col-lg-5 col-12 col-md-6">
                <form onSubmit={handleSubmit} action="#" method="post">
                  <div className="">
                    <div className="row">
                      <div className=" col-lg-12">
                        <div className="custom-input-group">
                          <h4> {t("auth.login")}</h4>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="custom-input-group ">
                          <input
                            required
                            type="text"
                            placeholder={t("auth.email")}
                            id="email"
                            name="email"
                            value={data.email}
                            onChange={handleChange}
                            autoComplete="email"
                          />
                          <span style={{ color: "red" }}>{error.email}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <div className="custom-input-group">
                          <input
                            required
                            type={showPassword ? "text" : "password"}
                            placeholder={t("auth.password")}
                            value={data.password}
                            id="password"
                            name="password"
                            onChange={handleChange}
                            error={!!error.password}
                            helperText={error.password}
                            autoComplete="password"
                          />
                          {showPassword ? (
                            <i
                              style={{ right: "19px", top: "27px" }}
                              onClick={handleShowPassword}
                              class="bi bi-eye-slash-fill"
                            />
                          ) : (
                            <i
                              style={{ right: "19px", top: "27px" }}
                              onClick={handleShowPassword}
                              class="bi bi-eye-fill"
                            />
                          )}
                          <span style={{ color: "red" }}>{error.password}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12">
                        <p
                          className="text-secondary"
                          style={{ fontWeight: "600" }}
                        >
                          <Link to={`/forgotPassword`} className="text-danger ">
                            {t("auth.login_forgot_password")}
                          </Link>
                        </p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-6">
                        <div className="custom-input-group">
                          <div className="submite-btn">
                            <button type="submit" onClick={handleSubmit}>
                              {t("auth.login")}
                            </button>
                          </div>
                          <Link to={`/register`}>
                            <span>
                              <p
                                style={{
                                  color: "#dc3545",
                                  cursor: "pointer",
                                  paddingBottom: "10px",
                                  textDecoration: "underline",
                                }}
                              >
                                {t("auth.create_new_account")}
                              </p>
                            </span>
                          </Link>
                        </div>
                      </div>
                      {/* <div className="col-6">
                        <div className="custom-input-group">
                          <Link to={`/register`} className='text-danger '>
                            <div className="submite-btn">
                              <button type="submit">{t("auth.register")}</button>
                            </div>
                          </Link>
                        </div>
                      </div> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}

export default LoginWrapperArea;
