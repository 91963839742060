import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import notistack from "../../../../plugin/notistack";
import travel from "../../../../models/travel";
import luggage from "../../../../models/luggage";
import RatingComp from "../Rating/RatingComp";
import MyTravelEditPopup from "./MyTravelEditPopup";
import MyTravelsEditPopup from "./MyTravelsEditPopup";
import moment from "moment";

export default function TravelCard(props) {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const handleHide = () => setShow(false);
  const deleteTravel = () => {
    if (props?.type == 4) {
      travel
        .delete(props.item?.id)
        .then((responseData) => {
          props.getData();
          notistack.success(t("form.Your_deleted_Successfully"));
        })
        .catch((error) => {
          console.log(error);
          notistack.error(error?.data.message);
        });
    } else {
      luggage
        .delete(props.item?.id)
        .then((responseData) => {
          props.getData();
          notistack.success(t("form.Your_deleted_Successfully"));
        })
        .catch((error) => {
          console.log(error);
          notistack.error(error?.data.message);
        });
    }
  };

  return (
    <div className="col-lg-5 col-md-6 col-sm-6 mb-4">
      <div className="package-card-delta">
        <div className="package-thumb">
          <div className="guide-card-beta ">
            <div className="guide-image">
              <Link to={`#`}>
                <img
                  src={
                    props.type == 4
                      ? props.item.vehicle_type == 1
                        ? "/images/vehicles/Bike.png"
                        : props.item.vehicle_type == 2
                          ? "/images/vehicles/boat.png"
                          : props.item.vehicle_type == 3
                            ? "/images/vehicles/bus.png"
                            : props.item.vehicle_type == 4
                              ? "/images/vehicles/car.png"
                              : props.item.vehicle_type == 5
                                ? "/images/vehicles/kickscooter.png"
                                : props.item.vehicle_type == 6
                                  ? "/images/vehicles/train.png"
                                  : props.item.vehicle_type == 7
                                    ? "/images/vehicles/MoterBike.png"
                                    : props.item.vehicle_type == 8
                                      ? "/images/vehicles/plan.png"
                                      : props.item.vehicle_type == 9
                                        ? "/images/vehicles/scooter.png"
                                        : props.item.vehicle_type == 10
                                          ? "/images/vehicles/Tvg.png"
                                          : props.item.vehicle_type == 12
                                          ? "/images/vehicles/train.png"
                                          : "/images/vehicles/Tramway.png"
                      : props?.item?.image[0]
                  }
                  alt=""
                  style={{
                    aspectRatio: "3/2",
                    objectFit: "contain",
                    padding: "15px",
                    borderRadius: "20px",
                  }}
                />
              </Link>
              <p
                className="card-lavel me-2"
                style={{
                  fontSize: "10px",
                  textAlign: "end",
                  minWidth: "10px",
                  borderRadius: "10px",
                  padding: "0px 6px 0px 6px",
                }}
              >
                {props?.item?.verification == false ? t("cobagage.unverified") : t("cobagage.verified")}
              </p>
              {props.type == 4 && (
                <>
                  <p
                    className="px-4"
                    style={{ marginTop: "-40px", fontWeight: "700" }}
                  >
                    {props?.item?.title?.length > 18 ? (props?.item?.title?.slice(0, 18).trimEnd() + '...') : props?.item.title}
                  </p>
                  <p
                    className="px-4"
                    style={{ fontSize: "12px", fontStyle: "italic" }}
                  >
                    {props?.item?.traveller_account_type == 2 ? t("profile.Professional_Travellers") : t("profile.Individual_Travellers")}
                  </p>
                </>
              )}
              <span>
                <RatingComp rate={props.item.rating} />
              </span>
              <div
                style={{
                  backgroundColor: "whitesmoke",
                  borderRadius: "7px",
                  width: "50%",
                  marginLeft: "24px",
                  marginTop: "10px",
                  marginBottom: "-7px",
                }}
              >
                <h3
                  className="p-card-title"
                  style={{
                    fontSize: "14px",
                    padding: "8px 20px 8px",
                    fontWeight: "700",
                  }}
                >
                  {props.type == 5 ? props.item?.luggage_estimation : props.item?.price_per_kg} {props?.item?.currency} {t("cobagage.per")} kg
                </h3>
              </div>
            </div>
          </div>
        </div>
        <div className="package-card-body" style={{ padding: "12px 12px" }}>
          <div class="pb-2 row icons_container">
            <div className="row">
              <div className="col-2">
                <img src={props.item.departure_flag}></img>
              </div>
              <div
                className="col-3 icons_container icon facebook"
                style={{ paddingTop: "2px" }}
              >
                {props?.item.city_departure_name.length > 4
                  ? props?.item.city_departure_name.slice(0, 4) + ".."
                  : props?.item.city_departure_name}
                <div class="tooltip">{props?.item?.city_departure_name}</div>
              </div>
              <div className="col-2">
                <i className="bx bxs-right-arrow-alt" />
              </div>
              <div className="col-2">
                <img src={props.item.arrival_flag}></img>
              </div>
              <div
                className="col-3  icons_container icon facebook"
                style={{ paddingTop: "2px" }}
              >
                {props?.item.city_arrival_name.length > 4
                  ? props?.item.city_arrival_name.slice(0, 4) + ".."
                  : props?.item.city_arrival_name}
                <div class="tooltip">{props?.item?.city_arrival_name}</div>
              </div>
            </div>
          </div>
          <div class="pb-2">
            <table
              className="col-lg-12 col-12 col-md-12 col-sm-12"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              {props.type == 4 && (
                <tr>
                  <td style={{ color: "#ff4838", opacity: 0.6 }}>
                    {t("profile.trip_type")}:
                  </td>
                  <td class="text-end" style={{ opacity: 0.8 }}>
                    {props?.item?.trip_type == 1
                      ? t("form.one_way")
                      : t("form.Round_Way")}
                  </td>
                </tr>
              )}
              {props.type == 4 && (
                <tr>
                  <td style={{ color: "#ff4838", opacity: 0.6 }}>
                    {t("profile.carriage_capacity")}:
                  </td>
                  <td class="text-end" style={{ opacity: 0.8 }}>
                    {props?.item?.carriage_capacity} kg
                  </td>
                </tr>
              )}
              {props.type == 4 && (
                <tr>
                  <td style={{ color: "#ff4838", opacity: 0.6 }}>
                    {t("profile.Remaining")} Kg:
                  </td>
                  <td class="text-end" style={{ opacity: 0.8 }}>
                    {props?.item?.remaining_capacity} {t("profile.out_of")}{" "}
                    {props?.item?.carriage_capacity} kg
                  </td>
                </tr>
              )}
              {props.type == 5 && (
                <tr>
                  <td style={{ color: "#ff4838" }}>{t("profile.weight")}</td>
                  <td class="text-end" style={{ color: "#ff4838" }}>
                    {t("profile.Worth")}
                  </td>
                </tr>
              )}
              {props.type == 5 && (
                <tr>
                  <td> {props?.item?.weight} Kg</td>
                  <td class="text-end">{props?.item?.luggage_estimation} {props?.item?.currency}</td>
                </tr>
              )}
              <tr>
                <td style={{ color: "#ff4838", opacity: 0.8 }}>
                  {t("form.Posted_Date")}:
                </td>
                <td class="text-end">{moment(props?.item?.updated_at.slice(0, 10)).format("DD/MM/YYYY")}</td>
              </tr>
            </table>
          </div>
          {props?.isShow == 1 && (
            <div className="p-card-bottom">
              <div className="book-btn">
                <Link
                  onClick={() => {
                    handleShow();
                  }}
                  to={`#`}
                  style={{
                    fontSize: "11px",
                    fontWeight: "600",
                    color: "green",
                  }}
                >
                  <i class="bi bi-pencil-fill" style={{ color: "green" }} />{" "}
                  {t("profile.edit")}
                </Link>
              </div>
              <i
                class="bi bi-trash"
                style={{ color: "red", fontSize: "20px", cursor: "pointer" }}
                onClick={() => deleteTravel()}
              />
            </div>
          )}

          {props?.type == 4 && (
            <MyTravelEditPopup props={props} show={show} close={handleHide} />
          )}
          {props?.type == 5 && (
            <MyTravelsEditPopup props={props} show={show} close={handleHide} />
          )}
        </div>
      </div>
    </div>
  );
}
