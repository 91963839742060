import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from 'react-bootstrap';
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import request from "../../../../../models/request";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";
import luggage from "../../../../../models/luggage";



function PaymentWrapperArea() {
  const { id } = useParams();
  const { type } = useParams();
  const { request_type } = useParams();
  console.log('type', type);
  console.log('request_type', request_type);
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  console.log(luggageData);
  console.log(LuggageRequest);
  const [validator, showMessage] = useValidator();
  const [CancleValidator, showCancleMessage] = useValidator();
  const [isShow, setIsShow] = useState();
  console.log('isShow', isShow);
  const [data, setData] = useState({
    request_id: id,
    request_type: type,
    status: '',
    reason_to_reject_luggage: '',
    reason_to_cancel: '',
    cancel_by: '2',
  });
  console.log(data);
  const error = {
    reason_to_reject_luggage: validator.message('Reason to Reject', data.reason_to_reject_luggage, (isShow == 1 ? 'required|' : '') + 'string|max:200'),
  };
  const cancleError = {
    reason_to_cancel: CancleValidator.message('Reason to Reject', data.reason_to_cancel, (isShow == 4 ? 'required|' : '') + 'string|max:200'),
  };
  const handleClose = () => {
    if (isShow == 3) {
      getData();
    }
    setIsShow(1);
    showMessage(false);
    showCancleMessage(false);
    handleSetData('reason_to_cancel', '')
    setShow(false);
  }
  const handleShow = () => setShow(true);

  // useEffect(() => {
  //   getData();
  // }, [])
  useEffect(() => {
    if (isShow === 2) {
      requestReceived(data);
    }
  }, [isShow])

  const getData = () => {
    if (request_type == 1) {
      request
        .getRequestReceived(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
    } else {
      request
        .get(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
      luggage
        .get(id)
        .then((item) => {
          setLuggageData(item);
        })
        .catch(error => {
          console.log("error", error);
        })
    }
  };
  const requestReceived = (data) => {
    if (validator.allValid()) {
      request.
        respondToRequestReceived(data)
        .then((item) => {
          if (isShow == 1) {
            setIsShow(3);
          } else {
            getData();
          }
        })
        .catch(error => {
          console.log("error", error);
          if (error.data.message) {
            notistack.error(error.data.message);
            setIsShow(1);
          }
        })
    } else {
      showMessage(true);
    }
  }
  const cancleOrder = () => {
    if (CancleValidator.allValid()) {
      request.
        cancle(data)
        .then((item) => {
          setIsShow(5);
          console.log(item);
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
    } else {
      showCancleMessage(true);
    }
  }
  const tabPanelValue = () => {
    contextObj.setTabValue(5);
    history.push('/myProfile');
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  return (
    <>
      <div class="p-4">
        <div className="row pb-110 " style={{ textAlign: 'center' }}>
          <div className="col-lg-12 justify-content-center">
            <div className="row" style={{ textAlign: '-webkit-center' }} >
              <div className="col-lg-3" />
              <div class="pb-3 col-lg-6 col-sm-12 col-md-12">
                <div class='earning pt-3' style={{ justifyContent: 'center' }}>
                  <div class='plan' style={{ background: 'white', textAlign: 'center' }}>
                    <p className="fw-bold fs-6" style={{ color: 'black', padding: '15px' }}>{t("form.Please_Continue_Your_Order")}</p>
                    <Button style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: '5px', marginTop: '30px', marginBottom: '25px' }}
                    // onClick={() => tabPanelValue()}
                    >
                      {t('form.Continue_Order')}{' '}<i class="bi bi-arrow-right-circle" />
                    </Button>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

export default PaymentWrapperArea;
