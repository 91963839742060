import React from "react";
import PasswordResetWrapperArea from "./PasswordResetWrapperArea";
import Breadcrumb from "../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function PasswordReset() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("auth.forgot_password")} />
      <PasswordResetWrapperArea/>
     
    </>
  );
}

export default PasswordReset;
