
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import { useTranslation } from "react-i18next";

function Footer(props) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  // smooth scrol
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className={props.className}>
        <div className="footer-main-wrapper">
          <div className="footer-vactor">
            <img src={process.env.PUBLIC_URL + "/images/banner/footer-bg.png"} alt="FooterIMG" />
          </div>
          <div className="container">
            <div className="row justify-content-center g-4">
              <div className="col-lg-4">
                <div className="footer-about text-lg-start text-center">
                  <div className="footer-social-wrap">
                    <h5>{t("menu.follow_us_on")} </h5>
                    <ul className="footer-social-links justify-content-lg-start justify-content-center">
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.instagram.com/cobagage_officiel/?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr"
                          target="_blank"
                          style={{ all: 'unset', cursor: 'pointer' }}
                        >
                          <img src={process.env.PUBLIC_URL + '/images/icons/instagram.png'} alt="Instagram Logo" height={35} width={35} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.facebook.com/cobagage.cobagage.9"
                          target="_blank"
                          style={{ all: 'unset', cursor: 'pointer' }}
                        >
                          <img src={process.env.PUBLIC_URL + '/images/icons/facebook.png'} alt="Instagram Logo" height={35} width={35} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://twitter.com/CobagageSite"
                          target="_blank"
                          style={{ all: 'unset', cursor: 'pointer' }}
                        >
                          <img src={process.env.PUBLIC_URL + '/images/icons/twitter.png'} alt="Twitter Logo" height={35} width={35} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.youtube.com/@cobagage6596/featured"
                          target="_blank"
                          style={{ all: 'unset', cursor: 'pointer' }}
                        >
                          <img src={process.env.PUBLIC_URL + '/images/icons/youtube.png'} alt="youtube Logo" height={35} width={35} />
                        </a>
                      </li>
                      <li>
                        <a
                          rel="noopener noreferrer"
                          href="https://www.linkedin.com/in/cobagage-network-11abb427a/"
                          target="_blank"
                          to={"#"}
                          style={{ all: 'unset', cursor: 'pointer' }}
                        >
                          <img src={process.env.PUBLIC_URL + '/images/icons/linkedin.png'} alt="linkedin Logo" height={35} width={35} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-12 row" style={{ marginTop: "20px" }}>
                  <div className="col-6 d-lg-block d-flex justify-content-end justify-content-md-center">
                    <img
                      src={process.env.PUBLIC_URL + "/images/banner/png-transparent-google-play-android-app-store-apple-android-text-rectangle-logo-thumbnail.png"}
                      style={{ width: "80%", height: "100%" }}
                    ></img>
                  </div>
                  <div className="col-6">
                    <img
                      src={process.env.PUBLIC_URL + "/images/banner/AppStore - Copy.png"}
                      style={{ width: "80%", height: "100%" }}
                    ></img>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  {/* <h3 className="footer-widget-title">Quick Link</h3> */}
                  <ul className="footer-links">
                    <li className="pt-0">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        <h4 className="footer-widget-title ">{t("menu.about_us")}</h4>
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/RateOurWebsite`}
                      >
                        Tips and advices
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        onClick={scrollTop}

                        to={`${process.env.PUBLIC_URL}/TipsAndAdvice`}
                      >
                        {t('menu.Tips_And_Advice')}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/destination`}
                        to={`${process.env.PUBLIC_URL}/contact`}
                      >
                        {/* Destination */}
                        {t("menu.contact_us")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/guide`}
                        // to={`${process.env.PUBLIC_URL}/legals`}
                        to={`${process.env.PUBLIC_URL}/legals`}
                      >
                        {/* Tour Guide */}
                        {t("menu.legals")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/package-details`}
                        to={`${process.env.PUBLIC_URL}/Cgvu`}
                      >
                        {/* Booking Process */}
                        CGVU
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        onClick={scrollTop}
                        
                        to={`${process.env.PUBLIC_URL}/TipsAndAdvice`}
                      >
                        {t('menu.Tips_And_Advice')}
                      </Link>
                    </li> */}
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/blog`}
                        to={`${process.env.PUBLIC_URL}/`}
                      >
                        {/* Blog */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-2 col-md-4">
                <div className="footer-widget">
                  <h4 className="footer-widget-title">Cobagage</h4>
                  <ul className="footer-links">
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/morecobagage`}
                      >
                        {/* Wild &amp; Adventure Tours */}
                        {t("menu.more_on_cobagage")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/faq`}
                      // to={`${process.env.PUBLIC_URL}/`}
                      >
                        {/* Group Tour */}
                        FAQ
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/package`}
                        // to={`${process.env.PUBLIC_URL}/faq`}
                        to={`${process.env.PUBLIC_URL}/CobagageAsJob`}
                      >
                        {/* Seasonal Tours */}
                        {t("menu.cobagage_as_a_job")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                        // to={`${process.env.PUBLIC_URL}/faq`}
                        to={`${process.env.PUBLIC_URL}/privacyPolicy`}
                      >
                        {/* Relaxation Tours */}
                        {t("menu.privacy_policy")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={scrollTop}
                      // to={`${process.env.PUBLIC_URL}/package`}
                      >
                        {/* Family Friendly Tours */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <>
                <div className="col-lg-4 col-md-8">
                  <SRLWrapper>
                    <div className="footer-widget">
                      <h4 className="footer-widget-title text-center">
                        {t("menu.gallery")}
                      </h4>
                      <div className="footer-gallary-grid">
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron01.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-1.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron02.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-2.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron06.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-3.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron05.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-4.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron03.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-5.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron06.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-6.png"} alt="footerIMG" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </SRLWrapper>
                </div>
              </>
            </div>
            <div className="footer-contact-wrapper">
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center ">
              <div className="col-lg-3 col-md-4 col-6 ">
                <div className="footer-logo ">
                  <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`} >
                    <img
                      src={process.env.PUBLIC_URL + i18n.language === 'frFR' ? "/images/CobagageLogo4.png" : "/images/CobagageLogo.png"} alt="FooterIMG" style={{ height: '55px' }} />
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 col-md-4 col-6">
                <p>
                  {t("form.INPI_2022014480")}
                </p>
              </div>
              <div className="col-lg-3 col-md-4 col-12">
                <div className="copyright-link">
                  <p>
                    {t("profile.Cobagage_copyright_2019_All_rights_reserved")}{" "}
                    <a
                      rel="noopener noreferrer"
                      href="https://www.egenslab.com/"
                      target="_blank"
                    >
                      {" "}
                      {/* Egens Lab */}
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
