import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from 'react-bootstrap';
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import request from "../../../../../models/request";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";
import travel from "../../../../../models/travel";

function SendingRequestWrapperArea() {
  const { id } = useParams();
  const { type } = useParams();
  const { request_type, product_id } = useParams();
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  const [validator, showMessage] = useValidator();
  const [cancleValidator, showCancleMessage] = useValidator();
  const [isShow, setIsShow] = useState();

  const [data, setData] = useState({
    request_id: id,
    request_type: type,
    status: '',
    reason_to_reject_luggage: '',
    reason_to_cancel: '',
    cancel_by: '1',
  });
  const error = {
    reason_to_reject_luggage: validator.message('Reason to Reject', data.reason_to_reject_luggage, (isShow == 1 ? 'required|' : '') + 'string|max:200'),
  };
  const cancleError = {
    reason_to_cancel: cancleValidator.message('Reason to Reject', data.reason_to_cancel, (isShow == 4 ? 'required|' : '') + 'string|max:200'),
  };
  const handleClose = () => {
    if (isShow == 3) {
      getData();
    }
    setIsShow(1);
    showMessage(false);
    showCancleMessage(false);
    handleSetData('reason_to_reject_luggage', '');
    if (isShow != 5 || LuggageRequest?.reason_of_cancel == 0) {
      handleSetData('reason_to_cancel', '');
    }
    setShow(false);
  }
  const handleShow = () => setShow(true);

  useEffect(() => {
    getData();
  }, [])
  useEffect(() => {
    if (isShow === 2) {
      requestReceived(data);
    }
  }, [isShow])

  const getData = () => {
    if (request_type == 1) {
      request
        .getRequestReceived(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
    } else {
      request
        .get(id, type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
      travel
        .get(product_id)
        .then((item) => {
          setLuggageData(item);
         
        })
        .catch(error => {
          console.log("error", error);
        })
    }
  };
  const requestReceived = (data) => {
    if (validator.allValid()) {
      request.
        respondToRequestReceived(data)
        .then((item) => {
          if (isShow == 1) {
            setIsShow(3);
          } else {
            getData();
          }
        })
        .catch(error => {
          console.log("error", error);
          if (error.data.message) {
            notistack.error(error.data.message);
            setIsShow(1);
          }
        })
    } else {
      showMessage(true);
    }
  }
  const cancleOrder = () => {
    if (cancleValidator.allValid()) {
      request.
        cancle(data)
        .then((item) => {
          setIsShow(5);
          setLuggageRequest(item);

        })
        .catch(error => {
          console.log("error", error);
        })
    } else {
      showCancleMessage(true);
    }
  }
  const tabPanelValue = () => {
    contextObj.setTabValue(5);
    history.push('/myProfile');
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const acceptRequest = () => {
    handleSetData('status', 1);
    setIsShow(2);
  }
  return (
    <>
      <div class="p-4">
        <div className="row pb-110">
          <div className="col-lg-8">
            <Button style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', margin: '2px' }}
              onClick={() => tabPanelValue()}>
              <i class="bi bi-arrow-left-circle" />{' '} {t('profile.back_to_luggaage_request')}
            </Button>
            <div className="row">
              <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                <div class='earning pt-3'>
                  <div class='plan' style={{ background: 'white' }}>
                    <table class="table">
                      <tbody class='border-0'>
                        <tr>
                          <th className='border-0 d-flex align-items-center ps-1  gap-1'>
                            <img
                              src={LuggageRequest?.departure_flag}
                              style={{ objectFit: "contain", width: "30px", height: "17px", }}
                            ></img> {LuggageRequest?.city_departure_name}</th>
                          <th class='border-0'><i class="bi bi-arrow-right" /></th>
                          <th className='border-0 d-flex align-items-center ps-1  gap-1'>
                            <img
                              src={LuggageRequest?.arrival_flag}
                              style={{ objectFit: "contain", width: "30px", height: "17px", }}
                            ></img>
                            {LuggageRequest?.city_arrival_name}
                          </th>
                        </tr>
                        <tr class='border-0'>
                          <td class='border-0 fw-normal'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{LuggageRequest?.departure_date} | {LuggageRequest?.departure_time}</td>
                          <td class='border-0' />
                          <td class='border-0 fw-normal'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{LuggageRequest?.arrival_date} | {LuggageRequest?.arrival_time}</td>
                        </tr>
                        <tr class='border-0'>
                          <td class='border-0 fw-normal'>{' '}{t('profile.departure')}</td>
                          <td class='border-0'></td>
                          <td class='border-0 fw-normal'>{' '}{t('profile.arrival')}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="earning">
                  <div class="plan" >
                    <header>
                      <i class="bi bi-globe2"></i>
                      <h6 class="plan-title">{t('profile.travelling_by')}:</h6>
                      <div class="plan-cost"><span class="plan-price">
                        {LuggageRequest?.vehicle_type == 1 ? t('form.bike') :
                          LuggageRequest?.vehicle_type == 2 ? t('form.Boat') :
                            LuggageRequest?.vehicle_type == 3 ? t('form.Bus') :
                              LuggageRequest?.vehicle_type == 4 ? 'Car' :
                                LuggageRequest?.vehicle_type == 5 ? t('form.Kick_scooter') :
                                  LuggageRequest?.vehicle_type == 6 ? t('form.train') :
                                    LuggageRequest?.vehicle_type == 7 ? t('form.MoterBike') :
                                      LuggageRequest?.vehicle_type == 8 ? t('form.Flight') :
                                        LuggageRequest?.vehicle_type == 9 ? t('form.Scooter') :
                                          LuggageRequest?.vehicle_type == 10 ? t('form.Tgv') :
                                            LuggageRequest?.vehicle_type == 11 ? t('form.Tramway') : ''}
                      </span>  {LuggageRequest?.vehicle_type != 1 && <span class="plan-type">{LuggageRequest?.vehicle_details}</span>}</div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
            <div className="row pt-4">
              <div class="table-responsive ">
                <table class="table table-borderless">
                  <tbody class='border-0'>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.trip_type')}:</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.trip_type == 1 ? t('form.one_way') : t('form.Round_Way')}</td>
                    </tr>
                  </tbody>
                  <tbody class='border-0'>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.weight_carriage_capacity')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.carriage_capacity} Kg</td>
                    </tr>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.price_to_carry_weight')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.price_per_kg}{(request_type == 1 ? LuggageRequest : luggageData)?.currency}/kg</td>
                    </tr>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.Is_a_stopver_planned_in_middle_of_your_trip')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.stopover_in_trip == false ? t('form.no') : t('form.yes')}</td>
                    </tr>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.luggage_collection_place')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.pickup_place}</td>
                    </tr>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.Luggage_drop_off_place')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.delivery_place}</td>
                    </tr>
                    <tr class='border-0'>
                      <td class='border-0 fw-normal'>{t('profile.does_your_trip_benefit_from_travel_insurance')}</td>
                      <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.insurance == true ? t('form.yes') :t('form.no')}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row">
              <h4 class='fs-5 pb-3 text-decoration-underline fw-none pt-2' style={{ fontSize: '18px' }}>{t('profile.Your_luggage_instructions')}</h4>
              <p>{LuggageRequest?.packing_instruction}</p>
              <h5 className="text-decoration-underline pb-2">{t('profile.your_other_instructions')}</h5>
              <p>{LuggageRequest?.description}</p>
            </div>
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-3">
            <div className="custom-input-group row">
              <div className="col-sm-6 col-md-6 col-lg-12">
                {(request_type == 1 && LuggageRequest?.status == 0) &&
                  < div className="submite-btn d-lg-flex d-sm-none d-md-flex pb-lg-5 pb-md-1">
                    {/* <button type="submit" class='m-1' onClick={handleShow}> Reject </button> */}
                    <Button
                      onClick={() => {
                        handleSetData('status', 2);
                        setIsShow(1);
                        handleShow();
                      }} style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', margin: '2px' }}>
                      {t('profile.reject_request')}
                    </Button>
                    <Button
                      onClick={() => {
                        handleShow();
                        setIsShow(5);
                      }}
                      style={{ backgroundColor: '#63a103', borderColor: '#63a103', borderRadius: '5px', margin: '2px' }}>
                      {t('profile.accept_request')}
                    </Button>
                    {/* <button type="submit" class='m-1' style={{ backgroundColor: '#63a103', color: '#fff' }}>Accept</button> */}
                  </div>}
                <Modal show={show}
                  onHide={handleClose}
                  size="lg"
                  centered
                >
                  <Modal.Header closeButton>
                    <Modal.Title>{isShow == 1 ? t('profile.reject_confirmation') : isShow == 5 ? t('profile.Accept_Confirmation') : isShow == 4 ? t('profile.Cancel_Order_Confirmation') : t('profile.success')}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body >
                    <Form>
                      <div class="container-fluid">
                        <div class="row d-flex justify-content-center align-items-center pb-4 pt-1">
                          {isShow == 1 ?
                            (<div class=" col mb-lg-0">
                              <h5 className='text-center fw-none'>{t('profile.your_are_about_to_reject_the_request2')}</h5>
                              <h5 className='text-center fw-none'>{t('profile.please_select_a_reason_for_rejection_and_confirm_your_action')}</h5>
                              <div class="pt-2" style={{ borderRadius: '8px' }}>
                                <div class=" justify-content-center d-lg-flex d-md-flex">
                                  <div class="">
                                    <select
                                      name="reason_to_reject_luggage"
                                      onChange={handleChange}
                                      style={{ borderColor: '#ff4838' }}>
                                      <option value={'0'} disabled selected>-{t('profile.select_reasone_to_reject')}-</option>
                                      <option value={'1'} >{t('profile.overWeight')}</option>
                                      <option value={'2'} >{t('profile.i_changed_my_travel_plan')}</option>
                                      <option value={'3'} >{t('profile.improper')}</option>
                                      <option value={'4'} >{t('profile.luggage_not_taken_into_account')}</option>
                                    </select>
                                    <span style={{ color: "red" }}>{error.reason_to_reject_luggage}</span>
                                  </div>
                                </div>
                              </div>
                            </div>) : isShow == 5 ?
                              (<div class=" col mb-lg-0">
                                <h5 className='text-center fw-none'>{t('profile.acceptText1')}</h5>
                                {/* <h5 className='text-center fw-none'>{t('profile.acceptText2')}</h5> */}
                                {/* <div class="pt-2 mt-3 clo-12 row" style={{ alignItems: 'center' }}>
                                  <div className="col-lg-4">
                                  </div>
                                  <div className="col-lg-4" style={{ backgroundColor: '#f2f2f2', border: '1px solid black', padding: '10px' }}>
                                    <p style={{ textDecoration: 'underLine' }}>{t('profile.Price_to_pay')}:</p>
                                    <p>{t('profile.Traveller_fees')}: {LuggageRequest?.luggage_weight}Kg * {LuggageRequest?.price_per_kg}{LuggageRequest?.currency} = {LuggageRequest?.luggage_weight * LuggageRequest?.price_per_kg}{LuggageRequest?.currency}</p>
                                    <p>{t('profile.Service_fees')}:  = {LuggageRequest?.service_fee_luggage}{LuggageRequest?.currency}</p>
                                    <p>{t('profile.Total_fees')}:  = {LuggageRequest?.luggage_weight * LuggageRequest?.price_per_kg + LuggageRequest?.service_fee_luggage}{LuggageRequest?.currency}</p>
                                  </div>
                                  <div className="col-lg-4">
                                  </div>
                                </div> */}
                              </div>) :
                              isShow == 4 ?
                                (<div class="col mb-lg-0">
                                  <h5 className='text-center fw-none pb-3'>{t('profile.your_are_about_to_cancel_the_luggage_sending_request')}</h5>
                                  <div class=" justify-content-center d-lg-flex d-md-flex">
                                    <div class="">
                                      <select style={{ border: '1px solid #ff4838' }}
                                        onChange={handleChange}
                                        name="reason_to_cancel">
                                        <option selected disabled>- {t('profile.select_reasone_to_reject')} -</option>
                                        <option value={5}>{t('profile.price_too_high')}</option>
                                        <option value={6}>{t('profile.I_changed_my_mind')}</option>
                                      </select>
                                      <span style={{ color: "red" }}>{cancleError.reason_to_cancel}</span>
                                    </div>
                                  </div>
                                </div>) :
                                isShow == 5 ?
                                  (<div class="col mb-lg-0">
                                    <h5 className='text-center fw-none pb-3'>{t('profile.the_traveller_successfully_notified_cancelation')}</h5>
                                  </div>) :
                                  (<div class=" col mb-lg-0">
                                    <p className='text-center fw-none'>{t('profile.successText')}</p>

                                    <p className='text-center fw-none mt-2'>{t('profile.successText1')}</p>
                                    <p className='text-center fw-none'>{t('profile.successText2')}</p>
                                    <p className='text-center fw-none mt-2'>{t('profile.successText3')}</p>
                                    <p className='text-center fw-none mt-3'>{t('profile.successText4')}</p>
                                  </div>)
                          }
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className='justify-content-center' >
                    <Button onClick={() => isShow == 1 ? requestReceived(data) : isShow == 5 ? acceptRequest() : isShow == 4 ? cancleOrder() : handleClose()} style={{ backgroundColor: '#ff4838', borderColor: '#ff4838' }}>
                      {isShow == 1 ? t('profile.reject_request') : isShow == 5 ? t('profile.I_Accept_Confirm') : isShow == 4 ? t('profile.cancle_order') : t('close')}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="row">
              {request_type != 1 ?
                <div class='col-lg-12 col-md-6 col-12  pt-2'>
                  <div class="earning pb-2" style={{ backgroundColor: 'white', borderColor: '#ff4838', border: '2px solid' }}>
                    <div class="plan" style={{ background: 'white' }}>
                      <header style={{ padding: '11px 10px' }}><i class="bi bi-currency-dollar1"></i>
                        <h6 class="plan-title" style={{ color: 'black' }}>{t('profile.status')}:</h6>
                        <div class="plan-cost"><span class="plan-price fs-6 d-flex align-items-center" style={{ color: LuggageRequest?.status == 1 ? '#63a103' : LuggageRequest?.status == 2 ? 'red' : '#f39c12' }}> {LuggageRequest?.status !== 0 && <i class={LuggageRequest?.status == 1 ? "bi bi-check-circle" : LuggageRequest?.status == 2 ? "bi bi-x-circle " : ''} style={{ color: LuggageRequest?.status == 1 ? ' #63a103' : 'red', fontSize: '30px' }} />}{LuggageRequest?.status == 1 ? t('profile.accepted') : LuggageRequest?.status == 2 ? t('profile.rejected') : LuggageRequest?.status == 0 ? t('profile.Pending_acceptances') : ''}</span></div>
                      </header>
                    </div>
                  </div>
                  {LuggageRequest?.reason_of_cancel == 0 &&
                    <div className="submite-btn">
                      {(request_type == 2 && (LuggageRequest?.status == 0 || LuggageRequest?.status == 1)) &&
                        <Button
                          onClick={() => {
                            setIsShow(4);
                            handleShow();
                          }} style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', margin: '10px' }}>
                          {t('profile.cancle_order')}
                        </Button>
                      }
                    </div>
                  }
                </div> :
                <div class='col-lg-12 col-md-6 col-12 pt-2'>
                  <div class="earning pb-2" style={{ backgroundColor: 'white', borderColor: '#ff4838', border: '2px solid' }}>
                    <div class="plan" style={{ background: 'white' }}>
                      <header style={{ padding: '11px 10px' }}><i class="bi bi-currency-dollar1"></i>
                        <h6 class="plan-title" style={{ color: 'black' }}>{t('profile.status')}:</h6>
                        <div class="plan-cost"><span class="plan-price fs-6 d-flex align-items-center" style={{ color: LuggageRequest?.status == 1 ? '#63a103' : LuggageRequest?.status == 2 ? 'red' : '#f39c12' }}> {<i class={LuggageRequest?.status == 1 ? "bi bi-check-circle" : LuggageRequest?.status == 2 ? "bi bi-x-circle " : 'bi bi-hourglass-split'} style={{ color: LuggageRequest?.status == 1 ? ' #63a103' : LuggageRequest?.status == 0 ? '#f39c12' : 'red', fontSize: '30px' }} />}{LuggageRequest?.status == 1 ? t('profile.accepted') : LuggageRequest?.status == 2 ? t('profile.rejected') : LuggageRequest?.status == 0 ? t('profile.pending') : ''}</span></div>
                      </header>
                    </div>
                  </div>
                </div>}
            </div>
            <div className="row pt-5">
              <p class='fs-5 pb-3 text-decoration-underline'>{request_type==1?t('profile.request_received_from'):t('profile.request_sent_to')}:</p>
              <h6 class='pb-1'><i class="bi bi-person-fill"></i> <span style={{ color: '#1e98d7' }}>{request_type == 1 ? LuggageRequest?.traveler_name : LuggageRequest?.travel_user_name}</span></h6>
              <p style={{color:'black'}}>
                <i class="bi bi-person-fill" style={{visibility:'hidden'}}></i>{" "}
                <span style={{ color: "black", fontSize:'14px'}}>{t('form.Member_since')}:{" "}  
                  {request_type == 1
                    ? LuggageRequest?.member_since_sender?.slice(0,10)
                    : LuggageRequest?.member_since_receiver?.slice(0,10)}
                </span>
              </p>
            </div>
          </div>
        </div>
      </div >
    </>
  );
}

export default SendingRequestWrapperArea;
