import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from "react-router-dom";
import ReactPlayer from 'react-player'
import { FaXTwitter } from "react-icons/fa6";
function TopDestination() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const { t } = useTranslation();
  return (
    <>
      <div className="guide-area guide-style-one pt-50" style={{background:'#f4f4f4',paddingBottom:'20px'}}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-6 col-sm-10">
              <div className="section-head-alpha text-center mx-auto">
                <h2>{t('homePage.how_it_works')}</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-4 col-md-6 col-sm-4" >
              <div className="guide-card-beta " style={{ height: '400px', width: '100%' }}>
                <div className="guide-image" style={{ height: '400px', width: '100%' }}>
                  <img
                    src={process.env.PUBLIC_URL + "/images/guide/CHAMEL.png"}
                    alt="guide images"
                    style={{ aspectRatio: "2/2", objectFit: "contain" }}
                  />
                  <ul className="guide-social-links d-flex justify-content-center flex-column gap-3">
                    <li>
                      <a href='https://www.instagram.com/cobagage_officiel/?igshid=YzAwZjE1ZTI0Zg%3D%3D&utm_source=qr'>
                      {/* <Link to={""}> */}
                        <i className="bx bxl-instagram" />
                      {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <a href='https://www.facebook.com/'>
                      {/* <Link to={"#"}> */}
                        <i className="bx bxl-facebook" />
                      {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <a href='https://twitter.com/CobagageSite'>
                      {/* <Link to={"#"}> */}
                      <FaXTwitter size={10} />
                      {/* </Link> */}
                      </a>
                    </li>
                    <li>
                      <Link to={"#"}>
                        <i className="bx bxl-whatsapp" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className=" col-lg-4 col-md-6 col-sm-4" >
              <div style={{ height: "100%" }}>
                <ReactPlayer height='100%' width='100%' url='https://www.youtube.com/watch?v=6Bs6qghgmwI' style={{ height: '300px', width: '450px' }} />
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-4 package-card-delta p-5">
              <div className="row" >
                <div className="col-lg-3 col-md-3 col-3" style={{ textAlign: 'end' }}><img src={process.env.PUBLIC_URL + "/images/icons/Valid_icon.png"} style={{ height: '30px', width: '25px' }} /></div>
                <div className="col-lg-9 col-md-9 col-9"><h4>{t('homePage.economics')}</h4></div>
              </div>
              <div className="row" style={{ height: '35px' }} />
              <div className="row ">
                <div className="col-md-3 col-3 col-lg-3" style={{ textAlign: 'end' }}><img src={process.env.PUBLIC_URL + "/images/icons/Valid_icon.png"} style={{ height: '30px', width: '25px' }} /></div>
                <div className="col-md-9 col-9 col-lg-9"><h4>RAPIDE</h4></div>
              </div>
              <div className="row" style={{ height: '35px' }} />
              <div className="row">
                <div className="col-md-3 col-3 col-lg-3" style={{ textAlign: 'end' }}><img src={process.env.PUBLIC_URL + "/images/icons/Valid_icon.png"} style={{ height: '30px', width: '25px' }} /></div>
                <div className="col-md-9 col-9 col-lg-9"><h4>{t('homePage.ecological')}</h4></div>
              </div>
              <div className="row" style={{ height: '35px' }} />
              <div className="row">
                <div className="col-md-3 col-3 col-lg-3" style={{ textAlign: 'end' }}><img src={process.env.PUBLIC_URL + "/images/icons/Valid_icon.png"} style={{ height: '30px', width: '25px' }} /></div>
                <div className="col-md-9 col-9 col-lg-9"><h4>{t('homePage.secured')}</h4></div>
              </div>
              <div className="row" style={{ height: '35px' }} />
              <div className="row">
              <div className="col-md-3 col-3 col-lg-3" style={{ textAlign: 'end' }}></div>
                <div className="col-md-9 col-9 col-lg-9"><h4 style={{color:'#0d6efd',textDecorationLine:'underline'}}><Link to={'/working'} onClick={scrollTop}>{t('homePage.read_more')}</Link></h4> </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export default TopDestination