import axios from '../plugin/axios';

const travel = {
    list(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Travel/GetAll', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    GetAllUser() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Travel/GetAllByUser')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Travel/Create', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Travel/GetByid/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    StatusReject(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Travel/GetStatus/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Travel/Update', id, data)
                .then(({ data }) => {
                    resolve(data);
                    console.log(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Travel/Delete/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Travel/ValidatePost', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default travel;
