import React, { useState, useContext } from "react";
import articles from "../../../../models/articles";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../plugin/validator";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import notistack from "../../../../plugin/notistack";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import articlesDonation from "../../../../models/articlesDonation";
import AppContext from "../../../../store/context/app-context";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
export default function ArticleCard(props) {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const [data, setData] = useState(props?.item);
  const [validator, showMessage] = useValidator();
  const [image, setImage] = useState(props?.item?.images);
  const [decodedString, setdecodedString] = useState([]);
  const [editImg, setEditImg] = useState();
  const [show, setShow] = useState(false);
  const imageName = ["Exterior 1", "Exterior 2", "Interior 1", "Interior 2"];
  const contextObj = useContext(AppContext);

  useEffect(() => {
    // getData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const error = {
    title: validator.message(t("title"), data.title, "required"),
    price: validator.message(
      t("price"),
      data.price,
      props.type == 4 ? "required" : ""
    ),
    type: validator.message(t("type"), data.type, "required"),
    quantity: validator.message(t("quantity"), data.quantity, "required"),
    weight: validator.message(t("weight"), data.weight, "required"),
    location: validator.message(t("location"), data.location, "required"),
    terms_and_condition: validator.message(
      t("terms_and_condition"),
      data.terms_and_condition,
      "required"
    ),
    delivery_place: validator.message(
      t("delivery_place"),
      data.delivery_place,
      "required"
    ),
    delivery_date: validator.message(
      t("delivery_date "),
      data.delivery_date,
      "required"
    ),
  };

  const handleSubmit = (event) => {
    if (validator.allValid()) {
      if (data.gallery.length >= 3) {
        if (props.type == 4) {
          articles
            .update(props?.item?.id, data)
            .then((response) => {
              notistack.success(t("form.updated_Successfully"));
              setData(response);
              setImage(response?.images);
              props.getData();
              handleClose();
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          articlesDonation
            .update(props?.item?.id, data)
            .then((response) => {
              notistack.success(t("form.updated_Successfully"));
              setData(response);
              setImage(response?.images);
              props.getData();
              handleClose();
            })
            .catch((error) => {
              console.log(error);
            });
        }
      } else {
        notistack.error(t("form.minimum_3maximum_4images"));
      }
    } else {
      showMessage(true);
    }
  };

  const convertToBase64 = (e, index) => {
    let tempFile = e.target.files[0];
    if (tempFile.size > 4000000) {
      notistack.error(t("form.File_Size"));
      return false;
    } else {
      let arrUrl = [...image];
      arrUrl[editImg] = URL.createObjectURL(e.target.files[0]);
      setImage(arrUrl);
      getBase64Image(tempFile)
        .then((responseBase64) => {
          let arr = data?.gallery ? [...data?.gallery] : ["", "", "", ""];
          // if (type == 4) {
          //     arr.push(responseBase64);
          // } else {
          //     arr[editImg == 0 ? 0 : editImg == 1 ? 1 : editImg == 2 ? 2 : 3] = responseBase64;
          // }
          arr[editImg] = responseBase64;
          handleSetData("gallery", arr);
        })
        .catch((err) => {
          console.log("Error While convert to base64: ", err);
        });
    }
  };
  const getBase64Image = (fileParam) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const convert = () => {
    let arr = [];
    image?.map((item) => {
      fetch(item)
        .then((response) => {
          let blob = response.blob();
          blob.then((blob) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              let baseURL = reader.result;
              arr.push(baseURL);
              console.log("baseURL", baseURL);
              setdecodedString(baseURL);
            };
          });
        })
        .catch((error) => {
          console.log("converterrorArticle:", error);
        });
    });
    handleSetData("gallery", arr);
  };
  const deleteTravel = () => {
    if (props.type == 4) {
      articles
        .delete(props.item?.id)
        .then((responseData) => {
          props.getData();
          notistack.success(t("form.Your_deleted_Successfully"));
        })
        .catch((error) => {
          console.log(error);
          notistack.error(error?.data.message);
        });
    } else {
      articlesDonation
        .delete(props.item?.id)
        .then((responseData) => {
          props.getData();
          notistack.success(t("form.Your_deleted_Successfully"));
        })
        .catch((error) => {
          console.log(error);
          notistack.error(error?.data.message);
        });
    }
  };
  const handleClose = () => {
    // setData(restLuggageData);
    // setImage(props?.item?.image);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target?.value?.slice(1);
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <div className="col-lg-5 col-md-6 mb-4">
      <div className="package-card-delta">
        <div className="package-thumb">
          <div className="guide-card-beta ">
            <div className="guide-image">
              <Link to={`#`}>
                <img
                  src={props.item.images[0]}
                  alt=""
                  style={{
                    aspectRatio: "3/2",
                    objectFit: "contain",
                    padding: "15px",
                    borderRadius: "20px",
                  }}
                />
              </Link>
              <p
                className="card-lavel me-2"
                style={{
                  fontSize: "10px",
                  textAlign: "end",
                  minWidth: "10px",
                  borderRadius: "10px",
                  padding: "0px 6px 0px 6px",
                }}
              >
                {props?.item?.verification == false ? t("cobagage.unverified") : t("cobagage.verified")}
              </p>
            </div>
          </div>
        </div>
        {/* <div className=' pt-2 ps-3 pe-2' style={{ backgroundColor: 'white', borderTopRightRadius: '7px', borderTopLeftRadius: '7px' }}>
                    <h6 className='col-lg-12'><Link to={``} style={{ fontSize: '18px', color: 'black', fontWeight: '600' }}>{props?.item?.title}</Link></h6>
                </div> */}
        <div className="package-card-body icons_container" style={{ padding: "12px 12px" }}>
          <div class="pb-2 pt-2">
            <div>
              <h6 className="col-lg-12 pb-2 icons_container icon facebook">
                <Link
                  to={``}
                  style={{
                    fontSize: "18px",
                    color: "black",
                    fontWeight: "600",
                  }}
                >
                  {props?.item?.title?.length > 15 ? (props?.item?.title?.slice(0, 15)?.trimEnd() + '...') : props?.item?.title}
                  <div class="tooltip" >
                    {props?.item?.title}
                  </div>
                </Link>
              </h6>
            </div>
            <table
              className="col-lg-12 col-12 col-md-12 col-sm-12"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              {props.type == 4 && (
                <tr>
                  <td style={{ color: "#ff4838" }}>{t("form.Weight")}</td>
                  <td class="text-end" style={{ color: "#ff4838" }}>
                    {t("profile.price")}
                  </td>
                </tr>
              )}
              {props.type == 4 && (
                <tr>
                  <td> {props?.item?.weight} Kg</td>
                  <td class="text-end">{props?.item?.price} {contextObj?.loginUser?.symbol}</td>
                </tr>
              )}
              {props.type == 5 && (
                <tr>
                  <td style={{ color: "#ff4838" }}>{t("form.Weight")}</td>
                  <td class="text-end">{props?.item?.weight} Kg</td>
                </tr>
              )}
              <tr>
                <td style={{ color: "#ff4838", opacity: 0.8 }}>
                  {t("form.location")}:
                </td>
                <td class="text-end">{props?.item?.location}</td>
              </tr>
              <tr>
                <td style={{ color: "#ff4838", opacity: 0.8 }}>
                  {t("form.Posted_Date")}:
                </td>
                <td class="text-end">
                  {moment(props?.item?.updated_at?.slice(0, 10)).format(
                    "DD/MM/YYYY"
                  )}
                </td>
              </tr>
            </table>
          </div>
          {props?.isShow == 1 && (
            <div className="p-card-bottom">
              <div className="book-btn">
                <Link
                  onClick={() => {
                    convert();
                    handleShow();
                  }}
                  // to={`${process.env.PUBLIC_URL}/ad_details/${item?.id}/${props?.type}`}
                  to={`#`}
                  style={{
                    fontSize: "11px",
                    fontWeight: "600",
                    color: "green",
                  }}
                >
                  <i class="bi bi-pencil-fill" style={{ color: "green" }} />{" "}
                  {t("profile.edit")}
                </Link>
              </div>
              <i
                class="bi bi-trash"
                style={{ color: "red", fontSize: "20px", cursor: "pointer" }}
                onClick={() => deleteTravel()}
              />
            </div>
          )}
          <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
              <Modal.Title>
                {props.type == 4
                  ? t("form.Article_Announcement")
                  : t("form.Article_Donation_Announcement")}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <form
                onSubmit={(e) => e.preventDefault()}
                action="#"
                method="post"
              >
                <div className="">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.title")}</label>
                        <span style={{ color: "red" }}>*</span>
                        <input
                          required
                          type="text"
                          placeholder={t("form.title")}
                          id="title"
                          onChange={handleChange}
                          name="title"
                          onInput={onInputUppercase}
                          value={data.title}
                        />
                        <span style={{ color: "red" }}>{error.title}</span>
                        {/* <span style={{ color: "red" }}>{error.title}</span> */}
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.Type_Article")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          id="type"
                          onChange={handleChange}
                          name="type"
                          value={data.type ? data.type : 0}
                        >
                          <option value={0} selected disabled>
                            {t("form.Type_Article")}
                          </option>
                          <option value={1}>{t("form.New_Never_Used")}</option>
                          <option value={2}>
                            {t("form.Used_Secound_hand")}
                          </option>
                        </select>
                        <spna>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </spna>
                        <span style={{ color: "red" }}>{error.type}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-12" style={{ marginTop: "20px" }}>
                    <label htmlFor="name">
                      {t("form.Luggage_Dimensions")}:
                    </label>
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.height")}</label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder={t("form.height")}
                          id="height"
                          onChange={handleChange}
                          name="height"
                          value={data.height}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: 15,
                            bottom: "15px",
                            fontWeight: "500",
                            opacity: 0.6,
                          }}
                        >
                          cm
                        </span>
                        {/* <span style={{ color: "red" }}>{error.height}</span> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.length")}</label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder="length"
                          id="length"
                          onChange={handleChange}
                          name="length"
                          value={data.length}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: 15,
                            bottom: "15px",
                            fontWeight: "500",
                            opacity: 0.6,
                          }}
                        >
                          cm
                        </span>
                        {/* <span style={{ color: "red" }}>{error.length}</span> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.width")}</label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder="width"
                          id="width"
                          onChange={handleChange}
                          name="width"
                          value={data.width}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: 15,
                            bottom: "15px",
                            fontWeight: "500",
                            opacity: 0.6,
                          }}
                        >
                          cm
                        </span>
                        {/* <span style={{ color: "red" }}>{error.width}</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-12">
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.Weight")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder={t("form.Weight")}
                          id="weight"
                          onChange={handleChange}
                          name="weight"
                          required
                          value={data.weight}
                        />
                        <span
                          style={{
                            position: "absolute",
                            right: 15,
                            bottom: error.weight ? "37px" : "15px",
                            fontWeight: "500",
                            opacity: 0.6,
                          }}
                        >
                          kg
                        </span>
                        <span style={{ color: "red" }}>{error.weight}</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.Colour")}</label>
                        <input
                          type="text"
                          placeholder={t("form.Colour")}
                          id="color"
                          onChange={handleChange}
                          name="color"
                          value={data.color}
                        />
                        {/* <span style={{ color: "red" }}>{error.color}</span> */}
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.Volume")}</label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder={t("form.Volume")}
                          id="volume"
                          onChange={handleChange}
                          name="volume"
                          value={data.volume}
                        />
                        {/* <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span> */}
                        {/* <span style={{ color: "red" }}>{error.volume}</span> */}
                      </div>
                    </div>
                  </div>
                  <div className="row col-lg-12">
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.quantity")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          className="numberType"
                          type="number"
                          onWheel={numberInputOnWheelPreventChange}
                          placeholder={t("form.quantity")}
                          id="quantity"
                          onChange={handleChange}
                          name="quantity"
                          required
                          value={data.quantity}
                        />
                        <span style={{ color: "red" }}>{error.quantity}</span>
                      </div>
                    </div>
                    <div className="col-12 col-sm-4 ">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.Location_of_luggage")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder={t("location")}
                          id="location"
                          onChange={handleChange}
                          name="location"
                          onInput={onInputUppercase}
                          value={data.location}
                        />
                        <span style={{ color: "red" }}>{error.location}</span>
                      </div>
                    </div>
                    {props.type == 4 ? (
                      <div className="col-12 col-sm-4 ">
                        <div className="custom-input-group">
                          <label htmlFor="name">
                            {t("form.price")}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="numberType"
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            placeholder={t("form.price")}
                            id="price"
                            onChange={handleChange}
                            name="price"
                            required
                            value={data.price}
                          />
                          <span style={{ position: 'absolute', right: 15, bottom: (error.price ? '35px' : '15px'), fontWeight: '500', opacity: 0.6 }}>{contextObj?.loginUser?.symbol}</span>
                          <span style={{ color: "red" }}>{error.price}</span>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                  <div className="row">
                    <div
                      className="row col-lg-12"
                      style={{ marginTop: "20px" }}
                    >
                      <label htmlFor="name">{t("form.DELIVERY_INFOS")}:</label>
                    </div>
                    <div className="col-lg-4" >
                      <div className="custom-input-group">
                        <label htmlFor="name" style={{ display: "block" }}>
                          {t("form.delivery_date")}
                          <span style={{ color: "red" }}>*</span>
                        </label>

                        <div className="customDatePickerWidth">
                          <DatePicker
                            selected={moment(
                              data.delivery_date,
                              "DD/MM/YYYY"
                            ).toDate()} // Use a format that DatePicker can parse
                            dateFormat={
                              i18n.language === "frFR"
                                ? "dd/MM/yyyy"
                                : "MM/dd/yyyy"
                            } // Display format
                            onChange={(date) =>
                              handleSetData(
                                "delivery_date",
                                moment(date).format("DD/MM/YYYY")
                              )
                            }
                            minDate={new Date()}
                            className="px-3"
                          />
                        </div>
                        <span style={{ color: "red" }}>
                          {error.delivery_date}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="custom-input-group ">
                        <label htmlFor="name">
                          {t("form.delivery_place")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <input
                          required
                          type="text"
                          placeholder={t("form.enter_delivery_place")}
                          id="delivery_place"
                          onChange={handleChange}
                          name="delivery_place"
                          value={data.delivery_place}
                        />
                        <span style={{ color: "red" }}>
                          {error.delivery_place}
                        </span>
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.Delivery_by")}<span style={{ color: 'red' }}>*</span></label>
                        <select
                          id="vehicle_type"
                          onChange={handleChange}
                          name='vehicle_type'
                          value={data.vehicle_type ? data.vehicle_type : 0}
                        >
                          <option value={0} selected disabled>{t("form.type_of_transport")}</option>
                          {
                            i18n.language === 'frFR' ?
                              (contextObj.vehicleTypeFr?.map((item, index) => (
                                <option value={item.value} key={index}>{item.name}</option>
                              ))) :
                              (contextObj.vehicleTypeEn?.map((item, index) => (
                                <option value={item.value} key={index}>{item.name}</option>
                              )))
                          }
                        </select>
                        <span><i class="bi bi-caret-down-fill" style={{ marginTop: error.vehicle_type ? "0px" : "21px" }}></i></span>
                        <span style={{ color: "red" }}>{error.vehicle_type}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-12">
                      <div>
                        <h3>
                          {t("menu.gallery")} (
                          {t("form.Upload_image_in_1280_720_dimension")})
                        </h3>
                        <br />
                        <label htmlFor="name" style={{ color: "#ff4838" }}>
                          {t("form.You_can_add_4_images_maximum")}
                        </label>
                        {/* <input
                                                    id="images"
                                                    onChange={(e) => {
                                                        if (data.gallery.length == 4) {
                                                            notistack.error(t("form.You_can_add_4_images_maximum"))
                                                        }
                                                        else {
                                                            convertToBase64(e, 4);
                                                            let arr = image ? [...image] : ['0', '0'];
                                                            arr.push(URL.createObjectURL(e.target.files[0]));
                                                            setImage(arr);
                                                        }
                                                    }
                                                    }
                                                    name='images'
                                                    // value={data.images}
                                                    type="file" multiple accept="gif|jpg|png|jpeg"
                                                /> */}
                      </div>
                    </div>
                    {image && (
                      <div className="col-lg-12 row pt-1">
                        <div
                          className="col-12 col-lg-3 col-sm-3"
                          style={{ border: "1px dotted" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {" "}
                            {t("profile.Exterior_1")}
                          </span>
                          <img
                            src={image[0]}
                            style={{
                              aspectRatio: "4/2",
                              objectFit: "contain",
                              width: "100%",
                            }}
                          ></img>
                          <button
                            type="button"
                            onClick={() => setEditImg(0)}
                            style={{ border: "0px", backgroundColor: "#fff" }}
                          >
                            <label for="file">
                              <i
                                class="bi bi-pencil-square"
                                style={{ color: "green", cursor: "pointer" }}
                              />
                            </label>
                          </button>
                          <input
                            type="file"
                            id="file"
                            className="d-none"
                            onChange={(e) => {
                              convertToBase64(e, 5);
                            }}
                          />
                        </div>
                        <div
                          className="col-12 col-lg-3 col-sm-3"
                          style={{ border: "1px dotted" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {t("profile.Exterior_2")}
                          </span>
                          <img
                            src={image[1]}
                            style={{
                              aspectRatio: "4/2",
                              objectFit: "contain",
                              width: "100%",
                            }}
                          ></img>
                          <button
                            type="button"
                            onClick={() => setEditImg(1)}
                            style={{ border: "0px", backgroundColor: "#fff" }}
                          >
                            <label for="file2">
                              <i
                                class="bi bi-pencil-square"
                                style={{ color: "green", cursor: "pointer" }}
                              />
                            </label>
                          </button>
                          <input
                            type="file"
                            id="file2"
                            className="d-none"
                            onChange={(e) => {
                              convertToBase64(e, 5);
                            }}
                          />
                        </div>
                        <div
                          className="col-12 col-lg-3 col-sm-3"
                          style={{ border: "1px dotted" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {t("profile.Interior_1")}
                          </span>
                          <img
                            src={image[2]}
                            style={{
                              aspectRatio: "4/2",
                              objectFit: "contain",
                              width: "100%",
                            }}
                          ></img>
                          <button
                            type="button"
                            onClick={() => setEditImg(2)}
                            style={{ border: "0px", backgroundColor: "#fff" }}
                          >
                            <label for="file3">
                              <i
                                class="bi bi-pencil-square"
                                style={{ color: "green", cursor: "pointer" }}
                              />
                            </label>
                          </button>
                          <input
                            type="file"
                            id="file3"
                            className="d-none"
                            onChange={(e) => {
                              convertToBase64(e, 5);
                            }}
                          />
                        </div>
                        <div
                          className="col-12 col-lg-3 col-sm-3"
                          style={{ border: "1px dotted" }}
                        >
                          <span
                            style={{
                              display: "flex",
                              justifyContent: "center",
                            }}
                          >
                            {t("profile.Interior_2")}
                          </span>
                          <img
                            src={image[3]}
                            style={{
                              aspectRatio: "4/2",
                              objectFit: "contain",
                              width: "100%",
                            }}
                          ></img>
                          <button
                            type="button"
                            onClick={() => setEditImg(3)}
                            style={{ border: "0px", backgroundColor: "#fff" }}
                          >
                            <label for="file4">
                              <i
                                class="bi bi-pencil-square"
                                style={{ color: "green", cursor: "pointer" }}
                              />
                            </label>
                          </button>
                          <input
                            type="file"
                            id="file4"
                            className="d-none"
                            onChange={(e) => {
                              convertToBase64(e, 5);
                            }}
                          />
                        </div>
                      </div>
                    )}
                    {/* <div className='row'>
                                            {image?.map((item, index) => {
                                                return (
                                                    <div className='col-12 col-lg-3'
                                                        style={{ border: "1px dotted" }}
                                                    >
                                                        <span style={{ display: "flex", justifyContent: "center" }}>{imageName[index]}</span>
                                                        <img src={item}
                                                            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", }}
                                                        ></img>
                                                        <input
                                                            type='file'
                                                            id={`file-${index}`}
                                                            className='d-none'
                                                            onChange={(e) => {
                                                                convertToBase64(e, index);
                                                            }}
                                                        />
                                                        <button type='button' style={{ border: '0px', backgroundColor: '#fff' }}>
                                                            <label for='file'><i class="bi bi-pencil-square" style={{ color: 'green', cursor: 'pointer' }} /></label>
                                                        </button>
                                                    </div>
                                                )
                                            })}
                                        </div> */}
                  </div>
                  <div className="package-sidebar">
                    <aside
                      className="package-widget widget-tour-categoris mt-30"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="widget-body">
                        <div className="">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="cate"
                            checked={data?.terms_and_condition}
                            onChange={(e) => {
                              handleSetData(
                                "terms_and_condition",
                                e.target.checked ? true : false
                              );
                            }}
                          />{" "}
                          <span>{t("form.I_CONFIRM_THAT")}</span>
                        </div>
                      </div>
                    </aside>
                  </div>
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        <button
                          type="submit"
                          disabled={!data.terms_and_condition}
                          onClick={handleSubmit}
                          className="btn btn-secondary"
                        >
                          {t("update")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
              <Button onClick={handleClose}>{t("close")}</Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </div>
  );
}
