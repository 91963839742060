import React, { useEffect } from "react";

function Adcomponent() {

  useEffect(() => {
    // Load the Google AdSense script
    const script = document.createElement('script');
    script.src = "https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3311435370344447";
    script.crossOrigin = "anonymous";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  useEffect(() => {
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }, []);



  return (
    <div style={{ margin: '20px' }}>
      <ins className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3311435370344447"
        data-ad-slot="7489085856"
        data-ad-format="auto"
        data-full-width-responsive="true" />
    </div>
  )
}
export default Adcomponent;