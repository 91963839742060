const cgvu={
title:'General Conditions – Shippers from the website www.cobagage.com applicable from December 1, 2023',
the_general_conditions_are_concluded:'These general conditions are concluded between Cobagage Network, SASU, share capital: €1000, RCS de Paris: 848 506 861, head office: 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, telephone: +336 75 68 10 28, email: lyao@cobagage.com, intra-community VAT number: FR44914381116, hereinafter referred to as “Cobagage Network” and any person, natural or legal, under private or public law, registered on the Site to ship a Good and/or purchase a Product, hereinafter referred to as “the Sender”.',
good:'" GOODS "',
property_of_any_kind_held_by_sender:'property of any kind held by the Sender and sent to a Recipient via a Traveler',
site_content:'“Site Content”',
element_of_any_kind_published_on_site:'elements of any kind published on the Site, whether or not protected by intellectual property rights, such as texts, images, designs, presentations, videos, diagrams, structures, databases or software.',
recipient:'" RECIPIENT "',
any_person_natural_legal:'any person, natural or legal, under private or public law, final recipient of the Goods shipped by the Sender and transported by the Traveler.',
traveler:'“Traveler”',
any_person_natural_or_legal_registered_on_site:'any person, natural or legal, under private or public law, registered on the Site to transport Goods and/or sell Products.',
internet_user:'“Internet user”',
any_person_connecting_to_site:'any person, natural or legal, under private or public law, connecting to the Site.',
product:'" Product "',
goods_of_any_kind:'goods of any kind sold on the Site by a Traveler to a Sender.',
service:'" Service "',
service_offered_by_cobagage_on_site:'service offered by Cobagage on the Site and consisting of the provision of an online platform allowing the shipment of Goods and/or the sale of Products between Travelers and the Shipper.',
site:'" Site "',
website_accessible_at_url:'website accessible at the URL www.Cobagage.com, as well as the subsites, mirror sites, portals and URL variations relating thereto.',
scope:'SCOPE',
the_site_is_freely_accessible_to_any:'The Site is freely accessible to any Sender. Browsing the Site implies acceptance by any Sender of these general conditions. Simply connecting to the Site, by any means whatsoever, in particular via a robot or a browser, will constitute full acceptance of these general conditions.When registering on the Site, this acceptance will be confirmed by checking the box corresponding to the following sentence: “I acknowledge having read and accepted the general conditions of sale and use”. The Sender acknowledges having read them fully and accepts them without restriction.',
checking_the_above_mentioned_boxes_will_be_deemed:"Checking the above-mentioned box will be deemed to have the same value as a handwritten signature from the Sender. The Shipper recognizes the evidentiary value of Cobagage's automatic check-in systems and, unless it provides proof to the contrary, it waives the right to challenge them in the event of a dispute.",
these_general_conditions_are_applicable_to:'These general conditions are applicable to relations between the parties to the exclusion of all other conditions, and in particular those of the Shipper.',
acceptance_of_these_general_conditions:'Acceptance of these general conditions assumes on the part of the Shippers that they have the necessary legal capacity for this, or failing that they have the authorization of a guardian or curator if they are incapable. , their legal representative if they are minors, or that they hold a mandate if they act on behalf of a legal entity.',
purpose_of_the_site:'PURPOSE OF THE SITE',
the_purpose_of_site_is_to_provide_an:'The purpose of the Site is to provide an online electronic platform allowing the shipment of Goods and/or the sale of Products between Shippers and Travelers.',
cobagaging_obligation:'COBAGGING OBLIGATIONS',
provision_of_the_platform:'Provision of the platform',
cobagage_undertakes_to_provide_the_traveller_with_an:'Cobagage undertakes to provide the Traveler with an online platform allowing them to offer the transport of Goods and/or sell their Products',
site_maintenance:'Site Maintenance',
cobagage_undertakes_to_host_an_site:'Cobagage undertakes to host and maintain the Site at all times, outside of maintenance periods.',
in_this_regard_cobagage_will_bear_all:'In this regard, Cobagage will bear all costs incurred in the execution of this contract. Cobagage will do its personal business and will bear the burden of all fiscal and parafiscal taxes, as well as all social security contributions incumbent on it in this respect.',
obligation_of_sender:'OBLIGATIONS OF THE SENDER',
publication_of_an_announcement:'Publication of an announcement requesting the transport of a Good',
publication_announcement:'Publication of the announcement',
publication_of_add_is_open_to:'The publication of an ad is open to any Sender. Announcements must be written in correct and understandable French. They must comply with the legal provisions in force and must be free of any discriminatory criteria as to the quality of the possible co-contractor.',
the_sender_acknowledges:'The Sender acknowledges that the publication of its ad on this Site automatically and without intervention from Cobagage results in the publication of said ad on partner and affiliate sites.',
it_is_expressively_brought_to_attention:'It is expressly brought to the attention of the Shippers that Cobagage in no way guarantees the accuracy of the data contained in the advertisements. It is the responsibility of any Traveler interested in the announcement to ensure the accuracy of all this data prior to any effective transaction.',
add_content:'Ad content',
the_sender_must_specify_the_following_request:'The Sender must specify the following information on the advertisement:',
the_place_and_approximate_date_of_departure:'The place and approximate date of departure;',
the_possible_mode_of_transport_desired:'The possible mode of transport desired;',
the_place_time_and_date_of_arrival:'The place, date and time of arrival;',
the_identity_of_recipient:'The identity of the Recipient;',
the_number_of_killogram_necessary:'The number of kilograms necessary for transporting the Good',
purchase_of_product_by_shipper:'Purchase of a Product by a Shipper',
purchase_of_product:'Purchase of a Product',
the_purchase_of_product_on_site_is:'The purchase of a Product on the Site is only made from a Traveler by responding to an ad. By clicking on the button provided for this purpose on the Site, the Sender can purchase a Product at the price proposed by the Traveler.',
aquisition_of_product_as_part_of:'Acquisition of a Product as part of a donation',
the_sender_has_the_possiblity_of_aquiring:'The Sender has the possibility of acquiring a Product as part of a donation. In this case, the Sender must hold a specific subscription taken out on the Site under the conditions provided for in these general conditions.',
validation_of_purchase:'Validation of purchase',
once_order_has_been_placed:'Once the order has been placed, the Shippers will be able to validate it. They will then access a form on which they can either enter their connection details if they already have them, or register on the Site by completing the registration form using their personal information.',
payment_by_sender:'Payment by Sender',
as_soon_as_they_are_connected:'As soon as they are connected or after they have completely completed the registration form, Shippers will be invited to check or modify their delivery and billing details, then will be invited to make their payment by being redirected for this purpose on the secure payment interface including the words “order with payment obligation” or any similar formula.',
confirmation_of_order_by_cobagage:'Confirmation of the order by Cobagage',
once_payment_has_actually_been_received:'Once payment has actually been received by Cobagage, the latter undertakes to acknowledge receipt of it to the Sender electronically, within a maximum period of 24 hours. Within the same period, Cobagage undertakes to send the Shipper an email summarizing the order and confirming the processing, including all the related information.',
Delivery:'Delivery',
delivery_of_the_product_will_be_made:'Delivery of the Product will be made directly by the Traveler.',
taking_out_a_subscription:'Taking out a subscription',
subscription_purchase:'Subscription purchase',
in_order_to_benifit_from_certain_service:'In order to benefit from certain Services offered by Cobagage, Shippers must take out a subscription. In this context, the Shipper must provide its billing information on the form provided for this purpose on the Site. The characteristics of the subscription (in particular its price and initial duration) are specified on the Site.',
subscription_duraion:'Subscription duration',
the_subscription_takes_effect_from:'The subscription takes effect from the date of its subscription by the Sender on the Site. The duration of the subscription is that mentioned on the Site on the day of subscription.The subscription is concluded for a fixed period. At the end of the term, it will not be tacitly renewed',
early_termination_of_subscription:'Early termination of subscription',
in_the_event_of_failure_by_shipper:"In the event of failure by the Shipper to comply with one of the stipulations provided for in Articles 6, 7 and 9 of these general conditions, Cobagage may terminate the subscription and block, if necessary, access to the personal space of the Shipper. 'Sender until said breach is resolved. Termination of the subscription at the fault of the Shipper is without prejudice to any damages to which Cobagage may be entitled due to the Shipper's failure.",
declaration_and_obligation:'DECLARATIONS AND OBLIGATIONS OF THE SHIPPER',
sender_information:'Sender Information',
the_shipper_declares_that:'The Shipper declares that it has received from Cobagage all useful explanations and details which may enable it to use the Services covered by this contract.',
the_shipper_acknowledges_that_its_needs:'The Shipper acknowledges that its needs and the Services offered by Cobagage are appropriate and that it has subscribed to the contract with full knowledge of the facts and having all the necessary information allowing it to produce free and informed consent.',
the_shipper_undertakes_to_inform:'The Shipper undertakes to inform Cobagage of any circumstances likely to affect the execution of this contract as soon as it becomes aware of them.',
authorization_and_declarion:'Authorizations and declarations',
the_sender_is_solely_responsible_for:'The Sender is solely responsible for authorizations and declarations relating to the use of the Services. The Sender declares that it has the necessary rights and authorizations for this purpose. Where applicable, the Shipper declares that it has previously carried out all necessary steps, such as authorization requests and administrative declarations.',
the_lack_of_such_declarion:'The lack of such declarations and authorizations may in no case call into question the validity of this contract. In particular, the Shipper will remain required to pay Cobagage the amounts due. The Shipper guarantees Cobagage against any recourse that may be taken against it in the event of failure to make such declarations and authorizations.',
charter_of_good_conductor:'Charter of good conduct',
the_sender_undertakes_not_to_publish:'The Sender undertakes not to publish any advertisement on the Site which may:',
harm_or_make_comment:'Harm or make comments contrary to public order, good morals or which may offend the sensibilities of minors;',
infringe_in_any_right:'Infringe in any way the rights to reputation, privacy, rights or image of a third party;',
be_denigrating_defamatory:'Be denigrating, defamatory, harm the image or reputation of a brand or any natural or legal person, in any way whatsoever;',
make_comment_of_a_pedophilic:'Make comments of a pornographic or pedophilic nature;',
undermine_the_security_or_integraty:'Undermine the security or integrity of a State or territory, whatever it may be',
allows_third_parties_to_obtain:'Allow third parties to obtain pirated software, software serial numbers or any software that may harm or infringe, in any way, the rights or property of third parties;',
the_intellectual_property:'Infringe the intellectual property rights of any person;',
insite_hatred_voilence:'Incite hatred, violence, suicide, racism, anti-Semitism, xenophobia, homophobia, advocate war crimes or crimes against humanity;',
insite_to_commit_a_crome:'Incite to commit a crime, misdemeanor or act of terrorism;',
insite_descrimination_against:'Incite discrimination against a person or group of people because of their ethnicity, religion, race, or because of their sexual orientation or disability.',
advice_a_questionable:'advise a questionable or fraudulent practice.',
any_processing_transmission:'Any processing, transmission, publication, distribution or representation of advertisements by the Sender is carried out under its sole and entire responsibility. The Sender undertakes not to hinder or disrupt the Site and Cobagage servers and to comply with the required conditions, procedures and general rules communicated to it by Cobagage for the publication of advertisements.',
any_illegal_or_generally:"Any illegal or generally unauthorized use of the Site will result in the immediate deletion of the Sender's account, without prejudice to any possible damages to which Cobagage may be entitled.",
the_shipper_therefore_guarentees:'The Shipper therefore guarantees Cobagage against any damage that may be caused to it as a result of its use of the Site, including any legal and procedural costs, and undertakes in this respect to intervene in any legal proceedings initiated. against him due to his use of the Site.',
price_and_payment:'PRICE - PAYMENT',
price:'Price',
the_applicable_price_are_those:'The applicable prices are those displayed on the Site on the day of the order. These prices may be modified at any time by Travelers in the case of Products and by Cobagage in the case of subscriptions. The prices displayed are only valid on the day of the order and do not apply for the future.',
the_price_indicated_on_site:'The prices indicated on the Site are in euros, all taxes included, excluding delivery costs.',
payment_terms:'Payment terms',
shpper_can_make_payment_by_credit_card:"The Shipper can make payment by credit card. Payments by credit card are made using secure transactions provided by Qonto and/or Revolut.In the context of bank card payments, Cobagage does not have access to any data relating to the Shipper's means of payment. Payment is made directly into the hands of the banking institution.",
billing:'Billing',
cobagage_will_make_or_send:'Cobagage will send or make available to the Sender an invoice electronically after each payment. The Sender expressly agrees to receive invoices electronically.',
failure_to_pay:'Failure to pay',
agreed_payment_date_can_not_be_delayed:'Agreed payment dates cannot be delayed under any circumstances, including in the event of a dispute.',
any_sum_not_paid_on_due_date:'Any sum not paid on the due date will give rise, automatically and without formal notice, to the application of late payment penalties calculated on the basis of a rate equal to 3 times the legal interest rate, without this penalty affects the payment of the sums due in principal.',
in_addition_any_late_payment_will_result:'In addition, any late payment will result in the defaulting Shipper being billed for recovery costs in the amount of 40 euros, the immediate payment of all remaining sums due regardless of the agreed deadlines, plus a compensation of 20% of the amount as a penalty clause, as well as the possibility of terminating the contract unilaterally to the detriment of the Shipper. This clause falls within the framework of the provisions of article 1152 of the civil code allowing the judge to reduce the compensation if the judge considers that it is excessive.',
complaint_withdrawal:'COMPLAINT - WITHDRAWAL',
customer_service:'Customer service',
site_customer_service_is_accessible_from:"The Site's customer service is accessible from +336 75 68 10 28 from 10 a.m. to 6 p.m. or by email",
or_by_post_to_the_address:'or by post to the address indicated in article 1 of these general conditions. In these last two cases, Cobagage undertakes to provide a response within 2 working days.',
right_withdrawal_distance:'Right of withdrawal – Distance selling',
the_sell_being_made_between:'The sale being made between Shippers and non-professional Travelers, no right of withdrawal can be exercised.',
personal_space:'PERSONAL SPACE',
creation_of_personal_space:'Creation of personal space',
creation_of_personal_space_is_an_essential:'The creation of a personal space is an essential prerequisite for any publication of an advertisement by a Sender on the Site. To this end, the Sender will be asked to provide a certain amount of personal information. Some of this information is deemed essential for the creation of personal space. The refusal by a Sender to provide said information will have the effect of preventing the creation of the personal space as well as, incidentally, the validation of the ad.',
when_creating_personal_space:"When creating the personal space, the Sender is asked to choose a password. This password guarantees the confidentiality of the information contained in the personal space. The Sender therefore undertakes not to transmit it or communicate it to a third party. Otherwise, Cobagage cannot be held responsible for unauthorized access to a Sender's personal space.",
the_sender_undertakes_to_carry_out:'The Sender undertakes to carry out regular verification of the data which concerns him and to carry out the necessary updates and modifications online, from his personal space.',
content_of_personal_space:'Content of personal space',
generalities:'Generality',
the_personal_space_allows_the_sender:'The personal space allows the Sender to consult and follow all his advertisements published on the Site.',
the_pages_relating_to_personal_spaces_are:'The pages relating to personal spaces are freely printable by the account holder in question, but in no way constitute admissible evidence in a court of law. They are only of an informative nature intended to ensure efficient management of its orders by the Shipper.',
cobagage_undertakes_to_securely_stire_all:'Cobagage undertakes to securely store all contractual elements whose retention is required by law or regulations in force.',
posting_a_public_profile:'Posting a Public Profile',
sender_are_offered_option_of_creating:'Senders are offered the option of creating a public profile on the Site. In this context, Senders will be able to provide a certain amount of personal information concerning them. All public profiles will be subject to prior validation by Cobagage or its team of moderators before their first posting online.',
it_is_expressively_understood_that_the_sender:"It is expressly understood that the Sender, by deciding to subscribe to the services offered by Cobagage and by completing the form relating to his public profile, acknowledges that this data, and in particular his photograph, is likely to be published on the Site and disseminated as such with other Senders and Internet Users. The simple fact of providing this information and completing the fields of the public profile creation form will constitute an express manifestation of the Sender's desire to publish said information on the Site.Senders will have the option to stop the distribution of their public profile. To do this, they must request the deletion of their Sender account and the termination of services, following the procedure provided for this purpose. The suspension of the distribution of the public profile will be effective within a maximum period of 3 working days from receipt of the request by Cobagage.",
internal_messeging:'Internal messaging',
an_internal_private_messeging_service:'An internal private messaging service is made available to Senders. This system is reserved for Senders and secrecy of correspondence applies to it.',
the_content_of_inboxes_and_outgoings:'The contents of inboxes and outgoing boxes are not subject to any conservation guarantee on the part of Cobagage and it is up to the Senders to save said contents. The loss of this content, whatever the cause, cannot constitute damage to the Sender who will not be able to claim any compensation as a result.',
any_sender_who_is_victim_of_abuse:'Any Sender who is the victim of abuse (spam, unwanted advertising or other) may inform Cobagage who will take all necessary measures.',
contribution_from_sender:'Contributions from Senders',
shipper_are_offered_the_option_of:'Shippers are offered the option of contributing to the content of the Site by publishing comments on their use of the Products and their relationship with Cobagage. Comments must be made in French. They will be subject to validation by Cobagage or its team of moderators.',
by_clicking_on_the_validate_tab:'By clicking on the “Validate” tab, in order to publish its comment, the Sender grants a non-exclusive copyright license free of charge to Cobagage relating to said comment. As such, the Shipper authorizes Cobagage to communicate to the online public, in whole or in part, its comments on the Site, Cobagage newsletters and the sites of Cobagage partners. The Sender authorizes Cobagage to reproduce its comment for the purposes of communicating it to the public online and creating communication and promotional materials for the Site. The Shipper authorizes Cobagage to translate its comment into any language for the purposes of communicating it to the public online and reproducing it on its commercial and promotional media. The right of adaptation also includes the right to make modifications technically necessary for the exploitation of the comment in other formats. This license is granted for commercial and advertising use. It is granted for the entire duration of the rights, for exploitation in France and abroad.',
the_sender_declares_that_it_has_the:'The Sender declares that it has the intellectual property and authorizations necessary to publish its comment. The Shipper undertakes to intervene in any request from Co-baggage in any proceedings initiated against the latter as a result of its comment and to guarantee it against any costs and convictions pronounced against it as a result, including any possible costs of lawyer.',
deletion_of_personal_space:'Deletion of personal space',
cobagage_reserves_right_to_delete:'Cobagage reserves the right to delete the account of any Sender who contravenes these general conditions, in particular when the Sender provides inaccurate, incomplete, misleading or fraudulent information, as well as when the personal space of a Sender has remained inactive for at least a year. Said deletion will not be likely to constitute a fault of Cobaggage or damage for the excluded Sender, who will not be able to claim any compensation as a result.',
this_exclusion_is_without:'This exclusion is without prejudice to the possibility, for Cobagage, of taking legal action against the Sender, when the facts justify it.',
personal_data:'PERSONAL DATA',
as_part_of_service_cobagage_will_require:'As part of its service, Cobagage will be required to process the personal data of its Shippers.',
identity_of_data_controller:'Identity of the data controller',
the_person_responsible_fora_collection_of:'The person responsible for the collection and processing of data on the Site is Cobagage.',
identity_of_data_protection_officer:'Identity of the Data Protection Officer',
data_protection_officer_is:'The data protection officer is: Etienne Deshoulières, 121 boulevard de Sébastopol 75002 Paris, contact@deshoulieres-avocats.com, 01 77 62 82 03, www.deshoulieres-avocats.com", always taking care to create a hyperlink on the URL of our site',
data_collected:'Data collected',
data_collected_from_shippers:'Data collected from Shippers',
as_part_of_contractual_relationships:'As part of its contractual relationships, Cobagage may be required to collect and process information from its Shippers, namely: surnames, first names, position, company name, telephone number, postal addresses, email addresses, bank details, contract history.',
purpose_of_collecting_data:'Purposes of collecting personal data',
data_collected_during_contractual_relationships:'The data collected during the contractual relationship is subject to automated processing with the aim of:',
executes_contractual_commitments:'Execute contractual commitments;',
contact_the_shipper:'Contact the Shippers;',
avoid_any_illicit_or:'Avoid any illicit or illegal activity;',
enfores_the_general_conditon:'Enforce the general conditions;',
initiates_legal_proceeding:'Initiate legal proceedings;',
verify_identity_of_sender:'Verify the identity of the Senders;',
legal_bases_for_proceedings:'Legal bases for processing',
the_data_collected_has_legal_basis_of_contractual:'The data collected has the legal basis of a contractual relationship.',
data_recipient:'Data recipients',
data_collected_can_only_be_consulted:'The data collected can only be consulted by Cobagage within the limits strictly necessary for the execution of contractual commitments.',
the_data_whether_in_individual:'This data, whether in individual or aggregate form, is never made freely viewable by a third party.',
duration_of_retention_of_personal:'Duration of retention of personal data',
ther_personal_data_personal_collected:'The personal data collected are kept for the duration of the contractual relationship, and for the time during which Cobagage may be held liable.',
after_the_retention_period:'After the retention period, Cobagage undertakes to permanently delete the data of the persons concerned without retaining a copy.',
security_and_confidentiality_of_data:'Security and confidentiality of personal data',
personal_data_is_stored_in_secure:'Personal data is stored in secure conditions, according to current technical means, in compliance with the provisions of the General Data Protection Regulation and the national legislation in force.Access to Cobagage premises is also secure.',
data_minimization:'Data minimization',
cobagage_may_also_collect_and_process:'Cobagage may also collect and process any data voluntarily transmitted by its Shippers.',
cobagage_directs_it:'Cobagage directs its Shippers to provide personal data strictly necessary for the execution of contractual commitments.',
cobagage_undertakes_only_to_retain:'Cobagage undertakes to only retain and process data strictly necessary for its professional activities, and will delete any data received that is not useful to its activities as quickly as possible.',
respect_for_rights:'Respect for rights',
cobagage_shippers_have_following_rights:'Cobagage Shippers have the following rights regarding their personal data, which they can exercise by writing to the Cobagage postal address or by completing the online contact form',
right_of_information_access:'Right to information, access and communication of data',
cobagage_shipper_have_possiblity:'Co-baggage Shippers have the possibility of accessing personal data concerning them',
due_to_cobagage_obligation_of_security:"Due to Cobagage's obligation of security and confidentiality in the processing of personal data, requests will only be processed if the Shippers provide proof of their identity, in particular by producing a scan of their title. valid identity document (in the case of a request using the dedicated electronic form) or a signed photocopy of their valid identity document (in the case of a request made in writing), both accompanied by the words “I attest on the honor that the copy of this identity document conforms to the original. Done at…on…”, followed by their signature.",
to_help_them_in_their_process:'To help them in their process, Senders will find here a letter template developed by the CNIL.',
right_to_rectification_deletion:'Right to rectification, deletion and right to forgetting of data',
cobagage_shippers_have_possiblity_of_requesting:'Co-baggage Shippers have the possibility of requesting the rectification, updating, blocking or even erasure of their personal data which may prove to be inaccurate, erroneous, incomplete or obsolete.',
cobagage_shippers_may_also_define_general:'Co-baggage Shippers may also define general and specific guidelines relating to the fate of personal data after their death. If applicable, the heirs of a deceased person may demand to take the death of their loved one into consideration and/or to make the necessary updates.',
right_to_object_to_data_processing:'Right to object to data processing',
cobagage_shippers_have_possiblity_to_object:'Co-baggage Shippers have the possibility to object to the processing of their personal data.',
right_to_data_portablity:'Right to data portability',
cobagage_shippers_have_right_to:'Cobagage Shippers have the right to receive the personal data they have provided to Cobagage in a transferable, open and readable format.',
right_to_retriction_of_processing:'Right to restriction of processing',
cobagage_shippers_have_rights_to_request_their:'Cobagage Shippers have the right to request that the processing of their personal data by Cobagage be limited. Thus, their data can only be kept and no longer used by Cobagage.',
reply_duration:'Reply duration',
cobagage_responds_to_undertakes_to_any_request:'Cobagage undertakes to respond to any request for access, rectification or opposition or any other additional request for information within a reasonable period of time which cannot exceed 1 month from receipt of the request.',
complaint_to_the_competent_authority:'Complaint to the competent authority',
if_the_cobagage_shippers_consider_that_cobagage:'If Cobagage Shippers consider that Cobagage is not complying with its obligations with regard to their personal data, they may submit a complaint or request to the competent authority. In France, the competent authority is the CNIL to which they can send a request here.',
transfer_of_collected_data:'Transfer of collected data',
transfer_to_partner:'Transfer to partners',
cobagage_uses_authorized:'Cobagage uses authorized service providers to facilitate the collection and processing of data from its Shippers. These service providers may be located outside the European Union.',
cobagage_has_previously_ensured:'Cobagage has previously ensured the implementation by its service providers of adequate guarantees and compliance with strict conditions in terms of confidentiality, use and protection of data, for example via the US Privacy Shield.',
cobagage_uses_following_subcontractor:'Cobagage uses the following subcontractors:',
transfer_upon_requisition:'Transfer upon requisition or judicial decision',
the_shipper_also_consent_to:'The Shippers also consent to Cobagage communicating the data collected to any person, upon requisition by a state authority or by judicial decision.',
transfer_in_context_of_merger:'Transfer in the context of a merger or acquisition',
if_cobagage_involved_in_merger_sale_of_asset:"If Cobagage is involved in a merger, sale of assets, financing transaction, liquidation or bankruptcy or in an acquisition of all or part of its activity by another company, the Shippers consent to the data collected being transmitted by Cobagage to this company and that this company carries out the processing of personal data referred to in these General Conditions of Service instead of Cobagage.",
cobagaging_responsiblity:'COBAGGING RESPONSIBILITY',
nature_of_cobagaging_application:'Nature of co-bagage obligations',
cobagage_undertakes_to_provide_diligence:'Cobagage undertakes to provide the care and diligence necessary to provide quality Service in accordance with the specifications of these General Conditions. Cobagage only responds to an obligation of means concerning the services covered herein.',
forces_majeur_part_of_shipper:'Force majeure - Fault of the Shipper',
cobagage_will_not_be_held_liable:'Cobagage will not be held liable in the event of force majeure or fault of the Sender, as defined in this article:',
for_the_purpose_of_these_general_condition:'For the purposes of these general conditions, any impediment, limitation or disruption of the Service due to fire, epidemic, explosion, earthquake, fluctuations in temperature will be considered as a case of force majeure opposable to the Shipper. bandwidth, failure attributable to the access provider, failure of transmission networks, collapse of installations, illicit or fraudulent use of passwords, codes or references provided to the Sender, computer hacking, a security breach attributable to the Site host or developers, flood, power outage, war, embargo, law, injunction, request or requirement of any government , requisition, strike, boycott, or other circumstances beyond the reasonable control of Cobagage. In such circumstances, Cobagage will be excused from the performance of its obligations within the limits of this impediment, this limitation or this disturbance.',
fault_of_sender:'Fault of the Sender',
for_the_purpose_of_these_general_conditons_any_misuse:'For the purposes of these General Conditions, any misuse of the Service, fault, negligence, omission or failure on its part or that of its employees, non-compliance with the advice given by Co-baggage on its Site, any disclosure or illicit use of the password, codes and references of the Sender, as well as the provision of erroneous information or the absence of updating of such information in its personal space. The implementation of any technical process, such as robots, or automatic requests, the implementation of which would contravene the letter or spirit of these general conditions of sale will also be considered as a fault of the Sender.',
technical_problems_hyperlink:'Technical problems - Hyperlinks',
in_the_event_of_impossiblity_of:'In the event of impossibility of access to the Site, due to technical problems of any nature, the Sender will not be able to claim damage and will not be able to claim any compensation. The unavailability, even prolonged and without any limitation period, of one or more online services, cannot constitute harm to the Shippers and cannot in any way give rise to the granting of damages and interest from Co-baggage.The hypertext links present on the Site may refer to other websites. Cobagage cannot be held liable if the content of these sites contravenes current legislation. Likewise, Cobagage cannot be held liable if the visit, by the Sender, to one of these sites causes it harm.',
damages_payable_by_cobagages:'Damages payable by Cobagage',
in_the_absense_of_legal_or_regulatory:'In the absence of legal or regulatory provisions to the contrary, the liability of Cobagage is limited to the direct, personal and certain damage suffered by the Shipper and linked to the failure in question. Cobagage cannot under any circumstances be held responsible for indirect damage such as, in particular, loss of data, commercial damage, loss of orders, damage to brand image, commercial disruptions and loss of profits or customers. . Likewise and within the same limits, the amount of damages payable by Cobagage cannot in any case exceed the price of the Product ordered.',
hypertext_link_and_context_of_site:'Hypertext links and content of the Site',
the_content_of_site_is_published:'The Content of the Site is published for information purposes only, without guarantee of accuracy. Cobagage cannot under any circumstances be held responsible for any omission, inaccuracy or any error contained in this information and which would cause direct or indirect damage to the Traveler.',
responsiblity_as_host:'Responsibility as a host',
data_in_perticular_comment:'The data, in particular comments, published on the Site by the Sender are under its own responsibility.',
in_this_context_cobagage_will_benifits:'In this context, Cobagage will benefit from the status of data host within the meaning of article 6-I-2 of the Law for confidence in the digital economy of June 21, 2004. In accordance with paragraph 3 of the same article, Cobagage cannot be held civilly or criminally liable for these comments, unless, from the moment it became aware of the illicit activity or information, it acted promptly to remove this information or make access impossible',
intectual_properties:'INTELLECTUAL PROPERTY',
legal_protection_of_site_content:'Legal protection of Site Content',
the_content_of_site_may_be_procted:'The Content of the Site may be protected by copyright and database law. Any representation, reproduction, translation, adaptation or transformation, in whole or in part, carried out illegally and without the consent of Cobagage or its successors or assigns constitutes a violation of Books I and III of the Intellectual Property Code and will be likely to give give rise to legal proceedings for counterfeiting.',
conntractual_process_of_site_content:'Contractual protection of Site Content',
the_internet_user_undertakes:'The Internet user undertakes contractually with Cobagage not to use, reproduce or represent, in any way whatsoever, the Content of the Site, whether or not they are protected by an intellectual property right, at any time. purpose other than that of their reading by a robot or a browser. This prohibition does not apply to indexing robots whose sole purpose is to scan the content of the Site for indexing purposes.',
final_stupulation:'FINAL STIPULATIONS',
applicable_right:'Applicable right',
these_general_condition_subjected_to:'These general conditions are subject to the application of French law.',
modification_these_general_condition:'Modifications to these general conditions',
these_general_conditon_may_be_modified_at:'These general conditions may be modified at any time by Cobagage. The general conditions applicable to the Sender are those in force on the day of his order or his connection to this Site, any new connection to the personal space entailing acceptance, where applicable, of the new general conditions.',
disputes:'Disputes',
pursuance_to_ordinance:'Pursuant to Ordinance No. 2015-1033 of August 20, 2015, all disputes which may arise in the context of the execution of these general conditions and for which a solution cannot previously be found amicably between the parties must be submitted to Medicys:',
in_addition_the_sender_is_informed:'In addition, the Sender is informed of the existence of the online dispute resolution platform, accessible at the following URL address:',
any_disputes_related_to_this_contract:'Any dispute relating to this contract or in connection with it will be settled by arbitration in accordance with the regulations of the Digital Institute of Arbitration and Mediation:',
entirely:'Entirety',
the_nullity_of_one_of_the_claauses:'The nullity of one of the clauses of this contract will not result in the nullity of the other clauses of the contract or of the contract as a whole, which will retain their full effect and scope. In such a case, the parties must, as far as possible, replace the canceled stipulation with a valid stipulation corresponding to the spirit and purpose hereof.',
non_waiver:'Non-waiver',
the_absence_of_exercise_by_cobagage:"The absence of exercise by Cobagage of the rights granted to it hereby cannot under any circumstances be interpreted as a waiver of the right to assert said rights.",
telephone_canvacassing:'Telephone canvassing',
the_sender_is_informed_that_he_has_possiblity:'The Sender is informed that he has the possibility of registering on the list opposing telephone canvassing at the address',
lanuages_of_the_general_conditions:'Languages ​​of these general conditions',
these_general_contions_are_offered_in:'These general conditions are offered in French.',
the_stipulation_of_these_general_condition:'The stipulations of these general conditions apply subject to compliance with the mandatory provisions of the Consumer Code concerning unfair clauses in contracts concluded between a professional and a consumer.',
order_cancellation_procedure:'ORDER CANCELLATION PROCEDURES IN CASE OF DISTANCE SALE FROM A SENDER',
in_the_event_of_distance_selling:'In the event of distance selling, as defined by article L. 221-1 of the Consumer Code, within fourteen days, including public holidays, from the order or purchase commitment, the The sender has the right to waive this by registered letter with acknowledgment of receipt. If this period normally expires on a Saturday, Sunday or public holiday, it is extended until the first following working day.',
any_clauses_in_contract_by_which:'Any clause in the contract by which the Shipper abandons its right to renounce its order or its purchase commitment is null and void. This article does not apply to contracts concluded under the conditions provided for in Article L. 221-2.',
if_you_cancel_your_order:'If you cancel your order,you can use the detachable form opposite.',
order_cancellation:'ORDER CANCELLATION',
consumer_court:'Consumer Code art. L. 221-5',
complete_and_sign_this_form:'Complete and sign this form',
send_it_by_registered_letter:'Send it by registered letter with acknowledgment of receipt',
use_the_address_in_article_1:'Use the address in article 1',
ship_it_no_later_than:'Ship it no later than the fourteenth day from the day of the order or, if this period normally expires on a Saturday, Sunday or public holiday, the first following working day.',
i_the_under_signed_declare:'I, the undersigned, declare to cancel the order below:',
nature_of_goods:'Nature of the goods or service ordered:',
date_of_order:'Date of the order',
date_of_recipt_of_order:'Date of receipt of the order',
sender_name:"Sender's name :",
expeditor_address:"Expeditor's address :",
signature_of_sender:'Signature of the Sender:',
travel:'Travellers',
bagage:'Shippers',

// Travel Tab
general_condition:'General conditions – Traveler of the website www.cobagage.com applicable from December 1, 2023',
part:'Parts',
the_general_conditions_are_concluded:'These general conditions are concluded between Cobagage Network, SASU, share capital: €1000, RCS de Paris: 848 506 861, head office: 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, telephone: +336 75 68 10 28, email: lyao@cobagage.com, intra-community VAT number: FR44914381116, hereinafter referred to as “Cobagage Network” and any person, natural or legal, under private or public law, registered on the Site to ship a Good and/or purchase a Product, hereinafter referred to as “the Traveler”.',
sender:'“Sender”',
any_person_registered_on_site_ship:'Any person, natural or legal, under private or public law, registered on the Site to ship a Good and/or purchase a Product.',
option:'" Option "',
paid_model_that_can_be_added_to_service:'Paid module that can be added to a Service by the Traveler.',
site_is_freely_accesible_to_all_traveller:"The Site is freely accessible to all Travelers. Browsing the Site implies acceptance by any Traveler of these general conditions. Simply connecting to the Site, by any means whatsoever, in particular via a robot or a browser, will constitute full acceptance of these general conditions.When registering on the Site, this acceptance will be confirmed by checking the box corresponding to the following sentence: “I acknowledge having read and accepted the general conditions of sale and use”. The Traveler acknowledges having read them fully and accepting them without restriction.Checking the above-mentioned box will be deemed to have the same value as a handwritten signature from the Traveler. The Traveler recognizes the evidentiary value of Cobagage's automatic check-in systems and, unless he provides proof to the contrary, he waives the right to contest them in the event of a dispute.These general conditions are applicable to relations between the parties to the exclusion of all other conditions, and in particular those of the Traveler.Acceptance of these general conditions assumes on the part of Travelers that they have the necessary legal capacity for this, or failing that they have the authorization of a guardian or curator if they are incapable. , their legal representative if they are minors, or that they hold a mandate if they act on behalf of a legal entity.",
traveler_obligation:'TRAVELERS’ OBLIGATIONS',
publication_of_advertizement_transfering:'Publication of an advertisement offering the transport of a Good',
publication_of_advertizement_is_opent_to:'The publication of an advertisement is open to any Traveler. Announcements must be written in correct and understandable French. They must comply with the legal provisions in force and must be free of any discriminatory criteria as to the quality of the possible co-contractor.',
traveller_acknowledes_that:'The Traveler acknowledges that the publication of his ad on this Site automatically and without intervention from Cobagage results in the publication of said ad on partner and affiliate sites.',
it_is_expressively_brought_to:'It is expressly brought to the attention of the Shippers that Cobagage in no way guarantees the accuracy of the data contained in the advertisements. It is the responsibility of any Sender interested in the announcement to ensure the accuracy of all this data prior to any effective transaction.',
the_traveller_must_specify:'The Traveler must specify the following information on the advertisement:',
the_place_date_time:'The place, date and time of departure;',
the_mode_of_transport:'The mode of transport;',
number_of_killogram_available_for_transporting:'The number of kilograms available for transporting the Good;',
the_asking_price:'The asking price.',
the_posiblity_of_subscring:'The Traveler has the possibility of subscribing to Options allowing him to highlight his ad on the Site. The details of each Option are specified on the Site.',
delivery_of_goods:'Delivery of the Good',
upon_acceptance_of_an_announcement:'Upon acceptance of an announcement, the Traveler undertakes to bring the Goods subject to the announcement to the Recipient thus designated, under the conditions and deadlines specified between the Traveler and the Sender.',
sale_or_donation:'Sale or donation of Product',
traveler_also_has_the_possiblity:'The Traveler also has the possibility of selling or giving away a Product',
publication_of_an_ad:'Publication of an ad',
traveller_will_have_possiblity_to_download:'Travelers will have the possibility to download photographs relating to the Product which is the subject of the advertisement. The number of photographs is not limited. The photographs uploaded must be in jpg format, with a maximum size not exceeding 2 megabytes. During the publication of the ad, the advertiser will have the option, via their personal space, to modify the descriptive sheet of the ad. These modifications will be possible throughout the publication of the ad.',
internet_user_expressly_informed:'Internet users are expressly informed that Cobagage in no way guarantees the accuracy of the data contained in the advertisements. It is the responsibility of any Internet user interested in the ad to ensure the accuracy of all this data prior to any effective transaction. Likewise, Cobagage does not in any way guarantee advertisers the actual sale or rental of the Products covered by the advertisement.',
sending_of_product_by:'Sending of the Product by the Traveler to the Internet User',
in_the_event_of_that:'In the event that the Product is purchased by an Internet user, the Traveler must send the Product directly to the Internet user within the time limit specified in the advertisement and at the latest within 7 working days.',
right_of_withdrawal_and_guarentee:'Right of withdrawal and guarantees',
traveler_remain_solely_responsible:'The Traveler remains solely responsible for the application of any right of withdrawal and the guarantees linked to the Product, vis-à-vis the Internet user.',
traveler_payment_terms:'Traveler payment terms',
payment_made_by_internet_user:'The payment made by the Internet user is made directly into the hands of Qonto or Revolt, payment providers of the Site. Once the Product has been sent to the Internet user by the Traveler, the payment provider concerned pays the Traveler his remuneration, less operating and/or transaction commissions due to Cobagage. The amounts of these commissions are indicated on the Site.',
declaration_and_obligation_of_traveller:'DECLARATIONS AND OBLIGATIONS OF THE TRAVELER',
traveller_information:'Traveler Information',
traveller_declares_to_have:'The Traveler declares to have received from Cobagage all useful explanations and details which may enable him to use the Services covered by this contract.',
the_traveller_acknowledges_that_he_need:'The Traveler acknowledges that his needs and the Services offered by Cobagage are appropriate and that he has subscribed to the contract with full knowledge of the facts and having all the necessary information allowing him to produce free and informed consent.',
the_traveller_undertake_to_inform:'The Traveler undertakes to inform Cobagage of any circumstance likely to affect the execution of this contract as soon as he becomes aware of it.',
the_traveller_is_solely_responsible_for:'The Traveler is solely responsible for authorizations and declarations relating to the use of the Services. The Traveler declares that he has the necessary rights and authorizations for this purpose. Where applicable, the Traveler declares having previously carried out all necessary steps, such as authorization requests and administrative declarations.',
lack_of_such_declarion_traveller:'The lack of such declarations and authorizations may in no case call into question the validity of this contract. In particular, the Traveler will remain required to pay Cobagage the amounts due. The Traveler guarantees Cobagage against any recourse which would be taken against him in the event of failure to make such declarations and authorizations.',
the_traveller_undertakes_not_to_publish:'The Traveler undertakes not to publish any advertisement on the Site that could',
undermine_the_security:'Undermine the security or integrity of any State or territory;',
any_processing_transmission_traveller:"Any processing, transmission, publication, distribution or representation of advertisements by the Traveler is carried out under his sole and entire responsibility. The Traveler undertakes not to hinder or disrupt the Site and Cobagage's servers and to comply with the required conditions, procedures and general rules communicated to him by Cobagage for the publication of advertisements.",
any_illigal_or_general:"Any illegal or generally unauthorized use of the Site will result in the immediate deletion of the Traveler's account, without prejudice to any possible damages to which Cobagage may be entitled.",
the_traveller_therefore_guarantees:'The Traveler therefore guarantees Cobagage against any damage that may be caused to him as a result of his use of the Site, including any legal and procedural costs, and undertakes in this respect to intervene in any legal proceedings initiated in against him due to his use of the Site.',
step_in_ordering:'STEPS IN ORDERING OPTIONS',
order:'Order',
in_order_to_place_an_order:'In order to place an order, Travelers will be able to select one or more Options and add them to their basket. When their order is complete, they will be able to access their basket by clicking on the button provided for this purpose.',
validation_of_order_by_traveller:'8.2. Validation of the order by the Traveler',
by_consulting_their_basket:'By consulting their basket, Travelers will be able to check the number and nature of the Options they have chosen and will be able to check their unit price, as well as their overall price. They will have the possibility to remove one or more Options from their basket.',
if_their_oder_suits_them:'If their order suits them, Travelers will be able to validate it. They will then access a form on which they can either enter their connection details if they already have them, or register on the Site by completing the registration form using their personal information.',
payment_by_traveller:'8.3. Payment by the Traveler',
as_soon_as_they_are_connected:'As soon as they are connected or after they have completely completed the registration form, Travelers will be invited to check or modify their billing details, then will be invited to make their payment by being redirected for this purpose to the secure payment interface including the words “order with payment obligation” or any similar formula.',
confirmation_of_order:'8.4. Confirmation of the order by Cobagage',
once_paymet_actually_received_by_cobagage_traveller:'Once the payment has actually been received by Cobagage, the latter undertakes to acknowledge receipt to the Traveler electronically, within a maximum of 24 hours. Within the same period, Cobagage undertakes to send the Traveler an email summarizing the order and confirming the processing, including all the related information.',
price_payment:'ARTICLE 9. PRICE – PAYMENT',
article_9:'9.1. Price',
applicable_price_are_those_displayed:'The applicable prices are those displayed on the Site on the day of the order. These prices may be modified at any time by Cobagage. The prices displayed are only valid on the day of the order and do not apply for the future.,',
article9_payment_term:'9.2. Payment terms',
traveller_can_make_payment_by_credit:'The Traveler can make payment by credit card. Credit card payments are made through secure transactions provided by Qonto or Revolut.',
in_this_context_of_bank_card:"In the context of bank card payments, Cobagage does not have access to any data relating to the Traveler's means of payment. Payment is made directly into the hands of the banking establishment.",
cobagage_will_send_available:'Cobagage will send or make available to the Traveler an invoice electronically after each payment. The Traveler expressly agrees to receive invoices electronically.',
in_addition_to_late_paymet_traveler:'In addition, any late payment will result in the defaulting Traveler being billed for recovery costs in the amount of 40 euros, the immediate payment of all sums remaining due regardless of the agreed deadlines, plus compensation of 20% of the amount as a penalty clause, as well as the possibility of terminating the contract unilaterally to the detriment of the Traveler. This clause falls within the framework of the provisions of article 1152 of the civil code allowing the judge to reduce the compensation if the judge considers that it is excessive.',
compalint_withdrawal_guarantee:'COMPLAINT – WITHDRAWAL – GUARANTEE',
sites_customer_service_accessible:"The Site's customer service is accessible from +336 75 68 10 28 from 10 a.m. to 6 p.m. or by email: contact@cobagage.com or by post to the address indicated in article 1 hereof. terms and conditions. In these last two cases, Cobagage undertakes to provide a response within 2 working days.",
conditions_for_exercising:'Conditions for exercising the right of withdrawal',
in_accordance_with_legistation:'In accordance with the legislation in force regarding distance selling, the Traveler has a period of fourteen clear days to exercise his right of withdrawal without having to justify reasons or pay penalties, with the exception, where applicable , return costs.',
periodmentioned_in_proceeding:'The period mentioned in the preceding paragraph runs from the day on which the distance contract is concluded for contracts relating to the provision of a service and/or the supply of digital content not provided on a material medium. .',
when_the_fourteen_period:'When the fourteen-day period expires on a Saturday, Sunday or public or non-working holiday, it is extended until the first following working day.',
the_withdrawal_decision:'The withdrawal decision must be notified to Cobagage using the contact details indicated in Article 1 of these general conditions by means of an unambiguous declaration. The Traveler has, for example, the possibility of using the standard form provided at the end of these general conditions. In any case, Cobagage will send the Traveler an acknowledgment of receipt of said withdrawal by email as soon as possible.',
effect_of_the_right_of_the_withdrawal:"Effects of the right of withdrawal",
when_the_right_of_withdrawal:'When the right of withdrawal is exercised, the professional is required to reimburse the Traveler for all sums paid, as soon as possible and at the latest within fourteen days following the date on which this right was exercised. Beyond this, the amount due is, automatically, productive of interest at the legal rate in force, as specified in article L. 242-4 of the Consumer Code.',
where_applicable_the_professional:'Where applicable, the professional makes the reimbursement using the same means of payment as that used by the Traveler for the initial transaction, unless expressly agreed by the Traveler to use another means of payment and to the extent that the reimbursement does not incur costs for the Traveler.',
condition_deadlines:'The conditions, deadlines and methods for exercising the right of withdrawal are set out in the standard form provided at the end of these general conditions.',
exclusion_from_right_of_withdrawal:'Exclusions from the right of withdrawal',
right_of_withdrawal_does_not_apply:"The right of withdrawal does not apply, in particular, to contracts",
for_the_provision_services:"For the provision of services fully executed before the end of the withdrawal period and the execution of which has begun after the Traveler's express prior agreement and express waiver of his or her right of withdrawal;",
for_the_provision_of_digital_content:"For the provision of digital content not provided on a physical medium whose execution has begun after the Traveler's express prior agreement and express waiver of his or her right of withdrawal.",
likewise_right_of_withdrawal:'Likewise, the right of withdrawal is not applicable to contracts executed in full by both parties at the express request of the Traveler before the latter exercises his right of withdrawal.',
when_validating_the_for_digital:"When validating the order for digital content independent of any material support before the withdrawal period has expired, the Traveler's waiver of the right of withdrawal will be manifested by checking the box corresponding to the following sentence:",
i_expressively_waive:"“I expressly waive my 14-day right of withdrawal for the Products delivered”. The Traveler will then receive confirmation by email of their waiver of the right of withdrawal.",
the_traveller_wh_has_exercised_his_right:"The Traveler who has exercised his right of withdrawal from a contract for the provision of services whose execution has begun, at his express request, before the end of the withdrawal period pays the professional an amount corresponding to the service provided until the communication of his decision to withdraw, this amount being proportional to the total price of the service agreed in the contract.",
the_personal_space_allows_the_traveller:"The personal space allows the Traveler to consult and follow all their advertisements published on the Site.",
pages_related_to_personal_space:"The pages relating to personal spaces are freely printable by the account holder in question, but in no way constitute admissible evidence in a court of law. They are only of an informative nature intended to ensure efficient management of their orders by the Traveler.",
publishing_profile:"Publishing a profile",
traveller_offered_the_option:"Travelers are offered the option of creating a public profile on the Site. In this context, Travelers will be able to provide a certain amount of personal information concerning them. All public profiles will be subject to prior validation by Cobagage or its team of moderators before their first posting online.",
it_is_expressively_understood_that:"It is expressly understood that the Traveler, by deciding to subscribe to the services offered by Cobagage and by completing the form relating to his public profile, acknowledges that this data, and in particular his photograph, may be published on the Site and distributed to this title among other Travelers and Internet users. The simple fact of providing this information and completing the fields of the public profile creation form will constitute an express manifestation of the Traveler's desire to publish said information on the Site.",
traveler_will_have_option_of:"Travelers will have the option of stopping the distribution of their public profile. To do this, they must request the deletion of their Traveler account and the termination of services, following the procedure provided for this purpose. The suspension of the distribution of the public profile will be effective within a maximum period of 3 working days from receipt of the request by Cobagage.",
an_internal_private_messeging_travel:"An internal private messaging service is available to Travelers. This system is reserved for Travelers and secrecy of connections applies to it.",
the_content_of_inboxxing_and_outgoing_traveller:"The contents of inboxes and outgoing boxes are not subject to any conservation guarantee on the part of Cobagage and it is up to Travelers to save said contents. The loss of this content, whatever the cause, cannot constitute damage to the Traveler who will not be able to claim any compensation as a result.",
any_traveller_who_is_victim:"Any Traveler who is the victim of abuse (spam, unwanted advertising or other) may inform Cobagage who will take all necessary measures.",
contribution_from_traveller:"Contributions from Travelers",
traveller_are_offered_the_option_of:"Travelers are offered the option of contributing to the content of the Site by publishing comments on their use of the Products and their relationship with Cobagage. Comments must be made in French. They will be subject to validation by Cobagage or its team of moderators.",
by_clicking_on_the_validate_tab_traveller:"By clicking on the “Validate” tab, in order to publish their comment, the Traveler grants a non-exclusive copyright license free of charge to Cobagage relating to said comment. As such, the Traveler authorizes Cobagage to communicate to the online public, in whole or in part, their comment on the Site, Cobagage newsletters and the sites of Cobagage partners. The Traveler authorizes Cobagage to reproduce his comment for the purposes of communicating it to the public online and creating communication and promotional materials for the Site. The Traveler authorizes Cobagage to translate his comment into any language for the purposes of communicating it to the public online and reproducing it on its commercial and promotional media. The right of adaptation also includes the right to make modifications technically necessary for the exploitation of the comment in other formats. This license is granted for commercial and advertising use. It is granted for the entire duration of the rights, for exploitation in France and abroad",
cobagage_reserves_the_right:'Cobagage reserves the right to delete the account of any Traveler who contravenes these general conditions, in particular when the Traveler provides inaccurate, incomplete, misleading or fraudulent information, as well as when the personal space of a Traveler has remained inactive for at least least a year. Said deletion will not be likely to constitute a fault of Co-baggage or damage for the excluded Traveler, who will not be able to claim any compensation as a result.',
these_exclusion_without:"This exclusion is without prejudice to the possibility, for Cobagage, of taking legal action against the Traveler, when the facts justify it.",
as_part_of_this_servece_cobagage_will_be_requred:'As part of its service, Cobagage will be required to process the personal data of its Travelers.',
data_collected_from_traveller:"Data collected from Travelers",
as_part_of_contractual_relationships_traveller:"As part of its contractual relationships, Cobagage may be required to collect and process information from its Travelers, namely: surnames, first names, position, company name, telephone number, postal addresses, email addresses, bank details, contract history.",
contact_traveler:"contact Travelers;",
verify_identity_of_traveller:"Verify the identity of Travelers;",
cobagage_may_also_collect_traveler:"Cobagage may also collect and process any data voluntarily transmitted by its Travelers.",
cobagage_direct_it_to_traveller:'Cobagage directs its Travelers to provide personal data strictly necessary for the execution of contractual commitments. ,',
cobagage_traveller_have_the_following:"Cobagage Travelers have the following rights regarding their personal data, which they can exercise by writing to the Cobagage postal address or by completing the online contact form.",
cobagage_traveller_possiblity:"Co-baggage Travelers have the possibility of accessing personal data concerning them.",
personal_nature:"Personal nature which is the responsibility of Cobagage, requests will only be processed if Travelers provide proof of their identity, in particular by producing a scan of their valid identity document (in the event of a request using the dedicated electronic form) or 'a signed photocopy of their valid identity document (in the event of a written request), both accompanied by the words I certify on my honor that the copy of this identity document conforms to the original . Done at…on…”, followed by their signature.To help them in their process, Travelers will find here a model letter developed by the CNIL.",
cobaggage_traveler_have_possiblity_of:"Co-baggage Travelers have the possibility of requesting the rectification, updating, blocking or even erasure of their personal data which may prove to be inaccurate, erroneous, incomplete or obsolete.",
cobagage_traveller_can_also:"Co-baggage Travelers can also define general and specific guidelines relating to the fate of personal data after their death. If applicable, the heirs of a deceased person may demand to take the death of their loved one into consideration and/or to make the necessary updates.",
to_help_them_in_their_process_traveller:"To help them in their process, Travelers will find here a model letter developed by the CNIL.",
cobagage_traveller_have_possiblity_to_object:"Co-baggage Travelers have the possibility to object to the processing of their data",
cobagage_traveller_have_right_to_receive:"Cobagage Travelers have the right to receive the personal data they have provided to Cobagage in a transferable, open and readable format.",
cobagage_traveller_right_to_receive_data:"Cobagage Travelers have the right to request that the processing of their personal data by Cobagage be limited. Thus, their data can only be kept and no longer used by Cobagage.",
if_cobagage_traveller_consider_that_cobagage:"If Cobagage Travelers consider that Cobagage is not respecting its obligations with regard to their personal data, they can send a complaint or request to the competent authority. In France, the competent authority is the CNIL to which they can send a request here.",
cobagage_uses_authorized_traveller:"Cobagage uses authorized service providers to facilitate the collection and processing of its Travelers’ data. These service providers may be located outside the European Union.",
cobagage_has_previously_ensured_traveller:"Cobagage has previously ensured the implementation by its service providers of adequate guarantees and compliance with strict conditions in terms of confidentiality, use and protection of data, for example via the US Privacy Shield.Cobagage uses the following subcontractors:",
traveller_also_consent:"Travelers also consent to Cobagage communicating the data collected to any person, upon requisition by a state authority or by judicial decision.",
if_cobagage_is_involved_in:"If Cobagage is involved in a merger, a sale of assets, a financing transaction,",
force_majour_fault_of_traveller:'Force majeure - Fault of the Traveler',
cobagage_will_not_held_liable_traveller:"Cobagage will not be held liable in the event of force majeure or fault of the Traveler, as defined in this article:",
force_maheur_traveler:"Force majeure",
for_the_purpose_of_these_general_condition_traveller:"For the purposes of these general conditions, any impediment, limitation or disruption of the Service due to fire, epidemic, explosion, earthquake, band fluctuations will be considered as a case of force majeure opposable to the Traveler. bandwidth, failure attributable to the access provider, failure of transmission networks, collapse of installations, illicit or fraudulent use of passwords, codes or references provided to the Traveler, computer hacking, a breach security attributable to the Site host or developers, flood, power outage, war, embargo, law, injunction, request or requirement of any government, requisition, strike, boycott, or other circumstances beyond the reasonable control of Cobagage. In such circumstances, Cobagage will be excused from the performance of its obligations within the limits of this impediment, this limitation or this disturbance.",
fault_of_the_traveller:"Fault of the Traveler",
as_the_purpose_of_these_general_conditions_traveller:"For the purposes of these General Conditions, any misuse of the Service, fault, negligence, omission or failure on their part or that of their agents, non-compliance with the advice given by Cobagage on its Site, any disclosure or illicit use of the Password, codes and references of the Traveler, as well as the provision of incorrect information or the absence of updating of such information in their personal space. The implementation of any technical process, such as robots, or automatic requests, the implementation of which would contravene the letter or spirit of these general conditions of sale will also be considered as a fault of the Traveler.",
if_it_is_impossible_traveller:"If it is impossible to access the Site, due to technical problems of any nature, the Traveler will not be able to claim damage and will not be able to claim any compensation. The unavailability, even prolonged and without any limitation period, of one or more online services, cannot constitute harm to Travelers and cannot in any way give rise to the award of damages from Co-baggage.",
hypertext_link_present_on_site:"The hypertext links present on the Site may refer to other websites. Cobagage cannot be held liable if the content of these sites contravenes current legislation. Likewise, Cobagage cannot be held liable if the Traveler's visit to one of these sites causes him harm.",
damage_payable_by_cobagage_traveller:"In the absence of legal or regulatory provisions to the contrary, the liability of Cobagage is limited to the direct, personal and certain damage suffered by the Traveler and linked to the failure in question. Cobagage cannot under any circumstances be held responsible for indirect damage such as, in particular, loss of data, commercial damage, loss of orders, damage to brand image, commercial disruptions and loss of profits or customers. . Likewise and within the same limits, the amount of damages payable by Cobagage cannot in any case exceed the price of the commission received.",
content_of_site_published_on_site_traveller:"The Content of the Site is published for information purposes only, without guarantee of accuracy. Cobagage cannot under any circumstances be held responsible for any omission, inaccuracy or any error contained in this information and which would cause direct or indirect damage to the Traveler.",
data_published_by_traveller:"The data, in particular comments, published on the Site by the Traveler are under their own responsibility.",
in_this_context_cobagage_will_benit_traveler:"In this context, Cobagage will benefit from the status of data host within the meaning of article 6-I-2 of the Law for confidence in the digital economy of June 21, 2004. In accordance with paragraph 3 of the same article, Cobagage cannot be held civilly or criminally liable for these comments, unless, from the moment it became aware of the illicit activity or information, it acted promptly to remove this information or make access impossible.",
these_general_condition_may_be_modified_traveller:"These general conditions may be modified at any time by Cobagage. The general conditions applicable to the Traveler are those in force on the day of their order or connection to this Site, any new connection to the personal space entailing acceptance, where applicable, of the new general conditions.",
in_addition_traveller_is_informed:'In addition, the Traveler is informed of the existence of the online dispute resolution platform, accessible at the following URL address:',
traveler_is_informed_that:"The Traveler is informed that he or she has the possibility of registering on the list opposing telephone canvassing at the address http://www.bloctel.gouv.fr/.",
unfair_clauses:"Unfair clauses",
order_cancellation_procedure_traveler:'ORDER CANCELLATION PROCEDURES IN THE CASE OF DISTANCE SALE TO A TRAVELER',
in_the_event_of_distance_selling_traveller:"In the event of distance selling, as defined by article L. 221-1 of the Consumer Code, within fourteen days, including public holidays, from the order or purchase commitment, the Traveler may waive it by registered letter with acknowledgment of receipt. If this period normally expires on a Saturday, Sunday or public holiday, it is extended until the first following working day.",
any_clauses_of_contract_traveller:"Any clause of the contract by which the Traveler abandons his right to renounce his order or his commitment to purchase is null and void. This article does not apply to contracts concluded under the conditions provided for in Article L. 221-2."








}
export default cgvu;