import React from "react";
import { useTranslation } from "react-i18next";

export default function CgveWrap() {
  const { t } = useTranslation();

  return (
    <>
      <div className="container mt-4">
        <div className="package-details-wrapper pb-110">
          <div className="tour-package-details">
            <div className="package-details-tabs">
              <ul
                className="nav nav-tabs  gap-xxl-4 gap-3 pb-5"
                id="myTab"
                role="tablist"
                style={{ marginBottom: "0px" }}
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-package1"
                    data-bs-toggle="pill"
                    data-bs-target="#pill-body1"
                    type="button"
                    role="tab"
                    aria-controls="pill-body1"
                    aria-selected="true"
                    style={{ fontSize: "16px", borderRadius: "5px" }}
                  >
                    {t("cgvu.travel")}
                  </button>
                  {/* nav nav-pills tab-switchers id- pills-tab*/}
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-package2"
                    data-bs-toggle="pill"
                    data-bs-target="#pill-body2"
                    type="button"
                    role="tab"
                    aria-controls="pill-body2"
                    aria-selected="false"
                    style={{ fontSize: "16px", borderRadius: "5px" }}
                  >
                    {t("cgvu.bagage")}
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="pills-tabContent">
                <div
                  className="tab-pane fade show active package-info-tab "
                  id="pill-body1"
                  role="tabpanel"
                  aria-labelledby="pills-package1"
                  style={{ overflowX: "auto" }}
                >
                  <div
                    className="container"
                    style={{ height: "100vh", overflowY: "scroll" }}
                  >
                    <div className="mt-5  pb-110">
                      <h1
                        className="text-center "
                        style={{ fontWeight: "bold" }}
                      >
                        {t("cgvu.general_condition")}{" "}
                      </h1>
                      <h4 className="pt-4 fs-5">Article 1. {t("cgvu.part")}</h4>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_general_conditions_are_concluded")}
                      </p>
                      <h4 className="pt-4 fs-6">ARTICLE 2. DEFINITIONS</h4>
                      <p className="fs-6 pt-2">
                        {t("cgvu.good")}:{" "}
                        {t("cgvu.property_of_any_kind_held_by_sender")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.site_content")} :{" "}
                        {t("cgvu.element_of_any_kind_published_on_site")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.recipient")}:{" "}
                        {t("cgvu.any_person_natural_legal")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.sender")} :{" "}
                        {t("cgvu.any_person_registered_on_site_ship")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.internet_user")}:{" "}
                        {t("cgvu.any_person_connecting_to_site")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.option")}:
                        {t("cgvu.paid_model_that_can_be_added_to_service")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.product")} : {t("cgvu.goods_of_any_kind")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.service")} :{" "}
                        {t("cgvu.service_offered_by_cobagage_on_site")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.site")} : {t("cgvu.website_accessible_at_url")}
                      </p>
                      <h4 className="pt-4 fs-5">ARTICLE 3.{t("cgvu.scope")}</h4>
                      <p className="fs-6 pt-2">
                        {t("cgvu.site_is_freely_accesible_to_all_traveller")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 4. {t("cgvu.purpose_of_the_site")}
                      </h4>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_purpose_of_site_is_to_provide_an")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 5. {t("cgvu.cobagaging_obligation")}
                      </h4>
                      <p className="fs-5 pt-2">
                        5.1.{t("cgvu.provision_of_the_platform")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.cobagage_undertakes_to_provide_the_traveller_with_an"
                        )}
                      </p>
                      <p className="fs-5 pt-2">
                        5.2. {t("cgvu.site_maintenance")}
                      </p>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_undertakes_to_host_an_site")} <br />
                        {t("cgvu.in_this_regard_cobagage_will_bear_all")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 6. {t("cgvu.traveler_obligation")}
                      </h4>
                      <h5 className="pt-2">
                        6.1.
                        {t("cgvu.publication_of_advertizement_transfering")}
                      </h5>
                      <p className="fs-6 pt-2">
                        6.1.1. {t("cgvu.publication_announcement")} <br></br>
                        {t("cgvu.publication_of_advertizement_is_opent_to")}
                        <br></br>
                        {t("cgvu.traveller_acknowledes_that")}
                        <br></br>
                        {t("cgvu.it_is_expressively_brought_to")}
                      </p>
                      <p className="fs-6 pt-2">
                        6.1.2. {t("cgvu.add_content")} <br></br>
                        {t("cgvu.the_traveller_must_specify")}
                        <br></br>- {t("cgvu.the_place_date_time")}
                        <br></br>- {t("cgvu.the_mode_of_transport")}
                        <br></br>-{" "}
                        {t("cgvu.the_place_time_and_date_of_arrival")}
                        <br></br>-{" "}
                        {t(
                          "cgvu.number_of_killogram_available_for_transporting"
                        )}
                        <br></br>- {t("cgvu.the_asking_price")}
                      </p>
                      <p className="fs-6 pt-2">
                        6.1.3. Options<br></br>
                        {t("cgvu.the_posiblity_of_subscring")}
                      </p>
                      <p className="fs-6 pt-2">
                        6.2.{t("cgvu.delivery_of_goods")}
                        <br></br>
                        {t("cgvu.upon_acceptance_of_an_announcement")}
                      </p>
                      <p className="fs-6 pt-2">
                        6.3.{t("cgvu.sale_or_donation")}
                        <br></br>
                        {t("cgvu.traveler_also_has_the_possiblity")}
                      </p>
                      <p className="fs-6 pt-2">
                        6.3.1. {t("cgvu.publication_of_an_ad")}
                        <br></br>
                        {t("cgvu.publication_of_advertizement_is_opent_to")}
                        <br></br>
                        {t("cgvu.traveller_will_have_possiblity_to_download")}
                        <br></br>
                        {t("cgvu.traveller_acknowledes_that")}
                        <br></br>
                        {t("cgvu.internet_user_expressly_informed")}
                        <br></br>
                        6.3.2. {t("cgvu.sending_of_product_by")} <br></br>
                        {t("cgvu.in_the_event_of_that")}
                        <br></br>
                        6.3.3. {t(
                          "cgvu.right_of_withdrawal_and_guarentee"
                        )}{" "}
                        <br></br>
                        {t("cgvu.traveler_remain_solely_responsible")}
                        <br></br>
                        6.3.4. {t("cgvu.traveler_payment_terms")} <br></br>
                        {t("cgvu.payment_made_by_internet_user")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 7.{" "}
                        {t("cgvu.declaration_and_obligation_of_traveller")}
                      </h4>
                      <h5 className="pt-2">
                        7.1. {t("cgvu.traveller_information")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveller_declares_to_have")}
                        <br></br>
                        {t("cgvu.the_traveller_acknowledges_that_he_need")}
                        <br></br>
                        {t("cgvu.the_traveller_undertake_to_inform")}
                      </p>
                      <h5 className="pt-2">
                        7.2. {t("cgvu.authorization_and_declarion")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_traveller_is_solely_responsible_for")}
                        <br></br>
                        {t("cgvu.lack_of_such_declarion_traveller")}
                      </p>
                      <h5 className="pt-2">
                        7.3. {t("cgvu.charter_of_good_conductor")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_traveller_undertakes_not_to_publish")}:
                        <br></br>- {t("cgvu.harm_or_make_comment")}
                        <br></br>- {t("cgvu.infringe_in_any_right")} <br></br>-
                        {t("cgvu.be_denigrating_defamatory")} <br></br>-{" "}
                        {t("cgvu.make_comment_of_a_pedophilic")}
                        <br></br>- {t("cgvu.undermine_the_security")}
                        <br></br>-{t("cgvu.allows_third_parties_to_obtain")}{" "}
                        <br></br>- {t("cgvu.the_intellectual_property")}
                        <br></br>- {t("cgvu.insite_hatred_voilence")}
                        <br></br>-{t("cgvu.insite_to_commit_a_crome")} <br></br>
                        -{t("cgvu.insite_descrimination_against")} <br></br>-{" "}
                        {t("cgvu.advice_a_questionable")}
                        <br></br>
                        {t("cgvu.any_processing_transmission_traveller")}
                        <br></br>
                        {t("cgvu.any_illigal_or_general")}
                        <br></br>
                        {t("cgvu.the_traveller_therefore_guarantees")}
                        <br></br>
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 8. {t("cgvu.step_in_ordering")}
                      </h4>
                      <h5 className="pt-2">8.1.{t("cgvu.order")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.in_order_to_place_an_order")}
                      </p>
                      <h5 className="pt-2">
                        {t("cgvu.validation_of_order_by_traveller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.by_consulting_their_basket")}
                        <br></br>
                        {t("cgvu.if_their_oder_suits_them")}
                      </p>
                      <h5 className="pt-2">{t("cgvu.payment_by_traveller")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.as_soon_as_they_are_connected")}
                      </p>
                      {/* <h5 className='pt-2'>
                                                {t('cgvu.bagage')}
                                              
                                            </h5>
                                            <p className='fs-6 pt-2'>
                                                {t('cgvu.bagage')}
                                                Dès lors qu'ils seront connectés ou après qu'ils auront parfaitement complété le formulaire d’inscription, les Voyageurs seront invités à contrôler ou modifier leurs coordonnées de facturation, puis seront invités à effectuer leur paiement en étant redirigés à cet effet sur l'interface de paiement sécurisée comportant la mention « commande avec obligation de paiement » ou toute formule analogue.
                                            </p> */}
                      <h5 className="pt-2">
                        {t("cgvu.confirmation_of_order")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.once_paymet_actually_received_by_cobagage_traveller"
                        )}
                      </p>
                      <h4 className="pt-4 fs-5">{t("cgvu.price_payment")}</h4>
                      <h5 className="pt-2">{t("cgvu.article_9")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.applicable_price_are_those_displayed")}
                        <br></br>
                        {t("cgvu.the_price_indicated_on_site")}
                      </p>
                      <h5 className="pt-2">
                        {t("cgvu.article9_payment_term")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveller_can_make_payment_by_credit")}
                        <br></br>
                        {t("cgvu.in_this_context_of_bank_card")}
                      </p>
                      <h5 className="pt-2">9.3.{t("cgvu.billing")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_will_send_available")}
                      </p>
                      <h5 className="pt-2">9.4. {t("cgvu.failure_to_pay")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.agreed_payment_date_can_not_be_delayed")}
                        <br></br>
                        {t("cgvu.any_sum_not_paid_on_due_date")}
                        <br></br>
                        {t("cgvu.in_addition_to_late_paymet_traveler")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 10. {t("cgvu.compalint_withdrawal_guarantee")}
                      </h4>
                      <h5 className="pt-2">
                        10.1. {t("cgvu.customer_service")}
                      </h5>
                      <p className="fs-6 pt-2">
                        ARTICLE 11.{" "}
                        {t("cgvu.sites_customer_service_accessible")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        11.1.{t("cgvu.right_withdrawal_distance")}
                      </h4>
                      <h5 className="pt-2">
                        11.1.1. {t("cgvu.conditions_for_exercising")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.in_accordance_with_legistation")}
                        <br></br>
                        {t("cgvu.periodmentioned_in_proceeding")}
                        <br></br>
                        {t("cgvu.when_the_fourteen_period")}
                        <br></br>
                        {t("cgvu.the_withdrawal_decision")}
                      </p>
                      <h5 className="pt-2">
                        11.1.2.{" "}
                        {t("cgvu.effect_of_the_right_of_the_withdrawal")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.when_the_right_of_withdrawal")}
                        <br></br>
                        {t("cgvu.where_applicable_the_professional")}
                        <br></br>
                        {t("cgvu.condition_deadlines")}
                      </p>
                      <h5 className="pt-2">
                        11.1.3. {t("cgvu.exclusion_from_right_of_withdrawal")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.right_of_withdrawal_does_not_apply")}:<br></br>
                        - {t("cgvu.for_the_provision_services")} <br></br>-
                        {t("cgvu.for_the_provision_of_digital_content")}{" "}
                        <br></br>
                        {t("cgvu.likewise_right_of_withdrawal")}
                        <br></br>
                        {t("cgvu.when_validating_the_for_digital")}
                        <br></br>
                        {t("cgvu.i_expressively_waive")}
                        <br></br>
                        {t("cgvu.the_traveller_wh_has_exercised_his_right")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        12.2. {t("cgvu.content_of_personal_space")}
                      </h4>
                      <h5 className="pt-2">12.2.1. {t("cgvu.generalities")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_personal_space_allows_the_traveller")}
                        <br></br>
                        {t("cgvu.pages_related_to_personal_space")}
                        <br></br>
                        {t("cgvu.cobagage_undertakes_to_securely_stire_all")}
                      </p>
                      <h5 className="pt-2">
                        12.2.2. {t("cgvu.publishing_profile")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveller_offered_the_option")}
                        <br></br>
                        {t("cgvu.it_is_expressively_understood_that")}
                        <br></br>
                        {t("cgvu.traveler_will_have_option_of")}
                      </p>
                      <h5 className="pt-2">
                        12.2.3. {t("cgvu.internal_messeging")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.an_internal_private_messeging_travel")}
                        <br></br>
                        {t(
                          "cgvu.the_content_of_inboxxing_and_outgoing_traveller"
                        )}
                        <br></br>
                        {t("cgvu.any_traveller_who_is_victim")}
                      </p>
                      <h5 className="pt-2">
                        12.2.4. {t("cgvu.contribution_from_traveller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveller_are_offered_the_option_of")}
                        <br></br>
                        {t("cgvu.by_clicking_on_the_validate_tab_traveller")}
                      </p>
                      <h5 className="pt-2">
                        12.3. {t("cgvu.deletion_of_personal_space")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_reserves_the_right")}
                        <br></br>
                        {t("cgvu.these_exclusion_without")}
                      </p>
                      <h4 className="pt-4 fs-5">
                        ARTICLE 13. {t("cgvu.personal_data")}
                      </h4>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.as_part_of_this_servece_cobagage_will_be_requred"
                        )}
                      </p>
                      <h5 className="pt-2">
                        13.1. {t("cgvu.identity_of_data_controller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_person_responsible_fora_collection_of")}
                      </p>
                      <h5 className="pt-2">
                        13.2. {t("cgvu.identity_of_data_protection_officer")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.data_protection_officer_is")}
                      </p>
                      <h5 className="pt-2">
                        13.3.1. {t("cgvu.data_collected_from_traveller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.as_part_of_contractual_relationships_traveller"
                        )}
                      </p>
                      <h5 className="pt-2">
                        13.3.2. {t("cgvu.purpose_of_collecting_data")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.data_collected_during_contractual_relationships"
                        )}
                        :<br></br>-{t("cgvu.executes_contractual_commitments")}
                        <br></br>-{t("cgvu.contact_traveler")}
                        <br></br>-{t("cgvu.avoid_any_illicit_or")} <br></br>-
                        {t("cgvu.enfores_the_general_conditon")} <br></br>-
                        {t("cgvu.initiates_legal_proceeding")} <br></br>-
                        {t("cgvu.verify_identity_of_traveller")}
                      </p>
                      <h5 className="pt-2">
                        13.3.3. {t("cgvu.legal_bases_for_proceedings")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.the_data_collected_has_legal_basis_of_contractual"
                        )}
                      </p>
                      <h5 className="pt-2">
                        13.3.4. {t("cgvu.data_recipient")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.data_collected_can_only_be_consulted")}
                        <br></br>
                        {t("cgvu.the_data_whether_in_individual")}
                      </p>
                      <h5 className="pt-2">
                        13.3.5. {t("cgvu.duration_of_retention_of_personal")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.ther_personal_data_personal_collected")}
                        <br></br>
                        {t("cgvu.after_the_retention_period")}
                      </p>
                      <h5 className="pt-2">
                        13.3.6. {t("cgvu.security_and_confidentiality_of_data")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.personal_data_is_stored_in_secure")}
                      </p>
                      <h5 className="pt-2">
                        {" "}
                        13.3.7. {t("cgvu.data_minimization")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_may_also_collect_traveler")}
                        <br></br>
                        {t("cgvu.cobagage_direct_it_to_traveller")}
                        <br></br>
                        {t("cgvu.cobagage_undertakes_only_to_retain")}
                      </p>
                      <h5 className="pt-2">
                        13.4. {t("cgvu.respect_for_rights")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_traveller_have_the_following")}
                      </p>
                      <h5 className="pt-2">
                        13.4.1. {t("cgvu.right_of_information_access")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_traveller_possiblity")}
                        <br></br>
                        {t("cgvu.personal_nature")}
                      </p>
                      <h5 className="pt-2">
                        13.4.2. {t("cgvu.right_to_rectification_deletion")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobaggage_traveler_have_possiblity_of")}
                        <br></br>
                        {t("cgvu.cobagage_traveller_can_also")}
                        <br></br>
                        {t("cgvu.to_help_them_in_their_process_traveller")}
                      </p>
                      <h5 className="pt-2">
                        13.4.3. {t("cgvu.right_to_object_to_data_processing")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_traveller_have_possiblity_to_object")}
                        <br></br>
                        {t("cgvu.to_help_them_in_their_process_traveller")}
                      </p>
                      <h5 className="pt-2">
                        13.4.4. {t("cgvu.right_to_data_portablity")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_traveller_have_right_to_receive")}
                      </p>
                      <h5 className="pt-2">
                        13.4.5. {t("cgvu.right_to_retriction_of_processing")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_traveller_right_to_receive_data")}
                      </p>
                      <h5 className="pt-2">
                        13.4.6. {t("cgvu.reply_duration")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.cobagage_responds_to_undertakes_to_any_request"
                        )}
                      </p>
                      <h5 className="pt-2">
                        13.4.7. {t("cgvu.complaint_to_the_competent_authority")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.if_cobagage_traveller_consider_that_cobagage")}
                      </p>
                      <h4 className="pt-2">
                        13.5.{t("cgvu.transfer_of_collected_data")}
                      </h4>
                      <h5 className="pt-2">
                        13.5.1. {t("cgvu.transfer_to_partner")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_uses_authorized_traveller")}
                        <br></br>
                        {t("cgvu.cobagage_has_previously_ensured_traveller")}
                      </p>
                      <div className="col-12 mt-2">
                        <table className="table table-bordered text-center ">
                          <thead>
                            <tr>
                              <th className="text-center" colSpan={5}>
                                {t("privacyPolicy.partner_directory")}
                              </th>
                            </tr>
                            <tr>
                              <th>{t("privacyPolicy.partner")}</th>
                              <th>{t("privacyPolicy.quality")}</th>
                              <th>{t("privacyPolicy.recipent_country")}</th>
                              <th>
                                {t("privacyPolicy.treatment_carried_out")}
                              </th>
                              <th>{t("privacyPolicy.garanties")}</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>AWS</td>
                              <td>{t("privacyPolicy.sub_contractor")}</td>
                              <td>France</td>
                              <td>{t("privacyPolicy.web_hosting")}</td>
                              <td>
                                <a href="https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#">
                                  https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>Google Suite</td>
                              <td>
                                {t(
                                  "privacyPolicy.sub_contractor_and_co_contractor"
                                )}
                              </td>
                              <td>USA (Privacy Shield)</td>
                              <td>{t("privacyPolicy.management_of_people")}</td>
                              <td>
                                <a href="https://policies.google.com/privacy">
                                  https://policies.google.com/privacy
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>Google Analytics</td>
                              <td>
                                {t(
                                  "privacyPolicy.sub_contractor_and_co_contractor"
                                )}
                              </td>
                              <td>USA (Privacy Shield)</td>
                              <td>
                                {t("privacyPolicy.development_of_commercial")}
                              </td>
                              <td>
                                <a href="https://policies.google.com/privacy">
                                  https://policies.google.com/privacy
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>Universal Analytics</td>
                              <td>
                                {t(
                                  "privacyPolicy.sub_contractor_and_co_contractor"
                                )}
                              </td>
                              <td>USA (Privacy Shield)</td>
                              <td>
                                {t("privacyPolicy.development_of_commercial")}
                              </td>
                              <td>
                                <a href="https://policies.google.com/privacy">
                                  https://policies.google.com/privacy
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>Youtube</td>
                              <td>{t("privacyPolicy.co_responsable")}</td>
                              <td>USA (Privacy Shield)</td>
                              <td>{t("privacyPolicy.video_hosting")}</td>
                              <td>
                                <a href="https://policies.google.com/privacy?hl=fr&gl=fr">
                                  https://policies.google.com/privacy?hl=fr&gl=fr
                                </a>
                              </td>
                            </tr>
                            <tr>
                              <td>Spidrontech</td>
                              <td>{t("privacyPolicy.co_responsable")}</td>
                              <td>USA (Privacy Shield)</td>
                              <td>
                                {t(
                                  "privacyPolicy.digital_marketing_and_web_development"
                                )}
                              </td>
                              <td>
                                <a href="https://policies.google.com/privacy?hl=fr&gl=fr">
                                  https://policies.google.com/privacy?hl=fr&gl=fr
                                </a>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <h5 className="pt-2">
                        13.5.2. {t("cgvu.transfer_upon_requisition")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveller_also_consent")}
                      </p>
                      <h5 className="pt-2">
                        13.5.3. {t("cgvu.transfer_in_context_of_merger")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.if_cobagage_is_involved_in")}
                      </p>
                      <h4 className="pt-2">
                        ARTICLE 14. {t("cgvu.cobagaging_responsiblity")}
                      </h4>
                      <h5 className="pt-2">
                        14.1. {t("cgvu.nature_of_cobagaging_application")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_undertakes_to_provide_diligence")}
                      </p>
                      <h5 className="pt-2">
                        14.2. {t("cgvu.force_majour_fault_of_traveller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.cobagage_will_not_held_liable_traveller")}
                      </p>
                      <h5 className="pt-2">
                        14.2.1. {t("cgvu.force_maheur_traveler")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.for_the_purpose_of_these_general_condition_traveller"
                        )}
                      </p>
                      <h5 className="pt-2">
                        14.2.2. {t("cgvu.fault_of_the_traveller")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.as_the_purpose_of_these_general_conditions_traveller"
                        )}
                      </p>
                      <h5 className="pt-2">
                        14.3. {t("cgvu.technical_problems_hyperlink")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.if_it_is_impossible_traveller")}
                        <br></br>
                        {t("cgvu.hypertext_link_present_on_site")}
                      </p>
                      <h5 className="pt-2">
                        14.4. {t("cgvu.damages_payable_by_cobagages")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.damage_payable_by_cobagage_traveller")}
                      </p>
                      <h5 className="pt-2">
                        14.5. {t("cgvu.hypertext_link_and_context_of_site")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.content_of_site_published_on_site_traveller")}
                      </p>
                      <h5 className="pt-2">
                        14.6. {t("cgvu.responsiblity_as_host")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.data_published_by_traveller")}
                        <br></br>
                        {t("cgvu.in_this_context_cobagage_will_benit_traveler")}
                      </p>
                      <h4 className="pt-2">
                        ARTICLE 15. {t("cgvu.intectual_properties")}
                      </h4>
                      <h5 className="pt-2">
                        15.1. {t("cgvu.legal_protection_of_site_content")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_content_of_site_may_be_procted")}
                      </p>
                      <h5 className="pt-2">
                        15.2. {t("cgvu.conntractual_process_of_site_content")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_internet_user_undertakes")}
                      </p>
                      <h4 className="pt-2">
                        ARTICLE 16. {t("cgvu.final_stupulation")}
                      </h4>
                      <h5 className="pt-2">
                        16.1. {t("cgvu.applicable_right")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.these_general_condition_subjected_to")}
                      </p>
                      <h5 className="pt-2">
                        16.2. {t("cgvu.modification_these_general_condition")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t(
                          "cgvu.these_general_condition_may_be_modified_traveller"
                        )}
                      </p>
                      <h5 className="pt-2">16.3. {t("cgvu.disputes")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.pursuance_to_ordinance")}
                        <a href="">www.medicys.fr.</a> <br></br>
                        {t("cgvu.in_addition_traveller_is_informed")}
                        <a href="">
                          https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
                        </a>{" "}
                        <br></br>
                        {t("cgvu.any_disputes_related_to_this_contract")}
                        <a>www.fast-arbitre.com.</a>
                        <br></br>
                      </p>
                      <h5 className="pt-2">16.4. {t("cgvu.entirely")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_nullity_of_one_of_the_claauses")}
                      </p>
                      <h5 className="pt-2">16.5. {t("cgvu.non_waiver")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_absence_of_exercise_by_cobagage")}
                      </p>
                      <h5 className="pt-2">
                        16.6. {t("cgvu.telephone_canvacassing")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.traveler_is_informed_that")}
                      </p>
                      <h5 className="pt-2">
                        16.7. {t("cgvu.lanuages_of_the_general_conditions")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.these_general_contions_are_offered_in")}
                      </p>
                      <h5 className="pt-2">16.8. {t("cgvu.unfair_clauses")}</h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.the_stipulation_of_these_general_condition")}
                      </p>
                      <h5 className="pt-2">
                        {t("cgvu.order_cancellation_procedure_traveler")}
                      </h5>
                      <p className="fs-6 pt-2">
                        {t("cgvu.in_the_event_of_distance_selling_traveller")}
                        <br></br>
                        {t("cgvu.any_clauses_of_contract_traveller")}
                      </p>
                    </div>
                  </div>
                </div>
                <div
                  className="tab-pane fade package-info-tab"
                  id="pill-body2"
                  role="tabpanel"
                  aria-labelledby="pills-package2"
                  style={{ overflowX: "auto" }}
                >
                  <div
                    className="container"
                    style={{ height: "100vh", overflowY: "scroll" }}
                  >
                    <div className="pt-4 ">
                      <div className="row">
                        <div className="col-12 text-center">
                          <h1 className="fw-bold">{t("cgvu.title")}</h1>
                        </div>
                        <div className="col-12 mt-5">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 1.PARTIES{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 2{" "}
                          </h4>
                          <p className="fs-6">
                            {t("cgvu.the_general_conditions_are_concluded")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 3.DEFINITIONS{" "}
                          </h4>
                          <p className="fs-6">
                            <strong>{t("cgvu.good")}:</strong>{" "}
                            {t("cgvu.property_of_any_kind_held_by_sender")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.site_content")}:</strong>{" "}
                            {t("cgvu.element_of_any_kind_published_on_site")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.recipient")}:</strong>{" "}
                            {t("cgvu.any_person_natural_legal")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.traveler")}:</strong>{" "}
                            {t(
                              "cgvu.any_person_natural_or_legal_registered_on_site"
                            )}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.internet_user")}:</strong>{" "}
                            {t("cgvu.any_person_connecting_to_site")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.product")}:</strong>{" "}
                            {t("cgvu.goods_of_any_kind")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.service")}:</strong>{" "}
                            {t("cgvu.service_offered_by_cobagage_on_site")}
                          </p>
                          <p className="fs-6">
                            <strong>{t("cgvu.site")}:</strong>{" "}
                            {t("cgvu.website_accessible_at_url")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 4. {t("cgvu.scope")}{" "}
                          </h4>
                          <p className="fs-6">
                            {t("cgvu.the_site_is_freely_accessible_to_any")}{" "}
                            <br />
                            {t(
                              "cgvu.checking_the_above_mentioned_boxes_will_be_deemed"
                            )}{" "}
                            <br />
                            {t(
                              "cgvu.these_general_conditions_are_applicable_to"
                            )}{" "}
                            <br />
                            {t("cgvu.acceptance_of_these_general_conditions")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 5. {t("cgvu.purpose_of_the_site")}{" "}
                          </h4>
                          <p className="fs-6">
                            {t("cgvu.the_purpose_of_site_is_to_provide_an")}{" "}
                            <br />
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 6. {t("cgvu.cobagaging_obligation")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            6.1 {t("cgvu.provision_of_the_platform")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.cobagage_undertakes_to_provide_the_traveller_with_an"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            6.2 {t("cgvu.site_maintenance")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_undertakes_to_host_an_site")}{" "}
                            <br />
                            {t("cgvu.in_this_regard_cobagage_will_bear_all")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 7. {t("cgvu.obligation_of_sender")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            7.1 {t("cgvu.publication_of_an_announcement")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.1.1 {t("cgvu.publication_announcement")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.publication_of_add_is_open_to")} <br />
                            {t("cgvu.the_sender_acknowledges")} <br />
                            {t("cgvu.it_is_expressively_brought_to_attention")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.1.2 {t("cgvu.add_content")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.the_sender_must_specify_the_following_request"
                            )}
                          </p>
                          <ul className="ps-2 ps-md-4">
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t(
                                    "cgvu.the_place_and_approximate_date_of_departure"
                                  )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t(
                                    "cgvu.the_possible_mode_of_transport_desired"
                                  )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.the_place_time_and_date_of_arrival")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.the_identity_of_recipient")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.the_number_of_killogram_necessary")}
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            7.2 {t("cgvu.purchase_of_product_by_shipper")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.2.1 {t("cgvu.purchase_of_product")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.the_purchase_of_product_on_site_is")}{" "}
                            <br />
                            {t("cgvu.aquisition_of_product_as_part_of")} <br />
                            {t(
                              "cgvu.the_sender_has_the_possiblity_of_aquiring"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.2.2 {t("cgvu.validation_of_purchase")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.once_order_has_been_placed")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.2.3 {t("cgvu.payment_by_sender")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.as_soon_as_they_are_connected")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.2.4 {t("cgvu.confirmation_of_order_by_cobagage")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.once_payment_has_actually_been_received")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.2.5 {t("cgvu.Delivery")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.delivery_of_the_product_will_be_made")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            7.3 {t("cgvu.taking_out_a_subscription")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.3.1 {t("cgvu.subscription_purchase")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.in_order_to_benifit_from_certain_service")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.3.2 {t("cgvu.subscription_duraion")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.the_subscription_takes_effect_from")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            7.3.3 {t("cgvu.early_termination_of_subscription")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.in_the_event_of_failure_by_shipper")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 8. {t("cgvu.declaration_and_obligation")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            8.1 {t("cgvu.sender_information")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_shipper_declares_that")} <br />
                            {t(
                              "cgvu.the_shipper_acknowledges_that_its_needs"
                            )}{" "}
                            <br />
                            {t("cgvu.the_shipper_undertakes_to_inform")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            8.2 {t("cgvu.authorization_and_declarion")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_sender_is_solely_responsible_for")}{" "}
                            <br />
                            {t("cgvu.the_lack_of_such_declarion")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            8.3 {t("cgvu.charter_of_good_conductor")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_sender_undertakes_not_to_publish")}
                          </p>
                          <ul className="ps-2 ps-md-4">
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.harm_or_make_comment")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.infringe_in_any_right")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.be_denigrating_defamatory")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.make_comment_of_a_pedophilic")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t(
                                    "cgvu.undermine_the_security_or_integraty"
                                  )}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.allows_third_parties_to_obtain")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.the_intellectual_property")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.insite_hatred_voilence")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.insite_to_commit_a_crome")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.insite_descrimination_against")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.advice_a_questionable")}</div>
                              </div>
                            </li>
                          </ul>
                          <p className="fs-6">
                            {t("cgvu.any_processing_transmission")} <br />
                            {t("cgvu.any_illegal_or_generally")} <br />
                            {t("cgvu.the_shipper_therefore_guarentees")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 9. {t("cgvu.price_and_payment")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">9.1 {t("cgvu.price")}</h5>
                          <p className="fs-6">
                            {t("cgvu.the_applicable_price_are_those")} <br />
                            {t("cgvu.the_price_indicated_on_site")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            9.2 {t("cgvu.payment_terms")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.shpper_can_make_payment_by_credit_card")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">9.3 {t("cgvu.billing")}</h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_will_make_or_send")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            9.4 {t("cgvu.failure_to_pay")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.agreed_payment_date_can_not_be_delayed")}{" "}
                            <br />
                            {t("cgvu.any_sum_not_paid_on_due_date")} <br />
                            {t("cgvu.in_addition_any_late_payment_will_result")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 10. {t("cgvu.complaint_withdrawal")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            10.1 {t("cgvu.customer_service")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.site_customer_service_is_accessible_from")}
                            :{" "}
                            <a href="mailto:cobagagecontact@cobagage.com">
                              cobagagecontact@cobagage.com
                            </a>
                            {t("cgvu.or_by_post_to_the_address")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            10.2 {t("cgvu.right_withdrawal_distance")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_sell_being_made_between")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 11. {t("cgvu.personal_space")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            11.1 {t("cgvu.creation_of_personal_space")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.creation_of_personal_space_is_an_essential"
                            )}{" "}
                            <br />
                            {t("cgvu.when_creating_personal_space")} <br />
                            {t("cgvu.the_sender_undertakes_to_carry_out")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            11.2 {t("cgvu.content_of_personal_space")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            11.2.1 {t("cgvu.generalities")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.the_personal_space_allows_the_sender")}{" "}
                            <br />
                            {t(
                              "cgvu.the_pages_relating_to_personal_spaces_are"
                            )}{" "}
                            <br />
                            {t(
                              "cgvu.cobagage_undertakes_to_securely_stire_all"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            11.2.2 {t("cgvu.posting_a_public_profile")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.sender_are_offered_option_of_creating")}{" "}
                            <br />
                            {t(
                              "cgvu.it_is_expressively_understood_that_the_sender"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            11.2.3 {t("cgvu.internal_messeging")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.an_internal_private_messeging_service")}{" "}
                            <br />
                            {t(
                              "cgvu.the_content_of_inboxes_and_outgoings"
                            )}{" "}
                            <br />
                            {t("cgvu.any_sender_who_is_victim_of_abuse")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            11.2.4 {t("cgvu.contribution_from_sender")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.shipper_are_offered_the_option_of")} <br />
                            {t("cgvu.by_clicking_on_the_validate_tab")} <br />
                            {t("cgvu.the_sender_declares_that_it_has_the")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            11.3 {t("cgvu.deletion_of_personal_space")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_reserves_right_to_delete")} <br />
                            {t("cgvu.this_exclusion_is_without")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 12. {t("cgvu.personal_data")}{" "}
                          </h4>
                          <p className="fs-6">
                            {t("cgvu.as_part_of_service_cobagage_will_require")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            12.1 {t("cgvu.identity_of_data_controller")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.the_person_responsible_fora_collection_of"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            12.2 {t("cgvu.identity_of_data_protection_officer")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.data_protection_officer_is")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            12.3 {t("cgvu.data_collecteds")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.1 {t("cgvu.data_collected_from_shippers")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.as_part_of_contractual_relationships")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.2 {t("cgvu.purpose_of_collecting_data")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.data_collected_during_contractual_relationships"
                            )}
                          </p>
                          <ul className="ps-2 ps-md-4">
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.executes_contractual_commitments")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.contact_the_shipper")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.avoid_any_illicit_or")}</div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.enfores_the_general_conditon")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>
                                  {t("cgvu.initiates_legal_proceeding")}
                                </div>
                              </div>
                            </li>
                            <li>
                              <div className="d-flex gap-2">
                                <div>-</div>
                                <div>{t("cgvu.verify_identity_of_sender")}</div>
                              </div>
                            </li>
                          </ul>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.3 {t("cgvu.legal_bases_for_proceedings")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.the_data_collected_has_legal_basis_of_contractual"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.4 {t("cgvu.data_recipient")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.data_collected_can_only_be_consulted")}{" "}
                            <br />
                            {t("cgvu.the_data_whether_in_individual")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.5 {t("cgvu.duration_of_retention_of_personal")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.ther_personal_data_personal_collected")}{" "}
                            <br />
                            {t("cgvu.after_the_retention_period")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.6{" "}
                            {t("cgvu.security_and_confidentiality_of_data")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.personal_data_is_stored_in_secure")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.3.7 {t("cgvu.data_minimization")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.cobagage_may_also_collect_and_process")}{" "}
                            <br />
                            {t("cgvu.cobagage_directs_it")} <br />
                            {t("cgvu.cobagage_undertakes_only_to_retain")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            12.4 {t("cgvu.respect_for_rights")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_shippers_have_following_rights")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.1 {t("cgvu.right_of_information_access")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.cobagage_shipper_have_possiblity")} <br />
                            {t(
                              "cgvu.due_to_cobagage_obligation_of_security"
                            )}{" "}
                            <br />
                            {t("cgvu.to_help_them_in_their_process")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.2 {t("cgvu.right_to_rectification_deletion")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.cobagage_shippers_have_possiblity_of_requesting"
                            )}{" "}
                            <br />
                            {t(
                              "cgvu.cobagage_shippers_may_also_define_general"
                            )}{" "}
                            <br />
                            {t("cgvu.to_help_them_in_their_process")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.3{" "}
                            {t("cgvu.right_to_object_to_data_processing")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.cobagage_shippers_have_possiblity_to_object"
                            )}{" "}
                            <br />
                            {t("cgvu.to_help_them_in_their_process")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.4 {t("cgvu.right_to_data_portablity")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.cobagage_shippers_have_right_to")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.5 {t("cgvu.right_to_retriction_of_processing")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.cobagage_shippers_have_rights_to_request_their"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.6 {t("cgvu.reply_duration")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.cobagage_responds_to_undertakes_to_any_request"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.4.7{" "}
                            {t("cgvu.complaint_to_the_competent_authority")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.if_the_cobagage_shippers_consider_that_cobagage"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            12.5 {t("cgvu.transfer_of_collected_data")}
                          </h5>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.5.1 {t("cgvu.transfer_to_partner")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.cobagage_uses_authorized")} <br />
                            {t("cgvu.cobagage_has_previously_ensured")} <br />
                            {t("cgvu.cobagage_uses_following_subcontractor")}
                          </p>
                        </div>
                        <div className="col-12 mt-2">
                          <table className="table table-bordered text-center ">
                            <thead>
                              <tr>
                                <th className="text-center" colSpan={5}>
                                  {t("privacyPolicy.partner_directory")}
                                </th>
                              </tr>
                              <tr>
                                <th>{t("privacyPolicy.partner")}</th>
                                <th>{t("privacyPolicy.quality")}</th>
                                <th>{t("privacyPolicy.recipent_country")}</th>
                                <th>
                                  {t("privacyPolicy.treatment_carried_out")}
                                </th>
                                <th>{t("privacyPolicy.garanties")}</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>AWS</td>
                                <td>{t("privacyPolicy.sub_contractor")}</td>
                                <td>France</td>
                                <td>{t("privacyPolicy.web_hosting")}</td>
                                <td>
                                  <a href="https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#">
                                    https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Google Suite</td>
                                <td>
                                  {t(
                                    "privacyPolicy.sub_contractor_and_co_contractor"
                                  )}
                                </td>
                                <td>USA (Privacy Shield)</td>
                                <td>
                                  {t("privacyPolicy.management_of_people")}
                                </td>
                                <td>
                                  <a href="https://policies.google.com/privacy">
                                    https://policies.google.com/privacy
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Google Analytics</td>
                                <td>
                                  {t(
                                    "privacyPolicy.sub_contractor_and_co_contractor"
                                  )}
                                </td>
                                <td>USA (Privacy Shield)</td>
                                <td>
                                  {t("privacyPolicy.development_of_commercial")}
                                </td>
                                <td>
                                  <a href="https://policies.google.com/privacy">
                                    https://policies.google.com/privacy
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Universal Analytics</td>
                                <td>
                                  {t(
                                    "privacyPolicy.sub_contractor_and_co_contractor"
                                  )}
                                </td>
                                <td>USA (Privacy Shield)</td>
                                <td>
                                  {t("privacyPolicy.development_of_commercial")}
                                </td>
                                <td>
                                  <a href="https://policies.google.com/privacy">
                                    https://policies.google.com/privacy
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Youtube</td>
                                <td>{t("privacyPolicy.co_responsable")}</td>
                                <td>USA (Privacy Shield)</td>
                                <td>{t("privacyPolicy.video_hosting")}</td>
                                <td>
                                  <a href="https://policies.google.com/privacy?hl=fr&gl=fr">
                                    https://policies.google.com/privacy?hl=fr&gl=fr
                                  </a>
                                </td>
                              </tr>
                              <tr>
                                <td>Spidrontech</td>
                                <td>{t("privacyPolicy.co_responsable")}</td>
                                <td>USA (Privacy Shield)</td>
                                <td>
                                  {t(
                                    "privacyPolicy.digital_marketing_and_web_development"
                                  )}
                                </td>
                                <td>
                                  <a href="https://policies.google.com/privacy?hl=fr&gl=fr">
                                    https://policies.google.com/privacy?hl=fr&gl=fr
                                  </a>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.5.2 {t("cgvu.transfer_upon_requisition")}
                          </h6>
                          <p className="fs-6">
                            {t("cgvu.the_shipper_also_consent_to")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            12.5.3 {t("cgvu.transfer_in_context_of_merger")}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.if_cobagage_involved_in_merger_sale_of_asset"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 13. {t("cgvu.cobagaging_responsiblity")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.1 {t("cgvu.nature_of_cobagaging_application")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_undertakes_to_provide_diligence")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.2 {t("cgvu.forces_majeur_part_of_shipper")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.cobagage_will_not_be_held_liable")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            13.2.1 Force majeure
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.for_the_purpose_of_these_general_condition"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h6 className="fs-5 fw-light textDecoration">
                            13.2.2 {t("cgvu.fault_of_sender")}{" "}
                          </h6>
                          <p className="fs-6">
                            {t(
                              "cgvu.for_the_purpose_of_these_general_conditons_any_misuse"
                            )}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.3 {t("cgvu.technical_problems_hyperlink")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.in_the_event_of_impossiblity_of")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.4 {t("cgvu.damages_payable_by_cobagages")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.in_the_absense_of_legal_or_regulatory")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.5 {t("cgvu.hypertext_link_and_context_of_site")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_content_of_site_is_published")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            13.6 {t("cgvu.responsiblity_as_host")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.data_in_perticular_comment")} <br />
                            {t("cgvu.in_this_context_cobagage_will_benifits")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 14. {t("cgvu.intectual_properties")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            14.1 {t("cgvu.legal_protection_of_site_content")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_content_of_site_may_be_procted")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            14.2{" "}
                            {t("cgvu.conntractual_process_of_site_content")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.the_internet_user_undertakes")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h4
                            style={{
                              textDecorationLine: "underline",
                              color: "#f0592b",
                            }}
                          >
                            ARTICLE 15. {t("cgvu.final_stupulation")}{" "}
                          </h4>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            15.1 {t("cgvu.applicable_right")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.these_general_condition_subjected_to")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            15.2{" "}
                            {t("cgvu.modification_these_general_condition")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.these_general_conditon_may_be_modified_at"
                            )}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">15.3 {t("cgvu.disputes")}</h5>
                          <p className="fs-6">
                            {t("cgvu.pursuance_to_ordinance")}{" "}
                            <a href="http://www.medicys.fr.">www.medicys.fr.</a>{" "}
                            <br />
                            {t("cgvu.in_addition_the_sender_is_informed")}
                            <a href="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show">
                              https://ec.europa.eu/consumers/odr/main/?event=main.home2.show
                            </a>{" "}
                            <br />
                            {t(
                              "cgvu.any_disputes_related_to_this_contract"
                            )}{" "}
                            <a href="http://www.fast-arbitre.com">
                              www.fast-arbitre.com
                            </a>
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">15.4 {t("cgvu.entirely")}</h5>
                          <p className="fs-6">
                            {t("cgvu.the_nullity_of_one_of_the_claauses")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">15.5 {t("cgvu.non_waiver")}</h5>
                          <p className="fs-6">
                            {t("cgvu.the_absence_of_exercise_by_cobagage")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            15.6 {t("cgvu.telephone_canvacassing")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.the_sender_is_informed_that_he_has_possiblity"
                            )}{" "}
                            <a href="http://www.bloctel.gouv.fr/">
                              http://www.bloctel.gouv.fr/
                            </a>{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            15.7 {t("cgvu.lanuages_of_the_general_conditions")}
                          </h5>
                          <p className="fs-6">
                            {t("cgvu.these_general_contions_are_offered_in")}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <h5 className="fs-5">
                            15.8 {t("privacyPolicy.unfair_clauses")}
                          </h5>
                          <p className="fs-6">
                            {t(
                              "cgvu.the_stipulation_of_these_general_condition"
                            )}{" "}
                          </p>
                        </div>
                        <div className="col-12 mt-4 text-center ">
                          <h2 className="fw-bold px-0 px-md-5">
                            {" "}
                            {t("cgvu.order_cancellation_procedure")}
                          </h2>
                        </div>
                        <div className="col-12 mt-3">
                          <p className="fs-6">
                            {t("cgvu.in_the_event_of_distance_selling")} <br />
                            {t("cgvu.any_clauses_in_contract_by_which")}
                          </p>
                        </div>
                        <div className="col-12 mt-3 text-center ">
                          <h2 className="fw-bold px-0 px-md-5">
                            {" "}
                            {t("cgvu.if_you_cancel_your_order")}
                          </h2>
                          <div
                            className="mb-1 mt-2"
                            style={{ borderBottom: "2px dashed" }}
                          >
                            {" "}
                          </div>
                          <div style={{ borderBottom: "2px dashed" }}></div>
                        </div>
                        <div className="col-12 mt-3 text-center ">
                          <h3 className="fw-bold ">
                            {" "}
                            {t("cgvu.order_cancellation")}
                          </h3>
                          <p className="fs-6">{t("cgvu.consumer_court")}</p>
                        </div>
                        <div className="col-12 mt-3">
                          <p className="fs-6">Conditions :</p>
                          <ul>
                            <li>* {t("cgvu.complete_and_sign_this_form")}</li>
                            <li>* {t("cgvu.send_it_by_registered_letter")}</li>
                            <li>* {t("cgvu.use_the_address_in_article_1")}</li>
                            <li>* {t("cgvu.ship_it_no_later_than")}</li>
                          </ul>
                          <p className="mt-3 fs-6">
                            {t("cgvu.i_the_under_signed_declare")}
                          </p>
                        </div>
                        <div className="col-12 mt-3">
                          <p>{t("cgvu.nature_of_goods")}</p>
                          <p
                            className="mt-3"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                        </div>
                        <div className="col-12 mt-3">
                          <p>{t("cgvu.date_of_order")}:</p>
                          <p
                            className="mt-3"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                        </div>
                        <div className="col-12 mt-3">
                          <p>{t("cgvu.date_of_recipt_of_order")}:</p>
                          <p
                            className="mt-3"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                        </div>
                        <div className="col-12 mt-3">
                          <p>{t("cgvu.sender_name")}</p>
                          <p
                            className="mt-3"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                        </div>
                        <div className="col-12 mt-3">
                          <p>{t("cgvu.expeditor_address")}</p>
                          <p
                            className="mt-3"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                          <p
                            className="mt-5"
                            style={{ borderBottom: "1px dashed" }}
                          ></p>
                        </div>
                        <div className="col-12 mt-3 pb-4">
                          <p>{t("cgvu.signature_of_sender")}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
