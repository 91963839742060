import React, { useEffect, useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { Link, useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useValidator from "../../../../plugin/validator";
import { auth } from "../../../../models";
import notistack from "../../../../plugin/notistack";
import Loading from "../../../common/Loading";

function ForgotPasswordWrapperArea() {
  const history = useHistory();
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  const { token, email } = useParams();
  const [data, setData] = useState({
    token: token,
    password: '',
    confirm_password: ''
  });
  const [load, setLoad] = useState(false);
  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);


  const error = {
    password: validator.message(t('auth.new_Password'), data.password, 'required|password|max:200'),
    confirm_password: validator.message(t("auth.confirm_password"), data.confirm_password, `required|password|max:200|in:${data.password}`),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      setLoad(true);
      auth.resetPassword(data).then(() => {
        history.push('/login');
        setLoad(false);
      })
        .catch((error) => {
          setLoad(false);
          notistack.error(error?.data.message);
        })
    }
    else {
      showMessage(true);
    }
  }
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div className='pt-110 pb-110'>
          <div className='container'>
            <div className='row justify-content-center'>
              <div className='col-lg-6'>
                <div className='package-sidebar'>
                  <aside className='package-widget-style-2 widget-form'>
                    <div className='widget-title text-center d-flex justify-content-between'>
                      <h4>{t("auth.forgot_password")}</h4>
                    </div>
                    <div className='widget-body'>
                      <form onSubmit={(e) => handleSubmit(e)} >
                        <div className='booking-form-wrapper'>
                          <div className='custom-input-group'>
                            <input
                              type='text'
                              id='password'
                              name='password'
                              value={data.password}
                              placeholder={t("auth.new_Password")}
                              className={!!error.password ? 'is-invalid' : ''}
                              onChange={handleChange}
                            />
                            <div className='invalid-feedback'>{error.password}</div>
                          </div>
                          <div className='custom-input-group'>
                            <input
                              type='text'
                              id='email_or_contact'
                              name='confirm_password'
                              value={data.confirm_password}
                              placeholder={t("auth.confirm_password")}
                              className={!!error.confirm_password ? 'is-invalid' : ''}
                              onChange={handleChange}
                              autoComplete='email'
                            />
                            <div className='invalid-feedback'>{error.confirm_password}</div>
                          </div>
                          <div className='custom-input-group'>
                            <div className='submite-btn '>
                              <button type='button' className='button-fill-round' onClick={handleSubmit}> {t('submit')}</button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ForgotPasswordWrapperArea;
