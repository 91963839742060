import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
function LegalsWrapperArea() {
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();
  return (
    <>
      <div className="container">
             <div className="text-center mt-5">
              <h3 style={{fontWeight:'blod'}}>{t('profile.Legals')}</h3>
              <h3 className="mt-2">{t('profile.from_the_website')} <span style={{color:'blue',textDecoration:"underLine"}}>www.cobagage.com</span></h3>
              <p style={{fontSize:"18px"}}>{t('profile.legal_text')}</p>
              </div>
                <h3 className="mt-4"> <span style={{ color: '#f0592b', textDecoration: 'underline' }} >ARTICLE 1. {t("profile.EDITOR")}</span></h3><br />
                {/* <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 1. PARTIES</h5> */}
                <p style={{ fontSize: '18px' }}>
                  {t('profile.legal_text1')} <span style={{color:'blue',textDecoration:"underLine"}}>www.cobagage.com</span> {t('profile.legal_text2')}<span style={{color:'blue',textDecoration:"underLine"}}>lyao@cobagage.com</span> {t('profile.legal_text3')}
                </p><br />
                <h3 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 2. {t("profile.HOST")}</h3><br />
                <p style={{ fontSize: '18px' }}>
                  {t("profile.legal_text4")}
                </p><br />
             
           
        
      </div>
    </>
  );
}

export default LegalsWrapperArea;
