import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import AppContext from "../../../../store/context/app-context";

export default function RecentLuggageCard(props) {
  const contextObj = useContext(AppContext);
  const history = useHistory();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();

  return (
    <div
      className="package-card-delta"
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        history.push(
          contextObj.login == true
            ? `${process.env.PUBLIC_URL}/ad_details/${props.item.id}/5`
            : "/login"
        );
      }}
    >
      <div className="package-thumb">
        <Link
          to={
            contextObj.login == true
              ? `${process.env.PUBLIC_URL}/ad_details/${props.item.id}/5`
              : "/login"
          }
        >
          <img
            src={props.item.image[0]}
            alt=""
            style={{
              aspectRatio: "3/2",
              objectFit: "contain",
              padding: "15px",
              borderRadius: "20px",
            }}
          />
        </Link>
      </div>
      <div className="package-card-body p-2">
        <div className="">
          <div className="row icons_container">
            <div className="col-2">
              <img
                src={props.item.departure_flag}
                style={{ width: "100%", objectFit: "contain" }}
              ></img>
            </div>
            <div className="col-3 icon facebook" style={{ paddingLeft: "0px" }}>
              {props?.item.city_departure_name.length > 4
                ? props?.item.city_departure_name.slice(0, 4) + ".."
                : props?.item.city_departure_name}
              <div class="tooltip">{props?.item?.city_departure_name}</div>
            </div>
            <div className="col-2">
              <i className="bx bxs-right-arrow-alt" />
            </div>
            <div className="col-2">
              <img
                src={props.item.arrival_flag}
                style={{ width: "100%" }}
              ></img>
            </div>
            <div
              className="col-3 icons_container icon facebook"
              style={{ paddingLeft: "0px" }}
            >
              {props?.item.city_arrival_name.length > 4
                ? props?.item.city_arrival_name.slice(0, 4) + ".."
                : props?.item.city_arrival_name}
              <div class="tooltip">{props?.item?.city_arrival_name}</div>
            </div>
          </div>
          <table
            className="col-lg-12 col-12 col-md-12 col-sm-12"
            style={{ fontSize: "16px", fontWeight: "700" }}
          >
            <tr>
              <td
                style={{ fontSize: "12px", fontWeight: "500" }}
                className="text-decoration-underline"
              >
                {t("form.Posted_By")}:
              </td>
              <td></td>
              <td
                className="text-end text-decoration-underline"
                style={{ fontSize: "12px", fontWeight: "500" }}
              >
                {t("form.packer_type")}:
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "12px" }}>
                <i class="bi bi-person-fill" />{" "}
                <span style={{ color: "#1e98d7" }}>
                  {props?.item?.user_name}
                </span>
              </td>
              <td></td>
              <td className="text-end" style={{ fontSize: "12px" }}>
                {props.item.sender_type == 1
                  ? t("form.one_off")
                  : props.item.sender_type == 2
                  ? t("form.periodical")
                  : t("form.Regular")}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "12px" }}>
                {t("form.Weight")}: {props.item.weight} Kg
              </td>
              <td></td>
              <td className="text-end" style={{ fontSize: "12px" }}>
                {" "}
                {t("profile.Worth")}: {props.item.luggage_estimation}{" "}
                {props?.item?.currency}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div className="package-card-body" style={{ padding: "14px 10px" }}>
        <div className="p-card-bottom" style={{ marginTop: "0px" }}>
          <div className="book-btn">
            <Link
              to={
                contextObj.login == true
                  ? `${process.env.PUBLIC_URL}/ad_details/${props.item.id}/5`
                  : "/login"
              }
              style={{
                fontSize: "9px",
                fontWeight: "600",
                borderRadius: "5px",
              }}
              onClick={() => scrollTop()}
            >
              {t("profile.VIEW_LUGGAGE_DETAILS")}{" "}
              <i className="bx bxs-right-arrow-alt" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
