import React, { useContext, useEffect, useState } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomePage from "../pages/Home/HomePage";
import HomeFour from "../pages/homeFour/HomeFour";
import Loading from "../common/Loading";
import HeaderFour from "../pages/homeFour/HeaderFour";
import CookiesPage from "../pages/cookie/CookiesPage";
import AppContext from "../../store/context/app-context";
import Cookies from "js-cookie";
import { useLocation, useHistory } from "react-router-dom";

function MainLayout() {
  const [load, setLoad] = useState(false);
  const [showCookie, setShowCookie] = useState(
    !!Cookies.get("cookiesAccepted")
      ? Cookies.get("cookiesAccepted")
      : !!Cookies.get("cookiesAccepted")
  );
  const contextObj = useContext(AppContext);
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 3000);
  }, []);

  // useEffect(() => {
  //   checkURL();
  // }, [contextObj.login]);

  // const checkURL = () => {
  //   if (contextObj.login && location.pathname === "/register") {
  //     history.push("/home");
  //   }
  // };

  const handleCookiesAcceptance = (accept) => {
    Cookies.set("cookiesAccepted", accept, { expires: 365 });
    setShowCookie(accept == "Refuse" ? true : accept);
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <HeaderFour />
          <HomeFour />
          {contextObj.login ? (
            <div
              style={{
                position: "fixed",
                bottom: 0,
                display: showCookie
                  ? showCookie !== "Refuse"
                    ? "none"
                    : "block"
                  : "block",
                zIndex: 9999,
              }}
            >
              <CookiesPage setShowCookie={handleCookiesAcceptance} />
            </div>
          ) : (
            ""
          )}
          {/* <Footer className="footer-area" /> */}
        </>
      )}
    </>
  );
}

export default MainLayout;
