import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from "react-simple-star-rating";
import RecentTravelCard from "../profile/swipersData/RecentTravelCard";
import { useMediaQuery } from "@mui/material";
import travel from "../../../models/travel";
import { Link, useHistory } from "react-router-dom";
import RecentLuggageCard from "../profile/swipersData/RecentLuggageCard";
import luggage from "../../../models/luggage";
import { useContext } from "react";
import AppContext from "../../../store/context/app-context";
import moment from "moment";

export default function MoreUpdate(props) {
  const { t } = useTranslation();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [luggageRequest, setLuggageRequest] = useState();
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const mediumViewport = useMediaQuery("(min-width:860px)");
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    luggage
      .list(userId)
      .then((item) => {
        console.log("Item in response: ", item);
        setLuggageRequest(item?.filter((item) => item?.isComplete == false)); 
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const viewDetails = () => {
    history.push("/cobagage_travel_announcements");
  };
  const viewDetailsluggage = () => {
    history.push("/cobagage_luggage_announcements");
  };

  const addTraveller = (radioData) => {
    contextObj.setTabValue(0);
    scrollTop();
    contextObj.setRadioValue(radioData);
    history.push({ pathname: "/myProfile" });
  };

  const setTologin = ()=>{
    scrollTop();
    history.push("/login")
  }

  return (
    <div>
      <div
        class="package-area package-style-two chain"
        // style={{ marginTop: props.open || props.frist ? mediumViewport ? "130px" : null : "", background: '#f4f4f4', paddingBottom: '20px' }}
        style={{
        
          background: "#fee5e5",
          paddingBottom: "20px",
        }}
      >
        <div className="container">
          <div className="tour-package-details">
            <div className="package-details-tabs">
              
              <div className="tab-content" id="pills-tabContent">
                {/* Travel */}
                <div
                  className="tab-pane fade show active package-info-tab "
                  id="pill-body1"
                  role="tabpanel"
                  aria-labelledby="pills-package1"
                >
                    <div className="">
                      <div className="row d-flex justify-content-center  pt-4">
                        <div
                          className="testimonial-area testimonial-style-three  p-0"
                          style={{ backgroundImage: "none" }}
                        >
                          <div className="container">
                            <div className="row d-flex justify-content-center align-items-center p-0">
                              <div className="col-lg-6 col-sm-10 px-0 mx-0">
                                <div className="section-head-alpha text-lg-start text-center pb-0">
                                  {/* <h2 className='fs-5'>Recent travel announcements</h2> */}
                                  <p className="fs-4 fw-bold">
                                    {t(
                                      "profile.recent_luggage_sending_requests"
                                    )}
                                  </p>
                                </div>
                              </div>
                              <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                <div
                                  className="d-flex d-lg-block d-none"
                                  style={{ marginRight: "20px" }}
                                >
                                  <button
                                    className="nav-link active"
                                    type="button"
                                    onClick={() => {
                                      contextObj.login
                                        ? addTraveller(2)
                                        : setTologin();;
                                    }}
                                    style={{
                                      borderRadius: "5px",
                                      color: "#fff",
                                      border: "1px solid #53bde2",
                                      backgroundColor: "#53bde2",
                                      padding: "8px 25px",
                                      boxShadow:
                                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                                    }}
                                  >
                                    {t("homePage.l_send_luggages")}
                                  </button>
                                </div>
                                {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                  <div
                                    className="testi-prev4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Previous slide"
                                  >
                                    {" "}
                                    <i className="bi bi-arrow-left" />
                                  </div>
                                  <div
                                    className="testi-next4"
                                    tabIndex={0}
                                    role="button"
                                    aria-label="Next slide"
                                  >
                                    <i className="bi bi-arrow-right" />
                                  </div>
                                </div> */}
                              </div>
                            </div>
                            <div className="row p-3">
                              <div className=" col-12 text-end">
                                <button
                                  className="fs-6"
                                  onClick={() => (viewDetailsluggage(), scrollTop())}
                                  // onClick={viewDetailsluggage}
                                  style={{
                                    color: "#ff4838",
                                    border: "0px",
                                    backgroundColor: "#fff",
                                    background: "#fee5e5",
                                  }}
                                >
                                  {t("profile.view_all_luggage_requests")}{" "}
                                  <i className="bi bi-arrow-right-circle" />
                                </button>
                              </div>
                            </div>
                            <div className="row align-items-center justify-content-center">
                              <div className="col-lg-12 p-0">
                                <Swiper
                                  className="swiper testimonial-slider-three"
                                  slidesPerView={1}
                                  speed={500}
                                  spaceBetween={5}
                                  loop={false}
                                  roundLengths={true}
                                  autoplay={{
                                    delay: 5000,
                                  }}
                                  navigation={{
                                    nextEl: ".testi-next4",
                                    prevEl: ".testi-prev4",
                                  }}
                                  breakpoints={{
                                    480: {
                                      slidesPerView: 1,
                                    },
                                    768: {
                                      slidesPerView: 2,
                                    },
                                    992: {
                                      slidesPerView: 2,
                                    },
                                    1200: {
                                      slidesPerView: 4,
                                    },
                                  }}
                                >
                                  <div className="swiper-wrapper">
                                    {luggageRequest?.map((item) => {if(!item.isComplete)  return(
                                      <SwiperSlide
                                        className="swiper-slide"
                                        style={{ padding: "10px" }}
                                      >
                                        <RecentLuggageCard
                                          item={item}
                                          data={"viewAllLuggageRequest"}
                                        />
                                      </SwiperSlide>
                                    )})}
                                  </div>
                                </Swiper>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
              </div>
              <div className="mt-2 mb-3 d-flex justify-content-end d-lg-none d-block">
                
                  <button
                    className="nav-link active"
                    type="button"
                    onClick={() => {
                      contextObj.login
                        ? addTraveller(2)
                        : setTologin();;
                    }}
                    style={{
                      borderRadius: "5px",
                      color: "#fff",
                      border: "1px solid #53bde2",
                      backgroundColor: "#53bde2",
                      padding: "8px 25px",
                      boxShadow:
                        "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
                    }}
                  >
                    {t("homePage.l_send_luggages")}
                  </button>
               
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
