import React from "react";
import SubmitLuggageWrapperArea from "./SubmitLuggageWrapperArea";
import Breadcrumb from "../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function SubmitLuggage() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("menu.submit_an_ad")}/>
      <SubmitLuggageWrapperArea />
     
    </>
  );
}

export default SubmitLuggage;
