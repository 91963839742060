import React, { useEffect, useState, useContext } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import moment from "moment/moment";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import DatePicker from "react-datepicker";
import { country } from "../../../../models";
import AppContext from "../../../../store/context/app-context";
import travel from "../../../../models/travel";
import luggage from "../../../../models/luggage";

const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_stopover: {},
  state_of_stopover: {},
  city_of_stopover: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  stopoverCountry: [],
  stopoverState: [],
  stopoverCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

export default function MyTravelsEditPopup({ props, show, close }) {
  const { t, i18n } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [data, setData] = useState();
  const [image, setImage] = useState(props?.item?.image);
  const [editImg, setEditImg] = useState();
  const [details, setDetails] = useState(format);
  const [isShow, setIsShow] = useState(true);
  const [isRadio, setIsRadio] = useState(true);
  const [tempImages, setTempImages] = useState();
  const [currencyData, setCurrencyData] = useState([]);
  const contextObj = useContext(AppContext);
  useEffect(() => {
    getCurrency();
    getData();
    setData(props.item);
    handleSetData("insurance", true);
    handleSetData("stopover_in_trip", false);
    getDepartureStateData(props?.item?.country_departure);
    getDepartureCity(props?.item?.state_departure);
    getArrivalStateData(props?.item?.country_arrival);
    getArrivalCity(props?.item?.state_arrival);
    getStopoverStateData(props?.item?.country_stopover);
    getStopoverCity(props?.item?.state_stopover);

  }, []);
  useEffect(() => {
    convert();
  }, [show])

  // useEffect(() => {
  //   convert();
  // }, [props?.item?.image])

  const convert = () => {
    let arr = [];
    props?.item?.image.map((item) => {
      fetch(item)
        .then((response) => {
          let blob = response.blob();
          blob.then((blob) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              let baseURL = reader.result;
              arr.push(baseURL);
            };
          });
        })
        .catch((error) => {
          console.log("converterrorTravel:", error);
        });
    });
    handleSetData("images", arr);
  };

  const error = {
    title: validator.message(t("title"), data?.title, "required|max:200"),
    receiver_contact_number: validator.message(t("receiver_contact_number"), data?.receiver_contact_number, "required|max:200"),
    weight: validator.message(t("weight"), data?.weight, "required"),
    country_departure: validator.message(
      t("country_departure"),
      data?.country_departure,
      "required"
    ),
    trip_type: validator.message(t("trip_type"), data?.trip_type, "required"),
    country_arrival: validator.message(
      t("country_arrival"),
      data?.country_arrival,
      "required"
    ),
    state_departure: validator.message(
      t("state_departure"),
      data?.state_departure,
      "required"
    ),
    state_arrival: validator.message(
      t("state_arrival"),
      data?.state_arrival,
      "required"
    ),
    city_departure: validator.message(
      t("city_departure"),
      data?.city_departure,
      "required"
    ),
    city_arrival: validator.message(
      t("city_arrival"),
      data?.city_arrival,
      "required"
    ),
    pickup_place: validator.message(
      t("pickup_place"),
      data?.pickup_place,
      "required"
    ),
    delivery_place: validator.message(
      t("delivery_place"),
      data?.delivery_place,
      "required"
    ),
    departure_date: validator.message(
      t("departure_date"),
      data?.departure_date,
      "required"
    ),
    arrival_date: validator.message(
      t("arrival_date "),
      data?.arrival_date,
      "required"
    ),
    departure_time: validator.message(
      t("departure_time "),
      data?.departure_time,
      "required"
    ),
    arrival_time: validator.message(
      t("arrival_time "),
      data?.arrival_time,
      "required"
    ),
    carriage_capacity: validator.message(
      t("carriage_capacity "),
      data?.carriage_capacity,
      "required"
    ),
    price_per_kg: validator.message(
      t("price_per_kg"),
      data?.price_per_kg,
      "required"
    ),
    vehicle_type: validator.message(
      t("vehicle_type"),
      data?.vehicle_type,
      "required"
    ),
    stopover_in_trip: validator.message(
      t("stopover_in_trip"),
      data?.stopover_in_trip,
      "required"
    ),
    traveller_type: validator.message(
      t("traveller_type"),
      data?.traveller_type,
      "required"
    ),
    currency_id: validator.message(t('currency'), data?.currency_id, 'required'),
  };

  const convertToBase64 = (e) => {
    let tempFile = e.target.files[0];
    if (tempFile.size > 4000000) {
      notistack.error(t("form.File_Size"));
      return false;
    } else {
      let arrUrl = [...image];
      arrUrl[editImg] = URL.createObjectURL(e.target.files[0]);
      setImage(arrUrl);
      getBase64Image(tempFile)
        .then((responseBase64) => {
          let arr = data?.images ? [...data?.images] : ["", "", "", ""];
          arr[editImg] = responseBase64;
          handleSetData("images", arr);
        })
        .catch((err) => {
          console.log("Error While convert to base64: ", err);
        });
    }
  };

  const getBase64Image = (fileParam) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
        handleData("arriavalCountry", details);
        handleData("stopoverCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getDepartureStateData = (id) => {
    if (id)
      country.state(id).then((item) => {
        handleData("departureState", item);
      });
  };

  const getArrivalStateData = (id) => {
    if (id)
      country.state(id).then((details) => {
        handleData("arriavalState", details);
      });
  };
  const getDepartureCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("departureCity", details);
      });
  };
  const getStopoverCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("stopoverCity", details);
      });
  };
  const getArrivalCity = (id) => {
    if (id)
      country.city(id).then((details) => {
        handleData("arriavalCity", details);
      });
  };

  const getStopoverStateData = (id) => {
    if (id)
      country.state(id).then((details) => {
        handleData("stopoverState", details);
      });
  };

  const returnError = {
    return_departure_date: validator.message(
      t("return_departure_date"),
      data?.return_departure_date,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_arrival_date: validator.message(
      t("return_arrival_date"),
      data?.return_arrival_date,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_departure_time: validator.message(
      t("return_departure_time"),
      data?.return_departure_time,
      data?.trip_type == 2 ? "required" : ""
    ),
    return_arrival_time: validator.message(
      t("return_arrival_time"),
      data?.return_arrival_time,
      data?.trip_type == 2 ? "required" : ""
    ),
  };

  const showError = () => {
    if (validator.allValid()) {
      showMessage(false);
    } else {
      showMessage(true);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("handleChange name and value: ", name, value);
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = (event) => {
    if (data.images.length >= 3) {

      luggage
        .update(props?.item?.id, data)
        .then((response) => {
          notistack.success(t("form.updated_Successfully"));
          setData(response);
          props.getData();
          handleClose();
        })
        .catch((error) => {
          console.log("error", error);
          notistack.error(error?.data?.message);
        });
    } else {
      notistack.error(t("form.minimum_3maximum_4images"));
    }
  };

  const handleClose = () => {
    close();
  };

  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target.value?.slice(1);
  };
  const getCurrency = () => {
    country.currency().then((respons) => {
      setCurrencyData(respons);
    })
  }
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }
  return (
    <>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{t("form.luggage_ad")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      onInput={onInputUppercase}
                      value={data?.title}
                    />
                    <span style={{ color: "red" }}>{error.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Type_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="type"
                      onChange={handleChange}
                      name="type"
                      value={data?.type}
                    >
                      <option value={0} selected disabled>
                        {t("form.Type_luggage")}
                      </option>
                      <option value={1}>{t("form.New_Never_Used")}</option>
                      <option value={2}>{t("form.Used_Secound_hand")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_Of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_departure"
                      onChange={(e) => {
                        handleChange(e, "country_of_Departure");
                        handleSetData("state_departure", "");
                        handleSetData("city_departure", "");
                        getDepartureStateData(e.target.value);
                      }}
                      name="country_departure"
                      value={data?.country_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.country_Of_departure")}
                      </option>
                      {details.departureCountry?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_arrival"
                      onChange={(e) => {
                        handleChange(e, "country_arrival");
                        handleSetData("state_arrival", "");
                        handleSetData("city_arrival", "");
                        getArrivalStateData(e.target.value);
                      }}
                      name="country_arrival"
                      value={data?.country_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.country_of_arrival")}
                      </option>
                      {details.arriavalCountry?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_arrival}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.state_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_departure"
                      onChange={(e) => {
                        handleChange(e, "state_of_Departure");
                        handleSetData("city_departure", "");
                        getDepartureCity(e.target.value);
                      }}
                      name="state_departure"
                      value={data?.state_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_departure")}
                      </option>
                      {details.departureState?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.state_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.state_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_arrival"
                      onChange={(e) => {
                        handleChange(e, "state_arrival");
                        handleSetData("city_arrival", "");
                        getArrivalCity(e.target.value);
                      }}
                      name="state_arrival"
                      value={data?.state_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_arrival")}
                      </option>
                      {details.arriavalState?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.state_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.city_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_departure"
                      onChange={(e) => {
                        handleChange(e, "city_of_Departure");
                      }}
                      name="city_departure"
                      value={data?.city_departure}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_departure")}
                      </option>
                      {details.departureCity?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.city_departure}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.city_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_arrival"
                      onChange={(e) => {
                        handleChange(e, "city_arrival");
                      }}
                      name="city_arrival"
                      value={data?.city_arrival}
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_arrival")}
                      </option>
                      {details.arriavalCity?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.city_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name" style={{ display: "block" }}>
                      {t("form.departure_date")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        selected={moment(
                          data?.departure_date,
                          "DD/MM/YYYY"
                        ).toDate()} // Use a format that DatePicker can parse
                        dateFormat={
                          i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                        } // Display format
                        onChange={(date) =>
                          handleSetData(
                            "departure_date",
                            moment(date).format("DD/MM/YYYY")
                          )
                        }
                        minDate={new Date()}
                        className="px-3"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name" style={{ display: "block" }}>
                      {t("form.arrival_date")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div className="customDatePickerWidth">
                      <DatePicker
                        selected={moment(
                          data?.arrival_date,
                          "DD/MM/YYYY"
                        ).toDate()} // Use a format that DatePicker can parse
                        dateFormat={
                          i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                        } // Display format
                        onChange={(date) =>
                          handleSetData(
                            "arrival_date",
                            moment(date).format("DD/MM/YYYY")
                          )
                        }
                        minDate={moment(
                          data?.departure_date,
                          "DD/MM/YYYY"
                        ).toDate()}
                        className="px-3"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="row col-lg-12" style={{ marginTop: "20px" }}>
                <label htmlFor="name">{t("form.Luggage_Dimensions")}:</label>
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.height")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.height")}
                      id="height"
                      onChange={handleChange}
                      name="height"
                      value={data?.height}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.carriage_capacity ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.length")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder="length"
                      id="length"
                      onChange={handleChange}
                      name="length"
                      value={data?.length}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.width")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder="width"
                      id="width"
                      onChange={handleChange}
                      name="width"
                      value={data?.width}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                  </div>
                </div>
              </div>
              <div className="row col-lg-12">
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Weight")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.Weight")}
                      id="weight"
                      onChange={handleChange}
                      name="weight"
                      value={data?.weight}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.weight ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      kg
                    </span>
                    <span style={{ color: "red" }}>{error.weight}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Colour")}</label>
                    <input
                      type="text"
                      placeholder={t("form.Colour")}
                      id="colour"
                      onChange={handleChange}
                      name="colour"
                      value={data?.colour}
                    />
                  </div>
                </div>
                <div className="col-12 col-sm-4 ">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Volume")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.Volume")}
                      id="volume"
                      onChange={handleChange}
                      name="volume"
                      value={data?.volume}
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.contents_under_warranty")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  setIsRadio(true);
                                  handleSetData("warranty", false);
                                }}
                                checked={isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  setIsRadio(false);
                                  handleSetData("warranty", true);
                                }}
                                checked={!isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.estimate_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.estimate_of_luggage")}
                      id="luggage_estimation"
                      onChange={handleChange}
                      name="luggage_estimation"
                      value={data?.luggage_estimation}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.luggage_estimation ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      {contextObj?.loginUser?.symbol}
                    </span>
                    <span style={{ color: "red" }}>
                      {error.luggage_estimation}
                    </span>
                  </div>
                </div>
                {/* <div className="col-lg-2">
                  <div className="custom-input-group ">
                    <label htmlFor="name">{t("form.currency")}<span style={{ color: 'red' }}>*</span></label>
                    <select
                      required
                      id="currency_id"
                      onChange={(e) => {
                        handleChange(e, "currency_id");
                      }}
                      name='currency_id'
                      value={data?.currency_id}
                    >
                      <option value="" selected disabled>{t("form.Select_currency")}</option>
                      {currencyData.map((item) => <option value={item.id} key={item.id}>{item.code} ({item.symbol})</option>)}
                    </select>
                    <span style={{ color: "red" }}>{error?.currency_id}</span>
                  </div>
                </div> */}
              </div>
              <div className="row col-lg-12">
                <label htmlFor="name">{t("form.receiver_details")}:</label>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Name_of_receiver")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_pick_up_place")}
                      id="receiver_name"
                      onChange={handleChange}
                      name="receiver_name"
                      onInput={onInputUppercase}
                      value={data?.receiver_name}
                    />
                    <span style={{ color: "red" }}>{error.receiver_name}</span>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.Phone_code")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="receiver_phone_code"
                      onChange={(e) => {
                        handleChange(e, "receiver_phone_code");
                      }}
                      name="receiver_phone_code"
                      value={data?.receiver_phone_code}
                    >
                      {details.arriavalCountry?.map((item) => (
                        <option value={item.phone_code} key={item.id}>
                          {item.phone_code}
                        </option>
                      ))}
                    </select>
                    <span style={{ color: "red" }}>
                      {error.receiver_phone_code}
                    </span>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.Phone_Contact_number")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      id="receiver_contact_number"
                      onChange={handleChange}
                      name="receiver_contact_number"
                      value={data?.receiver_contact_number}
                    />
                    <span style={{ color: "red" }}>
                      {error.receiver_contact_number}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.getting_your_luggage")}?
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", false);
                                  // setShow(true);
                                }}
                                checked={isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", true);
                                  // setShow(false);
                                }}
                                checked={!isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Your_relationship_receiver")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      id="receiver_relation"
                      onChange={handleChange}
                      name="receiver_relation"
                      value={data?.receiver_relation}
                    />
                    <span style={{ color: "red" }}>
                      {error.receiver_relation}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Location_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("location")}
                      id="location"
                      onChange={handleChange}
                      name="location"
                      onInput={onInputUppercase}
                      value={data?.location}
                    />
                    <span style={{ color: "red" }}>{error.location}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Luggage_Sender_Type")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="sender_type"
                      onChange={handleChange}
                      name="sender_type"
                      value={data?.sender_type ? data?.sender_type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.Luggage_Sender_Type")}
                      </option>
                      <option value={1}>
                        {t("form.RegularI_send_luggages_reguler")}
                      </option>
                      <option value={2}>
                        {t("form.Periodic_send_luggages_periodically")}
                      </option>
                      <option value={3}>
                        {t("form.One_off_luggage_sender")}
                      </option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.sender_type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.detail_content_luggage_carried")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <textarea
                      rows={4}
                      id="description"
                      onChange={handleChange}
                      name="description"
                      value={data?.description}
                      placeholder="description"
                      maxLength={160}
                    ></textarea>
                    {/* <span style={{ color: "red" }}>{error.description}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div>
                    <h3>
                      {t("menu.gallery")} (
                      {t("form.Upload_image_in_1280_720_dimension")})
                    </h3>
                    <br />
                    <label htmlFor="name" style={{ color: "#ff4838" }}>
                      {t("form.You_can_add_4_images_maximum")}
                    </label>
                  </div>
                </div>
                {image && (
                  <div className="col-lg-12 row pt-1">
                    <div
                      className="col-12 col-lg-3 col-sm-3"
                      style={{ border: "1px dotted" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {" "}
                        {t("profile.Exterior_1")}
                      </span>
                      <img
                        src={image[0]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                      <button
                        type="button"
                        onClick={() => setEditImg(0)}
                        style={{ border: "0px", backgroundColor: "#fff" }}
                      >
                        <label for="file">
                          <i
                            class="bi bi-pencil-square"
                            style={{ color: "green", cursor: "pointer" }}
                          />
                        </label>
                      </button>
                      <input
                        type="file"
                        id="file"
                        className="d-none"
                        onChange={(e) => {
                          convertToBase64(e);
                        }}
                      />
                    </div>
                    <div
                      className="col-12 col-lg-3 col-sm-3"
                      style={{ border: "1px dotted" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {t("profile.Exterior_2")}
                      </span>
                      <img
                        src={image[1]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                      <button
                        type="button"
                        onClick={() => setEditImg(1)}
                        style={{ border: "0px", backgroundColor: "#fff" }}
                      >
                        <label for="file2">
                          <i
                            class="bi bi-pencil-square"
                            style={{ color: "green", cursor: "pointer" }}
                          />
                        </label>
                      </button>
                      <input
                        type="file"
                        id="file2"
                        className="d-none"
                        onChange={(e) => {
                          convertToBase64(e);
                        }}
                      />
                    </div>
                    <div
                      className="col-12 col-lg-3 col-sm-3"
                      style={{ border: "1px dotted" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {t("profile.Interior_1")}
                      </span>
                      <img
                        src={image[2]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                      <button
                        type="button"
                        onClick={() => setEditImg(2)}
                        style={{ border: "0px", backgroundColor: "#fff" }}
                      >
                        <label for="file3">
                          <i
                            class="bi bi-pencil-square"
                            style={{ color: "green", cursor: "pointer" }}
                          />
                        </label>
                      </button>
                      <input
                        type="file"
                        id="file3"
                        className="d-none"
                        onChange={(e) => {
                          convertToBase64(e);
                        }}
                      />
                    </div>
                    <div
                      className="col-12 col-lg-3 col-sm-3"
                      style={{ border: "1px dotted" }}
                    >
                      <span
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        {t("profile.Interior_2")}
                      </span>
                      <img
                        src={image[3]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                      <button
                        type="button"
                        onClick={() => setEditImg(3)}
                        style={{ border: "0px", backgroundColor: "#fff" }}
                      >
                        <label for="file4">
                          <i
                            class="bi bi-pencil-square"
                            style={{ color: "green", cursor: "pointer" }}
                          />
                        </label>
                      </button>
                      <input
                        type="file"
                        id="file4"
                        className="d-none"
                        onChange={(e) => {
                          convertToBase64(e);
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
              <div className="package-sidebar">
                <aside
                  className="package-widget widget-tour-categoris mt-30"
                  style={{ boxShadow: "none" }}
                >
                  <div className="widget-body">
                    <div className="">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cate"
                        checked={data?.terms_and_condition}
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                      />{" "}
                      <span>{t("form.I_CONFIRM_THAT")}</span>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="custom-input-group row col-lg-12">
                <div className=" col-sm-6 col-md-6 col-lg-">
                  <div className="submite-btn">
                    <button
                      type="submit"
                      disabled={!data?.terms_and_condition}
                      onClick={() => (handleSubmit(), showError())}
                      className="btn btn-secondary"
                    >
                      {t("form.submit")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer className="justify-content-center">
          <Button onClick={handleClose}>{t("close")}</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
