import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../common/Loading";
import { Link } from "react-router-dom";
import RecentTravelCard from "../../../swipersData/RecentTravelCard";
import travel from "../../../../../../models/travel";
import { country } from "../../../../../../models";
import AppContext from "../../../../../../store/context/app-context";
import luggage from "../../../../../../models/luggage";
import RecentLuggageCard from "../../../swipersData/RecentLuggageCard";

const details = {
  country_departure: '',
  trip_type: '',
  traveller_account_type: '',
  verification: '',
  carriage_capacity: '',
  price_per_kg: '',
  sortBy: '',
  weight: "",
  type: "",
}

function ViewLuggageWrapperArea() {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const contextObj = useContext(AppContext);
  const [LuggageAnnouncement, setLuggageAnnouncements] = useState();
  const [data, setData] = useState(details);
  const [countries, setCountries] = useState();
  const [userId, setUserId] = useState(contextObj?.login ? contextObj?.loginUser?.id : "0")

  // useEffect(() => {
  //   setLoad(true);
  //   setTimeout(() => {
  //     setLoad(false);
  //   }, 2000);
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoad(true)
    luggage
      .list(userId)
      .then((item) => {
        setLuggageAnnouncements(item?.filter((item) => item?.isComplete == false));
        setData(false)
        setLoad(false)
      })
      .catch(error => {
        setLoad(false)
        console.log("error", error);
      })
    country
      .country()
      .then((details) => {
        setCountries(details);
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const filterLuggageDetails = () => {
    // console.log([true, false, 2, 3].filter(ietm => ietm));
    // console.log([0, 1, 2, 3].map(ietm => ietm === 1 ? 'A' : 'b'));
    let arr = [1, 3, 0, 2, 5, 7];
    if (data?.verification === 0) {
      console.log(arr.sort());
    }
    return LuggageAnnouncement?.filter((item) => data?.country_departure ? item?.country_departure == data?.country_departure : item)
      .filter((item) => data?.type ? item.type == data?.type : item)
      .filter((item) => data?.verification ? (data?.verification === 'true' ? item.verification : !item.verification) : item)
      .filter((item) => {
        if (data?.weight) {
          if (data?.weight === '1') return 20 >= item.weight;
          if (data?.weight === '2') return 10 >= item.weight
          if (data?.weight === '3') return 5 >= item.weight;
        }
        return item;
      })
      .filter((item) => contextObj.luggage?.country ? item.country_arrival == contextObj.luggage?.country.value : item)
      .filter((item) => contextObj.luggage?.state.value ? item.state_arrival == contextObj.luggage?.state.value : item)
      .filter((item) => contextObj.luggage?.city.value ? item.city_arrival == contextObj.luggage?.city.value : item)
      .filter((item) => contextObj.luggage?.particular ? contextObj.luggage?.particular == item.luggage_account_type : item)
      .filter((item) => contextObj.luggage?.professional ? contextObj.luggage?.professional == item.luggage_account_type : item)
      .sort((a, b) => {
        if (data?.sortBy == '1') {
          return parseFloat(a.price_per_kg) - parseFloat(b.price_per_kg);
        } else if (data?.sortBy == '2') {
          return parseFloat(b.price_per_kg) - parseFloat(a.price_per_kg);
        } else if (data?.sortBy == '3') {
          return b.id - a.id;
        } else if (data?.sortBy == '4') {
          return parseFloat(a.carriage_capacity) - parseFloat(b.carriage_capacity);
        } else if (data?.sortBy == '5') {
          return parseFloat(b.carriage_capacity) - parseFloat(a.carriage_capacity);
        } else {
          return a;
        }
      })
  }

  return (
    <>
      {load ? <Loading /> :
        <div className="container">
          <div className="package-area package-style-two  pb-110 chain b">
            {/* <div className="p-4"> */}
            <div className="row justify-content-center">
              <div className="row">
                <div className='col-12 col-md-9 col-lg-9'>
                  <h2 class='fs-3 pb-4'>{filterLuggageDetails()?.length} {t('form.results_found')}</h2>
                </div>
                <div className=' col-lg-12 col-12 col-md-12 col-xl-12 xol-xxl-12 row' style={{ marginBottom: '20px' }}>
                  <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                    <select
                      id="Ads_Category"
                      name='country_departure'
                      onChange={handleChange}
                      value={data.country_departure}
                      style={{ border: '1px solid', borderColor: '#ff4838' }}
                    >
                      <option value={''} selected >- {t("form.all")} -</option>
                      {countries?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                    </select>
                  </div>
                  <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                    <select
                      id="Ads_Category"
                      name='type'
                      onChange={handleChange}
                      style={{ border: '1px solid', borderColor: '#ff4838' }}
                    >
                      <option value={''} selected disabled>-{t('form.Select_Type')} -</option>
                      <option value={1}>{t('form.one_way')}</option>
                      <option value={2}>{t('form.round_trip')}</option>
                    </select>
                  </div>
                  <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                    <select
                      id="Ads_Category"
                      name='weight'
                      onChange={handleChange}
                      style={{ border: '1px solid', borderColor: '#ff4838' }}
                    >
                      <option value={''} selected disabled>- {t('form.select_Weight')} -</option>
                      <option value={1} >{t('form.less_than')} {'  20 kg'}</option>
                      <option value={2} >{t('form.less_than')} {'  10 kg'} </option>
                      <option value={3} >{t('form.less_than')} {'   5 kg'} </option>
                    </select>
                  </div>
                  <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                    <select
                      id="Ads_Category"
                      name='verification'
                      onChange={handleChange}
                      style={{ border: '1px solid', borderColor: '#ff4838' }}
                    >
                      <option value={''} selected disabled>-{t('form.Traveller_Status')}-</option>
                      <option value={true} >{t('form.verified')}</option>
                      <option value={false} >{t('form.Unverified')}</option>
                    </select>
                  </div>
                  <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                    <select
                      id="Ads_Category"
                      name='sortBy'
                      onChange={handleChange}
                      style={{ border: '1px solid', borderColor: '#ff4838' }}
                    >
                      <option value={''} selected disabled>- {t('form.Sort_By')} -</option>
                      <option value={1}>{t('form.by_ascending_price')}</option>
                      <option value={2}>{t('form.by_decreasing_price')}</option>
                      <option value={3}>{t('form.recent_announcement')}</option>
                      <option value={4}>{t('form.per_kg_increasing')}</option>
                      <option value={5}>{t('form.per_kg_decreasing')}</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="row d-flex g-4">
              {filterLuggageDetails()?.map(item => (
                <div className="col-lg-3 col-md-6 col-sm-10"
                  key={item.id}
                >
                  <div className="package-card-delta">
                    <RecentLuggageCard item={item} />
                  </div>
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
        </div>
      }
    </>
  );
}

export default ViewLuggageWrapperArea;
