import React from 'react'
import BradcomeCgve from '../../common/BradcomeCgve'
import CgveWrap from './CgveWrap'

export default function Cgve() {
    return (
        <div>
            <BradcomeCgve name="CGVU"/>
            <CgveWrap/>
        </div>
    )
}
