import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from "react-i18next";
import Loading from "../../../../../common/Loading";
import { Link } from "react-router-dom";
import RecentTravelCard from "../../../swipersData/RecentTravelCard";
import travel from "../../../../../../models/travel";
import { country } from "../../../../../../models";
import AppContext from "../../../../../../store/context/app-context";

const details = {
  country_departure: '',
  trip_type: '',
  traveller_account_type: '',
  verification: '',
  carriage_capacity: '',
  price_per_kg: '',
  sortBy: '',
}

function ViewTravelWrapperArea() {
  const { t } = useTranslation();
  // const [load, setLoad] = useState(false);
  const contextObj = useContext(AppContext);
  const [TravelAnnouncement, setTravelAnnouncements] = useState();
  const [data, setData] = useState(details);
  const [countries, setCountries] = useState();
  const [userId, setUserId] = useState(contextObj?.login ? contextObj?.loginUser?.id : "0")
  const [loder, setLoder] = useState(false)

  // useEffect(() => {
  //   setLoad(true);
  //   setTimeout(() => {
  //     setLoad(false);
  //   }, 2000);
  // }, []);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    setLoder(true)
    travel
      .list(userId)
      .then((item) => {
        setTravelAnnouncements(item?.filter((item) => item?.isComplete == false));
        setLoder(false)
      })
      .catch(error => {
        setLoder(false)
        console.log("error", error);
      })
    country
      .country()
      .then((details) => {
        setCountries(details);
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const filterTravelDetails = () => {
    return TravelAnnouncement?.filter((item) => data?.country_departure ? item?.country_departure == data?.country_departure || item?.country_arrival == data?.country_departure : item)
      .filter((item) => data?.trip_type ? item.trip_type == data?.trip_type : contextObj.travel?.oneWayTicket ? contextObj.travel?.oneWayTicket == item.trip_type : contextObj.travel?.roundTrip ? contextObj.travel?.roundTrip == item.trip_type : item)
      .filter((item) => data?.traveller_account_type ? item.traveller_account_type == data?.traveller_account_type : item)
      .filter((item) => data?.verification ? (data?.verification === 'true' ? item.verification : !item.verification) : item)
      .filter((item) => {
        if (data?.carriage_capacity) {
          if (data?.carriage_capacity === '1') return 100 > item.carriage_capacity;
          if (data?.carriage_capacity === '2') return 10 <= item.carriage_capacity && item.carriage_capacity <= 50;
          if (data?.carriage_capacity === '3') return 50 <= item.carriage_capacity && item.carriage_capacity <= 100;
          if (data?.carriage_capacity === '4') return 100 < item.carriage_capacity;
        }
        return item;
      })
      .filter((item) => {
        if (data?.price_per_kg) {
          if (data?.price_per_kg === '1') return 5 > item.price_per_kg;
          if (data?.price_per_kg === '2') return 5 <= item.price_per_kg && item.price_per_kg <= 10;
          if (data?.price_per_kg === '3') return 11 <= item.price_per_kg && item.price_per_kg <= 20;
          if (data?.price_per_kg === '4') return 20 < item.price_per_kg;
        }
        return item;
      })
      .filter((item) => contextObj.travel?.country ? item.country_arrival == contextObj.travel?.country.value : item)
      .filter((item) => contextObj.travel?.state.value ? item.state_arrival == contextObj.travel?.state.value : item)
      .filter((item) => contextObj.travel?.city.value ? item.city_arrival == contextObj.travel?.city.value : item)
      // .filter((item) => contextObj.travel?.oneWayTicket ? contextObj.travel?.oneWayTicket == item.trip_type : item)
      // .filter((item) => contextObj.travel?.roundTrip ? contextObj.travel?.roundTrip == item.trip_type : item)
      .filter((item) => contextObj.travel?.particular ? contextObj.travel?.particular == item.traveller_account_type : item)
      .filter((item) => contextObj.travel?.professional ? contextObj.travel?.professional == item.traveller_account_type : item)
      .sort((a, b) => {
        if (data?.sortBy == '1') {
          return parseFloat(a.price_per_kg) - parseFloat(b.price_per_kg);
        } else if (data?.sortBy == '2') {
          return parseFloat(b.price_per_kg) - parseFloat(a.price_per_kg);
        } else if (data?.sortBy == '3') {
          return b.id - a.id;
        } else if (data?.sortBy == '4') {
          return parseFloat(a.carriage_capacity) - parseFloat(b.carriage_capacity);
        } else if (data?.sortBy == '5') {
          return parseFloat(b.carriage_capacity) - parseFloat(a.carriage_capacity);
        } else {
          return a;
        }
      })
  }

  return (
    <>
      {loder ? <Loading /> :
        <div className="container">
          <div className="package-area package-style-two  pb-110 chain b">
            {/* <div className="p-4"> */}
            {/* <div className="row justify-content-center"> */}
            <div className="row">
              <div className='col-12 col-md-9 col-lg-9'>
                <h2 class='fs-3 pb-4'>{filterTravelDetails()?.length} {t('form.results_found')}</h2>
              </div>
              <div className=' col-lg-12 col-12 col-md-12 col-xl-12 xol-xxl-12 row' style={{ marginBottom: '20px' }}>
                <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='country_departure'
                    onChange={handleChange}
                    value={data.country_departure}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected>{t("form.all")}</option>
                    {countries?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                  </select>
                </div>
                <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='trip_type'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>{t("form.select_trip_type")}</option>
                    <option value={1}>{t("form.one_way")}</option>
                    <option value={2}>{t("form.round_trip")}</option>
                  </select>
                </div>
                <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='price_per_kg'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>-{t("form.Unit_Price")}-</option>
                    <option value={1} >{t("form.less_than")} 5{contextObj?.loginUser?.symbol}/kg</option>
                    <option value={2} >{t("form.between")}  5{contextObj?.loginUser?.symbol} - 10{contextObj?.loginUser?.symbol} /kg</option>
                    <option value={3} >{t("form.between")}  11{contextObj?.loginUser?.symbol} - 20{contextObj?.loginUser?.symbol} /kg</option>
                    <option value={4} >{t("form.More_than")} 20{contextObj?.loginUser?.symbol} /kg</option>
                  </select>
                </div>
                <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='carriage_capacity'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>-  {t("form.carriage_capacity")} -</option>
                    <option value={1} >{t("form.less_than")} - 100 KG</option>
                    <option value={2} >{t("form.between")}  10 - 50 Kg</option>
                    <option value={3} >{t("form.between")}  50 - 100 Kg</option>
                    <option value={4} >{t("form.More_than")} - 100 Kg</option>
                  </select>
                </div>
                <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='traveller_account_type'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>{t("form.select_traveller_type")}</option>
                    <option value={1} >{t("form.Individual")}</option>
                    <option value={2} >{t("form.Professional")}</option>
                  </select>
                </div>
                <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='verification'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>{t("form.traveller_status")}</option>
                    <option value={true} >{t("form.verified")}</option>
                    <option value={false} >{t("form.Unverified")}</option>
                  </select>
                </div>
                <div class='col-lg-2 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='sortBy'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>{t("form.sort_by")}</option>
                    <option value={1}>{t("form.by_ascending_price")}</option>
                    <option value={2}>{t("form.by_decreasing_price")}</option>
                    <option value={3}>{t("form.recent_announcement")}</option>
                    <option value={4}>{t("form.per_kg_increasing")}</option>
                    <option value={5}>{t("form.per_kg_decreasing")}</option>
                  </select>
                </div>
              </div>
            </div>
            {/* </div> */}
            <div className="row d-flex g-4">
              {filterTravelDetails()?.map(item => (
                <div className="col-lg-3 col-md-6 col-sm-10"
                  key={item.id}
                >
                  <div className="package-card-delta">
                    <RecentTravelCard item={item} />
                  </div>
                </div>
              ))}
            </div>
            {/* </div> */}
          </div>
        </div>
      }
    </>
  );
}

export default ViewTravelWrapperArea;
