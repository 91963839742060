import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from 'react-bootstrap';
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "../../../../../models/request";
import luggage from "../../../../../models/luggage";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";

function LuggageCancelledWrapperArea() {
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const { id, product_id } = useParams();
  const { request_type } = useParams();
  const { order } = useParams();
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  const [show, setShow] = useState(false);
  const [open, SetIsOpen] = useState();
  const [validator, showMessage] = useValidator();
  const [otpValidator, showMessageOtp] = useValidator();
  const [data, setData] = useState({
    request_id: id,
    request_type: request_type,
    status: '',
    reason_to_cancel: '',
    cancel_by: '2',
    Delivery_otp: '',
    traveler_signature: ''
  });
 
  const error = {
    reason_to_cancel: validator.message('Reason to Reject', data.reason_to_cancel, (open == 1 ? 'required|' : '') + 'string|max:200'),
  };
  const OtpError = {
    Delivery_otp: otpValidator.message('OTP', data.Delivery_otp, (order == 2 ? 'required|' : '') + 'string|max:200'),
  };
 
  useEffect(() => {
    getData();
  }, [])
  // const getData = () => {
  //   if (request_type == 1) {
  //     request
  //       .getRequestReceived(id, request_type)
  //       .then((item) => {
  //         setLuggageRequest(item);
  //       })
  //       .catch(error => {
  //         console.log("error", error);
  //       })
  //   } else {
  //     request
  //       .get(id, request_type)
  //       .then((item) => {
  //         setLuggageRequest(item);
  //       })
  //       .catch(error => {
  //         console.log("error", error);
  //       })
  //     luggage
  //       .get(id)
  //       .then((item) => {
  //         setLuggageData(item);
  //       })
  //       .catch(error => {
  //         console.log("error", error);
  //       })
  //   }
  // };
  const getData = () => {
    if (request_type == 1) {
      if (order == 1) {
        request
          .getRequestReceived(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
          })
          .catch(error => {
            console.log("error", error);
          })
      } else if (order == 2) {
        request
          .get(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
          })
          .catch(error => {
            console.log("error", error);
          })
      }
    } else {
      if (order == 2) {
        request
          .getRequestReceived(id, request_type)
          .then((item) => {
            setLuggageRequest(item);
          })
          .catch(error => {
            console.log("error", error);
          })
      }
      request
        .get(id, request_type)
        .then((item) => {
          setLuggageRequest(item);
        })
        .catch(error => {
          console.log("error", error);
        })
      luggage
        .get(((order == 1 && request_type == 2) || (order == 2 && request_type == 2)) ? product_id : id)
        .then((item) => {
          setLuggageData(item);
        })
        .catch(error => {
          console.log("error", error);
        })
    }
  };

  const cancleOrder = () => {
    if (validator.allValid()) {
      request.
        cancle(data)
        .then((item) => {
          SetIsOpen(2);
          console.log(item);
        })
        .catch(error => {
          notistack.error(error?.response?.data?.message);
          console.log("error", error);
        })
    } else {
      showMessage(true);
    }
  }
  const handleSignature = () => {
    request.uploadSignature(data?.request_id, data?.request_type).then((response) => {

    }).catch((error) => {
      notistack.error(error.response.data.message);
    })
  };
  const handleOtp = () => {
    if (otpValidator.allValid()) {
      request.otpVerify(data?.Delivery_otp, data?.request_id, data?.request_type).then((response) => {

      }).catch((error) => {
        notistack.error(error?.response?.data?.message);
      })
    }
    else {
      showMessageOtp(true);
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleClose = () => {
    setShow(false);
    handleSetData('reason_to_cancel', '');
    showMessage(false);
  };
  const handleShow = () => setShow(true);
  const tabPanelValue = () => {
    contextObj.setTabValue(7);
    history.push('/myProfile');
  }
  return (
    <>
      <div class="p-4">
        <Button style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', border: '0px', color: 'white', marginLeft: '-11px' }}
          onClick={() => tabPanelValue()}>
          <i class="bi bi-arrow-left-circle" />{' '} {t('profile.back_to_luggage_carrying_order_list')}
        </Button>
        <div>
          <div className="row pb-3">
            <div className="col-lg-9">
              <div className="row p-3">
                <div className="col-12 col-lg-3 col-md-4 p-1">
                  <h6 className="text-decoration-underline pb-2">{t('profile.luggage_sender_signature')}</h6>
                  <div className="package-sidebar">
                    <aside className="package-widget widget-tour-categoris" style={{ boxShadow: 'none' }}>
                      <div className="widget-body p-0">
                        <div className="d-flex">
                          <div class="digital" style={{ border: '1px solid', borderRadius: '20px' }}> <svg class="animated-check" style={{ height: '95px', width: '110px' }} viewBox="0 0 24 24">
                            <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /></svg>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="">
                  <div className="row">
                    <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                      <h6 className="text-decoration-underline pt-3">{t('profile.my_travel_detailes')}</h6>
                      <div class='earning pt-3'>
                        <div class='plan' style={{ background: 'white' }}>
                          <table class="table">
                            <tbody class='border-0'>
                              <tr>
                                <th class='border-0'><img
                                src={LuggageRequest?.departure_flag}
                                style={{ width: "25px",height:"20px",marginRight:'5px' }}/>{LuggageRequest?.city_departure_name}</th>
                                <th class='border-0'><i class="bi bi-arrow-right" /></th>
                                <th class='border-0'><img
                                src={LuggageRequest?.arrival_flag}
                                style={{ width: "25px",height:"20px",marginRight:'5px' }}/>{LuggageRequest?.city_arrival_name}</th>
                              </tr>
                              <tr class='border-0'>
                                <td class='border-0 fw-normal'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{LuggageRequest?.departure_date} | {LuggageRequest?.departure_time}</td>
                                <td class='border-0'></td>
                                <td class='border-0 fw-normal'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{LuggageRequest?.arrival_date} | {LuggageRequest?.arrival_time}</td>
                              </tr>
                              <tr class='border-0'>
                                <td class='border-0 fw-normal'>{' '}{t('profile.departure')}</td>
                                <td class='border-0'></td>
                                <td class='border-0 fw-normal'>{' '}{t('profile.arrival')}</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                      <div class="earning">
                        <div class="plan" >
                          <header>
                            <i class="bi bi-globe2"></i>
                            <h6 class="plan-title">{t('profile.travelling_by')}:</h6>
                            <div class="plan-cost"><span class="plan-price">
                              {LuggageRequest?.vehicle_type == 1 ? t('form.bike') :
                                LuggageRequest?.vehicle_type == 2 ? t('form.Boat') :
                                  LuggageRequest?.vehicle_type == 3 ? t('form.Bus') :
                                    LuggageRequest?.vehicle_type == 4 ? 'Car' :
                                      LuggageRequest?.vehicle_type == 5 ? t('form.Kick_scooter') :
                                        LuggageRequest?.vehicle_type == 6 ? t('form.train') :
                                          LuggageRequest?.vehicle_type == 7 ? t('form.MoterBike') :
                                            LuggageRequest?.vehicle_type == 8 ? t('form.Flight') :
                                              LuggageRequest?.vehicle_type == 9 ? t('form.Scooter') :
                                                LuggageRequest?.vehicle_type == 10 ? t('form.Tgv') :
                                                  LuggageRequest?.vehicle_type == 11 ? t('form.Tramway') : ''}
                            </span>  {LuggageRequest?.vehicle_type != 1 && <span class="plan-type">{LuggageRequest?.vehicle_details}</span>}</div>
                          </header>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                      <div class="table-responsive">
                        <table class="table table-borderless">
                          <tbody class='border-0'>
                            <tr class='border-0'>
                              <td class='border-0 fw-bold text-decoration-underline'>{t('profile.luggage_detailes')}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.luggage_title')}:</td>
                              <td class='border-0 fw-bold'>{request_type == 1 ? LuggageRequest?.title : LuggageRequest?.luggage_title}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.type_of_luggage')}:</td>
                              <td class='border-0 fw-bold'>{t('profile.used_second_hand')}</td>
                            </tr>
                          </tbody>
                          <tbody class='border-0'>
                            <tr class='border-0'>
                              <td class='border-0 text-decoration-underline fw-bold'>{t('profile.luggage_dimensions')}:</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.height')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.height} {(request_type == 1 ? LuggageRequest : luggageData)?.height ? 'inches' : 'NA'}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.length')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.length} {(request_type == 1 ? LuggageRequest : luggageData)?.length ? 'inches' : 'NA'}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('form.Width')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.width} {(request_type == 1 ? LuggageRequest : luggageData)?.width ? "inches" : 'NA'}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.weight')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.weight} KG</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('form.Colour')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.colour ? (request_type == 1 ? LuggageRequest : luggageData)?.colour : 'NA'}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('form.Volume')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.volume} {(request_type == 1 ? LuggageRequest : luggageData)?.volume ? 'cubic inches' : 'NA'}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.current_price_estimate')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.luggage_estimation} {(request_type == 1 ? LuggageRequest : luggageData)?.currency}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                      <div class="table-responsive">
                        <table class="table table-borderless">
                          <tbody class='border-0'>
                            <tr class='border-0'>
                              <td class='border-0 text-decoration-underline fw-bold'>{t('profile.luggage_receivers_details')}:</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.name_of_receiver')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.receiver_name}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.phone')}/{t('profile.contact')} #</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.receiver_phone_code}{(request_type == 1 ? LuggageRequest : luggageData)?.receiver_contact_number}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.your_relationship_with_receiver')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.receiver_relation}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.will_you_be_getting_your_luggage_delivery')}</td>
                              <td class='border-0 fw-bold'>{t('profile.yes')}</td>
                            </tr>
                            <tr class='border-0'>
                              <td class='border-0 fw-normal'>{t('profile.luggage_location')}</td>
                              <td class='border-0 fw-bold'>{(request_type == 1 ? LuggageRequest : luggageData)?.location}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <h4 class='fs-5 pb-4'>{t('profile.luggage_images')} :</h4>
                    {(request_type == 1 ? LuggageRequest?.image[0] : luggageData?.image[0]) && <div class='col-lg-3 col-md-6'>
                      <h6>{t('profile.exterior')} 1</h6>
                      <img src={request_type == 1 ? LuggageRequest?.image[0] : luggageData?.image[0]} style={{ width: '98%', aspectRatio: "3/2", objectFit: "contain" }} />
                    </div>}
                    {(request_type == 1 ? LuggageRequest?.image[1] : luggageData?.image[1]) && <div class='col-lg-3 col-md-6'>
                      <h6>{t('profile.exterior')} 2</h6>
                      <img src={request_type == 1 ? LuggageRequest?.image[1] : luggageData?.image[1]} style={{ width: '98%', aspectRatio: "3/2", objectFit: "contain" }} />
                    </div>}
                    {(request_type == 1 ? LuggageRequest?.image[2] : luggageData?.image[2]) && <div class='col-lg-3 col-md-6'>
                      <h6>{t('profile.interior')} 1</h6>
                      <img src={request_type == 1 ? LuggageRequest?.image[2] : luggageData?.image[2]} style={{ width: '98%', aspectRatio: "3/2", objectFit: "contain" }} />
                    </div>}
                    {(request_type == 1 ? LuggageRequest?.image[3] : luggageData?.image[3]) && <div class='col-lg-3 col-md-6'>
                      <h6>{t('profile.interior')} 2</h6>
                      <img src={request_type == 1 ? LuggageRequest?.image[3] : luggageData?.image[3]} style={{ width: '98%', aspectRatio: "3/2", objectFit: "contain" }} />
                    </div>}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="row">
                <div className="col-12 col-lg-12 col-md-12 p-1">
                  <div className="row">
                    <div className="col-12 col-lg-12 col-md-6">
                      <div class="earning">
                        <div class="plan" style={{ background: 'white' }}>
                          <header>
                            <h6 class="plan-title" style={{ color: 'black', margin: '0px 0px 5px' }}>Order {t('profile.status')}:</h6>
                            <div class="plan-cost"><span class="plan-price" style={{ fontSize: '15px' }}>{t('profile.cancelled')}</span></div>
                          </header>
                        </div>
                      </div>
                      <div className="pt-2 pb-3">
                        <p className="pt-2">{t('profile.cancellation_date')}:<span className="fw-bold">{LuggageRequest?.updated_at?.slice(0, 10)}</span></p>
                        <p className="">{t('profile.cancellation_by')}<span className="fw-bold"> {LuggageRequest?.cancel_by == 1 ? t('profile.luggage_sender') : t('profile.traveller')}</span> <br /><span className="fw-bold"><i class="bi bi-person-fill" /> </span><span className="fw-bold" style={{ color: '#1e98d7' }}> {LuggageRequest?.cancel_by == 1 ? LuggageRequest?.request_user_name : (order == 2 && request_type == 2) || (order == 1 && request_type == 2) ? LuggageRequest?.traveler_name : LuggageRequest?.travel_user_name}</span></p>
                        {LuggageRequest?.reason_to_cancel ?<p className="">{t('profile.cancellation_reason')} <span className="fw-bold">{LuggageRequest?.reason_to_cancel == 1 ? t("profile.overWeight") : LuggageRequest?.reason_to_cancel == 2 ? t("profile.i_changed_my_travel_plan") : LuggageRequest?.reason_to_cancel == 3 ? t("profile.improper") : LuggageRequest?.reason_to_cancel == 4 ? t("profile.luggage_not_taken_into_account") : LuggageRequest?.reason_to_cancel == 5 ? t('profile.Price_to_high') : LuggageRequest?.reason_to_cancel == 6 ? t('profile.I_Changed_My_Mind') : LuggageRequest?.reason_to_cancel == 7 ? t('profile.Required_Quantity_Unavailable') : ''}</span></p>:<p className="">{t('profile.cancellation_reason')} <span className="fw-bold">{LuggageRequest?.reason_of_cancel == 1 ? t("profile.overWeight") : LuggageRequest?.reason_of_cancel == 2 ? t("profile.i_changed_my_travel_plan") : LuggageRequest?.reason_of_cancel == 3 ? t("profile.improper") : LuggageRequest?.reason_of_cancel == 4 ? t("profile.luggage_not_taken_into_account") : LuggageRequest?.reason_of_cancel == 5 ? t('profile.Price_to_high') : LuggageRequest?.reason_of_cancel == 6 ? t('profile.I_Changed_My_Mind') : LuggageRequest?.reason_of_cancel == 7 ? t('profile.Required_Quantity_Unavailable') : ''}</span></p>}
                      </div>
                    </div>
                    <div className="col-12 col-lg-12 col-md-6">
                      {order == 1 &&
                        <div class="earning">
                          <div class="plan " style={{ background: 'white' }}>
                            <header><i class="bi bi-currency-dollar"></i>
                              <h6 class="plan-title" style={{ color: 'black' }}>{t('profile.earning_oppurtunity')}</h6>
                              <div class="plan-cost"><span class="plan-price">{request_type == 1 ? LuggageRequest?.total_fees : LuggageRequest?.earning} {LuggageRequest?.currency}</span></div>
                            </header>
                          </div>
                        </div>}
                      {order == 1 &&
                        <div className="row pt-2">
                          <p class='fs-6 pb-2 fw-bold text-decoration-underline'>{t('profile.luggage_sender')}:</p>
                          {/* <h6 class='pb-1'><i class="bi bi-person-fill"></i> <span style={{ color: '#1e98d7' }}>{request_type == 1 ? LuggageRequest?.request_user_name : LuggageRequest?.luggager_name}</span></h6> */}
                          <h6 class='pb-1'><i class="bi bi-person-fill"></i> <span style={{ color: '#1e98d7' }}>{request_type == 1 ? LuggageRequest?.request_user_name : LuggageRequest?.luggager_name}</span></h6>
                          <p style={{color:'black'}}>
                <i class="bi bi-person-fill" style={{visibility:'hidden'}}></i>{" "}
                <span style={{ color: "black", fontSize:'14px'}}>{t('form.Member_since')}:{" "}  
                  {request_type == 1
                    ? LuggageRequest?.member_since_sender?.slice(0,10)
                    : LuggageRequest?.member_since_receiver?.slice(0,10)}
                </span>
              </p>
                        </div>}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LuggageCancelledWrapperArea;
