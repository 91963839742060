import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth, country, user } from "../../../models";
import AppContext from "../../../store/context/app-context";
import useValidator from "../../../plugin/validator";
import notistack from "../../../plugin/notistack";
import Loading from "../../common/Loading";

function OtppageWrapperArea() {
  const { t } = useTranslation();
  const [data, setData] = useState({
    contact_number: '',
    email: '',
    sms_otp: '',
    email_otp: '',
    contact_number_or_email: '',
    send_otp_to: "",
  });
  console.log("data",data);
  const history = useHistory();
  const [validator, showMessage] = useValidator();
  const [load, setLoad] = useState(false);

  const getStorageData = () => {
    let email = window.sessionStorage.getItem("email")
    let contact_number = window.sessionStorage.getItem("contact_number")
    if (email && contact_number) {
      setData(prevState => ({
        ...prevState,
        ['email']: email
      }));
      setData(prevState => ({
        ...prevState,
        ["contact_number"]: contact_number
      }))
    }
  }

  useEffect(() => {
    getStorageData()
  }, [])

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const error = {
    sms_otp: validator.message('Otp', data.sms_otp, 'required|' + 'string|max:200'),
    // email_otp: validator.message('Otp', data.email_otp, 'required|' + 'string|max:200'),
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      auth.otpVerified(data).then((response) => {
        notistack.success(response.message);
        history.push("/login");
        showMessage(false);
      })
        .catch((error) => {
          setLoad(false);
          notistack.error(error.data.message);
        })
    }
    else {
      showMessage(true);
    }
  }
  const handleResendOtp = (type) => {
    data.send_otp_to = type === 1 ? 1 : 2;
    data.contact_number_or_email = type === 1 ? data.contact_number : data.email;
    auth.resendOtp(data).then((response) => {
      notistack.success(response.message);
    })
      .catch((error) => {
        notistack.error(error.data.message);
        console.log(error);
      })
  }

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <div class='contact-form-wrap'>
            <div className='package-sidebar row justify-content-center pt-110 pb-110'>
              <aside className='package-widget-style-2 widget-form col-lg-6 col-md-8'>
                <div className='widget-title text-center d-flex justify-content-between'>
                  <h4>{t('auth.verify_your_account')}</h4>
                </div>
                <div className='widget-body'>
                  <div className='booking-form-wrapper'>
                    <p style={{ fontWeight: '600' }}>{t('auth.please_enter_the_OTP_you_received_through_SMS_and_email')}<br /> {t('auth.for_verifying_your_account')}</p>
                    <div className='custom-input-group'>
                      <label htmlFor="name"> {t('auth.Enter_OTP_received_on_SMS')}</label>
                      <input
                        required
                        type='text'
                        id='sms_otp'
                        name='sms_otp'
                        value={data.sms_otp}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>{error.sms_otp}</span>
                      <p style={{ color: '#1e98d7', fontSize: '14px', cursor: 'pointer', width: "fit-content" }} onClick={() => {
                        handleResendOtp(1);
                      }}>{t('auth.resend_OTP_code_through_SMS')}</p>
                    </div>
                    {/* <div className='custom-input-group'>
                      <label htmlFor="name"> {t('auth.enter_OTP_received_on_email')}</label>
                      <input
                        required
                        type='text'
                        id='email_otp'
                        name='email_otp'
                        value={data.email_otp}
                        onChange={handleChange}
                      />
                      <span style={{ color: "red" }}>{error.email_otp}</span>
                      <p
                        style={{ color: '#1e98d7', fontSize: '14px', cursor: 'pointer' }}
                        onClick={() => {
                          handleResendOtp(2);
                        }}>{t('auth.resend_OTP_code_through_email')}</p>

                    </div> */}
                    <div className='custom-input-group'>
                      <div className='submite-btn d-flex'>
                        <button type='submit' onClick={(event) => handleSubmit(event)} className='button-fill-round' >{t('form.submit')}</button>
                      </div>
                    </div>
                  </div>
                </div>
              </aside>
            </div >
          </div>
        </>
      )}
    </>
  );
}

export default OtppageWrapperArea;
