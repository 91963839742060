const works={
    title:'Comment ça fonctionne',
    have_you_arrived:'Vous êtes arrivé sur la plateforme Cobagage et vous souhaitez savoir comment elle fonctionne ?',
    whether_are_you_professional:'Que vous soyez professionnel ou particulier, le site Cobagage a pour objectif de mettre en relation les co-ensacheurs entre eux de manière simple, rapide et sécurisée..',
    becoming_a_cobaggage:'Devenir co-bagage : qu’est-ce que c’est ?',
    saving_money_by_sharing:"Économiser de l'argent en partageant",
    sharing_a_car_journey:"Partager un trajet en voiture ou louer une chambre pour économiser de l'argent : une nouvelle façon de penser le quotidien qui facilite la vie de millions de personnes.",
    sharing_between_individual:'Aujourd’hui, le partage entre particuliers et/ou professionnels s’avère être un excellent moyen de gagner de l’argent d’un côté et d’en économiser de l’autre.',
    travellers_rent_free:'Voyageurs - Louez un espace de bagages gratuit',
    this_principle_also_applies_to:"Ce principe s'applique également à la location gratuite d'un espace bagage lors d'un voyage, pour transporter un ou plusieurs objets jusqu'à son destinataire.",
    the_traveller_can_take_advantage_of:'Le voyageur peut alors profiter de l’espace restant dans son bagage en le monétisant, ce qui lui permettra de rentabiliser une partie de son séjour.',
    luggage_sender:'Expéditeur/destinataire des bagages',
    for_this_part_recipient_can_have:"De son côté, le destinataire peut se faire livrer un ou plusieurs colis rapidement à des tarifs bien plus abordables que s'il avait dû faire appel à une société de fret.",
    being_a_cobaggage_allows_you_to:'Être un co-bagage vous permet de',
    becoming_a_traveller:'Devenez un voyageur',
    by_monitizing_your_kilos:'en monétisant vos kilos de bagages vides',
    post_a_travel_plan:'Publier un plan de voyage',
    become_a_luggage_sender:'Devenez un expéditeur de bagages',
    by_creating_a_transport_request:'en créant une demande de transport.',
    post_a_luggage_sending_request:"Publier une demande d'envoi de bagages",
    step_by_step_operation:'Fonctionnement étape par étape',
    the_cobaggage_platform_intended:'La plateforme Cobagage a pour vocation de mettre en relation les co-emballeurs entre eux. Il est nécessaire de suivre plusieurs étapes simples et rapides pour retrouver un co-bagage et sécuriser votre transaction voici comment procéder étape par étape',
    step:'Étape ',
    do_you_have_free_space_in_your_luggage:"Vous disposez d'un espace libre dans vos bagages et souhaitez le monétiser ? Il vous suffit de vous rendre dans l'espace dédié aux annonces pour remplir le formulaire correspondant.",
    you_will_need_to_provide_information_about_your_trip:'Vous devrez fournir des informations sur votre voyage, le nombre de kilos disponibles dans vos bagages ainsi que le prix au kilo. Une fois votre annonce rédigée, elle apparaîtra sur le site dans la rubrique « Annoncer un voyage ».',
    the_sender_fill_out_a_form:"L'expéditeur remplit un formulaire pour mettre son annonce en ligne sur le site en précisant les informations relatives à votre bagage dans la rubrique « Envoyer un bagage »..",
    he_makes_payment_the_payment_directly_on_platform:'Il effectue le paiement directement sur la plateforme, qui est ensuite mis en attente le temps de la finalisation de la transaction sur Cobagage.',
    the_traveller_and_sender_are_put_in_contact:"Le voyageur et l'expéditeur sont mis en contact via le site Cobagage et signent un bon de commande. Ce dernier devra être signé par les deux parties et servira de garantie quant à la responsabilité des bagages.",
    once_the_connection_has_been_made:'Une fois la correspondance établie, le voyageur transportera le(s) article(s) dans ses bagages et se présentera au lieu de rendez-vous pour remettre le colis au destinataire.',
    the_recipient_comes_to_the_meeting_place:"Le destinataire se présente au lieu de rendez-vous pour récupérer son colis. Des notes, avis et commentaires peuvent ensuite être déposés sur la plateforme pour évaluer l'expéditeur et le voyageur.",
    a_complete_secure_transport_solution:'Une solution de transport totalement sécurisée',
    one_of_the_legitimate_question:'L’une des questions légitimes que l’on peut se poser avant de franchir le pas du cobagaging est de savoir si cette transaction est sécurisée.',
    parcel_transport_through_cobaggage:"Le transport de colis via Cobagage est l'un des moyens les plus sécurisés d'envoyer et de recevoir vos articles.",
    as_a_platform_for_connecting_co_baggagers:'Plateforme de mise en relation des co-emballeurs, nous restons en contact permanent avec le voyageur détenteur de votre colis.',
    serveral_safety_rules:'Plusieurs règles de sécurité restent néanmoins à respecter afin de garantir un parfait déroulement du transport.',
    the_traveller_and_sender_have_obligation:"Le voyageur et l'expéditeur ont l'obligation de vérifier conjointement le contenu du colis réceptionné.",
    the_sender_must_complete_the_registration:"L'expéditeur doit également remplir les formulaires d'inscription avec les documents demandés sur la plateforme, et prendre une photo du contenu du colis.",
    for_their_part_the_traveller_must_follow:"De son côté, le voyageur doit suivre la même procédure en remplissant le formulaire d'inscription.",
    he_must_also_provide_a_photocopy:"Il devra également fournir une photocopie de sa carte d'identité, de son titre de séjour et de son passeport par l'intermédiaire du site.",
    becoming_a_cobaggager_is_assuarance_of:"Devenir Co-bagage, c'est l'assurance de pouvoir obtenir rapidement un colis, ou de rentabiliser une partie de son voyage en monétisant les kilos vides de ses bagages.",
    this_service_applies_to_all_kind_of_travel:"Ce service s'applique à tous types de déplacements, que ce soit en avion, en train, en voiture ou en métro ou tout autre moyen de transport permettant de se déplacer d'un endroit à un autre."
    }
    export default works;