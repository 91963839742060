import React, { useContext, useEffect, useReducer, useState } from "react";
import { Link } from "react-router-dom";
import { Language } from "../../layout/Main/Popover"
import { useTranslation } from "react-i18next";
import AppContext from "../../../store/context/app-context";
import { auth } from "../../../models";
import notification from "../../../models/notification";
import { i18n } from "i18next";
import notistack from '../../../plugin/notistack';
import basket from "../../../models/basket";
import {
  useHistory
} from "react-router-dom";


// import AppContext from "../../../store/context/app-context";

function HeaderFour() {
  // ---------------------------------
  //   const catMenu = useRef(null)
  //   const [openSlide, setopenSlide] = useState(""); 
  //   const closeOpenMenus = (e)=>{
  //     console.log("abah");
  //     if(catMenu.current && openSlide && !catMenu.current.contains(e.target)){
  //       setuserDropdown(false)
  //     }
  // }
  // document.addEventListener('mousedown',closeOpenMenus)

  // ---------------------------------------------------------

  // const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const [searchActive, setSearchActive] = useState(0);
  const [catagoryActive, setCatagoryActive] = useState(0);
  const [userDropdown, setuserDropdown] = useState(0);
  const [userEmail, setUserEmail] = useState(0)
  const [userEmailData, setUserEmailData] = useState([]);
  const [basketData, setBasketData] = useState([]);
  const history = useHistory();
  const [mobileSideberMenu, setMobileSideberMenu] = useState(0);
  const contextObj = useContext(AppContext);
  const [Profiledetails, setProfiledetails] = useState();
  const { i18n } = useTranslation();

  const loginUserType = localStorage.getItem("loginUserType")
  const loginUserCountry = localStorage.getItem("loginUsercountry")
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  // console.log(contextObj.loginUser.full_name);
  /*useing reducer to manage the active or inactive menu*/

  useEffect(() => {
    contextObj.login && getNotificationData();
    contextObj.login && getBasketNotificationData();
    // Add scroll event when the component is loaded
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, [contextObj.login]);
  /* Method that will fix header after a specific scrollable */
  const isSticky = (e) => {
    const header = document.querySelector("header");
    const scrollTop = window.scrollY;
    scrollTop >= 200
      ? header?.classList?.add("sticky")
      : header?.classList?.remove("sticky");
  };

  const initialState = { activeMenu: "" };
  const [state, dispatch] = useReducer(reducer, initialState);

  const addTraveller = () => {
    contextObj.setTabValue(0)
    history.push(contextObj.login || contextObj?.loginUser?.login_type == true ? `${process.env.PUBLIC_URL}/myProfile` : `${process.env.PUBLIC_URL}/login`)
  }

  function reducer(state, action) {
    switch (action.type) {
      case "homeOne":
        return { activeMenu: "homeOne" };
      case "page":
        return { activeMenu: "page" };
      case "package":
        return { activeMenu: "package" };
      case "blog":
        return { activeMenu: "blog" };
      case "destination":
        return { activeMenu: "destination" };
      default:
        return { activeMenu: " " };
    }
  }
  const handleSearhBar = () => {
    if (searchActive === false || searchActive === 0) {
      setSearchActive(1);
    } else {
      setSearchActive(false);
    }
  };
  const handleCatagorybtn = () => {
    if (catagoryActive === false || catagoryActive === 0) {
      setCatagoryActive(1);
    } else {
      setCatagoryActive(false);
    }
  };
  const handleUserDropDown = () => {
    setUserEmail(false);
    if (userDropdown === false || userDropdown === 0) {

      setuserDropdown(1);
    } else {
      setuserDropdown(false);

    }
  };

  const handleUserEmailDropDwon = () => {
    setuserDropdown(false);
    if (userEmail === false || userEmail === 0) {
      setUserEmail(1);
    } else {
      setUserEmail(false);

    }
  };

  const handleMobileSiderbar = () => {
    if (mobileSideberMenu === false || mobileSideberMenu === 0) {
      setMobileSideberMenu(1);
    } else {
      setMobileSideberMenu(false);
    }
  };

  const getNotificationData = () => {
    notification.list().then((resp) => {
      setUserEmailData(resp);
    }).catch((err) => {
      if (err.status == 401) {
        // notistack.error(t("profile.Unauthorized"))
        contextObj.setLogin();
        localStorage.removeItem('tokens')
        history.push('/login');

      }
      console.log('notification err: ', err.status);
    })
  }

  const getBasketNotificationData = () => {
    basket.list().then((resp) => {
      setBasketData(resp)
    }).catch((err) => {
      console.log('basket err: ', err);
    })
  }

  const deleteNotification = (id) => {
    notification.delete(id).then((resp) => {
      getNotificationData();
    }).catch((error) => {
      console.log("notification Delete", error);
    })

  }

  return (
    <>
      <div
        className={searchActive === 1 ? "mobile-search slide" : "mobile-search"}
      >
        <div className="container">
          <div className="row d-flex justify-content-center">
            <div className="col-md-11">
              <label>What are you lookking for?</label>
              <input
                type="text"
                placeholder="Search Products, Category, Brand"
              />
            </div>
            <div className="col-1 d-flex justify-content-end align-items-center">
              <div className="search-cross-btn" onClick={handleSearhBar}>
                <i className="bi bi-x" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={
          catagoryActive === 1
            ? "category-sidebar-wrapper category-active"
            : "category-sidebar-wrapper"
        }
      >
        <div className="category-sidebar">
          <div className="category-header d-flex justify-content-between align-items-center">
            <h4>Category</h4>
            <div className="category-toggle" onClick={handleCatagorybtn}>
              <i className="bi bi-x-lg" />
            </div>
          </div>
          <div className="row row-cols-lg-3 row-cols-2 gy-5 mt-3">
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate1.svg"}
                    alt=""
                  />
                </div>
                <h5>Adventure</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate2.svg"}
                    alt=""
                  />
                </div>
                <h5>Group Tour</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate3.svg"}
                    alt=""
                  />
                </div>
                <h5>Couple Tour</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate4.svg"}
                    alt=""
                  />
                </div>
                <h5>Single Tour</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate5.svg"}
                    alt=""
                  />
                </div>
                <h5>Honeymoon</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate6.svg"}
                    alt=""
                  />
                </div>
                <h5>Sea Beach</h5>
              </Link>
            </div>
            <div className="col">
              <Link
                className="category-box"
                to={`${process.env.PUBLIC_URL}/package`}
                onClick={scrollTop}
              >
                <div className="cate-icon mx-auto">
                  <img
                    src={process.env.PUBLIC_URL + "/images/icons/cate7.svg"}
                    alt=""
                  />
                </div>
                <h5>Mountain Tour</h5>
              </Link>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="topbar-area topbar-style-two d-sm-flex d-none">
        <div className="container">
          <div className="row">
            <div className="col-xl-6 col-lg-6 col-md-6 d-md-flex d-none justify-content-start align-items-center">
              <div className="topbar-contact-left">
                <ul className="contact-list">
                  <li>
                    <i className="bi bi-telephone-fill" />{" "}
                    <a href="tel:+17632275032">+1 763-227-5032</a>
                  </li>
                  <li>
                    <i className="bi bi-envelope-fill" />{" "}
                    <a href="mailto:info@example.com">info@example.com</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xl-6 col-lg-6 col-md-6 d-flex justify-content-md-end justify-content-center align-items-center">
              <ul className="topbar-social-links">
                <li>
                  <Link to={"#"}>
                    <i className="bx bxl-facebook" />
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <i className="bx bxl-instagram-alt" />
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <i className="bx bxl-twitter" />
                  </Link>
                </li>
                <li>
                  <Link to={"#"}>
                    <i className="bx bxl-pinterest" />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
      <header>
        <div className="header-area header-style-four position-absolute w-100">
          <div className="container">
            <div className="row">
              <div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                <div className="nav-logo d-flex justify-content-between align-items-center">
                  <Link to={`/home`} onClick={scrollTop}>
                    <img
                      src={i18n.language === 'frFR' ? "/images/CobagageLogo4.png" : "/images/CobagageLogo.png"}
                      alt="logo"
                      style={{ height: '60px' }}
                    />
                  </Link>
                  <div className="mobile-menu d-flex ">
                    <div className="d-flex align-items-center">
                      <div className="nav-right-icons d-xl-none d-flex align-items-center ">
                        <div
                          className="user-dropdown"
                          onClick={handleUserDropDown}
                        >
                          <i className="bx bx-user-circle" />
                          <div
                            className={
                              userDropdown == 1
                                ? "user-drop-list account-drop-active"
                                : "user-drop-list"
                            }
                            style={{ backgroundColor: "transparent", height: "1500px", width: '550px', right: '-165px', overflow: 'hidden', top: '0px' }}
                          >
                            <div className="noti" style={{ backgroundColor: 'black', height: '20px', width: '15px', top: '44px', right: '155px', position: "absolute" }}></div>
                            <ul
                              className={
                                userDropdown === 1
                                  ? "user-drop-list account-drop-active"
                                  : "user-drop-list"
                              }
                              style={{ top: '50px', right: '143px' }}
                            >
                              {/* <li>
                              <Link to={"/myProfile"}>My Profile</Link>
                            </li>
                            <li>
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/login`}
                              >
                                Login
                              </Link>
                            </li>
                            <li>
                              <Link
                                onClick={scrollTop}
                                to={`${process.env.PUBLIC_URL}/register`}
                              >
                                Registration
                              </Link>
                            </li>
                            <li>
                              <Link to={"#"}>LogOut</Link>
                            </li> */}
                              {contextObj.login && <li>
                                <Link
                                  onClick={scrollTop}
                                  to={loginUserType == 'true' ? `${process.env.PUBLIC_URL}/myProfile` : `/ProfileInfo/${loginUserCountry}`}> {t("menu.my_account")}
                                </Link>
                              </li>}
                              {!contextObj.login && <li>
                                <Link
                                  onClick={scrollTop}
                                  to={`${process.env.PUBLIC_URL}/login`}
                                >
                                  {t("menu.login")}
                                </Link>
                              </li>}
                              {!contextObj.login && <li>
                                <Link
                                  onClick={scrollTop}
                                  to={`${process.env.PUBLIC_URL}/register`}
                                >
                                  {t("menu.registration")}
                                </Link>
                              </li>}
                              {contextObj.login && <li>
                                <Link to={"/login"} onClick={() => auth.logout().then((data) => {
                                  contextObj.functionsetloginUser();
                                  contextObj.functionLogout();
                                })}>
                                  {t("menu.logout")}
                                </Link>
                              </li>}
                            </ul>
                          </div>
                        </div>
                        {contextObj.login &&
                          <div
                            className="mt-4 user-dropdown"
                            onClick={handleUserEmailDropDwon}
                          >
                            <i class="bi bi-bell" style={{ fontSize: '18px' }}></i>
                            {userEmailData?.length > 0 ? <div style={{ position: 'relative', top: '-40px', left: '20px', background: 'black', height: '24px', width: '24px', textAlign: 'center', justifyItems: 'center', borderRadius: '50%' }}> <span style={{ color: '#fff', padding: '2px', fontSize: '13px' }}>{userEmailData?.length}</span></div> : <div style={{ position: 'relative', top: '-40px', left: '20px', height: '24px', width: '24px', textAlign: 'center', justifyItems: 'center', borderRadius: '50%' }}> <span style={{ color: 'transparent', padding: '2px', fontSize: '13px' }}>0</span></div>}
                            <div
                              className={
                                userEmail == 1
                                  ? "emailuser-drop-list account-drop-active"
                                  : "emailuser-drop-list"
                              }
                              style={{ backgroundColor: "transparent", height: "1500px", width: '100%', overflow: 'hidden', right: '-140px', top: '-20px' }}
                            >
                              <div className="noti" style={{ backgroundColor: 'black', height: '20px', width: '15px', top: '64px', right: '134px', position: "absolute" }}></div>
                              <ul
                                className={
                                  userEmail === 1
                                    ? "emailusermobile-drop-list account-drop-active"
                                    : "emailusermobile-drop-list"
                                }
                                style={{ top: '70px', right: '50px', width: '30%' }}
                              >
                                {contextObj.login &&
                                  <table
                                    style={{
                                      overflowY: "scroll",
                                      height: "300px",
                                      display: "block"
                                    }}
                                  >
                                    <tr>
                                      <div className="pb-3"><h4>Notifications</h4></div>
                                    </tr>
                                    {userEmailData?.map((item) => {
                                      if (i18n.language === 'frFR') {
                                        return (
                                          <>
                                            <div className="d-flex">
                                              <div className="col-lg-11">
                                                {item.message_fr}
                                              </div>
                                              <div className="col-lg-1 d-flex align-items-lg-center">
                                                <i class="bi bi-trash" style={{ color: 'white', backgroundColor: "black", fontSize: '20px', cursor: 'pointer', borderRadius: "0px" }} onClick={() => deleteNotification(item.notification_id)} />
                                              </div>
                                            </div>
                                            {item.redirectUrl &&
                                              <span>
                                                <Link
                                                  to={`${item.redirectUrl}`}
                                                >
                                                  {t('form.Click_Payment')}
                                                </Link>
                                              </span>}
                                            <hr></hr>
                                          </>
                                        )
                                      } else {
                                        return (
                                          <>
                                            <div className="d-flex">
                                              <div className="col-lg-11">
                                                {item.message_en}
                                              </div>

                                              <div className="col-lg-1 d-flex align-items-lg-center">
                                                <i class="bi bi-trash" style={{ color: 'white', backgroundColor: "black", fontSize: '20px', cursor: 'pointer', borderRadius: "0px" }} onClick={() => deleteNotification(item.notification_id)} />
                                              </div>
                                            </div>
                                            {item.redirectUrl &&
                                              <span>
                                                <Link
                                                  to={`${item.redirectUrl}`}
                                                >
                                                  {t('form.Click_Payment')}
                                                </Link>
                                              </span>}
                                            <hr></hr>
                                          </>
                                        )
                                      }

                                    })}
                                  </table>
                                }
                              </ul>
                            </div>
                          </div>
                        }
                        <div
                          className="category-toggle"
                        >
                          <Language />
                        </div>


                        {/* <div
                          className="category-toggle"
                          onClick={handleCatagorybtn}
                        >
                          <i className="bx bx-category" />
                        </div> */}
                      </div>
                      <Link
                        to={"#"}
                        className={
                          mobileSideberMenu === 1
                            ? "hamburger d-block d-xl-none h-active"
                            : "hamburger d-block d-xl-none"
                        }
                        onClick={handleMobileSiderbar}
                      >
                        <span className="h-top" />
                        <span className="h-middle" />
                        <span className="h-bottom" />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6 col-xl-5 col-lg-9 col-md-8 col-sm-6 col-xs-6">
                <nav
                  className={
                    mobileSideberMenu === 1 ? "main-nav slidenav" : "main-nav"
                  }
                >
                  <div className="inner-logo d-xl-none text-center">
                    <Link to={"#"}>
                      <img
                        src={i18n.language === 'frFR' ? "/images/CobagageLogo4.png" : "/images/CobagageLogo.png"}
                        alt="logo"
                        style={{ height: '60px' }}
                      />
                    </Link>
                  </div>
                  <ul>
                    <li
                      // className="has-child-menu"
                      onClick={() => {
                        scrollTop()
                        handleMobileSiderbar()
                        dispatch({ type: "homeOne" })
                      }}
                    >
                      <Link to={`/home`}>{t("menu.home")}</Link>
                    </li>
                    {/* <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/about`}
                      >
                        {t("menu.about_us")}

                      </Link>
                    </li> */}
                    {/* <li
                      className="has-child-menu "
                      onClick={() => dispatch({ type: "destination" })}
                    >
                      <Link to={contextObj.login ? "/faq" : "/login"}>FAQ</Link>
                    </li> */}
                    {contextObj.login && <li
                      id="submitanad"
                      // className="has-child-menu"
                      onClick={() => {
                        scrollTop()
                        handleMobileSiderbar()
                        // addTraveller()
                        if (loginUserType == 'true') {
                          addTraveller()
                        } else {
                          history.push(`/ProfileInfo/${loginUserCountry}`)
                        }
                      }}
                      style={{ cursor: 'pointer' }}
                    >
                      {/* <Link> */}
                      <p style={{ fontWeight: "500" }}>{t("profile.submit_an_ad")}</p>
                      {/* </Link> */}
                    </li>}
                    <li
                      className="d-xl-none"
                      onClick={handleMobileSiderbar}
                    >
                      <Link to={contextObj.login || contextObj?.loginUser?.login_type == true ? `${process.env.PUBLIC_URL}/shoppingCard` : `${process.env.PUBLIC_URL}/login`}>
                        {t("profile.ShoppingCart")}
                      </Link>
                    </li>
                    {/* <li>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/contact`}
                      >
                        {t("menu.contact_us")}
                      </Link>
                    </li> */}
                  </ul>
                  {/* <div className="inner-contact-options d-xl-none">
                    <div className="contact-box-inner">
                      <i className="bi bi-telephone-fill" />{" "}
                      <a href="tel:+17632275032">+1 763-227-5032</a>
                    </div>
                    <div className="contact-box-inner">
                      <i className="bi bi-envelope-fill" />{" "}
                      <a href="mailto:info@example.com">info@example.com</a>
                    </div>
                  </div> */}
                </nav>
              </div>
              <div className="col-xxl-4 col-xl-5 col-lg-1">
                <div className="nav-right d-xl-flex d-none">
                  <div className="nav-right-icons gap-3 d-flex align-items-center">
                    <div>
                      {contextObj.login && <p style={{ color: "black", }}>{t("homePage.welcome")},{' '}{contextObj?.loginUser?.full_name}</p>}
                    </div>
                    <div
                      className="user-dropdown"
                      onClick={handleUserDropDown}
                    >
                      <i className="bx bx-user-circle" />
                      <div
                        className={
                          userDropdown == 1
                            ? "user-drop-list account-drop-active"
                            : "user-drop-list"
                        }
                        style={{ backgroundColor: "transparent", height: "1500px", width: contextObj.login == true ? '1500px' : '1500px', right: contextObj.login == true ? '-270px' : '-160px', overflow: 'hidden', top: '0px' }}
                      >
                        <div className="noti" style={{ backgroundColor: 'black', height: '20px', width: '15px', top: '45px', right: contextObj.login == true ? '260px' : "150px", position: "absolute" }}></div>
                        <ul
                          className={
                            userDropdown == 1
                              ? "user-drop-list account-drop-active"
                              : "user-drop-list"
                          }
                          style={{ top: '52px', right: contextObj.login == true ? '240px' : "130px" }}
                        >
                          {contextObj.login && <li>
                            <Link
                              onClick={() => (contextObj.setRadioValue(), scrollTop())}
                              to={loginUserType == 'true' ? `${process.env.PUBLIC_URL}/myProfile` : `/ProfileInfo/${loginUserCountry}`}>
                              {t("menu.my_account")}
                            </Link>
                          </li>}
                          {!contextObj.login && <li>
                            <Link
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/login`}
                            >
                              {t("menu.login")}
                            </Link>
                          </li>}
                          {!contextObj.login && <li>
                            <Link
                              onClick={scrollTop}
                              to={`${process.env.PUBLIC_URL}/register`}
                            >
                              {t("menu.registration")}
                            </Link>
                          </li>}
                          {contextObj.login && <li>
                            <Link to={"/login"} onClick={() =>
                              auth.logout().then((data) => {
                                contextObj.functionsetloginUser();
                                contextObj.functionLogout();
                              }).catch((error) => {
                                console.log(error);
                              })}>
                              {t("menu.logout")}
                            </Link>
                          </li>}
                        </ul>
                      </div>
                    </div>
                    {contextObj.login &&
                      <div className="mt-4 user-dropdown"
                        onClick={handleUserEmailDropDwon}
                      >
                        <i class="bi bi-bell" style={{ fontSize: '18px' }}></i>
                        {userEmailData?.length > 0 ? <div style={{ position: 'relative', top: '-40px', left: '20px', background: 'black', textAlign: 'center', justifyItems: 'center', borderRadius: '50%', height: '24px', width: '24px' }}> <span style={{ color: '#fff', padding: '2px', fontSize: '13px' }}>{userEmailData?.length}</span></div> : <div style={{ position: 'relative', top: '-40px', left: '20px', textAlign: 'center', justifyItems: 'center', borderRadius: '50%', height: '24px', width: '24px' }}> <span style={{ color: 'transparent', padding: '2px', fontSize: '13px' }}>0</span></div>}
                        <div
                          className={
                            userEmail == 1
                              ? "emailuser-drop-list account-drop-active"
                              : "emailuser-drop-list"
                          }
                          style={{ backgroundColor: "transparent", height: "1500px", width: '1260px', overflow: 'hidden', right: '-213px', top: '0px' }}
                        >
                          <div className="noti" style={{ backgroundColor: 'black', height: '20px', width: '15px', top: '40px', right: '207px', position: "absolute" }}></div>
                          <div
                            className={
                              userEmail == 1
                                ? "emailuser-drop-list account-drop-active"
                                : "emailuser-drop-list"
                            }
                            style={{ top: '48px', right: '100px', width: '70%' }}
                          >
                            {contextObj.login &&
                              <table
                                style={{
                                  overflowY: "scroll",
                                  height: "300px",
                                  display: "block"
                                }}
                              >
                                <tr>
                                  <td><div className="pb-3"><h4>Notifications</h4></div></td>
                                </tr>
                                {userEmailData?.map((item) => {
                                  if (i18n.language === 'frFR') {
                                    return (
                                      <>
                                        <div className="d-flex">
                                          <div className="col-lg-11">
                                            {item.message_fr}
                                          </div>
                                          <div className="col-lg-1 d-flex align-items-lg-center">
                                            <i class="bi bi-trash" style={{ color: 'white', backgroundColor: "black", fontSize: '20px', cursor: 'pointer', borderRadius: "0px" }} onClick={() => deleteNotification(item.notification_id)} />
                                          </div>
                                        </div>
                                        {item.redirectUrl && <span style={{ color: "white" }}>
                                          <Link
                                            to={`${item.redirectUrl}`}
                                          >
                                            {t('form.Click_Payment')}
                                          </Link>
                                        </span>}
                                        <hr></hr>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <>
                                        <div className="d-flex">
                                          <div className="col-lg-11">
                                            {item.message_en}

                                          </div>
                                          <div className="col-lg-1 d-flex align-items-lg-center">
                                            <i class="bi bi-trash" style={{ color: 'white', backgroundColor: "black", fontSize: '20px', cursor: 'pointer', borderRadius: "0px" }} onClick={() => deleteNotification(item.notification_id)} />
                                          </div>
                                        </div>
                                        {item.redirectUrl &&
                                          <span>
                                            <Link
                                              to={`${item.redirectUrl}`}
                                            >
                                              {t('form.Click_Payment')}
                                            </Link>
                                          </span>}
                                        <hr></hr>
                                      </>
                                    )
                                  }

                                })}
                              </table>
                            }
                          </div>
                        </div>
                      </div>}
                    {contextObj.login &&
                      <div className="mt-4">
                        <a href="/shoppingCard">
                          <i class="bi bi-cart3" style={{ fontSize: '18px' }}></i>
                          {basketData?.length > 0 ? <div style={{ position: 'relative', top: '-40px', left: '20px', background: 'black', height: '24px', width: '24px', justifyItems: 'center', textAlign: 'center', borderRadius: '50%' }}> <span style={{ color: '#fff', padding: '2px', fontSize: '13px' }}>{basketData?.length}</span></div> : <div style={{ position: 'relative', top: '-40px', left: '20px', height: '24px', width: '24px', justifyItems: 'center', textAlign: 'center', borderRadius: '50%' }}> <span style={{ color: 'transparent', padding: '2px', fontSize: '13px' }}>0</span></div>}
                        </a>
                      </div>}
                    <div
                      className="header-search search-btn"
                    // onClick={handleSearhBar}
                    >
                      <Language />
                    </div>


                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header >
    </>
  );
}

export default HeaderFour;
