import React from 'react'
import { useTranslation } from 'react-i18next'

export default function CobagageAsJobWrapp() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='pt-90  pb-110 mt-3'>
                <h1 style={{ fontWeight: 'bold' }} className='text-center'>{t('form.Cobagage_as_Job')}</h1>
                <h3 className='pt-4 text-center' style={{fontWeight:'bolder',fontSize:'20px'}}>
                    "{t('form.Cobagage_as_Job_text1')}"
                </h3>
                <p className='pt-4 fs-5'>
                    {t('form.Cobagage_as_Job_text2')}
                </p>
                <p className='pt-4 fs-5'>
                    {t('form.Cobagage_as_Job_text3')}
                </p>
                <p className='fs-5 pt-2'> <strong>{t('form.Cobagage_as_Job_text4')} </strong>{t('form.Cobagage_as_Job_text5')}</p>
                <h2 className='mt-5' style={{ fontWeight: 'bold',color: '#ff4838',textDecoration: "underline" }}>{t('form.Cobagage_as_Job_text6')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text7')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text8')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text9')}
                </p>
                <p className='fs-5 pt-2'>{t('form.Cobagage_as_Job_text10')} <strong>{t('form.Cobagage_as_Job_text11')}</strong></p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text12')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text13')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text14')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text15')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text16')}
                </p>
        
                <h2 className='mt-5' style={{ fontWeight: 'bold',color: '#ff4838',textDecoration: "underline"  }}>{t('form.Cobagage_as_Job_text17')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text18')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text19')}
                </p>
               
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text20')} <strong>{t('form.Cobagage_as_Job_text21')}</strong>{t('form.Cobagage_as_Job_text22')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text23')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text24')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text25')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text26')}
                </p>
                
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text27')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text28')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text29')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text30')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Cobagage_as_Job_text31')}
                </p>
                
            </div>
        </div>
    )
}
