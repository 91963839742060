import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { country } from "../../../../models";
import AppContext from "../../../../store/context/app-context";
import useValidator from "../../../../plugin/validator";
import travel from "../../../../models/travel";
import Loading from "../../../common/Loading";
import notistack from "../../../../plugin/notistack";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
const restData = {
  title: "",
  country_departure: "",
  state_departure: "",
  city_departure: "",
  country_arrival: "",
  state_arrival: "",
  city_arrival: "",
  pickup_place: "",
  delivery_place: "",
  departure_date: moment().format("DD/MM/YYYY"),
  arrival_date: "",
  departure_time: "",
  arrival_time: "",
  carriage_capacity: "",
  trip_type: "",
  price_per_kg: "",
  vehicle_type: "",
  vehicle_details: "",
  packing_instruction: "",
  description: "",
  terms_and_condition: "",
  stopover_in_trip: 0,
  country_stopover: "",
  state_stopover: "",
  city_stopover: "",
  insurance: "",
  return_departure_date: "",
  return_arrival_date: "",
  return_departure_time: "",
  return_arrival_time: "",
  traveller_type: "",
  // currency_id: '',
};

const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_stopover: {},
  state_of_stopover: {},
  city_of_stopover: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  stopoverCountry: [],
  stopoverState: [],
  stopoverCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};
const otherDataCity = {
  state_id: "",
  city_name: "",
};

const otherDataCityArray = {
  state_id: "",
  city_name: "",
};
function PostTravelAnnouncement() {
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const contextObj = useContext(AppContext);
  const [data, setData] = useState(restData);
  const [otherCityData, setOtherCityData] = useState(otherDataCity);
  const [otherCityDataArrwal, setOtherCityDataArrwal] =
    useState(otherDataCityArray);
  const [details, setDetails] = useState(format);
  const [open, setOpen] = useState(false);
  const [validator, showMessage] = useValidator();
  const history = useHistory();
  const [filed, setFiled] = useState(0);
  const [isShow, setIsShow] = useState(true);
  const [isRadio, setIsRadio] = useState(true);
  const [load, setLoad] = useState(false);
  const [currencyData, setCurrencyData] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleSetDataOtherCity = (name, value) => {
    setOtherCityData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSetDataOtherCityArrawl = (name, value) => {
    setOtherCityDataArrwal((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  useEffect(() => {
    getData();
    // handleSetData('terms_and_condition', false);
    handleSetData("insurance", true);
    handleSetData("stopover_in_trip", false);
    getCurrency();
  }, []);
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  useEffect(() => {
    getData();
    // handleSetData('terms_and_condition', false);
    handleSetData("insurance", true);
    handleSetData("stopover_in_trip", false);
    getCurrency();
  }, []);

  const error = {
    title: validator.message(t("title"), data.title, "required|max:200"),
    country_departure: validator.message(
      t("country_departure"),
      data.country_departure,
      "required"
    ),
    trip_type: validator.message(t("trip_type"), data.trip_type, "required"),
    country_arrival: validator.message(
      t("country_arrival"),
      data.country_arrival,
      "required"
    ),
    state_departure: validator.message(
      t("state_departure"),
      data.state_departure,
      "required"
    ),
    state_arrival: validator.message(
      t("state_arrival"),
      data.state_arrival,
      "required"
    ),
    city_departure: validator.message(
      t("city_departure"),
      data.city_departure,
      "required"
    ),
    city_arrival: validator.message(
      t("city_arrival"),
      data.city_arrival,
      "required"
    ),
    pickup_place: validator.message(
      t("pickup_place"),
      data.pickup_place,
      "required"
    ),
    delivery_place: validator.message(
      t("delivery_place"),
      data.delivery_place,
      "required"
    ),
    departure_date: validator.message(
      t("departure_date"),
      data.departure_date,
      "required"
    ),
    arrival_date: validator.message(
      t("arrival_date "),
      data.arrival_date,
      "required"
    ),
    departure_time: validator.message(
      t("departure_time "),
      data.departure_time,
      "required"
    ),
    arrival_time: validator.message(
      t("arrival_time "),
      data.arrival_time,
      "required"
    ),
    carriage_capacity: validator.message(
      t("carriage_capacity "),
      data.carriage_capacity,
      "required"
    ),
    price_per_kg: validator.message(
      t("price_per_kg"),
      data.price_per_kg,
      "required"
    ),
    // currency: validator.message(t('currency'), data.currency_id, 'required'),
    vehicle_type: validator.message(
      t("vehicle_type"),
      data.vehicle_type,
      "required"
    ),
    stopover_in_trip: validator.message(
      t("stopover_in_trip"),
      data.stopover_in_trip,
      "required"
    ),
    traveller_type: validator.message(
      t("traveller_type"),
      data.traveller_type,
      "required"
    ),
  };

  const returnError = {
    return_departure_date: validator.message(
      t("return_departure_date"),
      data.return_departure_date,
      data.trip_type == 2 ? "required" : ""
    ),
    return_arrival_date: validator.message(
      t("return_arrival_date"),
      data.return_arrival_date,
      data.trip_type == 2 ? "required" : ""
    ),
    return_departure_time: validator.message(
      t("return_departure_time"),
      data.return_departure_time,
      data.trip_type == 2 ? "required" : ""
    ),
    return_arrival_time: validator.message(
      t("return_arrival_time"),
      data.return_arrival_time,
      data.trip_type == 2 ? "required" : ""
    ),
  };
  const arrowStyle = {
    marginTop: error.vehicle_type ? "-3px" : "21px",
  };

  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
        handleData("arriavalCountry", details);
        handleData("stopoverCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const getDepartureStateData = (id) => {
    country.state(id).then((details) => {
      handleData("departureState", details);
    });
  };
  const getStopoverStateData = (id) => {
    country.state(id).then((details) => {
      handleData("stopoverState", details);
    });
  };
  const getArrivalStateData = (id) => {
    country.state(id).then((details) => {
      handleData("arriavalState", details);
    });
  };
  const getDepartureCity = (id) => {
    country.city(id).then((details) => {
      handleData("departureCity", details);
    });
  };
  const getStopoverCity = (id) => {
    country.city(id).then((details) => {
      handleData("stopoverCity", details);
    });
  };
  const getArrivalCity = (id) => {
    country.city(id).then((details) => {
      handleData("arriavalCity", details);
    });
  };

  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getCurrency = () => {
    country.currency().then((respons) => {
      setCurrencyData(respons);
    });
  };

  const handleSubmit = (event) => {
    contextObj.setTravelDetails(data);
    if (validator.allValid()) {
      setLoad(true);
      travel
        .create(data)
        .then((response) => {
          history.push(`/travelDetails/${response.id}/0`);
        })
        .catch((error) => {
          setLoad(false);
          notistack.error(error?.data.message);
        });
    } else {
      showMessage(true);
    }
  };
  const handleSubmitOtherCity = () => {
    country
      .addOtherCity(otherCityData)
      .then((response) => {
        data?.city_departure == "other_city" &&
          handleSetData("city_departure", "");
        getDepartureCity(otherCityData?.state_id);
        // getArrivalCity(otherCityDataArrwal?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };
  const handleSubmitOtherCityArrawl = () => {
    country
      .addOtherCity(otherCityDataArrwal)
      .then((response) => {
        data?.city_arrival == "other_city" && handleSetData("city_arrival", "");
        getArrivalCity(otherCityDataArrwal?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };
  const showError = () => {
    if (validator.allValid()) {
      showMessage(false);
    } else {
      showMessage(true);
    }
  };
  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <h5 style={{ fontWeight: "bold" }}>
                {t("form.Travel_Announcement")}
              </h5>
              <div className="pt-3">
                <h6 style={{ color: "red" }}>
                  {t("form.PLEASE_CARRY_SHIPPER")}
                </h6>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      onInput={onInputUppercase}
                      value={data.title}
                    />
                    <span style={{ color: "red" }}>{error.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.trip_type")}</label>
                    <select
                      id="trip_type"
                      onChange={(e) => {
                        if (e.target.value == 1) {
                          setFiled(0);
                        }
                        handleChange(e);
                      }}
                      name="trip_type"
                      value={data.trip_type}
                    >
                      <option value="" selected disabled>
                        {t("form.select_trip_type")}
                      </option>
                      <option value={1}>{t("form.one_way")}</option>
                      <option value={2}>{t("form.round_trip")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.trip_type}</span>
                  </div>
                </div>
              </div>
              {data.trip_type == 2 ? (
                <div className="row d-flex justify-content-center align-items-center pt-50">
                  <div className="package-details-wrapper">
                    <div className="tour-package-details">
                      <div className="package-details-tabs">
                        <ul
                          className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                          id="pills-tab"
                          role="tablist"
                          style={{ marginBottom: "0px" }}
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              id="pills-package1"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body1"
                              type="button"
                              role="tab"
                              aria-controls="pill-body1"
                              aria-selected="true"
                              style={{ fontSize: "16px", borderRadius: "0px" }}
                              onClick={() => {
                                setFiled(0);
                              }}
                              className={
                                filed == 1 ? "nav-link" : "nav-link active"
                              }
                            >
                              {t("form.Onward_Journey_Details")}
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              id="pills-package2"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body2"
                              type="button"
                              role="tab"
                              aria-controls="pill-body2"
                              aria-selected="false"
                              style={{ fontSize: "16px", borderRadius: "0px" }}
                              onClick={() => {
                                setFiled(1);
                              }}
                              className={
                                filed == 1 ? "nav-link active" : "nav-link"
                              }
                            >
                              {t("form.Return_Journey_Details")}
                            </button>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_Of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_departure"
                      onChange={(e) => {
                        handleChange(e, "country_of_Departure");
                        handleSetData(
                          filed === 1 ? "state_arrival" : "state_departure",
                          ""
                        );
                        handleSetData(
                          filed === 1 ? "city_arrival" : "city_departure",
                          ""
                        );
                       filed===1? getArrivalStateData(e.target.value): getDepartureStateData(e.target.value);
                      }}
                      name={
                        filed === 1 ? "country_arrival" : "country_departure"
                      }
                      value={
                        filed === 1
                          ? data.country_arrival
                          : data.country_departure
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.country_Of_departure")}
                      </option>

                      {filed === 1
                        ? details.arriavalCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.departureCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.country_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country_arrival"
                      onChange={(e) => {
                        handleChange(e, "country_arrival");
                        handleSetData(
                          filed === 1 ? "state_departure" : "state_arrival",
                          ""
                        );
                        handleSetData(
                          filed === 1 ? "city_departure" : "city_arrival",
                          ""
                        );
                       filed===1?getDepartureStateData(e.target.value): getArrivalStateData(e.target.value);
                      }}
                      name={
                        filed === 1 ? "country_departure" : "country_arrival"
                      }
                      value={
                        filed === 0
                          ? data.country_arrival
                          : data.country_departure
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.country_of_arrival")}
                      </option>
                      {filed === 0
                        ? details.arriavalCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.departureCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.country_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country_arrival}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.state_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_departure"
                      onChange={(e) => {
                        handleChange(e, "state_of_Departure");
                        handleSetData(
                          filed === 1 ? "city_arrival" : "city_departure",
                          ""
                        );
                       filed===1?getArrivalCity(e.target.value): getDepartureCity(e.target.value);
                        handleSetDataOtherCity("state_id", e.target.value);
                      }}
                      name={filed === 1 ? "state_arrival" : "state_departure"}
                      value={
                        filed === 1 ? data.state_arrival : data.state_departure
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_departure")}
                      </option>

                      {filed === 1
                        ? details.arriavalState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.departureState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.state_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.state_departure}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.state_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="state_arrival"
                      onChange={(e) => {
                        handleChange(e, "state_arrival");
                        handleSetData(
                          filed === 1 ? "city_departure" : "city_arrival",
                          ""
                        );
                       filed===1?getDepartureCity(e.target.value): getArrivalCity(e.target.value);
                        handleSetDataOtherCityArrawl(
                          "state_id",
                          e.target.value
                        );
                      }}
                      name={filed === 1 ? "state_departure" : "state_arrival"}
                      value={
                        filed === 1 ? data.state_departure : data.state_arrival
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.state_of_arrival")}
                      </option>

                      {filed === 1
                        ? details.departureState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.arriavalState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.state_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.state_arrival}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.city_of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_departure"
                      onChange={(e) => {
                        handleChange(e, "city_of_Departure");
                      }}
                      name={filed === 1 ? "city_arrival" : "city_departure"}
                      value={
                        filed === 1 ? data.city_arrival : data.city_departure
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_departure")}
                      </option>

                      {filed === 1
                        ? details.arriavalCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.departureCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                      {otherCityData?.state_id && (
                        <option value="other_city">
                          {t("form.Add_your_City")}
                        </option>
                      )}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.city_departure ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.city_departure}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.city_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="city_arrival"
                      onChange={(e) => {
                        handleChange(e, "city_arrival");
                      }}
                      name={filed === 1 ? "city_departure" : "city_arrival"}
                      value={
                        filed === 1 ? data.city_departure : data.city_arrival
                      }
                    >
                      <option value="" selected disabled>
                        {t("form.city_of_arrival")}
                      </option>
                      {/* {details.arriavalCity?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))} */}
                      {filed === 1
                        ? details.departureCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))
                        : details.arriavalCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                      {otherCityDataArrwal?.state_id && (
                        <option value="other_city">
                          {t("form.Add_your_City")}
                        </option>
                      )}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.city_arrival ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.city_arrival}</span>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-lg-4 col-12">
                  {data.city_departure == "other_city" && (
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.Add_your_City")}</label>
                      <input
                        required
                        disabled={!otherCityData?.state_id}
                        type="text"
                        placeholder={t("form.Add_your_City")}
                        id="city_name"
                        onChange={(e) => {
                          handleSetDataOtherCity("city_name", e.target.value);
                        }}
                        name="city_name"
                        value={otherCityData?.city_name}
                      />
                    </div>
                  )}
                </div>
                <div
                  className="col-lg-2 col-12"
                  style={{ display: "flex", alignItems: "end" }}
                >
                  {data.city_departure == "other_city" && (
                    <div className="custom-input-group">
                      <button
                        type="submit"
                        onClick={handleSubmitOtherCity}
                        // className="btn btn-secondary"

                        style={{
                          fontSize: "14px",
                        }}
                      >
                        {t("form.Add")}
                      </button>
                    </div>
                  )}
                </div>
                {data.city_arrival == "other_city" && (
                  <>
                    <div className="col-lg-4 col-12">
                      <div className="custom-input-group">
                        <label htmlFor="name">{t("form.Add_your_City")}</label>
                        <input
                          required
                          disabled={!otherCityDataArrwal?.state_id}
                          type="text"
                          placeholder={t("form.Add_your_City")}
                          id="city_name"
                          onChange={(e) => {
                            handleSetDataOtherCityArrawl(
                              "city_name",
                              e.target.value
                            );
                          }}
                          name="city_name"
                          value={otherCityDataArrwal?.city_name}
                        />
                      </div>
                    </div>
                    <div
                      className="col-lg-2 col-12"
                      style={{ display: "flex", alignItems: "end" }}
                    >
                      <div className="custom-input-group">
                        <button
                          type="submit"
                          onClick={handleSubmitOtherCityArrawl}
                          // className="btn btn-secondary"

                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Add")}
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>

              {filed == 0 && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name" style={{ display: "block" }}>
                        {t("form.departure_date")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                                            required
                                            type="Date"
                                            id="departure_date"
                                            onChange={handleChange}
                                            name='departure_date'
                                            value={data.departure_date}
                                            min={new Date().toISOString().split('T')[0]}
                                            max={data.arrival_date}
                                        /> */}
                      <div className="customDatePickerWidth">
                        <DatePicker
                          placeholderText={
                            i18n.language === "frFR"
                              ? "dd/mm/yyyy"
                              : "mm/dd/yyyy"
                          }
                          selected={moment(
                            data.departure_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          dateFormat={
                            i18n.language === "frFR"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(date) =>
                            handleSetData(
                              "departure_date",
                              moment(date).format("DD/MM/YYYY")
                            )
                          }
                          minDate={new Date()}
                          maxDate={moment(
                            data.arrival_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          className="px-3"
                        />
                      </div>
                      <span style={{ color: "red" }}>
                        {error.departure_date}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom-input-group ">
                      <label htmlFor="name" style={{ display: "block" }}>
                        {t("form.arrival_date")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                                            required
                                            type="Date"
                                            id="arrival_date"
                                            onChange={handleChange}
                                            name='arrival_date'
                                            value={data.arrival_date}
                                            // min={new Date().toISOString().split('T')[0]}
                                            min={data?.departure_date ? data.departure_date : new Date().toISOString().split('T')[0]}
                                        /> */}
                      <div className="customDatePickerWidth">
                        <DatePicker
                          placeholderText={
                            i18n.language === "frFR"
                              ? "dd/mm/yyyy"
                              : "mm/dd/yyyy"
                          }
                          disabled={!data.departure_date}
                          selected={
                            data.arrival_date &&
                            moment(data.arrival_date, "DD/MM/YYYY").toDate()
                          }
                          dateFormat={
                            i18n.language === "frFR"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(date) =>
                            handleSetData(
                              "arrival_date",
                              moment(date).format("DD/MM/YYYY")
                            )
                          }
                          minDate={moment(
                            data.departure_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          className="px-3"
                        />
                        {/* <DatePicker selected={data.arrival_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data.arrival_date} onChange={(value)=>handleSetData("arrival_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={data.departure_date} className="px-3"/> */}
                        {/* <DatePicker selected={data.arrival_date}  dateFormat={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} value={data.arrival_date} onChange={(value)=>handleSetData("arrival_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={data.departure_date} className="px-3"/> */}
                      </div>
                      <span style={{ color: "red" }}>{error.arrival_date}</span>
                    </div>
                  </div>
                </div>
              )}
              {filed == 0 && (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {t("form.departure_time")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        type="time"
                        id="departure_time"
                        onChange={handleChange}
                        name="departure_time"
                        value={data.departure_time}
                      />
                      <span style={{ color: "red" }}>
                        {error.departure_time}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom-input-group ">
                      <label htmlFor="name">
                        {t("form.arrival_time")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        type="time"
                        id="arrival_time"
                        onChange={handleChange}
                        name="arrival_time"
                        value={data.arrival_time}
                      />
                      <span style={{ color: "red" }}>{error.arrival_time}</span>
                    </div>
                  </div>
                </div>
              )}
              {data.trip_type == 2 && filed == 1 ? (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name" style={{ display: "block" }}>
                        {t("form.return_departure_date")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                                            required
                                            type="Date"
                                            id="return_departure_date"
                                            onChange={handleChange}
                                            name='return_departure_date'
                                            value={data.return_departure_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                      <div className="customDatePickerWidth">
                        <DatePicker
                          placeholderText={
                            i18n.language === "frFR"
                              ? "dd/mm/yyyy"
                              : "mm/dd/yyyy"
                          }
                          disabled={!data.arrival_date}
                          selected={
                            data.return_departure_date &&
                            moment(
                              data.return_departure_date,
                              "DD/MM/YYYY"
                            ).toDate()
                          }
                          dateFormat={
                            i18n.language === "frFR"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(date) =>
                            handleSetData(
                              "return_departure_date",
                              moment(date).format("DD/MM/YYYY")
                            )
                          }
                          minDate={moment(
                            data.arrival_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          maxDate={moment(
                            data.return_arrival_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          className="px-3"
                        />
                        {/* <DatePicker selected={data.return_departure_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data.return_departure_date} onChange={(value)=>handleSetData("return_departure_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={new Date()} maxDate={data.return_arrival_date} className="px-3"/> */}
                      </div>
                      <span style={{ color: "red" }}>
                        {returnError.return_departure_date}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom-input-group ">
                      <label htmlFor="name" style={{ display: "block" }}>
                        {t("form.return_arrival_date")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      {/* <input
                                            required
                                            type="Date"
                                            id="return_arrival_date"
                                            onChange={handleChange}
                                            name='return_arrival_date'
                                            value={data.return_arrival_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                      <div className="customDatePickerWidth">
                        <DatePicker
                          disabled={!data.return_departure_date}
                          placeholderText={
                            i18n.language === "frFR"
                              ? "dd/mm/yyyy"
                              : "mm/dd/yyyy"
                          }
                          selected={
                            data.return_arrival_date &&
                            moment(
                              data.return_arrival_date,
                              "DD/MM/YYYY"
                            ).toDate()
                          }
                          dateFormat={
                            i18n.language === "frFR"
                              ? "dd/MM/yyyy"
                              : "MM/dd/yyyy"
                          }
                          onChange={(date) =>
                            handleSetData(
                              "return_arrival_date",
                              moment(date).format("DD/MM/YYYY")
                            )
                          }
                          minDate={moment(
                            data.return_departure_date,
                            "DD/MM/YYYY"
                          ).toDate()}
                          className="px-3"
                        />
                        {/* <DatePicker selected={data.return_arrival_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data?.return_arrival_date} onChange={(value)=>handleSetData("return_arrival_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={data?.return_departure_date} className="px-3"/> */}
                      </div>
                      <span style={{ color: "red" }}>
                        {returnError.return_arrival_date}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              {data.trip_type == 2 && filed == 1 ? (
                <div className="row">
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {t("form.Return_departure_time")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        type="time"
                        id="return_departure_time"
                        onChange={handleChange}
                        name="return_departure_time"
                        value={data.return_departure_time}
                      />
                      <span style={{ color: "red" }}>
                        {returnError.return_departure_time}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="custom-input-group ">
                      <label htmlFor="name">
                        {t("form.return_arrival_time")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        type="time"
                        id="return_arrival_time"
                        onChange={handleChange}
                        name="return_arrival_time"
                        value={data.return_arrival_time}
                      />
                      <span style={{ color: "red" }}>
                        {returnError.return_arrival_time}
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.carriage_capacity")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      required
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder=""
                      id="carriage_capacity"
                      onChange={handleChange}
                      name="carriage_capacity"
                      value={data.carriage_capacity}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.carriage_capacity ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      kg
                    </span>
                    <span style={{ color: "red" }}>
                      {error.carriage_capacity}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.price_per_kg")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      required
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder=""
                      id="price_per_kg"
                      onChange={handleChange}
                      name="price_per_kg"
                      value={data.price_per_kg}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 20,
                        bottom: error.price_per_kg ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      {contextObj?.loginUser?.symbol}
                    </span>
                    <span style={{ color: "red" }}>{error.price_per_kg}</span>
                  </div>
                </div>
                {/* <div className="col-lg-3">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name">{t("form.currency")}<span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            required
                                            id="currency_id"
                                            onChange={(e) => {
                                                handleChange(e, "currency_id");
                                            }}
                                            name='currency_id'
                                            value={data.currency_id}
                                        >
                                            <option value="" selected disabled>{t("form.Select_currency")}</option>
                                            {currencyData.map((item) => <option value={item.id} key={item.id}>{item.code} ({item.symbol})</option>)}
                                        </select>
                                        <span style={{ color: "red" }}>{error.currency}</span>
                                    </div>
                                </div> */}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>{t("form.Is_a_stopover")}</label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ps-0">
                          <ul className="row">
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="stopover_in_trip"
                                name="stopover_in_trip"
                                required
                                onChange={(e) => {
                                  setIsRadio(true);
                                  handleSetData("stopover_in_trip", false);
                                  setOpen(false);
                                  showMessage(false);
                                }}
                                checked={isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="stopover_in_trip"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="stopover_in_trip"
                                name="stopover_in_trip"
                                required
                                onChange={(e) => {
                                  setIsRadio(false);
                                  handleSetData("stopover_in_trip", true);
                                  setOpen(true);
                                  showMessage(false);
                                }}
                                checked={!isRadio}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="stopover_in_trip"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="custom-input-group"></div>
                </div>
              </div>
              {open ? (
                <>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.country_Of_stopper")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="SelectElement"
                          onChange={(e) => {
                            handleChange(e, "country_Of_stopper");
                            handleSetData("state_stopover", "");
                            handleSetData("city_stopover", "");
                            console.log("r", e.target.value);
                            getStopoverStateData(e.target.value);
                          }}
                          name="country_stopover"
                          value={data.country_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.country_Of_stopper")}
                          </option>
                          {details.stopoverCountry?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <spna>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </spna>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.state_Of_stopper")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="state_stopover"
                          onChange={(e) => {
                            handleChange(e, "state_of_stopover");
                            handleSetData("city_stopover", "");
                            getStopoverCity(e.target.value);
                          }}
                          name="state_stopover"
                          value={data.state_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.state_Of_stopper")}
                          </option>
                          {details.stopoverState?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <spna>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </spna>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name">
                          {t("form.city_of_stopover")}
                          <span style={{ color: "red" }}>*</span>
                        </label>
                        <select
                          required
                          id="city_stopover"
                          onChange={(e) => {
                            handleChange(e, "city_of_stopover");
                          }}
                          name="city_stopover"
                          value={data.city_stopover}
                        >
                          <option value="" selected disabled>
                            {t("form.city_of_stopover")}
                          </option>
                          {details.stopoverCity?.map((item) => (
                            <option value={item.id} key={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </select>
                        <spna>
                          <i
                            class="bi bi-caret-down-fill"
                            style={{ marginTop: "21px" }}
                          ></i>
                        </spna>
                      </div>
                    </div>
                  </div>
                </>
              ) : null}
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.pick_up_place")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_pick_up_place")}
                      id="pickup_place"
                      onChange={handleChange}
                      name="pickup_place"
                      onInput={onInputUppercase}
                      value={data.pickup_place}
                    />
                    <span style={{ color: "red" }}>{error.pickup_place}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.delivery_place")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_delivery_place")}
                      id="delivery_place"
                      onChange={handleChange}
                      name="delivery_place"
                      onInput={onInputUppercase}
                      value={data.delivery_place}
                    />
                    <span style={{ color: "red" }}>{error.delivery_place}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.vehicle_type")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="vehicle_type"
                      onChange={handleChange}
                      name="vehicle_type"
                      value={data.vehicle_type ? data.vehicle_type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.type_of_transport")}
                      </option>
                      {i18n.language === "frFR"
                        ? contextObj.vehicleTypeFr?.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))
                        : contextObj.vehicleTypeEn?.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <spna>
                      <i class="bi bi-caret-down-fill" style={arrowStyle}></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.vehicle_type}</span>
                  </div>
                </div>
                <div className="col-lg-2">
                  <div className="custom-input-group">
                    {data.vehicle_type == 0 ? (
                      false
                    ) : (
                      <img
                        src={
                          data.vehicle_type == 1
                            ? "/images/vehicles/Bike.png"
                            : data.vehicle_type == 2
                            ? "/images/vehicles/boat.png"
                            : data.vehicle_type == 3
                            ? "/images/vehicles/bus.png"
                            : data.vehicle_type == 4
                            ? "/images/vehicles/car.png"
                            : data.vehicle_type == 5
                            ? "/images/vehicles/kickscooter.png"
                            : data.vehicle_type == 6
                            ? "/images/vehicles/train.png"
                            : data.vehicle_type == 7
                            ? "/images/vehicles/MoterBike.png"
                            : data.vehicle_type == 8
                            ? "/images/vehicles/plan.png"
                            : data.vehicle_type == 9
                            ? "/images/vehicles/scooter.png"
                            : data.vehicle_type == 10
                            ? "/images/vehicles/Tvg.png"
                            : data.vehicle_type == 11
                            ? "/images/vehicles/Tramway.png"
                            :data.vehicle_type == 12?
                            "/images/vehicles/train.png"
                            : ""
                        }
                        height="100%"
                        width="100%"
                      />
                    )}
                  </div>
                </div>
                {data.vehicle_type == 0 ? (
                  false
                ) : (
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {data.vehicle_type == 1
                          ? ""
                          : data.vehicle_type == 2
                          ? t("form.Boat")
                          : data.vehicle_type == 3
                          ? t("form.Bus")
                          : data.vehicle_type == 4
                          ? t("form.Car_Registration")
                          : data.vehicle_type == 5
                          ? t("form.kick_scooter")
                          : data.vehicle_type == 6
                          ? t("form.Train_Number")
                          : data.vehicle_type == 7
                          ? t("form.MoterBike")
                          : data.vehicle_type == 8
                          ? t("form.Flight_Number")
                          : data.vehicle_type == 9
                          ? t("form.Scooter")
                          : data.vehicle_type == 10
                          ? t("form.Tgv")
                          : data.vehicle_type == 11
                          ? t("form.Tramway")
                          : ""}
                      </label>
                      {data.vehicle_type == 1 ? (
                        " "
                      ) : (
                        <input
                          required
                          type="text"
                          placeholder={
                            data.vehicle_type == 1
                              ? ""
                              : data.vehicle_type == 2
                              ? t("form.Boat")
                              : data.vehicle_type == 3
                              ? t("form.Bus")
                              : data.vehicle_type == 4
                              ? t("form.Car_Registration")
                              : data.vehicle_type == 5
                              ? t("form.kick_scooter")
                              : data.vehicle_type == 6
                              ? t("form.Train_Number")
                              : data.vehicle_type == 7
                              ? t("form.MoterBike")
                              : data.vehicle_type == 8
                              ? t("form.Flight_Number")
                              : data.vehicle_type == 9
                              ? t("form.Scooter")
                              : data.vehicle_type == 10
                              ? t("form.Tgv")
                              : data.vehicle_type == 11
                              ? t("form.Tramway")
                              : ""
                          }
                          id="vehicle_details"
                          onChange={handleChange}
                          name="vehicle_details"
                          value={data.vehicle_details}
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>{t("form.travel_insurance")}</label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ps-0 ">
                          <ul className="row">
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="insurance"
                                name="insurance"
                                required
                                onChange={(e) => {
                                  setIsShow(false);
                                  handleSetData("insurance", false);
                                }}
                                checked={!isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-4 col-md-4">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="insurance"
                                name="insurance"
                                required
                                onChange={(e) => {
                                  setIsShow(true);
                                  handleSetData("insurance", true);
                                }}
                                checked={isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.traveller_type")}</label>
                    <select
                      id="traveller_type"
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      name="traveller_type"
                      value={data.traveller_type}
                    >
                      <option value="" selected disabled>
                        {t("form.traveller_type")}
                      </option>
                      <option value={1}>{t("form.Regular")}</option>
                      <option value={2}>{t("form.periodical")}</option>
                      <option value={3}>{t("form.Oneoff")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.traveller_type}</span>
                  </div>
                </div>
              </div>

              {/* <div className="row"> */}
              <div className="col-lg-12">
                <div className="custom-input-group">
                  <label htmlFor="name">
                    {t("form.packaging_instruction")}
                  </label>
                  <textarea
                    rows={3}
                    id="packing_instruction"
                    onChange={handleChange}
                    name="packing_instruction"
                    value={data.packing_instruction}
                    style={{ borderRadius: "5px" }}
                    maxLength={160}
                  ></textarea>
                  {/* <span style={{ color: "red" }}>{error.packing_instruction}</span> */}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="custom-input-group">
                  <label htmlFor="name">{t("form.Description")}</label>
                  <textarea
                    rows={3}
                    id="description"
                    onChange={handleChange}
                    name="description"
                    value={data.description}
                    style={{ borderRadius: "5px" }}
                    maxLength={160}
                  ></textarea>
                  {/* <span style={{ color: "red" }}>{error.description}</span> */}
                </div>
              </div>
              {data.trip_type == 2 && filed == 1 && (
                <div className="">
                  <div
                    class="form-check-inline d-flex pt-50"
                    style={{ justifyContent: "space-between" }}
                  >
                    <div class="d-flex">
                      <input
                        class=""
                        type="checkbox"
                        id="inlineCheckbox1"
                        style={{
                          width: "15px",
                          height: "15px",
                          marginTop: "3px",
                          marginRight: "8px",
                        }}
                        name="oneWayTicket"
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                        checked={data.terms_and_condition}
                      />
                      <label style={{ fontSize: "14px" }}>
                        {t("profile.i_would_like_to_publish")}
                      </label>
                    </div>
                  </div>
                  {/* <span style={{ color: "red", fontSize: "14px" }}>{error.terms_and_condition}</span> */}
                </div>
              )}

              {data.trip_type == 1 || !data.trip_type ? (
                <div className="package-sidebar">
                  <aside
                    className="package-widget widget-tour-categoris mt-30"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="widget-body">
                      <div className="d-flex align-items-center">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate"
                          onChange={(e) => {
                            handleSetData(
                              "terms_and_condition",
                              e.target.checked ? true : false
                            );
                          }}
                          checked={data.terms_and_condition}
                        />{" "}
                        <span style={{ marginLeft: "10px" }}>
                          {t("form.Terms_and_condition")}
                        </span>
                      </div>
                    </div>
                    {/* <span style={{ color: "red" }}>{error.terms_and_condition}</span> */}
                  </aside>
                </div>
              ) : (
                ""
              )}

              {
                // data.trip_type &&
                data.trip_type == 1 || !data.trip_type ? (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        {/* <Link to={`${process.env.PUBLIC_URL}/sellArticle`}>   */}
                        <button
                          type="submit"
                          disabled={!data.terms_and_condition}
                          onClick={() => (handleSubmit(), scrollTop())}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Preview_Advert")}
                        </button>
                        {/* </Link> */}
                      </div>
                    </div>
                  </div>
                ) : filed == 1 ? (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        <button
                          type="submit"
                          disabled={!data.terms_and_condition}
                          onClick={() => (handleSubmit(), scrollTop())}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Preview_Advert")}
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="custom-input-group row col-lg-12">
                    <div className=" col-sm-6 col-md-6 col-lg-">
                      <div className="submite-btn">
                        <button
                          type="submit"
                          onClick={() => {
                            setFiled(1);
                            showError();
                          }}
                          className="btn btn-secondary"
                          style={{
                            fontSize: "14px",
                          }}
                        >
                          {t("form.Submit_and_fill")}
                        </button>
                      </div>
                    </div>
                  </div>
                )
              }
              <div className="col-12  mt-2">
                <p>
                  {t("form.Note")}
                  <br />
                  {t("form.aerosol")}
                  <br />
                  {t("form.firearm")}
                  <br />
                  {t("form.ammunition")}
                  <br />
                  {t("form.weapon")}
                  <br />
                  {t("form.general")}
                </p>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
export default PostTravelAnnouncement;
