import React, { useState, useEffect, useContext, useRef } from "react";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";
import { useTranslation } from "react-i18next";
import { chat } from "../../models";
import AppContext from "../../store/context/app-context";
import * as signalR from "@microsoft/signalr";
import { IoMdSend } from "react-icons/io";

export default function Chat({
  receiverID,
  senderID,
  product_id,
  product_type,
  requestedby,
  requestedto,
}) {
  const { t } = useTranslation();
  const [connection, setConnection] = useState(null);
  const [messages, setMessages] = useState([]);
  const [user, setUser] = useState("");
  const [message, setMessage] = useState("");
  const [users, setUsers] = useState([]);
  const [connectionIdState, setConnectionIdState] = useState();
  const contextObj = useContext(AppContext);
  const [receiverId, setReceiverId] = useState("");
  const [connectioId, setConnctionId] = useState();
  const [receiverConnectionId, setReceiverConnectionId] = useState();
  const [senderConnectionId, setSenderConnectionId] = useState();
  const [senderName, setSenderName] = useState();
  const [receiverName, setReceiverName] = useState();
  const messageRef = useRef();
  const textMessageBoxRef = useRef();

  useEffect(() => {
    getChatFunction();
    joinRoom();

    return () => {
      closeConnection();
    };
  }, []);

  useEffect(() => {
    if (messageRef && messageRef.current) {
      const { scrollHeight, clientHeight } = messageRef.current;
      messageRef.current.scrollTo({
        left: 0,
        top: scrollHeight - clientHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  const getChatFunction = () => {
    chat
      .getChatList(product_id, product_type)
      .then((response) => {
        if (response.message === "Ther is no Convertion") {
        } else if (response.length >= 0) {
          // !senderName &&
          setSenderName(
            contextObj.loginUser.id ===
              response[response.length - 1]?.sender_user_id
              ? response[response.length - 1]?.sender_user_name
              : response[response.length - 1]?.receiver_user_name
          );
          // !senderConnectionId &&
          setSenderConnectionId(
            contextObj.loginUser.id ===
              response[response.length - 1]?.sender_user_id
              ? response[response.length - 1]?.sender_ConnectionId
              : response[response.length - 1]?.receiver_ConnectionId
          );
          // !receiverConnectionId &&
          setReceiverConnectionId(
            contextObj.loginUser.id ===
              response[response.length - 1]?.sender_user_id
              ? response[response.length - 1]?.receiver_ConnectionId
              : response[response.length - 1]?.sender_ConnectionId
          );
          // !receiverName &&
          setReceiverName(
            contextObj.loginUser.id ===
              response[response.length - 1]?.sender_user_id
              ? response[response.length - 1]?.receiver_user_name
              : response[response.length - 1]?.sender_user_name
          );
          setMessages(response);
        }
      })
      .catch((error) => {
        console.log("Error while fetch chat history.");
      });
  };

  async function joinRoom() {
    try {
      const connection = new HubConnectionBuilder()
        .withUrl("http://localhost:5000/chat", {
          skipNegotiation: true,
          transport: HttpTransportType.WebSockets,
        })
        .configureLogging(LogLevel.Information)
        .build();

      console.log("connection: ", connection);

      connection.on("ReceiveMessage", (senderUserId, message) => {
        console.log("ReceiveMessage: ", senderUserId, message);
        // getChatFunction();

        setMessages((prevState) => [
          ...prevState,
          {
            product_id: product_id,
            product_type: product_type,
            sender_user_id: senderUserId,
            reciver_user_id: receiverID,
            text: message,
            sender_user_name: senderName,
            receiver_user_name: receiverName,
            sender_ConnectionId: senderConnectionId,
            receiver_ConnectionId: receiverConnectionId,
          },
        ]);
      });

      // connection.on("UsersInRoom", (users) => {
      //   setUsers(users);
      // });

      await connection
        .start()
        .then(function () {
          connection.invoke("GetConnectionId").then(function (id) {
            console.log("ConnectionId: ", id);
            if (id) {
              chat
                .saveConnectionId(id)
                .then(() => {
                  console.log("Connection Id is save.");
                })
                .catch((error) => {
                  console.log("Connection Id not saved.");
                });
              // setConnctionId(id);
            }
          });
          // document.getElementById("sendButton").disabled = false;
        })
        .catch(function (err) {
          return console.error(err.toString());
        });
      // await connection.invoke("JoinRoom", { room, user });
      setConnection(connection);

      connection.onclose((e) => {
        setConnection();
        setMessages([]);
        setUsers([]);
      });
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    console.log("messages are here: ", messages);
  }, [messages]);

  // const sendMessage = async (senderID, receiverID, message) => {
  const sendMessage = () => {
    console.log(
      "senderID, receiverID, message: ",
      senderID,
      receiverID,
      message
    );
    // try {
    //   await connection.invoke(
    //     "SendMessage",
    //     "shivraj",
    //     connectionIdState,
    //     message
    //   );
    // console.log("connectioId: ", connectioId);
    chat
      .sendMessage({
        product_id: product_id,
        product_type: product_type,
        sender_user_id: senderID,
        reciver_user_id: receiverID,
        text: message,
        ConnectionId: receiverConnectionId,
      })
      .then(() => {
        getChatFunction();
      })
      .catch((error) => {
        console.log("Error while send message.");
      });
    // } catch (e) {
    //   console.log(e);
    // }
  };

  const closeConnection = async () => {
    try {
      await connection.stop();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div className="" style={{ width: "100%" }}>
      <div className="d-flex justify-content-center" style={{ width: "100%" }}>
        <div className="" style={{ width: "100%" }}>
          <div className="" style={{ width: "100%" }}>
            <div className="px-2 py-2 scroll" ref={messageRef}>
              {messages.map((item, index) => {
                return (
                  <>
                    {item.sender_user_id == contextObj.loginUser.id ? (
                      <div
                        className="d-flex text-right pb-2 justify-content-end"
                        key={index}
                      >
                        <div className="pr-2">
                          <div className="name text-end pe-2">
                            {item.sender_user_id == contextObj.loginUser.id
                              ? item.sender_user_name
                              : item.receiver_user_name}
                          </div>
                          <div className="msg mb-1 me-2 bg-primary text-white">
                            {item.text}
                          </div>
                        </div>
                        <div>
                          <img
                            src="https://i.imgur.com/HpF4BFG.jpg"
                            width="35"
                            className="img1"
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="d-flex" key={index}>
                        <div className="text-left pr-1">
                          <img
                            src="https://img.icons8.com/color/40/000000/guest-female.png"
                            width="35"
                            className="img1"
                          />
                        </div>
                        <div className="pr-2 pl-1">
                          <div className="name d-flex ps-2">
                            {item.sender_user_id == contextObj.loginUser.id
                              ? item.receiver_user_name
                              : item.sender_user_name}
                          </div>
                          <div className="msg mb-1 ms-2 bg-secondary text-white">
                            {item.text}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
            </div>
            <nav className="navbar bg-white navbar-expand-sm d-flex flex-row justify-content-between">
              <div style={{ width: "77%" }}>
                <input
                  type="text"
                  placeholder={t("profile.Type_message")}
                  onChange={(e) => setMessage(e.target.value)}
                  className="border-0"
                  ref={textMessageBoxRef}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      sendMessage();
                      textMessageBoxRef.current.value = "";
                    }
                  }}
                />
              </div>
              <div
                onClick={() => {
                  sendMessage();
                  textMessageBoxRef.current.value = "";
                }}
                className="d-flex align-items-center justify-content-center me-2 text-white p-2 rounded"
                style={{
                  backgroundColor: "#ff4838",
                  cursor: "pointer",
                  width: "20%",
                }}
              >
                Send
                <span>
                  <IoMdSend
                    className="ms-1"
                    style={{ marginBottom: "1px" }}
                    size={18}
                  />
                </span>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
}
