import React from "react";
import SubmitTravelsWrapperArea from "./SubmitTravelsWrapperArea";
import Breadcrumb from "../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function SubmitTravels() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("menu.submit_an_ad")}/>
      <SubmitTravelsWrapperArea />
     
    </>
  );
}

export default SubmitTravels;
