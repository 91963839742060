import React from "react";
import ViewTravelWrapperArea from "./ViewTravelWrapperArea";
import Breadcrumb from "../../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function ViewTravel() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t('form.Travel_Details')}/>
      <ViewTravelWrapperArea />
    </>
  );
}

export default ViewTravel;
