import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Travels from "./Travels";

function SubmitTravelsWrapperArea() {
  const { t } = useTranslation();


  return (
    <>
      <div className="custom-input-group row" >
        <div className="submite-btn" style={{ position: 'absolute', textAlign: 'center', bottom: '30px', zIndex: "1" }}>
         <Link to={'/submitTravels'}> <button type="submit" id="travelbtnColor" style={{ backgroundColor: 'white', color: '#ff4838', borderRadius: '5px' }} >{t("menu.travels")}</button></Link>
         <Link to={'/submitLuggages'}> <button type="submit" id="LuggageBtnColor" style={{ margin: '10px', borderRadius: '5px', backgroundColor: '#ff4838', color: 'white' }} >{t("menu.luggages")}</button></Link>
        </div>
      </div>
       <Travels />
    </>
  );
}

export default SubmitTravelsWrapperArea;
