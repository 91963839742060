import React, { useContext, useEffect, useState } from "react";
import { Button, Modal, Form } from "react-bootstrap";
import request from "../../../models/request";
import notistack from "../../../plugin/notistack";
import luggage from "../../../models/luggage";
import error from "../../../lang/enUS/error";
import AppContext from "../../../store/context/app-context";
import { useTranslation } from "react-i18next";
import Loading from "../../common/Loading";

export default function LuggageData(props) {
  const [decodedString, setdecodedString] = useState();
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const imageArray =
    contextObj.requestedLuggage?.imagesURL ??
    contextObj.requestedLuggage?.image;
  useEffect(() => {
    convert();
    contextObj.setRequestedLuggage((prevState) => {
      const newState = {
        ...prevState,
        sender_signature: contextObj?.senderSignature,
      };
      return newState;
    });
  }, []);

  const backToPage = () => {
    props.setHide(1);
  };

  const convert = () => {
    let arr = [];
    props?.luggageIdData?.image?.forEach((item) => {
      fetch(item)
        .then((response) => {
          if (!response.ok) {
            throw new Error("Network response was not ok");
          }
          return response.blob();
        })
        .then((blob) => {
          let reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => {
            let baseURL = reader.result;
            arr.push(baseURL);
          };
        })
        .catch((error) => {
          console.log("Fetch error:", error);
        });
      arr && setdecodedString(arr);
    });
  };

  // const handleSubmit = () => {
  //   if (!props.luggageIdData) {
  //     console.log(
  //       "inside if condition contextObj?.requestedLuggage: ",
  //       contextObj?.requestedLuggage
  //     );
  //     luggage.create(contextObj?.requestedLuggage).then((respons) => {});
  //   }

  //   const formData = new FormData();
  //   formData.append("travel_id", props?.allData.id);
  //   formData.append("title", props?.luggageIdData?.title);
  //   formData.append(
  //     "terms_and_condition",
  //     props?.luggageIdData?.terms_and_condition
  //   );
  //   formData.append("type", props?.luggageIdData?.type);
  //   formData.append("location", props?.luggageIdData?.location);
  //   formData.append(
  //     "luggage_estimation",
  //     props?.luggageIdData?.luggage_estimation
  //   );
  //   formData.append("weight", props?.luggageIdData?.weight);
  //   formData.append("height", props?.luggageIdData?.height);
  //   formData.append("width", props?.luggageIdData?.width);
  //   formData.append("volume", props?.luggageIdData?.volume);
  //   formData.append("colour", props?.luggageIdData?.colour);
  //   formData.append("warranty", props?.luggageIdData?.warranty);
  //   formData.append("receiver_name", props?.luggageIdData?.receiver_name);
  //   formData.append(
  //     "receiver_phone_code",
  //     props?.luggageIdData?.receiver_phone_code
  //   );
  //   formData.append(
  //     "receiver_contact_number",
  //     props?.luggageIdData?.receiver_contact_number
  //   );
  //   formData.append(
  //     "receiver_relation",
  //     props?.luggageIdData?.receiver_relation
  //   );
  //   formData.append("self_pickup", props?.luggageIdData?.self_pickup);
  //   formData.append("description", props?.luggageIdData?.description);
  //   console.log("decodedString in handle submit: ", decodedString);
  //   formData.delete("images");
  //   formData.append("images", decodedString);
  //   formData.append("sender_signature", contextObj?.senderSignature);
  //   console.log("FormData values:");
  //   for (const pair of formData.entries()) {
  //     console.log(pair[0], pair[1]);
  //   }
  //   request
  //     .createLuggage(formData)
  //     .then((respons) => {
  //       notistack.success(t("form.Request_successfully"));
  //       props.setHide(3);
  //     })
  //     .catch((error) => {
  //       notistack.error(error?.data.message);
  //     });
  // };

  const handleSubmit = () => {
    setLoad(true)
    let requestData = {};


    requestData = {
      travel_id: props?.allData.id,
      title: props?.luggageIdData?.title,
      terms_and_condition: props?.luggageIdData?.terms_and_condition,
      type: props?.luggageIdData?.type,
      location: props?.luggageIdData?.location,
      luggage_estimation: props?.luggageIdData?.luggage_estimation,
      weight: props?.luggageIdData?.weight,
      height: props?.luggageIdData?.height,
      width: props?.luggageIdData?.width,
      volume: props?.luggageIdData?.volume,
      colour: props?.luggageIdData?.colour,
      warranty: props?.luggageIdData?.warranty,
      receiver_name: props?.luggageIdData?.receiver_name,
      receiver_phone_code: props?.luggageIdData?.receiver_phone_code,
      receiver_contact_number: props?.luggageIdData?.receiver_contact_number,
      receiver_relation: props?.luggageIdData?.receiver_relation,
      self_pickup: props?.luggageIdData?.self_pickup,
      description: props?.luggageIdData?.description,
      images: decodedString,
      sender_signature: contextObj?.senderSignature,
    };
    request
      .createLuggage(
        !props.luggageIdData ? contextObj?.requestedLuggage : requestData
      )
      .then((respons) => {
        if (!props.luggageIdData) {
          luggage.create(contextObj?.requestedLuggage).then((respons) => {
            luggage.valid(respons.id).then((resp) => {
            })
          });
        }
        setLoad(false)
        notistack.success(t("form.Request_successfully"));
        props.setHide(3);
      })
      .catch((error) => {
        setLoad(false)
        notistack.error(error?.data.message);
      });
  };

  return (
    <>
      {load ? (<Loading />) :
        <>
          <div class="p-4">
            <div className="row">
              <div className="col-lg-12">{t("form.Luggage_data")}</div>
              <div className="col-lg-12">
                <div className="row">
                  <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                    <div class="earning pt-3">
                      <div style={{ background: "white" }}>
                        <div className="col-12 row">
                          <div className="col-4 d-flex">
                            <div className="col-3">
                              <img
                                src={props.allData?.departure_flag}
                                style={{
                                  aspectRatio: "4/2",
                                  objectFit: "contain",
                                  width: "70%",
                                  height: "60%",
                                  marginTop: "-7px",
                                }}
                              ></img>
                            </div>
                            <div className="col-8">
                              <h6 style={{ color: "black" }}>
                                {props.allData?.city_departure_name}
                              </h6>
                            </div>
                          </div>
                          <div className="col-2">
                            <h5 style={{ color: "black" }}>
                              <i class="bi bi-arrow-right"></i>
                            </h5>
                          </div>
                          <div className="col-5 d-flex">
                            <div className="col-3">
                              <img
                                src={props.allData?.arrival_flag}
                                style={{
                                  aspectRatio: "4/2",
                                  objectFit: "contain",
                                  width: "70%",
                                  height: "60%",
                                  marginTop: "-7px",
                                }}
                              ></img>
                            </div>
                            <div className="col-8">
                              <h6 style={{ color: "black" }}>
                                {props.allData?.city_arrival_name}
                              </h6>
                            </div>
                          </div>
                        </div>
                        <table class="table">
                          <tbody class="border-0">
                            <tr class="border-0">
                              <td class="border-0 fw-normal ps-0">
                                <i
                                  class="bi bi-calendar"
                                  style={{ color: "#f39c12" }}
                                />{" "}
                                {
                                  props.allData?.departure_date}{" "}
                                |
                                {
                                  props.allData?.departure_time}
                                hrs
                              </td>
                              <td class="border-0"></td>
                              <td class="border-0 fw-normal ps-0">
                                <i
                                  class="bi bi-calendar"
                                  style={{ color: "#f39c12" }}
                                />{" "}
                                {
                                  props.allData?.arrival_date}{" "}
                                |{" "}
                                {
                                  props.allData?.arrival_time}{" "}
                                hrs
                              </td>
                            </tr>
                            <tr class="border-0">
                              <td class="border-0 fw-normal ps-0">
                                {" "}
                                {t("profile.departure")}
                              </td>
                              <td class="border-0"></td>
                              <td class="border-0 fw-normal ps-0">
                                {" "}
                                {t("profile.arrival")}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                        <div className="row">
                          <div className="col-12 col-md-6">
                            <img
                              src={
                                props?.allData?.vehicle_type == 1
                                  ? "/images/vehicles/Bike.jpg"
                                  : props?.allData?.vehicle_type == 2
                                    ? "/images/vehicles/boat.jpg"
                                    : props?.allData?.vehicle_type == 3
                                      ? "/images/vehicles/bus.png"
                                      : props?.allData?.vehicle_type == 4
                                        ? "/images/vehicles/car.png"
                                        : props?.allData?.vehicle_type == 5
                                          ? "/images/vehicles/kickscooter.jpg"
                                          : props?.allData?.vehicle_type == 6
                                            ? "/images/vehicles/train.png"
                                            : props?.allData?.vehicle_type == 7
                                              ? "/images/vehicles/MoterBike.png"
                                              : props?.allData?.vehicle_type == 8
                                                ? "/images/vehicles/plan.jpg"
                                                : props?.allData?.vehicle_type == 9
                                                  ? "/images/vehicles/scooter.jpg"
                                                  : props?.allData?.vehicle_type == 10
                                                    ? "/images/vehicles/Tvg.png"
                                                    : props?.allData?.vehicle_type == 11
                                                      ? "/images/vehicles/Tramway.jpg"
                                                      : props?.allData?.vehicle_type == 12
                                                        ? "/images/vehicles/train.png"
                                                        : ""
                              }
                              height="100px"
                            />
                          </div>
                          <div className="col-12 col-md-6 d-flex  flex-column justify-content-center ps-0">
                            <h6 className="fs-6" style={{ color: "black" }}>
                              {t("profile.travelling_by")}:
                            </h6>
                            <h5 className="fs-4" style={{ color: "black" }}>
                              {props.allData?.vehicle_details}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* <div className='col-lg-5 col-md-4 pb-3'>
                            <div class="earning ">
                                <div class="plan " style={{ background: 'white' }}>
                                    <header style={{ padding: '17px 14px' }}>
                                        <h6 className="fw-bold text-start" style={{ color: 'black' }}>{t('form.price_to_pay')}: </h6>
                                        <h6 className="text-end pe-3" style={{ color: 'black' }}>{t('form.traveller_fees')}: 4kg*2€ = <span className="fs-5" style={{ color: '#1e98d7' }}>8 €</span></h6>
                                        <h6 className="text-end pe-3" style={{ color: 'black' }}>{t('form.service_fees')}:  = <span className="fs-5" style={{ color: '#1e98d7' }}>2 €</span></h6>
                                        <hr className='m-0' style={{ color: 'orange' }} />
                                        <h6 className="text-end pe-3" style={{ color: 'black' }}>{t('form.total_fees')} = <span className="fs-5" style={{ color: '#1e98d7' }}>10 €</span></h6>
                                    </header>
                                </div>
                            </div>
                        </div> */}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                    <div class="table-responsive">
                      <table class="table table-borderless">
                        <tbody class="border-0">
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.luggage_title")}:
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.title ||
                                props?.luggageIdData?.title}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.type_of_luggage")}:
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.type === "1" ||
                                props?.luggageIdData?.type === "1"
                                ? t("form.New_Never_Used")
                                : t("form.Used_Secound_hand")}
                            </td>
                          </tr>
                        </tbody>
                        <tbody class="border-0">
                          <tr class="border-0">
                            <td class="border-0 text-decoration-underline">
                              {t("profile.luggage_dimensions")}:
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("profile.height")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.height ||
                                props?.luggageIdData?.height}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("profile.length")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.length ||
                                props?.luggageIdData?.length}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("form.Width")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.width ||
                                props?.luggageIdData?.width}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("profile.weight")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.weight ||
                                props?.luggageIdData?.weight}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("form.Colour")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.colour ||
                                props?.luggageIdData?.colour}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">{t("form.Volume")}</td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.volume ||
                                props.luggageIdData?.colour}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.current_price_estimate")}
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.luggage_estimation ||
                                props?.luggageIdData?.luggage_estimation}
                              {contextObj?.loginUser?.symbol}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.product_warranty")}
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.warranty == true
                                ? "Yes"
                                : "No" || props?.luggageIdData.warranty == true
                                  ? "Yes"
                                  : "No"}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                    <div class="table-responsive">
                      <table class="table table-borderless">
                        <tbody class="border-0">
                          <tr class="border-0">
                            <td class="border-0 text-decoration-underline">
                              {t("profile.luggage_receivers_details")}:
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.name_of_receiver")}
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.receiver_name ||
                                props?.luggageIdData?.receiver_name}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.phone")}/Contact #
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.receiver_contact_number ||
                                props?.luggageIdData?.receiver_contact_number}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.your_relationship_with_receiver")}
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.receiver_relation ||
                                props?.luggageIdData?.receiver_relation}
                            </td>
                          </tr>
                          <tr class="border-0">
                            <td class="border-0 fw-normal">
                              {t("profile.Luggage_location")}
                            </td>
                            <td class="border-0 fw-bold">
                              {contextObj.requestedLuggage?.location ||
                                props?.luggageIdData?.location}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <div className="row pb-3">
                  <h4 className="fs-5" style={{ textDecorationLine: "underline" }}>
                    {t("form.packaging_instruction")}:
                  </h4>
                  <p>{props.allData?.packing_instruction}</p>
                </div>
                <div className="row pb-3">
                  <h4 className="fs-5" style={{ textDecorationLine: "underline" }}>
                    {t("form.Description")}:
                  </h4>
                  <p>{props.allData?.description}</p>
                </div>
                <div className="row">
                  <h4 class="fs-5 pb-4">{t("profile.luggage_images")} :</h4>
                  {imageArray?.map((item, index) => {
                    return (
                      <div class="col-lg-3 col-md-6">
                        <h6>
                          {index < 2 ? t("profile.exterior") : t("profile.interior")}{" "}
                          {index == 0
                            ? "1"
                            : index == 1
                              ? "2"
                              : index == 2
                                ? "1"
                                : index == 3 && "2"}
                        </h6>
                        <img
                          src={item}
                          style={{
                            aspectRatio: "2/2",
                            objectFit: "contain",
                            width: "100%",
                          }}
                        ></img>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="col-lg-12">
                <h5>{t("profile.Please_content_carried")}:</h5>
              </div>
              <div className="col-lg-12 pt-2">
                {contextObj.requestedLuggage?.description ||
                  props?.luggageIdData?.description}
              </div>
              <div className="col-lg-6 pt-4">
                <Button
                  style={{
                    backgroundColor: "#ff4838",
                    borderColor: "#ff4838",
                    borderRadius: "5px",
                    margin: "2px",
                  }}
                  onClick={backToPage}
                >
                  <i class="bi bi-arrow-left-circle" />{" "}
                  {t("form.edit_luggage_details")}
                </Button>
              </div>
              <div className="col-lg-6 pt-4">
                <Button
                  style={{
                    backgroundColor: "#ff4838",
                    borderColor: "#ff4838",
                    borderRadius: "5px",
                    margin: "2px",
                  }}
                  onClick={handleSubmit}
                >
                  {t("form.validate_send_request")}
                </Button>
              </div>
            </div>
          </div>
        </>}
    </>

  );
}
