const menu = {
    about_us: 'Qui sommes-nous ?',
    home: 'Accueil',
    contact_us: 'Contactez-nous',
    about_us: 'Qui sommes-nous ?',
    submit_an_ad: 'Déposer une annonce',
    my_account: 'Profil',
    login: 'Connexion',
    registration: 'Inscription',
    logout: 'Déconnexion',
    travels:"Voyages",
    luggages:"Bagages",
    sell_article:"Vendre l'article",
    articles:"Articles",
    article_donation:"Don d'articles",

    // footer
    follow_us_on: 'Suivez-nous sur',
    rate_our_website: 'Notez notre site web',
    legals: 'Mentions Légales',
    more_on_cobagage: 'En savoir plus sur Cobagage',
    cobagage_as_a_job: 'Cobagage comme job',
    privacy_policy:' Politique de confidentialité',
    gallery:'Galerie',
    Cobagage_copyright_2019_All_rights_reserved:'©Cobagage Copyright 2019-2024 Tous droits réservés.',

    Earnings_Report:"Rapport sur les revenus",
    Spending_Reports:"Rapport de dépenses",
    Received:"Reçue",
    Total_Net_Earnings:"Bénéfice net total",
    Transaction_Date:"Date de la transaction &",
    Description:"Description",
    Cobagage_fees:"Frais de cobagage",
    Net_Amount_earned:"Montant net gagné",
    Total_Spendings:"Dépenses totales",
    Sent:"Envoyé",
    Paid_to:"Versée à",
    Total_Amount_paid:"Montant total payé",
    Luggage_carrying_cost:"Coût total d'article vendu",

    // Tips_And_Advice
    Tips_And_Advice:"Astuces Et Conseils",
    Tips_And_AdviceText1:"Bienvenu(e)s à tous sur le site Cobagage, le site qui fait des voyageurs des hommes d'affaires (Business man, Business woman).Dans cette rubrique nous vous donnerons des astuces et conseils pour avoir des transactions réussies :",
    Tips_And_AdviceText2:"monétiser au maximum votre voyage tout en restant écolo !",
    Tips_And_AdviceText3:"VOUS (VOYAGEUR OU EXPEDITEUR DE BAGAGES)",
    Tips_And_AdviceText4:"Votre inscription est validée par un code envoyé simultanément sur votre émail et votre numéro de téléphone unique. Ensuite il faudra télécharger un justificatif d'identité afin d'avoir un profil vérifié (visible sur toutes vos annonces sur le site).",
    Tips_And_AdviceText5:"ACCEPTATION OU REFUS DES DEMANDES DE TRANSACTIONS (ENVOI DE BAGAGES OU TRANSPORT DE BAGAGES EN TANT QUE VOYAGEUR)",
    Tips_And_AdviceText6:"Vous postez vos annonces et vous aurez des demandes de certains utilisateurs. Vous devez ensuite soit accepter ou refuser les demandes. Si vous accepter l'auteur de la demande devra juste payer les frais de transactions : envoies de bagages par prix fixé au kg, achat de marchandises, transport de bagages en tant que voyageur.",
    Tips_And_AdviceText7:"Très important",
    Tips_And_AdviceText8:"Intéressez-vous uniquement aux utilisateurs n'ayant que des profils avec la mention 'Vérifié'. Ce sont des profils pour lesquels les utilisateurs ont téléchargé leur justificatif d'identité (carte d'identité, carte de résident, green carte, titre de séjour...)",
    Tips_And_AdviceText9:"RENDEZ-VOUS POUR LE RAMASSAGE/RECUPERATION DU BAGAGE A TRANSPORTER (VOUS LE VOYAGEUR)",
    Tips_And_AdviceText10:"Dans votre annonce de vente de kilos de bagages en tant que voyageur, vous devez indiquer le lieu exact pour récupérer le bagage avec lequel vous devez voyager. Soyez précis et simple afin de facilité la récupération du bagage. Ne faîtes rien de compliquer. Un endroit public où vous serez à l'aise.",
    Tips_And_AdviceText11:"LE BAGAGE A CONTROLER",
    Tips_And_AdviceText12:"Ayez un instrument de mesure ou de pesée afin de mesurer le poids total du bagage. Vous devez vérifier absolument que le poids total payé en ligne est le même poids que l'expéditeur vous présente. Contrôler le bagage non fermé et facile à vérifier. En cas de doute annulez tout simplement la transaction afin que le remboursement soit fait au propriétaire du bagage.",
    Tips_And_AdviceText13:"Ne soyez pas responsable du transport d'un bagage douteux.",
    Tips_And_AdviceText14:"Les photos mises en ligne pour le bagage doivent absolument être les mêmes que ceux que celles que vous voyez en face de vous.",
    Tips_And_AdviceText15:"LE VOYAGE OU LE TRAJET",
    Tips_And_AdviceText16:'Vous avez la possibilité depuis la rubrique "SUIVI DE MES VOYAGES" de faire une mise à jour de votre parcours en postant des photos de tous vos escales ou stops effectués.',
    DELIVERY:"LIVRAISON",
    Tips_And_AdviceText17:"Le récepteur de bagage ou de l'article (en cas de vente d'article) doit vous donner un code de livraison afin de valider ou de finaliser la transaction encours.",
    Tips_And_AdviceText18:"NOTE ET COMMENTAIRE",
    Tips_And_AdviceText19:"A la fin de la livraison, vous pouvez noté et faire un commentaire sur l'utilisateur avec qui vous avez finaliser ou effectué la transaction.",
    Tips_And_AdviceText20:"PORTEFEUILLE COBAGAGE",
    Tips_And_AdviceText21:"Dès l'acceptation de la demande que vous avez reçue, l'utilisateur (expéditeur de bagage ou acheteur d’article à vendre) paiera le montant total en ligne. Vous aurez dans votre 'Portefeuille Cobagage' votre contrepartie de la transaction. Vous avez juste à faire une demande de versement ou de paiement sur une carte bancaire que vous aurez renseignée.",
    Tips_And_AdviceText22:"En Conclusion soyez en contact avec des utilisateurs ayant des profils vérifiés et ne prenez jamais un bagage fermé, douteux, non conforme à ce qui a été mis sur le site, éviter les bagages avec des kilos en trop (nombre de kilos incohérent avec ce qui a été annoncé - vous déclarez 10 kilos en ligne et le voyageur mesure 15 kg).",
    Tips_And_AdviceText23:"Vous êtes l’unique responsable de tout ce que vous transporter, Cobagage ne fait que de la mise en relation.",
    Tips_And_AdviceText24:"En cas de doute annuler tout simplement la transaction car il n'y a aucune obligation à transporter des bagages sur le site Cobagage.",
    Finally_on_Cobagage_you_can:"Finalement sur Cobagage, vous pouvez",
    finallyText1:"Vendre des kilos de bagages lorsque vous voyagez",
    finallyText2:"Vendre des articles lorsque vous voyagez",
    finallyText3:"Faire des dons d'articles, un acte écologique de donner une seconde vie aux objets",
    Excellent_transactions_Cobagage_website:"Excellentes transactions sur le site Cobagage!",
    Article_Deleted_Successfully:"Article supprimé avec succès",
    Travle_Deleted_Successfully:"Voyage supprimé avec succès",
    Donation_Deleted_Successfully:"Don supprimé avec succès",
    Luggage_Deleted_Successfully:"Bagage supprimé avec succès"

};
export default menu;