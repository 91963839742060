import React from "react";
import ProfileInfoWrapperArea from "./ProfileInfoWrapperArea";
import Breadcrumb from "../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function ProfileInfo() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb name={t('profile.complete_your_profile')}/>
      <ProfileInfoWrapperArea />
     
    </>
  );
}

export default ProfileInfo;
