import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { auth, country, user } from "../../../models";
import AppContext from "../../../store/context/app-context";
import useValidator from "../../../plugin/validator";
import notistack from "../../../plugin/notistack";
import Loading from "../../common/Loading";

const defaultData = {
  account_type: '',
  full_name: '',
  country_id: '',
  phone_code: '',
  contact_number: '',
  company_contact_name: '',
  company_landline_code: '',
  landline_number: '',
  contact_type: '',
  offical_company_number: '',
  email: '',
  password: '',
  confirm_password: '',

  sms_otp: '',
  email_otp: '',
  send_otp_to: '',
  contact_number_or_email: '',
}

function RegistrationWrapperArea() {
  const { t } = useTranslation();
  const [data, setData] = useState(defaultData);
  const history = useHistory();
  const [countries, setCountries] = useState();
  const [validator, showMessage] = useValidator();
  const [isOpen, setIsOpen] = useState(false);
  const [isShow, setIsShow] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setshowConfirmPassword] = useState(false);
  const contextObj = useContext(AppContext);
  const [load, setLoad] = useState(false);
  const [number, seNumber] = useState();

  // useEffect(() => {
  //   setLoad(true);
  //   setTimeout(() => {
  //     setLoad(false);
  //   }, 2000);
  // }, []);

  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };
  const hadleConfirmPassword = () => {
    setshowConfirmPassword((show) => !show);
  };

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const error = {
    email: validator.message(t('auth.email'), data.email, 'required|email|string|max:200'),
    full_name: validator.message(!isShow ? t("auth.full_Name") : t("auth.company_name"), data.full_name, 'required|string|max:200'),
    country_id: validator.message(t("auth.country"), data.country_id, 'required|integer'),
    password: validator.message(t('auth.password'), data.password, 'required|password|max:200'),
    confirm_password: validator.message(t("auth.confirm_password"), data.confirm_password, `required|password|`),
    confirm_password2: validator.message(t("auth.confirm_password"), data.confirm_password, `in:${data.password}`),
    phone_code: validator.message(t("auth.phone"), data.phone_code, 'required|string|max:20'),
    contact_number: validator.message(t("profile.contact_number"), data.contact_number, 'required|string|max:20'),
  };

  useEffect(() => {
    getData();
    if (!isShow) {
      handleSetData('account_type', 1);
    }
  }, []);

  const getData = () => {
    country
      .country()
      .then((details) => {
        setCountries(details);
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      // if (isOpen) {
      //   setLoad(true);
      //   auth.otpVerified(data).then((response) => {
      //     setLoad(false);
      //     notistack.success(response.message);
      //     history.push("/login");
      //     showMessage(false);
      //   })
      //     .catch((error) => {
      //       setLoad(false);
      //       notistack.error(error.data.message);
      //     })
      // }
      if (!isOpen) {
        setLoad(true);
        data.email = data.email.toLowerCase();
        user.create(data).then((item) => {
          window.sessionStorage.setItem("contact_number", item.contact_number);
          window.sessionStorage.setItem("email", item.email);
          history.push("/otp")
          showMessage(false);
        })
          .catch((error) => {
            setLoad(false);
            notistack.error(error.data.message);
          })
      }
    }
    else {
      showMessage(true);
    }
  }
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <div>
            {!isOpen ?
              (<div class='contact-form-wrap pb-110'>
                <div class='row'>
                  <div class='col-lg-5 col-12 col-md-5'>
                    <img src="/images/registration/rG1.png" style={{ width: '100%' }} />
                  </div>
                  <div class='col-lg-6 col-12 col-md-7'>
                    <form onSubmit={handleSubmit}
                      action="#"
                      method="post" >
                      <div className="">
                        <div className="">
                          <h4>{t("auth.what_type_of_account_would_you_like_to_create")}</h4>
                          <div className="custom-input-group mt-0">
                            <div className="package-sidebar" >
                              <aside className="package-widget widget-duration " style={{ boxShadow: 'none' }} >
                                <div className="widget-body ">
                                  <ul className="row">
                                    <li className="deration-check col-lg-4 col-md-6">
                                      <input className="form-check-input"
                                        type="radio"
                                        id="dura1"
                                        name="account_type"
                                        required
                                        onChange={(e) => {
                                          setIsShow(false);
                                          setShowPassword(false);
                                          setshowConfirmPassword(false);
                                          setData(defaultData);
                                          showMessage(false);
                                          handleSetData('account_type', e.target.checked ? '1' : '');
                                        }}
                                        checked={!isShow} />
                                      <label className="form-check-label" htmlFor="dura1" style={{ fontWeight: '600' }}>
                                        {t("auth.individual_account")}
                                      </label>
                                    </li>
                                    <li className="deration-check col-lg-6 col-md-6">
                                      <input className="form-check-input"
                                        type="radio"
                                        id="dura1"
                                        name='account_type'
                                        required
                                        onChange={(e) => {
                                          setIsShow(true);
                                          setShowPassword(false);
                                          setshowConfirmPassword(false);
                                          setData(defaultData);
                                          showMessage(false);
                                          handleSetData('account_type', e.target.checked ? '2' : '');
                                        }}
                                        checked={isShow}
                                      />
                                      <label className="form-check-label" htmlFor="dura2" style={{ fontWeight: '600' }}>
                                        {t("auth.company_account")}
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </aside>
                            </div>
                          </div>
                          {!isShow ?
                            <div className="">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="custom-input-group">
                                    <label htmlFor="name"> {t("auth.full_Name")}  <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                      required
                                      type="text"
                                      placeholder={t("auth.full_Name")}
                                      id="full_name"
                                      onChange={handleChange}
                                      name='full_name'
                                      value={data.full_name}
                                    />
                                    <span style={{ color: "red" }}>{error.full_name}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.email")}  <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                      required
                                      type="email"
                                      placeholder={t("auth.email")}
                                      onChange={handleChange}
                                      name='email'
                                      value={data.email}
                                    />
                                    <span style={{ color: "red" }}>{error.email}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-4">
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.country")}<span style={{ color: 'red' }}> *</span></label>
                                    <select
                                      id="country_id"
                                      onChange={(e) => {
                                        handleChange(e, "country_id");
                                        const findPhoneCode = () => {
                                          return countries.find((item) => item.id == e.target.value ? item : '').phone_code;
                                        }
                                        handleSetData("phone_code", findPhoneCode());
                                      }}
                                      name='country_id'
                                      value={data.country_id}
                                      required
                                      style={{ appearance: 'auto' }}
                                    >
                                      <option value='' selected disabled>{t("auth.country")}</option>
                                      {countries?.map((item) => <option value={item.id} key={item.id} >{item.name}</option>)}
                                    </select>
                                    <span style={{ color: "red" }}>{error.country_id}</span>
                                  </div>
                                </div>
                                <div className="row col-lg-8" style={{ paddingRight: '0px' }} >
                                  <div className="col-5 col-sm-4 col-lg-4">
                                    <div className="custom-input-group">
                                      <label htmlFor="name">{t("auth.phone")} <span style={{ color: 'red' }}>*</span></label>
                                      <select
                                        required
                                        id="phone_code"
                                        onChange={handleChange}
                                        name='phone_code'
                                        value={data.phone_code}
                                        disabled={data.phone_code}
                                        className='numberType'
                                        style={{ padding: '0px 0px 0px 14px' }}
                                      >
                                        <option value='' disabled>{t("profile.phone_code")}</option>
                                        {countries?.map((item) => item.id == data?.country_id && <option value={item.phone_code} key={item.id}>{item.phone_code} {item.country_code}</option>)}
                                      </select>
                                    </div>
                                  </div>
                                  <div className="col-7 col-sm-8 col-lg-8" style={{ paddingRight: '0px', marginTop: '40px' }}>
                                    <div className="custom-input-group">
                                      <input
                                        required
                                        type="number"
                                        onWheel={numberInputOnWheelPreventChange}
                                        placeholder={t("auth.phone")}
                                        id="contact_number"
                                        onChange={handleChange}
                                        name='contact_number'
                                        value={data.contact_number}
                                        className='numberType'
                                      />
                                      <span style={{ color: "red" }}>{error.contact_number}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.password")}<span style={{ color: 'red' }}>*</span></label>
                                    <label style={{ fontSize: "14px" }}>{t("auth.patter")}</label>
                                    <input
                                      required
                                      type={showPassword ? 'text' : 'password'}
                                      placeholder={t("auth.enter_password")}
                                      id="password"
                                      onChange={handleChange}
                                      name='password'
                                    // value={data.password}
                                    />
                                    {showPassword ? <i id="passwordfiled" onClick={handleShowPassword} class="bi bi-eye-slash-fill" /> : <i id="passwordfiled" onClick={handleShowPassword} class="bi bi-eye-fill" />}
                                    <span style={{ color: "red" }}>{error.password}</span>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="custom-input-group ">
                                    <label htmlFor="email">{t("auth.confirm_password")} <span style={{ color: 'red' }}>*</span></label>
                                    <label style={{ fontSize: "14px" }}>{t("auth.patter")}</label>
                                    <input
                                      required
                                      type={showConfirmPassword ? 'text' : 'password'}
                                      placeholder={t("auth.enter_confirm_password")}
                                      id="confirm_password"
                                      onChange={handleChange}
                                      name='confirm_password'
                                    // value={data.confirm_password}
                                    />
                                    {showConfirmPassword ? <i id="passwordfiled" onClick={hadleConfirmPassword} class="bi bi-eye-slash-fill" /> : <i id="passwordfiled" onClick={hadleConfirmPassword} class="bi bi-eye-fill" />}
                                    <span style={{ color: "red" }}>{error.confirm_password2 ? t("form.Passwords_are_not_same") : error.confirm_password}</span>

                                  </div>
                                </div>
                              </div>
                            </div> :
                            <div className="">
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="custom-input-group">
                                    <label htmlFor="name"> {t("auth.company_name")}  <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                      required
                                      type="text"
                                      placeholder={t("auth.company_name")}
                                      id="full_name"
                                      onChange={handleChange}
                                      name='full_name'
                                      value={data.full_name}
                                    />
                                    <span style={{ color: "red" }}>{error.full_name}</span>
                                  </div>
                                </div>
                                <div className="col-lg-6">
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.email")}  <span style={{ color: 'red' }}>*</span></label>
                                    <input
                                      required
                                      type="email"
                                      placeholder={t("auth.email")}
                                      id="email"
                                      onChange={handleChange}
                                      name='email'
                                      value={data.email}
                                    />
                                    <span style={{ color: "red" }}>{error.email}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6">
                                  <div className="custom-input-group">
                                    <label htmlFor="name"> {t("auth.company_contacts_name")} </label>
                                    <input
                                      type="text"
                                      placeholder={t("auth.full_Name")}
                                      id="company_contact_name"
                                      onChange={handleChange}
                                      name='company_contact_name'
                                      value={data.company_contact_name}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-6"  >
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.country")}<span style={{ color: 'red' }}> *</span></label>
                                    <select
                                      id="country_id"
                                      onChange={(e) => {
                                        handleChange(e, "country_id");
                                        const findPhoneCode = () => {
                                          return countries.find((item) => item.id == e.target.value ? item : '').phone_code;
                                        }
                                        handleSetData("phone_code", findPhoneCode());
                                      }}
                                      name='country_id'
                                      value={data.country_id}
                                      required
                                    >
                                      <option value='' selected disabled>{t("auth.country")}</option>
                                      {countries?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                    </select>
                                    <span style={{ color: "red" }}>{error.country_id}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <label htmlFor="name" class='pt-4' style={{ fontWeight: '500', color: '#2d373c' }}>{t("auth.fixed_phone_landline")}</label>
                                <div className="col-4 col-sm-4 ">
                                  <div className="custom-input-group">
                                    <select
                                      id="company_landline_code"
                                      onChange={handleChange}
                                      name='company_landline_code'
                                      // value={data.company_landline_code}
                                      value={data.phone_code}
                                      disabled={data.phone_code}
                                    >
                                      <option value='' selected disabled>{t("profile.phone_code")}</option>
                                      {countries?.map((item) => <option value={item.phone_code} key={item.id}>{item.phone_code}</option>)}
                                    </select>
                                  </div>
                                </div>
                                <div className="col-8 col-sm-8">
                                  <div className="custom-input-group">
                                    <input
                                      required
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      placeholder={t("auth.phone")}
                                      id="landline_number"
                                      onChange={handleChange}
                                      name='landline_number'
                                      value={data.landline_number}
                                      className='numberType'
                                    />
                                  </div>
                                </div>
                                <div className="row col-lg-12" style={{ paddingRight: '0px' }} >
                                  <label htmlFor="name" class='pt-4' style={{ fontWeight: '500', color: '#2d373c' }}>{t("auth.mobile_phone")} <span style={{ color: 'red' }}>*</span></label>
                                  <div className="col-5 col-sm-4 ">
                                    <div className="custom-input-group">
                                      <select
                                        required
                                        id="phone_code"
                                        onChange={handleChange}
                                        name='phone_code'
                                        value={data.phone_code}
                                        disabled={data.phone_code}
                                      >
                                        <option value='' selected disabled>{t("profile.phone_code")}</option>
                                        {countries?.map((item) => <option value={item.phone_code} key={item.id}>{item.phone_code}</option>)}
                                      </select>
                                      <span style={{ color: "red" }}>{error.phone_code}</span>
                                    </div>
                                  </div>
                                  <div className="col-7 col-sm-8 " style={{ paddingRight: '0px' }}>
                                    <div className="custom-input-group">
                                      <input
                                        required
                                        type="number"
                                        onWheel={numberInputOnWheelPreventChange}
                                        placeholder={t("auth.phone")}
                                        id="contact_number"
                                        onChange={handleChange}
                                        name='contact_number'
                                        className='numberType'
                                        value={data.contact_number}
                                      />
                                      <span style={{ color: "red" }}>{error.contact_number}</span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-12">
                                  <div className="custom-input-group">
                                    <label htmlFor="name">{t("auth.password")}<span style={{ color: 'red' }}>*</span></label>
                                    <label style={{ fontSize: "14px" }}>{t("auth.patter")}</label>
                                    <input
                                      required
                                      type={showPassword ? 'text' : 'password'}
                                      placeholder={t("auth.enter_password")}
                                      id="password"
                                      onChange={handleChange}
                                      name='password'
                                      value={data.password}
                                    />
                                    {showPassword ? <i id="passwordfiled" onClick={handleShowPassword} class="bi bi-eye-slash-fill" /> : <i id="passwordfiled" onClick={handleShowPassword} class="bi bi-eye-fill" />}
                                    <span style={{ color: "red" }}>{error.password}</span>
                                  </div>
                                </div>
                                <div className="col-lg-12">
                                  <div className="custom-input-group ">
                                    <label htmlFor="email">{t("auth.confirm_password")} <span style={{ color: 'red' }}>*</span></label>
                                    <label style={{ fontSize: "14px" }}>{t("auth.patter")}</label>
                                    <input
                                      required
                                      type={showConfirmPassword ? 'text' : 'password'}
                                      placeholder={t("auth.enter_confirm_password")}
                                      id="confirm_password"
                                      onChange={handleChange}
                                      name='confirm_password'
                                      value={data.confirm_password}
                                    />
                                    {showConfirmPassword ? <i  id="passwordfiled" onClick={hadleConfirmPassword} class="bi bi-eye-slash-fill" /> : <i id="passwordfiled" onClick={hadleConfirmPassword} class="bi bi-eye-fill" />}
                                    <span style={{ color: "red" }}>{error.confirm_password2 ? t("form.Passwords_are_not_same") : error.confirm_password}</span>
                                  </div>
                                </div>
                              </div>
                              <div className="row col-lg-12" style={{ paddingRight: '0px', marginTop: '30px' }} >
                                <label htmlFor="name" style={{ fontWeight: '500', color: '#2d373c' }}> {t("auth.official_comapany_number_wich_can_be_verified")} </label>
                                <div className="col-5 col-sm-4 col-lg-4">
                                  <div className="custom-input-group">
                                    <select
                                      id="contact_type"
                                      onChange={handleChange}
                                      name='contact_type'
                                    // value={data.phone_code}
                                    >
                                      <option value='1'>SIREN</option>
                                      <option value='2'>SIRET</option>
                                      <option value='3'>{t("form.Company_Number")}</option>
                                      <option value='4' selected>N° RC</option>
                                      <option value='5'>{t("form.Other_Number")}</option>
                                    </select>
                                  </div>
                                </div>
                                <div className="col-7 col-sm-8 col-lg-8 " style={{ paddingRight: '0px' }}>
                                  <div className="custom-input-group">
                                    <input
                                      required
                                      type="number"
                                      onWheel={numberInputOnWheelPreventChange}
                                      // placeholder={t("auth.phone")}
                                      id="offical_company_number"
                                      onChange={handleChange}
                                      name='offical_company_number'
                                      value={data.offical_company_number}
                                      className='numberType'
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>}
                          <div className="custom-input-group">
                            <div className="submite-btn">
                              <button type="submit" onClick={(event) => { handleSubmit(event); scrollTop(); }}>{t("form.submit")}</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form >
                  </div>
                </div>
              </div>)
              :
              (<div class='contact-form-wrap'>
                <div className='package-sidebar row justify-content-center pt-110 pb-110'>
                  <aside className='package-widget-style-2 widget-form col-lg-6 col-md-8'>
                    <div className='widget-title text-center d-flex justify-content-between'>
                      <h4>{t('auth.verify_your_account')}</h4>
                    </div>
                    <div className='widget-body'>
                      <div className='booking-form-wrapper'>
                        <p style={{ fontWeight: '600' }}>{t('auth.please_enter_the_OTP_you_received_through_SMS_and_email')}<br /> {t('auth.for_verifying_your_account')}</p>
                        <div className='custom-input-group'>
                          <label htmlFor="name"> {t('auth.Enter_OTP_received_on_SMS')}</label>
                          <input
                            required
                            type='text'
                            id='sms_otp'
                            name='sms_otp'
                            value={data.sms_otp}
                            onChange={handleChange}
                          />
                          <span style={{ color: "red" }}>{error.sms_otp}</span>
                          <p style={{ color: '#1e98d7', fontSize: '14px', cursor: 'pointer' }} onClick={() => {
                            // handleResendOtp(1);
                          }}>{t('auth.resend_OTP_code_through_SMS')}</p>
                        </div>
                        <div className='custom-input-group'>
                          <label htmlFor="name"> {t('auth.enter_OTP_received_on_email')}</label>
                          <input
                            required
                            type='text'
                            id='email_otp'
                            name='email_otp'
                            value={data.email_otp}
                            onChange={handleChange}
                          />
                          <span style={{ color: "red" }}>{error.email_otp}</span>
                          <p
                            style={{ color: '#1e98d7', fontSize: '14px', cursor: 'pointer' }}
                            onClick={() => {
                              // handleResendOtp(2);
                            }}>{t('auth.resend_OTP_code_through_email')}</p>

                        </div>
                        <div className='custom-input-group'>
                          <div className='submite-btn d-flex'>
                            <button type='submit' onClick={(event) => (handleSubmit(event), scrollTop())} className='button-fill-round' >{t('form.submit')}</button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </aside>
                </div >
              </div>
              )
            }
          </div>
        </>
      )}
    </>
  );
}

export default RegistrationWrapperArea;
