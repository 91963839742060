const homePage = {
    how_it_works: 'How it Works?',
    economics: 'ECONOMIC',
    ecological: 'ECOLOGICAL',
    secured: 'SECURED',

    the_service_between_travellers: 'The service between travellers',
    with_cobagage_win_money_while_travelling: ' With Cobagage win money while traveling',

    lugagger_where_do_you_want_to_send: "Lugagger, where do you want to send?",
    enter_the_country_where_you_want_to_send_your_luggage: "enter the country where you want to send your luggage",
    country: 'Country',
    country_of_arrival: "Country of Arrival",
    state: "State",
    state_of_arrival: "State of Arrival ",
    city: 'City',
    city_of_arrival: "City of Arrival",
    one_way_ticket: "One Way Ticket",
    round_trip: "Round Trip",


    particular: 'Particular',
    professional: 'Professional',
    traveler_find_out_who_is_shipping_to_your_destination: "Traveler, find out who is shipping to your destination",
    enter_the_country_of_your_destination: "enter the country of your destination",
    luggages: "Luggages",

    imminent_departures: "Imminent departures",
    user: 'User',
    availables: "availables",
    price: "Price",
    submit_my_availables_kg: "Submit my availables kg",
    last_luggage_ads: "Last Luggage Ads",
    send_luggage: "Send Luggage",
    why_choosing_us: "Why choosing us ?",
    read_more: 'Read More',
    international_shipment: "International Shipment",
    cobagging_takes_many_forms_both_by_means_of_transport_and_the_various_destinations_of_travelers: "Is very important, it will be followed by the customer, but it happens at such a time that it takes a lot of work and pain. Who himself suspended the crucibles? Laughter.",
    quick_or_secure_payment: 'Quick/Secure Payment',
    by_choosing_cobagage_you_have_the_assurance_of_a_secure_payment_platform_for_your_transactions: "is important to the customer, it will be followed by ad-piscing company, but it happens at the same time as a lot of work and pain. Who himself suspended the basketball? Laughter is a good cartoon",
    eco_responsive: 'Environmentally friendly',
    by_becoming_a_co_gamer_you_directly_participate_in_reducing_the_carbon_footprint: "It is followed by the development of the client, but at the same time they occur as a lot of work and pain. Who himself suspended the crucibles? Laughter",
    tips_and_tricks: 'Tips & Tricks',
    subscribe: "Subscribe",
    Cobagage_travelers: "Cobagage the first website where travellers earn money",
    With_traveling: "Here you can Travel - Sell - Deliver",
    here_only_travelers_can_decide_what_to_transport: 'Here, only travellers can decide what to transport',
    cobagage_the_first_website_where_travelers_earn_money: 'Cobagage, the first website where travellers earn money',
    here_you_can_travel_sell_deliver: 'Here you can Travel – Sell - Deliver',
    type_of_transports_used_plane_bus_boat_car_bike_rain: 'Type of transports used: Plane – Bus – Boat – Car – Bike – Train',

    welcome: 'Welcome',

    Cobagage_Subscription: "Cobagage Club Subscription",
    Our_Pricing_Members: "Our Pricing For Non Members",
    Become_service_fees: "Become a member of our club and pay 0% service fees",
    Applicable_members: "Applicable site service fees for non members",
    luggage_senders: "For luggage senders",
    For_Travellers: "For Travellers",
    SILVER:" Silver",
    GOLD:"Gold",
    DIAMOND:"Diamond",
    BASIC:"BASIC",
    for:'for',
    free:'Free',
    service_fees_luggages:"0% service fees, pay only trip fare to the traveller for sending luggages",
    service_earnings_customer:"0% service fees on your earnings - receive 100% of trip fare received from your customer",
    articles_included_applied:"kg of baggage and items are not subject to any Co-baggage fees",
    Proceed_Buy:"Proceed to buy",
    INTERMEDIATE:"INTERMEDIATE",
    included_selling_applied:"kg of articles included for selling for free with no service fees applied",
    EXPERT:"EXPERT",
    articles_selling_applied:"kg of articles included for selling for free with no service fees applied",
    service_fees_applied:"kg of articles included for selling for free with no service fees applied",
    free_service_fees_applied:"kg of articles included for selling for free with no service fees applied",
    kg_55_of_articles_included_for_selling_for_free_with_no_service_fees_applied:'23 kg of articles included for selling for free with no service fees applied',
    articles_service_fees_applied:"kg of articles included for selling for free with no service fees applied",
    articles_applied:"kg of articles included for selling for free with no service fees applied",
    SENDING_LUGGAGES:"FOR SENDING LUGGAGES",
    fees_will_added_service:"25% fees will be added as service fees on the total trip amount. [(Unit price:",
    fees_will_added_service2:'(towards service fees) = 125',
    case_cancellation_non_refundabl:"In case of cancellation by either of the parties - service fees will be non refundable. You will receive only 100% trip cost i.e. 100 ",
    case_cancellation_non_refundabl2:'only instead of 125',
    SELLING_ARTICLES:"FOR SELLING ARTICLES",
    sending_sold_articles_to_buyers:"15% service fees will be added for sending sold articles to buyers[(Weight or article sold - 10 kg) * (unit price / kg 10 ",
    sending_sold_articles_to_buyers2:'(towards service fees) = 115 ',
    cancellation_either_parties:"In case of cancellation by either of the parties - service fees will be non refundable. You will receive only 100% of article sending cost i.e. 100",
    cancellation_either_parties2:'only instead of 115',
    FOR_CARRYING_LUGGAGES:"FOR CARRYING LUGGAGES",
    Total_earned_trip_amount:"25% fees will be deducted as service fees on the total earned trip amount. [(Unit price:",
    Total_earned_trip_amount2:' (towards service fees) = 75',
    Total_earned_trip_amount3:"will be amount you will receive as net earning on the trip]",
    fees_will_be_refundable:"In case of cancellation by either of the parties - service fees will be non refundable. Wallet adjustment towards cancellation - ",
    FOR_SELLING_ARTICLES:"FOR SELLING ARTICLES",
    delivering_sold_articles_recepient:"15% service fees will be deducted towards service fees on the earning for delivering sold articles to recepient. [(Weight or article sold - 10 kg) * (unit price / kg 10",
    delivering_sold_articles_recepient2:'(towards service fees) = 85 ',
    In_cancellation_either:"In case of cancellation by either of the parties - service fees will be non refundable. Wallet adjustment towards cancellation -",
    item_sale:"Item on sale",
    item_Donation:"Item on donation",
    Recent_announcements:"Recent travel announcements",
    Recent_articles_listed:"Recent articles listed for sale",
    Recent_articles_donation:"Recent articles listed for donation",
    Travel:"Travel",
    Luggage:"Luggage",
    Travels:"TRAVELS",
    Luggages:"LUGGAGES",
    Select:"Select",
    View_all_travel_announcements:"View all travel announcements",
    View_articles_sale:"View all articles on sale",
    View_articles_donation:"View all articles  for donation",
    Add_New_card: " I sell my trip kg",
    l_send_luggages: "l send luggages",
    I_sell_Article: "I sell article",
    I_make_Donation: "I make donation",
    For:'for',
    no_option:'No options'
}
export default homePage;