import React, { useState } from "react";
import CobagageAdsWrapperArea from "./CobagageAdsWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function CobagageAds() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb name={t("cobagage.cobagage_ads")} />
      <CobagageAdsWrapperArea/>

    </>
  );
}

export default CobagageAds;
