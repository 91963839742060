import React, { useContext, useState } from 'react'
import { Link } from "react-router-dom";
import { Modal, Button, Form } from 'react-bootstrap';
import { useParams } from "react-router-dom";
import AppContext from '../../../store/context/app-context';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { BsFillCheckCircleFill } from 'react-icons/bs'
import stripeSubscribe from '../../../models/stripeSubscribe';
import payPal from '../../../models/payPal';
import Loading from '../../common/Loading';
import { useTranslation } from 'react-i18next';


function PaymentDoneDetails() {
    const contextObj = useContext(AppContext);
    const history = useHistory();
    const { t } = useTranslation();
    const [load, setLoad] = useState(false);
    // const id = window.location && window.location.href.split('paymentDone/')[1].includes("PayerID=") ? window.location.href.split('PayerID=')[1] : window.location.href.split('paymentDone/')[1]
    const id = window.location.href.split('PayerID=')[1]
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };

    // const [data, setData] = useState(
    //     { "subscription_id ": id }
    // );
    // const [sessionId, setSessionId] = useState(window.localStorage.getItem('sessionId'))

    const [paypalData, setPayPalData] = useState(
        {
            'paymentId': window.localStorage.getItem('paymentId'),
            'payerId': id,
            'subscription_id': window.localStorage.getItem('subscription_id'),
        }
    )

    // const tabPanelValue = () => {
    //     if (paypalData.paymentId) {
    //         payPal.CreateSubscribeHistory(paypalData).then((resp) => {
    //             contextObj.setTabValue(14);
    //             history.push('/myProfile');
    //             window.localStorage.removeItem('paymentId')
    //             window.localStorage.removeItem('subscription_id')
    //         }).catch(error => {
    //             console.log("error", error);
    //         })
    //     }
    //     else {
    //         stripeSubscribe.paymetData(sessionId, data).then((resp) => {
    //             window.localStorage.removeItem('sessionId')
    //             contextObj.setTabValue(14);
    //             history.push('/myProfile');
    //         }).catch(error => {
    //             console.log("error", error);
    //         })
    //     }
    // }

    const tabPanelValue = () => {
        setLoad(true)
        payPal.CreateSubscribeHistory(paypalData).then((resp) => {
            contextObj.setTabValue(14);
            history.push('/myProfile');
            window.localStorage.removeItem('paymentId')
            window.localStorage.removeItem('subscription_id')
            setLoad(false)
        }).catch(error => {
            setLoad(false);
            console.log("error", error);
        })
    }

    return (
        <>
            {load ? (
                <Loading />
            ) : (
                <div className='' style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                    <div className='d-flex justify-content-center'>
                        <i class='bi bi-hourglass-split' style={{ fontSize: "85px", color: "orange" }} />
                    </div>
                    <div className='d-flex justify-content-center pt-3'>
                        <h4>{t('form.complete_your_Transaction')}</h4>
                    </div>
                    <div className='d-flex justify-content-center pt-2'>
                        <h4>{t('form.Click_button')}</h4>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <Button style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: '5px', marginTop: "34px" }}
                            onClick={() => tabPanelValue()}
                        >
                            {t('form.Continue_Order')}{' '}<i class="bi bi-arrow-right-circle" />
                        </Button>
                    </div>
                </div>
            )}

        </>
    )
}
export default PaymentDoneDetails;
