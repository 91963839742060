import React from 'react'
import BradcomeCobagageAsJob from '../../common/BradcomeCobagageAsJob'
import CobagageAsJobWrapp from './CobagageAsJobWrapp'
import { useTranslation } from 'react-i18next'

export default function CobagageAsJob() {
    const { t } = useTranslation()
    return (
        <div>
            <BradcomeCobagageAsJob
                name={t("form.Cobagage_as_Job")}
            />
            <CobagageAsJobWrapp/>
        </div>
    )
}
