import React, { useEffect, useState } from 'react'
import RecentArticleCard from '../swipersData/RecentArticleCard'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import articlesDonation from '../../../../models/articlesDonation';
import articles from '../../../../models/articles';
import { t } from 'i18next';

export default function RecentArticlesDonation() {
    const history = useHistory();
    const [donationListed, setDonationListed] = useState();
    const upcommingTour = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        loop: true,
        roundLengths: true,
        autoplay: {
            delay: 15000
        },
        navigation: {
            nextEl: '.testi-next5',
            prevEl: '.testi-prev5',
        },
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            },
        }
    }
    const upcommingTour1 = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        // loop: true,
        roundLengths: true,
        autoplay: {
            delay: 15000
        },
        navigation: {
            nextEl: '.testi-next5',
            prevEl: '.testi-prev5',
        },
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            },
        }
    }
    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        articlesDonation
            .list()
            .then((item) => {
                setDonationListed(item?.filter((item) => item?.isComplete == false));
            })
            .catch(error => {
                console.log("error", error);
            })
    };
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const viewDetails = (type) => {
        scrollTop();
        history.push({
            pathname: (`/cobagage_articles/${type}`),
            state: {
                data: "ArticleDonation"
            }
        });
    }


    return (
        <div class=''>
            <div className="row d-flex justify-content-center  pt-4">
                <div className="testimonial-area testimonial-style-three  p-0" style={{ backgroundImage: 'none' }}>
                    <div className="container " style={{ maxWidth: '1000px' }}>
                        <div className="row d-flex justify-content-center align-items-center">
                            <div className="col-lg-6 col-sm-12 col-sm-6 col-sm-10">
                                <div className="section-head-alpha text-lg-start text-center pb-0">
                                    {/* <h2 class='fs-5'>Recent travel announcements</h2> */}
                                    <p class='fs-4 fw-bold'>
                                        {t('homePage.Recent_articles_donation')}
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                    <div
                                        className="testi-prev5"
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Previous slide"
                                    >
                                        {" "}
                                        <i className="bi bi-arrow-left" />
                                    </div>
                                    <div
                                        className="testi-next5"
                                        tabIndex={0}
                                        role="button"
                                        aria-label="Next slide"
                                    >
                                        <i className="bi bi-arrow-right" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                        <div class='row p-3'>
                            <div class=' col-12 text-end'>
                                <button class='fs-6' onClick={() => viewDetails(2)} style={{ color: '#ff4838', border: '0px', backgroundColor: '#fff' }}> {t('profile.view_all_article_for_donation')} {' '}<i class="bi bi-arrow-right-circle" /></button>
                            </div>
                        </div>
                        <div className="row align-items-center justify-content-center">
                            <div className="col-lg-12 p-0">
                                {/* <Swiper
                                    className="swiper testimonial-slider-three"
                                    {...donationListed?.length >= 4 ? upcommingTour : upcommingTour1}
                                > */}
                                <Swiper
                                    className="swiper testimonial-slider-three"
                                    slidesPerView={1}
                                    speed={500}
                                    spaceBetween={5}
                                    loop={false}
                                    roundLengths={true}
                                    autoplay={{
                                        delay: 5000,
                                    }}
                                    navigation={{
                                        nextEl: ".testi-next4",
                                        prevEl: ".testi-prev4",
                                    }}
                                    breakpoints={{
                                        480: {
                                            slidesPerView: 1
                                        },
                                        576: {
                                            slidesPerView: 2
                                        },
                                        992: {
                                            slidesPerView: 2
                                        },
                                        1200: {
                                            slidesPerView: 3
                                        }
                                    }}
                                >
                                    <div className="swiper-wrapper">
                                        {donationListed?.map(item => (
                                            <SwiperSlide className="swiper-slide" style={{ padding: "10px" }}>
                                                <RecentArticleCard item={item} data={'ArticleDonation'} />
                                            </SwiperSlide>))}
                                    </div>
                                </Swiper>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
