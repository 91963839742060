import React from 'react'
import { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import request from '../../../../models/request';
import { useEffect } from 'react';
import Loading from '../../../common/Loading';
import notistack from '../../../../plugin/notistack';
import useValidator from '../../../../plugin/validator';

const format = {
    request_id: '',
    request_type: '',
    status: '',
    reason_to_reject_article: ''
}
export default function MyRequests() {
    const { t } = useTranslation();
    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false)
    const [acceptRequest, setAcceptRequest] = useState(1);
    const [received, setReceived] = useState(1);
    const [iSent, setISent] = useState([]);
    const [LuggageRequest, setLuggageRequest] = useState();
    const [donationdata, setDonationdata] = useState();
    const [iReceived, setIReceived] = useState([]);
    const [load, setLoad] = useState(false);
    const [validator, showMessage] = useValidator();
    const [data, setData] = useState(format);
    const [selected, setSelected] = useState(1);
    const error = {
        reason_to_reject_article: validator.message('Reason to Reject', data.reason_to_reject_article, acceptRequest == 2 ? 'required|string|max:200' : acceptRequest == 4 ? 'required|string|max:200' : 'string'),
    };

    useEffect(() => {
        getDetails();
        requestSendDetails();
    }, [])

    const getDetails = () => {
        setLoad(true);
        let arr = [];
        request
            .allData(1)
            .then((item) => {
                arr.push(item);
                request
                    .allData(2)
                    .then((item) => {
                        arr.push(item);
                        request
                            .allData(3)
                            .then((item) => {
                                arr.push(item);
                                request
                                    .allData(4)
                                    .then((item) => {
                                        arr.push(item);
                                        setLoad(!true);
                                    })
                            })
                    })
            })
            .catch(error => {
                console.log("error", error);
            })
        setIReceived(arr);
    };
    const requestSendDetails = () => {
        let arr = [];
        request
            .allRequestSend(2)
            .then((item) => {
                arr.push(item);
                request
                    .allRequestSend(1)
                    .then((item) => {
                        arr.push(item);
                        request
                            .allRequestSend(3)
                            .then((item) => {
                                arr.push(item);
                                request
                                    .allRequestSend(4)
                                    .then((item) => {
                                        arr.push(item);
                                    })
                            })
                    })
            })
            .catch(error => {
                console.log("error", error);
            })
        setISent(arr);
    };
    const articleReceived = (id, type) => {
        request
            .getRequestReceived(id, type)
            .then((item) => {
                setLuggageRequest(item);
            })
            .catch(error => {
                console.log("error", error);
            })
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetData(name, value);
    };
    const handleSetData = (name, value) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }
    const requestReceived = () => {
        if (validator.allValid()) {
            request.
                respondToRequestReceived(data)
                .then((item) => {
                    if (acceptRequest == 2) {
                        setAcceptRequest(6);
                        requestSendDetails();
                        getDetails();
                    } else if (acceptRequest == 4) {
                        setAcceptRequest(7);
                        requestSendDetails();
                        getDetails();
                    } else if (acceptRequest == 3) {
                        setAcceptRequest(8);
                        requestSendDetails();
                        getDetails();
                    }
                    else {
                        setAcceptRequest(5);
                        requestSendDetails();
                        getDetails();
                    }
                })
                .catch(error => {
                    console.log("error", error);
                    if (error.data.message) {
                        notistack.error(error.data.message);
                    }
                })
        }
        else {
            showMessage(true);
        }
    }
    const handleClose = () => {
        if (acceptRequest == 5 || acceptRequest == 6) {
            getDetails();
        } else if (acceptRequest == 8 || acceptRequest == 7) {
            requestSendDetails();
            getDetails();
        }
        showMessage(!true);
        setShow(false);
    }

    const handleOpenClose = () => {
        // if (acceptRequest == 5 || acceptRequest == 6) {
        //     getDetails();
        // } else if (acceptRequest == 8 || acceptRequest == 7) {
        //     requestSendDetails();
        //     getDetails();
        // }
        if (acceptRequest == 8) {
            requestSendDetails();
            getDetails();
        }
        showMessage(!true);
        setOpen(false)
    }

    const handleShow = () => {
        setShow(true)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    return (
        <>
            {load ? (
                <Loading />
            ) : (
                <div>
                    <div className='package-details-wrapper p-0' >
                        <div className='tour-package-details'>
                            <div className="package-details-tabs row">
                                <ul
                                    className="nav  nav-pills gap-xxl-4 gap-1 gap-lg-4"
                                    id="pills-tab"
                                    role="tablist"
                                    style={{ marginBottom: '1px', }}

                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link travel active "
                                            id="pills-package1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body1"
                                            aria-selected="true"
                                            style={{ fontSize: '13px', borderRadius: '5px', padding: '8px 30px', background: selected === 1 && 'rgb(238, 146, 0)' }}
                                            onClick={() => setSelected(1)}

                                        >
                                            {t("profile.travels")}
                                        </button>
                                    </li>
                                    <li className="nav-item luggage" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-package2"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body2"
                                            aria-selected="false"
                                            style={{ fontSize: '13px', borderRadius: '5px', background: selected === 2 && ' rgb(83, 189, 226)' }}
                                            onClick={() => setSelected(2)}
                                        >
                                            {t("profile.luggages")}
                                        </button>
                                    </li>
                                    <li className="nav-item article" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-package3"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body3"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body3"
                                            aria-selected="false"
                                            onClick={() => { setData(format); setSelected(3) }}
                                            style={{ fontSize: '13px', borderRadius: '5px', padding: '8px 13px', background: selected === 3 && 'gray' }}
                                        >
                                            Articles({t("profile.sale")})
                                        </button>
                                    </li>
                                    <li className="nav-item donation" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-package4"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body4"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body4"
                                            aria-selected="false"
                                            onClick={() => { setData(format); setSelected(4) }}
                                            style={{ fontSize: '13px', borderRadius: '5px', background: selected === 4 && 'black' }}
                                        >
                                            {t("profile.donations")}
                                        </button>
                                    </li>
                                </ul>
                                <div className='col-lg-2 col-12 p-0'>
                                    <ul
                                        className="nav nav-pills  gap-xxl-4 gap-3 pt-3"
                                        id="pills-tab"
                                        role="tablist"
                                        style={{ marginBottom: '0px' }}
                                    >
                                        <li className="nav-item travel">
                                            <button
                                                className={received == 1 ? "nav-link active" : 'nav-link'}
                                                style={{ fontSize: '13px', borderRadius: '5px', width: "102px" }}
                                                onClick={() => { setReceived(1) }}
                                            >
                                                {t("profile.i_received")}
                                            </button>
                                        </li>
                                        <li className="nav-item travel">
                                            <button
                                                className={received == 2 ? "nav-link active" : 'nav-link'}
                                                style={{ fontSize: '13px', borderRadius: '5px', width: "102px" }}
                                                onClick={() => {
                                                    setReceived(2)
                                                }}
                                            >
                                                {t("profile.i_sent")}
                                            </button>
                                        </li>
                                    </ul>
                                </div>
                                <div className="tab-content col-lg-10 col-12 p-0" id="pills-tabContent">
                                    {/*  Travel*/}
                                    <div
                                        className="tab-pane fade show active package-info-tab pt-4"
                                        id="pill-body1"
                                        role="tabpanel"
                                        aria-labelledby="pills-package1"
                                    >
                                        <div class="container pt-2 h-100 package-card-delta p-3" style={{ boxShadow: 'none' }}>
                                            <h4 className='fs-5'>{received == 1 ? t('profile.requests_I_received_for_carrying_luggages') : t('profile.requests_I_sent_for_carrying_luggages')}</h4>
                                            <hr />
                                            <div class="row d-flex justify-content-center align-items-center h-100 pt-1">
                                                <div class=" col mb-4 mb-lg-0">
                                                    {(received == 1 ? iReceived[0] : iSent[0])?.map(item => item.isComplete == false && item.status !== 2 &&
                                                        <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
                                                            {/* <div class="ribbon red"><span>{t("profile.not_completed")}</span></div> */}
                                                            <div class="row g-0">
                                                                <h4 className='p-2' style={{ backgroundColor: '' }} >{received == 1 ? item?.title : item?.luggage_title}</h4>
                                                                <hr class="mt-0 mb-4" />
                                                                <div class="col-md-7">
                                                                    <div class="card-body pt-0 pb-0">
                                                                        <div class="row ">
                                                                            <div class="col-5 mb-3">
                                                                                <h6><img
                                                                                    src={item?.departure_flag}
                                                                                    style={{ width: "25px", height: "20px", marginRight: '5px' }} />{' '}{item?.city_departure_name}</h6>
                                                                            </div>
                                                                            <div class="col-2 mb-3">
                                                                                <h6><i class="bi bi-arrow-right" ></i></h6>
                                                                            </div>
                                                                            <div class="col-5 mb-3">
                                                                                <h6><img
                                                                                    src={item?.arrival_flag}
                                                                                    style={{ width: "25px", height: "20px", marginRight: '5px' }} />{' '}{item?.city_arrival_name}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row pt-1">
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-calendar-event" />{' '}{t('profile.departure')}</h6>
                                                                                <p class="text-muted">{item?.departure_date} | {item?.departure_time}</p>
                                                                            </div>
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-calendar-event" />{' '}{t('profile.arrival')}</h6>
                                                                                <p class="text-muted">{' '}{item?.arrival_date} | {item?.arrival_time}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row pt-1">
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                <p class="text-muted">{received == 1 ? item?.weight : item?.luggage_weight} Kg</p>
                                                                            </div>
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{received == 1 ? t('profile.request_received_from') : (t('profile.requested_sent_to') + ':')}</h6>
                                                                                <h6 class='text-center' style={{ color: '#1e98d7' }}>{' '}{received == 1 ? '' : (item.luggager_name)}</h6>
                                                                                <p class={received == 1 ? " " : "fw-bold pt-0"} style={{ color: received == 1 ? '#1e98d7' : '' }}>{' '}<span>{received == 1 && item?.request_user_name}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-12">
                                                                    <div class="card-body pt-0 pb-0">
                                                                        <div class="earning">
                                                                            <div class="plan" >
                                                                                <header><i class="bi bi-currency-dollar"></i>
                                                                                    <h6 class="plan-title" style={{ fontSize: '15px' }}>{t('profile.earning_oppurtunity')}</h6>
                                                                                    <div class="plan-cost"><span class="plan-price">{received == 1 ? item?.fees : item?.earning} {item?.currency}</span><span class="plan-type"></span></div>
                                                                                </header>
                                                                            </div>
                                                                            <div class="plan-select pt-2">
                                                                                {received == 1 && <a style={{ cursor: 'pointer', margin: '7px', padding: '11px 10px' }} href={`/luggageRequest/${item?.id}/${item?.request_type}/${received}`}>{t('profile.view_request_details')}</a>}
                                                                                {received == 2 && <a style={{ cursor: 'pointer', margin: '7px', padding: '11px 10px' }} href={`/luggageRequest/${item?.id}/${item?.product_id}/${item?.product_type}/${received}`}>{t('profile.view_request_details')}</a>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  Luggage */}
                                    <div
                                        className="tab-pane fade package-info-tab"
                                        id="pill-body2"
                                        role="tabpanel"
                                        aria-labelledby="pills-package2"
                                        style={{ overflowX: 'auto' }}
                                    >
                                        <div class="container pt-2 h-100 package-card-delta p-3" style={{ boxShadow: 'none' }}>
                                            <h4 className='fs-5 pt-3'>{received == 1 ? t('profile.requests_I_received_from_travellers_for_sending_my_luggages') : t('profile.requests_I_sent_to_travellers_for_sending_my_luggages')}</h4>
                                            <hr />
                                            <div class="row d-flex justify-content-center align-items-center h-100 pt-1">
                                                <div class=" col mb-4 mb-lg-0">
                                                    {(received == 1 ? iReceived[1] : iSent[1])?.map(item => item.isComplete == false && item.status !== 2 &&
                                                        <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
                                                            {/* <div class="ribbon red"><span>{t("profile.not_completed")}</span></div> */}
                                                            <div class="row g-0">
                                                                <h4 className='p-2' style={{ backgroundColor: '' }} >{received == 1 ? item?.luggage_title : item?.title}</h4>
                                                                <hr class="mt-0 mb-4" />
                                                                <div class="col-md-7">
                                                                    <div class="card-body pt-0 pb-0">
                                                                        <div class="row ">
                                                                            <div class="col-4 mb-3">
                                                                                <h6><img
                                                                                    src={item?.departure_flag}
                                                                                    style={{ width: "25px", height: "20px", marginRight: '5px' }} />{' '}{item?.city_departure_name}</h6>
                                                                            </div>
                                                                            <div class="col-2 mb-3">
                                                                                <h6><i class="bi bi-arrow-right" ></i></h6>
                                                                            </div>
                                                                            <div class="col-4 mb-3">
                                                                                <h6><img
                                                                                    src={item?.arrival_flag}
                                                                                    style={{ width: "25px", height: "20px", marginRight: '5px' }} />{' '}{item?.city_arrival_name}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row pt-1">
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-calendar-event" />{' '}{t('profile.departure')}</h6>
                                                                                <p class="text-muted">{item?.departure_date} | {item?.departure_time}</p>
                                                                            </div>
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-calendar-event" />{' '}{t('profile.arrival')}</h6>
                                                                                <p class="text-muted">{' '}{item?.arrival_date} | {item?.arrival_time}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div class="row pt-1">
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.weight_carriage_capacity')}:</h6>
                                                                                <p class="text-muted">{item?.carriage_capacity} Kg</p>
                                                                            </div>
                                                                            <div class="col-6 mb-3">
                                                                                <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{received == 1 ? t('profile.request_received_from') : (t('profile.requested_sent_to') + ':')}</h6>
                                                                                <h6 class='text-center' style={{ color: '#1e98d7' }}>{' '}{received == 1 ? '' : item?.travel_user_name}</h6>
                                                                                <p class={received == 1 ? "" : "fw-bold pt-0"} style={{ color: received == 1 ? '#1e98d7' : '' }}>{' '}<span>{received == 1 && item?.traveler_name}</span></p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div class="col-md-5 col-lg-5 col-12">
                                                                    <div class="card-body pt-0 pb-0">
                                                                        <div class="earning">
                                                                            <div class="plan" >
                                                                                <header>
                                                                                    <i class="bi bi-globe2"></i>
                                                                                    <h6 class="plan-title">{t('profile.travelling_by')}:</h6>
                                                                                    <div class="plan-cost"><span class="plan-price">{item.vehicle_type == 1 ? 'Bike' : item.vehicle_type == 2 ? 'Boat' : item.vehicle_type == 3 ? 'Bus' : item.vehicle_type == 4 ? 'Car' : item.vehicle_type == 5 ? 'Kick Scooter' : item.vehicle_type == 6 ? 'Train' : item.vehicle_type == 7 ? 'MotorBike' : item.vehicle_type == 8 ? 'Flight' : item.vehicle_type == 9 ? 'Scooter' : item.vehicle_type == 10 ? 'TGV' : item.vehicle_type == 11 ? 'TramWay' : ''} </span>{item.vehicle_type != 1 && <span class="plan-type">{item?.vehicle_details}</span>}</div>
                                                                                </header>
                                                                            </div>
                                                                            <div class="plan-select pt-2">
                                                                                {received == 1 && <a style={{ cursor: 'pointer', margin: '7px', padding: '11px 10px' }} href={`/sendingRequest/${item?.id}/${received == 1 ? item?.product_type : item?.request_type}/${received}`}>{t('profile.view_request_details')}</a>}
                                                                                {received == 2 && <a style={{ cursor: 'pointer', margin: '7px', padding: '11px 10px' }} href={`/sendingRequest/${item?.id}/${item?.travel_id}/${received == 1 ? item?.product_type : item?.request_type}/${received}`}>{t('profile.view_request_details')}</a>}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/*  Articles(Sale)*/}
                                    <div
                                        className="tab-pane fade package-info-tab"
                                        id="pill-body3"
                                        role="tabpanel"
                                        aria-labelledby="pills-package3"
                                        style={{ overflowX: 'auto' }}
                                    >
                                        <div class="container pt-2 h-100 package-card-delta p-3" style={{ boxShadow: 'none' }}>
                                            <h4 className='fs-5 pt-3'>{received == 1 ? t('profile.requests_I_received_from_buyers_for_my_articles') : t('profile.requests_I_sent_to_article_sellers_for_buying_their_articles')}</h4>
                                            <hr />
                                            <div class="container p-0 h-100">
                                                <div class="row d-flex justify-content-center align-items-center h-100">
                                                    <div class=" col mb-4 mb-lg-0">
                                                        {(received == 1 ? iReceived[2] : iSent[2])?.map(item => item.is_completed == false && item.status !== 2 &&
                                                            <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
                                                                <div class="row g-0">
                                                                    <div class="col-md-4">
                                                                        <img
                                                                            src={item?.image[0]}
                                                                            // src='https://files.axshare.com/gsc/LYOMSK/ab/37/0c/ab370cb4c44343618d7ab6ac4a50086c/images/my_requests/u302.jpeg?pageId=a1722d23-31c3-4e60-b0e7-cfd824c8e631'
                                                                            class="card-img" alt="..." style={{ aspectRatio: "4/2", objectFit: "contain", height: '100%', }} />
                                                                    </div>
                                                                    <div class="col-md-8">
                                                                        <div class="card-body package-sidebar">
                                                                            <aside className="">
                                                                                <div className=" text-center d-flex justify-content-between">
                                                                                    <h4>{item.title}</h4>
                                                                                    <h3 className="widget-lavel">
                                                                                        {item?.currency} {item.article_price} <span></span>
                                                                                    </h3>
                                                                                </div>
                                                                            </aside>
                                                                            <hr class="mt-0 mb-4" />
                                                                            <div class="row ">
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                        <p class="text-muted">{item?.article_weight} kg</p>
                                                                                    </div> :
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}:{item?.article_weight} kg</h6>
                                                                                        <p class="" style={{ fontWeight: '500px' }}><i class="bi bi-cart-fill"></i>{' '}<span className='fw-6'>{t('profile.quantity_requested')}:</span> {item?.article_quantity}</p>
                                                                                    </div>}
                                                                                {received == 1 ? <div class="col-6 mb-3">
                                                                                    <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.quantity_requested')}</h6>
                                                                                    <p class="text-muted">{item?.article_quantity}</p>
                                                                                </div> :
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6>{' '}{t('profile.seller_location')}:{' '}</h6>
                                                                                        <p class="text-muted"><i class="bi bi-geo-alt-fill"></i>{item.requester_location}</p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div class="row pt-1">
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{t('profile.request_received_from')}</h6>
                                                                                        <p class="fw-bold" ><i class="bi bi-person-fill" /> <span style={{ color: '#1e98d7' }}>{item?.requester_user_name}</span></p>
                                                                                    </div> :
                                                                                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 mb-3">
                                                                                        <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{received == 1 ? t('profile.request_received_from') : (t('profile.requested_sent_to') + ':')}</h6>
                                                                                        <h6 class='fw-bold' >{' '} <i class="bi bi-person-fill" /><span style={{ color: '#1e98d7' }}>{' '} {received == 1 ? '' : item?.requestee_user_name}</span></h6>
                                                                                        {/* <p class={received == 1 ? " " : "fw-bold pt-0"} style={{ color: received == 1 ? '#1e98d7' : '' }}>{' '}<span>{received == 1 && "Elodie Dumas" }</span></p> */}
                                                                                        {/* <p class="text-muted">blue</p> */}
                                                                                    </div>}
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-geo-alt-fill"></i>{' '}{t("profile.requester_location")}</h6>
                                                                                        <p class="text-muted">{' '}{item?.requester_location}</p>
                                                                                    </div> :
                                                                                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 mb-3">
                                                                                        <div class="earning pt-0" style={{ backgroundColor: 'white', borderColor: '#ff4838', border: '2px solid' }}>
                                                                                            <div class="plan" style={{ background: 'white' }}>
                                                                                                <header style={{ padding: '15px 10px' }}><i class="bi bi-currency-dollar1" />
                                                                                                    <h6 class="plan-title m-0" style={{ color: 'black' }}>{t('profile.status')}:</h6>
                                                                                                    <div class="plan-cost"><span class="plan-price fs-6 d-flex align-items-center" style={{ color: item.status == 1 ? ' #63a103' : item.status == 2 ? 'red' : '#f39c12' }}> {item.status !== 0 && <i class={item.status == 1 ? "bi bi-check-circle" : "bi bi-x-circle "} style={{ color: item.status == 1 ? ' #63a103' : 'red', fontSize: '30px' }} />}{item.status == 1 ? t("profile.accepted") : item.status == 2 ? t("profile.rejected") : t('profile.Pending_acceptance_seller2')}</span></div>
                                                                                                </header>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {(received == 1 && item.status == 0) && <hr class="mt-0 mb-4" />}
                                                                            {(received == 1 && item.status == 0) && <div class=" d-flex justify-content-end">
                                                                                <a className='me-3 fs-5' style={{ color: '#63a103', fontWeight: '600', cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        handleShow();
                                                                                        setAcceptRequest(1);
                                                                                        articleReceived(item?.id, item?.product_type);
                                                                                        handleSetData('request_id', item?.id);
                                                                                        handleSetData('request_type', item?.product_type);
                                                                                        handleSetData('status', 1);
                                                                                    }}>
                                                                                    <i class="bi bi-check-circle " style={{ color: ' #63a103' }} />{' '}{t("profile.accept")}</a>
                                                                                <a class='me-3 fs-5' style={{ color: '#ff4838', fontWeight: '600', cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        handleShow();
                                                                                        setAcceptRequest(2);
                                                                                        handleSetData('request_id', item?.id);
                                                                                        handleSetData('request_type', item?.product_type);
                                                                                        handleSetData('status', 2);
                                                                                    }}><i class="bi bi-x-circle "></i>{' '}{t('profile.reject')}</a>
                                                                                <Modal show={show} onHide={handleClose}
                                                                                    size="lg"
                                                                                    // aria-labelledby="contained-modal-title-vcenter"
                                                                                    centered
                                                                                >
                                                                                    <Modal.Header closeButton>
                                                                                        <Modal.Title>{(acceptRequest == 1 || acceptRequest == 3) ? t('profile.accept_confimation') : acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? t('profile.success') : t('profile.reject_confirmation')}</Modal.Title>
                                                                                    </Modal.Header>
                                                                                    <Modal.Body >
                                                                                        <Form>
                                                                                            {acceptRequest == 5 ?
                                                                                                (<div>
                                                                                                    <h4 className='text-center'>{t('profile.congratulations')}</h4>
                                                                                                    <h5 className='p-2 fs-6 text-center'>{t('form.buyer_has_been_notified')}</h5>
                                                                                                    <div className='justify-content-center p-3'>
                                                                                                        <h5 className='text-center pt-2 pb-2'>{t('form.next_step')}:</h5>
                                                                                                        <h6 className='text-center'>1. {t('form.buyer_will_pay')}</h6>
                                                                                                        <h6 className='pt-2 text-center'>2. {t('form.once_the_buyer_pays')}</h6>
                                                                                                        <br />
                                                                                                        <h6 className='text-center'>" {t('form.happy_travelling_by_earning')} "</h6>
                                                                                                    </div>
                                                                                                </div>) :
                                                                                                acceptRequest == 8 ?
                                                                                                    (<div>
                                                                                                        <h4 className='text-center'>{t('profile.congratulations')}</h4>
                                                                                                        <h5 className='p-2 text-center fs-6'>{t('form.user_has_been_notified')}</h5>
                                                                                                        <div className='justify-content-center p-3'>
                                                                                                            <h5 className='text-center pt-2 pb-2'>{t('form.next_step')}:</h5>
                                                                                                            <h6 className='text-center'>{t('form.please_make_agreement')}</h6>
                                                                                                            <br />
                                                                                                            <h6 className='text-center'>"  {t('form.happy_travelling_by_earning')} "</h6>
                                                                                                        </div>
                                                                                                    </div>) :
                                                                                                    acceptRequest == 6 || acceptRequest == 7 ?
                                                                                                        (<div>
                                                                                                            <h5 className='pb-5 text-center'>{t('form.the')} {acceptRequest == 6 ? 'buyer' : 'user'}  {t('form.has_been_notified')}.</h5>
                                                                                                            <h5 className='pb-1 text-center'> {t('form.happy_travelling_by_earning')}</h5>
                                                                                                        </div>) :
                                                                                                        (<div class="container ">
                                                                                                            <div class="row d-flex justify-content-center align-items-center">
                                                                                                                <div class="col  mb-lg-0">
                                                                                                                    <h5 className='text-center fw-none'>{acceptRequest == 1 ? t("profile.your_are_about_to_accept_the_request_from_buyer_to_sell_your_Item") :
                                                                                                                        acceptRequest == 2 ? t('profile.your_are_about_to_reject_the_request_from_buyer_who_wants_to_buy_your_item') :
                                                                                                                            acceptRequest == 3 ? t('profile.accept_the_request_from_user_to_donate') :
                                                                                                                                acceptRequest == 4 ? t('profile.reject_the_request_from_user_who_wants_to_receive_your_donation') : ''}</h5>
                                                                                                                    <h5 className='text-center fw-none pt-4 pb-2'>{(acceptRequest == 2 || acceptRequest == 4) ? t('profile.please_select_a_reason_for_rejection_and_confirm_your_action') : ''}</h5>
                                                                                                                    <div class="pt-2" style={{ borderRadius: '8px' }}>
                                                                                                                        <div class=" justify-content-center d-lg-flex d-md-flex">
                                                                                                                            <div class="">
                                                                                                                                {acceptRequest == 1 || acceptRequest == 3 ?
                                                                                                                                    <img src={item?.image[0]} class="card-img" alt="..." style={{ height: '200px' }} />
                                                                                                                                    : <div>
                                                                                                                                        <select
                                                                                                                                            name='reason_to_reject_article'
                                                                                                                                            onChange={handleChange}
                                                                                                                                            style={{ borderColor: '#ff4838' }}>
                                                                                                                                            <option value={'0'} disabled selected>-{t('profile.select_reasone_to_reject')}-</option>
                                                                                                                                            <option value={'1'} >{t('profile.I_changed_my_mind')}</option>
                                                                                                                                            <option value={'2'} >{t('profile.required_quantity_unavailable')}</option>
                                                                                                                                        </select>
                                                                                                                                        <span style={{ color: "red" }}>{error.reason_to_reject_article}</span>
                                                                                                                                    </div>
                                                                                                                                }
                                                                                                                            </div>
                                                                                                                            {acceptRequest == 1 &&
                                                                                                                                <div class="">
                                                                                                                                    <div class="card-body  package-sidebar">
                                                                                                                                        <h4>{LuggageRequest?.title}</h4>
                                                                                                                                        <h4 style={{ color: 'red' }}> {LuggageRequest?.currency} {LuggageRequest?.article_price}</h4>
                                                                                                                                        {/* <hr class="mt-0 mb-4" /> */}
                                                                                                                                        <div class="  pt-2 ">
                                                                                                                                            <div class=" mb-3">
                                                                                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                                                                                <p class="text-muted">{LuggageRequest?.article_weight} kg</p>
                                                                                                                                            </div>
                                                                                                                                            <div class=" mb-3">
                                                                                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.quantity_requested')}</h6>
                                                                                                                                                <p class="text-muted">{LuggageRequest?.article_quantity}</p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>}
                                                                                                                            {acceptRequest == 3 &&
                                                                                                                                <div class="">
                                                                                                                                    <div class="card-body  package-sidebar pt-0 pb-0">
                                                                                                                                        <h4 style={{ color: 'red' }}>{LuggageRequest?.title}</h4>
                                                                                                                                        {/* <hr class="mt-0 mb-4" /> */}
                                                                                                                                        <div class="  pt-2 ">
                                                                                                                                            <div class=" mb-3">
                                                                                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                                                                                <p class="text-muted">{LuggageRequest?.weight} kg</p>
                                                                                                                                            </div>
                                                                                                                                            <div class=" mb-3">
                                                                                                                                                <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.quantity_requested')}</h6>
                                                                                                                                                <p class="text-muted">{LuggageRequest?.quantity}</p>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        <div class="row pt-2">
                                                                                                                                            <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
                                                                                                                                                <h6>{' '}{t('profile.donated_to')} :</h6>
                                                                                                                                                <p class="" style={{ color: '#1E98D6' }}>{LuggageRequest?.requester_name}</p>
                                                                                                                                            </div>
                                                                                                                                            <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
                                                                                                                                                <h6><i class="bi bi-geo-alt-fill" style={{ color: 'red' }}></i>{' '}{LuggageRequest?.requester_location}</h6>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                </div>}
                                                                                                                        </div>
                                                                                                                        <h5 className='text-center'>{acceptRequest == 1 ? t('profile.request_you_will_allow_buyer_to_pay_you_the_requested_money') : acceptRequest == 3 ? t('profile.request_you_will_confirm_requester_to_give_away_this_item_as_a_donation') : ''}</h5>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>)}
                                                                                        </Form>
                                                                                    </Modal.Body>
                                                                                    <Modal.Footer className='justify-content-center' >
                                                                                        <Button onClick={() => acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? handleClose() : requestReceived()} style={{ backgroundColor: acceptRequest == 1 || acceptRequest == 3 || acceptRequest == 5 || acceptRequest == 8 ? '#63a103' : '#ff4838', borderColor: acceptRequest == 1 || acceptRequest == 3 || acceptRequest == 5 || acceptRequest == 8 ? '#63a103' : '#ff4838' }}>
                                                                                            {acceptRequest == 1 || acceptRequest == 3 ? t('profile.i_accept_confirm') : acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? t('profile.Close') : t('profile.reject_request')}
                                                                                        </Button>
                                                                                    </Modal.Footer>
                                                                                </Modal>
                                                                            </div>}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Donations */}
                                    <div
                                        className="tab-pane fade package-info-tab"
                                        id="pill-body4"
                                        role="tabpanel"
                                        aria-labelledby="pills-package4"
                                        style={{ overflowX: 'auto' }}
                                    >
                                        <div class="container pt-2 h-100 package-card-delta p-3" style={{ boxShadow: 'none' }}>
                                            <h4 className='fs-5 pt-3'>{received == 1 ? t('profile.requests_I_received_for_my_donation_items') : t('profile.requests_I_sent_for_receiving_donation_items')}</h4>
                                            <hr />
                                            <div class="container p-0 h-100">
                                                <div class="row d-flex justify-content-center align-items-center h-100">
                                                    <div class=" col mb-4 mb-lg-0">
                                                        {(received == 1 ? iReceived[3] : iSent[3])?.map(item => item.isComplete == false &&
                                                            <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
                                                                <div class="row g-0">
                                                                    <div class="col-md-4">
                                                                        <img src={item?.image[0]} class="card-img" alt="..." style={{ width: '100%', height: '100%', aspectRatio: "3/2", objectFit: "contain" }} />
                                                                    </div>
                                                                    <div class="col-md-8">
                                                                        <div class="card-body  package-sidebar" >
                                                                            <h3 style={{ backgroundColor: '' }}>{item?.title}</h3>
                                                                            <hr class="mt-0 mb-4" />
                                                                            <div class="row ">
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                        <p class="text-muted">{item?.weight} kg</p>
                                                                                    </div> :
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}:{item?.weight} kg</h6>
                                                                                        <p class="" style={{ fontWeight: '500px' }}><i class="bi bi-cart-fill"></i>{' '}<span className='fw-6'>{t('profile.quantity_requested')}:</span> {item?.quantity}</p>
                                                                                    </div>}
                                                                                {received == 1 ? <div class="col-6 mb-3">
                                                                                    <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.quantity_requested')}</h6>
                                                                                    <p class="text-muted">{item?.quantity}</p>
                                                                                </div> :
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6>{' '}{t('profile.seller_location')}:{' '}</h6>
                                                                                        <p class="text-muted"><i class="bi bi-geo-alt-fill"></i>{item?.requestee_location}</p>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            <div class="row pt-1">
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{t('profile.request_received_from')}</h6>
                                                                                        <p class="fw-bold"><i class="bi bi-person-fill" /> {' '}<span style={{ color: '#1e98d7' }}>{item?.requester_name}</span></p>
                                                                                    </div> :
                                                                                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 mb-3">
                                                                                        <h6><i class="bi bi-arrow-down-left-circle-fill" style={{ color: '#ff4838' }}></i>{' '}{received == 1 ? t('profile.request_received_from') : (t('profile.requested_sent_to') + ':')}</h6>
                                                                                        <h6 class='fw-bold' ><i class="bi bi-person-fill" /><span style={{ color: '#1e98d7' }}>{' '}{received == 1 ? '' : item?.requestee_name}</span></h6>
                                                                                        {/* <p class={received == 1 ? " " : "fw-bold pt-0"} style={{ color: received == 1 ? '#1e98d7' : '' }}>{' '}<span>{received == 1 && "Elodie Dumas" }</span></p> */}
                                                                                    </div>}
                                                                                {received == 1 ?
                                                                                    <div class="col-6 mb-3">
                                                                                        <h6><i class="bi bi-geo-alt-fill"></i>{' '}{t('profile.requester_location')}</h6>
                                                                                        <p class="text-muted">{' '}{item?.requester_location}</p>
                                                                                    </div> :
                                                                                    <div class="col-12 col-lg-6 col-md-6 col-xl-6 mb-3">
                                                                                        <div class="earning pt-0" style={{ backgroundColor: 'white', borderColor: '#ff4838', border: '2px solid' }}>
                                                                                            <div class="plan" style={{ background: 'white' }}>
                                                                                                <header style={{ padding: '5px 10px' }}><i class="bi bi-currency-dollar1"></i>
                                                                                                    <h6 class="plan-title" style={{ color: 'black' }}>{t('profile.status')}:</h6>
                                                                                                    <div class="plan-cost"><span class="plan-price fs-6 d-flex align-items-center" style={{ color: item.status == 1 ? ' #63a103' : item.status == 2 ? 'red' : '#f39c12' }}> {item.status !== 0 && <i class={item.status == 1 ? "bi bi-check-circle" : "bi bi-x-circle "} style={{ color: item.status == 1 ? ' #63a103' : 'red', fontSize: '30px' }} />}{item.status == 1 ? t("profile.accepted") : item.status == 2 ? t('profile.rejected') : t('profile.Pending_acceptance_seller')}</span></div>
                                                                                                </header>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                }
                                                                            </div>
                                                                            {(received == 1 && item.status == 0) && <hr class="mt-0 mb-4" />}
                                                                            {(received == 1 && item.status == 0) && <div class=" d-flex justify-content-end">
                                                                                <a className='me-3 fs-5' style={{ color: '#63a103', fontWeight: '600', cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        // handleShow();
                                                                                        handleOpen()
                                                                                        setAcceptRequest(3);
                                                                                        articleReceived(item?.id, item?.product_type);
                                                                                        handleSetData('request_id', item?.id);
                                                                                        handleSetData('request_type', item?.product_type);
                                                                                        handleSetData('status', 1);
                                                                                        setDonationdata(item)
                                                                                    }}>
                                                                                    <i class="bi bi-check-circle " style={{ color: ' #63a103' }} />
                                                                                    {' '}{t('profile.accept')}
                                                                                </a>
                                                                                <a class='me-3 fs-5' style={{ color: '#ff4838', fontWeight: '600', cursor: 'pointer' }}
                                                                                    onClick={() => {
                                                                                        // handleShow();
                                                                                        handleOpen();
                                                                                        setAcceptRequest(4);
                                                                                        setDonationdata(item)
                                                                                        handleSetData('request_id', item?.id);
                                                                                        handleSetData('request_type', item?.product_type);
                                                                                        handleSetData('status', 2);
                                                                                    }}><i class="bi bi-x-circle "></i>{' '}{t('profile.reject')}</a>

                                                                            </div>}
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>

                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal show={open} onHide={handleOpenClose}
                        size="lg"
                        // aria-labelledby="contained-modal-title-vcenter"
                        centered
                    >
                        <Modal.Header closeButton>
                            <Modal.Title>{(acceptRequest == 1 || acceptRequest == 3) ? t('profile.accept_confimation') : acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? t('profile.success') : t('profile.reject_confirmation')}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body >
                            <Form>
                                {acceptRequest == 5 ?
                                    (<div>
                                        <h4 className='text-center'>{t('profile.congratulations')}</h4>
                                        <h5 className='p-2 fs-6 text-center'>{t('form.buyer_has_been_notified')}</h5>
                                        <div className='justify-content-center p-3'>
                                            <h5 className='text-center pt-2 pb-2'>{t('form.next_step')}</h5>
                                            <h6 className='text-center'>1. {t('form.buyer_will_pay')}</h6>
                                            <h6 className='pt-2 text-center'>2.  {t('form.once_the_buyer_pays')}</h6>
                                            <br />
                                            <h6 className='text-center'>" {t('form.happy_travelling_by_earning')}"</h6>
                                        </div>
                                    </div>) :
                                    acceptRequest == 8 ?
                                        (<div>
                                            <h4 className='text-center'>{t('profile.congratulations')}</h4>
                                            <h5 className='p-2 text-center fs-6'>{t('form.user_has_been_notified')}</h5>
                                            <div className='justify-content-center p-3'>
                                                <h5 className='text-center pt-2 pb-2'>{t('form.next_step')}:</h5>
                                                <h6 className='text-center'>{t('form.please_make_agreement')}</h6>
                                                <br />
                                                <h6 className='text-center'>" {t('form.happy_travelling_by_earning')}  "</h6>
                                            </div>
                                        </div>) :
                                        acceptRequest == 6 || acceptRequest == 7 ?
                                            (<div>
                                                <h5 className='pb-5 text-center'>
                                                    {/* {t('form.the')}  */}
                                                    {/* {acceptRequest == 6 ? 'buyer' : 'user'}  */}
                                                    {t('form.has_been_notified')} </h5>
                                                {/* <h5 className='pb-1 text-center'>{t('form.happy_travelling_by_earning')} </h5> */}
                                            </div>) :
                                            (<div class="container ">
                                                <div class="row d-flex justify-content-center align-items-center">
                                                    <div class="col  mb-lg-0">
                                                        <h5 className='text-center fw-none'>{acceptRequest == 1 ? t("profile.your_are_about_to_accept_the_request_from_buyer_to_sell_your_Item") :
                                                            acceptRequest == 2 ? t('profile.your_are_about_to_reject_the_request_from_buyer_who_wants_to_buy_your_item') :
                                                                acceptRequest == 3 ? t('profile.accept_the_request_from_user_to_donate') :
                                                                    acceptRequest == 4 ? t('profile.reject_the_request_from_user_who_wants_to_receive_your_donation') : ''}</h5>
                                                        <h5 className='text-center fw-none pt-4 pb-2'>{(acceptRequest == 2 || acceptRequest == 4) ? t('profile.please_select_a_reason_for_rejection_and_confirm_your_action') : ''}</h5>
                                                        <div class="pt-2" style={{ borderRadius: '8px' }}>
                                                            <div class=" justify-content-center d-lg-flex d-md-flex">
                                                                <div class="">
                                                                    {acceptRequest == 3 ?
                                                                        <img src={LuggageRequest?.image[0]} class="card-img" alt="..." style={{ height: '200px' }} />
                                                                        : <div>
                                                                            <select
                                                                                name='reason_to_reject_article'
                                                                                onChange={handleChange}
                                                                                style={{ borderColor: '#ff4838' }}>
                                                                                <option value={'0'} disabled selected>-{t('profile.select_reasone_to_reject')}-</option>
                                                                                <option value={'1'} >{t('profile.I_changed_my_mind')}</option>
                                                                                <option value={'2'} >{t('profile.required_quantity_unavailable')}</option>
                                                                            </select>
                                                                            <span style={{ color: "red" }}>{error.reason_to_reject_article}</span>
                                                                        </div>
                                                                    }
                                                                </div>

                                                                {acceptRequest == 3 &&
                                                                    <div class="">
                                                                        <div class="card-body  package-sidebar pt-0 pb-0">
                                                                            <h4 style={{ color: 'red' }}>{LuggageRequest?.title}</h4>
                                                                            {/* <hr class="mt-0 mb-4" /> */}
                                                                            <div class="  pt-2 ">
                                                                                <div class=" mb-3">
                                                                                    <h6><i class="bi bi-cart-fill"></i>{' '}{t("profile.weight")}</h6>
                                                                                    <p class="text-muted">{LuggageRequest?.weight} kg</p>
                                                                                </div>
                                                                                <div class=" mb-3">
                                                                                    <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.quantity_requested')}</h6>
                                                                                    <p class="text-muted">{LuggageRequest?.quantity}</p>
                                                                                </div>
                                                                            </div>
                                                                            <div class="row pt-2">
                                                                                <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
                                                                                    <h6>{' '}{t('profile.donated_to')} :</h6>
                                                                                    <p class="" style={{ color: '#1E98D6' }}>{LuggageRequest?.requester_name}</p>
                                                                                </div>
                                                                                <div class="col-12 col-lg-6 col-md-6 col-xl-6 col-xxl-6 mb-3">
                                                                                    <h6><i class="bi bi-geo-alt-fill" style={{ color: 'red' }}></i>{' '}{LuggageRequest?.requester_location}</h6>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>}
                                                            </div>
                                                            <h5 className='text-center'>{acceptRequest == 1 ? t('profile.request_you_will_allow_buyer_to_pay_you_the_requested_money') : acceptRequest == 3 ? t('profile.request_you_will_confirm_requester_to_give_away_this_item_as_a_donation') : ''}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>)}
                            </Form>
                        </Modal.Body>
                        <Modal.Footer className='justify-content-center' >
                            <Button
                                onClick={() => acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? handleOpenClose() : requestReceived()}
                                style={{ backgroundColor: acceptRequest == 1 || acceptRequest == 3 || acceptRequest == 5 || acceptRequest == 8 ? '#63a103' : '#ff4838', borderColor: acceptRequest == 1 || acceptRequest == 3 || acceptRequest == 5 || acceptRequest == 8 ? '#63a103' : '#ff4838' }}>
                                {acceptRequest == 1 || acceptRequest == 3 ? t('profile.i_accept_confirm') : acceptRequest == 5 || acceptRequest == 6 || acceptRequest == 7 || acceptRequest == 8 ? t('profile.Close') : t('profile.reject_request')}
                            </Button>
                        </Modal.Footer>
                    </Modal>
                </div>)}
        </>
    )
}
