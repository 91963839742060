import axios from '../plugin/axios';

const notification = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Notification/GetByUser')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Notification/Delete',id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default notification;