const common = {
    action: 'Action',
    delete: 'Supprimer',
    duplicate: 'Dupliquer',
    cancel: 'Annuler',
    save: 'Sauver',
    update: 'Mise à jour',
    edit:'Modifier',
    close:'Fermer',

    disagree: 'Être en désaccord',
    agree: 'Se mettre d\'accord',
    delete_data: 'Suprimmer les données?',
    delete_data_message: 'Êtes-vous sûr de vouloir supprimer ces données ?',
    
};
export default common;