import { useMediaQuery } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import subscribeAds from "../../../models/subscribeAds";
import stripeSubscribe from "../../../models/stripeSubscribe";
import payPal from "../../../models/payPal";
import Loading from "../../common/Loading";
import AppContext from "../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Modal, Button } from "react-bootstrap";

function Tourguide3() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const { t } = useTranslation();
  const mediumViewport = useMediaQuery("(max-width:1200px)");
  const [load, setLoad] = useState(false);
  const [details, setDetails] = useState([]);
  const [show, setShow] = useState(false);
  const [paymentMode, setPaymentMode] = useState();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );
  const [buyId, setBuyId] = useState(null);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    subscribeAds
      .list(userId)
      .then((item) => {
        setDetails(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const PaymentProsees = () => {
    if (paymentMode == 1) {
      stripData();
    }
    else if (paymentMode == 2) {
      PayPalData();
    }
  }


  const stripData = () => {
    if (contextObj.login == true) {
      if (buyId) {
        setLoad(true);
        stripeSubscribe
          .create(buyId)
          .then((item) => {
            // contextObj.setSessionId(item.id)
            window.localStorage.setItem("sessionId", item.id);
            window.location = item.url;
            // setLoad(false);
            setBuyId(null);
            handleClose();
          })
          .catch((error) => {
            setLoad(false);
            console.log("error", error);
          });
      }
    } else {
      history.push("/login");
    }
  };

  const PayPalData = () => {
    if (contextObj.login == true) {
      setLoad(true);
      stripeSubscribe.mobileMoney(buyId).then((item) => {
        window.localStorage.setItem('paymentId', item.transaction_id)
        window.localStorage.setItem('subscription_id', buyId)
        window.location = item.data.payment_url;
      }).catch((error) => {
        setLoad(false);
        console.log('Here is error shoppingCardPaypal page', error);
      })
    } else {
      history.push("/login");
    }
  };

  const handleShow = (id) => {
    setBuyId(id);
    setShow(true);
  };

  const handleClose = () => setShow(false);

  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <div
          class="package-area package-style-two chain"
          // style={{ marginTop: mediumViewport ? "130px" : null }}
          style={{ background: "#fee5e5" }}
        >
          <div className="container">
            <div className="tour-package-details">
              <div className="package-details-tabs   pt-4">
                <ul
                  className="nav nav-tabs gap-xxl-4 gap-3 pb-4"
                  id="myTab"
                  role="tablist"
                  style={{ marginBottom: "0px", background: "#fee5e5" }}
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-package4"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body4"
                      type="button"
                      role="tab"
                      aria-controls="pill-body4"
                      aria-selected="true"
                      style={{
                        borderRadius: "5px",
                        padding: "8px 25px",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("homePage.Cobagage_Subscription")}
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-package5"
                      data-bs-toggle="pill"
                      data-bs-target="#pill-body5"
                      type="button"
                      role="tab"
                      aria-controls="pill-body5"
                      aria-selected="false"
                      style={{
                        borderRadius: "5px",
                        padding: "8px 25px",
                        fontSize: "18px",
                        fontWeight: "bold",
                      }}
                    >
                      {t("homePage.Our_Pricing_Members")}
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active package-info-tab"
                    id="pill-body4"
                    role="tabpanel"
                    aria-labelledby="pills-package4"
                  >
                    <div className="container p-0">
                      <h4> {t("homePage.Become_service_fees")}</h4>
                    </div>
                    <div className="tour-package-details">
                      <div className="package-details-tabs pt-4">
                        <ul
                          className="nav nav-tabs  gap-3  gap-xxl-4 "
                          id="myTab"
                          role="tablist"
                          style={{ marginBottom: "0px", background: "#fee5e5" }}
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-package7"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body7"
                              type="button"
                              role="tab"
                              aria-controls="pill-body7"
                              aria-selected="true"
                              style={{
                                fontSize: "15px",
                                borderRadius: "0px",
                                fontWeight: "600",
                              }}
                            >
                              {t("homePage.SILVER")}
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-package8"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body8"
                              type="button"
                              role="tab"
                              aria-controls="pill-body8"
                              aria-selected="false"
                              style={{
                                fontSize: "15px",
                                borderRadius: "0px",
                                fontWeight: "600",
                              }}
                            >
                              {t("homePage.GOLD")}
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-package9"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body9"
                              type="button"
                              role="tab"
                              aria-controls="pill-body9"
                              aria-selected="false"
                              style={{
                                fontSize: "15px",
                                borderRadius: "0px",
                                fontWeight: "600",
                              }}
                            >
                              <i class="bi bi-gem" /> {t("homePage.DIAMOND")}
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active package-info-tab "
                            id="pill-body7"
                            role="tabpanel"
                            aria-labelledby="pills-package7"
                          >
                            <div class="container justify-content-center p-0 pt-3 row">
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card  shadow rounded-xs"
                                  style={{
                                    borderColor: "#0095b6",
                                    background: "#0095b6",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.BASIC")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#f39c12",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[0]?.price}
                                        {details[0]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[0]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#0095b6' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold"
                                            style={{ fontSize: mediumViewport ? "10px" : "14px" }}
                                          >{t("homePage.service_fees_luggages")}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#0095b6' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_earnings_customer")}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#0095b6",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[0]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[0]?.subscribe_id)}
                                      // onClick={() => stripData(details[0]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[0]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#4997d0",
                                    background: "#4997d0",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.INTERMEDIATE")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#f39c12",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[1]?.price}
                                        {details[1]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[1]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#4997d0' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_fees_luggages")}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#" >
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#4997d0' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_earnings_customer")}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#4997d0",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[1]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[1]?.subscribe_id)}
                                      // onClick={() => stripData(details[1]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[1]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card  shadow rounded-xs"
                                  style={{
                                    borderColor: "#2a52be",
                                    background: "#2a52be",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.EXPERT")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#f39c12",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[2]?.price}
                                        {details[2]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[2]?.kg_limit}kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#2a52be' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_fees_luggages")}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#2a52be' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_earnings_customer")}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#2a52be",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[2]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[2]?.subscribe_id)}
                                      // onClick={() => stripData(details[2]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[2]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade package-info-tab"
                            id="pill-body8"
                            role="tabpanel"
                            aria-labelledby="pills-package8"
                            style={{ overflowX: "auto" }}
                          >
                            <div class="container justify-content-center p-0 pt-3 row ">
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#ffbf00",
                                    background: "#ffbf00",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "black",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                          color: "black",
                                        }}
                                      >
                                        {t("homePage.BASIC")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[3]?.price}
                                        {details[3]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[3]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#ffbf00' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_earnings_customer")}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#ffbf00' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#ffbf00",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                                color: "black",
                                              }}
                                            >
                                              {details[3]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[3]?.subscribe_id)}
                                      // onClick={() => stripData(details[3]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[3]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card  shadow rounded-xs"
                                  style={{
                                    borderColor: "#EDDD59",
                                    backgroundColor: "#EDDD59",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "black",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.INTERMEDIATE")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[4]?.price}
                                        {details[4]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[4]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#EDDD59' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t("homePage.service_earnings_customer")}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#EDDD59' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#EDDD59",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                                color: "black",
                                              }}
                                            >
                                              {details[4]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[4]?.subscribe_id)}
                                      // onClick={() => stripData(details[4]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[4]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#ffff91",
                                    backgroundColor: "#ffff91",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "black",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.EXPERT")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[5]?.price}
                                        {details[5]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[5]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#ffff91' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#ffff91' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.free_service_fees_applied')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#ffff91",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                                color: "black",
                                              }}
                                            >
                                              {details[5]?.kg_limit}{" "}
                                              {t(
                                                "homePage.articles_included_applied"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[5]?.subscribe_id)}
                                      // onClick={() => stripData(details[5]?.subscribe_id)}
                                      onClick={() => handleShow(true)}
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* DIAMOND */}
                          <div
                            className="tab-pane fade package-info-tab"
                            id="pill-body9"
                            role="tabpanel"
                            aria-labelledby="pills-package9"
                            style={{ overflowX: "auto" }}
                          >
                            <div class="container justify-content-center p-0 pt-3 row ">
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#4b0082",
                                    backgroundColor: "#4b0082",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.BASIC")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[6]?.price}
                                        {details[6]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[6]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#4b0082' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#4b0082' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.free_service_fees_applied')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#4b0082",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[6]?.kg_limit}{" "}
                                              {t("homePage.articles_included_applied")}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[6]?.subscribe_id)}
                                      // onClick={() => stripData(details[6]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[6]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#c8a2c8",
                                    backgroundColor: "#c8a2c8",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.INTERMEDIATE")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[7]?.price}{" "}
                                        {details[7]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[7]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#c8a2c8' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#c8a2c8' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.free_service_fees_applied')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#c8a2c8",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[7]?.kg_limit}{" "}
                                              {t("homePage.articles_included_applied")}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[8]?.subscribe_id)}
                                      // onClick={() => stripData(details[7]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[7]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#da70d6",
                                    backgroundColor: "#da70d6",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.EXPERT")}
                                      </p>
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#ff4838",
                                          fontSize: "22px",
                                        }}
                                      >
                                        {details[8]?.price}
                                        {details[8]?.currency}
                                        <span class="text-muted ps-2">
                                          {" "}
                                          {t("homePage.For")}{" "}
                                          <span style={{ color: "#ff4838" }}>
                                            {details[8]?.kg_limit} kg
                                          </span>
                                        </span>
                                      </p>
                                    </div>
                                    {/* <div class="mb-3 pt-2"> <a href="#">
                                      <div class="btn btn-primary" style={{ backgroundColor: '#da70d6' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.service_earnings_customer')}</p>
                                        </div>
                                      </div>
                                    </a> </div>
                                    <div class="mb-3"> <a href="#">
                                      <div class="btn btn-primary" style={{ height: '98px', backgroundColor: '#da70d6' }}>
                                        <div class="">
                                          <p className="pt-0 fw-bold" style={{ fontSize: mediumViewport ? "10px" : "14px" }}>{t('homePage.free_service_fees_applied')}</p>
                                        </div>
                                      </div>
                                    </a> </div> */}
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "98px",
                                            backgroundColor: "#da70d6",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {details[8]?.kg_limit}{" "}
                                              {t("homePage.articles_included_applied")}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div
                                      class="order btn btn-primary my-3"
                                      // onClick={() => stripData(details[8]?.subscribe_id)}
                                      onClick={() =>
                                        handleShow(details[8]?.subscribe_id)
                                      }
                                    >
                                      {t("homePage.Proceed_Buy")}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade package-info-tab"
                    id="pill-body5"
                    role="tabpanel"
                    aria-labelledby="pills-package5"
                    style={{ overflowX: "auto" }}
                  >
                    <div className="container p-0">
                      <h4>{t("homePage.Applicable_members")}</h4>
                    </div>
                    <div className="tour-package-details">
                      <div className="package-details-tabs pt-4">
                        <ul
                          className="nav nav-tabs gap-xxl-4 gap-3  pb-4"
                          id="pills-tab"
                          role="tablist"
                          style={{ marginBottom: "0px", background: "#fee5e5" }}
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-package10"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body10"
                              type="button"
                              role="tab"
                              aria-controls="pill-body10"
                              aria-selected="true"
                              style={{ fontSize: "15px", fontWeight: "600" }}
                            >
                              {t("homePage.luggage_senders")}
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-package11"
                              data-bs-toggle="pill"
                              data-bs-target="#pill-body11"
                              type="button"
                              role="tab"
                              aria-controls="pill-body11"
                              aria-selected="false"
                              style={{ fontSize: "15px", fontWeight: "600" }}
                            >
                              {t("homePage.For_Travellers")}
                            </button>
                          </li>
                        </ul>
                        <div className="tab-content" id="pills-tabContent">
                          <div
                            className="tab-pane fade show active package-info-tab "
                            id="pill-body10"
                            role="tabpanel"
                            aria-labelledby="pills-package10"
                          >
                            <div class="container justify-content-center p-0 pt-3 row ">
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#da70d6",
                                    backgroundColor: "#F88017",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.SENDING_LUGGAGES")}
                                      </p>
                                    </div>
                                    <div class="mb-3 pt-2">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "150px",
                                            backgroundColor: "#F88017",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.fees_will_added_service"
                                              )}
                                              10{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              * 10kg{")"} + 25
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.fees_will_added_service2"
                                              )}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                              {" ]"}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "170px",
                                            backgroundColor: "#F88017",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.case_cancellation_non_refundabl"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.case_cancellation_non_refundabl2"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {"."}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    {/* <div class="order btn btn-primary my-3"> Proceed to Buy </div> */}
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card  shadow rounded-xs"
                                  style={{
                                    borderColor: "#da70d6",
                                    backgroundColor: "#f37a48",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.SELLING_ARTICLES")}
                                      </p>
                                    </div>
                                    <div class="mb-3 pt-2">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "150px",
                                            backgroundColor: "#f37a48",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.sending_sold_articles_to_buyers"
                                              )}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {")"}+ 15
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.sending_sold_articles_to_buyers2"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                              {" ]."}{" "}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "170px",
                                            backgroundColor: "#f37a48",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.cancellation_either_parties"
                                              )}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.cancellation_either_parties2"
                                              )}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                              {"."}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    {/* <div class="order btn btn-primary my-3"> Proceed to Buy </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className="tab-pane fade package-info-tab"
                            id="pill-body11"
                            role="tabpanel"
                            aria-labelledby="pills-package11"
                            style={{ overflowX: "auto" }}
                          >
                            <div class="container justify-content-center p-0 pt-3 row ">
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card shadow rounded-xs"
                                  style={{
                                    borderColor: "#da70d6",
                                    backgroundColor: "#F88017",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.FOR_CARRYING_LUGGAGES")}
                                      </p>
                                    </div>
                                    <div class="mb-3 pt-2">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "200px",
                                            backgroundColor: "#F88017",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.Total_earned_trip_amount"
                                              )}{" "}
                                              10{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              * 10kg {")"} - 25{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                              {t(
                                                "homePage.Total_earned_trip_amount2"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.Total_earned_trip_amount3"
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "150px",
                                            backgroundColor: "#F88017",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.fees_will_be_refundable"
                                              )}{" "}
                                              +75{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              - 75{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              = 0{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    {/* <div class="order btn btn-primary my-3"> Proceed to Buy </div> */}
                                  </div>
                                </div>
                              </div>
                              <div class="silverCard col-lg-4 pb-3 col-12 col-md-4">
                                <div
                                  class="card  shadow rounded-xs"
                                  style={{
                                    borderColor: "#da70d6",
                                    backgroundColor: "#f37a48",
                                  }}
                                >
                                  <div class="card-body">
                                    <div class="d-flex flex-column align-items-center justify-content-center">
                                      <p
                                        class="t-white"
                                        style={{
                                          color: "#fff",
                                          fontSize: mediumViewport
                                            ? "24px"
                                            : "32px",
                                        }}
                                      >
                                        {t("homePage.FOR_SELLING_ARTICLES")}
                                      </p>
                                    </div>
                                    <div class="mb-3 pt-2">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "200px",
                                            backgroundColor: "#f37a48",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.delivering_sold_articles_recepient"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {")"} - 15{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              {t(
                                                "homePage.delivering_sold_articles_recepient2"
                                              )}{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                              {" ]."}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    <div class="mb-3">
                                      {" "}
                                      <a>
                                        <div
                                          class="btn btn-primary"
                                          style={{
                                            height: "150px",
                                            backgroundColor: "#f37a48",
                                          }}
                                        >
                                          <div class="">
                                            <p
                                              className="pt-0 fw-bold"
                                              style={{
                                                fontSize: mediumViewport
                                                  ? "10px"
                                                  : "14px",
                                              }}
                                            >
                                              {t(
                                                "homePage.In_cancellation_either"
                                              )}{" "}
                                              +85{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              - 85{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}{" "}
                                              = 0{" "}
                                              {contextObj.loginUser?.symbol
                                                ? contextObj.loginUser?.symbol
                                                : "€"}
                                            </p>
                                          </div>
                                        </div>
                                      </a>{" "}
                                    </div>
                                    {/* <div class="order btn btn-primary my-3"> Proceed to Buy </div> */}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {show && (
              <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>{t("profile.payment_method")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex flex-column justify-content-center align-items-center">
                    <div className="col-6 d-flex justify-content-center align-items-center">
                      {/* <img
                        src="/images/vehicles/stripe.png"
                        height="76%"
                        width="35%"
                        onClick={() => stripData(show)}
                        className="paymentPop"
                        style={{ cursor: "pointer" }}
                      // style={{ border: "1px solid black", borderRadius: "10px", padding: "7px" }}
                      ></img> */}
                      <div className="row" style={{ justifyContent: "center" }}>
                        <div className="col-2">
                          <img
                            src={"/images/vehicles/visa.png"}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="col-2">
                          <img
                            src={"/images/vehicles/mscard.jpg"}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="col-2">
                          <img
                            src={"/images/vehicles/maestro.png"}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="col-2">
                          <img
                            src={"/images/vehicles/cb.jpg"}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                        <div className="col-2">
                          <img
                            src={"/images/vehicles/card.png"}
                            alt=""
                            width="100%"
                            height="100%"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <select
                          id="traveller_type"
                          onChange={(e) => {
                            setPaymentMode(e.target.value);
                          }}
                          name="traveller_type"
                        >
                          <option value="" selected disabled>
                            {t("profile.Select_payment_method")}
                          </option>
                          <option value={1}>{t("form.Stripe")}</option>
                          <option value={2}>{t("form.Mobile_Moeny")}</option>
                        </select>
                        <i class="bi bi-caret-down-fill"></i>
                      </div>
                    </div>
                  </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  {/* <Button onClick={handleClose} > */}
                  <Button onClick={() => PaymentProsees()} disabled={!paymentMode}>
                    {t("profile.proceed")}
                  </Button>
                </Modal.Footer>
              </Modal>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Tourguide3;
