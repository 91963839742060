import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AppContext from "../../../../../store/context/app-context";
import { useTranslation } from "react-i18next";
import travel from "../../../../../models/travel";
import rating from "../../../../../models/rating";
import notistack from "../../../../../plugin/notistack";
import Loading from "../../../../common/Loading";
import { Rating } from 'react-simple-star-rating';
import luggage from "../../../../../models/luggage";
import articles from "../../../../../models/articles";
import articlesDonation from "../../../../../models/articlesDonation";
import { Swiper, SwiperSlide } from "swiper/react";
import { Button, Modal, Form } from 'react-bootstrap';
import moment from "moment";
const restData = {
    rate: "",
    message: "",
}

function TravelAnnouncementDetails() {
    const contextObj = useContext(AppContext);
    const { id, type } = useParams();
    const [traveler, setTraveler] = useState(contextObj.travelDetails);
    const [luggagel, setluggae] = useState();
    const [travelerData, setTravelerData] = useState();
    const [luggaeData, setLuggaeData] = useState();
    const [articaleData, setArticaleData] = useState();
    const [donationData, setDonationData] = useState();
    const [load, setLoad] = useState(false);
    const [data, setData] = useState(restData);
    const { t, i18n } = useTranslation();
    const [show, setShow] = useState(false);
    const history = useHistory();
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    useEffect(() => {
        setLoad(true);
        setTimeout(() => {
            setLoad(false);
        }, 2000);
        if (type === '0') {
            getTravlerData();
        } else if (type === '1') {
            getLuggageData();
        }
        else if (type === '2') {
            getArticalData();
        }
        else {
            getArticalDonationData();
        }
        // setluggae(JSON.parse(localStorage.getItem('Data')))
    }, []);

    const getLuggageData = () => {
        luggage.get(id).then((data) => {
            delete data['images']
            setLuggaeData(data)
        }).catch((error) => {
            console.log("travlera article ", error.data.message);
        })
    }
    const vehicleTypeData = [
        { id: 1, vehicleName: t("form.bike"), imgPath: "/images/vehicles/Bike.png" },
        { id: 2, vehicleName: t("form.boat"), imgPath: "/images/vehicles/boat.png" },
        { id: 3, vehicleName: t("form.Bus"), imgPath: "/images/vehicles/bus.png" },
        { id: 4, vehicleName: t("form.car"), imgPath: "/images/vehicles/car.png" },
        { id: 5, vehicleName: t("form.kick_scooter"), imgPath: "/images/vehicles/kickscooter.png" },
        { id: 6, vehicleName: t("form.metro"), imgPath: "/images/vehicles/Tramway.png" },
        { id: 8, vehicleName: t("form.plane"), imgPath: "/images/vehicles/plan.jpg" },
        { id: 9, vehicleName: t("form.Scooter"), imgPath: "/images/vehicles/MoterBike.png" },
        { id: 10, vehicleName: t("form.Tgv"), imgPath: "/images/vehicles/Tvg.png" },
        { id: 11, vehicleName: t("form.Tramway"), imgPath: "/images/vehicles/Tramway.png" },
        { id: 12, vehicleName: 'Train', imgPath: "/images/vehicles/train.png" }
    ]
    const getTravlerData = () => {
        travel.get(id).then((data) => {
            setTravelerData(data);
        }).catch((error) => {
            console.log("travlera article ", error.data.message);
        })
    }

    const getArticalData = () => {
        articles.get(id).then((data) => {
            console.log("articaleData data is here for response: ", data);
            setArticaleData(data);
        }).catch((error) => {
            console.log("travlera article ", error.data.message);
        })
    }

    const getArticalDonationData = () => {
        articlesDonation.get(id).then((data) => {
            console.log("setDonationData: ", data)
            setDonationData(data);
        }).catch((error) => {
            console.log("travlera article ", error.data.message);
        })
    }

    const handlesubmite = (event) => {
        setLoad(true);
        console.log('type', type);
        if (type === '0') {
            travel.valid(id).then((response) => {
                setLoad(false);
                notistack.success(t('profile.add_submitted_successfully'));
                history.push(`/SubmitDetails/${id}/0`);
            })
                .catch((error) => {
                    console.log(error);
                    setLoad(false);
                })
        }
        else if (type === '1') {
            luggage.valid(id).then((response) => {
                setLoad(false);
                notistack.success(t('profile.add_submitted_successfully'));
                history.push(`/SubmitDetails/${id}/1`);
                localStorage.removeItem("Data");
            })
                .catch((error) => {
                    console.log(error);
                    setLoad(false);
                })
        }
        else if (type === '2') {
            articles.valid(id).then((response) => {
                setLoad(false);
                handleShow();
            })
                .catch((error) => {
                    console.log(error);
                    setLoad(false);
                })
        }
        else {
            articlesDonation.valid(id).then((response) => {
                setLoad(false);
                handleShow();
            }).catch((error) => {
                console.log(error);
                setLoad(false);
            })
        }
    }
    const handleClose = () => {
        setShow(false);
        history.push("/home")
    }
    const handleShow = () => setShow(true);

    const onPointerEnter = () => console.log('Enter')
    const onPointerLeave = () => console.log('Leave')
    const onPointerMove = (value, index) => console.log(value, index)

    const handleRating = (name, value) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    const testimonial3slide = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 20,
        loop: true,
        roundLengths: true,
        // autoplay: {
        //     delay: 15000,
        // },
        navigation: {
            nextEl: ".testi-next4",
            prevEl: ".testi-prev4",
        },
        breakpoints: {
            480: {
                slidesPerView: 1,
            },
            768: {
                slidesPerView: 1,
            },
            992: {
                slidesPerView: 2,
            },
            1200: {
                slidesPerView: 1,
            },
        },
    };
    return (

        <>
            {load ? (
                <Loading />
            ) : (
                <form onSubmit={(e) => e.preventDefault()}
                    action="#"
                    method="post">
                    <div className="package-details-wrapper pt-110">
                        <div className="container">
                            <div className="col-lg-12">
                                {donationData || articaleData ? " " :
                                    <div className="row mb-lg-3">
                                        <div className="col-lg-3 col-6 row">
                                            <div className="col-3"> <i class="bi bi-arrow-repeat" style={{ fontSize: '35px' }}></i></div>
                                            <div className="col-9">
                                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>{type == 0 ? t("form.Trip_Typ") : type == 1 ? t("form.Luggage_Sender_type") : ""}</span><br></br>
                                                <span>
                                                    {type == 0 ? travelerData?.trip_type == 1 ? t("form.one_way") : t("form.Round_Way") : type == 1 ? luggaeData?.sender_type == 1 ? t("form.Regular") : luggaeData?.sender_type == 2 ? t("form.Periodic") : luggaeData?.sender_type == 3 ? t("form.Oneoff") : "" : ""}
                                                    {/* {} */}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-3 col-6 row">
                                            <div className="col-3"><i class="bi bi-briefcase-fill" style={{ fontSize: "35px" }}></i></div>
                                            <div className="col-9">
                                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>{type == 0 ? t("form.Traveller_Type") : type == 1 ? t("form.Luggage_Wort") : ""}</span><br></br>
                                                <span>
                                                    {type == 0 ? travelerData?.traveller_type == 1 ? t("form.Regular") : travelerData?.traveller_type == 2 ? t("form.periodical") : travelerData?.traveller_type == 3 ? t("form.Oneoff") : ""
                                                        : type == 1 ? luggaeData?.luggage_estimation + luggaeData?.currency : ""}
                                                </span>
                                            </div>
                                        </div>
                                        <div className="col-lg-5 col-8 row">
                                            <div className="col-2"><i class="bi bi-cart3 me-2" style={{ fontSize: '35px' }}></i></div>
                                            <div className="col-10">
                                                <span style={{ fontSize: "12px", fontWeight: "bold" }}>{type == 0 ? t("form.Total_Carriage_Capacity") : type == 1 ? t("form.Luggage_Weigh") : ""}</span><br></br>
                                                {type == 0 && travelerData?.trip_type == 2 ?
                                                    <span>{travelerData?.total_carriage_capacity.toFixed(2)}{' '}Kg <i class="bi bi-chevron-compact-left"></i>{t("form.Onward")} - {travelerData?.carriage_capacity.toFixed(2)}Kg + {t("form.Return")} - {travelerData?.carriage_capacity.toFixed(2)}Kg<i class="bi bi-chevron-compact-right"></i></span>
                                                    :
                                                    <span>{type == 0 ? travelerData?.carriage_capacity : type == 1 ? luggaeData?.weight.toFixed(2) : ""}kg</span>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }

                            </div>
                            <div className="row">
                                <div className="col-lg-8">
                                    <div className="tour-package-details">
                                        <div className="pd-header">

                                            {type === '0' &&
                                                <div class="package-card-delta">
                                                    {travelerData?.verification === false ? <div class="ribbon red"><span>{t("Not verified")}</span></div> : <div class="ribbon red"><span>{t("verified")}</span></div>}
                                                    {traveler?.vehicle_type &&
                                                        <img
                                                            src={
                                                                traveler?.vehicle_type == 1 ?
                                                                    "/images/vehicles/Bike.png" : traveler?.vehicle_type == 2 ? "/images/vehicles/boat.png" : traveler?.vehicle_type == 3 ? "/images/vehicles/bus.png" : traveler?.vehicle_type == 4 ? "/images/vehicles/car.png" : traveler?.vehicle_type == 5 ? "/images/vehicles/kickscooter.png" : traveler?.vehicle_type == 6 ? "/images/vehicles/train.png" : traveler?.vehicle_type == 7 ? "/images/vehicles/MoterBike.png" : traveler?.vehicle_type == 8 ? "/images/vehicles/plan.png" : traveler?.vehicle_type == 9 ? "/images/vehicles/scooter.png" : traveler?.vehicle_type == 10 ? "/images/vehicles/Tvg.png" : traveler?.vehicle_type == 11 ? "/images/vehicles/Tramway.png":traveler?.vehicle_type == 12 ? "/images/vehicles/train.png" : ""
                                                            }
                                                            alt="PackageIMG"
                                                            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", }}
                                                        />}
                                                </div>
                                            }
                                            {type === '1' ?
                                                <div className="package-area package-style-two pb-110 pt-50 chain">
                                                    <div className="container">
                                                        <div className="row d-flex justify-content-center align-items-center">
                                                            <div className="col-lg-5 d-flex justify-content-lg-start justify-content-center">
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center justify-content-center">
                                                            <div className="col-lg-12">
                                                                <Swiper
                                                                    className="swiper testimonial-slider-three"
                                                                    {...testimonial3slide}
                                                                >
                                                                    <div className="swiper-wrapper">

                                                                        {luggaeData?.image.map((item) => {
                                                                            return (
                                                                                <SwiperSlide className="swiper-slide">
                                                                                    {/* {luggaeData?.verification === false ? <div class="ribbon red"><span>{t("Not verified")}</span></div> : <div class="ribbon red"><span>{t("verified")}</span></div>} */}
                                                                                    <TestimonialCardBeta
                                                                                        image={item}
                                                                                        name="Jims Pull"
                                                                                        review="Amazing team, effective solutions that resulted in high rated search optimization &amp; catchy branding. Running our tourist &amp; I am very satisfied and have been a customer."
                                                                                    />
                                                                                </SwiperSlide>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Swiper>
                                                            </div>
                                                        </div>
                                                        <div className="row col-lg-12">
                                                            <div className="slider-arrows text-start  col-11">
                                                                <div
                                                                    className="testi-prev4"
                                                                    tabIndex={0}
                                                                    role="button"
                                                                    aria-label="Previous slide"

                                                                >
                                                                    {" "}
                                                                    <i className="bi bi-arrow-left" />
                                                                </div>
                                                            </div>
                                                            <div className="slider-arrows text-end  col-1">

                                                                <div
                                                                    className="testi-next4 "
                                                                    tabIndex={0}
                                                                    role="button"
                                                                    aria-label="Next slide"
                                                                >
                                                                    <i className="bi bi-arrow-right" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div> :
                                                type === '2' ?
                                                    <div className="package-area package-style-two pb-110 pt-50 chain">
                                                        <div className="container">
                                                            <div className="row d-flex justify-content-center align-items-center">
                                                                <div className="col-lg-5 d-flex justify-content-lg-start justify-content-center">
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center">
                                                                <div className="col-lg-12">
                                                                    <Swiper
                                                                        className="swiper testimonial-slider-three"
                                                                        {...testimonial3slide}
                                                                    >
                                                                        <div className="swiper-wrapper">
                                                                            {articaleData?.images.map((item) => {
                                                                                return (
                                                                                    <SwiperSlide className="swiper-slide">
                                                                                        {/* {articaleData?.verification === false ? <div class="ribbon red"><span>{t("Not verified")}</span></div> : <div class="ribbon red"><span>{t("verified")}</span></div>} */}
                                                                                        <TestimonialCardBeta
                                                                                            image={item}
                                                                                            name="Jims Pull"
                                                                                            review="Amazing team, effective solutions that resulted in high rated search optimization &amp; catchy branding. Running our tourist &amp; I am very satisfied and have been a customer."
                                                                                        />
                                                                                    </SwiperSlide>
                                                                                )
                                                                            })}
                                                                        </div>
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                            <div className="row col-lg-12">
                                                                <div className="slider-arrows text-start  col-11">
                                                                    <div
                                                                        className="testi-prev4"
                                                                        tabIndex={0}
                                                                        role="button"
                                                                        aria-label="Previous slide"
                                                                    >
                                                                        {" "}
                                                                        <i className="bi bi-arrow-left" />
                                                                    </div>
                                                                </div>
                                                                <div className="slider-arrows text-end  col-1">
                                                                    <div
                                                                        className="testi-next4 "
                                                                        tabIndex={0}
                                                                        role="button"
                                                                        aria-label="Next slide"
                                                                    >
                                                                        <i className="bi bi-arrow-right" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div> :
                                                    type === "3" ?
                                                        <div className="package-area package-style-two pb-110 pt-50 chain">
                                                            <div className="container">
                                                                <div className="row d-flex justify-content-center align-items-center">
                                                                    <div className="col-lg-5 d-flex justify-content-lg-start justify-content-center">
                                                                    </div>
                                                                </div>
                                                                <div className="row align-items-center justify-content-center">
                                                                    <div className="col-lg-12">
                                                                        <Swiper
                                                                            className="swiper testimonial-slider-three"
                                                                            {...testimonial3slide}
                                                                        >
                                                                            <div className="swiper-wrapper">
                                                                                {donationData?.images.map((item) => {
                                                                                    return (
                                                                                        <SwiperSlide className="swiper-slide">
                                                                                            {/* {articaleData?.verification === false ? <div class="ribbon red"><span>{t("Not verified")}</span></div> : <div class="ribbon red"><span>{t("verified")}</span></div>} */}
                                                                                            <TestimonialCardBeta
                                                                                                image={item}
                                                                                                name="Jims Pull"
                                                                                                review="Amazing team, effective solutions that resulted in high rated search optimization &amp; catchy branding. Running our tourist &amp; I am very satisfied and have been a customer."
                                                                                            />
                                                                                        </SwiperSlide>
                                                                                    )
                                                                                })}
                                                                            </div>
                                                                        </Swiper>
                                                                    </div>
                                                                </div>
                                                                <div className="row col-lg-12">
                                                                    <div className="slider-arrows text-start  col-11">
                                                                        <div
                                                                            className="testi-prev4"
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Previous slide"
                                                                        >
                                                                            {" "}
                                                                            <i className="bi bi-arrow-left" />
                                                                        </div>
                                                                    </div>
                                                                    <div className="slider-arrows text-end  col-1">
                                                                        <div
                                                                            className="testi-next4 "
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Next slide"
                                                                        >
                                                                            <i className="bi bi-arrow-right" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        : ""
                                            }
                                            {type === '0' && <div className="header-bottom">
                                                <div className="pd-lavel d-flex  align-items-center flex-wrap gap-2">
                                                    <h5>{t("form.Drop_Off_Location")}:</h5>
                                                    <span>{travelerData?.pickup_place}</span>
                                                </div>
                                                <div className="pd-lavel d-flex  align-items-center flex-wrap gap-2">
                                                    <h5>{t("form.Delivery_Location")}:</h5>
                                                    <span>{travelerData?.delivery_place}</span>
                                                </div>
                                            </div>}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="package-sidebar">
                                        <aside className="package-widget-style-2 widget-form">
                                            {donationData || articaleData ? "" :
                                                <div className="widget-title text-center d-flex justify-content-between">
                                                    <h4>{t("form.Way_to")}</h4>
                                                    <h3 className="widget-lavel">
                                                        {type === '0' ? travelerData?.city_arrival_name : luggaeData?.city_arrival_name}
                                                    </h3>
                                                </div>
                                            }
                                            <div className="widget-body">
                                                <form
                                                    onSubmit={(e) => e.preventDefault()}
                                                    action="#"
                                                    method="post"
                                                    id="booking-form"
                                                >
                                                    <div className="booking-form-wrapper">
                                                        <div className="custom-input-group">
                                                            <div className="row icons_container">
                                                                <h4 className="icons_container icon facebook" style={{ color: "#ff4838" }}>{type === '0' ? travelerData?.title?.length > 20 ? (travelerData?.title?.slice(0, 20).trimEnd() + '...') : travelerData?.title : type === '1' ? luggaeData?.title?.length > 20 ? (luggaeData?.title?.slice(0, 20).trimEnd() + '...') : luggaeData?.title : type === '2' ? articaleData?.title?.length > 20 ? (articaleData?.title?.slice(0, 20).trimEnd() + '...') : articaleData?.title : type === '3' ? donationData?.title?.length > 20 ? (donationData?.title?.slice(0, 20).trimEnd() + '...') : donationData?.title : ""}
                                                                    <div class="tooltip">{type === '0' ? travelerData?.title : type === '1' ? luggaeData?.title : type === '2' ? articaleData?.title : type === '3' && donationData?.title}</div>
                                                                </h4>

                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            {type === '1' &&
                                                                <div className="pt-2 pb-2">
                                                                    <spna style={{ color: "rgb(255, 72, 56)" }}><h6>{t("form.Shipping_Detail")}:</h6></spna>
                                                                </div>
                                                            }
                                                            {type === '0' && travelerData?.trip_type == 1 &&
                                                                <div className="pt-2 pb-2">
                                                                    <spna style={{ color: "rgb(255, 72, 56)" }}><h6>{t("form.Journey_Details")}:</h6></spna>
                                                                </div>
                                                            }
                                                            {type === '0' && travelerData?.trip_type == 2 &&
                                                                <div className="pt-2 pb-2">
                                                                    <span style={{ color: "rgb(255, 72, 56)" }}><h6>{t("form.Onward_Journey_Details")}:</h6></span>
                                                                </div>
                                                            }
                                                            {type === '2' || type === "3" ?
                                                                <div className="pt-2 pb-2">
                                                                    <span style={{ color: "rgb(255, 72, 56)" }}><h6>{t("form.Geolocation")} : {articaleData ? articaleData?.location : donationData ? donationData?.location : ""}</h6></span>
                                                                </div> : ""
                                                            }
                                                            {donationData || articaleData ? "" :
                                                                type === '0' ?
                                                                    // <div className="row">
                                                                    //     <div className="col-4" style={{ width: 'auto' }}><h5>{travelerData?.city_arrival_name.length > 8 ? (travelerData?.city_arrival_name.slice(0, 8) + '....') : (travelerData?.city_arrival_name)}</h5></div>
                                                                    //     <div className="col-4" style={{ width: 'auto' }}><h5><i class="bi bi-arrow-right" ></i></h5></div>
                                                                    //     <div className="col-4" style={{ width: 'auto' }}><h5>{travelerData?.city_departure_name.length > 8 ? (travelerData?.city_departure_name.slice(0, 8) + '....') : (travelerData?.city_departure_name)}</h5></div>
                                                                    // </div> 
                                                                    <div className="row">
                                                                        <div className="col-5 d-flex"
                                                                            style={{ marginLeft: "-4px" }}
                                                                        >
                                                                            <div className="col-3">
                                                                                <img
                                                                                    src={travelerData?.departure_flag}
                                                                                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "60%", marginTop: "-7px" }}
                                                                                ></img>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <h6>{travelerData?.city_departure_name}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2"><h5><i class="bi bi-arrow-right" ></i></h5></div>
                                                                        <div className="col-5 d-flex">
                                                                            <div className="col-3">
                                                                                <img
                                                                                    src={travelerData?.arrival_flag}
                                                                                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "60%", marginTop: "-7px" }}
                                                                                ></img>
                                                                            </div>
                                                                            <div className="col-8"><h6>{travelerData?.city_arrival_name}</h6></div>
                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    <div className="row">
                                                                        <div className="col-5 d-flex">
                                                                            <div className="col-3">
                                                                                <img
                                                                                    src={luggaeData?.departure_flag}
                                                                                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", marginTop: "-7px" }}
                                                                                ></img>
                                                                            </div>
                                                                            <div className="col-8">
                                                                                <h6>{luggaeData?.city_departure_name}</h6>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-2"><h5><i class="bi bi-arrow-right" ></i></h5></div>
                                                                        <div className="col-5 d-flex">
                                                                            <div className="col-3">
                                                                                <img
                                                                                    src={luggaeData?.arrival_flag}
                                                                                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", marginTop: "-7px" }}
                                                                                ></img>
                                                                            </div>
                                                                            <div className="col-8"><h6>{luggaeData?.city_arrival_name}</h6></div>
                                                                        </div>
                                                                    </div>
                                                            }
                                                        </div>
                                                        <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                                            {type === '0' &&
                                                                <div className="col row">
                                                                    <div className="col"><i class="bi bi-calendar-month-fill me-2" /> {i18n.language === "enUS" ? moment(travelerData?.departure_date, "DD/MM/YYYY").format("MM/DD/YYYY") : travelerData?.departure_date}<br />{travelerData?.departure_time}{type === "o" ? <br />:""}{type === "0" && "hrs"}<br />{t("form.Departure")}</div>
                                                                </div>
                                                            }
                                                            {type === '0' &&
                                                                <div className="col row">
                                                                    <div className="col" style={{ marginLeft: "24px" }}><i class="bi bi-calendar-month-fill me-2" />{i18n.language === "enUS" ? moment(travelerData?.arrival_date, "DD/MM/YYYY").format("MM/DD/YYYY") : travelerData?.arrival_date} <br />{travelerData?.arrival_time}{type === "0" && "hrs"}<br />{t("form.Arrival")}</div>
                                                                </div>
                                                            }
                                                        </div>
                                                        {type === '0' ?
                                                            <div className="row d-flex gap-2 mt-2">
                                                                <div className="col-12 row">
                                                                    <div className="d-flex">
                                                                        <i class="bi bi-cart3 me-2" style={{ fontSize: '35px' }}></i>
                                                                        <div>{t("form.Carrying_Capacity_remaining")}<br />
                                                                            {travelerData?.remaining_capacity.toFixed(2)}kg
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 row">
                                                                    <div className="d-flex">
                                                                        <i class="bi bi-tag-fill" style={{ fontSize: '35px' }}></i>
                                                                        <span>{t("form.Unit_Price")}<br />
                                                                            {travelerData?.price_per_kg} {travelerData?.currency}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            :
                                                            type === '2' ?
                                                                <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                                                    <div className="col row">
                                                                        <div className="col d-flex">
                                                                            <i class="bi bi-cart3 me-2" style={{ fontSize: '35px' }}></i>
                                                                            <span>{t("form.Product_Weight")}<br />
                                                                                {articaleData?.weight.toFixed(2)}kg
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col row">
                                                                        <div className="col d-flex">
                                                                            <i class="bi bi-tag-fill" style={{ fontSize: '35px' }}></i>
                                                                            <span>{t("form.Product_Price_Unit")}<br />
                                                                                {articaleData?.price}{' '}{articaleData?.currency}
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div> :
                                                                type === '3' ?
                                                                    <div className="col-12">
                                                                        <span><h6>{t("form.Product_Weight")} : {donationData?.weight}</h6></span>
                                                                    </div>
                                                                    :
                                                                    ""
                                                        }

                                                        {/* {type === '0' &&
                                                            <div className="pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.vehical_Detiles")}:{travelerData?.vehicle_details}</h6></span>
                                                                </div>
                                                            </div>
                                                        } */}

                                                        <div className="row pt-2">
                                                            {type === '1' &&
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.Luggage_Location")} : {luggaeData?.location}</h6></span>
                                                                </div>
                                                            }
                                                            {type === "2" &&
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.delivery_country")} : {articaleData?.country_name}</h6></span>
                                                                </div>
                                                            }
                                                            {type === "2" &&
                                                                <div className="col-12 pt-2">
                                                                    <span><h6>{t("form.Item_Location")} : {articaleData?.location}</h6></span>
                                                                </div>
                                                            }
                                                        </div>

                                                        {type === '1' &&
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.receiver_name")} : {luggaeData?.receiver_name}</h6></span>
                                                                </div>
                                                            </div>
                                                        }

                                                        <div className="row pt-2">
                                                            {type === '2' ?
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.Available_Quantity")} : {articaleData?.quantity}</h6></span>
                                                                </div> :
                                                                type === '3' ?
                                                                    <div className="col-12">
                                                                        <span><h6>{t("form.Available_Quantity")} : {donationData?.quantity}</h6></span>
                                                                    </div> : ""
                                                            }
                                                        </div>
                                                        {type === '2' &&
                                                            <div className="col-12 row d-flex align-items-center mt-2">

                                                                <div className="col-5">
                                                                    <p>{t("form.Delivery_by")} :</p>
                                                                    {vehicleTypeData?.map((item) => item.id == articaleData?.vehicle_type && <p>{item.vehicleName}</p>)}
                                                                </div>
                                                                <div className="col-4">
                                                                    {vehicleTypeData?.map((item) => item.id == articaleData?.vehicle_type && <img src={item.imgPath} height="60px" width="70px" />)}
                                                                </div>
                                                            </div>
                                                        }
                                                        {type === '3' &&
                                                            <div className="col-12 row d-flex align-items-center mt-2">

                                                                <div className="col-5">
                                                                    <p>{t("form.Delivery_by")} :</p>
                                                                    {vehicleTypeData?.map((item) => item.id == donationData?.vehicle_type && <p>{item.vehicleName}</p>)}
                                                                </div>
                                                                <div className="col-4">
                                                                    {vehicleTypeData?.map((item) => item.id == donationData?.vehicle_type && <img src={item.imgPath} height="60px" width="70px" />)}
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* {type === '2' &&
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.Quanatity_require")}:{articaleData?.remaining_quantity}</h6></span>
                                                                </div>
                                                            </div>
                                                        } */}
                                                        {(type === '2' || type === '3') &&
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.delivery_date")} : {articaleData ? articaleData?.delivery_date : donationData?.delivery_date}</h6></span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {type === '2' &&
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.delivery_place")} : {articaleData ? articaleData?.delivery_place : donationData?.delivery_place}</h6></span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {
                                                            type === "3" &&
                                                            <div className="row pt-2">
                                                        <div className="col-12">
                                                            <span><h6>{t("form.delivery_country")} : {articaleData ? articaleData?.country_name : donationData?.country_name}</h6></span>
                                                        </div>
                                                    </div>
                                                        }
                                                        {type === '3' &&
                                                        <>
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span><h6>{t("form.delivery_place")} : {articaleData ? articaleData?.delivery_place : donationData?.delivery_place}</h6></span>
                                                                </div>
                                                            </div>
                                                            </>
                                                        }
                                                        {type === '2' || type === '3' &&
                                                            <div className="col-12 pt-2">
                                                                <span><h6>{t("form.Delivery_by")} : {articaleData ? articaleData?.user_name : donationData?.user_name}</h6></span>
                                                            </div>
                                                        }
                                                        {type === '1' &&
                                                            <div className="col-12">
                                                                <span><h6>{t("form.receiver_contact_number")}:{' '}{luggaeData?.receiver_contact_number}</h6></span>
                                                            </div>
                                                        }
                                                        {type === '0' && travelerData?.trip_type == 2 &&
                                                            <hr></hr>
                                                        }
                                                        {type === '0' && travelerData?.trip_type == 2 &&
                                                            <div className="row pt-2">
                                                                <div className="col-12">
                                                                    <span style={{ color: "rgb(255, 72, 56)" }}><h6>{t("form.Return_Journey_Details")}:</h6></span>
                                                                </div>
                                                            </div>
                                                        }
                                                        {type === '0' && travelerData?.trip_type == 2 &&
                                                            <div className="row">
                                                                <div className="col-5 d-flex">
                                                                    <div className="col-3">
                                                                        <img
                                                                            src={travelerData?.arrival_flag}
                                                                            style={{ aspectRatio: "4/2", objectFit: "contain", width: "70%", height: "60%", marginTop: "-7px" }}
                                                                        ></img>
                                                                    </div>
                                                                    <div className="col-8"><h6>{travelerData?.city_arrival_name}</h6></div>
                                                                </div>
                                                                <div className="col-2"><h5><i class="bi bi-arrow-right" ></i></h5></div>
                                                                <div className="col-5 d-flex">
                                                                    <div className="col-3">
                                                                        <img
                                                                            src={travelerData?.departure_flag}
                                                                            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "60%", marginTop: "-7px" }}
                                                                        ></img>
                                                                    </div>
                                                                    <div className="col-8">
                                                                        <h6>{travelerData?.city_departure_name}</h6>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        <div className="">
                                                            <div className="row pd-lavel d-flex  align-items-center flex-wrap gap-2 mt-2">
                                                                {type === '0' && travelerData?.trip_type == 2 && <div className="col row">
                                                                    <div className="col"><i class="bi bi-calendar-month-fill me-2" /> {travelerData?.return_departure_date}<br />{travelerData?.return_departure_time}hrs<br/>{t("form.Departure")}</div>
                                                                </div>}
                                                                {type === '0' && travelerData?.trip_type == 2 && <div className="col row">
                                                                    <div className="col"><i class="bi bi-calendar-month-fill me-2" />{travelerData?.return_arrival_date} <br />{travelerData?.return_arrival_time}hrs<br/>{t("form.Arrival")}</div>
                                                                </div>}
                                                            </div>
                                                        </div>
                                                        {type === '0' && travelerData?.trip_type == 2 &&
                                                            <div className="row d-flex gap-2 mt-2">
                                                                <div className="col-12 row">
                                                                    <div className="d-flex">
                                                                        <i class="bi bi-cart3 me-2" style={{ fontSize: '35px' }}></i>
                                                                        <div>{t("form.Carrying_Capacity_remaining")}<br />
                                                                            {travelerData?.remaining_capacity.toFixed(2)}kg
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 row">
                                                                    <div className="d-flex">
                                                                        <i class="bi bi-tag-fill" style={{ fontSize: '35px' }}></i>
                                                                        <span>{t("form.Unit_Price")}<br />
                                                                            {travelerData?.price_per_kg} {travelerData?.currency}
                                                                        </span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        }
                                                        {/* <div className="row pt-2">
                                                            {type === '0' && travelerData?.trip_type == 2 &&
                                                                <div className="col-6">
                                                                    <span><h6>{t("form.vehical_Detiles")}:{travelerData.vehicle_details}</h6></span>
                                                                </div>
                                                            }
                                                        </div> */}
                                                        <div className="custom-input-group">
                                                            <div className="submite-btn">
                                                                <button type="buttons" onClick={() => (handlesubmite(), scrollTop())}>{t("form.I_Validate_Post_Advert")}</button>
                                                            </div>
                                                            <Modal show={show} onHide={handleClose}
                                                                size="lg"
                                                                // aria-labelledby="contained-modal-title-vcenter"
                                                                centered
                                                            >
                                                                <Modal.Header closeButton>
                                                                    <Modal.Title>{t("form.Success")}</Modal.Title>
                                                                </Modal.Header>
                                                                <Modal.Body >
                                                                    <Form>
                                                                        {/* data takaych ahe */}
                                                                        <div className="">
                                                                            <div className="" style={{ textAlign: "center" }}>
                                                                                <label >{t("form.Your_advertisement_has_been_posted_successfully")}</label>
                                                                            </div>
                                                                            <div className="" style={{ textAlign: "center" }}>
                                                                                <label >{t("form.Your_view_advertisement_in")}</label>
                                                                            </div>
                                                                            <div style={{ textAlign: "center" }}>
                                                                                {type === "2" ?
                                                                                    <label ><h6>“{t("form.Article_on_sale")}”</h6></label>
                                                                                    :
                                                                                    <label ><h6>“{t("form.Articles_For_Donation")}”</h6></label>
                                                                                }
                                                                            </div>
                                                                            <div style={{ textAlign: "center" }}>
                                                                                <label>{t("form.announcements_section")}</label>
                                                                            </div>
                                                                        </div>
                                                                    </Form>
                                                                </Modal.Body>
                                                                <Modal.Footer className='justify-content-center' >
                                                                    <Button onClick={handleClose} >
                                                                        {t('profile.Close')}
                                                                    </Button>
                                                                </Modal.Footer>
                                                            </Modal>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            )}
        </>
    )
}

function TestimonialCardBeta(props) {
    return (
        <>
            <div className="package-card-beta">
                <div className="package-thumb" style={{ height: "400px" }}>
                    <img
                        src={`${process.env.PUBLIC_URL} ${props.image}`}
                        alt="package images"
                        style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", }}
                    />
                </div>
            </div>
        </>
    );
}
export default TravelAnnouncementDetails;