import React,{useContext} from 'react'
import { useTranslation } from 'react-i18next'
import AppContext from '../../../store/context/app-context';
import { useHistory } from 'react-router-dom';
export default function HowWorkWrap() {
    const { t } = useTranslation()
    const history = useHistory();
    const contextObj = useContext(AppContext);
    const addTraveller = (radioData) => {
        contextObj.setTabValue(0)
        contextObj.setRadioValue(radioData);
        history.push({ pathname: "/myProfile" });
      }
    return (
        <div className='container'>
            <div className='pt-100  pb-100'>
                <h3 style={{ fontWeight: 'bold' }} className='text-center '>{t('works.have_you_arrived')}</h3>
                <p className='pt-4 fs-5 text-center'>
                    {t('works.whether_are_you_professional')}
                </p>
                <h4 className='pt-4 text-center'>
                    {t('works.becoming_a_cobaggage')}
                </h4>
                <div className='row mt-3 mb-3'>
                    <div className=' col-12 col-lg-4 col-md-6  col-sm-4 mt-3 text-center'>
                        <div className="blog-card-beta pb-2" style={{height:'100%'}}>
                            <div className="">
                                <img
                                    style={{ width: "60%", height: "200px" }}
                                    src={process.env.PUBLIC_URL + "images/works/works3.png"} alt=""
                                />
                            </div>
                            <div className="content">
                                <h5 className='text-start pt-3 ps-3'>{t('works.saving_money_by_sharing')}</h5>
                                <div>
                                    <div className='pt-2 ps-3 d-flex align-items-center gap-2'>
                                        <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                                        <p className='text-start'>
                                           {t('works.sharing_a_car_journey')}
                                        </p>
                                    </div>
                                    <div className='pt-2 ps-3 d-flex align-items-center gap-2'>
                                        <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                                        <p className='text-start'>
                                            {t('works.sharing_between_individual')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=' col-12 col-lg-4 col-md-6 col-sm-4 mt-3 text-center'>
                        <div className="blog-card-beta" style={{height:'100%'}}>
                            <div className="">
                                <img
                                    style={{ width: "100%", height: "200px" }}
                                    src={process.env.PUBLIC_URL + "images/works/works2.png"} alt=""
                                />
                            </div>
                            <div className=" ">
                                <h5 className='text-start pt-3 ps-3'>{t('works.travellers_rent_free')}</h5>
                                <div>
                                    <div className='pt-2 ps-3 d-flex align-items-center gap-2'>
                                        <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                                        <p className='text-start'>
                                            {t('works.this_principle_also_applies_to')}
                                        </p>
                                    </div>
                                    <div className='pt-2 ps-3 d-flex align-items-center gap-2'>
                                        <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                                        <p className='text-start overflow-hidden'>
                                            {t('works.the_traveller_can_take_advantage_of')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    <div className=' col-12 col-lg-4 col-md-6 col-sm-4 mt-3 text-center'>
                        <div className="blog-card-beta " style={{height:'100%'}}>
                            <div className="">
                                {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}> */}
                                <img
                                    style={{ width: "100%", height: "200px" }}
                                    src={process.env.PUBLIC_URL + "images/works/works1.png"} alt=""
                                />
                            </div>
                            <div className="content">
                                <h5 className='text-start pt-3 ps-3'>{t('works.luggage_sender')}</h5>
                                <div>
                                    <div className='pt-2 ps-3 d-flex align-items-center gap-2'>
                                        <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                                        <p className='text-start'>
                                             {t('works.for_this_part_recipient_can_have')}
                                        </p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <div className=' mt-5 mb-3'>
                    <h4 className='text-center'>{t('works.being_a_cobaggage_allows_you_to')}</h4>
                    <div className='row mt-3'>
                        <div className='col-lg-6'>
                            <div className='card p-3'>
                                <h4>{t('works.becoming_a_traveller')}</h4>
                                <div>
                                    <p>{t('works.by_monitizing_your_kilos')}</p>
                                </div>
                                <div className='mt-3'>
                                    <button
                                        className="nav-link active"
                                        type="button"
                                        onClick={() =>{ contextObj.login ? addTraveller(1): history.push('/login');}}
                                        style={{ borderRadius: '5px', color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                                    >
                                        {t('works.post_a_travel_plan')}
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='col-lg-6'>
                            <div className='card p-3'>
                                <h4>{t('works.become_a_luggage_sender')}</h4>
                                <div>
                                    <p>{t('works.by_creating_a_transport_request')}</p>
                                </div>
                                <div className='mt-3'>
                                    <button
                                        className="nav-link active"
                                        type="button"
                                        onClick={() =>{ contextObj.login ? addTraveller(2): history.push('/login');}}
                                        style={{ borderRadius: '5px', color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                                    >
                                        {t('works.post_a_luggage_sending_request')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className=' mt-5 mb-3 text-center '>
                    <h4>{t('works.step_by_step_operation')}</h4>
                    <p className='pt-2 fs-5 p-3'>{t('works.the_cobaggage_platform_intended')}</p>
                </div>
                <div className='row my-3 ps-2 '>
                    <div className='col-lg-5 d-none d-lg-flex justify-content-end'>
                        <img src={process.env.PUBLIC_URL + "images/works/step1.png"} height={'200px'} width={'240px'} alt="" />
                    </div>
                    <div className='col-1 d-flex flex-column  align-items-center'>
                        <div class="d-flex" style={{ height: '20px' }}>
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                        <div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#0000ff' }}></div>
                        <div class="d-flex flex-grow-1">
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                    </div>
                    <div className='col-11 col-lg-5 '>
                        <div className='d-flex d-lg-none'><img src={process.env.PUBLIC_URL + "images/works/step1.png"} height={'200px'} width={'240px'} alt="" /></div>
                        <h4 className='pt-4'>{t('works.step')} 1</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.do_you_have_free_space_in_your_luggage')}
                            </p>
                        </div>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.you_will_need_to_provide_information_about_your_trip')}
                            </p>
                        </div>
                    </div>
                    <div className='col-5 d-none d-lg-block '>
                        <h4 className='text-end'>{t('works.step')} 2</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                              {t('works.do_you_have_free_space_in_your_luggage')}
                            </p>
                        </div>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.he_makes_payment_the_payment_directly_on_platform')}
                             </p>
                        </div>
                    </div>
                    <div className='col-1 d-flex flex-column  align-items-center'>
                        <div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#0000ff' }}></div>
                        <div class="d-flex flex-grow-1">
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                    </div>
                    <div className='col-11 col-lg-5'>
                        <img src={process.env.PUBLIC_URL + "images/works/step2.png"} height={'200px'} width={'240px'} alt="" />
                        <div className='d-lg-none '>
                        <h4 className='text-lg-end'>{t('works.step')} 2</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.the_sender_fill_out_a_form')}
                            </p>
                        </div>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.he_makes_payment_the_payment_directly_on_platform')}
                            </p>
                        </div>
                        </div>
                    </div>
                    <div className='col-lg-5 d-none d-lg-flex  justify-content-end '>
                        <img src={process.env.PUBLIC_URL + "images/works/step3.png"} height={'200px'} width={'240px'} alt="" />
                    </div>
                    <div className='col-1 d-flex flex-column  align-items-center'>
                        <div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#0000ff' }}></div>
                        <div class="d-flex flex-grow-1">
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                    </div>
                    <div className='col-11 col-lg-5 pb-3'>
                        <div className='d-lg-none'>
                        <img src={process.env.PUBLIC_URL + "images/works/step3.png"} height={'200px'} width={'240px'} alt="" />
                        </div>
                        <h4>{t('works.step')} 3</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                 {t('works.the_traveller_and_sender_are_put_in_contact')}
                            </p>
                        </div>
                    </div>
                    <div className='d-none d-lg-block col-lg-5'>
                        <h4 className='text-end'>{t('works.step')} 4</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                {t('works.once_the_connection_has_been_made')}
                            </p>
                        </div>
                    </div>
                    <div className='col-1 d-flex flex-column  align-items-center'>
                        <div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#0000ff' }}></div>
                        <div class="d-flex flex-grow-1">
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                    </div>
                    <div className='col-11 col-lg-5 pb-4'>
                        <img src={process.env.PUBLIC_URL + "images/works/step4.png"} height={'200px'} width={'240px'} alt="" />
                       <div className='d-lg-none pt-3'>
                       <h4 className='text-lg-end'>{t('works.step')} 4</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                            {t('works.once_the_connection_has_been_made')}
                            </p>
                        </div>
                       </div>
                    </div>
                    <div className='col-lg-5 d-none d-lg-flex justify-content-end  '>
                        <img src={process.env.PUBLIC_URL + "images/works/step5.png"} height={'200px'} width={'240px'} alt="" />
                    </div>
                    <div className='col-1 d-flex flex-column  align-items-center'>
                        <div style={{ height: '30px', width: '30px', borderRadius: '50%', backgroundColor: '#0000ff' }}></div>
                        <div class="d-flex flex-grow-1">
                            <div class="vr" style={{ border: '2px solid #0000ff' }}></div>
                        </div>
                    </div>
                    <div className='col-11 col-lg-5'>
                        <div className='d-lg-none'>
                        <img src={process.env.PUBLIC_URL + "images/works/step5.png"} height={'200px'} width={'240px'} alt="" />
                        </div>
                        <h4>{t('works.step')} 5</h4>
                        <div className='d-flex gap-2 pt-2'>
                            <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                            <p>
                                 {t('works.the_recipient_comes_to_the_meeting_place')}
                            </p>
                        </div>
                    </div>
                </div>
               <div>
               <div className='mt-5 mb-3 text-center'>
                <h4>{t('works.a_complete_secure_transport_solution')}</h4>
                <p className='pt-2 fs-5 p-3 pb-0'>{t('works.one_of_the_legitimate_question')}</p>
                </div>
                <div>
                    <div className='d-flex align-items-center  gap-2 pt-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                   {t('works.parcel_transport_through_cobaggage')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 pt-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                   {t('works.as_a_platform_for_connecting_co_baggagers')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className=' fs-5'>
                    {t('works.serveral_safety_rules')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                     {t('works.the_traveller_and_sender_have_obligation')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                   {t('works.the_sender_must_complete_the_registration')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                      {t('works.for_their_part_the_traveller_must_follow')}
                    </p>
                    </div>
                    <div className='d-flex align-items-center gap-2 p-2'>
                    <i className="bi bi-chevron-double-right" style={{ fontSize: '20px' }} />
                    <p className='fs-5'>
                      {t('works.he_must_also_provide_a_photocopy')}
                    </p>
                    </div>
                </div>
               </div>
               <div className='mt-5 mb-5'>
                <div className='row gap-2'>
                     <div className='col-12 col-md-5'>
                         <img src={process.env.PUBLIC_URL + "images/works/works5.png"} className='img-fluid'  alt="" />
                     </div>
                     <div className='col-12 col-md-6 d-flex flex-column  justify-content-center '>
                        <p>{t('works.becoming_a_cobaggager_is_assuarance_of')}</p>
                        <p className='pt-4'>{t('works.this_service_applies_to_all_kind_of_travel')}</p>
                     </div>
                </div>
               </div>
            </div>
        </div>
    )
}
