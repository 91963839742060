import React, { useContext, useEffect, useState } from 'react'
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import travel from "../../../models/travel";
import { country } from "../../../models";
import AppContext from '../../../store/context/app-context';

const format = {
  arriavalCountry: []
};

export default function Travels() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const [details, setDetails] = useState(format);
  const [traveldetails, settraveldetails] = useState([]);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("arriavalCountry", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  useEffect(() => {
    getAllData();
  }, [])
  const getAllData = () => {
    travel.list()
      .then(responseData => {
        if (responseData)
          settraveldetails(responseData);
      })
  }

  const filterTravelDetails = () => {
    return traveldetails.filter((item) => contextObj.travel?.country ? item.country_arrival == contextObj.travel?.country.value : item)
      .filter((item) => contextObj.travel?.state.value ? item.state_arrival == contextObj.travel?.state.value : item)
      .filter((item) => contextObj.travel?.city.value ? item.city_arrival == contextObj.travel?.city.value : item)
      .filter((item) => contextObj.travel?.oneWayTicket ? contextObj.travel?.oneWayTicket == item.trip_type : item)
      .filter((item) => contextObj.travel?.roundTrip ? contextObj.travel?.roundTrip == item.trip_type : item)
      .filter((item) => contextObj.travel?.particular ? contextObj.travel?.particular == item.user_account_type : item)
      .filter((item) => contextObj.travel?.professional ? contextObj.travel?.professional == item.user_account_type : item)
  }

  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };

  return (
    <div className="package-area package-style-two  pb-110 chain b">
      <div className="container">
        <div className="row justify-content-center">
          <div className="row">
            <div className='col-12 col-md-9 col-lg-9'>
              <h2>{t("cobagage.recent_listing")}</h2>
            </div>
            <div className='custom-input-group col-lg-3 col-12 col-md-3 ' style={{ marginBottom: '20px' }}>
              <select
                id="Ads_Category"
                name='Ads_Category'
              >
                <option value={''} selected disabled>{t("cobagage.default_sorting")}</option>
                <option>{t("cobagage.newest_first")}</option>
                <option>{t("cobagage.oldest_first")}</option>
                <option>{t("cobagage.lowest_price_first")}</option>
                <option>{t("cobagage.highest_price_first")}</option>
              </select>
              <select
                id="Ads_Category"
                // onChange={handleChange}
                name='Ads_Category'
              // value={data.Ads_Category}
              >
                <option value={''} selected disabled>{t("cobagage.select_arrival_country")}</option>
                {details.arriavalCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center g-4">
          {filterTravelDetails()?.map(item => (
            <div className="col-lg-3 col-md-6 col-sm-10" key={item.id}>
              <div className="package-card-delta">
                <div className="package-thumb">
                  <Link to={`${process.env.PUBLIC_URL}/package-details`} onClick={scrollTop}><img src={process.env.PUBLIC_URL + "/images/package/best-s1.png"} alt="" /></Link>
                  <p className="card-lavel" style={{ fontSize: '12px', textAlign: 'end', minWidth: '20px' }}>
                    {t("cobagage.unverified")}
                  </p>
                  <h3 style={{ position: 'absolute', bottom: '45px', left: '20px' }}><Link to={`${process.env.PUBLIC_URL}/package-details`} onClick={scrollTop} style={{ color: 'white', fontSize: '18px' }}>{item.title}</Link></h3>
                  <div style={{ position: 'absolute', bottom: '0px', left: '20px', backgroundColor: 'white', borderTopRightRadius: '7px', borderTopLeftRadius: '7px' }}>
                    <h3 className="p-card-title" style={{ fontSize: '16px', padding: "8px 20px 0px 20px", fontWeight: '700' }}>{item.price_per_kg}€ {t("cobagage.per")} kg</h3>
                  </div>
                </div>
                <div className="package-card-body">
                  <div className="row">
                    <div className=" col col-6 col-lg-6 col-md-6 " style={{ position: 'absolute', top: '0px', left: '0px' }}>
                      <p>
                        <i class="bi bi-calendar" />
                        <span style={{ fontSize: '12px', fontWeight: '500' }}> 04 Feb 2023</span>
                      </p>
                    </div>
                    <div className="col col-6 col-lg-6 col-md-6" style={{ position: 'absolute', top: '0px', right: '0px' }}>
                      <p>
                        <i class="bi bi-person-fill" />
                        <Link><span style={{ fontSize: '12px', fontWeight: '500', color: '#ff4838' }}>UserName</span></Link>
                      </p>
                    </div>
                  </div>
                  <table className='col-lg-12 col-12 col-md-12 col-sm-12' style={{ fontSize: '12px', fontWeight: '700' }}>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.from")}</td>
                      <td style={{ textAlign: 'end' }}>{(item.country_departure_name)}/{item.city_departure_name}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.to")}</td>
                      <td style={{ textAlign: 'end' }}>{item.country_arrival_name}/{item.city_arrival_name}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.carriage_capacity")}</td>
                      <td style={{ textAlign: 'end' }}>{item.carriage_capacity}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.departure_date")}</td>
                      <td style={{ textAlign: 'end' }}>{item.departure_date}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.arrival_date")}</td>
                      <td style={{ textAlign: 'end' }}>{item.arrival_date}</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.article_for_sell")}</td>
                      <td style={{ textAlign: 'end' }}>3214800</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.article_donation")}</td>
                      <td style={{ textAlign: 'end' }}>0</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.type_of_traveler")}</td>
                      <td style={{ textAlign: 'end' }}>{item.type_of_traveler === "1" ? "Regular" : (item.type_of_traveler === "2" ? "Periodical" : "One-Off")}</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.member_since")}</td>
                      <td style={{ textAlign: 'end' }}>30-12-2022</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.trips_made")}</td>
                      <td style={{ textAlign: 'end' }}>23</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.luggages_sent")}</td>
                      <td style={{ textAlign: 'end' }}>10</td>
                    </tr>
                  </table>
                  <div className="p-card-bottom">
                    <div className="book-btn">
                      <p style={{ fontSize: '12px', fontWeight: '700', color: '#ff4838' }}>{t("cobagage.user_rating")}</p>
                    </div>
                    <div className="p-card-info">
                      <i class="bi bi-star-fill" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                    </div>
                  </div>
                  <div className="p-card-bottom">
                    <div className="book-btn">
                      <Link to={`${process.env.PUBLIC_URL}/package-details`} style={{ fontSize: '11px', fontWeight: '800' }} onClick={scrollTop}>{t("cobagage.detail")}<i className="bx bxs-right-arrow-alt" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>))}
        </div>
      </div>
    </div>
  )
}
