import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Form, Modal } from "react-bootstrap";
import Wallet from "../../../../models/Wallet";
import moment from "moment/moment";
import card from "../../../../models/card";
import { useContext } from "react";
import AppContext from "../../../../store/context/app-context";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import { DataGrid } from "@mui/x-data-grid";
import { Visibility as VisibilityIcon } from "@mui/icons-material";
import { IconButton } from "@mui/material";
import i18n from "../../../../plugin/i18n";
import mobileMoney from "../../../../models/mobileMoney";
import { country } from "../../../../models";

const reset = {
  wallet: "1",
  withdrawal: "1",
};
const request = {
  withdrawal_amount: "",
  card_id: "",
  Mobile_Number_id: "",
  Ibanm_Number_id: "",
};
const cardDetails = {
  card_type: '',
  card_holder_name: '',
  card_number: '',
  cvv: '',
  card_expiry_month: '',
  card_expiry_year: '',
  paypal_id: '',
}

const ResetMobileData = {
  Mobile_number: "",
  phone_code: ""
}
const ResetIbanData = {
  IBAN: "",
  Swift_BIC: ""
}

const format = {
  departureCountry: [],
};

export default function CobagageWallet() {
  const { t } = useTranslation();
  const contextObj = useContext(AppContext);
  const [validator, showMessage] = useValidator();
  const [show, setShow] = useState(false);
  const [walletData, setWalletData] = useState();
  const [receivedData, setReceivedData] = useState();
  const [withdrawData, setWithdrawData] = useState();
  const [receivedItem, setReceivedItem] = useState();
  const [userCards, setUserCards] = useState([]);
  const [userMobileNumber, setUserMobileNumber] = useState([]);
  const [userIbanNumber, setUserIbanNumber] = useState([]);
  const [isShow, setIsShow] = useState(1);
  const [data, setData] = useState(reset);
  const [requestdata, setRequestData] = useState(request);
  const [pageSize, setPageSize] = useState(10);
  const [filteredData, setFilteredData] = useState(null);
  const [cardValidator, cardFieldsMessage] = useValidator();
  const [mobileValidator, mobileFieldsMessage] = useValidator();
  const [ibanValidator, ibanFieldsMessage] = useValidator();
  const [cardData, setCardData] = useState(cardDetails);
  const [popUp, setPopUp] = useState(false)
  const [mobilePopup, setMobilePopup] = useState(false);
  const [ibanPopup, setIbanPopup] = useState(false);
  const [refresh, setRefresh] = useState(false)
  const [mobileData, setMobileData] = useState(ResetMobileData)
  const [ibanData, setIbanData] = useState(ResetIbanData)
  const [valueZero, setValueZero] = useState(0);
  const [hideOption, setHideOption] = useState(1);
  const [details, setDetails] = useState(format);
  useEffect(() => { }, [receivedData, filteredData]);
  const columns = useMemo(
    () => [
      {
        field: "icon",
        headerName: t("profile.icon"),
        renderCell: (params) => {
          return (
            <div>
              <i
                class={
                  params?.row?.hold == true
                    ? "bi bi-hourglass-split"
                    : "bi bi-arrow-down-circle-fill"
                }
                style={{
                  color: params?.row?.hold == true ? "#f39c12" : "green",
                  fontSize: "30px",
                }}
              />
            </div>
          );
        },
        flex: 1,
        sortable: false,
        minWidth: 80,
      },
      {
        field: "status",
        headerName: t("profile.status"),
        sortable: false,
        renderCell: (params) => {
          return (
            <div>
              <span
                class="font-weight-bold"
                style={{
                  color: params?.row?.hold == true ? "#f39c12" : "green",
                }}
              >
                {params?.row?.hold == true
                  ? t("profile.pendingbyadmin")
                  : t("profile.received")}
              </span>
            </div>
          );
        },
        flex: 1.5,
        sortable: false,
        minWidth: 200,
      },
      {
        field: "sender_name",
        headerName: t("profile.received_from"),
        flex: 1,
        minWidth: 200,
        sortable: false,
      },
      {
        field: "net_amount",
        headerName: t("profile.amount"),
        renderCell: (param) => (<>
          {param.row.net_amount}{" "}{contextObj?.loginUser?.symbol}
        </>),
        sortable: false,
        flex: 1,
        minWidth: 200,
      },
      {
        field: "actions",
        type: "actions",
        sortable: false,
        headerName: t("action"),
        minWidth: 80,
        renderCell: (params) => {
          return (
            <IconButton
              aria-label="view"
              onClick={() => {
                openpop();
                setReceivedItem(params?.row);
              }}
            >
              <VisibilityIcon />
            </IconButton>
          );
        },
      },
    ],
    [t]
  );

  const error = {
    withdrawal_amount: validator.message(
      t("Amount"),
      requestdata.withdrawal_amount,
      "required|string|max:200"
    ),
    // card_id: validator.message(
    //   t("Card"),
    //   requestdata.card_id,
    //   "required|string|max:200"
    // ),
  };
  const cardError = {
    card_holder_name: cardValidator.message(t('profile.card_holders_name'), cardData?.card_holder_name, 'required|alpha_space'),
    card_number: cardValidator.message('Card Number', cardData?.card_number, 'required'),
    cvv: cardValidator.message("CVV", cardData?.cvv, 'required|numeric|min:3'),
    card_expiry_month: cardValidator.message("Month", cardData?.card_expiry_month, 'required|integer'),
    card_expiry_year: cardValidator.message("Year", cardData?.card_expiry_year, 'required|numeric|min:4'),
    card_type: cardValidator.message("Card Type", cardData?.card_type, 'required|'),
  }
  const mobileDataError = {
    Mobile_number: mobileValidator.message(t("profile.Mobile_number"), mobileData?.Mobile_number, 'required'),
    phone_code: mobileValidator.message(t("form.Phone_code"), mobileData?.phone_code, 'required'),
  }

  const IbanDataError = {
    IBAN: ibanValidator.message("IBAN", ibanData?.IBAN, 'required'),
    Swift_BIC: ibanValidator.message("Swift BIC", ibanData?.Swift_BIC, 'required'),
  }
  useEffect(() => {
    scrollTop();
    WalletGetByUser();
    WalletReceivedPayments();
    WalletWithdrawPayments();
    getCardData();
    getMobileData();
    getIbanData();
    filterWalletHistory();
    getData();
  }, [refresh]);

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleClose = () => {
    setShow(false);
    setPopUp(false);
    setMobilePopup(false)
    setIbanPopup(false)
    setCardData(cardDetails);
    setMobileData(ResetMobileData);
    setIbanData(ResetIbanData);
    setValueZero(0)
    mobileFieldsMessage(false);
    ibanFieldsMessage(false);
  };
  const handleShow = () => setShow(true);
  const openpop = () => {
    handleShow();
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    if (name == "withdrawal_amount" || name == "card_id" || name == "Mobile_Number_id" || name == "Ibanm_Number_id") {

      setRequestData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      setData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };
  const WalletGetByUser = () => {
    Wallet.getBuyUserID()
      .then((data) => {
        setWalletData(data);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const WalletReceivedPayments = () => {
    Wallet.ReceivedPayments()
      .then((item) => {
        setReceivedData(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const WalletWithdrawPayments = () => {
    Wallet.WithdrawRequestBuyUserID()
      .then((item) => {
        setWithdrawData(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const CreateWithdrawRequest = () => {
    if (validator.allValid()) {
      Wallet.update(requestdata)
        .then((item) => {
          WalletGetByUser();
          setIsShow(1);
          setRequestData(request);
          showMessage(!true);
          WalletWithdrawPayments()
          notistack.success(t('form.withdraw_message'))
        })
        .catch((error) => {
          notistack.error(error?.data.message);
          console.log("error", error);
        });
    } else {
      showMessage(true);
    }
  };
  const getCardData = () => {
    card
      .get(contextObj?.loginUser?.id)
      .then((item) => {
        setUserCards(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getMobileData = () => {
    mobileMoney.list().then((resp) => {
      setUserMobileNumber(resp)
    }).catch((error) => {
      console.log("getMobileData", error);
    })
  }

  const getIbanData = () => {
    mobileMoney.IbanNumber().then((resps) => {
      setUserIbanNumber(resps)
    }).catch((error) => {
      console.log("getIbanData", error);
    })
  }
  // const handleCardChange = (e) => {

  //   const { name, value } = e.target;
  //   handleCardData(name, value);
  // }

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    if (name === "card_number") {
      console.log("value", value);
      const formattedCardNumber = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ');
      console.log("formattedCardNumber", formattedCardNumber);
      handleCardData(name, formattedCardNumber);
    } else {
      handleCardData(name, value);
    }
  };
  const handleCardData = (name, value) => {
    setCardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleMobileChange = (e) => {
    const { name, value } = e.target;
    handleMobileData(name, value);
  }

  const handleMobileData = (name, value) => {
    setMobileData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleIbanChange = (e) => {
    const { name, value } = e.target;
    handleIbanData(name, value);
  }

  const handleIbanData = (name, value) => {
    setIbanData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleMobileSubmit = () => {
    if (mobileValidator.allValid()) {
      mobileMoney.create(mobileData).then((respons) => {
        getMobileData();
        notistack.success(t("profile.number_added_uccessfully"))
        setMobilePopup(false)
        setIbanPopup(false)
        setMobileData(ResetMobileData)
        mobileFieldsMessage(false)
      }).catch((error) => {
        console.log("handleMobileSubmit", error);
      })
    } else {
      mobileFieldsMessage(true)
    }

  }
  const handleIbanSubmit = () => {
    if (ibanValidator.allValid()) {
      mobileMoney.createIban(ibanData).then((respons) => {
        getMobileData();
        getIbanData();
        notistack.success(t("profile.number_added_uccessfully"))
        setIbanPopup(false)
        setIbanData(ResetIbanData);
        ibanFieldsMessage(false)
      }).catch((error) => {
        console.log("handleIbanSubmit", error);
      })
    } else {
      ibanFieldsMessage(true)
    }

  }
  const handleCardSubmit = () => {
    if (cardValidator.allValid()) {
      card.create(cardData).then((item) => {
        notistack.success(t('profile.card_add'));
        handleClose();
        setRefresh(true);
        cardFieldsMessage(false);
      })
        .catch((error) => {
          notistack.error(error.data.errors.card_expiry_year);
          console.log(error);
        })
    }
    else {
      cardFieldsMessage(true);
    }
  }
  const filterWalletHistory = () => {
    return receivedData?.filter((item) =>
      data?.wallet == 2
        ? item?.hold == true
        : data?.wallet == 3
          ? item?.hold == false
          : item
    );
  };

  const filterWithdrawtHistory = () => {
    return withdrawData?.filter((item) =>
      data?.withdrawal == 2
        ? item?.status == 0
        : data?.withdrawal == 3
          ? item?.status == 1
          : data?.withdrawal == 4
            ? item?.status == 2
            : item
    );
  };

  const handlePaymentMethod = (e) => {
    if (e.target.value == 1) {
      setPopUp(true)

    }
    else if (e.target.value == 2) {
      setMobilePopup(true)
    }
    else {
      setIbanPopup(true)
    }
  }

  const SelectPaymentMethod = (e) => {
    setHideOption(e.target.value)
  }

  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  return (
    <div class="pt-30">
      <div
        class="col-12 col-lg-5 col-sm-4 walletImg wallet"
        style={{
          backgroundImage: 'url("/images/vehicles/wallet.png")',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          borderRadius: "10px",
          height: "200px",
          width: "303px",
        }}
      >
        <div style={{ paddingTop: "18px", paddingLeft: "18px" }}>
          <h6 style={{ color: "white" }}>
            {t("profile.cobagage_wallet_balance")}
          </h6>
          <h2 style={{ color: "white" }}>
            <i class="bi bi-cash-stack " style={{ fontSize: "18px" }}></i>
            <span style={{ fontSize: "18px" }}>
              {"  "}
              {walletData?.current_balance} {contextObj?.loginUser?.symbol}
            </span>
          </h2>
        </div>
        <div
          style={{
            padding: "18px",
            marginTop: "6px",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <h6 style={{ color: "white" }}>
            <span>
              {"  "}
              {walletData?.name}
            </span>
          </h6>
        </div>
        <div className="col-12 row" style={{ marginTop: "20px" }}>
          <div className="col-4" style={{ paddingLeft: "29px" }}>
            <h6 style={{ color: "white" }}>
              <span>
                {"  "}...{walletData?.card_number?.slice(12)}
              </span>
            </h6>
          </div>
          <div className="col-5 row">
            <div className="col-5 expireName">
              <h6 style={{ color: "white", fontSize: "6px" }}>
                {t("profile.EXPIRE_PIN")}
              </h6>
            </div>
            <div className="col-6">
              <h6 style={{ color: "white" }}>
                <span>{walletData?.card_expire_date}</span>
              </h6>
            </div>
          </div>
          <div className="col-3 d-flex justify-content-end">
            <h6 style={{ color: "white", fontStyle: "initial" }}>
              <span>VISA</span>
            </h6>
          </div>
        </div>
      </div>
      <div class="">
        {isShow == 1 && (
          <div class="col-12 pt-3">
            <div className="package-details-tabs row ms-1 mb-1">
              <ul
                className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                id="pills-tab"
                role="tablist"
                style={{
                  marginBottom: "0px",
                  overflowX: "auto",
                  justifyContent: "end",
                }}
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="pills-package1"
                    data-bs-toggle="pill"
                    data-bs-target="#pill-body1"
                    type="button"
                    role="tab"
                    aria-controls="pill-body1"
                    aria-selected="true"
                    onClick={() => setIsShow(2)}
                    style={{ fontSize: "15px", borderRadius: "5px" }}
                  >
                    <i class="bi bi-arrow-counterclockwise"></i>
                    {t("profile.Withdraw")}
                  </button>
                </li>
              </ul>
            </div>
          </div>
        )}
        {isShow == 2 && (
          <div class="col-12 col-lg-12 col-sm-12 pt-3">
            <div class="walletCard bg-c-yellow order-walletCard col-lg-12 row">
              <div
                class="card-block col-lg-6 col-md-4"
                style={{ padding: "12px" }}
              >
                <label>{t("profile.Enter_Amount")}</label>
                <input
                  className="numberType"
                  type="number"
                  style={{ height: "35px" }}
                  placeholder={t("profile.Enter_Amount")}
                  name="withdrawal_amount"
                  onChange={handleChange}
                />
                <span style={{ color: "red" }}>{error.withdrawal_amount}</span>
              </div>
              {hideOption == 1 && <div
                class="card-block col-lg-6 col-md-6"
                style={{ padding: "12px" }}
              >
                <label>{t("profile.Select_Card_Number")}</label>
                <select
                  style={{ height: "35px" }}
                  onChange={handleChange}
                  name="card_id"
                >
                  <option selected disabled>
                    {t("profile.Select_Card_Number")}
                  </option>
                  {
                    userCards?.map((item) => (
                      <option value={item?.card_id}>{`**** **** **** ${item.card_number.slice(-4)}`}</option>
                    ))
                  }
                </select>
                {/* <span style={{ color: "red" }}>{error.card_id}</span> */}
              </div>}
              {hideOption == 2 && <div
                class="card-block col-lg-6 col-md-6"
                style={{ padding: "12px" }}
              >
                <label>{t("profile.Mobile_number")}</label>
                <select
                  style={{ height: "35px" }}
                  onChange={handleChange}
                  name="Mobile_Number_id"
                >
                  <option selected disabled>
                    {t("profile.Mobile_number")}
                  </option>
                  {
                    userMobileNumber?.map((item) => (
                      <option value={item?.id}>{item.mobileNumber}</option>
                    ))
                  }
                </select>
                {/* <span style={{ color: "red" }}>{error.card_id}</span> */}
              </div>}
              {hideOption == 3 && <div
                class="card-block col-lg-6 col-md-6"
                style={{ padding: "12px" }}
              >
                <label>
                  IBAN
                  {/* {t("profile.IBAN")} */}
                </label>
                <select
                  style={{ height: "35px" }}
                  onChange={handleChange}
                  name="Ibanm_Number_id"
                >
                  <option selected disabled>
                    {/* {t("profile.IBAN")} */}
                    IBAN
                  </option>
                  {
                    userIbanNumber?.map((item) => (
                      <option value={item?.id}>{item?.iban}</option>
                    ))
                  }
                </select>
                {/* <span style={{ color: "red" }}>{error.card_id}</span> */}
              </div>}
              <div className="col-lg-4 col-12" style={{ marginTop: "10px" }}>
                <div className="package-details-tabs row ms-1 mb-1">
                  <ul
                    className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                    id="pills-tab"
                    role="tablist"
                    style={{ marginBottom: "0px", overflowX: "auto" }}
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-package1"
                        data-bs-toggle="pill"
                        data-bs-target="#pill-body1"
                        type="button"
                        role="tab"
                        aria-controls="pill-body1"
                        aria-selected="true"
                        onClick={() => {
                          setIsShow(1);
                          setHideOption(1);
                          showMessage(false);
                          setRequestData(request);
                        }}
                        style={{ fontSize: "15px", borderRadius: "5px" }}
                      >
                        {t("profile.cancel")}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-package2"
                        data-bs-toggle="pill"
                        data-bs-target="#pill-body2"
                        type="button"
                        role="tab"
                        aria-controls="pill-body2"
                        aria-selected="false"
                        onClick={() => CreateWithdrawRequest()}
                        style={{ fontSize: "15px", borderRadius: "5px" }}
                      >
                        {t("form.submit")}
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-lg-4 col-12 text-end" style={{ marginBottom: "10px" }}>
                <span className='text-end col-12'>{t('profile.add_new_card')}</span>
                <select
                  style={{ height: "35px" }}
                  onChange={(e) => handlePaymentMethod(e)}
                  value={valueZero}
                >
                  <option selected disabled value={0}>
                    {t('profile.add_new_card')}
                  </option>
                  <option value={1}>
                    {t("profile.Select_Card")}
                  </option>
                  <option value={2}>
                    Mobile Money
                  </option>
                  <option value={3}>
                    {/* {t("profile.IBAN")} */}
                    IBAN
                  </option>
                </select>
              </div>
              <div className="col-lg-4 col-12 text-end" style={{ marginBottom: "10px" }}>
                <span className='text-end col-12'>{t('profile.select_Paymet_Method')}</span>
                <select
                  style={{ height: "35px" }}
                  onChange={(e) => SelectPaymentMethod(e)}
                // value={valueZero}
                >
                  {/* <option selected disabled value={0}>
                    {t('profile.select_Paymet_Method')}
                  </option> */}
                  <option selected value={1}>
                    {t("profile.Card")}
                  </option>
                  <option value={2}>
                    Mobile Money
                  </option>
                  <option value={3}>
                    {/* {t("profile.IBAN")} */}
                    IBAN
                  </option>
                </select>
              </div>
            </div>
          </div>
        )}
      </div>
      <div class="row">
        <div class="col-md-8 col-lg-8 col-xl-8">
          <div
            style={{ fontWeight: "bold", marginTop: "40px", fontSize: "30px" }}
          >
            <span>{t("profile.wallet_history")}</span>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4">
          <label htmlFor="name" style={{ marginTop: "30px" }}>
            {t("profile.Filter")}
          </label>
          <select
            id="type"
            onChange={handleChange}
            name="wallet"
            style={{ border: "1px solid red" }}
          >
            <option value={0} selected disabled>
              {t("profile.Filter")}
            </option>
            <option value={1}>{t("profile.all")}</option>
            <option value={2}>{t("profile.on_hold")}</option>
            <option value={3}>{t("profile.complete")}</option>
          </select>
        </div>
      </div>
      <div class="package-card-delta card mt-5" style={{ overflowX: "auto" }}>
        {receivedData && (
          <DataGrid
            onRowClick={false}
            onCellClick={false}
            disableSelectionOnClick={true}
            showCellVerticalBorder={false}
            disableColumnFilter={true}
            rowSelection={false}
            disableColumnSelector={true}
            isRowSelectable={false}
            sortingOrder={[null]}
            onColumnHeaderClick={false}
            showColumnVerticalBorder={false}
            disableRowSelectionOnClick={true}
            autoHeight
            sx={{
              width: "100%",
              "& .MuiDataGrid-columnHeaders": {
                backgroundColor: "#ff4838",
                color: "#fff",
                fontSize: 16,
              },
            }}
            rows={filterWalletHistory() ?? []}
            columns={columns}
            pageSize={pageSize}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            rowsPerPageOptions={[10, 20, 50]}
            disableColumnMenu={true}
            localeText={{
              noRowsLabel: t("profile.no_rows"),
            }}
          />
        )}
      </div>
      <div class="row">
        <div class="col-md-8 col-lg-8 col-xl-8">
          <div
            style={{ fontWeight: "bold", marginTop: "40px", fontSize: "30px" }}
          >
            <span>{t("profile.withdrawal_history")}</span>
          </div>
        </div>
        <div className="col-md-4 col-lg-4 col-xl-4">
          <label htmlFor="name" style={{ marginTop: "30px" }}>
            {t("profile.Filter")}
          </label>
          <select
            id="type"
            onChange={handleChange}
            name="withdrawal"
            style={{ border: "1px solid red" }}
          >
            <option value={0} selected disabled>
              {t("profile.Filter")}
            </option>
            <option value={1}>{t("profile.all")}</option>
            <option value={2}>{t("profile.on_hold")}</option>
            <option value={3}>{t("profile.complete")}</option>
            <option value={4}>{t("profile.reject")}</option>
          </select>
        </div>
      </div>
      <div
        class="container package-card-delta card mt-5 pb-2"
        style={{ overflowX: "auto" }}
      >
        <table class="table table-borderless table-responsive card-1 p-4 mt-4">
          <thead>
            <tr class="border-bottom">
              <th>
                <span class="ml-2">{t("profile.icon")}</span>
              </th>
              <th>
                <span class="ml-2">{t("profile.status")}</span>
              </th>
              <th>
                <span class="ml-2">{t("profile.transfer_to")}</span>
              </th>
              <th>
                <span class="ml-2">{t("profile.amount")}</span>
              </th>
              <th>
                <span class="ml-4">{t("profile.Date_and_time")}</span>
              </th>
            </tr>
          </thead>
          <tbody>
            {filterWithdrawtHistory()?.map((item) => (
              <tr class="border-bottom">
                <td>
                  <i
                    class={
                      item?.status == 0
                        ? "bi bi-hourglass-split"
                        : item?.status == 1
                          ? "bi bi-arrow-down-circle-fill"
                          : "bi bi-x-circle"
                    }
                    style={{
                      color:
                        item?.status == 0
                          ? "#f39c12"
                          : item?.status == 1
                            ? "green"
                            : "red",
                      fontSize: "30px",
                    }}
                  />
                </td>
                <td>
                  <div class="p-2">
                    <span
                      class="font-weight-bold"
                      style={{
                        color:
                          item?.status == 0
                            ? "#f39c12"
                            : item?.status == 1
                              ? "green"
                              : "red",
                      }}
                    >
                      {item?.status == 0
                        ? t("profile.pendingbyadmin")
                        : item?.status == 1
                          ? t("profile.received")
                          : t("profile.reject")}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="p-2">
                    <span class="font-weight-bold">Cobagage</span>
                  </div>
                </td>
                <td>
                  <div class="p-2">
                    <span class="font-weight-bold">
                      {item?.withdraw_amount}
                    </span>
                  </div>
                </td>
                <td>
                  <div class="p-2">
                    <span class="font-weight-bold">
                      {moment(item?.updated_at).format("DD/MM/YYYY") +
                        " | " +
                        moment(item?.updated_at).format("hh:mm a")}
                    </span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {/* <nav aria-label="Page navigation example">
                    <ul class="pagination justify-content-end">
                        <li class="page-item disabled">
                            <a class="page-link" href="#" tabindex="-1" aria-disabled="true">{t("profile.Previous")}</a>
                        </li>
                        <li class="page-item"><a class="page-link" >1</a></li>
                        <li class="page-item"><a class="page-link" >2</a></li>
                        <li class="page-item"><a class="page-link" >3</a></li>
                        <li class="page-item">
                            <a class="page-link" href="#">{t('profile.Next')}</a>
                        </li>
                    </ul>
                </nav> */}
      </div>
      <Modal show={show} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <i
              class="bi bi-arrow-down-circle-fill"
              style={{ color: "green", fontSize: "30px" }}
            />{" "}
            {t("profile.received")}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="">
            <div className="row">
              <div
                className="col-lg-6"
                style={{ padding: "5px", fontWeight: "700" }}
              >
                Transaction Date & {t("profile.time")}:
              </div>
              <div className="col-lg-6" style={{ padding: "5px" }}>
                {moment(receivedItem?.updated_at)?.format("DD/MM/YYYY")} |{" "}
                {moment(receivedItem?.updated_at)?.format("hh:mm a")}
              </div>
              <div
                className="col-lg-6"
                style={{ padding: "5px", fontWeight: "700" }}
              >
                Description:
              </div>
              <div className="col-lg-6" style={{ padding: "5px" }}>
                {receivedItem?.request_type == 1
                  ? t("profile.money_received_for_carrying_luggage")
                  : receivedItem?.request_type == 5
                    ? t("profile.refund")
                    : t("profile.money_received_from_sold_article")}
              </div>
              <div
                className="col-lg-6"
                style={{ padding: "5px", fontWeight: "700" }}
              >
                {t("profile.received_from")}:
              </div>
              <div className="col-lg-6" style={{ padding: "5px" }}>
                {receivedItem?.sender_name}
              </div>
              <div
                className="col-lg-6"
                style={{ padding: "5px", fontWeight: "700" }}
              >
                {t("profile.amount")}:
              </div>
              <div className="col-lg-6" style={{ padding: "5px" }}>
                {receivedItem?.net_amount}{" "}{contextObj?.loginUser?.symbol}
              </div>
              {/* <div className='col-lg-6' style={{ padding: "5px", fontWeight: "700" }}>Advert Reference</div>
                            <div className='col-lg-6' style={{ padding: "5px", }}>99999999</div> */}
            </div>
          </div>
        </Modal.Body>
      </Modal>
      <Modal
        show={mobilePopup}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>Mobile moeny</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="card my-4 p-3">
                  <form class="form-card">
                    <div class="row mt-2">
                      <div className="col-12 col-lg-4">
                        <div className="custom-input-group">
                          <label htmlFor="name">+###</label>
                          <select
                            required
                            id="phone_code"
                            onChange={(e) => {
                              handleMobileChange(e, "phone_code");
                            }}
                            // disabled={mobileData.phone_code}
                            name="phone_code"
                            value={mobileData.phone_code}
                          >
                            <option value="" selected disabled>
                              {t("form.Phone_code")}
                            </option>
                            {details.departureCountry?.map((item) => (
                              <option value={item.phone_code} key={item.id}>
                                {item.phone_code}
                              </option>
                            ))}
                          </select>
                          <span style={{ color: "red" }}>{mobileDataError.phone_code}</span>
                        </div>
                      </div>
                      <div class="col-8">
                        <div
                          className="custom-input-group "
                        // style={{ marginTop: i18n.language === 'frFR' ? "5px" : "28px" }}
                        >
                          <label class="form-control-placeholder p-0" for="name">{t('profile.Mobile_number')}<span style={{ color: 'red' }}> *</span></label>
                          <input
                            maxLength={19}
                            type="number"
                            className='numberType'
                            placeholder="****************"
                            name='Mobile_number'
                            onChange={handleMobileChange}
                            value={mobileData.Mobile_number}
                            required />
                          {/* <span>{t("form.Delivery_communicated")}</span> */}
                          <span style={{ color: "red" }}>{mobileDataError.Mobile_number}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <Button variant="primary"
            onClick={handleMobileSubmit}
          >
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={ibanPopup}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('profile.International_payment')}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="card my-4 p-3">
                  <form class="form-card">
                    <div class="row mt-2">
                      <div className="col-12">
                        <span>{t('auth.full_Name')}{' '}:{' '}{contextObj?.loginUser?.full_name}</span>
                      </div>
                      <div className="col-12">
                        <div className="custom-input-group">
                          <label htmlFor="name">IBAN<span style={{ color: 'red' }}> *</span></label>
                          <input
                            type="text"
                            className='numberType'
                            placeholder="****************"
                            name='IBAN'
                            onChange={handleIbanChange}
                            value={ibanData.IBAN}
                            required />
                          <span style={{ color: "red" }}>{IbanDataError.IBAN}</span>
                        </div>
                      </div>
                      <div class="col-12">
                        <div
                          className="custom-input-group "
                        // style={{ marginTop: i18n.language === 'frFR' ? "5px" : "28px" }}
                        >
                          <label class="form-control-placeholder p-0" for="name">Swift/BIC<span style={{ color: 'red' }}> *</span></label>
                          <input
                            type="text"
                            className='numberType'
                            placeholder="****************"
                            name='Swift_BIC'
                            onChange={handleIbanChange}
                            value={ibanData.Swift_BIC}
                            required />
                          <span style={{ color: "red" }}>{IbanDataError.Swift_BIC}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <Button variant="primary"
            onClick={handleIbanSubmit}
          >
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal
        show={popUp}
        onHide={handleClose}
      >
        <Modal.Header closeButton>
          <Modal.Title>{t('profile.add_new_card')}</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          <Form>
            <div class="container-fluid">
              <div class="row justify-content-center">
                <div class="card my-4 p-3">

                  <div class="row justify-content-center mrow">
                    <div class="col-lg-8 col-12 col-sm-6 col-md-8">
                      {/* <img src="https://img.icons8.com/color/48/000000/mastercard-logo.png" width="35px" height="35px" />
                                    <img src="https://img.icons8.com/color/48/000000/visa.png" width="35px" height="35px" /> */}
                      {/* <img src="https://img.icons8.com/color/48/000000/paypal.png" width="35px" height="35px" /> */}
                      <div className='row'>
                        <div className='col-2'>
                          <img src={"/images/vehicles/visa.png"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/mscard.jpg"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/maestro.png"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/cb.jpg"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/card.png"} alt="" width="100%" height="100%" />
                        </div>
                      </div>
                    </div>
                    <div class="form-group col-lg-4 col-12 col-sm-6 col-md-4">
                      <label class="form-control-placeholder p-0" for="expdate">{t('profile.card_type')}{' '}<i class="bi bi-credit-card"></i><span style={{ color: 'red' }}> *</span></label>
                      <select type="text" id="expdate" name='card_type'
                        onChange={handleCardChange} value={cardData?.card_type}
                        required style={{ border: '1px solid #eee' }} >
                        <option value='' selected disabled>-</option>
                        <option value={'1'}>Credit Card</option>
                        <option value={'2'}>Debit Card</option>
                        <option value={'3'}>Prepaid Card</option>
                        <option value={'4'}>Visa</option>
                        <option value={'5'}>Master Card</option>
                        <option value={'6'}>Discover</option>
                      </select>
                      <span style={{ color: "red" }}>{cardError.card_type}</span>
                    </div>
                  </div>
                  <form class="form-card">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-control-placeholder p-0" for="number">{t('profile.card_holders_name')}<span style={{ color: 'red' }}> *</span></label>
                          <input type="text"
                            class=""
                            placeholder={t('profile.card_holders_name')}
                            name='card_holder_name'
                            onChange={handleCardChange}
                            value={cardData?.card_holder_name}
                            required
                            style={{ border: '1px solid #eee' }}
                          />
                          <span style={{ color: "red" }}>{cardError.card_holder_name}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="form-group">
                          <label class="form-control-placeholder p-0" for="name">{t('profile.card_number')}<span style={{ color: 'red' }}> *</span></label>
                          <input
                            maxLength={19}
                            type="text"
                            className='numberType'
                            placeholder="****  ****  ****  **** "
                            name='card_number'
                            onChange={handleCardChange}
                            value={cardData.card_number.trim()}
                            required />
                          <span style={{ color: "red" }}>{cardError.card_number}</span>
                        </div>
                      </div>
                    </div>
                    <div class="row pb-30 mt-2">
                      <div class="col-sm-4 col-12 col-lg-4">
                        <div class="form-group">
                          <label class="form-control-placeholder p-0" for="sdate">{t('profile.expiry_month')}<span style={{ color: 'red' }}> *</span></label>
                          <select required type="text" class="" id="sdate" style={{ border: '1px solid #eee' }}
                            name='card_expiry_month'
                            onChange={handleCardChange}
                            value={cardData?.card_expiry_month}
                          >
                            <option value='' selected disabled>-</option>
                            <option value={'1'}>Jan</option>
                            <option value={'2'}>Feb</option>
                            <option value={'3'}>Mar</option>
                            <option value={'4'}>Apr</option>
                            <option value={'5'}>May</option>
                            <option value={'6'}>Jun</option>
                            <option value={'7'}>July</option>
                            <option value={'8'}>Aug</option>
                            <option value={'9'}>Sep</option>
                            <option value={'10'}>Oct</option>
                            <option value={'11'}>Nov</option>
                            <option value={'12'}>Dec</option>
                          </select>
                          <span style={{ color: "red" }}>{cardError.card_expiry_month}</span>
                        </div>
                      </div>
                      <div class="col-sm-4 col-12 col-lg-4 mt-2 mt-md-0">
                        <div class="form-group">
                          <label class="form-control-placeholder p-0" for="expdate">{t('profile.expiry_year')}<span style={{ color: 'red' }}> *</span></label>
                          <input
                            type="text"
                            placeholder="YYYY"
                            maxLength={4}
                            onChange={handleCardChange}
                            className='numberType'
                            name='card_expiry_year'
                            value={cardData?.card_expiry_year}
                            required />
                          <span style={{ color: "red" }}>{cardError.card_expiry_year}</span>
                        </div>
                      </div>
                      <div class="col-sm-4 col-12 col-lg-3">
                        <div class="form-group">
                          <label class="form-control-placeholder p-0" for="passw">CVV<span style={{ color: 'red' }}> *</span></label>
                          <br className={`d-none ${i18n.language === 'frFR' ? 'd-lg-block' : 'd-lg-none'} `} /> <br className={`d-none ${i18n.language === 'frFR' ? 'd-lg-block' : 'd-lg-none'} `} />
                          <input
                            maxLength={3}
                            type="password"
                            onChange={handleCardChange}
                            name='cvv'
                            placeholder='* * *'
                            id="passw"
                            value={cardData?.cvv}
                            required />
                          <span style={{ color: "red" }}>{cardError.cvv}</span>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </Form>
        </Modal.Body>
        <Modal.Footer >
          <Button variant="secondary"
            onClick={handleClose}
          >
            {t('close')}
          </Button>
          <Button variant="primary"
            onClick={handleCardSubmit}
          >
            {t('save')}
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

