const privacyPolicy={
 title:'Politique de confidentialité',
 applicable_from:'Applicable à compter du 1er décembre  2023',
 preamble:'PREAMBULE',
 this_confedentially_policy_informs:"La présente politique de confidentialité vous informe sur la manière dont Cobagage utilise et protège les informations que vous nous transmettez, le cas échéant, lorsque vous utilisez le présent site accessible à partir de l’URL suivante :  www.cobagage.com (ci-après le « Site »). Veuillez noter que cette politique de confidentialité est susceptible d’être modifiée ou complétée à tout moment par Cobagage, notamment en vue de se conformer à toute évolution légale ou technologique. Dans un tel cas, la date de sa mise à jour sera clairement identifiée en tête de la présente politique. Ces modifications vous engagent dès leur mise en ligne. Il convient par conséquent que vous consultiez régulièrement la présente politique de confidentialité afin de prendre connaissance de ces éventuelles modifications.",
 article1_particle:'ARTICLE 1.	PARTIES',
 this_confidential_policy_applicable_between:'La présente politique de confidentialité est applicable entre l’éditeur du Site, ci-après ',
 cobagage:'« Cobagage »,',
 any_person_connecting:'et toute personne se connectant au Site, ci-après',
 the_user:'« l’Utilisateur ».',
 site_content:"« Contenus du Site » :",
 element_of_any_kind:'éléments de toute nature publiés sur le Site, protégés ou non par un droit de propriété intellectuelle, tel que textes, images, designs, présentations, vidéos, schémas, structures, bases de données ou logiciels.',
 user:"« Utilisateur » :",
 any_person_to_site:"toute personne se connectant au Site.",
 site:'« Site » ',
 website_accessible:'site internet accessible à l’URL www.cobagage.com, ainsi que les sous-sites, sites miroirs, portails et variations d’URL y afférant.',
 article3:'ARTICLE 3. CHAMP D’APPLICATION',
 browsing_the_site_will_constitute:'La présente politique de confidentialité est applicable à tout Utilisateur. La simple connexion et/ou navigation sur le Site emportera votre acceptation pleine et entière de la présente politique de confidentialité. Par ailleurs, le fait de cliquer sur « J’accepte » dans le bandeau d’information relatif aux cookies affiché sur le Site emporte votre confirmation de cette acceptation, tout en vous permettant de personnaliser les cookies qui vous seront ou non appliqués. Vous reconnaissez du même fait en avoir pris pleinement connaissance et les accepter sans restriction.',
 the_user_recognizes:"L’Utilisateur reconnaît la valeur de preuve des systèmes d'enregistrement automatique de Cobagage et, sauf pour lui d'apporter preuve contraire, il renonce à les contester en cas de litige.",
 acceptance_of_this_confedential_policy:"L'acceptation de la présente politique de confidentialité suppose de la part des Utilisateurs qu'ils jouissent de la capacité juridique nécessaire pour cela ou qu’ils aient au moins 16 ans, ou à défaut qu'ils en aient l'autorisation d'un tuteur ou d'un curateur s'ils sont incapables, de leur représentant légal s'ils ont moins de 16 ans, ou encore qu'ils soient titulaires d'un mandat s'ils agissent pour le compte d'une personne morale.",
 article4:'ARTICLE 4. DONNEES PERSONNELLES',
 in_accordance_with_general_data:'Conformément au Règlement général sur la protection des données (RGPD) adopté par le Parlement européen le 14 avril 2016 et à la législation nationale en vigueur, Cobagage vous fournit les informations suivantes :',
 identy_of_data_controller:'Identité du responsable du traitement',
 the_person_responsible_for_connection_of:'Le responsable de la collecte et des données traitées sur le Site est Cobagage, 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, téléphone : +336 75 68 10 28,email : lyao@cobagage.com',
 identy_of_data_protection_officer:'Identité du Délégué à la protection des données',
 the_data_protection_delegates:"Le délégué de la protection des données est Maître Etienne Deshoulières, 121 boulevard de Sébastopol, 75002 Paris, adresse email : contact@deshoulieres-avocats.com, numéro de téléphone : 01 77 62 82 03. ",
 data_collection_by_cobagage:'Collecte de données par Cobagage',
 data_collected:"Données collectées ",
 data_collection_during_navigation:'Données collectées lors de la navigation et par l’intermédiaire des cookies',
 user_consent_to:"Lors de la navigation sur le Site, l’Utilisateur consent à ce que Cobagage collecte les informations relatives : à l’utilisation du Site ; au contenu qu’il consulte et sur lequel il clique ; à ses données démographiques ; à l'appareil utilisé et à son environnement logiciel ; à sa localisation ; à ses données de connexion (horaires, pages consultées, adresse IP…) ; aux pages web visitées avant ou après l’utilisation du Site.Pour toute information complémentaire relative à la configuration et à l’utilisation des cookies, nous vous invitons à vous référer à notre politique cookies www.cobagage.com/cookies ",
 data_collected_while_creating_user_account:'Données collectées lors de la création du compte utilisateur',
 as_part_of_user_registration:'Dans le cadre de l’inscription de l’Utilisateur sur le Site, Cobagage peut être amené à collecter et traiter : nom, prénom, date de naissance, adresse e-mail, adresse postale, numéro de téléphone, informations bancaires. ',
 user_who_do_not_wish_to_provide:'Les Utilisateurs ne souhaitant pas fournir les informations requises pour la création d’un compte Utilisateur ne pourront pas demander une inscription sur le Site.',
 data_collected_using_contact_form:'Données collectées lors de l’utilisation du formulaire de contact',
 the_use_of_contact_form:'L’utilisation du formulaire de contact par l’Utilisateur suppose la collecte par Cobagage des données personnelles suivantes : nom, prénom, date de naissance, numéro de téléphone, adresse e-mail, adresse IP, ainsi que toute information volontairement transmise par l’Utilisateur (champ libre). ',
 data_collected_when_using_newsletter_form:'Données collectées lors de l’utilisation du formulaire de newsletter',
 as_part_of_use_of_newsletter:'Dans le cadre de l’utilisation du formulaire de newsletter, Cobagage peut être amené à collecter et traiter : votre adresse mail. ',
 purpose_of_collecting_personal_data:'Finalités de la collecte de données personnelles',
 data_collected_via_cookies:"Les données collectées lors de la navigation et par l’intermédiaire des cookies font l'objet d'un traitement automatisé ayant pour finalité de :",
 verify_identy_of_users:"Vérifier l'identité des Utilisateurs ;",
 ensure_and_improve:'Assurer et améliorer la sécurité du Site ;',
 develops_oprates_improve:'Développer, exploiter, améliorer, fournir et gérer le Site ;',
 send_information_and_contact_the_user:'Adresser des informations et contacter l’Utilisateur, y compris par e-mail, téléphone et notification push ;',
 share_content_from_site:"Partager des contenus du Site avec d'autres personnes ou de faire connaître à ces autres personnes sa consultation ou son opinion, via les boutons de partages des réseaux-sociaux ;",
 improve_user_experience:'Contextualiser et améliorer l’expérience de l’Utilisateur ;',
 target:'Cibler les contenus publicitaires notamment en transmettant l’adresse IP et l’URL des pages visitées aux réseaux sociaux ;',
 avoid_any_illicit_or_illegal:'Eviter toute activité illicite ou illégale ;',
 enforce_the_condition:"Faire respecter les conditions relatives à l'utilisation du Site.",
 automate_processing_with_aim_of:"Les données collectées lors de la création du compte utilisateur font l'objet d'un traitement automatisé ayant pour finalité de :",
 verify_identity_of_people:'Vérifier l’identité des personnes ; ',
 communicate_with_people:'Communiquer avec les personnes ;',
 proceed_with_user_registration:'Procéder à l’inscription des Utilisateur et réaliser notre prestation de services ;',
 ensure_and_improve_service:'Assurer et améliorer la sécurité des services fournis ;',
 share_the_content_from_site_with_other_people:'Partager des contenus du Site avec d’autres personnes ou faire connaître à ces autres personnes sa consultation ou son opinion ; ',
 contact_form_proceeded_for_the_purpose_of:'Les données collectées lors de l’utilisation du formulaire de contact font l’objet d’un traitement ayant pour finalité de :',
 data_collected_when_subscribing:'Les données collectées lors de l’inscription à la newsletter font l’objet d’un traitement ayant pour finalité de : ',
 communicate_about_new_features:'Communiquer sur les nouvelles fonctionnalités du Site ;',
 communicate_about_cobagae_product:'Communiquer sur les produits et services Cobagage ; ',
 legal_bases_for_processing:'Bases juridiques du traitement',
 data_collected_during_navigation_via_cookies:"Les données collectées lors de la navigation et par l’intermédiaire des cookies ont pour base juridique l’intérêt légitime de Cobagage, à savoir réaliser une analyse des comportements sur le Site et obtenir une sécurité et un fonctionnement améliorés du Site. Lorsque ces données sont issues de cookies, elles peuvent avoir pour base juridique le consentement des personnes. Les données collectées lors de l’utilisation du formulaire de contact ont pour base juridique le consentement des personnes concernées.",
 data_collected_during_creation_of_user_account:'Les données collectées lors de la création du compte Utilisateur ont pour base juridique une relation contractuelle.',
 data_collected_legal_basis:'Les données collectées lors de l’utilisation du formulaire de newsletter ont pour base juridique le consentement des personnes concernées.',
 data_recipent:'Destinataires des données',
 data_collected_can_only_be_viewed:'Les données collectées sont consultables uniquement par Cobagage, et ne sont jamais rendues librement visualisables par une personne physique tierce.',
 duration_of_retention_of_personal_data:'Duration of retention of personal data',
 personal_data_collected_during_navigation_kept_reasonable_period:"Les données personnelles collectées lors de la navigation et via les cookies sont conservées pendant une durée raisonnable nécessaire à la bonne administration du Site et pendant une durée maximale de 13 mois, ou jusqu'au retrait du consentement des personnes concernées. Les données personnelles collectées lors de l'utilisation du formulaire de contact sont conservées pendant une durée raisonnable nécessaire à la bonne gestion de la demande de l'Utilisateur, et pour une durée maximale de 12 mois.",
 data_collected_during_user_creation_kept_duration:'Les données collectées lors de la création du compte Utilisateur sont conservées pendant la durée de la relation contractuelle entre Cobagage et l’Utilisateur, et pendant la durée durant laquelle la responsabilité de Cobagage peut être engagée.',
 data_collected_from_newssletter_kept_until:'Les données collectées lors de l’utilisation du formulaire de newsletter sont conservées jusqu’au retrait du consentement des personnes concernées. Passés ces délais de conservation, Cobagage s’engage à supprimer définitivement les données des personnes concernées.',
 security_and_confidentiality_of_personal_data:'Sécurité et confidentialité des données personnelles',
 personal_data_is_stored_in_secure_condition:'Les données personnelles sont conservées dans des conditions sécurisées, selon les moyens actuels de la technique, dans le respect des dispositions du Règlement général pour la protection des données et de la législation nationale en vigueur.',
 minimization_of_personal_data:'Minimisation des données personnelles',
 cobagage_may_also_collect_and_process:'Cobagage peut également collecter et traiter toute donnée transmise volontairement par un Utilisateur, notamment via l’utilisation de l’adresse email de contact. ',
 cobagage_guides_users_as_much_as_possible:'Cobagage oriente les Utilisateurs autant que possible lorsqu’ils fournissent des données à caractère personnel inutiles ou superflues.',
 cobagage_undertakes_to_only:'Cobagage s’engage à ne conserver et traiter que les données strictement nécessaires à ses activités, et supprimera toute donnée reçue non utile dans les plus brefs délais.',
 respect_for_rights:'Respects des droits',
 you_have_the_following_right:'Vous disposez des droits suivants concernant vos données personnelles, que vous pouvez exercer en nous écrivant à notre adresse postale ou en remplissant notre formulaire contact en ligne.',
 right_to_information:'Droit d’information, d’accès et de communication des données',
 you_have_the_possiblity_of_accessing:"Vous avez la possibilité d’accéder aux données personnelles qui vous concernent.En raison de l’obligation de sécurité et de confidentialité dans le traitement des données à caractère personnel qui incombe à Cobagage, votre demande sera uniquement traitée si vous rapportez la preuve de votre identité, notamment par la production d’un scan de votre titre d’identité valide (en cas de demande par notre formulaire électronique dédié) ou d’une photocopie signée de votre titre d’identité valide (en cas de demande adressée par écrit), tous deux accompagnés de la mention « j’atteste sur l’honneur que la copie de cette pièce d’identité est conforme à l’original. Fait à … le … », suivie de votre signature.Pour vous aider dans votre démarche, vous trouverez ici un modèle de courrier élaboré par la Cnil.",
 right_torectification:'Droit de rectification, de suppression et droit à l’oubli des données',
 you_have_the_possiblity_of_requesting:'Vous avez la possibilité de demander la rectification, la mise à jour, le verrouillage ou encore l’effacement de vos données personnelles qui peuvent s’avérer le cas échéant inexactes, erronées, incomplètes ou obsolètes.',
 you_can_also_define_a_general:'Vous pouvez également définir des directives générales et particulières relatives au sort des données à caractère personnel après votre décès. Le cas échéant, les héritiers d’une personne décédée peuvent exiger de prendre en considération le décès de leur proche et/ou de procéder aux mises à jour nécessaires.',
 to_help_you_in_your_process:'Pour vous aider dans votre démarche, vous trouverez ici un modèle de courrier élaboré par la Cnil.',
 right_to_object_data_processing:'Droit d’opposition au traitement de données',
 you_have_the_possiblity_to_object_the_processing_of:'Vous avez la possibilité de vous opposer à un traitement de vos données personnelles. Pour se faire, il vous conviendra d’adresser un email à l’adresse suivante : contact@cobagage.fr. Dans ce mail, il vous faudra préciser les données que vous souhaitez voir supprimer ainsi que les raisons justifiant cette demande, sauf cas de prospection commerciale. ',
 right_to_data_portablity:'Droit à la portabilité des données',
 you_have_the_right_to_receive_personal_data:'Vous avez le droit de recevoir les données personnelles que vous nous avez fournies dans un format transférable, ouvert et lisible. ',
 right_to_restriction_of:'Droit à la limitation du traitement',
 you_have_right_that_requesting_processing_of_you:'Vous avez le droit de demander que le traitement de vos données personnelles par Cobagage soit limité. Ainsi, vos données ne pourront qu’être conservées et non plus utilisées par Cobagage.',
 reply_duration:'Délais de réponse ',
 cobagage_undertakes_to_respond:'Cobagage s’engage à répondre à votre demande d’accès, de rectification ou d’opposition ou toute autre demande complémentaire d’informations dans un délai raisonnable qui ne saurait dépasser 1 mois à compter de la réception de votre demande.',
 complaint_to_the_competent_authoruty:'Plainte auprès de l’autorité compétente',
 if_you_consider_that_cobagage_is_not_responding:'Si vous considérez que Cobabage ne respecte pas ses obligations au regard de vos informations personnelles, vous pouvez adresser une plainte ou une demande auprès de l’autorité compétente. En France, l’autorité compétente est la Cnil à laquelle vous pouvez adresser une demande ici.',
 transfer_of_collected_data:'Transfert des données collectées ',
 transfer_to_partners:'Transfert à des partenaires ',
 cobagage_informs_that_we_use_authorized:'Cobagage vous informe que nous avons recours à des prestataires habilités pour faciliter le recueil et le traitement des données que vous nous avez communiquées. ',
 cobagage_has_previously_ensured_the_implementation:'Cobagage s’est préalablement assuré de la mise en œuvre par ses prestataires de garanties adéquates et du respect de conditions strictes en matière de confidentialité, d’usage et de protection des données.',
 our_site_uses_social_media_plugin:"Notre Site utilise des plug-ins de réseaux sociaux (cf. tableau ci-dessous). Lorsqu’un plugin est intégré à une page du site, le logo du réseau social apparaît sur la page. Lorsque vous vous rendez sur une page du Site contenant un plug-in, nous transmettons votre adresse IP et l'adresse URL de la page visitée sur le Site au réseau social concerné.",
 user_consent_to_data_collected:'L’Utilisateur consent à ce que les données collectées soient transmises par Cobagage à ses partenaires et fassent l’objet d’un traitement par ces partenaires dans le cadre des services tiers, à savoir :',
 partner_directory:'Répertoire des Partenaires',
 partner:'Partenaire',
 quality:'Qualité',
 recipent_country:'Pays destinataire',
 treatment_carried_out:'Traitement réalisé',
 garanties:'Garanties',
 sub_contractor:'Sous-traitant',
 web_hosting:'Hébergement de site web',
 sub_contractor_and_co_contractor:'Sous-traitant et co-traitant',
 management_of_people:'Gestion des avis des personnes sur des produits, services ou contenus.',
 development_of_commercial:'Elaboration de statistiques commerciales afin de générer des rapports sur les interactions des Utilisateurs',
 co_responsable:'co_responsable',
 video_hosting:'Hébergement de vidéos.',
 digital_marketing_and_web_development:'Marketing Digital et développement web. ',
 transfer_upon_requsition_or_juditial_decesion:'Transfert sur réquisition ou décision judiciaire',
 the_user_is_also_consent_to:'L’Utilisateur consent également à ce que Cobagage communique les données collectées à toute personne, sur réquisition d’une autorité étatique ou sur décision judiciaire. ',
 transfer_in_context_of_merger:"Transfert dans le cadre d'une fusion ou d'une acquisition",
 intellectual_property:'PROPRIETE INTELLECTUELLE',
 legal_protection_of_site:'Protection légale des Contenus du Site',
 the_content_of_site_may_be_protected_by:"Les Contenus du Site sont susceptibles d'être protégés par le droit d'auteur et le droit des bases de données. Toute représentation, reproduction, traduction, adaptation ou transformation, intégrale ou partielle, réalisée illégalement et sans le consentement de Cobagage ou de ses ayants droit ou ayants cause constitue une violation des Livres I et III du Code de la propriété intellectuelle et sera susceptible de donner lieu à des poursuites judiciaires pour contrefaçon",
 contractual_protection_of_software_content:'Protection contractuelle des Contenus du Logiciel',
 the_user_undertakes_contactually_with_cobagage:"L’Utilisateur s'engage contractuellement à l'égard de Cobagage à ne pas utiliser, reproduire ou représenter, de quelque manière que ce soit, les Contenus du Site, qu'ils soient ou non protégés par un droit de propriété intellectuelle, à une autre fin que celle de leur lecture par un robot ou un navigateur. Cette interdiction n'est pas applicable aux robots d'indexation ayant pour seul objet de scanner le contenu du Site aux fins d'indexation.",
 final_stipulation:'STIPULATIONS FINALES',
 this_privacy_policy_modified_at_any_time:'La présente Politique de Confidentialité peut être modifiée à tout moment par Cobagage. Les conditions applicables à l’Utilisateur sont celles en vigueur lors de sa connexion sur le Logiciel, toute nouvelle connexion au Logiciel emportant acceptation le cas échéant des nouvelles conditions. ',
 entirely:'Entièreté',
 the_nulity_of_one_of_the_clauses:"La nullité d'une des clauses du présent contrat n'entraînera pas la nullité des autres clauses du contrat ou du contrat dans sa globalité, qui garderont leur plein effet et portée. Dans une telle hypothèse, les parties devront dans la mesure du possible remplacer la stipulation annulée par une stipulation valable correspondant à l'esprit et à l'objet des présentes.",
 non_waiver:'Non-renonciation',
 the_absence_of_exercise_by:"L'absence d'exercice par Cobagage des droits qui lui sont reconnus par les présentes ne pourra en aucun cas être interprétée comme une renonciation à faire valoir lesdits droits.",
 LANGUAGES:'Langues',
 this_privacy_policy_available_in_french:'La présente politique de confidentialité est proposée en français.',
 unfair_clauses:'Clauses abusives',
 the_stipulity_of_confedentiality:"Les stipulations de la présente politique de confidentialité s'appliquent sous réserve du respect des dispositions impératives du Code de la consommation concernant les clauses abusives dans les contrats conclus entre un professionnel et un consommateur.",
 disputes:'LITIGES',
 applicable_right:'Droit applicable',
 privacy_policy_subjected_to:"La présente Politique de Confidentialité est soumise à l'application du droit français et de la règlementation européenne, notamment le Règlement européen sur la protection des données.",
 Litiges:'Litiges',
 pursuant_to_order_no:"En vertu de l’ordonnance n°2015-1033 du 20 août 2015, tous litiges qui pourraient survenir dans le cadre de l'exécution de la présente politique de confidentialité et dont la solution n’aura pu être trouvée préalablement à l’amiable entre les parties devra être soumis à Medicys",
 arbitration:'Arbitrage',
 any_dispute_related_to_this:'Tout litige relatif au présent contrat ou en relation avec celui-ci sera tranché par voie d’arbitrage conformément au règlement FastArbitre de l’Institut digital d’arbitrage et de médiation.'
}
export default privacyPolicy;