import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import StripPaymentDetails from './stripPaymentDetails';
import { useTranslation } from 'react-i18next'

export default function StripPayment() {
    const { t } = useTranslation()
    return (
        <div>
            <Breadcrumb name={t('profile.Payments')} />
            <StripPaymentDetails />
        </div>
    )
}
