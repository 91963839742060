import React from 'react'
import { useTranslation } from 'react-i18next'

export default function InternationalWrapp() {
    const { t } = useTranslation()
    return (
        <div className='container'>
            <div className='pt-110  pb-110'>
                <h1 style={{ fontWeight: 'bold' }} className='text-center '>{t('homePage.international_shipment')}</h1>
                <p className='pt-4 fs-5'>
                    {t('form.Faire_voyageurs')}
                </p>
                <p className='fs-5 pt-2'>{t('form.La_société')} <strong>{t('form.réduire_C02')}</strong>{t('form.par_cobagage')}</p>
                <h2 className='mt-5' style={{ fontWeight: 'bold' }}>{t('form.Pourquoi_C02')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Lorsque_avion')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Il_trajets')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Pour_transporter')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Lavantage_transport')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.La_envisagées')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Ainsi_trottinette')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Tous_de')} <strong>{t('form.earn_recipient')}</strong>.
                </p>
                <h2 className='mt-5' style={{ fontWeight: 'bold' }}>{t('form.Faire_cobagage')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Gagner_site_Cobagage')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Il_coûteux')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Calling_cost')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.De_par')} <strong>{t('form.le_transport')}</strong>.
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Par_bagages')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_prix_HT')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Le_HTcommission')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Une_côté_tarifs')}
                </p>
                <h2 className='mt-5' style={{ fontWeight: 'bold' }}>{t('form.Les_conditions')}</h2>
                <p className='fs-5 pt-2'>
                    {t('form.Les_envois')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Néanmoins_confiscation')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.En_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    {t('form.Les_destinataire')}
                </p>
                <p className='fs-5 pt-2'>
                    <strong>{t('form.L_international')}</strong> {t('form.n_Cobagage')}
                </p>
            </div>
        </div>
    )
}
