import React from "react";
import LuggageCancelledWrapperArea from "./LuggageCancelledWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function LuggageCancelled() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t('profile.luggage_carrying_order_detailes_cancel')}/>
      <LuggageCancelledWrapperArea />
    </>
  );
}

export default LuggageCancelled;
