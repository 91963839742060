import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import AppContext from '../../../store/context/app-context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';


export default function BanerMobile({ setopen }) {
    const { t } = useTranslation();
    const contextObj = useContext(AppContext);
    const { i18n } = useTranslation();
    const history = useHistory();
    const addTraveller = (radioData) => {
        contextObj.setTabValue(0)
        contextObj.setRadioValue(radioData);
        history.push("/myProfile");
    }
    return (
        <>
            <div className="pt-lg-4"
                style={{
                    backgroundImage: 'url("/images/vehicles/mobilehomePage.jpg")',
                    backgroundRepeat: "no-repeat",
                    backgroundSize: 'cover',
                    height: "750px"
                }}
            >
            </div>
            <div className='container'
                style={{
                    backgroundColor: "rgb(176 108 108 / 37%)",
                    marginTop: "-424px"
                }}
            >
                <div className='col-12 mb-2'>
                    <h2 style={{ color: "white", fontStyle: "italic" }}>{t('profile.I_TRAVELLER')}</h2>
                </div>
                <div className='col-12 row'>
                    <div className='col-6' style={{ color: "white", fontStyle: "italic", fontSize: "14px" }}>
                        {/* {t('profile.TRANSPORT_LUGGAGES_MONEY')} */}
                    </div>
                    <div className='col-6 mb-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            onClick={() => contextObj.login == true ? addTraveller(1) : history.push({ pathname: "/login" })}
                            style={{ borderRadius: "7px", fontSize: "14px", width: "175px", color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Sell_luggages')}
                        </button>
                    </div>
                    <div className='col-6 mb-2 d-flex align-items-center' style={{ color: "white", fontStyle: "italic", fontSize: "14px" }}>
                        {/* {t('profile.SELL_ARTICLES')} */}
                    </div>
                    <div className='col-6 mb-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => (addTraveller(3))}
                            onClick={() => contextObj.login == true ? addTraveller(3) : history.push({ pathname: "/login" })}
                            style={{ borderRadius: "7px", fontSize: "14px", width: "175px", color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Sell_article')}
                        </button>
                    </div>
                    <div className='col-6 mb-2 d-flex align-items-center' style={{ color: "white", fontStyle: "italic", fontSize: "14px" }}>
                        {/* {t('profile.DONATE_ARTICLES')} */}
                    </div>
                    <div className='col-6 mb-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => (addTraveller(4))}
                            onClick={() => contextObj.login == true ? addTraveller(4) : history.push({ pathname: "/login" })}
                            style={{ borderRadius: "7px", fontSize: "13px", width: "175px", color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Post_donation')}
                        </button>
                    </div>
                </div>
                <hr
                    style={{ color: "white", height: "4px" }}
                ></hr>
                <div className='col-12 mb-2'>
                    <h2 style={{ color: "white", fontStyle: "italic" }}>
                        {t('profile.I_SEND_LUGGAGES')}
                    </h2></div>
                <div className='col-12 row'>
                    <div className='col-6'>
                        {/* <p style={{ color: "white", fontStyle: "italic", fontSize: "14px" }}>{t('profile.SEND_TRAVELLERS_COST')}</p> */}
                    </div>
                    <div className='col-6'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => (addTraveller(2))}
                            onClick={() => contextObj.login == true ? addTraveller(2) : history.push({ pathname: "/login" })}
                            style={{ borderRadius: "7px", fontSize: "13px", width: "175px", color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Post_details')}
                        </button>
                    </div>
                    <div className='col-6 mt-3' style={{ marginLeft: "-20px" }}>
                        <img
                            src={"/images/vehicles/cardsEnglish.png"}
                            style={{ aspectRatio: "4/2", objectFit: "contain", width: "75%", height: "75%" }}
                        >
                        </img>
                    </div>
                    <div className='col-6'>
                        <button
                            className="nav-link active"
                            type="button"
                            onClick={() => history.push("/home")}
                            style={{
                                marginTop: "15px",
                                fontSize: "14px",
                                borderRadius: "7px",
                                marginLeft: "19px",
                                width: "175px",
                                color: '#fff',
                                border: '1px solid #00A651',
                                background: "linear-gradient(to bottom, #00A651 0%, #8DC63F 100%)",
                                boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                            }}
                        >
                            {t('profile.Explore_Cobagage')}
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
