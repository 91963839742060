import React from 'react'
import { Swiper, SwiperSlide } from "swiper/react";
import { useTranslation } from 'react-i18next';
import BanerMobile from './BanerMobile';
import BanerPage from './BanerPage';
import BanerIpad from './BanerIpad';
import HeaderFour from './HeaderFour';
import Footer from '../../common/Footer';

export default function NewHomePage({ setopen }) {
    const { t } = useTranslation();

    return (
        <>
            <HeaderFour />
            <div className='d-lg-block d-none'>
                <BanerPage/>
            </div>
            <div className='d-block d-lg-none d-sm-none'>
                <BanerMobile/>
            </div>
            <div className='d-none d-lg-none d-sm-block'>
                <BanerIpad/>
            </div>
            {/* <Footer className="footer-area" /> */}

        </>
    )
}
