import React, { useContext, useEffect, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { Modal, Button, Form } from "react-bootstrap";
import AppContext from "../../../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import request from "../../../../../models/request";
import luggage from "../../../../../models/luggage";
import useValidator from "../../../../../plugin/validator";
import notistack from "../../../../../plugin/notistack";
import { Rating } from "react-simple-star-rating";

function LuggageCarryingWrapperArea() {
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const { t } = useTranslation();
  const { id, request_type, sent, order, product_id } = useParams();
  console.log("request_type", request_type);
  console.log("id", id);
  const [LuggageRequest, setLuggageRequest] = useState();
  const [luggageData, setLuggageData] = useState();
  const [show, setShow] = useState(false);
  const [open, setIsOpen] = useState();
  const [validator, showMessage] = useValidator();
  const [otpValidator, showMessageOtp] = useValidator();
  const [data, setData] = useState({
    request_id: id,
    request_type: request_type,
    status: "",
    reason_to_cancel: "",
    cancel_by: "2",
    Delivery_otp: "",
    traveler_signature: "",
    cancel_by_pending: 1,
  });
  const error = {
    reason_to_cancel: validator.message(
      "Reason to Reject",
      data.reason_to_cancel,
      (open == 1 ? "required|" : "") + "string|max:200"
    ),
  };
  const OtpError = {
    Delivery_otp: otpValidator.message(
      "OTP",
      data.Delivery_otp,
      (order == 2 ? "required|" : "") + "string|max:200"
    ),
  };
  useEffect(() => {
    sent === "1" ? requestReceived() : requestISent();
    !sent && request_type == 1 ? requestReceived() : requestISent();
  }, []);

  const requestReceived = () => {
    request
      .getRequestReceived(id, request_type)
      .then((item) => {
        setLuggageRequest(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
    luggage
      .get(request_type)
      .then((item) => {
        setLuggageData(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const requestISent = () => {
    request
      .get(id, request_type)
      .then((item) => {
        setLuggageRequest(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
    luggage
      .get(request_type)
      .then((item) => {
        console.log("item", item);
        setLuggageData(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const cancleOrder = () => {
    if (validator.allValid()) {
      request
        .cancle(data)
        .then((item) => {
          order == 1 && handleSetData("cancel_by_pending", 2);
          setIsOpen(2);
        })
        .catch((error) => {
          notistack.error(error?.response?.data?.message);
          console.log("error", error);
        });
    } else {
      showMessage(true);
    }
  };
  const handleSignature = () => {
    request
      .uploadSignature(data?.request_id, data?.request_type)
      .then((response) => {
        if (LuggageRequest?.payment_status == true) {
          handleSetData("traveler_signature", true);
        }
      })
      .catch((error) => {
        notistack.error(error.response.data.message);
        console.log(error);
      });
  };
  const handleOtp = () => {
    if (otpValidator.allValid()) {
      request
        .otpVerify(data?.Delivery_otp, data?.request_id, data?.request_type)
        .then((response) => {
          handleShow();
          setIsOpen(3);
          tabPanelValue();
        })
        .catch((error) => {
          notistack.error(error?.response?.data?.message);
        });
    } else {
      showMessageOtp(true);
    }
  };

  // const handleResendOtp = () => {
  //     request
  //       .resendOtp(id, request_type)
  //       .then((response) => {
  //         notistack.success("kkkkk")
  //       })
  //       .catch((error) => {
  //         notistack.error(error?.response?.data?.message);
  //       });
  // };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const handleClose = () => {
    setShow(false);
    handleSetData("reason_to_cancel", "");
    showMessage(false);
  };
  const handleShow = () => setShow(true);
  const tabPanelValue = () => {
    if (order == 1) {
      contextObj.setTabValue(6);
      history.push("/myProfile");
    } else {
      contextObj.setTabValue(7);
      history.push("/myProfile");
    }
  };
  console.log("luggageData", LuggageRequest);
  return (
    <div className="container">
      <div class="p-4">
        <Button
          style={{
            backgroundColor: "#ff4838",
            borderColor: "#ff4838",
            borderRadius: "5px",
            border: "0px",
            color: "white",
            marginLeft: "-11px",
          }}
          onClick={() => tabPanelValue()}
        >
          <i class="bi bi-arrow-left-circle" />{" "}
          {order == "3"
            ? t("profile.Back_to_pending_luggage_sending_orders_list")
            : t("profile.back_to_luggage_carrying_order_list")}
        </Button>
        {order == 2 && (
          <div>
            <div className="row">
              <div
                className="rounded-2 mt-2"
                style={{ backgroundColor: "#ec808d" }}
              >
                <h5 className="pt-2 pb-3 fs-6" style={{ color: "white" }}>
                  <i class="bi bi-exclamation-triangle-fill fs-4" />{" "}
                  {t("profile.please_check_luggage_content_and_upload")}
                </h5>
              </div>
            </div>
            <h5 className="fw-normal fs-6 p-2" style={{ color: "#d9001b" }}>
              {t("profile.please_note_after_you_upload_signature")}
            </h5>
            <div className="row pb-3">
              <div className="col-12 col-lg-3 col-md-6 p-1">
                <h6 className="text-decoration-underline pb-2">
                  {t("profile.luggage_sender_signature")}
                </h6>
                {LuggageRequest?.sender_signature && (
                  <div className="package-sidebar">
                    <aside
                      className="package-widget widget-tour-categoris"
                      style={{ boxShadow: "none" }}
                    >
                      <div className="widget-body p-0">
                        <div className="d-flex">
                          <div
                            class="digital"
                            style={{
                              border: "1px solid",
                              borderRadius: "20px",
                            }}
                          >
                            {" "}
                            <svg class="animated-check" viewBox="0 0 24 24">
                              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
                            </svg>
                          </div>
                        </div>
                      </div>
                    </aside>
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-3 col-md-6 p-1">
                <h6 className="text-decoration-underline pb-2">
                  {t("profile.traveller_signature")}
                </h6>
                {data?.traveler_signature == "" &&
                  LuggageRequest?.traveler_signature == false && (
                    <div class="mt-2">
                      <button
                        type="button"
                        style={{ border: "0px", backgroundColor: "#fff" }}
                        onClick={() => {
                          handleSignature();
                          // if (LuggageRequest?.payment_status == true) {
                          //   handleSetData('traveler_signature', true)
                          // }
                        }}
                      >
                        <label
                          for="file"
                          style={{ color: "#1e98d7", fontWeight: "600" }}
                        >
                          {t("profile.Click_Here")}
                        </label>
                      </button>
                    </div>
                  )}
                <div className="package-sidebar">
                  <aside
                    className="package-widget widget-tour-categoris"
                    style={{ boxShadow: "none" }}
                  >
                    <div className="widget-body p-0">
                      <div className="d-flex">
                        {LuggageRequest?.traveler_signature == true ||
                        data?.traveler_signature == true ? (
                          <div
                            class="digital"
                            style={{
                              border: "1px solid",
                              borderRadius: "20px",
                            }}
                          >
                            {" "}
                            <svg class="animated-check" viewBox="0 0 24 24">
                              <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" />{" "}
                            </svg>
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
              <div className="col-12 col-lg-3 col-md-6 p-1">
                <p>{t("profile.please_enter_delivery_code_recieved")}</p>
                <label className="text-decoration-underline fw-bold pb-2">
                  {t("profile.enter_delivery_code")}
                </label>
                <input
                  className="numberType"
                  // type="number"
                  maxLength={6}
                  onChange={handleChange}
                  name="Delivery_otp"
                  placeholder="OTP"
                />
                <span style={{ color: "red" }}>{OtpError.Delivery_otp}</span>
                <br />
                <Button
                  style={{
                    backgroundColor: "#ff4838",
                    borderColor: "#ff4838",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    handleOtp();
                  }}
                >
                  {t("form.submit")}
                </Button>
                {/* <Button
                  style={{
                    backgroundColor: "#ff4838",
                    borderColor: "#ff4838",
                    borderRadius: "5px",
                    marginTop: "10px",
                  }}
                  onClick={() => {
                    handleResendOtp();
                  }}
                >
                  Resend
                   {t('form.submit')} */}
                {/* </Button>  */}
              </div>
              {LuggageRequest?.status === 1 ? (
                LuggageRequest?.traveler_signature == false && (
                  <div className="col-12 col-lg-3 col-md-6 p-1 text-end">
                    {data?.traveler_signature == "" && (
                      <Button
                        style={{
                          borderColor: "#ff4838",
                          borderRadius: "5px",
                          margin: "2px",
                          padding: "10px 20px",
                        }}
                        onClick={() => {
                          handleShow();
                          setIsOpen(1);
                        }}
                      >
                        {t("profile.cancle_order")}
                      </Button>
                    )}
                  </div>
                )
              ) : (
                <div class="col-12 col-lg-3 col-md-6 p-1">
                  <div
                    class="earning "
                    style={{
                      backgroundColor: "white",
                      borderColor: "#ff4838",
                      border: "2px solid",
                    }}
                  >
                    <div class="plan" style={{ background: "white" }}>
                      <header style={{ padding: "11px 10px" }}>
                        <i class="bi bi-currency-dollar1"></i>
                        <h6 class="plan-title" style={{ color: "black" }}>
                          {t("profile.status")}:
                        </h6>
                        <div class="plan-cost">
                          <span
                            class="plan-price fs-6 d-flex align-items-center "
                            style={{
                              color: "red",
                            }}
                          >
                            <i
                              class="bi bi-x-circle "
                              style={{
                                color: "red",
                                fontSize: "30px",
                              }}
                            />
                            {t("profile.rejected")}
                          </span>
                        </div>
                      </header>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        <div className="row pb-110">
          <div className="col-lg-8">
            <div className="row">
              <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                <h6 className="text-decoration-underline pt-3">
                  {t("profile.my_travel_detailes")}
                </h6>
                <div class="earning pt-3">
                  <div class="plan" style={{ background: "white" }}>
                    <table class="table">
                      <tbody class="border-0">
                        <tr>
                          <th class="border-0">
                            {" "}
                            <img
                              src={LuggageRequest?.departure_flag}
                              style={{
                                width: "25px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                            {LuggageRequest?.city_departure_name}
                          </th>
                          <th class="border-0">
                            <i class="bi bi-arrow-right" />
                          </th>
                          <th class="border-0">
                            <img
                              src={LuggageRequest?.arrival_flag}
                              style={{
                                width: "25px",
                                height: "20px",
                                marginRight: "5px",
                              }}
                            />
                            {LuggageRequest?.city_arrival_name}
                          </th>
                        </tr>
                        <tr class="border-0">
                          <td class="border-0 fw-normal">
                            <i
                              class="bi bi-calendar"
                              style={{ color: "#f39c12" }}
                            />{" "}
                            {LuggageRequest?.departure_date} |{" "}
                            {LuggageRequest?.departure_time}
                          </td>
                          <td class="border-0"></td>
                          <td class="border-0 fw-normal">
                            <i
                              class="bi bi-calendar"
                              style={{ color: "#f39c12" }}
                            />{" "}
                            {LuggageRequest?.arrival_date} |{" "}
                            {LuggageRequest?.arrival_time}
                          </td>
                        </tr>
                        <tr class="border-0">
                          <td class="border-0 fw-normal">
                            {" "}
                            {t("profile.departure")}
                          </td>
                          <td class="border-0"></td>
                          <td class="border-0 fw-normal">
                            {" "}
                            {t("profile.arrival")}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="earning">
                  <div class="plan">
                    <header>
                      <i class="bi bi-globe2"></i>
                      <h6 class="plan-title">{t("profile.travelling_by")}:</h6>
                      <div class="plan-cost">
                        <span class="plan-price">
                          {LuggageRequest?.vehicle_type == 1
                            ? t("form.bike")
                            : LuggageRequest?.vehicle_type == 2
                            ? t("form.Boat")
                            : LuggageRequest?.vehicle_type == 3
                            ? t("form.Bus")
                            : LuggageRequest?.vehicle_type == 4
                            ? "Car"
                            : LuggageRequest?.vehicle_type == 5
                            ? t("form.Kick_scooter")
                            : LuggageRequest?.vehicle_type == 6
                            ? t("form.train")
                            : LuggageRequest?.vehicle_type == 7
                            ? t("form.MoterBike")
                            : LuggageRequest?.vehicle_type == 8
                            ? t("form.Flight")
                            : LuggageRequest?.vehicle_type == 9
                            ? t("form.Scooter")
                            : LuggageRequest?.vehicle_type == 10
                            ? t("form.Tgv")
                            : LuggageRequest?.vehicle_type == 11
                            ? t("form.Tramway")
                            : ""}
                        </span>{" "}
                        {LuggageRequest?.vehicle_type != 1 && (
                          <span class="plan-type">
                            {LuggageRequest?.vehicle_details}
                          </span>
                        )}
                      </div>
                    </header>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 fw-bold text-decoration-underline">
                          {t("profile.luggage_detailes")}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.luggage_title")}:
                        </td>
                        <td class="border-0 fw-bold">
                          {request_type == 1
                            ? LuggageRequest?.title
                            : LuggageRequest?.luggage_title}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.type_of_luggage")}:
                        </td>
                        <td class="border-0 fw-bold">
                          {t("profile.used_second_hand")}
                        </td>
                      </tr>
                    </tbody>
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 text-decoration-underline fw-bold">
                          {t("profile.luggage_dimensions")}:
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.height")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.height
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.height
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.length")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.length
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.length
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Width")}</td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.width
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.width
                            ? "inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.weight")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.weight
                          }{" "}
                          KG
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Colour")}</td>
                        <td class="border-0 fw-bold">
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.colour
                            ? (request_type == 1 ? LuggageRequest : luggageData)
                                ?.colour
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">{t("form.Volume")}</td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.volume
                          }{" "}
                          {(request_type == 1 ? LuggageRequest : luggageData)
                            ?.volume
                            ? "cubic inches"
                            : "NA"}
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.current_price_estimate")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.luggage_estimation
                          }{" "}
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.currency
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-lg-6 col-12 col-md-6 col-xl-6">
                <div class="table-responsive">
                  <table class="table table-borderless">
                    <tbody class="border-0">
                      <tr class="border-0">
                        <td class="border-0 text-decoration-underline fw-bold">
                          {t("profile.luggage_receivers_details")}:
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.name_of_receiver")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_name
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.phone")}/{t("profile.contact")} #
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_phone_code
                          }
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_contact_number
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.your_relationship_with_receiver")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.receiver_relation
                          }
                        </td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t(
                            "profile.will_you_be_getting_your_luggage_delivery"
                          )}
                        </td>
                        <td class="border-0 fw-bold">{t("profile.yes")}</td>
                      </tr>
                      <tr class="border-0">
                        <td class="border-0 fw-normal">
                          {t("profile.luggage_location")}
                        </td>
                        <td class="border-0 fw-bold">
                          {
                            (request_type == 1 ? LuggageRequest : luggageData)
                              ?.location
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="row">
              <h4 class="fs-5 pb-4">{t("profile.luggage_images")} :</h4>
              {(request_type == 1
                ? LuggageRequest?.image[0]
                : luggageData?.image[0]) && (
                <div class="col-lg-3 col-md-6">
                  <h6> {t("profile.exterior")} 1</h6>
                  <img
                    src={
                      request_type == 1
                        ? LuggageRequest?.image[0]
                        : luggageData?.image[0]
                    }
                    style={{
                      width: "98%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
              {(request_type == 1
                ? LuggageRequest?.image[1]
                : luggageData?.image[1]) && (
                <div class="col-lg-3 col-md-6">
                  <h6>{t("profile.exterior")} 2</h6>
                  <img
                    src={
                      request_type == 1
                        ? LuggageRequest?.image[1]
                        : luggageData?.image[1]
                    }
                    style={{
                      width: "98%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
              {(request_type == 1
                ? LuggageRequest?.image[2]
                : luggageData?.image[2]) && (
                <div class="col-lg-3 col-md-6">
                  <h6>{t("profile.interior")} 1</h6>
                  <img
                    src={
                      request_type == 1
                        ? LuggageRequest?.image[2]
                        : luggageData?.image[2]
                    }
                    style={{
                      width: "98%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
              {(request_type == 1
                ? LuggageRequest?.image[3]
                : luggageData?.image[3]) && (
                <div class="col-lg-3 col-md-6">
                  <h6>{t("profile.interior")} 2</h6>
                  <img
                    src={
                      request_type == 1
                        ? LuggageRequest?.image[3]
                        : luggageData?.image[3]
                    }
                    style={{
                      width: "98%",
                      aspectRatio: "3/2",
                      objectFit: "contain",
                    }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="col-lg-1" />
          <div className="col-lg-3">
            <div className="custom-input-group row">
              <div className="col-sm-6 col-md-6 col-lg-12">
                {data.cancel_by_pending == 1 && (
                  <div className="submite-btn d-lg-flex d-sm-none d-md-flex  pb-md-1">
                    {order == 1 && (
                      <Button
                        onClick={() => {
                          setIsOpen(1);
                          handleShow();
                        }}
                        style={{
                          borderColor: "#ff4838",
                          borderRadius: "5px",
                          margin: "2px",
                        }}
                      >
                        {t("profile.cancle_order")}
                      </Button>
                    )}
                  </div>
                )}
                <Modal show={show} onHide={handleClose} size="lg" centered>
                  <Modal.Header closeButton>
                    <Modal.Title>
                      {open == 1
                        ? t("profile.cancel_order_confirmation")
                        : open == 3
                        ? t("profile.success")
                        : open == 4
                        ? "Rate your experience"
                        : t("profile.success")}
                    </Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Form>
                      <div class="container-fluid">
                        <div class="row d-flex justify-content-center align-items-center pb-4 pt-1">
                          {open == 1 ? (
                            <div class="col mb-lg-0">
                              <h5 className="text-center fw-none pb-3">
                                {t(
                                  "profile.your_are_about_to_cancel_the_luggage_sending_request"
                                )}
                              </h5>
                              <div class=" justify-content-center d-lg-flex d-md-flex">
                                <div class="">
                                  <select
                                    style={{ border: "1px solid #ff4838" }}
                                    onChange={handleChange}
                                    name="reason_to_cancel"
                                  >
                                    <option selected disabled>
                                      - {t("profile.select_reasone_to_reject")}{" "}
                                      -
                                    </option>
                                    {order == 1 && (
                                      <option value={5}>
                                        {t("profile.price_too_high")}
                                      </option>
                                    )}
                                    {order == 1 && (
                                      <option value={6}>
                                        {t("profile.I_changed_my_mind")}
                                      </option>
                                    )}
                                    {order == 2 && (
                                      <option value={1}>
                                        {t("profile.overWeight")}
                                      </option>
                                    )}
                                    {order == 2 && (
                                      <option value={2}>
                                        {t("profile.i_changed_my_travel_plan")}
                                      </option>
                                    )}
                                    {order == 2 && (
                                      <option value={3}>
                                        {t("profile.improper")}{" "}
                                      </option>
                                    )}
                                    {order == 2 && (
                                      <option value={4}>
                                        {t(
                                          "profile.luggage_not_taken_into_account"
                                        )}
                                      </option>
                                    )}
                                  </select>
                                  <span style={{ color: "red" }}>
                                    {error.reason_to_cancel}
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : open == 3 ? (
                            <div class="col mb-lg-0">
                              <h6 className="text-center fw-none pb-3">
                                {t(
                                  "profile.the_order_has_been_successfuly_marked"
                                )}
                              </h6>
                              <h6 className="text-center fw-none pb-4 pt-1">
                                {t("profile.you_may_also_view")}
                              </h6>
                              <h6 className="text-center fw-none pb-2">
                                {t(
                                  "profile.please_take_some_time_to_rate_your_experience"
                                )}
                              </h6>
                            </div>
                          ) : open == 4 ? (
                            <div class="col mb-lg-0">
                              <div class="container">
                                <p className="pb-2">
                                  {t(
                                    "profile.please_rate_your_experience_with_luggage_sender"
                                  )}
                                </p>
                                <p className="pb-3">
                                  <span>
                                    <i class="bi bi-person-fill" />
                                  </span>
                                  <span
                                    className="fw-bold"
                                    style={{ color: "#1e98d7" }}
                                  >
                                    {" "}
                                    Elodie Dumas
                                  </span>
                                </p>
                                <h5>{t("profile.rating")}</h5>
                                <Rating
                                // onClick={handleRating}
                                />
                                <p>{t("profile.comment")}</p>
                                <textarea />
                              </div>
                            </div>
                          ) : (
                            <div class="col mb-lg-0">
                              <h5 className="text-center fw-none pb-3">
                                {t(
                                  "profile.the_traveller_successfully_notified_cancelation"
                                )}
                              </h5>
                            </div>
                          )}
                        </div>
                      </div>
                    </Form>
                  </Modal.Body>
                  <Modal.Footer className="justify-content-center">
                    <Button
                      onClick={() => {
                        open == 1
                          ? cancleOrder()
                          : open == 3
                          ? setIsOpen(4)
                          : handleClose();
                      }}
                      style={{
                        backgroundColor: "#ff4838",
                        borderColor: "#ff4838",
                      }}
                    >
                      {open == 1
                        ? t("profile.reject_request")
                        : open == 3
                        ? "Continue to give feedback & ratings"
                        : open == 4
                        ? "Submit"
                        : t("close")}
                    </Button>
                  </Modal.Footer>
                </Modal>
              </div>
            </div>
            <div className="row">
              <div class="col-lg-12 col-md-6 col-12 pt-2">
                {order == 1 && (
                  <div class="earning">
                    <div class="plan" style={{ background: "white" }}>
                      <header>
                        <h6
                          class="plan-title"
                          style={{ color: "black", margin: "0px" }}
                        >
                          {t("profile.status")}
                        </h6>
                        <div class="plan-cost">
                          <span class="plan-price" style={{ fontSize: "15px" }}>
                            {t("profile.payment_due_by_luggage_sender")}
                          </span>
                          <span class="plan-type"></span>
                        </div>
                      </header>
                    </div>
                  </div>
                )}
              </div>
              <div class="col-lg-12 col-md-6 col-12 pt-3">
                {order == 1 && (
                  <div class="earning">
                    <div class="plan " style={{ background: "white" }}>
                      <header>
                        <i class="bi bi-currency-dollar"></i>
                        <h6 class="plan-title" style={{ color: "black" }}>
                          {t("profile.payment_due")}
                        </h6>
                        <div class="plan-cost">
                          <span class="plan-price">
                            {LuggageRequest?.fees} {LuggageRequest?.currency}
                          </span>
                          <span class="plan-type"></span>
                        </div>
                      </header>
                    </div>
                  </div>
                )}
              </div>
              <div class="col-lg-12 col-md-6 col-12 pt-3">
                {order == 2 && (
                  <div class="earning">
                    <div class="plan " style={{ background: "white" }}>
                      <header>
                        <i class="bi bi-currency-dollar"></i>
                        <h6 class="plan-title" style={{ color: "black" }}>
                          {t("profile.earning_oppurtunity")}
                        </h6>
                        <div class="plan-cost">
                          <span class="plan-price">
                            {sent == 1
                              ? LuggageRequest?.total_fees
                              : LuggageRequest?.earning}{" "}
                            {LuggageRequest?.currency}
                          </span>
                          <span class="plan-type"></span>
                        </div>
                      </header>
                    </div>
                  </div>
                )}
              </div>
              {/* {order == 3 && <div class='col-lg-12 col-md-6 col-12 pt-3'>
                <div class="earning">
                  <div class="plan " style={{ background: 'white' }}>
                    <header style={{ padding: '17px 14px' }}>
                      <h6 style={{ color: 'black' }}>Cost to carry luggage:  = 6€</h6>
                      <h6 style={{ color: 'black' }}>Service fees:  = 2€</h6>
                      <h6 style={{ color: 'black' }}>Total fees:  = 8€</h6>
                    </header>
                  </div>
                </div>
                <div className="row pt-1 pb-3">
                  <p class='fs-5 pb-3 text-decoration-underline'>Traveller:</p>
                  <h6 class='pb-1'><i class="bi bi-person-fill"></i> <span style={{ color: '#1e98d7' }}>{request_type == 1 ? LuggageRequest?.request_user_name : LuggageRequest?.luggager_name}</span></h6>
                </div>
              </div>} */}
              {order == 3 && (
                <div>
                  <div className="row pb-3">
                    <div className="col-12 col-lg-12 col-md-6 p-1">
                      <h6 className="text-decoration-underline pb-2">
                        {t("profile.luggage_sender_signature")}
                      </h6>
                      {/* {LuggageRequest?.sender_signature &&  */}
                      <div className="package-sidebar">
                        <aside
                          className="package-widget widget-tour-categoris"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="widget-body p-0">
                            <div className="d-flex">
                              <div
                                class="digital"
                                style={{
                                  border: "1px solid",
                                  borderRadius: "20px",
                                }}
                              >
                                {" "}
                                <svg class="animated-check" viewBox="0 0 24 24">
                                  <path
                                    d="M4.1 12.7L9 17.6 20.3 6.3"
                                    fill="none"
                                  />{" "}
                                </svg>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                      {/* // } */}
                    </div>
                    <div className="col-12 col-lg-12 col-md-6 p-1">
                      <h6 className="text-decoration-underline pb-2">
                        {t("profile.traveller_signature")}
                      </h6>
                      {/* {LuggageRequest?.sender_signature &&  */}
                      <div className="package-sidebar">
                        <aside
                          className="package-widget widget-tour-categoris"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="widget-body p-0">
                            <div className="d-flex">
                              <div
                                class="digital"
                                style={{
                                  border: "1px solid",
                                  borderRadius: "20px",
                                }}
                              >
                                {" "}
                                <svg class="animated-check" viewBox="0 0 24 24">
                                  <path
                                    d="M4.1 12.7L9 17.6 20.3 6.3"
                                    fill="none"
                                  />
                                </svg>
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                      {/* // } */}
                      <div className="package-sidebar">
                        <aside
                          className="package-widget widget-tour-categoris"
                          style={{ boxShadow: "none" }}
                        >
                          <div className="widget-body p-0">
                            <div className="d-flex">
                              {data?.traveler_signature == true ? (
                                <div
                                  class="digital"
                                  style={{
                                    border: "1px solid",
                                    borderRadius: "20px",
                                  }}
                                >
                                  {" "}
                                  <svg
                                    class="animated-check"
                                    viewBox="0 0 24 24"
                                  >
                                    <path
                                      d="M4.1 12.7L9 17.6 20.3 6.3"
                                      fill="none"
                                    />{" "}
                                  </svg>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </aside>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
            {order != 3 && (
              <div className="row pt-5">
                <p class="fs-5 pb-3 text-decoration-underline">
                  {t("profile.luggage_sender")}:
                </p>
                <h6 class="pb-1">
                  <i class="bi bi-person-fill"></i>{" "}
                  <span style={{ color: "#1e98d7" }}>
                    {request_type == 1
                      ? LuggageRequest?.request_user_name
                      : LuggageRequest?.luggager_name}
                  </span>
                </h6>
                <p style={{ color: "black" }}>
                  <i
                    class="bi bi-person-fill"
                    style={{ visibility: "hidden" }}
                  ></i>{" "}
                  <span style={{ color: "black", fontSize: "14px" }}>
                    {t("form.Member_since")}:{" "}
                    {request_type == 1
                      ? LuggageRequest?.member_since_sender?.slice(0, 10)
                      : LuggageRequest?.member_since_receiver?.slice(0, 10)}
                  </span>
                </p>
                {/* <h6 className="ms-2">Member since: 9 Nov 2022</h6> */}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LuggageCarryingWrapperArea;
