import React, { useState, useEffect, useContext } from 'react'
import { useTranslation } from 'react-i18next';
import articles from '../../../../models/articles';
import TravelCard from '../myTravels/TravelCard';
import ArticleCard from './ArticleCard';
import AppContext from '../../../../store/context/app-context';

export default function MyArticles({ setValue }) {
    const { t } = useTranslation();
    const [articledetails, setArticleDetails] = useState([]);
    const [isShow, setIsShow] = useState(1);
    const contextObj = useContext(AppContext);

    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        articles.GetByUserId().then(responseData => {
            if (responseData)
                setArticleDetails(responseData);
        })
    }
    const filterArticle = () => {
        return (articledetails)?.filter((item) =>
            item?.isComplete == (isShow == 1 ? false : true))
        // .filter((item) => item?.reason_to_cancel == 0)
    }

    const addTraveller = () => {
        setValue(0) // for select the tab 
        contextObj.setRadioValue(3) // for select the radio button
        contextObj.setTabValue(0) // for set the banner value
    }

    return (
        // <section class="">
        //     {articledetails?.map(item => (
        //         <div class="container  h-100">
        //             <div class="row d-flex justify-content-center align-items-center h-100">
        //                 <div class=" col mb-4 mb-lg-0">
        //                     <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
        //                         <div class="ribbon red"><span>{t("profile.not_completed")}</span></div>
        //                         <div class="row g-0">
        //                             <div class="col-md-4">
        //                                 <img src="https://thumbs.dreamstime.com/b/green-motorcycle-10713774.jpg" class="card-img" alt="..." />
        //                             </div>
        //                             <div class="col-md-8">
        //                                 <div class="card-body p-4">
        //                                     <h3>Title <span style={{ textAlign: 'end' }}></span></h3>
        //                                     <hr class="mt-0 mb-4" />
        //                                     <div class="row pt-1">
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-geo-alt-fill" />{' '}{t("profile.from")}</h6>
        //                                             <p class="text-muted">India/Pune</p>
        //                                         </div>
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-geo-alt-fill" />{' '}{t("profile.to")}</h6>
        //                                             <p class="text-muted">India/Mumbai</p>
        //                                         </div>
        //                                     </div>
        //                                     <div class="row pt-1">
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-bag-fill" />{' '}{t("profile.quantity")}</h6>
        //                                             <p class="text-muted">{item?.quantity}</p>
        //                                         </div>
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-circle-fill" />{' '}{t("profile.material")}</h6>
        //                                             <p class="text-muted">{item?.material}</p>
        //                                         </div>
        //                                     </div>
        //                                     <div class="row pt-1">
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-palette-fill"></i>{' '}{t("profile.color")}</h6>
        //                                             <p class="text-muted">{item?.color}</p>
        //                                         </div>
        //                                         <div class="col-6 mb-3">
        //                                             <h6><i class="bi bi-cash"></i>{' '}{t("profile.price")}</h6>
        //                                             <p class="text-muted">€{' '}{item?.price}</p>
        //                                         </div>
        //                                     </div>
        //                                     <h6></h6>
        //                                     <hr class="mt-0 mb-4" />
        //                                     <div class="row pt-1">
        //                                         <div class="col-6 mb-3">
        //                                             <h6><span style={{ fontWeight: 600 }}>{t("profile.pending_signatures")} :</span><spam style={{ color: '#ff4838' }}> 0</spam></h6>
        //                                         </div>
        //                                         <div class="col-6 mb-3">
        //                                             <a href="#" style={{ color: '#ff4838' }}> <h6><span style={{ fontWeight: 600 }}><i class="bi bi-arrow-up-right-circle-fill"></i>{' '}{t("profile.request")} :</span>  0</h6></a>
        //                                         </div>
        //                                     </div>
        //                                     <div class=" d-flex justify-content-end">
        //                                         <a className='me-3' style={{ color: '#ff4838', fontWeight: '600' }} href="#"><i class="fab fa-twitter fa-lg me-3"></i>{t("profile.details")}</a>
        //                                         <a class='me-3 ' href="#" style={{ color: 'green' }}><i class="bi bi-pencil-square me-2"></i>{t("profile.edit")}</a>
        //                                         <a class='' href="#" style={{ color: 'red' }}><i class="bi bi-trash-fill me-1"></i>{t("profile.delete")}</a>
        //                                     </div>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>))}
        // </section>
        <div>
            <div class="container">
                <div className='package-details-wrapper p-0 '>
                    <div className='tour-package-details'>
                        <div className="package-details-tabs row">
                            <div className='d-flex  flex-column gap-3 flex-md-row  justify-content-between'>
                                <ul
                                    className="nav nav-pills  gap-xxl-4 gap-3"
                                    id="pills-tab"
                                    role="tablist"
                                    style={{ marginBottom: '0px', }}

                                >
                                    <li className="nav-item" role="presentation">
                                        <button
                                            className="nav-link travel active"
                                            id="pills-package1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body1"
                                            aria-selected="true"
                                            onClick={() => { setIsShow(1) }}
                                            style={{ fontSize: '15px', borderRadius: '5px' }}
                                        >
                                            {t("profile.published")}
                                        </button>
                                    </li>
                                    <li className="nav-item travel" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-package2"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body2"
                                            aria-selected="false"
                                            onClick={() => { setIsShow(2) }}
                                            style={{ fontSize: '15px', borderRadius: '5px' }}
                                        >
                                            {t("profile.completed")}
                                        </button>
                                    </li>

                                </ul>
                                <button
                                    className="nav-link active"
                                    type="button"
                                    onClick={() => addTraveller()}
                                    style={{ borderRadius: '5px', color: '#fff', border: '1px solid gray', backgroundColor: 'gray', padding: '8px 25px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                                >
                                    {t("homePage.I_sell_Article")}
                                </button>
                            </div>
                            <div className="tab-content " id="pills-tabContent">
                                {/*   In Progress*/}
                                <div
                                    className="tab-pane fade show active package-info-tab pt-4 "
                                    id="pill-body1"
                                    role="tabpanel"
                                    aria-labelledby="pills-package1"
                                >
                                    <div className="row">
                                        {filterArticle()?.map((item =>
                                            <ArticleCard item={item} getData={getData} isShow={isShow} type={4} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
