import axios from '../plugin/axios';

const subscribeAds = {
    list(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('SubscribeAds/GetAll', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Article_donation/Post', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Article_donation/GetById', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Article_donation', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Article_donation/ValidatePost', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    }
};
export default subscribeAds;
