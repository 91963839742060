import React from 'react'
import BradcomePayment from '../../common/BradcomePayment'
import PaymentWrap from './PaymentWrap'
import { useTranslation } from "react-i18next";

export default function Payment() {
    const { t } = useTranslation();
    return (
        <div>
            <BradcomePayment
                name={t("homePage.quick_or_secure_payment")}
            />
            <PaymentWrap />
        </div>
    )
}
