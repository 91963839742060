import React from "react";
import { useTranslation } from "react-i18next";

function Faq() {
  const { t } = useTranslation();

  return (
    <>
      <div className="faqs pb-110">

        <h2>
        {t('profile.Getting')} <span> {t('profile.Started_with')} Cobagage</span>
        </h2>
        <div
          className="accordion faq-accordion accordion-flush"
          id="faq-accordion-example"
        >
          <div className="accordion-item faq-accordion">
            <div className="accordion-header" id="faq-headingOne">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-collapseOne"
                aria-expanded="false"
                aria-controls="faq-collapseOne"
              >
                {t('profile.Getting_Cobagage')}
              </button>
            </div>
            <div
              id="faq-collapseOne"
              className="accordion-collapse collapse  show"
              aria-labelledby="faq-headingOne"
              data-bs-parent="#faq-accordion-example"
            >
              <div className="accordion-body">
                <p>
                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}> {t('profile.Discover_Cobagage')}</span><br />
                  {t('profile.Cobagage_service_connects')}<br />
                  {t('profile.You_weight_bags')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Registration_identification')}</span><br />
                  {t('profile.Cobagage_forms')}<br />
                  {t('profile.This_email')}<br />
                  {t('profile.Once_valid_passport')}<br />
                  {t('profile.password_username')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Profile_verification')}</span><br />
                  {t('profile.Each_registration_Cobagagers')}<br />
                  {t('profile.registration_will_finalized')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Reviews')}</span><br />
                  {t('profile.When_completed')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Security_Follow')}</span><br />
                  {t('profile.As_cobagger_site')}<br />
                  {t('profile.The_Cobagage_website_allows')}<br />
                  {t('profile.Through_ervice_exchanges')}<br />
                  {t('profile.Thus_clarified_messaging')}<br />
                  {t('profile.The_order_recipient')}<br />
                  {t('profile.It_attests_that')}<br />
                  {t('profile.Throughout_cessary')}<br />
                </p>
                {/* <div className="row pt-2">
                  <div className="col-xl-6">
                    <ul className="faq-list">
                      <li>
                        <strong>01.</strong> Nullam eget mauris imperdiet
                        turpis.
                      </li>
                      <li>
                        <strong>02.</strong> lacus mi dictum urna, quis cursus.
                      </li>
                    </ul>
                  </div>
                  <div className="col-xl-6">
                    <ul className="faq-list">
                      <li>
                        <strong>03.</strong> Sed feugiat sed erat sed eleifend.
                        Done velit.
                      </li>
                      <li>
                        <strong>04.</strong> augue dignissim Sed vel molestie
                        ligula.
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
          <div className="accordion-item faq-accordion">
            <div className="accordion-header" id="faq-headingTwo">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-collapseTwo"
                aria-expanded="false"
                aria-controls="faq-collapseTwo"
              >
                {t('profile.Payments')}
              </button>
            </div>
            <div
              id="faq-collapseTwo"
              className="accordion-collapse collapse"
              aria-labelledby="faq-headingTwo"
              data-bs-parent="#faq-accordion-example"
            >
              <div className="accordion-body">
                <p>
                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Receive_payment')}</span><br />
                  <span style={{ textDecoration: "underline", fontWeight: '600' }}>{t('profile.Payment_conditions')}</span><br />
                  - {t('profile.The_order_luggage')}.<br />
                  - {t('profile.The_traveler_recipient')}.<br />
                  {t('profile.Then_just_Cobagage')}.<br />
                  <span style={{ textDecoration: "underline", fontWeight: '600' }}>{t('profile.Simply_Payment_conditions')}:</span><br />
                  - {t('profile.Delivery_has')}.<br />
                  - {t('profile.Cancellation_pickup')}<br />
                  {t("profile.Payment_shipped")}.<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Online_payments')}</span><br />
                  {t('profile.When_PayPal_credit_card')}.<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Refunds')}</span><br />
                  {t('profile.The_cases')}:<br />
                  - {t('profile.The_traveler_operation')}.<br />
                  - {t('profile.The_traveler_luggage')}.<br />
                  - {t('profile.The_travele_updates')}.<br />
                  - {t('profile.The_canceled_traveler')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Cancellation_meeting')}</span><br />
                  {t('profile.It_baggage_pickup')}.<br />
                  {t('profile.Simply_service_traveler')}.<br />
                  {t('profile.In_case_traveler')}.<br />
                  {t('profile.It_happen_reasons')}.<br />
                  {t('profile.In_delivery_appointment')}.<br />
                  {t('profile.If_delivery_date')}<br /><br />
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item faq-accordion">
            <div className="accordion-header" id="faq-headingThree">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-collapseThree"
                aria-expanded="false"
                aria-controls="faq-collapseThree"
              >
                {t('profile.Responsibilities')}
              </button>
            </div>
            <div
              id="faq-collapseThree"
              className="accordion-collapse collapse"
              aria-labelledby="faq-headingThree"
              data-bs-parent="#faq-accordion-example"
            >
              <div className="accordion-body">
                <p>
                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Type_content_transported')}</span><br />
                  {t('profile.The_discretion_user')}.<br />
                  {t('profile.Type_content_transported')}<br />
                  {t('profile.In_case_doubt_service')}<br />
                  {t('profile.Cobagage_contents')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Responsibilities_Luggage')}</span><br />
                  {t('profile.Once_carries')}<br />
                  {t('profile.The_latter')}<br /><br />

                  <span style={{ color: '#ff4838', fontSize: "20px", fontWeight: "600", textDecoration: "underline" }}>{t('profile.Accident_Traveler')}</span><br />
                  {t('profile.In_accident')}<br />
                  {t('profile.The_baggage_lost')}<br /><br />
                </p>
              </div>
            </div>
          </div>
          {/* <div className="accordion-item faq-accordion">
            <div className="accordion-header" id="faq-headingFour">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-collapseFour"
                aria-expanded="false"
                aria-controls="faq-collapseFour"
              >
                Ut iaculis vulputate odio ac laoreet. Aliquam euontep bunt?
              </button>
            </div>
            <div
              id="faq-collapseFour"
              className="accordion-collapse collapse"
              aria-labelledby="faq-headingFour"
              data-bs-parent="#faq-accordion-example"
            >
              <div className="accordion-body">
                <p>
                  Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                  venenatis hendrerit a id lectus. Suspendissendt molestie
                  turpis nec lacinia vehicula. Quisque eget volutpat purus.
                  Aenean blandit magna maximus landi quam facilisis, tempor
                  porttitor elit hendrerit. Aliquam cursus arcu vel bibendum
                  pulvinar.
                </p>
              </div>
            </div>
          </div>
          <div className="accordion-item faq-accordion">
            <div className="accordion-header" id="faq-headingFive">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#faq-collapseFive"
                aria-expanded="false"
                aria-controls="faq-collapseFive"
              >
                Suspendisse convallis dui eget orci mollis?
              </button>
            </div>
            <div
              id="faq-collapseFive"
              className="accordion-collapse collapse"
              aria-labelledby="faq-headingFive"
              data-bs-parent="#faq-accordion-example"
            >
              <div className="accordion-body">
                <p>
                  Duis rutrum nisl urna. Maecenas vel libero faucibus nisi
                  venenatis hendrerit a id lectus. Suspendissendt molestie
                  turpis nec lacinia vehicula. Quisque eget volutpat purus.
                  Aenean blandit magna maximus landi quam facilisis, tempor
                  porttitor elit hendrerit. Aliquam cursus arcu vel bibendum
                  pulvinar.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
}

export default Faq;
