import axios from '../plugin/axios';

const contact = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('ContactUs/GetAll')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('ContactUs/Post', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
};
export default contact;