import React, { useContext, useEffect, useState } from "react";
// import { BrowserRouter, Route, Switch } from "react-router-dom";
import Footer from "./common/Footer";
import HeaderFour from "./pages/homeFour/HeaderFour";
import { BrowserRouter, Route, Switch, useLocation } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import AppContext, {
  AppDataConsumer,
  ApplicationDataProvider,
} from "../store/context/app-context";
import { auth } from "../models";
import MainLayout from "../components/layout/MainLayout";
// import Layout from "../components/App";
import About from "../components/pages/about/About";
import Registration from "../components/pages/registration/Registration";
import Login from "../components/pages/login/Login";
import ForgotPassword from "../components/pages/forgotPassword/ForgotPassword";
import PasswordReset from "../components/pages/forgotPassword/passwordReset/PasswordReset";
import TermsConditions from "../components/pages/terms&Conditions/TermsConditions";
import SellArticle from "../components/pages/sellArticle/SellArticle";
import CobagageAds from "../components/pages/cobagageAds/CobagageAds";
import LuggageAds from "../components/pages/luggageAds/LuggageAds";
import SendingRequest from "../components/pages/profile/myRequests/sendingRequest/SendingRequest";
import LuggageCarrying from "../components/pages/profile/myOrders/luggageCarrying/LuggageCarrying";
import Payments from "../components/pages/profile/subscribeAds/payment/Payment";
import LuggageCompleted from "../components/pages/profile/myOrders/luggageCompleted/LuggageCompleted";
import LuggageCancelled from "../components/pages/profile/myOrders/luggageCancelled/LuggageCancelled";
import Invoice from "../components/pages/profile/myOrders/invoice/Invoice";
import ShoppingCard from "../components/pages/profile/shoppingCart/ShoppingCard";
import PaymentDone from "../components/pages/paymentDone/paymentDone";
import StripPayment from "../components/pages/StripPayment/stripPayment";
import ShoppingStrip from "../components/pages/ShoppingStrip/shoppingStrip";
import ShoppingPaypal from "../components/pages/ShoppingPaypal/shoppingPaypal";
import Construction from "../components/pages/construction/construction";
import NewHomePage from "../components/pages/homeFour/NewHomePage";
import International from "../components/pages/International/International";
import Payment from "../components/pages/Payment/Payment";
import Ecoresponsive from "../components/pages/Eco/Ecoresponsive";
import HowWork from "../components/pages/HowWork/HowWork";
import MoreCobagage from "../components/pages/MoreCobagage/MoreCobagage";
import Cgve from "../components/pages/Cgve/Cgve";
import PrivacyPolicy from "../components/pages/PrivacyPolicy/PrivacyPolicy";
import TipsAndAdvice from "../components/pages/tipsAndAdvice/TipsAndAdvice";
// import About from "../components/pages/about/About";
import BlogSidebar from "../components/pages/blog/BlodSidebar/BlogSidebar";
import BlogDetailsPage from "../components/pages/blog/blogdetails/BlogDetailsPage";
import BlogStandard from "../components/pages/blog/BlogStandard/BlogStandard";
import Contact from "../components/pages/contact/Contact";
import DestinationDetails from "../components/pages/destinationDetails/DestinationDetails";
import Destinations from "../components/pages/destinetion/Destinatination";
import Error from "../components/pages/error/Error";
import FaqPage from "../components/pages/faq/FaqPage";
import GuidePage from "../components/pages/guide/GuidePage";
import PackageDetails from "../components/pages/packageDetails/PackageDetails";
import PackageGrid from "../components/pages/packageGrid/PackageGrid";
import PackageSidebar from "../components/pages/packageSidebar/PackageSIdebar";
import PackageStandard from "../components/pages/packageStandard/PackageStandard";
import GallaryPage from "../components/pages/gallary/GallaryPage";
import BlogGridPage from "../components/pages/blog/BlogGrid/BlogGridPage";
import SubmitTravels from "../components/pages/submitAnAd/submitTravels/SubmitTravels";
import SubmitLuggage from "../components/pages/submitAnAd/submitLuggage/SubmitLuggage";
import Profile from "../components/pages/profile/profileMenu/Profile";
import MoreOnCobagage from "../components/pages/moreOnCobagage/MoreOnCobagage";
import Legals from "../components/pages/legals/Legals";
import RateOurWebsite from "../components/pages/Rate_our_website/RateOurWebsite";
import CobagageAsJob from "../components/pages/cobagageAs_a_Job/CobagageAsJob";
import ProfileInfo from "../components/pages/login/completeYourProfile/ProfileInformmation";
import TravelDetails from "../components/pages/profile/postanadvert/TravelDetails/TravelDetails";

import SubmitDetails from "../components/pages/profile/postanadvert/SubmitPage/SubmitDetails";
import ViewTravel from "../components/pages/profile/dashBoard/viewAll/viewTravel/ViewTravel";
import Details from "../components/pages/AdDetails/Details";
import LuggageRequest from "../components/pages/profile/myRequests/luggageRequest/LuggageRequest";
import ViewArticles from "../components/pages/profile/dashBoard/viewAll/viewArticles/ViewArticles";
import ViewLuggage from "../components/pages/profile/dashBoard/viewAll/viewLuggage/ViewLuggage";
import OtpaPage from "./pages/otpPage/OtpaPage";
import {
  HttpTransportType,
  HubConnectionBuilder,
  LogLevel,
} from "@microsoft/signalr";

function App(props) {
  // const { pathname } = useLocation();
  const [admin, setAdmin] = useState(false);

  const contextObj = useContext(AppContext);

  useEffect(() => {
    scrollTop();
  }, []);

  useEffect(() => {
    // joinRoom();
  }, []);

  // async function joinRoom() {
  //   try {
  //     const connection = new HubConnectionBuilder()
  //       .withUrl("http://localhost:5000/chat", {
  //         skipNegotiation: true,
  //         transport: HttpTransportType.WebSockets,
  //       })
  //       .configureLogging(LogLevel.Information)
  //       .build();

  //     console.log("connection: ", connection);

  //     connection.on("ReceiveMessage", (senderUserId, message) => {
  //       console.log("ReceiveMessage: ", senderUserId, message);
  //     });

  //     connection.on("UsersInRoom", (users) => {
  //       // setUsers(users);
  //     });

  //     connection.onclose((e) => {
  //       // setConnection();
  //       // setMessages([]);
  //       // setUsers([]);
  //     });

  //     await connection.start();
  //     // await connection.invoke("JoinRoom", { user, room });
  //     // setConnection(connection);
  //   } catch (e) {
  //     console.log(e);
  //   }
  // }

  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  // setTimeout(() => {
  //   if (!window.sessionStorage.getItem("Admin")) {
  //     let abc = prompt("Admin Password", "");
  //     if (abc === "Ludovic@8788") {
  //       setAdmin(true);
  //       window.sessionStorage.setItem("Admin", true);
  //     } else {
  //       window.location.href = "/";
  //     }
  //   } else {
  //     setAdmin(true);
  //   }
  // }, [1000]);

  const myRoutes = () => {
    return (
      <>
        {/* <BrowserRouter basename="/"> */}
        <Switch>
          <Route
            exact
            path="/"
            component={NewHomePage }
          />

          {/* <Layout> */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/home`}
            component={MainLayout}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/about`}
            component={About}
          />
          {/* registration */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/register`}
            // component={contextObj.login ? MainLayout : Registration}
            component={Registration}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/otp`}
            // component={contextObj.login ? MainLayout : Registration}
            component={OtpaPage}
          />
          {/*Login*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/login`}
            // component={contextObj.login ? MainLayout : Login}
            component={Login}
          />
          {/* Forgot Password */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/forgotPassword`}
            component={ForgotPassword}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/password-reset/:token`}
            component={PasswordReset}
          />
          {/* Terms and Conditions */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/termsConditions`}
            component={TermsConditions}
          />
          {/* sell article */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/sellArticle/:id/:type_of_travel`}
            component={SellArticle}
          />
          {/* Cobagage Ads */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cobagageAds`}
            component={CobagageAds}
          />
          {/* Luggage Ads */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageAds`}
            component={LuggageAds}
          />
          {/* desination pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destination`}
            component={Destinations}
          />
          {/* submitTravel (submit an ad page)*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/submitTravels`}
            component={SubmitTravels}
          />
          {/* submitLuggage (submit an ad page)*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/submitLuggages`}
            component={SubmitLuggage}
          />
          {/* My Profile */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/myProfile`}
            // component={contextObj.login ? Profile : Login}
            component={Profile}
          />
          {/* Payment */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/payments`}
            component={Payments}
          />
          {/* Cobagage Travel Announcements */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cobagage_travel_announcements`}
            component={ViewTravel}
          />
          {/* Cobagage luggage Announcements */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cobagage_luggage_announcements`}
            component={ViewLuggage}
          />
          {/* Cobagage Articles On Sale */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cobagage_articles/:id`}
            component={ViewArticles}
          />
          {/* Luggage Carrying Request */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageRequest/:id/:type/:request_type`}
            component={LuggageRequest}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageRequest/:id/:product_id/:type/:request_type`}
            component={LuggageRequest}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/sendingRequest/:id/:type/:request_type`}
            component={SendingRequest}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/sendingRequest/:id/:product_id/:type/:request_type`}
            component={SendingRequest}
          />
          {/* luggage carrying order detaills(In progress) */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCarrying/:id/:request_type/:sent/:order`}
            component={LuggageCarrying}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCarrying/:id/:product_id/:request_type/:sent/:order`}
            component={LuggageCarrying}
          />
          {/* luggage carrying order detaills(Completed) */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCompleted/:id/:request_type/:order`}
            component={LuggageCompleted}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCompleted/:id/:product_id/:request_type/:order`}
            component={LuggageCompleted}
          />
          {/* luggage carrying order detaills(Cancelled) */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCancelled/:id/:request_type/:order`}
            component={LuggageCancelled}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/luggageCancelled/:id/:product_id/:request_type/:order`}
            component={LuggageCancelled}
          />
          {/* Invoice*/}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/invoice/:order/:id/:request_type`}
            component={Invoice}
          />
          {/* shopping cart */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shoppingCard`}
            component={ShoppingCard}
          />
          {/* TravelAnnouncementDetails */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/travelDetails/:id/:type`}
            component={TravelDetails}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/SubmitDetails/:id/:type`}
            component={SubmitDetails}
          />
          {/* AdDetails */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ad_details/:id/:type`}
            component={Details}
          />
          {/* More On Cobagage */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/moreOnCobagage`}
            component={MoreOnCobagage}
          />
          {/* Legals */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/legals`}
            component={Legals}
          />
          {/* Cobagage As a Job */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/cobagageAsJob`}
            component={CobagageAsJob}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ProfileInfo/:country_id`}
            component={ProfileInfo}
          />

          <Route
            exact
            path={`${process.env.PUBLIC_URL}/destination-details`}
            component={DestinationDetails}
          />

          {/* all package pages component */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package`}
            component={PackageGrid}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-sidebar`}
            component={PackageSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-standard`}
            component={PackageStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/package-details`}
            component={PackageDetails}
          />

          {/* all blog pages */}
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog`}
            component={BlogGridPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-details`}
            component={BlogDetailsPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-standard`}
            component={BlogStandard}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/blog-sidebar`}
            component={BlogSidebar}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/guide`}
            component={GuidePage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/faq`}
            component={FaqPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/gallary`}
            component={GallaryPage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/contact`}
            component={Contact}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/error`}
            component={Error}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/paymentDone/*`}
            component={PaymentDone}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/strippayment/:id`}
            component={StripPayment}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shoppingCart/:id`}
            component={ShoppingStrip}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/shoppingCartPaypal/*`}
            component={ShoppingPaypal}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/internationl`}
            component={International}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Payment`}
            component={Payment}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/ecoresponsive`}
            component={Ecoresponsive}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/working`}
            component={HowWork}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/morecobagage`}
            component={MoreCobagage}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/RateOurWebsite`}
            component={RateOurWebsite}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/Cgvu`}
            component={Cgve}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/privacyPolicy`}
            component={PrivacyPolicy}
          />
          <Route
            exact
            path={`${process.env.PUBLIC_URL}/TipsAndAdvice`}
            component={TipsAndAdvice}
          />
          {/* </Layout> */}
        </Switch>
        {/* </BrowserRouter> */}
      </>
    );
  };

  return (
    <>
      <BrowserRouter basename="/">
        <>
          <HeaderFour />
          {myRoutes()}
          <Footer className="footer-area" />
        </>
      </BrowserRouter>
    </>
  );
}

export default App;
