import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';



export default function CookiesPage(props) {
    const [showCookieBar, setShowCookieBar] = useState(true);
    const { t } = useTranslation();

    // useEffect(() => {
    //     const testCookieName = 'test';

    //     const setAndLogCookie = async () => {
    //         // Set a new cookie
    //         Cookies.set(testCookieName, 500);

    //         // Wait a short time to ensure the cookie is set (you can adjust this as needed)
    //         await new Promise((resolve) => setTimeout(resolve, 100));

    //         // Now, you can access the cookie
    //         console.log('cookie:', Cookies.get(testCookieName));
    //     };

    //     setAndLogCookie();
    // }, []);


    return (
        <div>
            <div className='container'>
                <div className='col-11 col-lg-6 row'
                    style={{
                        backgroundColor: "ghostwhite",
                        marginBottom: "10px",
                        padding: "5px",
                        borderRadius: "5px",
                        marginLeft: "10px"
                    }}
                >
                    <div className='col-10 row'>
                        <div className='col-12'
                            style={{ paddingLeft: "25px", paddingTop: "10px" }}
                        >
                            <h3>{t('profile.We_cookies')}</h3>
                        </div>
                        <div className='col-12'
                            style={{ paddingLeft: "25px", paddingTop: "10px" }}
                        >
                            <p>{t('profile.We_selected_third_parties')}</p>
                            <p>{t('profile.technologies_marketing_efforts')}</p>
                        </div>
                        <div className='col-12 pt-3 pb-3'>
                            <div className=''>
                                <button
                                    style={{
                                        background: "linear-gradient(to bottom, #ff9933 0%, #cc6600 100%)",
                                        borderRadius: "5px",
                                        color: "white",
                                        padding: "8px",
                                        marginLeft: '10px',
                                        border: "1px"
                                    }}
                                    onClick={() => props.setShowCookie('Allow')}
                                >
                                    {t('profile.Accept_All')}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className='col-2 d-flex justify-content-end'>
                        <i class="bi bi-x-circle"
                            style={{ fontSize: "35px", cursor: "pointer" }}
                            onClick={() => props.setShowCookie('Refuse')}
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    )
}
