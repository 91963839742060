import axios from '../plugin/axios';

const stripeSubscribe = {

    create(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Stripe_Subscribe/Stripe_Payment/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    paymetData(id, data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Stripe_Subscribe/StripePaymentHistory/' + id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    mobileMoney(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Stripe_Subscribe/SubscribePaymentUsingMobileMoney/' + id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },

};
export default stripeSubscribe;
