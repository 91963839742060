import React from "react";
import ForgotPasswordWrapperArea from "./ForgotPasswordWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("auth.forgot_password")} />
      <ForgotPasswordWrapperArea/>
     
    </>
  );
}

export default ForgotPassword;
