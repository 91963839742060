import React, { useEffect, useState } from "react";
import ProfileWrapperArea from "./ProfileWrapperArea";
import Breadcrumb from "../../../common/Breadcrumb";
import Myaccount from "../../../common/Myaccount";
import Myads from "../../../common/Myads";
import Dashbord from "../../../common/Dashbord";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../store/context/app-context";
import { useContext } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Myrequest from "../../../common/Myrequest";
import Subplan from "../../../common/Subplan";
import Orderes from "../../../common/Orderes";
import Jobs from "../../../common/Jobs";
import LuggaePage from "../../../common/LuggaePage";

function Profile() {
  const { t } = useTranslation();
  const contextObj = useContext(AppContext);
  const history = useHistory()
  // useEffect(() => {
  //   if (!contextObj?.loginUser) {
  //     history.push('/login')
  //   }
  // }, [])

  return (
    <>
      {/* <Breadcrumb name={
        contextObj?.tabValue == 0 ? t("profile.post_an_advert") :
          contextObj?.tabValue == 1 ? t("profile.dashboard") :
            // contextObj?.tabValue == 2 ? t("profile.my_profile") :
            contextObj?.tabValue == 3 ? t("profile.change_password") :
              contextObj?.tabValue == 4 ? t("profile.my_subscription_plan") :
                contextObj?.tabValue == 5 ? t("profile.my_requests") :
                  contextObj?.tabValue == 6 ? t("profile.my_pending_payments") :
                    contextObj?.tabValue == 7 ? t("profile.my_orders") :
                      contextObj?.tabValue == 8 ? t("profile.my_travels") :
                        contextObj?.tabValue == 9 ? t("profile.my_luggages") :
                          contextObj?.tabValue == 10 ? t("profile.my_articles") :
                            contextObj?.tabValue == 11 ? t("profile.my_article_donations") :
                              contextObj?.tabValue == 12 ? t("profile.cobagage_wallet") :
                                contextObj?.tabValue == 13 ? t("profile.earn_spends_report") :
                                  contextObj?.tabValue == 14 ? t("profile.subscription_balance") :
                                    contextObj?.tabValue == 15 ? t("profile.ShoppingCard") :
                                      ''
      }
      /> */}
      {contextObj?.tabValue == 0 || contextObj?.tabValue == 10 || contextObj?.tabValue == 11 ?
        <Myads
          name={
            contextObj?.tabValue == 0 ? t("profile.post_an_advert") :
              contextObj?.tabValue == 10 ? t("profile.my_articles") :
                contextObj?.tabValue == 11 ? t("profile.my_article_donations") : ""
          }
        /> : ""}
      {/* {contextObj?.tabValue == 11 ?
        <Dashbord
          name={
            contextObj?.tabValue == 11 ? t("profile.my_article_donations") : ""
          }
        /> : ""} */}
      {contextObj?.tabValue == 1 || contextObj?.tabValue == 2 || contextObj?.tabValue == 3 ?
        <Myaccount
          name={
            contextObj?.tabValue == 1 ? t("profile.dashboard") :
              contextObj?.tabValue == 2 ? t("profile.my_profile") :
                contextObj?.tabValue == 3 ? t("profile.change_password") : ""
          }
        /> : ""}

      {contextObj?.tabValue == 4 || contextObj?.tabValue == 5 || contextObj?.tabValue == 14 ?
        <Myrequest
          name={
            contextObj?.tabValue == 5 ? t("profile.my_requests") : contextObj?.tabValue == 4 ? t("profile.my_subscription_plan") : contextObj?.tabValue == 14 ? t("profile.subscription_balance") : ""
          }
        /> : ""}
      {contextObj?.tabValue == 6 || contextObj?.tabValue == 12 || contextObj?.tabValue == 13 ?
        <Subplan
          name={
            contextObj?.tabValue == 6 ? t("profile.my_pending_payments") : contextObj?.tabValue == 12 ? t("profile.cobagage_wallet") : contextObj?.tabValue == 13 ? t("profile.earn_spends_report") : ""
          }
        /> : ""}
      {contextObj?.tabValue == 7 ?
        <Orderes
          name={
            contextObj?.tabValue == 7 ? t("profile.my_orders") : ""
          }
        /> : ""}

      {contextObj?.tabValue == 8 ?
        <Jobs
          name={
            contextObj?.tabValue == 8 ? t("profile.my_travels") : ""
          }
        /> : ""}

      {contextObj?.tabValue == 9  ?
        <LuggaePage
          name={
            contextObj?.tabValue == 9 ? t("profile.my_luggages") : ""
          }
        /> : ""}


      <ProfileWrapperArea />

    </>
  );
}

export default Profile;
