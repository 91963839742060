import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { country } from "../../../models";
import AppContext from "../../../store/context/app-context";
import useValidator from "../../../plugin/validator";
import travel from "../../../models/travel";
import Loading from "../../common/Loading";
// import moment from 'moment';
import DatePicker from "react-datepicker";
import moment from 'moment/moment';
const format = {
    country_of_Departure: {},
    state_of_Departure: {},
    city_of_Departure: {},
    country_of_stopover: {},
    state_of_stopover: {},
    city_of_stopover: {},
    country_of_Arriaval: {},
    state_of_Arriaval: {},
    city_of_Arriaval: {},
    departureCountry: [],
    departureState: [],
    departureCity: [],
    stopoverCountry: [],
    stopoverState: [],
    stopoverCity: [],
    arriavalCountry: [],
    arriavalState: [],
    arriavalCity: [],
};

function TravellerToLuggage(props) {
    const { t } = useTranslation();
    const { i18n } = useTranslation();
    const contextObj = useContext(AppContext);
    const [data, setData] = useState({
        luggage_id: props?.allData?.id,
        title: "",
        country_departure: props.allData.country_departure,
        country_arrival: props.allData.country_arrival,
        state_arrival: props.allData.state_arrival,
        city_arrival: props.allData.city_arrival,
        state_departure: props.allData.state_departure,
        city_departure: props.allData.city_departure,
        pickup_place: "",
        delivery_place: "",
        departure_date: "",
        arrival_date: "",
        departure_time: "",
        arrival_time: "",
        carriage_capacity: "",
        trip_type: "",
        price_per_kg: "",
        vehicle_type: "",
        vehicle_details: "",
        packing_instruction: "",
        description: "",
        terms_and_condition: "",
        stopover_in_trip: false,
        country_stopover: "",
        state_stopover: "",
        city_stopover: "",
        insurance: false,
        return_departure_date: "",
        return_arrival_date: "",
        return_departure_time: "",
        return_arrival_time: "",
        sender_signature: '',
    });
    const [details, setDetails] = useState(format);
    const [open, setOpen] = useState(false);
    const [validator, showMessage] = useValidator();
    const history = useHistory();
    const [filed, setFiled] = useState(0);
    const [travellerUserData, setTravellerUserData] = useState()
    const [isShow, setIsShow] = useState(true);
    const [isRadio, setIsRadio] = useState(true);
    const [load, setLoad] = useState(false);

    const handleChange = (e) => {
        const { name, value } = e.target;
        handleSetData(name, value);
    }

    const handleSetData = (name, value) => {
        setData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    }

    useEffect(() => {
        getIdData();
        getData();
        GetAllByUser();
        // contextObj.requestedTraveller && setData(contextObj.requestedTraveller);
        if (!contextObj.requestedTraveller) {
        } else if (
            !contextObj.requestedTraveller?.active_status &&
            contextObj.requestedTraveller?.active_status !== true
        ) {
            setData(contextObj.requestedTraveller);
        }
    }, []);

    const error = {
        title: validator.message(t('title'), props.travelIdData ? props.travelIdData?.title : data?.title, 'required'),
        trip_type: validator.message(t('trip_type'), props.travelIdData ? props.travelIdData?.trip_type : data?.trip_type, 'required'),
        pickup_place: validator.message(t('pickup_place'), props.travelIdData ? props.travelIdData?.pickup_place : data?.pickup_place, 'required'),
        delivery_place: validator.message(t('delivery_place'), props.travelIdData ? props.travelIdData?.delivery_place : data?.delivery_place, 'required'),
        departure_date: validator.message(t('departure_date'), props.travelIdData ? props.travelIdData?.departure_date : data?.departure_date, 'required'),
        arrival_date: validator.message(t('arrival_date '), props.travelIdData ? props.travelIdData?.arrival_date : data?.arrival_date, 'required'),
        departure_time: validator.message(t('departure_time '), props.travelIdData ? props.travelIdData?.departure_time : data?.departure_time, 'required'),
        arrival_time: validator.message(t('arrival_time '), props.travelIdData ? props.travelIdData?.arrival_time : data?.arrival_time, 'required'),
        carriage_capacity: validator.message(t('carriage_capacity '), props.travelIdData ? props.travelIdData?.carriage_capacity : data?.carriage_capacity, 'required'),
        price_per_kg: validator.message(t('price_per_kg'), props.travelIdData ? props.travelIdData?.price_per_kg : data?.price_per_kg, 'required'),
        vehicle_type: validator.message(t('vehicle_type'), props.travelIdData ? props.travelIdData?.vehicle_type : data?.vehicle_type, 'required'),
        stopover_in_trip: validator.message(t('stopover_in_trip'), props.travelIdData ? props.travelIdData?.stopover_in_trip : data?.stopover_in_trip, 'required'),
    };

    const returnError = {
        return_departure_date: validator.message(t('return_departure_date'), props.travelIdData ? props.travelIdData?.return_departure_date : data?.return_departure_date, data?.trip_type == 2 ? 'required' : ""),
        return_arrival_date: validator.message(t('return_arrival_date'), props.travelIdData ? props.travelIdData?.return_arrival_date : data?.return_arrival_date, data?.trip_type == 2 ? 'required' : ""),
        return_departure_time: validator.message(t('return_departure_time'), props.travelIdData ? props.travelIdData?.return_departure_time : data?.return_departure_time, data?.trip_type == 2 ? 'required' : ""),
        return_arrival_time: validator.message(t('return_arrival_time'), props.travelIdData ? props.travelIdData?.return_arrival_time : data?.return_arrival_time, data?.trip_type == 2 ? 'required' : ""),
    }

    const GetAllByUser = () => {
        travel.GetAllUser().then((data) => {
            setTravellerUserData(data.filter((item) =>
                item.country_departure === props.allData.country_departure &&
                item.country_departure === props.allData.country_departure &&
                item.country_arrival === props.allData.country_arrival &&
                item.city_arrival_name === props.allData.city_arrival_name &&
                item.city_departure_name === props.allData.city_departure_name &&
                item.isComplete === false
            ))
        })
    }

    const getIdData = (id) => {
        travel.get(id).then((item) => {
            handleSetData('terms_and_condition', true);
            contextObj.setRequestedTraveller('');
            props.setTravelIdData(item);
        }).catch(error => {
            console.log("travel id", error);
        })

    }

    const getData = () => {
        country
            .country()
            .then((details) => {
                handleData("departureCountry", details)
                handleData("arriavalCountry", details)
                handleData("stopoverCountry", details)
            })
            .catch(error => {
                console.log("error", error);
            })
    };
    const getDepartureStateData = (id) => {
        country
            .state(id)
            .then((details) => {
                handleData("departureState", details)
            })
    };
    const getStopoverStateData = (id) => {
        country
            .state(id)
            .then((details) => {
                console.log("details", details);
                handleData("stopoverState", details)
            })
    };
    const getArrivalStateData = (id) => {
        country
            .state(id)
            .then((details) => {
                handleData("arriavalState", details)
            })
    };
    const getDepartureCity = (id) => {
        country
            .city(id)
            .then((details) => {
                handleData("departureCity", details)
            })
    };
    const getStopoverCity = (id) => {
        country
            .city(id)
            .then((details) => {
                handleData("stopoverCity", details)
            })
    };
    const getArrivalCity = (id) => {
        country
            .city(id)
            .then((details) => {
                handleData("arriavalCity", details)
            })
    };

    const handleData = (name, value) => {
        setDetails(prevState => ({
            ...prevState,
            [name]: value,
        }))
    };

    const handleSubmit = (event) => {
        if (validator.allValid()) {
            contextObj.setRequestedTraveller(data);
            props.setHide(2);
        } else {
            showMessage(true);
        }
    }

    const showError = () => {
        if (validator.allValid()) {
            showMessage(false);
        } else {
            showMessage(true);
        }
    }
    const numberInputOnWheelPreventChange = (e) => {
        e.target.blur()
        e.stopPropagation()
        setTimeout(() => {
            e.target.focus()
        }, 0)
    }
    return (
        <>
            {load ? (
                <Loading />
            ) : (
                <>
                    <form onSubmit={(e) => e.preventDefault()}
                        action="#"
                        method="post">
                        <div className="">
                            <div className="pt-3">
                                <h6 style={{ color: "red" }}>{t("form.Please_select_advertisement")}</h6>
                            </div>
                            <div className="col-lg-6">
                                <div className="custom-input-group">
                                    <select
                                        id="travellerId"
                                        onChange={(e) => {
                                            getIdData(e.target.value);
                                            handleSetData('luggage_id', '');
                                        }}
                                        name='travellerId'
                                    >
                                        <option value="" selected disabled>{t("homePage.Select")}</option>
                                        {travellerUserData?.map((item) =>
                                            <option value={item.id} key={item.id} >{item.title}</option>
                                        )}
                                    </select>
                                    <spna><i class="bi bi-caret-down-fill"></i></spna>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.title")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="text"
                                            placeholder={t("form.title")}
                                            id="title"
                                            onChange={handleChange}
                                            name='title'
                                            // value={data?.title}
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.title : data?.title}
                                        />
                                        <span style={{ color: "red" }}>{error.title}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.trip_type")}</label>
                                        <select
                                            id="trip_type"
                                            onChange={(e) => {
                                                if (e.target.value == 1) {
                                                    setFiled(0)
                                                }
                                                handleChange(e); handleSetData('vehicle_type', 0)
                                            }}
                                            name='trip_type'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.trip_type : data?.trip_type}

                                        >
                                            <option value="" selected disabled>{t("form.select_trip_type")}</option>
                                            <option value={1}>{t("form.one_way")}</option>
                                            <option value={2}>{t("form.round_trip")}</option>
                                        </select>
                                        <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>
                                        <span style={{ color: "red" }}>{error.trip_type}</span>
                                    </div>
                                </div>
                            </div>
                            {props.travelIdData?.trip_type == 2 || data?.trip_type == 2 ?
                                (
                                    <div className="row d-flex justify-content-center align-items-center pt-50">
                                        <div className='package-details-wrapper'>
                                            <div className='tour-package-details'>
                                                <div className="package-details-tabs">
                                                    <ul
                                                        className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                                                        id="pills-tab"
                                                        role="tablist"
                                                        style={{ marginBottom: '0px' }}
                                                    >
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                id="pills-package1"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pill-body1"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="pill-body1"
                                                                aria-selected="true"
                                                                style={{ fontSize: '16px', borderRadius: '0px' }}
                                                                onClick={() => { setFiled(0) }}
                                                                className={filed == 1 ? "nav-link" : "nav-link active"}
                                                                disabled={props?.travelIdData?.active_status}
                                                            >
                                                                {t("form.Onward_Journey_Details")}
                                                            </button>
                                                        </li>
                                                        <li className="nav-item" role="presentation">
                                                            <button
                                                                id="pills-package2"
                                                                data-bs-toggle="pill"
                                                                data-bs-target="#pill-body2"
                                                                type="button"
                                                                role="tab"
                                                                aria-controls="pill-body2"
                                                                aria-selected="false"
                                                                style={{ fontSize: '16px', borderRadius: '0px' }}
                                                                onClick={() => { setFiled(1) }}
                                                                className={filed == 1 ? "nav-link active" : "nav-link"}
                                                                disabled={props?.travelIdData?.active_status}
                                                            >
                                                                {t("form.Return_Journey_Details")}
                                                            </button>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ) : (null)}
                            <div className="row">
                                <div className="col-lg-6 row">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.country_Of_departure")}<span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                    <div className="">
                                        <h4>{props.allData.country_departure_name}</h4>
                                    </div>
                                </div>
                                <div className="col-lg-6 row">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.country_of_arrival")}<span style={{ color: 'red' }}>*</span></label>
                                    </div>
                                    <div className="">
                                        <h4>{props.allData.country_arrival_name}</h4>
                                    </div>
                                </div>
                            </div>

                            {props.travelIdData?.trip_type == 2 || data?.trip_type == 2 && filed == 1 ? <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name" style={{ display: 'block' }}>{t("form.return_departure_date")}<span style={{ color: 'red' }}>*</span></label>
                                        {/* <input
                                            required
                                            type="Date"
                                            id="return_departure_date"
                                            onChange={handleChange}
                                            name='return_departure_date'
                                            disabled={props.travelIdData}
                                            value={props.travelIdData ? props.travelIdData?.return_departure_date : data?.return_departure_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                placeholderText={i18n.language === 'frFR' ? "dd/mm/yyyy" : "mm/dd/yyyy"}
                                                selected={props.travelIdData?.return_departure_date ? moment(props.travelIdData?.return_departure_date, 'DD/MM/YYYY').toDate() : data?.return_departure_date && moment(data?.return_departure_date, 'DD/MM/YYYY').toDate()}
                                                dateFormat={i18n.language === 'frFR' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                                onChange={(date) => handleSetData('return_departure_date', moment(date).format('DD/MM/YYYY'))}
                                                minDate={moment(data?.arrival_date, 'DD/MM/YYYY').toDate()}
                                                maxDate={moment(data?.return_arrival_date, 'DD/MM/YYYY').toDate()}
                                                className="px-3" disabled={props.travelIdData ? props.travelIdData : !data?.arrival_date}
                                            />
                                        </div>
                                        <span style={{ color: "red" }}>{returnError.return_departure_date}</span>
                                    </div>
                                </div >
                                <div className="col-lg-6">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name" style={{ display: 'block' }}>{t("form.return_arrival_date")}<span style={{ color: 'red' }}>*</span></label>
                                        {/* <input
                                            required
                                            type="Date"
                                            id="return_arrival_date"
                                            onChange={handleChange}
                                            name='return_arrival_date'
                                            disabled={props.travelIdData}
                                            value={props.travelIdData ? props.travelIdData?.return_arrival_date : data?.return_arrival_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                placeholderText={i18n.language === 'frFR' ? "dd/mm/yyyy" : "mm/dd/yyyy"}
                                                selected={props.travelIdData?.return_arrival_date ? moment(props.travelIdData?.return_arrival_date, 'DD/MM/YYYY').toDate() : data?.return_arrival_date && moment(data?.return_arrival_date, 'DD/MM/YYYY').toDate()}
                                                dateFormat={i18n.language === 'frFR' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                                onChange={(date) => handleSetData('return_arrival_date', moment(date).format('DD/MM/YYYY'))}
                                                minDate={moment(data?.return_departure_date, 'DD/MM/YYYY').toDate()} className="px-3"
                                                disabled={props.travelIdData ? props.travelIdData : !data?.return_departure_date}
                                            />
                                        </div>
                                        <span style={{ color: "red" }}>{returnError.return_arrival_date}</span>
                                    </div>
                                </div>
                            </div > : ""}
                            {props.travelIdData?.trip_type == 2 || data?.trip_type == 2 && filed == 1 ? <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.Return_departure_time")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="time"
                                            id="return_departure_time"
                                            onChange={handleChange}
                                            name='return_departure_time'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.return_departure_time : data?.return_departure_time}
                                        />
                                        <span style={{ color: "red" }}>{returnError.return_departure_time}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name">{t("form.return_arrival_time")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="time"
                                            id="return_arrival_time"
                                            onChange={handleChange}
                                            name='return_arrival_time'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.return_arrival_time : data?.return_arrival_time}
                                        />
                                        <span style={{ color: "red" }}>{returnError.return_arrival_time}</span>
                                    </div>
                                </div>
                            </div> : ""}

                            {filed == 0 && <><div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name" style={{ display: 'block' }}>{t("form.departure_date")}<span style={{ color: 'red' }}>*</span></label>
                                        {/* <input
                                            required
                                            type="Date"
                                            id="departure_date"
                                            onChange={handleChange}
                                            name='departure_date'
                                            disabled={props.travelIdData}
                                            // value={moment(props.travelIdData ? props.travelIdData?.departure_date : data?.departure_date).formate("yyyy/mm/dd")}
                                            value={props.travelIdData ? props.travelIdData?.departure_date : data?.departure_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                placeholderText={i18n.language === 'frFR' ? "dd/mm/yyyy" : "mm/dd/yyyy"}
                                                selected={props.travelIdData?.departure_date ? moment(props.travelIdData?.departure_date, 'DD/MM/YYYY').toDate() : data?.departure_date && moment(data?.departure_date, 'DD/MM/YYYY').toDate()}
                                                dateFormat={i18n.language === 'frFR' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                                onChange={(date) => handleSetData('departure_date', moment(date).format('DD/MM/YYYY'))}
                                                minDate={new Date()}
                                                maxDate={moment(data.arrival_date, 'DD/MM/YYYY').toDate()}
                                                className="px-3"
                                                disabled={props?.travelIdData?.active_status}
                                            />
                                        </div>
                                        <span style={{ color: "red" }}>{error.departure_date}</span>
                                    </div>
                                </div >
                                <div className="col-lg-6">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name" style={{ display: 'block' }}>{t("form.arrival_date")}<span style={{ color: 'red' }}>*</span></label>
                                        {/* <input
                                            required
                                            type="Date"
                                            id="arrival_date"
                                            onChange={handleChange}
                                            name='arrival_date'
                                            disabled={props.travelIdData}
                                            value={props.travelIdData ? props.travelIdData?.arrival_date : data?.arrival_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                                        <div className="customDatePickerWidth">
                                            <DatePicker
                                                placeholderText={i18n.language === 'frFR' ? "dd/mm/yyyy" : "mm/dd/yyyy"}
                                                selected={props.travelIdData?.arrival_date ? moment(props.travelIdData?.arrival_date, 'DD/MM/YYYY').toDate() : data?.arrival_date && moment(data?.arrival_date, 'DD/MM/YYYY').toDate()}
                                                dateFormat={i18n.language === 'frFR' ? "dd/MM/yyyy" : "MM/dd/yyyy"}
                                                onChange={(date) => handleSetData('arrival_date', moment(date).format('DD/MM/YYYY'))}
                                                minDate={moment(data?.departure_date, 'DD/MM/YYYY').toDate()} className="px-3"
                                                disabled={props.travelIdData?.active_status ? props.travelIdData?.active_status : !data?.departure_date}
                                            />
                                        </div>
                                        <span style={{ color: "red" }}>{error.arrival_date}</span>
                                    </div>
                                </div>
                            </div >
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="custom-input-group">
                                            <label htmlFor="name">{t("form.departure_time")}<span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                required
                                                type="time"
                                                id="departure_time"
                                                onChange={handleChange}
                                                name='departure_time'
                                                disabled={props?.travelIdData?.active_status}
                                                value={props.travelIdData ? props.travelIdData?.departure_time : data?.departure_time}
                                            />
                                            <span style={{ color: "red" }}>{error.departure_time}</span>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="custom-input-group ">
                                            <label htmlFor="name">{t("form.arrival_time")}<span style={{ color: 'red' }}>*</span></label>
                                            <input
                                                required
                                                type="time"
                                                id="arrival_time"
                                                onChange={handleChange}
                                                name='arrival_time'
                                                disabled={props?.travelIdData?.active_status}
                                                value={props.travelIdData ? props.travelIdData?.arrival_time : data?.arrival_time}
                                            />
                                            <span style={{ color: "red" }}>{error.arrival_time}</span>
                                        </div>
                                    </div>
                                </div></>}

                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.carriage_capacity")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="numberType"
                                            required
                                            onWheel={numberInputOnWheelPreventChange}
                                            type="number"
                                            placeholder=""
                                            id="carriage_capacity"
                                            onChange={handleChange}
                                            name='carriage_capacity'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.carriage_capacity : data?.carriage_capacity}
                                        />
                                        <span style={{ position: 'absolute', right: 15, bottom: (error.carriage_capacity ? '37px' : '15px'), fontWeight: '500', opacity: 0.6 }}>kg</span>
                                        <span style={{ color: "red" }}>{error.carriage_capacity}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name">{t("form.price_per_kg")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            className="numberType"
                                            required
                                            type="number"
                                            onWheel={numberInputOnWheelPreventChange}
                                            placeholder=""
                                            id="price_per_kg"
                                            onChange={handleChange}
                                            name='price_per_kg'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.price_per_kg : data?.price_per_kg}
                                        />
                                        <span style={{ position: 'absolute', right: 20, bottom: (error.price_per_kg ? '37px' : '15px'), fontWeight: '500', opacity: 0.6 }}>{contextObj?.loginUser?.symbol}</span>
                                        <span style={{ color: "red" }}>{error.price_per_kg}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <div className="">
                                            <label>{t("form.Is_a_stopover")}</label>
                                        </div>
                                        <div className="package-sidebar" >
                                            <aside className="package-widget widget-duration " style={{ boxShadow: 'none' }} >
                                                <div className="widget-body ">
                                                    <ul className="row">
                                                        <li className="deration-check col-lg-3 col-md-3">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                id="stopover_in_trip"
                                                                name="stopover_in_trip"
                                                                required
                                                                onChange={(e) => {
                                                                    setIsRadio(true);
                                                                    handleSetData('stopover_in_trip', false);
                                                                    setOpen(false);
                                                                    // showMessage(false);
                                                                }}
                                                                checked={data?.stopover_in_trip === 0 ? (props?.travelIdData ? !props?.travelIdData?.stopover_in_trip : false) : !data?.stopover_in_trip}
                                                                // checked={isRadio}
                                                                disabled={props?.travelIdData?.active_status}
                                                            />
                                                            <label className="form-check-label" htmlFor="stopover_in_trip" style={{ fontWeight: '600' }}>
                                                                {t("form.no")}
                                                            </label>
                                                        </li>
                                                        <li className="deration-check col-lg-3 col-md-3">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                id="stopover_in_trip"
                                                                name='stopover_in_trip'
                                                                required
                                                                onChange={(e) => {
                                                                    setIsRadio(false);
                                                                    handleSetData('stopover_in_trip', true);
                                                                    setOpen(true);
                                                                    showMessage(false);
                                                                }}
                                                                disabled={props?.travelIdData?.active_status}
                                                                checked={props?.luggageIdData ? props?.luggageIdData?.warranty : data?.warranty}
                                                            />
                                                            <label className="form-check-label" htmlFor="stopover_in_trip" style={{ fontWeight: '600' }}>
                                                                {t("form.yes")}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group">

                                    </div>
                                </div>
                            </div>
                            {
                                open ?
                                    (
                                        <>
                                            <div className="">
                                                <div className="col-lg-6">
                                                    <div className="custom-input-group">
                                                        <label htmlFor="name">{t("form.country_Of_stopper")}<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            required
                                                            id="SelectElement"
                                                            onChange={(e) => {
                                                                handleChange(e, "country_Of_stopper");
                                                                handleSetData("state_stopover", "");
                                                                handleSetData("city_stopover", "");
                                                                getStopoverStateData(e.target.value);
                                                            }}
                                                            name='country_stopover'
                                                            disabled={props?.travelIdData?.active_status}
                                                            value={props.travelIdData ? props.travelIdData?.country_stopover : data?.country_stopover}
                                                        >
                                                            <option value="" selected disabled>{t("form.country_Of_stopper")}</option>
                                                            {details.stopoverCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                        </select>
                                                        <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="col-lg-6">
                                                    <div className="custom-input-group">
                                                        <label htmlFor="name">{t("form.state_Of_stopper")}<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            required
                                                            id="state_stopover"
                                                            onChange={(e) => {
                                                                handleChange(e, "state_of_stopover");
                                                                handleSetData("city_stopover", "");
                                                                getStopoverCity(e.target.value);
                                                            }}
                                                            name='state_stopover'
                                                            disabled={props?.travelIdData?.active_status}
                                                            value={props.travelIdData ? props.travelIdData?.state_stopover : data?.state_stopover}
                                                        >
                                                            <option value="" selected disabled>{t("form.state_Of_stopper")}</option>
                                                            {details.stopoverState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                        </select>
                                                        <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="">
                                                <div className="col-lg-6">
                                                    <div className="custom-input-group">
                                                        <label htmlFor="name">{t("form.city_of_stopover")}<span style={{ color: 'red' }}>*</span></label>
                                                        <select
                                                            required
                                                            id="city_stopover"
                                                            onChange={(e) => {
                                                                handleChange(e, "city_of_stopover");
                                                            }}
                                                            name='city_stopover'
                                                            disabled={props?.travelIdData?.active_status}
                                                            value={data?.city_stopover}
                                                        >

                                                            <option value="" selected disabled>{t("form.city_of_stopover")}</option>
                                                            {details.stopoverCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                                                        </select>
                                                        <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    ) : (null)
                            }
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.pick_up_place")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="text"
                                            placeholder={t("form.enter_pick_up_place")}
                                            id="pickup_place"
                                            onChange={handleChange}
                                            name='pickup_place'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.pickup_place : data?.pickup_place}
                                        />
                                        <span style={{ color: "red" }}>{error.pickup_place}</span>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group ">
                                        <label htmlFor="name">{t("form.delivery_place")}<span style={{ color: 'red' }}>*</span></label>
                                        <input
                                            required
                                            type="text"
                                            placeholder={t("form.enter_delivery_place")}
                                            id="delivery_place"
                                            onChange={handleChange}
                                            name='delivery_place'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props.travelIdData?.delivery_place : data?.delivery_place}
                                        />
                                        <span style={{ color: "red" }}>{error.delivery_place}</span>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.vehicle_type")}<span style={{ color: 'red' }}>*</span></label>
                                        <select
                                            required
                                            id="vehicle_type"
                                            onChange={handleChange}
                                            name='vehicle_type'
                                            disabled={props?.travelIdData?.active_status}
                                            value={props.travelIdData ? props?.travelIdData?.vehicle_type : data?.vehicle_type ? data?.vehicle_type : 0}
                                        >
                                            <option value={0} selected disabled>{t("form.type_of_transport")}</option>
                                            <option value={1}>{t("form.bike")}</option>
                                            <option value={2}>{t("form.Boat")}</option>
                                            <option value={3}>{t("form.Bus")}</option>
                                            <option value={4}>{t("form.car")}</option>
                                            <option value={5}>{t("form.Kick_scooter")}</option>
                                            <option value={6}>{t("form.train")}</option>
                                            <option value={7}>{t("form.MoterBike")}</option>
                                            <option value={8}>{t("form.plane")}</option>
                                            <option value={9}>{t("form.Scooter")}</option>
                                            <option value={10}>{t("form.Tgv")}</option>
                                            <option value={11}>{t("form.Tramway")}</option>
                                        </select>
                                        <spna><i class="bi bi-caret-down-fill" style={{ marginTop: "21px" }}></i></spna>
                                        <span style={{ color: "red" }}>{error.vehicle_type}</span>
                                    </div>
                                </div>
                                <div className="col-lg-2">
                                    <div className="custom-input-group">
                                        {!props?.travelIdData && data?.vehicle_type == 0 ? false :
                                            <img
                                                src={
                                                    (props?.travelIdData?.vehicle_type == 1 || data?.vehicle_type == 1) ? "/images/vehicles/Bike.jpg" : (props?.travelIdData?.vehicle_type == 2 || data?.vehicle_type == 2) ? "/images/vehicles/boat.jpg" : props?.travelIdData?.vehicle_type == 3 || data?.vehicle_type == 3 ? "/images/vehicles/bus.png" : props?.travelIdData?.vehicle_type == 4 || data?.vehicle_type == 4 ? "/images/vehicles/car.png" : props?.travelIdData?.vehicle_type == 5 || data?.vehicle_type == 5 ? "/images/vehicles/kickscooter.jpg" : props?.travelIdData?.vehicle_type == 6 || data?.vehicle_type == 6 ? "/images/vehicles/train.png" : props?.travelIdData?.vehicle_type == 7 || data?.vehicle_type == 7 ? "/images/vehicles/MoterBike.png" : props?.travelIdData?.vehicle_type == 8 || data?.vehicle_type == 8 ? "/images/vehicles/plan.jpg" : props?.travelIdData?.vehicle_type == 9 || data?.vehicle_type == 9 ? "/images/vehicles/scooter.jpg" : props?.travelIdData?.vehicle_type == 10 || data?.vehicle_type == 10 ? "/images/vehicles/Tvg.png" : props?.travelIdData?.vehicle_type == 11 || data?.vehicle_type == 11 ? "/images/vehicles/Tramway.jpg" : ""
                                                }
                                                height="100%"
                                                width="100%"
                                            />}
                                    </div>
                                </div>
                                {!props?.travelIdData && data?.vehicle_type == 0 ? false :
                                    <div className="col-lg-6">
                                        <div className="custom-input-group">
                                            <label htmlFor="name">{props?.travelIdData?.vehicle_type == 1 || data?.vehicle_type == 1 ? "" : props?.travelIdData?.vehicle_type == 2 || data?.vehicle_type == 2 ? t("form.Boat") : props?.travelIdData?.vehicle_type == 3 || data?.vehicle_type == 3 ? t("form.Bus") : props?.travelIdData?.vehicle_type == 4 || data?.vehicle_type == 4 ? t("form.Car_Registration") : props?.travelIdData?.vehicle_type == 5 || data?.vehicle_type == 5 ? t("form.kick_scooter") : props?.travelIdData?.vehicle_type == 6 || data?.vehicle_type == 6 ? t("form.Train_Number") : props?.travelIdData?.vehicle_type == 7 || data?.vehicle_type == 7 ? t("form.MoterBike") : props?.travelIdData?.vehicle_type == 8 || data?.vehicle_type == 8 ? t("form.Flight_Number") : props?.travelIdData?.vehicle_type == 9 || data?.vehicle_type == 9 ? t("form.Scooter") : props?.travelIdData?.vehicle_type == 10 || data?.vehicle_type == 10 ? 'Tvg' : props?.travelIdData?.vehicle_type == 11 || data?.vehicle_type == 11 ? t("form.Tramway") : ""}</label>
                                            {props?.travelIdData?.vehicle_type == 1 || data?.vehicle_type == 1 ? " " :
                                                <input
                                                    required
                                                    type="text"
                                                    placeholder={props?.travelIdData?.vehicle_type == 1 || data?.vehicle_type == 1 ? "" : props?.travelIdData?.vehicle_type == 2 || data?.vehicle_type == 2 ? t("form.Boat") : props?.travelIdData?.vehicle_type == 3 || data?.vehicle_type == 3 ? t("form.Bus") : props?.travelIdData?.vehicle_type == 4 || data?.vehicle_type == 4 ? t("form.Car_Registration") : props?.travelIdData?.vehicle_type == 5 || data?.vehicle_type == 5 ? t("form.kick_scooter") : props?.travelIdData?.vehicle_type == 6 || data?.vehicle_type == 6 ? t("form.Train_Number") : props?.travelIdData?.vehicle_type == 7 || data?.vehicle_type == 7 ? t("form.MoterBike") : props?.travelIdData?.vehicle_type == 8 || data?.vehicle_type == 8 ? t("form.Flight_Number") : props?.travelIdData?.vehicle_type == 9 || data?.vehicle_type == 9 ? t("form.Scooter") : props?.travelIdData?.vehicle_type == 10 || data?.vehicle_type == 10 ? 'Tvg' : props?.travelIdData?.vehicle_type == 11 || data?.vehicle_type == 11 ? t("form.Tramway") : ""}
                                                    id="vehicle_details"
                                                    onChange={handleChange}
                                                    name='vehicle_details'
                                                    disabled={props?.travelIdData?.active_status}
                                                    value={props.travelIdData ? props.travelIdData?.vehicle_details : data?.vehicle_details}
                                                />}
                                        </div>
                                    </div>}
                            </div>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="custom-input-group">
                                        <div className="">
                                            <label>{t("form.travel_insurance")}</label>
                                        </div>
                                        <div className="package-sidebar" >
                                            <aside className="package-widget widget-duration " style={{ boxShadow: 'none' }} >
                                                <div className="widget-body ">
                                                    <ul className="row">
                                                        <li className="deration-check col-lg-3 col-md-3">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                id="insurance"
                                                                name="insurance"
                                                                required
                                                                disabled={props?.travelIdData?.active_status}
                                                                onChange={(e) => {
                                                                    setIsShow(false)
                                                                    handleSetData('insurance', false);
                                                                }}
                                                                checked={data?.insurance === 0 ? (props?.travelIdData ? !props?.travelIdData?.insurance : false) : !data?.insurance}
                                                            />
                                                            <label className="form-check-label" htmlFor="insurance" style={{ fontWeight: '600' }}>
                                                                {t("form.no")}
                                                            </label>
                                                        </li>
                                                        <li className="deration-check col-lg-3 col-md-3">
                                                            <input className="form-check-input"
                                                                type="radio"
                                                                id="insurance"
                                                                name='insurance'
                                                                required
                                                                disabled={props?.travelIdData?.active_status}
                                                                onChange={(e) => {
                                                                    setIsShow(true)
                                                                    handleSetData('insurance', true);
                                                                }}
                                                                checked={props?.travelIdData ? props?.travelIdData?.insurance : data?.insurance}
                                                            />
                                                            <label className="form-check-label" htmlFor="insurance" style={{ fontWeight: '600' }}>
                                                                {t("form.yes")}
                                                            </label>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </aside>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="custom-input-group">

                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className=" col-12 col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.packaging_instruction")}</label>
                                        <textarea rows={4}
                                            id="packing_instruction"
                                            onChange={handleChange}
                                            name='packing_instruction'
                                            maxLength={160}
                                            disabled={props?.travelIdData?.active_status}
                                            value={props?.travelIdData ? props?.travelIdData?.packing_instruction : data?.packing_instruction}
                                        >
                                        </textarea>
                                        {/* <span style={{ color: "red" }}>{error.packing_instruction}</span> */}
                                    </div>
                                </div>
                                <div className="col-12 col-lg-6">
                                    <div className="custom-input-group">
                                        <label htmlFor="name">{t("form.Description")}</label>
                                        <textarea rows={4}
                                            id="description"
                                            onChange={handleChange}
                                            name='description'
                                            maxLength={160}
                                            disabled={props?.travelIdData?.active_status}
                                            value={props?.travelIdData ? props?.travelIdData?.description : data?.description}
                                        >
                                        </textarea>
                                        {/* <span style={{ color: "red" }}>{error.description}</span> */}
                                    </div>
                                </div>
                            </div>
                            {
                                props.travelIdData?.trip_type || data?.trip_type == 1 ?
                                    (
                                        <div className="row">
                                            <div className=" col-lg-12 package-sidebar">
                                                <aside className="package-widget widget-tour-categoris mt-30" style={{ boxShadow: 'none' }}>
                                                    <div className="widget-body">
                                                        <div className="">
                                                            <input className="form-check-input" type="checkbox" id="cate"
                                                                onChange={(e) => {
                                                                    handleSetData('terms_and_condition', e.target.checked ? true : false);
                                                                }}
                                                                checked={data?.terms_and_condition}
                                                            // disabled={props.travelIdData}
                                                            />

                                                            {' '}<span>{t("form.Terms_and_condition")}</span>
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                            <div className="col-lg-6 package-sidebar">
                                                <aside className="package-widget widget-tour-categoris" style={{ boxShadow: 'none' }}>
                                                    <div className="widget-body">
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" id="cate"
                                                                onChange={(e) => {
                                                                    handleSetData('sender_signature', e.target.checked ? true : false);
                                                                }}
                                                                checked={data?.sender_signature}
                                                            />
                                                            {' '}<span style={{ margin: "-5px 0px 0px 6px" }}>{t("form.digital_signature")}</span>
                                                            {data?.sender_signature == true ?
                                                                <div class="digital"> <svg class="animated-check" viewBox="0 0 24 24">
                                                                    <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                                                                </div> : null
                                                            }
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>

                                    ) :
                                    (filed == 1 ?
                                        (<div>
                                            <div className="package-sidebar">
                                                <aside className="package-widget widget-tour-categoris" style={{ boxShadow: 'none' }}>
                                                    <div className="widget-body">
                                                        <div class="d-flex" >
                                                            <input className="form-check-input" type="checkbox" id="cate"
                                                                name='oneWayTicket'
                                                                onChange={(e) => {
                                                                    handleSetData('terms_and_condition', e.target.checked ? true : false);
                                                                }}
                                                            />
                                                            <label style={{ fontSize: '14px', paddingLeft: "5px" }}>{t('profile.i_would_like_to_publish')}</label>
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                            <div className="package-sidebar">
                                                <aside className="package-widget widget-tour-categoris" style={{ boxShadow: 'none' }}>
                                                    <div className="widget-body">
                                                        <div className="d-flex">
                                                            <input className="form-check-input" type="checkbox" id="cate"
                                                                onChange={(e) => {
                                                                    handleSetData('sender_signature', e.target.checked ? true : false);
                                                                }}
                                                                checked={data?.sender_signature}
                                                            />
                                                            {' '}<span style={{ marginLeft: "5px", marginTop: "-3px" }}>{t("form.digital_signature")}</span>
                                                            {data?.sender_signature == true ?
                                                                <div class="digital">
                                                                    <svg class="animated-check" viewBox="0 0 24 24">
                                                                        <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                                                                </div>
                                                                : null
                                                            }
                                                        </div>
                                                    </div>
                                                </aside>
                                            </div>
                                        </div>
                                        ) : null
                                    )
                            }
                            {(props.travelIdData?.trip_type || data?.trip_type) &&
                                (props.travelIdData?.trip_type || data?.trip_type == 1 ?
                                    (
                                        <div className="custom-input-group row col-lg-12">
                                            <div className=" col-sm-6 col-md-6 col-lg-">
                                                <div className="submite-btn" >
                                                    {/* <Link to={`${process.env.PUBLIC_URL}/sellArticle`}>   */}
                                                    <button
                                                        type="submit"
                                                        disabled={!((data?.terms_and_condition || props.travelIdData?.terms_and_condition) && data?.sender_signature)}
                                                        onClick={handleSubmit}
                                                        className="btn btn-secondary"
                                                        style={{
                                                            fontSize: '14px'
                                                        }}>
                                                        {t("form.Preview_Advert")}
                                                    </button>
                                                    {/* </Link> */}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                    :
                                    (filed == 1 ?
                                        <div className="custom-input-group row col-lg-12">
                                            <div className=" col-sm-6 col-md-6 col-lg-">
                                                <div className="submite-btn" >
                                                    <button
                                                        type="submit"
                                                        disabled={!((data?.terms_and_condition || props.travelIdData?.terms_and_condition) && data?.sender_signature)}
                                                        onClick={handleSubmit}
                                                        className="btn btn-secondary"
                                                        style={{
                                                            fontSize: '14px'
                                                        }}>
                                                        {t("form.Preview_Advert")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div> :
                                        <div className="custom-input-group row col-lg-12">
                                            <div className=" col-sm-6 col-md-6 col-lg-">
                                                <div className="submite-btn" >
                                                    <button
                                                        type="submit"
                                                        onClick={() => {
                                                            setFiled(1);
                                                            showError()
                                                        }}
                                                        className="btn btn-secondary"
                                                        style={{
                                                            fontSize: '14px'
                                                        }}>
                                                        {t("form.Submit_and_fill")}
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div >
                        <div className="col-12  mt-2">
                            <p>
                                {t("form.Note")}<br />
                                {t("form.aerosol")}<br />
                                {t("form.firearm")}<br />
                                {t("form.ammunition")}<br />
                                {t("form.weapon")}<br />
                                {t("form.general")}
                            </p>
                        </div>
                    </form >
                </>
            )}
        </>
    )
}
export default TravellerToLuggage;