import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Luggages from "./Luggages";

function SubmitLuggageWrapperArea() {
  const { t } = useTranslation();


  return (
    <>
      <div className="custom-input-group row" >
        <div className="submite-btn" style={{ position: 'absolute', textAlign: 'center', bottom: '30px', zIndex: "1" }}>
          <Link to={'/submitTravels'}><button type="submit" id="travelbtnColor" style={{  borderRadius: '5px', backgroundColor: '#ff4838', color: 'white' }} >{t("menu.travels")}</button></Link>
          <Link to={'/submitLuggages'}><button type="submit" id="LuggageBtnColor" style={{ margin: '10px', borderRadius: '5px',backgroundColor: 'white', color: '#ff4838', }}>{t("menu.luggages")}</button></Link>
        </div>
      </div>
      <Luggages />
    </>
  );
}

export default SubmitLuggageWrapperArea;
