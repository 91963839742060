import React, { useContext, useEffect, useState } from 'react'
import "react-modal-video/css/modal-video.css";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import luggage from "../../../models/luggage";
import notistack from "../../../plugin/notistack";
import { country } from "../../../models";
import AppContext from '../../../store/context/app-context';


const defaultData = {
  type_of_traveler: '',
  title: '',
  country_departure: '',
  country_arrival: '',
  state_departure: '',
  state_arrival: '',
  city_departure: '',
  city_arrival: '',
  pickup_place: '',
  delivery_place: '',
  departure_date: '',
  arrival_date: '',
  departure_time: '',
  arrival_time: '',
  carriage_capacity: '',
  price_per_kg: '',
  trip_type: '',
  height: '',
  weight: '',
  length: '',
  transport_type: '',
  packing_instruction: '',
  description: '',
}
const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

export default function Luggages() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const contextObj = useContext(AppContext);
  const history = useHistory();
  const { t } = useTranslation();
  const [data, setData] = useState(defaultData);
  const [details, setDetails] = useState(format);
  const [traveldetails, settraveldetails] = useState([])


  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleSubmit = (event) => {
    luggage.create(data).then(() => {
      setData(defaultData);
      console.log(data);
      notistack.success(t('form.add_submited'));
      history.push(`/sellArticle`)
    })
  }
  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details)
        handleData("arriavalCountry", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  useEffect(() => {
    getAllData();
  }, [])
  const getAllData = () => {
    luggage.list().then(responseData => {
      if (responseData)
        settraveldetails(responseData);
    })
  }
  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };
   
  const filterLuggageDetails = () => {
    return traveldetails.filter((item) => contextObj.luggage?.country ? item.country_arrival == contextObj.luggage?.country.value : item)
    .filter((item) => contextObj.luggage?.state.value ? item.state_arrival == contextObj.luggage?.state.value : item)
    .filter((item) => contextObj.luggage?.city.value ? item.city_arrival == contextObj.luggage?.city.value : item)
    .filter((item) => contextObj.luggage?.particular ? contextObj.luggage?.particular == item.user_account_type : item)
    .filter((item) => contextObj.luggage?.professional ? contextObj.luggage?.professional == item.user_account_type : item)
  }

  return (
    <div className="package-area package-style-two  pb-110 chain b">
      <div className="container">
        <div className="row justify-content-center">
          <div className="row">
            <div className='col-12 col-md-9 col-lg-9'>
              <h2>{t("cobagage.recent_listing")}</h2>
            </div>
            <div className='custom-input-group col-lg-3 col-12 col-md-3 ' style={{ marginBottom: '20px' }}>
              <select
                id="Ads_Category"
                onChange={handleChange}
                name='Ads_Category'
              // value={data.Ads_Category}
              >
                <option value={''} selected disabled>{t("cobagage.default_sorting")}</option>
                <option>{t("cobagage.newest_first")}</option>
                <option>{t("cobagage.oldest_first")}</option>
              </select>
              <select
                id="Ads_Category"
                onChange={handleChange}
                name='Ads_Category'
              // value={data.Ads_Category}
              >
                <option value={''} selected disabled>{t("cobagage.select_arrival_country")}</option>
                {details.arriavalCountry?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
              </select>
            </div>
          </div>
        </div>
        <div className="row d-flex justify-content-center g-4">
          {filterLuggageDetails()?.map(item => (
            <div className="col-lg-3 col-md-6 col-sm-10">
              <div className="package-card-delta">
                <div className="package-thumb">
                  <Link to={`${process.env.PUBLIC_URL}/package-details`} onClick={scrollTop}><img src={item.image[0]} alt="" /></Link>
                  <p className="card-lavel" style={{ fontSize: '12px', textAlign: 'end', minWidth: '20px' }}>
                    {t("cobagage.unverified")}
                  </p>
                  <h3 style={{ position: 'absolute', bottom: '45px', left: '20px' }}><Link to={`${process.env.PUBLIC_URL}/package-details`} onClick={scrollTop} style={{ color: 'white', fontSize: '18px' }}>{item.title}</Link></h3>
                  <div style={{ position: 'absolute', bottom: '0px', left: '20px', backgroundColor: 'white', borderTopRightRadius: '7px', borderTopLeftRadius: '7px' }}>
                    <h3 className="p-card-title" style={{ fontSize: '16px', padding: "8px 20px 0px 20px", fontWeight: '700' }}>{item.luggage_estimation}€ {t("cobagage.per")} kg</h3>
                  </div>
                </div>
                <div className="package-card-body">
                  <div className="row">
                    <div className="col col-6 col-lg-6 col-md-6 col-sm-6" style={{ position: 'absolute', top: '0px', left: '0px' }}>
                      <p>
                        <i class="bi bi-calendar" />
                        <span style={{ fontSize: '12px', fontWeight: '500' }}> 04 Feb 2023</span>
                      </p>
                    </div>
                    <div className="col col-6 col-lg-6 col-md-6 col-sm-6" style={{ position: 'absolute', top: '0px', right: '0px' }}>
                      <p>
                        <i class="bi bi-person-fill" />
                        <Link><span style={{ fontSize: '12px', fontWeight: '500', color: '#ff4838' }}>UserName</span></Link>
                      </p>
                    </div>
                  </div>
                  <table className='col-lg-12 col-12 col-md-12' style={{ fontSize: '12px', fontWeight: '700' }}>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.from")}</td>
                      <td style={{ textAlign: 'end' }}>{item.country_departure_name}/{item.city_departure_name}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.to")}</td>
                      <td style={{ textAlign: 'end' }}>{item.country_arrival_name}/{item.city_arrival_name}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.carriage_capacity")}</td>
                      <td style={{ textAlign: 'end' }}>{item.weight}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.departure_date")}</td>
                      <td style={{ textAlign: 'end' }}>{item.departure_date}</td>
                    </tr>
                    <tr>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.arrival_date")}</td>
                      <td style={{ textAlign: 'end' }}>{item.arrived_date}</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}> {t("cobagage.article_for_sell")}</td>
                      <td style={{ textAlign: 'end' }}>3214800</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.article_donation")}</td>
                      <td style={{ textAlign: 'end' }}>0</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.type_of_traveler")}</td>
                      <td style={{ textAlign: 'end' }}>
                        {item.packer === "1" ? "Regular" : (item.packer === "2" ? "Periodical" : "One-Off")}</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.member_since")}</td>
                      <td style={{ textAlign: 'end' }}>30-12-2022</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.trips_made")}</td>
                      <td style={{ textAlign: 'end' }}>23</td>
                    </tr>
                    <tr style={{ color: '#ff4838' }}>
                      <td style={{ opacity: 0.6 }}>{t("cobagage.luggages_sent")}</td>
                      <td style={{ textAlign: 'end' }}>10</td>
                    </tr>
                  </table>
                  <div className="p-card-bottom">
                    <div className="book-btn">
                      <p style={{ fontSize: '12px', fontWeight: '700', color: '#ff4838' }}>{t("cobagage.user_rating")}</p>
                    </div>
                    <div className="p-card-info">
                      <i class="bi bi-star-fill" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                      <i class="bi bi-star" style={{ margin: '2px' }}></i>
                    </div>
                  </div>
                  <div className="p-card-bottom">
                    <div className="book-btn">
                      <Link to={`${process.env.PUBLIC_URL}/package-details`} style={{ fontSize: '11px', fontWeight: '800' }} onClick={scrollTop}>{t("cobagage.detail")}<i className="bx bxs-right-arrow-alt" /></Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>))}
        </div>
      </div>
    </div>
  )
}
