const profile = {
  post_an_advert: "Post an Advert",
  dashboard: "Dashboard",
  my_profile: "My Profile",
  change_password: "Change Password",
  my_subscription_plan: "My Subscription Plan",
  my_requests: "My Requests",
  my_pending_payments: "My Pending Payments",
  my_orders: "My Orders",
  my_travels: "My Travels",
  my_luggages: "My Luggages",
  my_articles: "My Articles",
  my_article_donations: "My article donation",
  earn_spends_report: "Earn/Spends Report",
  cobagage_wallet: "Cobagage Wallet",
  subscription_balance: "Subscription Balance",
  payments_sent: "Payments Sent",
  payments_received: "Payments Received",
  subscribe_history: "Subscribe History",
  delete_account: "Delete Account",
  travels_completed: "Travels completed",
  recent_travel_announcements: "Recent travel announcements",
  recent_luggage_sending_requests: "Recent luggage sending requests",
  view_all_luggage_requests: "View all luggage requests",
  luggages_sent: "Luggage(s) sent",
  Filter: "Filter",
  Previous: "Previous",
  Next: "Next",
  Subscription_Buying: "Subscription Buying",
  Subscription_Balance: "Subscription Balance",
  Transaction_ID: "Transaction ID",
  Transaction_Date_Time: "Transaction Date & Time",
  Plan_name: "Plan name",
  Amount: "Amount",
  intermedite: "Intermedite",
  expert: "Expert",
  Description: "Description",
  Balance_Change: "Balance Change",
  Balance_remaining: "Balance remaining",
  Individual_Travellers: "Individual Traveller",
  Professional_Travellers: "Professional Traveller",
  recent_luggage_requests: "Recent luggage requests",
  article_on_sale: "Article on sale",
  articles_sold: "Articles sold",
  articles_listed_for_sale: "Articles listed for sale",
  article_for_donation: "Article for donation",
  articles_donated: "articles donated",
  articles_listed_for_donations: "articles listed for donations",
  // -----------------my profile----------------
  profile_information: " Profile Information",
  id_verification: " ID Verification",
  id_verification_documents: " ID Verification Documents",
  my_cards_and_payment_methods: "My Cards & Payment Methods",
  your_subscribe_plan: "Your Subscribe Plan",
  personal_information: "Personal Information",
  full_name: "Full Name",
  International_payment:"International payment",
  account_type: "Account type",
  passport_id: "Passport Id",
  passport_document: "Passport Document",
  user_name: "User Name",
  country: "Country",
  state: "State",
  contact_number: "Contact Number",
  city: "City",
  address: "Address",
  address2: "Additional address",
  zipcode: "Zipcode",
  phone_code: "Phone Code",
  verify_your_id: "Verify your ID",
  select_document_type: "Select Document  Type",
  front_page: "Front page",
  back_page: "Back page",
  Skip_and_go_my_profile: "Skip Id",
  Note_residence: "Note : Your address is required in order to convert ad prices to the currency of your country of residence",
  please_upload_your_ID_verification_document_you_may_upload_any_of_the_below_listed_documents:
    "Please upload your ID Verification Document. You may upload any of the below listed documents",
  national_identity_card: "National identity card",
  passport: "Passport",
  residence_card: "Residence card",
  green_card: "Green card",
  drive_licence: "Drivers licence",
  residence_permit: "Residence permit",
  other_valide_document: "Other valide document",
  postal_code: "Postal Code",
  geo_location: "Geolocation",
  youTube_video_link_for_showing_your_presentation: "Youtube video link",
  video_link: "Video Link",
  video_of_presentation: "Video of presentation",
  presentation_video_link: "Presentation video link",
  my_cards: "My Cards",
  add_new_card: "Add payment method",
  select_Paymet_Method:"Payment method",
  card_holders_name: "Card Holder’s name",
  card_number: "Card Number",
  Mobile_number: "Mobile Number",
  number_added_uccessfully:"Mobile number added successfully",
  expiry_date: "Expiry Date",
  expiry_month: "Expiry Month",
  expiry_year: "Expiry Year",
  card_type: "Card Type",
  enter_paypal_id: "Enter Paypal ID",
  paypal_id: "Paypal ID",
  add_paypal_id: "Add Paypal ID",
  Edit_paypal_id: "Edit Paypal ID",
  document_type: "Document Type",
  my_preferred_payment_method_for_receiving_payments:
    "My preferred payment method for receiving  payments",
  my_preferred_payment_method_for_spending_money_on_the_site:
    "My preferred payment method for spending money on the site",
  // ------------------Change Password ------------
  old_password: "Old Password",
  new_password: "New Password",
  email: "Email",
  change_password_request: "Change Password Request",
  // ------------- My Subscription Plan --------------------
  my_current_plan: "My Current Plan",
  applicable_site_service_fees_as_follows:
    "Applicable site service fees as follows",
  for_sending_luggages: "For Sending Luggages",
  for_selling_articles: "For Selling Articles",
  for_carrying_luggages_to_earn_money: "For Carrying Luggages to Earn Money",
  For_carrying_sold_articles_to_earn_money:
    "For Carrying Sold Articles to Earn Money",
  upgrade_to_our_club_membership_and_enjoy_0_service_fees:
    "Upgrade to our club membership and enjoy 0% service fees",
  upgrade_membership: "Upgrade Membership",
  //----------------My Request-----------------------
  travels: "Travels",
  luggages: "Luggages",
  sale: "Sale",
  sales: "Sales",
  donations: "Donations",
  i_received: "I Received",
  i_sent: "I Sent",
  requests_I_received_for_carrying_luggages:
    "Requests I received for carrying luggages",
  requests_I_sent_for_carrying_luggages:
    "Requests I sent for carrying luggages",
  departure: "Departure",
  arrival: "Arrival",
  weight: "Weight",
  height: "Height",
  length: "Length",
  request_received_from: "Request received from",
  requested_sent_to: "Request sent to",
  earning_oppurtunity: "Earning Opportunity",
  view_request_details: "View Request Details",
  travelling_by: "Travelling by",
  quantity_requested: "Quantity Requested",
  seller_location: "Seller Location",
  requests_I_received_from_travellers_for_sending_my_luggages:
    "Requests I received from travellers for sending my luggages",
  requests_I_sent_to_travellers_for_sending_my_luggages:
    "Requests I sent to travellers for sending my luggages",
  requests_I_received_from_buyers_for_my_articles:
    "Requests I received from buyers for my articles",
  requests_I_sent_to_article_sellers_for_buying_their_articles:
    "Requests I sent to article sellers for buying their articles",
  requester_location: "Requester location",
  status: "Status",
  accept: "Accept",
  reject: "Reject",
  accepted: "Accepted",
  rejected: "Rejected",
  your_are_about_to_accept_the_request_from_buyer_to_sell_your_Item:
    "You are about to accept the request from buyer to sell your Item",
  your_are_about_to_reject_the_request_from_buyer_who_wants_to_buy_your_item:
    "You are about to reject the request from buyer who wants to buy your item.",
  accept_the_request_from_user_to_donate:
    "You are about to agree to donate your article to the applicant.",
  reject_the_request_from_user_who_wants_to_receive_your_donation:
    "You are about to reject the request from user who wants to receive your donation item.",
  please_select_a_reason_for_rejection_and_confirm_your_action:
    "Please select a reason for rejection and confirm your action.",
  your_are_about_to_cancel_the_luggage_sending_request:
    "You are about to cancel the luggage sending request, you received from traveller. Please select a reason for cancelling this order and confirm your action.",
  the_traveller_successfully_notified_cancelation:
    "The traveller has been successfully notified about your cancellation of order with reason.",
  select_reasone_to_reject: "Select reasone to reject",
  I_changed_my_mind: "I changed my mind",
  price_too_high: "Price too high",
  required_quantity_unavailable: "Required quantity unavailable",
  I_did_not_received_the_item_at_all: "I did not received the item at all",
  item_is_not_as_described: "Item is not as described",
  item_is_defective: "Item is defective",
  request_you_will_allow_buyer_to_pay_you_the_requested_money:
    "By accepting this request you will allow buyer to pay you the requested money. Then you need to ship the item to buyer’s location",
  request_you_will_confirm_requester_to_give_away_this_item_as_a_donation:
    "By accepting this request you will confirm requester to give away this item as a donation and ask user to collect it from an agreeable place.",
  requests_I_received_for_my_donation_items:
    "Requests I received for my donation items",
  requests_I_sent_for_receiving_donation_items:
    "Requests I sent for receiving donation items",
  i_accept_confirm: "I Accept & Confirm",
  reject_request: "Reject Request",
  accept_request: "Accept Request",
  accept_confimation: "Acceptance of request",
  reject_confirmation: "Reject Confirmation",
  Pending_acceptance: "Pending acceptance by: Luggage Sender",
  Pending_acceptances: "Pending acceptance by: Traveller",
  donated_to: "Donated to",
  weight_carriage_capacity: "Weight carriage capacity",
  back_to_travel_requests: "Back to travel requests",
  luggage_title: "Luggage Title",
  type_of_luggage: "Type of luggage",
  luggage_dimensions: "Luggage Dimensions",
  current_price_estimate: "Current price estimate",
  luggage_receivers_details: "Luggage receiver’s details",
  name_of_receiver: "Name of receiver",
  phone: "Phone",
  your_relationship_with_receiver: "Your relationship with receiver",
  luggage_location: "Luggage location",
  trip_type: "Trip Type",
  round_way: "Round Way",
  price_to_carry_weight: "Price to carry weight",
  Is_a_stopver_planned_in_middle_of_your_trip:
    "Is a stopver planned in middle of your trip?",
  luggage_collection_place: "Luggage collection place",
  Luggage_drop_off_place: "Luggage drop off place",
  does_your_trip_benefit_from_travel_insurance:
    "Does your trip benefit from travel insurance?",
  luggage_images: "Luggage Images",
  Your_luggage_instructions: "Your luggage instructions",
  the_Luggage_Must_Not_Be_closed:
    "THE LUGGAGE MUST NOT BE CLOSED SO THAT THE TRAVELER CAN FULLY CONTROL THE CONTENTS",
  your_other_instructions: "Your other instructions",
  your_are_about_to_reject_the_request:
    "You are about to reject the request from luggage sender to deliver their luggage to their destination.",
  your_are_about_to_reject_the_request2:
    "You are about to reject the request from a traveler to deliver your luggage to their destination.",
  overWeight: "Over weight",
  i_changed_my_travel_plan: "I changed my travel plan",
  improper: "Improper",
  luggage_not_taken_into_account: "Luggage not taken into account",
  about_your_rejection_with_reason:
    "The luggage sender has been successfully notified about your rejection with reason.",
  happy_travelling_while_earning: "Happy travelling while earning",
  request_sent_by: "Request sent By",
  request_sent_to: "Request sent to",
  member_since: "Member since",
  luggage_carrying_request: "Luggage carrying request",
  cancellation_date: "Cancellation Date",
  cancle_order: "Cancel Order",
  // ---------------My pending Payments----------
  payments_due_receiving: "Payments due receiving from luggage senders",
  payment_due: "Payment Due",
  view_order_details: "View order details",
  Payments_due_receiving_from_article_buyers:
    "Payments due receiving from article buyers",
  payment_Pending_from_buyer: "Payment Pending from buyer",
  due_amount: "Due Amount",
  luggage_sender: "Luggage Sender",
  // ------------- My Orders---------------------
  in_progress: "In Progress",
  completed: "Completed",
  cancelled: "Cancelled",
  donated: "Donated",
  articles_I_sold: "Articles I sold",
  articles_I_bought: "Articles I bought",
  articles_I_confirmed_to_donate: "Articles I confirmed to donate",
  articles_I_requested_to_have_in_donation:
    "Articles I requested to have in donation",
  my_In_Progress_luggage_carrying_orders:
    "My in progress luggage carrying orders",
  Luggage_sending_order_Details_In_progress:
    "Luggage sending order Details(In Progress)",
  Back_to_pending_luggage_sending_orders_list:
    "Back to pending luggage sending orders list",
  my_Pending_luggage_orders: "My Pending luggage orders",
  payments_received_from_article_buyers:
    "Payments received from article buyers",
  items_I_bought_pending_delivery: "Items I bought (pending delivery)",
  requests_I_confirmed_to_donate_my_items:
    "Requests I confirmed to donate my items",
  items_I_want_in_donation_pending_delivery:
    "Items I want in donation (pending delivery)",
  item_sold_to: "Item Sold to:",
  item_bought_from: "Item bought from :",
  item_given_to: "Donation to be given to:",
  i_want_in_donation_from: "Requested donation:",
  payment_received_from_buyer: "Payment received from buyer",
  item_delivered: "Item delivered",
  delivered_to: "Delivered to",
  delivery_date: "Delivery date",
  mark_this_item_as_sent: "Confirm delivery",
  confirm_receipt_of_item: "Confirm receipt of item",
  confirm_delivery_of_sold_item: "Confirm delivery of sold item",
  confirm_receipt_of_item_you_bought: "Confirm receipt of item you bought",
  confirm_delivery_of_donated_item: "Confirm delivery of donated item",
  confirm_receipt_of_donated_item: "Confirm receipt of donated item",
  raise_dispute: "Raise dispute",
  success: "Success",
  your_are_about_to_confirm_that_the_sold_item:
    "You are about to confirm that the sold item has been successfully delivered to the buyer",
  confirm_that_the_item_you_bought:
    "You are about to confirm that the item you bought from seller has been received by you",
  confirm_that_the_donated_item_has_been_successfully_delivered:
    "You are about to confirm that the donated item has been successfully delivered to the interested person",
  confirm_that_the_item_you_wanted_to_have_in_donation:
    "You are about to confirm that the item you wanted to have in donation has been received by you",
  your_are_about_to_raise_a_dispute_for_the_item:
    "You are about to raise a dispute for the item you bought from seller. Please select a reason to raise this dispute.",
  congratulations: "Congratulations!!",
  admin_will_be_notified:
    "Admin will be notified about this and seller will be contacted to either refund or resolve the issue asap.",
  buyer_receiving_the_order_along_with_leaving_his_feedback:
    "The buyer has been informed of your delivery and will confirm it. Please leave a review for your sales experience on the Cobagage website.",
  user_has_been_notified_about_your_delivery:
    "The user has been informed of the delivery of the item donation. Please leave a user review.",
  received_by: "Received by",
  donated_by: "Donated by",
  received_date: "Received date",
  bought_from: "Bought from",
  sold_to: "Sold to",
  i_received_in_donation_from: "Received a donation from:",
  traveller: "Traveller",
  trip_Cost_paid: "Trip Cost paid",
  my_Completed_travels_orders: "My Completed travels orders",
  my_completed_luggage_orders: "My Completed luggage orders",
  my_cancelled_travels_orders: "My Cancelled travels orders",
  my_cancelled_luggage_orders: "My Cancelled luggage orders",
  confirmation_sent_to_requester: "Confirmation Sent to requester",
  item_pending_delivery: "Item Pending Delivery",
  cancel_order_confirmation: "Cancel Order Confirmation",

  // ---------------my travels-------------------
  from: "From",
  froms: "from",
  Seller: "Seller",
  to: "To",
  published: "Published",
  departure_date: "Departure Date",
  arrival_date: "Arrival Date",
  carriage_capacity: "Carriage Capacity",
  price: "Price",
  per: "per",
  request: "Request",
  edit: "Edit",
  details: "Details",
  pending_signatures: "Pending Signatures",
  not_completed: "Not Completed",
  Remaining: "Remaining",
  remaining: "remaining",
  out_of: "out of",
  view_trip_details: "View Trip Details",
  VIEW_ARTICLE_DETAILS: "VIEW ARTICLE DETAILS",
  VIEW_ARTICLE_DONATION_DETAILS: "VIEW ARTICLE DONATION DETAILS",
  VIEW_LUGGAGE_DETAILS: "VIEW LUGGAGE DETAILS",
  // --------------my articles-----------
  quantity: "Quantity",
  material: "Material",
  color: "Color",
  delete: "Delete",
  articles: "Articles",
  article_donation: "Article Donation",
  location: "Location",
  title: "Title",
  // -----------my tracking Travels------
  add_tracking: "Add Tracking",
  // ----------Cobagage Wallet-----------
  withdrawal_history: "Withdrawal History",
  Enter_Amount_Withdraw: "Enter Amount to Withdraw",
  Enter_Amount: "Enter Amount",
  Withdraw: "Withdraw",
  Select_Card: "Card Number",
  Select_Card_Number:"Select Card Number",
  Card: "Card",
  all: "All",
  on_hold: "On Hold",
  complete: "Complete",
  icon: "Icon",
  Date_and_time: "Date & Time",
  amount: "Amount",
  transfer_to: "Transfer To",
  time: "Time",
  description: "Description",
  received_from: "Received from",
  money_received_for_carrying_luggage: "Money received for carrying luggage",
  refund: "Refund Complete",
  money_received_from_sold_article: "Money received from sold Article",
  Money_paid_for_sending: "Money paid for sending",
  received: "Received",
  wallet_history: "Wallet History",
  cobagage_wallet_balance: "Cobagage Wallet Balance",
  pending: "Pending",
  cancel: "Cancel",
  pendingbyadmin: "Being processed by admin",

  your_total_balance: "Your Total Balance",
  your_withdrawal_balance: "Your Withdrawal Balance",
  your_remaining_balance: "Your Remaining Balance",
  your_escrow_balance: "Your Escrow Balance ",
  payment_in_progress: "Payment in progress",
  send_payment: "Send Payment",
  // ------------Payment sent----------
  order_id: "Order Id",
  qty: "Qty",
  payment_type: "Payment Type",
  payment_status: "Payment Status",
  transaction_id: "Transaction id",
  view: "View",
  // -----------Subscribe History-----
  type: "Type",
  total_transactions: "Total Transactions",
  remaining_transaction: "Remaining Transaction",
  payment_method: "Payment Method",
  created_date: "Created Date",
  Please_content_carried:
    " Please detail the content of this luggage to be carried",
  Lorem_ipsum:
    "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin sodales pulvinar sic tempor. Sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus mollis orci, sed rhoncus pronin sapien nunc accuan eget.",
  product_warranty: "Is product under warranty?",
  Luggage_location: "Luggage location",
  ShoppingCard: "Shopping Card",
  Received_Request: "Request Received",
  Sent_Request: " Request Sent",
  // new changes
  ShoppingCart: "Shopping Cart",
  We_cookies: "We use cookies",
  We_selected_third_parties:
    "We and selected third parties use cookies (or similar",
  technologies_marketing_efforts:
    "technologies)for technical purposes, to enhance and analyze site usage to support our marketing efforts",
  Accept_All: "Accept cookies",
  click_image: "+ click here upload image",
  Article_dimensions:
    "Article Images (Upload image in 1280 px * 720 px dimensions)",
  Luggage_images:
    " Luggage Images (Upload image in 1280 px * 720 px dimensions)",
  Exterior_1: "Exterior 1",
  Exterior_2: "Exterior 2",
  Interior_1: "Interior 1",
  Interior_2: "Interior 2",
  EXPIRE_PIN: "EXPIRE PIN",
  Are_delete_account: "Do you want to delete this account ?",
  Close: "Close",
  Cancel: "Cancel",
  Delete_an_account: "You want to delete all your data and Cobagage account.",
  Delete_account: "Account deleted Succesfully",
  I_TRAVELLER: "I AM A TRAVELLER",
  TRANSPORT_LUGGAGES_MONEY: "TRANSPORT LUGGAGES & EARN MONEY",
  SELL_ARTICLES: "SELL YOUR ARTICLES",
  DONATE_ARTICLES: "DONATE YOUR ARTICLES",
  Post_details: "Post your luggage details",
  Explore_Cobagage: "Explore Cobagage",
  Sell_luggages: "Sell kg of luggage",
  Sell_article: "Sell article",
  Post_donation: "Post article for donation",
  I_SEND_LUGGAGES: "I WANT TO SEND LUGGAGE",
  SEND_TRAVELLERS_COST: "SEND LUGGAGES WITH TRAVELLERS AT REDUCED COST",
  Worth: "Worth",
  Getting_Cobagage: "Getting Started with Cobagage",
  Discover_Cobagage: "Discover Cobagage",
  Cobagage_service_connects:
    "Cobagage is a service that connects co-baggers, travelers, and senders. Whether you are an individual or a professiona.",
  You_weight_bags:
    " You can choose to become a sender by sending your luggage or a traveler by carrying the luggage entrusted by the sender using the available weight in your bags",
  Registration_identification: "Registration and identification",
  Cobagage_forms:
    " Cobagage handlers must register on the platform by filling out the registration forms",
  This_email:
    "This is done either via a connection via social networks (Facebook, Twitter, Linkedin, Instagram ...), or by email.",
  Once_valid_passport:
    " Once the registration has been completed, you can access the detailed profile in your user account in order to provide more complete information: including name, surname, phone number, postal address, professional (SIRET, SIREN) or private, ID card, passport, residence permit, etc. They will need to provide a copy of their ID card, residence permit, and a valid passport.",
  password_username:
    "A password and username will be chosen during registration and will be required for logging into Cobagage.",
  Profile_verification: "Profile verification",
  Each_registration_Cobagagers:
    "Each registration is checked by our team. Fake  profiles may be deleted or blocked at any time by our services or by reporting to Cobagagers.",
  registration_will_finalized:
    " No registration will be finalized without the provision of the mandatory documents to be submitted.",
  Reviews: "Reviews",
  When_completed:
    "When a transaction is completed, meaning the traveler has delivered the luggage to the recipient, reviews, ratings, and comments can be left to evaluate the traveler and the sender..",
  Security_Follow: "Security Measures to Follow",
  As_cobagger_site:
    "As a co-bagger, you are responsible for the transported luggage, with Cobagage acting as a matchmaking site.",
  The_Cobagage_website_allows:
    "The Cobagage website allows for clear communication between the traveler and sender through the site's messaging system",
  Through_ervice_exchanges:
    "Through this channel, co-baggers can specify Cobagage's service in their exchanges",
  Thus_clarified_messaging:
    "Thus, new details that may not have appeared in the listing can be clarified through messaging.",
  The_order_recipient:
    " The order form must be signed by the parties involved in the transaction, namely the traveler, sender, and recipient.",
  It_attests_that:
    "It attests that the luggage has been properly checked and is in line with the listing on the site.",
  Throughout_cessary:
    "Throughout the procedure, from the pickup location to the final destination, the participants should take photos of the transported luggage if necessary.",
  Payments: " Payments",
  Receive_payment: "Receive payment",
  Payment_conditions:
    "Payment is due to the sender under the following conditions",
  The_order_luggage:
    "The order form is signed by all parties (the traveler, sender, and the recipient or receiver of the luggage)",
  The_traveler_recipient:
    "The traveler has delivered the luggage to its final recipient",
  Then_just_Cobagage:
    " Then just make a payment request through the account created on Cobagage",
  Simply_Payment_conditions:
    "Simply request payment through the account created on Cobagage. Payment is due to the traveler under the following conditions",
  Delivery_has:
    "Delivery has been made to the final recipient (recipient or receiver)",
  Cancellation_pickup: "Cancellation of the transaction on the day of pickup",
  Payment_shipped:
    "Payment is due to the traveler once the luggage has been picked up and is in the process of being shipped",
  Online_payments: "Online payments",
  When_PayPal_credit_card:
    "When the sender has selected the traveler to transport their luggage through the platform's listings, they make the payment directly through the site, with a commission rate of 7% excluding taxes. Payments can be made through PayPal or credit card",
  Refunds: "Refunds",
  The_cases: "The sender can receive a refund in the following 4 cases",
  The_traveler_operation: "The traveler himself canceled the operation",
  The_traveler_luggage: "The traveler has lost luggage",
  The_travele_updates: "The traveler has not provided any further updates",
  The_canceled_traveler:
    "The sender canceled the service before the day of luggage pickup by the traveler",
  Cancellation_meeting: "Cancellation and Absence at the meeting",
  It_baggage_pickup:
    "It is possible to cancel an appointment directly on the site up to 24 hours before the day of baggage pickup",
  Simply_service_traveler:
    "Simply go to your customer area and in the withdrawal request section, request the cancellation of a service from a traveler",
  In_case_traveler:
    "In case of absence during the luggage handover, the payment is due to the traveler",
  It_happen_reasons:
    " It may happen that the luggage pickup appointment is canceled or postponed for various reasons",
  In_delivery_appointment:
    "In this case, the recipient and/or sender can work with the traveler to arrange a new luggage delivery appointment",
  If_delivery_date:
    "If the cancellation is due to the traveler, no payment can be made to them until the luggage is delivered. The sender can then contact Cobagage 24 hours after the delivery date.",
  Responsibilities: "Responsibilities",
  Type_content_transported: "Type and content of baggage to be transported",
  The_discretion_user:
    "The type and nature of the baggage is left to the discretion of the user",
  In_case_doubt_service:
    "In case of doubt, the traveler may request additional information on baggage via the site's messaging service. Either party can freely cancel or refuse Cobagage's service.",
  Cobagage_contents:
    "Cobagage's purchase order or order is the one and only document proving that all of the parties involved are aware of the type of baggage to be transported and its contents.",
  Responsibilities_Luggage: "Responsibilities of Luggage",
  Once_carries:
    " Once the baggage has been handed over to him, the traveler becomes solely responsible for the goods he carries.",
  The_latter:
    "The latter's responsibility ends when the luggage is handed over to the recipient or receiver",
  Accident_Traveler: "Accident or Death of the Traveler",
  In_accident:
    "In the event of an accident during the journey or the death of the traveler, Cobagage's costs are reimbursed to the sender.",
  The_baggage_lost:
    "The baggage being then lost, the estimate price of this can be refunded to the sender if he has taken out insurance beforehand.",
  aboutus_text1:
    "“Coming together to design travel and luggage shipping under one single, win-win service”",
  aboutus_text2:
    "Are you new to Cobagage and would like to know more about us? You are at the right place !",
  aboutus_text3:
    "Discover behind the scenes of our team and share our ideas and our goal, that of making life of millions of people ever easier.",
  aboutus_text4: "Cobagage as a reference site",
  aboutus_text5:
    "By choosing to use the Cobagage site, you have the assurance of benefiting from a service reliable and advantageous.",
  aboutus_text6:
    "Born in 2020, Cobagage was designed to make life easier for people traveling or having baggage to ship by reconciling the two.",
  aboutus_text7: "The Goal",
  aboutus_text8:
    "Allow the traveler to earn money and allow sender to save on shipping cost.",
  aboutus_text9:
    "Our teams from the four corners of the world have worked rigorously to offer you the best service on the internet regarding co-baggage.",
  aboutus_text10: "Why choose Cobagage to carry out your shipments?",
  aboutus_text11:
    "Quite simply because you are assured fast service at very profitable prices.",
  aboutus_text12:
    "You also have the possibility of quickly finding a co-baggage handler for your shipments, whether you are a traveler or sender, Cobagage will be responsible for putting you in touch according to your profiles.",
  aboutus_text13:
    "A relationship focusing on simplicity and efficiency to no longer waste time.",
  aboutus_text14: "The key figures of Cobagage",
  aboutus_text15:
    "The quality of the connection services that we offer you is at very heart of our motivation.",
  aboutus_text16:
    "It is in this sense that Cobagage was created, these few figures allowing you to know more about our team and how we operate:",
  aboutus_text17:
    "15 countries in which Cobagage is active and allows you to find the ideal co-baggage.",
  aboutus_text18:
    "2020: Creation of the company and the Cobagage website allowing cobageurs to register and create their profiles as well as their ads.",
  aboutus_text19: "Three people are involved when sending baggage : ",
  Luggage_Senders: "Luggage Sender",
  Recipient: "Recipient",
  aboutus_text20:
    "People who make up our team and strive to help you provide the best experience on our website.",
  aboutus_text21:
    "But co-bagage is also a big saving in terms of C02 emissions by the association in a single journey of the traveler and the luggage to be sent to the recipient.",
  aboutus_text22:
    "An increasingly significant gain over time thanks to more and more co-baggers numerous throughout the world.",
  aboutus_text23: "Birth of the cobagage site",
  aboutus_text24:
    "During various trips, an idea came to us that could revolutionize not only the way of traveling but also of shipping goods from one place to another",
  aboutus_text25: "Cobagage Was Born",
  aboutus_text26:
    "Traveling is a real pleasure for many, however it is generally quite expensive especially when traveling long distances.",
  aboutus_text27:
    "Being able to benefit from an additional sum of money making part of this profitable travel was the solution to travel with complete peace of mind.",
  aboutus_text28:
    "Use your kilos of empty luggage to transport belongings from a shipper to a recipient, an idea ensuring both parties are winners.",
  aboutus_text29:
    "It is in this spirit that co-luggage has become a new way of life, a new way of considering travel on the one hand, and sending packages on the other, whether you are an individual or professional.",
  aboutus_text30:
    "Thanks to this site, you can now find the ideal co-baggage and start earning money by monetizing your kilos of empty luggage or by shipping your merchandise.",
  sending_luggage_fees1:
    "25% fees will be added as service fees on the total trip amount. [(Unit price: 10€ * 10kg) + 25€ (towards service fees) = 125€]",
  sending_luggage_fees2:
    "In case of cancellation by either of the parties - service fees will be non refundable. You will receive only 100% trip cost i.e. 100€ only instead of 125€.",
  selling_article_fee1:
    "15% service fees will be added for sending sold articles to buyers[(Weight or article sold - 10 kg) * (unit price / kg 10 € )+ 15€ (towards service fees) = 115 €] ",
  selling_article_fee2:
    "In case of cancellation by either of the parties - service fees will be non refundable. You will receive only 100% of article sending cost i.e. 100€ only instead of 115€",
  carrying_luggage_fee1:
    "25% fees will be deducted as service fees on the total earned trip amount. [(Unit price: 10€ * 10kg) - 25€ (towards service fees) = 75€ will be amount you will receive as net earning on the trip]",
  carrying_luggage_fee2:
    "In case of cancellation by either of the parties - service fees will be non refundable. Wallet adjustment towards cancellation - +75€ - 75€ = 0€",
  carrying_sold_article:
    "15% service fees will be deducted towards service fees on the earning for delivering sold articles to recepient. [(Weight or article sold - 10 kg) * (unit price / kg 10 € ) - 15€ (towards service fees) = 85 €]",
  carrying_sold_article_fee:
    "In case of cancellation by either of the parties - service fees will be non refundable. Wallet adjustment towards cancellation - +85€ - 85€ = 0€",
  Getting: "Getting",
  Started_with: "Started with",
  luggage_carrying_order_detailes: "Luggage carrying order details(Completed)",
  luggage_carrying_order_detailes_In_Progress:
    "Luggage carrying order details(In Progress)",
  luggage_carrying_order_detailes_cancel:
    "Luggage carrying order details(Cancelled)",
  back_to_luggage_carrying_order_list: "Back to luggage carrying orders list",
  please_check_luggage_content_and_upload:
    "Please check the luggage content and upload your signature in order to validate the order before you start to travel with the luggage.",
  please_note_after_you_upload_signature:
    "Note** : Please note that after you upload your signature, you will not be able to cancel the order. In case of cancellation, no refund of service fees will happen to either of the parties.",
  luggage_sender_signature: "Luggage Sender’s signature",
  traveller_signature: "Traveller’s Signature:",
  please_enter_delivery_code_recieved:
    "Please enter delivery code received from luggage recipient and submit it to mark the order as complete",
  enter_delivery_code: "Enter Delivery Code",
  my_travel_detailes: "My Travel Details",
  luggage_detailes: "Luggage Details:",
  used_second_hand: "Used - Second hand",
  contact: "Contact",
  will_you_be_getting_your_luggage_delivery:
    "Will you be getting your luggage delivery?",
  yes: "Yes",
  exterior: "Exterior",
  interior: "Interior",
  the_order_has_been_successfuly_marked:
    "The order has been successfully marked as completed. You may review the order details under the section “Completed Order”.",
  you_may_also_view:
    "You may also view the related invoice for the order in completed order details",
  please_take_some_time_to_rate_your_experience:
    "Please take some time to rate your experience with the luggage sender. This will help the community in identifying good leads",
  please_rate_your_experience_with_luggage_sender:
    "Please rate your experience with luggage sender",
  rating: "Rating",
  comment: "Comment",
  payment_due_by_luggage_sender: "Payment due by luggage sender",
  order: "Order",
  cancellation_by: "Cancelled by:",
  cancellation_reason: "Cancellation Reason:",
  reciever_signature: "Receiver’s Signature:",
  view_invoice: "View Invoice",
  rate_the_traveller: "Rate the traveller",
  rate_the_Sender: "Rate sender",
  rate_the_Article:"Rate the Article",
  rate_the_Donation:"Evaluate the donor",
  rate_your_experience: "Rate your experience",
  please_rate_your_experience_with_luggage_traveller_sender:
    "Please rate your experience with luggage carrier/traveller",
  please_rate_your_experience_with_Article_sender:"Please rate your experience with Article Seller",
  earned: "Earned",
  cost_to_carry_luggage: "Cost to carry luggage",
  service_fee: "Service fees",
  total_fees: "Total Fees",
  Setup: "Setup",
  credit_card: "Credit Card",
  debit_card: "Debit Card",
  prepaid_card: "Perpaid Card",
  visa: "Visa",
  master_card: "Master Card",
  discover: "Discover",
  e_wallet: "E-Wallet",
  view_all_travel_announcement: "View all travel announcements",
  view_all_article_on_sale: "View all articles on sale ",
  view_all_article_for_donation: "View all articles  for donation",
  Print_Invoice: "Print Invoice",
  Invoice_for_carrying_luggage: "Invoice for carrying luggage",
  Invoice_for_sending_luggage: "Invoice for sending luggages",
  Invoice_Date: "Invoice Date",
  Invoice_Amount: "Invoice Amount",
  Luggage_Title: "Luggage Title",
  Drop_off_Destination: "Drop off Destination",
  Delivery_Date: "Delivery Date",
  Luggage_Sender: "Luggage Sender",
  Luggage_sent_by: "Luggage sent by",
  Invoice_type: "Invoice type",
  Luggage_Weight: "Luggage Weight",
  Unit_Price_Kg: "Unit Price/Kg",
  Cost_to_carry_Luggage: "Cost to carry Luggage",
  Admin_Fees: "Admin Fees",
  Net_Earnings: "Net Earnings",
  Invoice: "Invoice",
  Total_Paid_Amount: "Total Paid Amount",
  change_profile_photo: "Change Profile Photo",
  complete_your_profile: "Complete your profile",
  Luggage_sending_request: "Luggage sending request (I sent)",
  Luggage_sending_request2: "Luggage sending request (I received)",
  Legals: "Legal Notice",
  from_the_website: "From the website",
  legal_text: "applicable from december 1, 2023",
  legal_text1: "You are currently connected to the website",
  legal_text2:
    "published by Cobagage Network, SASU, share capital: €1,000, Paris RCS: 848 506 861, head office: 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, telephone: +336 75 68 10 28, email :",
  legal_text3:
    "intra-community VAT number: FR44914381116, publication director and editorial manager: Ludovic YAO.",
  EDITOR: "EDITOR",
  HOST: "HOST",
  legal_text4: "The Site is hosted by AWS: Amazon Web Services (AWS)",
  Cobagage_copyright_2019_All_rights_reserved:
    "©Cobagage Copyright 2019-2024 All rights reserved.",
  Rate_our_website: "Rate our website",
  Accept_Confirmation: "Accept confirmation",
  Cancel_Order_Confirmation: "Cancel order confirmation",
  acceptText1:
    "You are about to accept the traveler's request to send your luggage.",
  acceptText2:
    "Upon your confirmation, we will invoice you for the cost of the trip as detailed below:",
  Price_to_pay: "Price to pay",
  Traveller_fees: "Traveller fees",
  Service_fees: "Service fees",
  Total_fees: "Total fees",
  I_Accept_Confirm: "I Accept & Confirm",
  successText:
    "Your confirmation has been successfully sent to the traveller. Please keep in mind the following instructions to perform on the travel date while handing over your luggage to the traveller:",
  successText1: "Please check the luggage content for validity",
  successText2: "Please upload your signature as a token of validity",
  successText3:
    "Once you luggage gets accepted by the traveller, you will receive an OTP, which you need to share with the luggage receiver. Traveller will deliver the luggage after receiving the OTP from him.",
  successText4:
    "You may check the status of this order under the section “My Orders - In progress”",
  I_received: "(I received)",
  i_would_like_to_publish: "I also want to post details of my return trip",
  luggage_carrying_request1: "Luggage carrying request(I received)",
  back_to_luggaage_request: "Back to luggage request",
  luggage_carrying_request2: "Luggage carrying request (I send)",
  Luggages_I_want_to_sent: "Luggages I want to send",
  Articles_I_want_to_buy: "Articles I want to buy",
  Pending_acceptance_seller: "Pending acceptance by: Donor",
  Pending_acceptance_seller2: "Pending acceptance by: Seller",
  travel_confirm_text:
    "You are about to accept baggage delivery from the shipping requester. Please confirm your action. After accepting, your remaining transport capacity will be:",
  travel_confirm_text1:
    "After accepting this request your remaining carriage capacity will be ",
  Your_transport_capacity_weight:
    "Your transport capacity is less than the requested weight.",
  Select_payment_method: "Select payment method",
  Rating_sent_sucessfully: "Rating sent sucessfully",
  tavelText1:
    "Your confirmation has been successfully sent to the baggage sender.:",
  tavelText2:
    "Please check the luggage content for validity Please upload your signature as a token of validity",
  tavelText3:
    "Once you deliver the luggage to receiver, please don’t forget to collect his signature and upload. That will mark the completion of luggage delivery and after 3 days, you will receive your payment through preferred payment method.",
  tavelText4: "Next Steps:",
  tavelText5:
    "1. You must collect the luggage. Agree with the sender through messaging on a simple and precise pick-up location ",
  tavelText6:
    "2. When the baggage is collected, please sign the receipt in the “My orders/Travels/outstanding” section. ",
  tavelText7:
    "3. As soon as you sign, the sender receives the delivery code which will validate your delivery (delivery upon validation of the OTP code by sms or email) ",
  tavelText8: "Warning: absolutely check the weight, contents, and conformity of the baggage - do not be distracted because you are solely responsible for the transport of the baggage.",
  You_have_already_rated_this_product: "You have already rated this product",
  Back_to_travel_announcements: "Back to travel announcements",
  Back_to_luggage_listing_page: "Back to luggage listing page",
  Back_to_listing_page: "Back to listing page",
  add_submitted_successfully: "Ad Submitted succesfully...",
  Unauthorized: "Unauthorized",
  Customer: "Customer",
  Money_received_from: "Money received from",
  Click_Here: "Click Here",
  Price_to_high: "Price to high",
  I_Changed_My_Mind: "I changed my mind",
  Required_Quantity_Unavailable: "Required quantity unavailable",
  Raise_a_dispute: "Raise a dispute",
  submit_an_ad: "Submit an Advert",
  new_password_check: "New password must differ from the old one.",
  // chat
  send_the_message: "Send the message",
  Chat_box: "Chat box",
  Type_message: "Type a message...",
  English: "English",
  French: "French",
  card_Deleted: "Card Deleted....",
  card_update: "Card Updated....",
  card_add: "Card added....",
  no_rows: "No rows",
  rows_per_page: "Rows per page",
  Otp_Verification: "Otp Verification",
  More_cobagage: "More on cobagage",
  acceptance_request: 'Accept Confirmation',
  signature_code: "Your signature - delivery code",
  proceed: "Proceed to payment",
  Your_ticket_has_business_days: "Your ticket has been successfully send, the admin will contact you within the next 3 working business days.",
  Phone_Number: " Phone Number"
};
export default profile;
