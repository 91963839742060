import React from "react";
import PaymentWrapperArea from "./PaymentWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function Payments() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t('profile.Payments')}/>
      <PaymentWrapperArea />
    </>
  );
}

export default Payments;
