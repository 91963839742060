import React from 'react'
import EcoresponsiveWrap from './EcoresponsiveWrap'
import BradcomeEco from '../../common/BradcomeEco'
import { useTranslation } from "react-i18next";

export default function Ecoresponsive() {
    const { t } = useTranslation();
    return (
        <div>
            <BradcomeEco name={t("homePage.eco_responsive")} />
            <EcoresponsiveWrap />
        </div>
    )
}
