import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { Link } from "react-router-dom";

function MoreOnCobagageWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="">
        <div className="contact-form-wrap " >
          <div className="row">
            <div className="col-lg-1">
            </div>
            <div className="col-lg-10">
              <div className="custom-input-group">
                <p style={{fontSize:'18px'}}>Designed as a new way to <span style={{ fontWeight: "600" }}> travel and send luggage</span> to a specific destination by taking advantage of accessible fares, Cobagage is dedicated to connecting co-pagers.</p><br />
                <h2 > <span style={{ color: '#f0592b', textDecoration: 'underline' }} >Make your daily life easier, our goal</span></h2><br />
                {/* <h5 style={{ color: '#f0592b', textDecoration: 'underline' }}>ARTICLE 1. PARTIES</h5> */}
                <p style={{fontSize:'18px'}}>
                  If traveling could become a source of additional income?<br />
                  It is with this in mind that Cobagage has designed its services in order to facilitate your daily life through new, particularly attractive services.<br />
                  Whether you are a shipper or a traveler, everyone can co-bag in order to pay for their extra kilos of luggage or to ship business at a lower cost.<br />
                  You plan to make a trip soon and you have <span style={{ fontWeight: "600" }}>space still available in your luggage </span>: here is the opportunity to benefit from a sum of money quickly by transporting the luggage of a sender with which you will have been put in contact on Cobagage.<br />
                  Baggage is monetized by prices set per kilo by the traveler.<br />
                  What simply and effectively make part of his trip profitable!<br />
                  As a shipper, Cobagage offers you to make your daily life easier by quickly dispatching your luggage in a simple, rapid manner and at a controlled cost.<br />
                  While using a freight company remains very expensive, co-letting is an opportunity to benefit from a secure service at affordable prices.<br />
                  Co-gamers therefore benefit from a win-win service from every point of view.
                </p><br />
                <h2 style={{ color: '#f0592b', textDecoration: 'underline' }}>Connect the co-pagers to each other</h2><br />
                <p style={{fontSize:'18px'}}>
                  You have kilos of empty luggage to offer but you do not know how to get in touch with a traveler who can benefit from it?<br />
                  Do you want to send luggage through a traveler but it's difficult to find someone who meets your expectations?<br />
                  The Cobagage site was created in order to respond to this problem by putting co-pagers in contact with each other.<br />
                  In this way, the traveler and the sender are immediately brought together so that they can conclude the <span style={{ fontWeight: "600" }}>transport service</span> .<br />
                  To do this, simply register on the Cobagage site and place an ad.<br />
                  For travelers, the purpose of this announcement is to specify the date and destination of the journey to be made, while the sender must for its part make an announcement describing the baggage to be transported.<br />
                  It is through these announcements that Cobagage will put the two parties in contact in order to conclude the transport offer.<br />
                  Using this platform greatly increases the chances of <span style={{ fontWeight: "600" }}>finding a traveler or sender quickly </span>.<br />
                  Our teams ensure the smooth running of the service by setting up various obligations to be respected.<br />
                  By using Cobagage, you have the assurance of a fast service, at low cost and a completely secure transaction.<br />
                </p><br />
                <h2 style={{ color: '#f0592b', textDecoration: 'underline' }}>Ensure and secure transactions</h2><br />
                <p style={{fontSize:'18px'}}>
                  Co-bagging without having the assurance of a platform managing the different profiles is a real risk for each of the parties: absence from meetings, payment not made, baggage that has not been delivered to the recipient ...<br />
                  There are great risks of managing this type of service only between individuals.<br />
                  Thus, the Cobagage site is an assurance for each of the parties to obtain<span style={{ fontWeight: "600" }}> a reliable and secure service</span> .<br />
                  How can Cobagage provide concrete service between co-pagers?<br />
                  Each payment is made directly on the platform and follows a strict procedure before being delivered to its recipient (see the Cobagage FAQ) .<br />
                  The signing of the order by the traveler, the sender and the recipient / receiver is the guarantee that everyone has read the baggage and its contents, as well as its condition.<br />
                  From that point on, the responsibility for baggage falls on the parties in possession of the baggage.<br />
                  As a shipper on Cobagage, you can benefit from reimbursements in well-defined cases such as cancellation of transport by the traveler, loss of luggage or even the absence of news.<br />
                  Security and ease of communication are the essential advantages linked to the use of our platform.<br />
                </p><br />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default MoreOnCobagageWrapperArea;
