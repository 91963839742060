import React, { useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import RecentTravelCard from '../swipersData/RecentTravelCard';
import RecentArticles from './RecentArticles';
import RecentArticlesDonation from './RecentArticlesDonation';
import travel from '../../../../models/travel';
// import RecentLuggageCard from '../swipersData/RecentLuggageCard';
import luggage from '../../../../models/luggage';
import RecentLuggageCard from '../swipersData/RecentLuggageCard';
import moment from 'moment';
import AppContext from '../../../../store/context/app-context';

export default function DashBoard() {
    const { t } = useTranslation();
    const history = useHistory();
    const [travelAnnouncement, setTravelAnnouncements] = useState();
    const [luggageRequest, setLuggageRequest] = useState();
    const [isOpen, setIsOpen] = useState(true);
    const [allCountList, setALLCountList] = useState()
    const contextObj = useContext(AppContext);
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const [userId, setUserId] = useState(contextObj?.loginUser?.id)
    const testimonial3slide = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        loop: true,
        roundLengths: true,
        autoplay: {
            delay: 15000,
        },
        navigation: {
            nextEl: ".testi-next4",
            prevEl: ".testi-prev4",
        },
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            },
        },
    };
    const testimonial4slide = {
        slidesPerView: 1,
        speed: 1000,
        spaceBetween: 0,
        // loop: true,
        roundLengths: true,
        autoplay: {
            delay: 15000,
        },
        navigation: {
            nextEl: ".testi-next4",
            prevEl: ".testi-prev4",
        },
        breakpoints: {
            480: {
                slidesPerView: 1
            },
            576: {
                slidesPerView: 2
            },
            992: {
                slidesPerView: 2
            },
            1200: {
                slidesPerView: 3
            }

        },
    };

    useEffect(() => {
        getData();
    }, []);
    const getData = () => {
        travel
            .list(userId)
            .then((item) => {
                setTravelAnnouncements(item?.filter((item) => item?.isComplete == false));
            })
            .catch(error => {
                console.log("error", error);
            })
        luggage
            .list(userId)
            .then((item) => {
                setLuggageRequest(item?.filter((item) => item?.isComplete == false));
            })
            .catch(error => {
                console.log("error", error);
            })
        luggage
            .allListCount(contextObj.loginUser.id)
            .then((item) => {
                setALLCountList(item)
            })
            .catch(error => {
                console.log("error", error);
            })
    };

    const viewDetails = (type) => {
        if (type == 1) {
            scrollTop();
            history.push("/cobagage_travel_announcements");
        } else {
            scrollTop();
            history.push("/cobagage_luggage_announcements");
        }
    }
    return (
        <div>
            <div className='row'>
                <div className='col-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3 pb-2'>
                    <div className='package-sidebar'  >
                        <aside className='package-widget-style-2 widget-form' style={{ height: '130px' }}>
                            <div className='widget-title text-center d-flex justify-content-between' style={{ backgroundColor: 'gray' }}>
                                <h4 style={{ fontSize: '14px' }}>{t('profile.travels')}</h4>
                            </div>
                            <div className='widget-body' style={{ overflowX: 'auto' }}>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.travels_completed')}: <span style={{ color: '#ff4838' }}>{allCountList?.completed_travels}</span></h6>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.recent_travel_announcements')}:<span style={{ color: '#ff4838' }}>{allCountList?.recent_travels}</span></h6>
                            </div>
                        </aside>
                    </div>
                </div>
                <div className='col-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3 pb-2'>
                    <div className='package-sidebar'>
                        <aside className='package-widget-style-2 widget-form' style={{ height: '130px' }}>
                            <div className='widget-title text-center d-flex justify-content-between' style={{ backgroundColor: 'gray' }}>
                                <h4 style={{ fontSize: '14px' }}>{t('profile.luggages')}</h4>
                            </div>
                            <div className='widget-body' style={{ overflowX: 'auto' }}>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.luggages_sent')}:<span style={{ color: '#ff4838' }}>{allCountList?.completed_luggage}</span></h6>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.recent_luggage_requests')}: <span style={{ color: '#ff4838' }}>{allCountList?.recent_luggage}</span></h6>
                            </div>
                        </aside>
                    </div>
                </div>
                <div className='col-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3 pb-2'>
                    <div className='package-sidebar'>
                        <aside className='package-widget-style-2 widget-form' style={{ height: '130px' }}>
                            <div className='widget-title text-center d-flex justify-content-between' style={{ backgroundColor: 'gray' }}>
                                <h4 style={{ fontSize: '14px' }}>{t('profile.article_on_sale')}</h4>
                            </div>
                            <div className='widget-body' style={{ overflowX: 'auto' }}>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.articles_sold')}: <span style={{ color: '#ff4838' }}>{allCountList?.articles_sold}</span></h6>
                                <h6 className='fw-bold pt-1' style={{ fontSize: '10px' }}>#{t('profile.articles_listed_for_sale')}:<span style={{ color: '#ff4838' }}>{allCountList?.article_for_sale}</span></h6>
                            </div>
                        </aside>
                    </div>
                </div>
                <div className='col-12 col-lg-3 col-md-6 col-xl-3 col-xxl-3 pb-2'>
                    <div className='package-sidebar'>
                        <aside className='package-widget-style-2 widget-form' style={{ height: '130px' }}>
                            <div className='widget-title' style={{ backgroundColor: 'gray' }} >
                                <h4 style={{ fontSize: '14px' }}>{t('profile.article_for_donation')}</h4>
                            </div>
                            <div className='widget-body' >
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.articles_donated')}:<span style={{ color: '#ff4838' }}>{allCountList?.articles_donated}</span></h6>
                                <h6 className='fw-bold' style={{ fontSize: '10px' }}>#{t('profile.articles_listed_for_donations')}:<span style={{ color: '#ff4838' }}>{allCountList?.article_for_donation}</span></h6>
                            </div>
                        </aside>
                    </div>
                </div>
            </div>
            <div className='row'>
                <div className='package-details-wrapper pb-110 ps-3'>
                    <div className='tour-package-details'>
                        <div className="package-details-tabs pt-4">
                            <ul
                                className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
                                id="pills-tab"
                                role="tablist"
                                style={{ marginBottom: '0px' }}
                            >
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={isOpen ? "nav-link active" : "nav-link"}
                                        id="pills-package1"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pill-body1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pill-body1"
                                        aria-selected="true"
                                        onClick={() => setIsOpen(true)}
                                        // style={{ borderRadius: '0px', border: '1px solid', borderColor: '#ff4838', padding: '8px 25px' }}
                                        style={{ borderRadius: '5px', color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', padding: '8px 25px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}

                                    >
                                        {t("homePage.Travels")}
                                    </button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button
                                        className={!isOpen ? "nav-link active" : "nav-link"}
                                        id="pills-package1"
                                        data-bs-toggle="pill"
                                        data-bs-target="#pill-body1"
                                        type="button"
                                        role="tab"
                                        aria-controls="pill-body1"
                                        aria-selected="true"
                                        onClick={() => setIsOpen(!true)}
                                        // style={{ borderRadius: '0px', border: '1px solid', borderColor: '#ff4838' }}
                                        style={{ borderRadius: '5px', color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', padding: '8px 25px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}

                                    >
                                        {t("homePage.Luggages")}
                                    </button>
                                </li>

                            </ul>
                            <div className="tab-content" id="pills-tabContent">
                                {/* Travel */}
                                <div
                                    className="tab-pane fade show active package-info-tab "
                                    id="pill-body1"
                                    role="tabpanel"
                                    aria-labelledby="pills-package1"
                                >
                                    {isOpen ? <div>
                                        <div className=''>
                                            <div className="row d-flex justify-content-center  pt-4">
                                                <div className="testimonial-area testimonial-style-three  p-0" style={{ backgroundImage: 'none' }}>
                                                    <div className="container" style={{ maxWidth: '1000px' }}>
                                                        <div className="row d-flex justify-content-center align-items-center p-0">
                                                            <div className="col-lg-6 col-sm-10">
                                                                <div className="section-head-alpha text-lg-start text-center pb-0">
                                                                    {/* <h2 className='fs-5'>Recent travel announcements</h2> */}
                                                                    <p className='fs-4 fw-bold'>
                                                                        {t('profile.recent_travel_announcements')}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                                                {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                                                    <div
                                                                        className="testi-prev4"
                                                                        tabIndex={0}
                                                                        role="button"
                                                                        aria-label="Previous slide"
                                                                    >
                                                                        {" "}
                                                                        <i className="bi bi-arrow-left" />
                                                                    </div>
                                                                    <div
                                                                        className="testi-next4"
                                                                        tabIndex={0}
                                                                        role="button"
                                                                        aria-label="Next slide"
                                                                    >
                                                                        <i className="bi bi-arrow-right" />
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                        <div className='row p-3'>
                                                            <div className=' col-12 text-end'>
                                                                <button className='fs-6' onClick={() => viewDetails(1)} style={{ color: '#ff4838', border: '0px', backgroundColor: '#fff' }}> {t('profile.view_all_travel_announcement')}{' '}<i className="bi bi-arrow-right-circle" /></button>
                                                            </div>
                                                        </div>
                                                        <div className="row align-items-center justify-content-center">
                                                            <div className="col-lg-12 p-0">
                                                                <Swiper
                                                                    className="swiper testimonial-slider-three"
                                                                    slidesPerView={1}
                                                                    speed={500}
                                                                    spaceBetween={5}
                                                                    loop={false}
                                                                    roundLengths={true}
                                                                    autoplay={{
                                                                        delay: 5000,
                                                                    }}
                                                                    navigation={{
                                                                        nextEl: ".testi-next4",
                                                                        prevEl: ".testi-prev4",
                                                                    }}
                                                                    breakpoints={{
                                                                        480: {
                                                                            slidesPerView: 1
                                                                        },
                                                                        576: {
                                                                            slidesPerView: 2
                                                                        },
                                                                        992: {
                                                                            slidesPerView: 2
                                                                        },
                                                                        1200: {
                                                                            slidesPerView: 3
                                                                        }
                                                                    }}
                                                                >
                                                                    <div className="swiper-wrapper">
                                                                        {travelAnnouncement?.map((item, index) => {
                                                                            return (
                                                                                <SwiperSlide className="swiper-slide" style={{ padding: "10px" }} key={index}>
                                                                                    <RecentTravelCard item={item} data={'recentTravelData'} />
                                                                                </SwiperSlide>
                                                                            )
                                                                        })}
                                                                    </div>
                                                                </Swiper>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> :
                                        <div>
                                            <div className=''>
                                                <div className="row d-flex justify-content-center  pt-4">
                                                    <div className="testimonial-area testimonial-style-three  p-0" style={{ backgroundImage: 'none' }}>
                                                        <div className="container" style={{ maxWidth: '1000px' }}>
                                                            <div className="row d-flex justify-content-center align-items-center p-0">
                                                                <div className="col-lg-6 col-sm-10">
                                                                    <div className="section-head-alpha text-lg-start text-center pb-0">
                                                                        <p className='fs-4 fw-bold'>
                                                                            {t('profile.recent_luggage_sending_requests')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                                                    {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                                                        <div
                                                                            className="testi-prev4"
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Previous slide"
                                                                        >
                                                                            {" "}
                                                                            <i className="bi bi-arrow-left" />
                                                                        </div>
                                                                        <div
                                                                            className="testi-next4"
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Next slide"
                                                                        >
                                                                            <i className="bi bi-arrow-right" />
                                                                        </div>
                                                                    </div> */}
                                                                </div>
                                                            </div>
                                                            <div className='row p-3'>
                                                                <div className=' col-12 text-end'>
                                                                    <button className='fs-6' onClick={() => viewDetails(2)} style={{ color: '#ff4838', border: '0px', backgroundColor: '#fff' }}>{t('profile.view_all_luggage_requests')}{' '}<i className="bi bi-arrow-right-circle" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center">
                                                                <div className="col-lg-12 p-0">
                                                                    <Swiper
                                                                        className="swiper testimonial-slider-three"
                                                                        slidesPerView={1}
                                                                        speed={500}
                                                                        spaceBetween={5}
                                                                        loop={false}
                                                                        roundLengths={true}
                                                                        autoplay={{
                                                                            delay: 5000,
                                                                        }}
                                                                        navigation={{
                                                                            nextEl: ".testi-next4",
                                                                            prevEl: ".testi-prev4",
                                                                        }}
                                                                        breakpoints={{
                                                                            480: {
                                                                                slidesPerView: 1
                                                                            },
                                                                            576: {
                                                                                slidesPerView: 2
                                                                            },
                                                                            992: {
                                                                                slidesPerView: 2
                                                                            },
                                                                            1200: {
                                                                                slidesPerView: 3
                                                                            }
                                                                        }}
                                                                    >
                                                                        <div className="swiper-wrapper">
                                                                            {luggageRequest?.map(item => (
                                                                                <SwiperSlide className="swiper-slide" style={{ padding: "10px" }}>
                                                                                    <RecentLuggageCard item={item} data={'viewAllLuggageRequest'} />
                                                                                </SwiperSlide>
                                                                            ))}
                                                                        </div>
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>}
                                    {/* <div>
                                            <div className=''>
                                                <div className="row d-flex justify-content-center  pt-4">
                                                    <div className="testimonial-area testimonial-style-three  p-0" style={{ backgroundImage: 'none' }}>
                                                        <div className="container" style={{ maxWidth: '1000px' }}>
                                                            <div className="row d-flex justify-content-center align-items-center p-0">
                                                                <div className="col-lg-6 col-sm-10">
                                                                    <div className="section-head-alpha text-lg-start text-center pb-0">
                                                                        <p className='fs-4 fw-bold'>
                                                                            {t('profile.recent_luggage_sending_requests')}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-lg-6 col-sm-10 d-flex justify-content-end">
                                                                    <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                                                                        <div
                                                                            className="testi-prev4"
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Previous slide"
                                                                        >
                                                                            {" "}
                                                                            <i className="bi bi-arrow-left" />
                                                                        </div>
                                                                        <div
                                                                            className="testi-next4"
                                                                            tabIndex={0}
                                                                            role="button"
                                                                            aria-label="Next slide"
                                                                        >
                                                                            <i className="bi bi-arrow-right" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='row p-3'>
                                                                <div className=' col-12 text-end'>
                                                                    <button className='fs-6' onClick={() => viewDetails(2)} style={{ color: '#ff4838', border: '0px', backgroundColor: '#fff' }}>{t('profile.view_all_luggage_requests')}{' '}<i className="bi bi-arrow-right-circle" /></button>
                                                                </div>
                                                            </div>
                                                            <div className="row align-items-center justify-content-center">
                                                                <div className="col-lg-12 p-0">
                                                                    <Swiper
                                                                        className="swiper testimonial-slider-three"
                                                                        {...luggageRequest?.length >= 4 ? testimonial3slide : testimonial4slide}
                                                                    >
                                                                        <div className="swiper-wrapper">
                                                                            {luggageRequest?.map(item => (
                                                                                <SwiperSlide className="swiper-slide" style={{ padding: "10px" }}>
                                                                                    <RecentLuggageCard item={item} data={'viewAllLuggageRequest'} />
                                                                                </SwiperSlide>
                                                                            ))}
                                                                        </div>
                                                                    </Swiper>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div> */}
                                    <RecentArticles />
                                    <RecentArticlesDonation />
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
