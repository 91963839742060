import React from "react";
import LoginWrapperArea from "./LoginWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function Login() {
  const { t } = useTranslation();

  return (
    <>
      <Breadcrumb name={t("auth.login")}/>
      <LoginWrapperArea />
     
    </>
  );
}

export default Login;
