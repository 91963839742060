import React from "react";
import SellArticleWrapperArea from "./SellArticleWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";

function SellArticle() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t("menu.sell_article")}/>
      <SellArticleWrapperArea />
     
    </>
  );
}

export default SellArticle;
