import React, { useContext, useEffect, useState } from "react";
import Loading from "../../../common/Loading";
import { t } from "i18next";
import useValidator from "../../../../plugin/validator";
import notistack from "../../../../plugin/notistack";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import articlesDonation from "../../../../models/articlesDonation";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import moment from "moment/moment";
import AppContext from "../../../../store/context/app-context";
import { country } from "../../../../models";
const restData = {
  title: "",
  type: "",
  quantity: "",
  weight: "",
  color: "",
  height: "",
  width: "",
  location: "",
  terms_and_condition: "",
  volume: "",
  gallery: [],
  delivery_date: moment().format("DD/MM/YYYY"),
  delivery_place: "",
  vehicle_type: "",
  country: "",
};

function PostArticleDonation() {
  const [data, setData] = useState(restData);
  const [load, setLoad] = useState(false);
  const { i18n } = useTranslation();
  const [image, setImage] = useState([]);
  const [validator, showMessage] = useValidator();
  const [imageArray, setImageArray] = useState(["", "", "", ""]);
  const [profile, setProfile] = useState([]);
  const [imgmassage, setImgmassage] = useState(["", "", "", ""]);
  const [countryList, setCountryList] = useState();
  const history = useHistory();
  const contextObj = useContext(AppContext);

  useEffect(()=>{
    getCountryList();
  },[])

  const getCountryList = () => {
    country
      .country()
      .then((response) => {
        setCountryList(response);
      })
      .catch((error) => {
        console.log("Error while fetch country list: ", error);
      });
    }

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const error = {
    title: validator.message(t("title"), data.title, "required"),
    type: validator.message(t("type"), data.type, "required"),
    quantity: validator.message(t("quantity"), data.quantity, "required"),
    weight: validator.message(t("weight"), data.weight, "required"),
    // color: validator.message(t('color'), data.color, 'required'),
    // height: validator.message(t('height'), data.height, 'required'),
    // width: validator.message(t('width'), data.width, 'required'),
    location: validator.message(t("location"), data.location, "required"),
    country: validator.message(t("country"), data.country, "required"),
    terms_and_condition: validator.message(
      t("terms_and_condition"),
      data.terms_and_condition,
      "required"
    ),
    delivery_place: validator.message(
      t("delivery_place"),
      data.delivery_place,
      "required"
    ),
    delivery_date: validator.message(
      t("delivery_date "),
      data.delivery_date,
      "required"
    ),
    vehicle_type: validator.message(
      t("vehicle_type "),
      data.vehicle_type,
      "required"
    ),
  };

  const handleSubmit = (event) => {
    if (validator.allValid()) {
      if (data.gallery.length >= 3) {
        setLoad(true);
        articlesDonation
          .create(data)
          .then((response) => {
            history.push(`/travelDetails/${response.id}/3`);
          })
          .catch((error) => {
            setLoad(false);
            notistack.error(error.data.message);
          });
      } else {
        notistack.error(t("form.Please_upload"));
      }
    } else {
      showMessage(true);
    }
  };

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const convertToBase64 = (e) => {
    let tempFile = e.target.files[0];

    getBase64Image(tempFile)
      .then((responseBase64) => {
        let arr = data?.gallery ? [...data?.gallery] : ["0", "0"];
        arr.push(responseBase64);
        handleSetData("gallery", arr);
      })
      .catch((err) => {
        console.log("Error While convert to base64: ", err);
      });
  };

  const convertToBase641 = (e, index) => {
    let array = [...imageArray];
    let arrayMsg = [...imgmassage];
    let tempFile = e.target.files[0];
    if (tempFile.size > 4000000) {
      arrayMsg[index] = t("form.File_allowed");
      setImgmassage(arrayMsg);
      // array[index] = "";
      // setImageArray(array);
      // let remove = data?.gallery ? [...data?.gallery] : ["", "", "", ""];
      // remove[index] = "";
      // handleSetData("gallery", remove);
    } else {
      let tempFiles = URL.createObjectURL(e.target.files[0]);
      array[index] = tempFiles;
      setImageArray(array);
      getBase64Image(tempFile)
        .then((responseBase64) => {
          let arr = data?.gallery ? [...data?.gallery] : ["", "", "", ""];
          arr[index] = responseBase64;
          handleSetData("gallery", arr);
          // arrayMsg[index] = " ";
          // setImgmassage(arrayMsg);
        })
        .catch((err) => {
          console.log("Error While convert to base64: ", err);
        });
    }
  };

  const getBase64Image = (fileParam) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        console.log("Called", reader);
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    });
  };
  const onInputUppercase = (e) => {
    let firstLetter = e.target.value.charAt(0);
    e.target.value = firstLetter.toUpperCase() + e.target.value.slice(1);
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur();
    e.stopPropagation();
    setTimeout(() => {
      e.target.focus();
    }, 0);
  };
  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <h5 style={{ fontWeight: "bold" }}>
                {t("form.Article_Donation_Announcement")}
              </h5>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      onInput={onInputUppercase}
                      value={data.title}
                    />
                    <span style={{ color: "red" }}>{error.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Type_Article")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="type"
                      onChange={handleChange}
                      name="type"
                      value={data.type ? data.type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.Type_Article")}
                      </option>
                      <option value={1}>{t("form.New_Never_Used")}</option>
                      <option value={2}>{t("form.Used_Secound_hand")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: error.type ? "10px" : "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.type}</span>
                  </div>
                </div>
              </div>
              <div className="row" style={{ marginTop: "20px" }}>
                <label htmlFor="name">{t("form.Luggage_Dimensions")}:</label>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.height")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.height")}
                      id="height"
                      onChange={handleChange}
                      name="height"
                      value={data.height}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.height}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.length")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.length")}
                      id="length"
                      onChange={handleChange}
                      name="length"
                      value={data.length}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.length}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.width")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder="width"
                      id="width"
                      onChange={handleChange}
                      name="width"
                      value={data.width}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      cm
                    </span>
                    {/* <span style={{ color: "red" }}>{error.width}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Weight")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.Weight")}
                      id="weight"
                      step="0.01"
                      onChange={handleChange}
                      name="weight"
                      value={data.weight}
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.weight ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      kg
                    </span>
                    <span style={{ color: "red" }}>{error.weight}</span>
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Colour")}</label>
                    <input
                      type="text"
                      placeholder={t("form.Colour")}
                      id="color"
                      onChange={handleChange}
                      name="color"
                      value={data.color}
                    />
                    {/* <span style={{ color: "red" }}>{error.color}</span> */}
                  </div>
                </div>
                <div className="col-12 col-sm-4 col-lg-4">
                  <div className="custom-input-group">
                    <label htmlFor="name">{t("form.Volume")}</label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.Volume")}
                      id="volume"
                      onChange={handleChange}
                      name="volume"
                      value={data.volume}
                    />
                    {/* <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span> */}
                    {/* <span style={{ color: "red" }}>{error.volume}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Available_Quantity")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      placeholder={t("form.quantity")}
                      id="quantity"
                      onChange={handleChange}
                      name="quantity"
                      value={data.quantity}
                    />
                    <span style={{ color: "red" }}>{error.quantity}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Item_Location_Addres")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.location")}
                      id="location"
                      onChange={handleChange}
                      onInput={onInputUppercase}
                      name="location"
                      value={data.location}
                    />
                    <span style={{ color: "red" }}>{error.location}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row col-lg-12" style={{ marginTop: "20px" }}>
                  <label htmlFor="name">{t("form.DELIVERY_INFOS")}:</label>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name" style={{ display: "block" }}>
                      {t("form.delivery_date")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    {/* <input
                                            required
                                            type="Date"
                                            id="delivery_date"
                                            onChange={handleChange}
                                            name='delivery_date'
                                            value={data.delivery_date}
                                            min={new Date().toISOString().split('T')[0]}
                                        /> */}
                    <div className="customDatePickerWidth">
                      <DatePicker
                        selected={moment(
                          data.delivery_date,
                          "DD/MM/YYYY"
                        ).toDate()} // Use a format that DatePicker can parse
                        dateFormat={
                          i18n.language === "frFR" ? "dd/MM/yyyy" : "MM/dd/yyyy"
                        } // Display format
                        onChange={(date) =>
                          handleSetData(
                            "delivery_date",
                            moment(date).format("DD/MM/YYYY")
                          )
                        }
                        className="px-3"
                        minDate={new Date()}
                      />
                      {/* <DatePicker selected={data.delivery_date}  dateFormat={i18n.language === 'frFR'?"dd/MM/yyyy":"MM/dd/yyyy"} value={data.delivery_date} onChange={(value)=>handleSetData("delivery_date",value)} placeholderText={i18n.language === 'frFR'?"dd/mm/yyyy":"mm/dd/yyyy"} minDate={new Date()} className="px-3"/> */}
                    </div>
                    <span style={{ color: "red" }}>{error.delivery_date}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.delivery_country")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      required
                      id="country"
                      onChange={(e) => {
                        handleChange(e, "country");            
                      }}
                      name="country"
                      value={data.country ? data.country : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.delivery_country")}
                      </option>
                      {countryList?.map((item) => (
                        <option value={item.id} key={item.id}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.country ? "10px" : "21px",
                        }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>
                      {error.country}
                    </span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group ">
                    <label htmlFor="name">
                      {t("form.delivery_place")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.enter_delivery_place")}
                      id="delivery_place"
                      onChange={handleChange}
                      onInput={onInputUppercase}
                      name="delivery_place"
                      value={data.delivery_place}
                    />
                    <span style={{ color: "red" }}>{error.delivery_place}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Delivery_by")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="vehicle_type"
                      onChange={handleChange}
                      name="vehicle_type"
                      value={data.vehicle_type ? data.vehicle_type : 0}
                    >
                      <option value={0} selected disabled>
                        {t("form.type_of_transport")}
                      </option>
                      {i18n.language === "frFR"
                        ? contextObj.vehicleTypeFr?.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))
                        : contextObj.vehicleTypeEn?.map((item, index) => (
                            <option value={item.value} key={index}>
                              {item.name}
                            </option>
                          ))}
                    </select>
                    <span>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{
                          marginTop: error.vehicle_type ? "0px" : "21px",
                        }}
                      ></i>
                    </span>
                    <span style={{ color: "red" }}>{error.vehicle_type}</span>
                  </div>
                </div>
              </div>

              {/* <div className="row">
                                <div className="col-lg-12">
                                    <div>
                                        <h3>{t("menu.gallery")} ({t("form.Upload_image_in_1280_720_dimension")})</h3><br />
                                        <label htmlFor="name" style={{ color: '#ff4838' }}>{t("form.You_can_add_4_images_maximum")}</label>
                                        <input
                                            id="images"
                                            onChange={(e) => {
                                                if (data.gallery.length == 4) {
                                                    notistack.error(t("form.You_can_add_4_images_maximum"))
                                                }
                                                else {
                                                    convertToBase64(e);
                                                    let arr = image ? [...image] : ['0', '0'];
                                                    arr.push(URL.createObjectURL(e.target.files[0]));
                                                    setImage(arr);
                                                }
                                            }
                                            }
                                            name='images'
                                            // value={data.images}
                                            type="file" multiple accept="gif|jpg|png|jpeg"
                                        />
                                    </div>
                                </div>
                                {image &&
                                    <div className="col-lg-12 row">
                                        {image.map((item) => {
                                            return (
                                                <div className="col-3">
                                                    <img
                                                        src={item}
                                                        height="100%" width="100%"></img>
                                                </div>
                                            )
                                        })}
                                    </div>
                                }
                            </div> */}
              <div className="row">
                <div className="col-lg-12 mb-lg-3 mt-lg-3">
                  <p>{t("profile.Article_dimensions")}</p>
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Exterior_1")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[0] ? (
                      <img
                        src={imageArray[0]}
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file"
                      onChange={(e) => {
                        convertToBase641(e, 0);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <lable
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[0]}
                    </lable>
                  </div>
                  {imageArray[0] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Exterior_2")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[1] ? (
                      <img
                        src={imageArray[1]}
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file2"
                      onChange={(e) => {
                        convertToBase641(e, 1);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[1]}
                    </label>
                  </div>
                  {imageArray[1] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Interior_1")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[2] ? (
                      <img
                        src={imageArray[2]}
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file3"
                      onChange={(e) => {
                        convertToBase641(e, 2);
                      }}
                      maxLength={3145728}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[2]}
                    </label>
                  </div>
                  {imageArray[2] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12 col-md-6">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {t("profile.Interior_2")}
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {imageArray[3] ? (
                      <img
                        src={imageArray[3]}
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                          height: "100%",
                        }}
                        for="file"
                        role="button"
                      ></img>
                    ) : (
                      <label
                        for="file4"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file4"
                      onChange={(e) => {
                        convertToBase641(e, 3);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[3]}
                    </label>
                  </div>
                  {imageArray[3] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file4"
                        style={{ color: "#1e98d7", fontSize: "11px" }}
                        role="button"
                      >
                        {t("profile.click_image")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="package-sidebar">
                <aside
                  className="package-widget widget-tour-categoris mt-30"
                  style={{ boxShadow: "none" }}
                >
                  <div className="widget-body">
                    <div className="">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cate"
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                        checked={data.terms_and_condition}
                      />{" "}
                      <span>{t("form.I_CONFIRM_THAT")}</span>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="custom-input-group row col-lg-12">
                <div className=" col-sm-6 col-md-6 col-lg-">
                  <div className="submite-btn">
                    <button
                      type="submit"
                      disabled={!data.terms_and_condition}
                      onClick={() => (handleSubmit(), scrollTop())}
                      className="btn btn-secondary"
                    >
                      {t("form.Preview_Advert")}
                    </button>
                  </div>
                </div>
              </div>
              <div className="col-12  mt-2">
                <p>
                  {t("form.Note")}
                  <br />
                  {t("form.aerosol")}
                  <br />
                  {t("form.firearm")}
                  <br />
                  {t("form.ammunition")}
                  <br />
                  {t("form.weapon")}
                  <br />
                  {t("form.general")}
                </p>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
export default PostArticleDonation;
