import React, { useContext } from 'react'
import { useTranslation } from 'react-i18next';
import AppContext from '../../../store/context/app-context';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

export default function BanerPage({ setopen }) {
    const { t } = useTranslation();
    const contextObj = useContext(AppContext);
    const history = useHistory();
    const { i18n } = useTranslation();
    const addTraveller = (radioData) => {
        contextObj.setTabValue(0)
        contextObj.setRadioValue(radioData);
        history.push("/myProfile" );
    }

    const scrollTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
    };

    return (
        <div className="pt-lg-4"
            style={{
                backgroundImage: 'url("/images/vehicles/newhomepage.webp")',
                backgroundRepeat: "no-repeat",
                backgroundSize: 'cover',
                height: "900px"
            }}
        >
            <div className='container d-flex justify-content-end'
                style={{ marginTop: "70px" }}
            >
                {i18n.language === "frFR"?<img
                    src={"/images/vehicles/CobagageLogo4.png"}
                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "25%" }}
                />:<img
                    src={"/images/vehicles/CobagageLogo.png"}
                    style={{ aspectRatio: "4/2", objectFit: "contain", width: "25%" }}
                />}
                
            </div>
            <div className='container' style={{ marginTop: "240px", backgroundColor: "rgb(176 108 108 / 37%)", padding: "35px" }}>
                <div className='col-12'>
                    <h2 style={{ color: "white", fontStyle: "italic" }}>{t('profile.I_TRAVELLER')}</h2>
                </div>
                <div className='col-12 row'>
                    <div className='col-lg-4' style={{ color: "white", fontStyle: "italic" }}>{t('profile.TRANSPORT_LUGGAGES_MONEY')}</div>
                    <div className='col-lg-4' style={{ color: "white", fontStyle: "italic" }}>{t('profile.SELL_ARTICLES')}</div>
                    <div className='col-lg-4' style={{ color: "white", fontStyle: "italic" }}>{t('profile.DONATE_ARTICLES')}</div>
                </div>
                <div className='col-12 row'>
                    <div className='col-lg-4 pt-lg-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => addTraveller(1)}
                            onClick={() => contextObj.login == true ? addTraveller(1) : history.push({ pathname: "/login" })}
                            style={{ fontSize: "12px", borderRadius: '5px', color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Sell_luggages')}
                        </button>
                    </div>
                    <div className='col-lg-4 pt-lg-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => addTraveller(3)}
                            onClick={() => contextObj.login == true ? addTraveller(3) : history.push({ pathname: "/login" })}
                            style={{ fontSize: "12px", borderRadius: '5px', color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Sell_article')}
                        </button>
                    </div>
                    <div className='col-lg-4 pt-lg-2'>
                        <button
                            className="nav-link active"
                            type="button"
                            // onClick={() => addTraveller(4)}
                            onClick={() => contextObj.login == true ? addTraveller(4) : history.push({ pathname: "/login" })}
                            style={{ fontSize: "12px", borderRadius: '5px', color: '#fff', border: '1px solid #ee9200', backgroundColor: '#ee9200', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                        >
                            {t('profile.Post_donation')}
                        </button>
                    </div>
                </div>
                <hr
                    style={{ color: "white", height: "3px" }}
                ></hr>
                <div className='col-12 row'>
                    <div className='col-12 col-lg-8'>
                        <div className='col-12'><h2 style={{ color: "white", fontStyle: "italic" }}>{t('profile.I_SEND_LUGGAGES')}</h2></div>
                        <div className='col-12'><p style={{ color: "white", fontStyle: "italic" }}>{t('profile.SEND_TRAVELLERS_COST')}</p></div>
                        <div className='col-12 mt-3'>
                            <button
                                className="nav-link active"
                                type="button"
                                // onClick={() => addTraveller(2)}
                                onClick={() => contextObj.login == true ? addTraveller(2) : history.push({ pathname: "/login" })}
                                style={{ borderRadius: '5px', color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                            >
                                {t('profile.Post_details')}
                            </button>
                        </div>
                    </div>
                    <div className='col-lg-4'>
                        <div className='col-12'>
                            <img
                                src={"/images/vehicles/cardsEnglish.png"}
                                style={{ paddingRight: "25px", aspectRatio: "4/2", objectFit: "contain", width: "60%", height: "60%" }}
                            >
                            </img>
                        </div>
                        <div className='col-12 pt-2'>
                            <button
                                className="nav-link active"
                                type="button"
                                // onClick={() => { scrollTop(); setopen(true) }}
                                onClick={() => history.push("/home" )}
                                style={{
                                    borderRadius: '5px',
                                    color: '#fff',
                                    border: '1px solid #00A651',
                                    // backgroundColor: '#ee9200',
                                    background: "linear-gradient(to bottom, #00A651 0%, #8DC63F 100%)",
                                    padding: '8px 25px',
                                    boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px'
                                }}
                            >
                                {t('profile.Explore_Cobagage')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}
