import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ShoppingPaypalDetails from "./shoppingPaypalDetails";
import { t } from "i18next";

export default function ShoppingPaypal() {
  return (
    <div>
      <Breadcrumb name={t("profile.Payments")} />
      <ShoppingPaypalDetails />
    </div>
  );
}
