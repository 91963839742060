const form = {
  travel_ads: "Annonces de voyages",
  type_of_traveler: "Type de voyageur",
  periodical: "Périodique",
  one_off: "Unique",
  title: "Titre",
  country_Of_departure: "Pays de départ",
  country_of_arrival: "Pays d'arrivée",
  state_of_departure: "État de départ",
  state_of_arrival: "État d'arrivée",
  city_of_departure: "Ville de départ",
  city_of_arrival: "Ville d'arrivée",
  pick_up_place: "Lieu de récupération",
  enter_pick_up_place: "Entrer le lieu de récupération",
  delivery_place: "Lieu de livraison",
  enter_delivery_place: "Saisir le lieu de livraison",
  departure_date: "Date de départ",
  arrival_date: "Date d'arrivée",
  type_of_transport: "Type de transport ",
  plane: "Avion",
  metro: "Métro",
  car: "Voiture",
  kick_scooter: "Trottinette",
  boat: "Bateau",
  bike: "Vélo",
  departure_time: "Heure de départ",
  arrival_time: "Heure d'arrivée",
  carriage_capacity: "Capacité de transport",
  price_per_kg: "Prix au kilo",
  trip_type: "Type de voyage",
  select_trip_type: "Sélectionner le type de voyage",
  single_trip: "Aller simple",
  round_trip: "Aller-retour",
  height: "Hauteur",
  weight: "Poids",
  length: "Longueur",
  packaging_instruction: "Instructions d'emballage",
  submit: "Soumettre",
  cancle: "Annuler",
  country_Of_stopper: "Pays d'escale",
  state_Of_stopper: "État d'escale",
  city_of_stopover: "Ville d'escale",
  vehicle_type: " Type de transport",
  Preview_Advert: "Aperçu de l'annonce",
  Is_a_stopover: "Une escale est-elle prévue au milieu de votre voyage ?",
  train: "Métro",
  Flight: "Avion",
  Bus: "Bus",
  Train_Number: "Numéro de métro",
  Car_Registration: "Numéro d'immatriculation du véhicule et marque + mode",
  Flight_Number: "Numéro de l'avion",
  Bus_Number: "Numéro de bus",
  travel_insurance: "Votre voyage bénéficie-t-il d'une assurance voyage ?",
  Description: "Description",
  Terms_and_condition: "Conditions générales d'utilisation",
  Post_a_travel_announcement: "Vendre des kg de bagages ",
  Trip_Type: "Type de voyage",
  Total_Carriage_Capacity: "Capacité totale de transport",
  Drop_Off_Location: "Lieu de récupération des bagages",
  Delivery_Location: "Lieu de livraison des bagages",
  Earning_Oppurtunity: "Possibilité de gagner de l'argent",
  Journey_Details: "Détails du voyage",
  Departure: "Départ",
  Arrival: "Arrivée",
  Send_Luggage_Request_to_Traveller:
    "Envoyer une demande de bagages au voyageur",
  Review_Your_request: "Vérifiez votre demande",
  Send_Luggage_Sender: "Envoyer le plan de voyage à l'expéditeur des bagages",
  Unit_Price: "Prix unitaire",
  Congratulations: "Toutes nos félicitations!! ",
  Your_advertisement_successfully: "Votre annonce a été publiée avec succès",
  Your_may_view_advertisement:
    "Vous pouvez visionner l'annonce en direct dans la section ",
  Your_may_Luggage_advertisement:
    "Vous pouvez voir la publicité en direct dans Annonce de bagages",
  Travel_Announcement: "“Annonce de voyage”",
  section: "“Annonce de voyage”",
  sectionL: "Annonceurs de bagages",
  I_Validate_Post_Advert: "Je valide et publie l'annonce",
  one_way: "Aller Simple",
  Submit_and_fill: "Soumettre et remplir les détails du voyage de retour",
  sending_requests:
    "Voici quelques-unes des demandes d'envoi de bagages correspondantes que nous avons trouvées",
  sending_requests_Travel:
    "Vous trouverez ci-dessous quelques-unes des demandes d'envoi de voyages correspondantes que nous avons trouvées.",
  sender_directly:
    "Vous pouvez contacter directement l'expéditeur des bagages pour qu'il vous contacte afin d'envoyer ses bagages.",
  sender_directly_Travel:
    "Vous pouvez contacter directement l'expéditeur du voyage pour vous contacter afin d'envoyer ses bagages.",
  Onward_Journey_Details: "Détails du voyage à venir",
  Return_departure_time: "Heure de retour et de départ",
  return_arrival_time: "Heure d'arrivée du retour",
  return_departure_date: "Date de retour et de départ",
  return_arrival_date: "Date d'arrivée du retour",
  Type_luggage: "Type ou nature des bagages",
  Luggage_Dimensions: "Dimensions des bagages",
  Width: "Largeur",
  Weight: "Poids total",
  Colour: "Couleur",
  Volume: "Volume",
  contents_under_warranty:
    "Le produit ou son contenu est-il encore sous garantie ?",
  estimate_of_luggage: "Estimation du prix actuel",
  receiver_details: "Coordonnées du receveur des bagages",
  Name_of_receiver: "Récepteur de bagages",
  Phone_code: "Indicatif téléphonique",
  Phone_Contact_number: "Numéro de contact",
  Delivery_communicated: "Le code de livraison doit être communiqué à hi",
  getting_your_luggage: "Allez-vous réceptionner vos propres bagages?",
  Your_relationship_receiver:
    "Votre relation ou lien avec le destinataire des bagages",
  Location_of_luggage: "Adresse de ramassage",
  Location_of_article: "Localisation de l'article",
  Luggage_Sender_Type: "Type d'expéditeur de bagages",
  detail_content_luggage_carried:
    "Veuillez préciser le contenu du bagage à transporter",
  I_CONFIRM_THAT:
    "JE CONFIRME QUE LE CONTENU N'EST PAS ILLÉGAL ET DANGEREUX (DROGUES - PRODUITS DANGEREUX - TOXIQUES)",
  Boat: "Bateau",
  Kick_scooter: "Trottinette",
  Scooter: "Scooter",
  Tgv: "TGV",
  Tramway: "Tramway",
  Luggage_Location: "Localisation des bagages",
  Luggage_Sender_type: "Type d'expéditeur de bagages",
  Traveller_Type: "Type de voyageur",
  Traveller: "Voyageur",
  from: "depuis",
  Luggage_Wort: "Valeur des bagages",
  Luggage_Weigh: "Poids des bagages",
  Shipping_Detail: "Détail de l'expédition",
  receiver_name: "Nom du destinataire",
  receiver_contact_number: "Numéro de contact du destinataire",
  RegularI_send_luggages_reguler:
    "Régulier - J'envoie régulièrement des bagages",
  Periodic_send_luggages_periodically:
    "Périodique - j'envoie des bagages périodiquement",
  One_off_luggage_sender:
    "Un expéditeur unique - je suis un expéditeur unique de bagages",
  Type_nature_luggage: "Type ou nature des bagages",
  New_Never_Used: "Nouveau - Jamais utilisé",
  Used_Secound_hand: "Occasion - Deuxième main",
  Trip_Typ: "Type de voyage",
  vehical_Detiles: "Détails du véhicule",
  Return_Journey_Details: "Détails du voyage de retour",
  Please_upload:
    "Veuillez télécharger un minimum de 3 ou un maximum de 4 images. Veuillez réessayer.",
  bike: "Vélo",
  price: "Prix à l’unité",
  quantity: "Quantité",
  Geolocation: "Adresse de localisation",
  Product_Price_Unit: "Prix du produit / unité",
  Product_Weight: "Poids du produit/unité",
  Item_Location: "Localisation de l'article",
  Available_Quantity: "Quantité disponible",
  Item_Location_Addres: "Emplacement ou adresse de l'article",
  Article_Announcement: "Annonce d'article",
  MoterBike: "Moto",
  Way_to: "Destination finale",
  Article_Donation_Announcement: "Annonce pour article de don",

  luggage_ad: "Annonce de demande d'envoi de bagages",
  PLEASE_VERIFIED_PROFILES:
    "VEUILLEZ ENVOYER VOS DEMANDES AUX VOYAGEURS DONT LE PROFIL A ÉTÉ VÉRIFIÉ.",
  title_of_luggage: "Titre du bagage",
  type_of_packer: "Type d'emballeur",
  send_date: "Date d'envoi",
  luggage_estimation: "Estimation du nombre de bagages",
  enter_luggage_estimation: "Saisir l'estimation de la valeur des bagages",
  total_kilos_of_luggage: "Nombre total de kilos de bagages",
  enter_total_kilos_of_luggage: "Indiquer le nombre total de kilos de bagages",
  luggage_receiver_name: "Nom du récepteur de bagages",
  enterluggage_receiver_name: "Nom du récepteur des bagages",
  luggage_receiver_telephone: "Numéro de téléphone du récepteur de bagages",
  enter_luggage_receiver_telephone:
    "Saisir le téléphone du récepteur des bagages",
  description_and_other_information: "Description et autres renseignements",
  enter_description_and_other_information:
    "Saisir la description et d'autres informations",
  Upload_image_in_1280_720_dimension:
    "Télécharger l'image de dimensions 1280*720",
  You_can_add_4_images_maximum: "Vous pouvez ajouter 4 images maximum",
  enter_description_and_other_information:
    "Saisir la description et d'autres informations",

  add_an_article: "Ajouter un article",
  location: "Localisation",
  price_per_item: "Prix (par article)",
  ads_category: "Catégorie d'annonces",
  select_ads_category: "Sélectionner une catégorie d'annonces",
  quantity_number_of_item: "Quantité (nombre d'articles)",
  weight_kg: "Poids (Kg)",
  type: "Type",
  tags: "Étiquettes",
  status: "Statut",
  very_good_status: "Très bon état",
  good_condition: "Bon état",
  average_state: "État moyen",
  first_owner: "Premier propriétaire",
  yes: "Oui",
  no: "Non",
  material: "Matériel",
  color: "Couleur",
  width: "Largeur",
  submit_article_and_add_more: "Soumettre un article et en ajouter d'autres",
  confirm_the_sale: "Confirmer la vente",
  exit: "Sortir",

  add_article_donation: "Ajouter un article pour don ",
  new: "Nouveau",
  used: "Occasion",
  submit_article_donation_and_add_more:
    "Soumettre un don d'article et en ajouter d'autres",
  confirm_the_gift: "Confirmer le don",

  delivery_date: "Date de livraison",
  Delivery_Place: "Lieu de livraison",
  Delivery_by: "Livraison par",

  Do_luggage_sending_requirement:
    "Voyez-vous une correspondance avec votre besoin d'envoi de bagages ?",
  advert_sending_luggage: "Réservez cette annonce pour l'envoi de bagages",
  travel_info_luggage_sender:
    "Envoyez vos informations de voyage à l'expéditeur de bagages",
  digital_signature: "Signature électronique",
  Save_review_request: "Sauvegardez et procédez à l'examen de votre demande",
  edit_luggage_details: "Retour à l'édition des détails du bagage",
  validate_send_request: "Je valide et j'envoie la demande",
  request_buy: "Demande d'achat",
  Request_get_item: "Demande d'obtention de cet article",
  Send_Request: "Envoyer une demande",
  Travel_Announcement: "Annonce de voyage",
  PLEASE_CARRY_SHIPPER:
    "VEUILLEZ TRANSPORTER LES BAGAGES D'EXPÉDITEURS DONT LES PROFILS ONT ETE VÉRIFIÉS",
  Post_Advertisement: "Publier une annonce",
  DELIVERY_INFOS: "Informations sur la livraison",
  Updated_At: "Mise à jour à",
  Send_travel_luggage_sender:
    "Envoyez vos informations de voyage à l'expéditeur de bagages",
  Send_request_buy_item: "Envoyez une demande d'achat de ce produit",
  Luggage_data:
    " Vous êtes sur le point d'envoyer une demande au voyageur pour l'envoi de bagages à la destination spécifiée. Veuillez noter que si le voyageur accepte votre demande, nous débiterons votre carte de crédit du montant requis pour le voyage, tel qu'indiqué dans la rubrique 'Prix à payer'.",
  Travel_Announcement_Details: "Détails de l'annonce de voyage",
  Luggage_Request_Details: "Détails de la demande de bagages",
  Articles_Sale: "Articles à vendre",
  Cobagage_Articles_Donations: "Articles pour dons",
  Weight_carriage_capacity: "Kilos  à transporter ",
  Price_arry_weight: "Prix du kilo à transporter",
  planned_middle_trip: "Une escale est-elle prévue au milieu de votre voyage ?",
  Luggage_collection_place: "Lieu de récupération des bagages",
  Luggage_drop_place: "Lieu de dépôt des bagages",
  Does_benefit_travel_insurance:
    "Votre voyage bénéficie-t-il d'une assurance voyage ?",
  Your_luggage_instructions: "Instructions relatives à vos bagages",
  Departure: "Départ",
  Arrival: "Arrivée",
  Posted_By: "Publié par",
  packer_type: "Type d'emballeur",
  Posted_Date: "Date de publication",
  Member_since: "Membre depuis",
  delivery_place: "Lieu de livraison",
  View_Trip_Details: "Voir les détails du voyage",
  view_details: "Voir les détails",
  Note: " Note** - Tout produit liquide, parfum, gel douche, shampoing, lotion, dentifrice, au-delà d'un flacon de 100ml chacun",
  aerosol:
    "Tout aérosol et cosmétique dont la contenance dépasserait 100 ml par flacon",
  firearm: "Toute arme à feu (évidemment...)",
  ammunition:
    "Toute munition ou cartouche destinée à la chasse ou aux armes de sport (évidemment...)",
  weapon:
    "Toute arme tranchante, et plus généralement tout objet contondant ou coupant, métallique ou non (ciseaux, lame, cutter, couteau suisse, etc.).",
  general:
    "En général, les objets tranchants, les liquides, les armes et le gel ne peuvent voyager que dans les soutes.",
  Post_luggage_sending_request: "Envoyez vos bagages par un voyageur",
  Post_article_sale: "Vendez vos articles",
  Post_article_donation: "Faîtes un don d’article",
  title: "Titre",
  Type_Article: "Type d'article",
  traveller_type: "Type de voyageur",
  Regular: "Régulier ",
  Oneoff: "Unique",
  Carrying_Capacity_remaining: "Capacité de transport restante",
  Remaining_Capacity: "Capacité restante",
  Remaining_Qty: "Quantité restante",
  Luggages_sents: "Total bagages envoyés",
  site_ookies: "Ce site utilise des cookies",
  We_third_similar:
    "Nous et des tiers sélectionnés utilisons des cookies (ou similaires",
  technologies_marketing_efforts:
    "technologies) à des fins techniques, pour améliorer et analyser l'utilisation du site afin de soutenir nos efforts de marketing",
  Accept: "Accepter",
  Decline: "Déclin",
  carry_luggage: "Prix de la livraison/Vente de l'article",
  Remove_cart: "Supprimer cet article du panier",
  Proceed_payments: "Procéder aux paiements",
  complete_your_Transaction: "Pour finaliser votre Transaction",
  Click_button: "Cliquez sur le bouton Continuer",
  minimum_3maximum_4images:
    "Veuillez télécharger un minimum de 3 et un maximum de 4 images. Veuillez réessayer.",
  updated_Successfully: "Mis à jour avec succés...",
  Your_deleted_Successfully: "Supprimé avec succès...",
  With_maximum_allowed:
    "Les fichiers d'une taille maximale de 4 Mo sont autorisés",
  //New changes"
  Article_Quantity: "Quantité d'articles",
  Requester_Location: "Localisation du demandeur",
  Your_luggage_request_successfully_traveller:
    "Votre demande de bagages a été envoyée avec succès au voyageur. Il examinera les détails de la demande et acceptera ou rejettera votre demande. Si votre demande est acceptée, nous débiterons votre carte du coût du voyage.",
  You_Luggages_section:
    "Vous pouvez également voir l'état actuel de la demande dans la section « Mes demandes/Bagages ».",
  Your_travel_luggage_sender:
    "Votre projet de voyage a été envoyé avec succès à l'expéditeur des bagages. Il examinera le plan de voyage et acceptera ou rejettera votre demande. Si votre demande est acceptée, nous débiterons sa carte du coût du voyage et vous serez informé des suites à donner.",
  You_My_Travels_section:
    "Vous pouvez également voir l'état actuel de la demande dans la section « Mes demandes  ».",
  Send_Request_Article_Donation: "Envoyer une demande de don d'article",
  Items_request_basket: "Articles dans votre panier de demande",
  File_Size: "Les fichiers d'une taille maximale de 4 Mo sont autorisés",
  Total_Quantity: "Quantité totale",
  remaining_quantity: "Quantité restante",
  Quanatity_require: "Quantité dont vous avez besoin",
  More_Donation: "Plus d'articles en vente et dons (publié par)",
  File_allowed: "Les fichiers d'une taille maximale de 4 Mo sont autorisés",
  Select_country: "-Choisissez le pays-",
  select_price_range: "Sélectionnez une fourchette de prix",
  below: "ci-dessous",
  Select_trip_type: "-Sélectionnez le type de voyage-",
  select_traveller_type: "-Sélectionnez le type de voyageur-",
  traveller_status: "-Statut de voyageur-",
  sort_by: "-Trier par-",
  less_than: "Moins que",
  between: "Entre",
  More_than: "Plus que",
  Individual: "Individuel",
  Professional: "Professionnel",
  verified: "Vérifié",
  Unverified: "Non vérifié",
  by_ascending_price: "Par prix croissant",
  by_decreasing_price: "En prix dégressif",
  per_kg_increasing: "Par kg en augmentation",
  per_kg_decreasing: "Par kg décroissant",
  recent_announcement: "Annonces récentes",
  shipment: "Éco-responsable",
  Faire_voyageurs:
    "Faire du cobagage revêt de nombreuses formes tant par les moyens de locomotion que les diverses destinations des voyageurs.",
  La_société:
    "La société Cobagage est en effet très active dans le domaine de l'écologie et tend à",
  réduire_C02: "réduire les émissions de C02",
  par_cobagage: "par l'intermédiaire d'un service simple, celui du cobagage.",
  Pourquoi_C02:
    "Pourquoi devenir cobagageur permet de réduire les émissions de C02 ?",
  Lorsque_avion:
    "Lorsque l'on pense au voyage ou l'expédition de colis, la première pensée qui nous vient à l'esprit est le voyage par avion",
  Il_trajets:
    "Il est vrai que ce moyen de locomotion est le plus rapide et le plus pratique pour faire de longs trajets.",
  Pour_transporter:
    "Pour transporter vos bagages d'un point à un autre, que cela soit au niveau national ou international, l'avion n'est pourtant pas le seul moyen de transport envisageable, loin de là !",
  Lavantage_transport:
    "L'avantage en faisant du cobagage est de pouvoir emmener un bagage d'un point A à un point B par l'intermédiaire de toutes sortes de transport.",
  La_envisagées:
    " La voiture ou encore le train restent également deux moyens idéaux pour se déplacer et faire du cobagage, mais d'autres solutions peuvent également être envisagées.",
  Ainsi_trottinette:
    " Ainsi, pour des trajets plus courts il est possible d'utiliser le RER, le métro, la moto, le scooter, le bus ou encore la trottinette !",
  Tous_de:
    "Tous les moyens de transport sont les bienvenus pour pratiquer le cobagage et permettre de",
  earn_recipient:
    "gagner de l'argent en apportant un bagage à son destinataire",
  Faire_cobagage: "Faire du cobagage à l'international",
  Gagner_site_Cobagage:
    "Gagner de l'argent en voyageant à l'international ou simplement expédier un bagage longue distance représente l'essence même des services offerts par le site Cobagage.",
  Il_coûteux:
    " Il faut dire que faire appel à une société de fret pour expédier un bagage dans une destination lointaine peut vite devenir particulièrement coûteux !",
  Calling_cost:
    "Faire appel à un voyageur pour transporter ses bagages s'avère être une opération gagnante à tous les niveaux : les bagages arrivent rapidement et à un coût maîtrisé.",
  De_par:
    "De son côté, le voyageur pourra rentabiliser une partie de son voyage longue distance par",
  le_transport: "le transport de bagages à l'international",
  Par_bagages:
    "Par exemple : Un voyageur part pour Moscou et dispose de 25 kilos disponibles dans ses bagages.",
  Le_prix_HT: "Le prix au kilo a été fixé par le voyageur à 15 € HT.",
  Le_HTcommission:
    "Le voyageur pourra donc disposer de la somme de 375 € HT (348,75 € HT après commission)",
  Une_côté_tarifs:
    "Une jolie somme qui sera bienvenue pour profiter pleinement de son voyage à Moscou tandis que l'expéditeur aura pu profiter d'un service fiable et avantageux côté tarifs.",
  Les_conditions: "Les conditions à respecter pour l'envoi à l'international",
  Les_envois:
    "Les envois à l'international nécessitent les mêmes précautions à pendre que pour un envoi national.",
  Néanmoins_confiscation:
    " Néanmoins, certains bagages pouvant être soumis au contrôle de la douane, il est important de toujours s'assurer de respecter la législation du pays dans lequel le bagage sera envoyé afin d'éviter toute confiscation.",
  En_destinataire:
    "En tant que voyageur, ce dernier a l'obligation de se rendre au rendez-vous pour récupérer le bagage de l'expéditeur et le remettre en parfait état au récepteur / destinataire.",
  Les_destinataire:
    "Les deux protagonistes ainsi que le récepteur seront alors les garants du bagage tout au long de la procédure et devront signer l'ordre de commande assurant le bon état de ce dernier et sa remise au destinataire.",
  L_international: "L'envoi de bagage à l'international",
  n_Cobagage:
    "n'aura jamais été aussi peu coûteux et pratique qu'avec Cobagage !",
  Round_Way: "Aller-Retour",
  select_Weight: "Sélectionnez le poids",
  Select_Country: "Choisissez le pays",
  Select_Type: "Sélectionnez le type de voyage",
  Traveller_Status: "Statut de voyageur",
  Sort_By: "Trier par",
  Paiement_sécurisé: "Paiement sécurisé",
  En_transactions:
    "En choisissant Cobagage, vous avez l'assurance d'une plateforme de paiement sécurisée pour vos transactions",
  Parce_circonstances:
    "Parce que chaque transaction entre cobagageurs passe par notre site, vous avez l'assurance d'un paiement sécurisé en toutes circonstances.",
  Nous_assurer:
    "Nous privilégions les paiements par Paypal et Carte bancaire sur Cobagage pour vous assurer",
  un_fiable: "un service de paiement fiable",
  et_contrainte: "et sans contrainte.",
  Comment_sécurisé: "Comment savoir si mon paiement est bien sécurisé ?",
  Il_risque:
    "Il existe tellement de sites sur internet qu'il n'est pas toujours facile de s'y retrouver et de savoir s'il est possible de réaliser ses paiements sans aucun risque.",
  Chez_honneur: "Chez Cobagage, nous mettons un point d'honneur à ",
  sécuriser_clients: "sécuriser les transactions de chacun de nos clients",
  Éco_responsable: "Éco-responsable",
  En_cobagageur:
    "En devenant cobagageur, vous participez directement à la réduction de l'empreinte carbonne.",
  La_tend:
    "La société Cobagage est en effet très active dans le domaine de l'écologie et tend à",
  réduire_C02: "réduire les émissions de C02",
  par_cobagage: "par l'intermédiaire d'un service simple, celui du cobagage",
  Pourquoi_émissions:
    "Pourquoi devenir cobagageur permet de réduire les émissions de C02 ?",
  L_destinataire:
    "L'initiative du site cobagage est de permettre à deux parties de conclure un contrat dans lequel l'une devra remettre un bagage, et la seconde le transporter au cours de son voyage jusqu'à un destinataire.",
  L_émissions:
    "L'avantage de ce service est de bénéficier d'une réduction des émissions de C02.",
  A_constater: "A quel niveau peut-on réellement constater",
  un_environnement: "un impact favorable sur l'environnement",
  La_destination:
    "La raison en est très simple. Si nous prenons l'exemple distinct d'une personne réalisant un voyage à New-York, que ce soit pour le plaisir ou pour affaires, et une personne souhaitant expédier un bagage vers un destinataire à cette même destination.",
  Views: "Vues",
  En_avantages:
    "En pratiquant le cobagage, vous pouvez avoir un impact positif sur l'environnement, et ce tout en profitant de nombreux avantages.",
  Que_agnante:
    "Que vous souhaitiez gagner de l'argent en voyageant ou expédier vos bagages à coûts réduits, le cobagage reste la solution gagnante.",
  Privilégier_propres: "Privilégier les moyens de transport propres",
  Pour_recommander:
    "Pour pouvoir bénéficier d'un impact favorable sur l'environnement, les moyens de transport dits propres sont à recommander.",
  Par_permet:
    "Par moyens de transport propres, il faut entendre des moyens de locomotion de types électriques comme la voiture ou la trottinette lorsque la distance du trajet le permet.",
  Cependant_locomotion:
    "Cependant, il est tout à fait possible d'envisager de réduire son impact sur l'environnement en rejetant moins de C02 en adaptant son moyen de locomotion.",
  Prenons_bagage:
    "Prenons l'exemple d'un trajet Paris-Marseille pour lequel un voyageur devra transporter et remettre un bagage.",
  Au_responsable:
    "Au lieu de prendre l'avion, impliquant le rejet de tonnes de C02 dans l'air, un voyage en train ou en voiture sera l'assurance de profiter d'un trajet éco-responsable.",
  Sur_temps:
    "Sur les transports longue distance, l'avion reste bien entendu incontournable la plupart du temps.",
  Néanmoins_bagage:
    "Néanmoins, en prenant en charge le bagage d'un expéditeur, vous participez à réduire les émissions de C02 qu'aurait pu engendrer l'envoi de ce bagage.",
  La_écoresponsable:
    "La société Cobagage a été pensée de façon éco-responsable",
  afin_C02:
    "afin de garantir à chaque voyageur la possibilité de participer à la réduction du taux d'émission de C02.",
  Une_sain: "Une véritable avancée pour un monde plus sain !",
  Devenir_service:
    "Devenir éco-responsable en misant sur un voyage alliant plaisir et service",
  Plus_quotidienne:
    " Plus que jamais l'impact que nous avons sur l'environnement, chacun à notre échelle, est à prendre en compte dans le cadre de notre vie quotidienne.",
  En_réels:
    "En devenant cobagageur, vous pouvez participer à cette volonté de réduire les émissions de C02 tout en profitant de services et d'avantages réels.",
  Vous_simplicité:
    " Vous êtes voyageur ? Ne vous contentez pas de voyager sans utiliser vos kilos de bagages vides et gagnez de l'argent en toute simplicité.",
  Vous_envoi:
    "Vous êtes expéditeur ? Choisissez une solution de transport simple et efficace permettant de profiter du trajet d'un voyageur pour arriver jusqu'à votre destinataire et économisez sur les frais d'envoi.",
  Le_services:
    "Le site Cobagage est là pour vous guider dans vos démarches et vous mettre en relation entre cobagageurs pour profiter pleinement de nos services.",
  Devenez_cobagageurs: "Devenez cobagageurs et participez à",
  la_environnement: "la préservation de l'environnement",
  Request_successfully: "Demande envoyée avec succès",
  Contact_Info: "Informations de contact",
  Lets_Talk: "Parlons",
  For_networks: "Pour les réseaux sociaux",
  For_payments: "Pour les payements",
  Address: "Adresse",
  Contact_us: "Contactez-nous",
  Head_office: "Siège social",
  Visit_Us: "Rendez nous visite sur les réseaux sociaux",
  Name: "Nom",
  Subject: "Objet",
  message: "Message",
  Send_Message: "Envoyez le message",
  Cobagage_as_Job: "Cobagage comme job",
  Cobagage_as_Job_text1:
    "Une nouvelle façon de gagner sa vie grâce aux voyages",
  Cobagage_as_Job_text2:
    "Utiliser Cobagage est l'assurance de pouvoir profiter d'avantages intéressants, que vous soyez expéditeur ou voyageur.",
  Cobagage_as_Job_text3:
    "Faire du co-bagage s'applique aussi bien aux particuliers qui souhaitent rentabiliser une partie de leurs frais, qu'aux professionnels.",
  Cobagage_as_Job_text4: "Pratiquer le co-bagage à des fins professionnelles",
  Cobagage_as_Job_text5:
    "s'avère idéal pour envisager une nouvelle façon de gagner sa vie.",
  Cobagage_as_Job_text6: "Rentabiliser ses voyages en tant que particulier",
  Cobagage_as_Job_text7:
    "Le site Cobagage a été pensé afin de faciliter la vie aussi bien à ceux qui souhaitent expédier leurs bagages facilement et à moindre coût, qu'à ceux qui souhaitent rentabiliser une partie de leurs voyages en transportant des bagages.",
  Cobagage_as_Job_text8:
    "Gagner de l'argent grâce au site Cobagage s'avère une excellente solution pour les particuliers qui ont envie de gagner de l'argent simplement et ainsi réduire les dépenses liées à leur voyage.",
  Cobagage_as_Job_text9:
    "En monétisant ses kilos vides et en vendant ses articles s'il le souhaite,  le voyageur a l'assurance de profiter d'une somme finale lui permettant d'économiser sur ses frais de séjour.",
  Cobagage_as_Job_text10:
    "Pratiquer le co-bagage en tant que particulier est idéal pour gagner de l'argent, mais cela peut également devenir ",
  Cobagage_as_Job_text11: "une activité à temps plein",
  Cobagage_as_Job_text12:
    "Se professionnaliser dans l'univers du co-bagage est effectivement envisageable afin d'en faire un métier à part entière permettant de gagner sa vie.",
  Cobagage_as_Job_text13:
    "Les particuliers tout comme les professionnels peuvent utiliser le site Cobagage afin de trouver des expéditeurs souhaitant envoyer leurs bagages à une destination précise.",
  Cobagage_as_Job_text14:
    "La plateforme Cobagage assure non seulement la mise en relation mais également la sécurisation de vos transactions.",
  Cobagage_as_Job_text15:
    "Pour cela, le voyageur, l'expéditeur ainsi que le récepteur du bagage doivent signer un ordre de commande.",
  Cobagage_as_Job_text16:
    "En utilisant Cobagage afin de vous mettre en relation avec un expéditeur ou un voyageur, vous avez l'assurance de trouver le partenaire idéal et de bénéficier d'un paiement sécurisé.",
  Cobagage_as_Job_text17:
    "Utiliser Cobagage en tant que professionnel : Cobagage comme travail",
  Cobagage_as_Job_text18:
    "La plupart des personnes faisant le choix de devenir co-bagageurs le font afin de profiter de leur voyage pour rentabiliser leurs kilos de bagages vides.",
  Cobagage_as_Job_text19:
    "Cette solution peut également être l'occasion de penser le cobagage comme une façon de gagner sa vie par l'intermédiaire de ses voyages tout au long de l'année.",
  Cobagage_as_Job_text20: "Est-il réellement possible d'envisager de faire du ",
  Cobagage_as_Job_text21: "co-bagage en tant que métier",
  Cobagage_as_Job_text22: "à part entière ?",
  Cobagage_as_Job_text23:
    "La réponse est oui et peut s'avérer être particulièrement intéressante au niveau financier.",
  Cobagage_as_Job_text24:
    "Prenons un exemple : Vous effectuer un voyage à Rome dans le but de transporter des bagages que vous monétisez au kilo.",
  Cobagage_as_Job_text25:
    "Vous avez 30 kilos de disponibles que vous facturez au prix de 10 euros le kilo.",
  Cobagage_as_Job_text26:
    "Pour ce voyage, vous pourrez gagner 300 euros (25 % de commission HT étant déduit de ce montant par le site Cobagage), soit un total net de 225 euros HT.    ",
  Cobagage_as_Job_text27:
    "Sachant qu'il est également possible de vendre ou donner des articles, cette somme peut rapidement grimper.",
  Cobagage_as_Job_text28:
    "Vous l'aurez compris, plus vous avez de kilos vides à proposer, plus vous pourrez profiter d'une rémunération importante.",
  Cobagage_as_Job_text29:
    "Utiliser le site Cobagage comme travail est donc accessible à tous ceux qui aiment voyager et ont l'opportunité de transporter des bagages d'un point à un autre.",
  Cobagage_as_Job_text30:
    "La rigueur est la ponctualité sont deux qualités essentielles pour envisager le co-bagage en tant que métier.",
  Cobagage_as_Job_text31:
    "Si vous pensez disposer des qualités requises, l'expérience du co-bagage s'offre à vous pour gagner votre vie grâce à vos voyages.",
  Travel_Details: "Détails de l'annonce de voyage",
  Cobagage_Announcements: "Annonces de bagages à cobagage",
  Cobagage_Sale: "Articles de cobagage en vente",
  results_found: "résultats trouvés",
  Cobagage_Donations: "Articles de cobagage pour les dons",
  send_buying_request: "Envoyer une demande d'achat",
  confirm_request: "Confirmer la requête",
  time: "Temps",
  i_want_this: "Je veux ceci",
  not_verified: "non vérifié",
  verified: "Vérifié",
  send_request_to_article1: "Envoyer la demande à l'article",
  your_earning: "Vos gains",
  price_to_pay: "Prix ​​à payer",
  traveller_fees: "Frais de voyage",
  service_fees: "Frais de service",
  total_fees: "Total fees",
  // new chnages
  Plus_Cobagage: "Plus sur Cobagage",
  Vous_sujet:
    "Vous arrivez sur la plateforme Cobagage et vous souhaitez en savoir plus à notre sujet ?",
  Cette_fonctionnement:
    "Cette page est destinée à vous en apprendre plus sur notre société ainsi que notre fonctionnement",
  Devenez_rêves:
    "Devenez cobagageurs et plongez dans l'univers du cobagage pour faciliter votre quotidien et donner vie à vos rêves",
  Trouver_idéal: "Trouver le cobagageur idéal",
  vous_éclaircies:
    "Si vous n'avez encore jamais pratiqué le cobagage, notre plateforme nécessite certainement que certaines petites zones d'ombre soient éclaircies.",
  Le_relation:
    "Le site Cobagage a pour but de vous aider à entrer en relation :",
  Avec_moindre:
    "- Avec un voyageur si vous êtes expéditeur et que vous souhaitez expédier des objets / colis / bagages à moindre coût.",
  Avec_vides:
    "- Avec un expéditeur si vous êtes voyageur et que vous cherchez à rentabiliser vos kilos de bagages vides.",
  Par_besoins:
    "Par l'intermédiaire de Cobagage, vous pouvez donc être mis en relation avec la personne idéale selon vos besoins.",
  Chaque_simplement:
    "Chaque utilisateur doit simplement s'inscrire puis remplir une annonce.",
  Celle_encore:
    "Celle-ci comprendra la description du trajet effectué, la date ainsi que les kilos vides accompagnés du prix par kilos, ou encore",
  Cintermédiaire:
    "C'est par l'intermédiaire de cette annonce que les cobagageurs sont mis en relation entre eux par Cobagage. Une annonce détaillée est donc l'assurance de trouver la personne idéale.",
  Le_voyager: "Le cobagage, une nouvelle façon de voyager",
  Pratiquer_jamais: "Pratiquer le cobagage est plus que jamais",
  une_façon: "une nouvelle façon d'envisager ses voyages",
  Vous_serré:
    "Vous souhaitez profiter de vacances bien méritées mais votre budget est peu serré ?",
  Vous_auriez:
    "Vous auriez aimé pouvoir disposer de plus de temps ou d'argent afin de profiter de vos vacances ?",
  La_dans: "La solution parfaite pour résoudre ces problèmes réside dans",
  le_cobagage: "le cobagage",
  simple_effort:
    "simple, rapide, accessible et sécurisé, il vous assurera un budget supplémentaire sans effort.",
  Pour_rendezvous:
    "Pour cela il suffit de respecter quelques petites consignes très simples : un expéditeur vous remet son bagage (correspondant aux kilos vides que vous avez mis en vente), et vous aurez simplement à remettre ce bagage au destinataire ou récepteur lors de votre arrivée après avoir convenu d'un rendez-vous.",
  Grâcerentabiliser_voyage:
    "Grâce à ce service, vous pourrez disposer d'une somme d'argent supplémentaire pour rentabiliser votre voyage.",
  Vous_venir:
    "Vous pourrez alors avoir l'opportunité de rallonger votre séjour, effectuer plus de dépenses sur place ou simplement mettre de côté pour vos projets à venir.",
  Choisir_transactions: "Choisir Cobagage pour assurer ses transactions",
  En_passant_transactions:
    "En passant par le site Cobagage, vous avez l'assurance de profiter de nombreux services et de sécuriser vos transactions.",
  Vous_plateforme:
    "Vous avez peur de ne pas être payé par un cobagageur ? Grâce à Cobagage, chaque transaction s'effectue directement par le biais de la plateforme.",
  Que_rencontrée:
    "Que vous soyez particulier ou professionnel, trouver un cobagageur peut vite devenir le parcours du combattant, sans garantie du sérieux de la personne rencontrée.",
  En_montant_final:
    "En réalisant chacune de vos transactions sur Cobagage, vous pouvez profiter d'un service fiable, la comission du site s'élevant seulement à 25 % HT du montant final",
  La_signature:
    "La signature de l'ordre de commande constitue le coeur même de ntore site, assurant la conformité du bagage par les trois parties concernées (voyageur, expéditeur et récepteur).",
  Chaque_sujet:
    " Chaque paiement est dû à condition que le bagage ait bien été remis puis transmis au récepteur sans encombre",
  voir_page: "voir la page",
  pour_sujet: "pour en savoir plus à ce sujet",
  En_sérénité:
    "En faisant confiance à notre site, vous pourrez compter sur le sérieux et la disponibilité de notre équipe et ainsi réaliser chacune de vos missions de cobagage en toute sérénité.",
  la_transporter: "la nature du bagage à transporter",

  Article_Images:
    "Images de l'article (Télécharger l'image dans des dimensions de 1280 px * 720 px)",
  click_drag_image_here: "+ cliquez ou faites glisser l'image ici",
  you_are_about_send_travel_plan_to_luggage_sender_shipper:
    "Vous êtes sur le point d'envoyer votre projet de voyage à l'expéditeur de bagages",
  you_are_about_send_travel_plan_to_luggage_sender_shipper1:
    "qui souhaite envoyer son envoi dans la zone où vous avez l'intention de voyager. Veuillez noter que si l'expéditeur des bagages accepte votre demande, nous débiterons sa carte de crédit du montant du voyage requis. Vous recevrez le montant affiché sous « Vos gains » 3 jours après la fin de la livraison.",
  basket_delete: "Panier Supprimer le avec succès",
  order_summary: "Récapitulatif de la commande",
  site_service_fees: "Frais de Cobagage",
  Luggage:"Bagage",
  Article:"Article",
  Article_Donation:"Article Donation",
  total_payable_amount: "Montant total à payer",
  buyer_has_been_notified:
    "L'acheteur a été informé de votre acceptation. Veuillez attendre que le paiement arrive, puis expédier l'article au domicile de l'acheteur.",
  next_step: "Prochaines étapes",
  buyer_will_pay:
    "Lacheteur paiera le montant demandé pour l'article. D'ici là, vous pouvez voir le statut de la commande dans la section Mes paiements en attente - Articles.",
  once_the_buyer_pays:
    "Une fois que l'acheteur a payé le montant demandé, vous pouvez envoyer l'article via un voyageur jusqu'à la destination de l'acheteur.",
  happy_travelling_by_earning: "Bon voyage tout en gagnant",
  user_has_been_notified:
    "L'utilisateur a été informé de votre acceptation du don de l'article.",
  please_make_agreement:
    "Veuillez prendre des dispositions pour envoyer l'article donné à un endroit agréable.",
  the: "la",
  has_been_notified: "Votre refus a été envoyé avec succès au demandeur",
  giver_status: "Statut du donateur",
  seller_status: "Statut du vendeur",
  newly_posted: "Récemment publié",
  relavance: "Pertinence",
  updated_successfully: "Mise à jour réussie...",
  signature_valid: "Signature valable par ",
  add_submited: "Annonce soumise avec succès",
  data_created_succesfully: "Données créées avec succès",
  Price_of_Item: "Frais de port de bagages",
  Donation_page: "Page de détails du don",
  subscribe_message:
    "Vous n'avez actuellement aucun plan d'abonnement. Veuillez obtenir un abonnement mis à niveau et profiter de zéro frais de service !",
  Out_stock: "En rupture de stock",
  Stripe: "Carte de crédit",
  Mobile_Moeny: "Mobile money",
  Procesed_payment: "Procéder au paiement",
  article_dimension: "Dimensions des articles",
  currency: "Devise",
  Select_currency: "Sélectionnez la devise",
  Account_deleted_successfully: "Compte supprimé avec succès",
  Requester_Name: "Nom du demandeur",
  Direction: "Pour la direction",
  Success: "Succès!",
  Your_advertisement_has_been_posted_successfully:
    "Votre annonce a été publiée avec succès",
  Your_view_advertisement_in: "Vous pouvez voir la publicité en direct dans",
  Article_on_sale: "Article en vente",
  Articles_For_Donation: "Articles pour don",
  announcements_section: "Rubrique annonces.",
  Continue_Order: "Continuez votre commande",
  Please_Continue_Your_Order:
    "Veuillez ne pas appuyer sur le bouton Retour ou ne pas fermer cette fenêtre. Veuillez continuer votre commande.",
  Onward: "Aller",
  Return: "Retour",
  address1:
    "Côte d’Ivoire Abidjan - Cocody - Les II Plateaux, 7e Tranche, Après la station en direction de Zinsou, en face de la Clinique Saint Georges : 28 BP 1676 ABIDJAN 28, Gérant: Brice Delagneau +225 0101262488",
  address2:
    "USA COBAGAGE NETWORK, Inc. Delaware C Corporation 1111B S Governors Ave STE 7830 USA",
  Please_select_advertisement:
    "Veuillez sélectionner l'une de vos annonces de projet de voyage",
  Click_Payment: "Cliquez ici pour finaliser le paiement",
  Article_Delivery_Details: "Détails de livraison de l'article",
  Return: "Retour",
  address2:
    "USA COBAGAGE NETWORK, Inc. Delaware C Corporation 1111B S Governors Ave STE 7830 USA",
  address3:
    "COBAGAGE NETWORK - France 78 avenue des Champs Elysées 75008 - Paris",
  Please_select_advertisement:
    "Veuillez sélectionner l'une de vos annonces de projet de voyage",
  Click_Payment: "Cliquez ici pour finaliser le paiement",
  Article_Delivery_Details: "Détails de livraison de l'article",
  Other_Number: "Autre numéro",
  Company_Number: "Numéro d'entreprise",
  Passwords_are_not_same: "Les mots de passe ne sont pas les mêmes",
  Price_Unit: "Prix ​​à l'unité",
  Add: "Ajouter",
  Add_your_City: "Ajoutez votre ville",
  toreceive_code: "Pour recevoir le code de livraison",
  withdraw_message: "Demande de retrait envoyé avec succès",
  delivery_country: "Pays de livraison",
  all: "Tous",
  Quatity: "Quantité",
  INPI_2022014480: "INPI – Enregistrement e-Soleau DSO2022014480",
  Request_Reject:"Note:Vous ne pouvez pas envoyer la demande car l'utilisateur a déjà refusé votre demande"

};
export default form;
