import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import Luggages from "./Luggages";

function LuggageAdsWrapperArea() {
  const { t } = useTranslation();
  return (
    <>
      <div className="custom-input-group row" >
        <div className="submite-btn" style={{ position: 'absolute', textAlign: 'center', bottom: '30px', zIndex: "1" }}>
          <Link to={"/cobagageAds"}><button type="submit" id="travelbtnColor" style={{  backgroundColor: '#ff4838', color: 'white',borderRadius: '5px' }}>{t("menu.travels")}</button></Link>
          <Link to={"/luggageAds"}><button type="submit" id="LuggageBtnColor" style={{ margin: '10px', borderRadius: '5px', backgroundColor: 'white', color: '#ff4838', }}  >{t("menu.luggages")}</button></Link>
        </div>
      </div>
       <Luggages />
    </>
  );
}

export default LuggageAdsWrapperArea;
