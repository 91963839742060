import React from "react";
import LegalsWrapperArea from "./LegalsWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import BradcomePrivacyPolicy from "../../common/BradcomePrivacyPolicy";
function Legals() {
  const { t } = useTranslation();
  return (
    <>
      <BradcomePrivacyPolicy name={t("profile.Legals")} />
      <LegalsWrapperArea />

    </>
  );
}

export default Legals;
