import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import luggage from '../../../../models/luggage';
import TravelCard from '../myTravels/TravelCard';
import { useContext } from 'react';
import AppContext from '../../../../store/context/app-context';
// import LuggageCard from './LuggageCard';

export default function MyLuggages({ setValue, setRadioValue }) {
    const { t } = useTranslation();
    const [luggageDetails, setLuggageDetails] = useState([]);
    const [isShow, setIsShow] = useState(1);
    const contextObj = useContext(AppContext);

    useEffect(() => {
        getData();
    }, [])
    const getData = () => {
        luggage.allData().then(response => {
            if (response)
                setLuggageDetails(response);
        }).catch((error)=>{
            console.log("My luggages error", error);
        })
    }
    const filterLuggage = () => {
        return (luggageDetails)?.filter((item) =>
            item?.isComplete == (isShow == 1 ? false : true));
    }

    const addTraveller = () => {
        setValue(0)
        contextObj.setRadioValue(2)
        contextObj.setTabValue(0)
    }


    return (
        <div>
            <div class="container">
                <div className='package-details-wrapper p-0 '>
                    <div className='tour-package-details'>
                        <div className="package-details-tabs row">
                            <div className='d-flex flex-column gap-3 flex-md-row  justify-content-between'>
                                <ul
                                    className="nav nav-pills gap-xxl-4 gap-3"
                                    id="myTab"
                                    role="tablist"
                                    style={{ marginBottom: '0px', }}

                                >
                                    <li className="nav-item travel" role="presentation">
                                        <button
                                            className="nav-link active"
                                            id="pills-package1"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body1"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body1"
                                            aria-selected="true"
                                            onClick={() => { setIsShow(1) }}
                                            style={{ fontSize: '15px', borderRadius: '5px' }}
                                        >
                                            {t("profile.published")}
                                        </button>
                                    </li>
                                    <li className="nav-item travel" role="presentation">
                                        <button
                                            className="nav-link"
                                            id="pills-package2"
                                            data-bs-toggle="pill"
                                            data-bs-target="#pill-body2"
                                            type="button"
                                            role="tab"
                                            aria-controls="pill-body2"
                                            aria-selected="false"
                                            onClick={() => { setIsShow(2) }}
                                            style={{ fontSize: '15px', borderRadius: '5px' }}
                                        >
                                            {t("profile.completed")}
                                        </button>
                                    </li>
                                </ul>
                                <button
                                    className="nav-link active"
                                    type="button"
                                    onClick={() => addTraveller()}
                                    style={{ borderRadius: '5px', color: '#fff', border: '1px solid rgb(83, 189, 226)', backgroundColor: 'rgb(83, 189, 226)', padding: '8px 25px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 4px 8px 0px, rgba(0, 0, 0, 0.19) 0px 6px 20px 0px' }}
                                >
                                    {t("homePage.l_send_luggages")}
                                </button>
                            </div>
                            <div className="tab-content " id="pills-tabContent">
                                {/*   In Progress*/}
                                <div
                                    className="tab-pane fade show active package-info-tab pt-4 "
                                    id="pill-body1"
                                    role="tabpanel"
                                    aria-labelledby="pills-package1"
                                >
                                    <div className="row">
                                        {filterLuggage()?.map((item =>
                                            <TravelCard item={item} getData={getData} isShow={isShow} type={5} />
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
