import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import SimpleReactLightbox from "simple-react-lightbox";
// import all css
import "./index.css";
import { SnackbarProvider } from "notistack";
import { FacebookProvider } from "react-facebook";
import { SnackbarUtilsConfigurator } from "./plugin/notistack";
import App from "./components/App";
import {
  AppDataConsumer,
  ApplicationDataProvider,
} from "./store/context/app-context";
/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide

function Root() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [window.location.href]);
  return (
    <>
      <App />
    </>
  );
}

export default Root;
ReactDOM.render(
  <React.StrictMode>
    <FacebookProvider appId="660766992916336">
      <SnackbarProvider maxSnack={5}>
        <SnackbarUtilsConfigurator />
        <SimpleReactLightbox>
          <ApplicationDataProvider>
            <AppDataConsumer>
              {() => {
                return <Root />;
              }}
            </AppDataConsumer>
          </ApplicationDataProvider>
        </SimpleReactLightbox>
      </SnackbarProvider>
    </FacebookProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
