import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { auth, country } from "../../../models";
import useValidator from "../../../plugin/validator";
import AppContext from "../../../store/context/app-context";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import notistack from "../../../plugin/notistack";
import luggage from "../../../models/luggage";
import Loading from "../../common/Loading";
import signatureImage from "../../../assets/images/Signature.png";

const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_stopover: {},
  state_of_stopover: {},
  city_of_stopover: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  stopoverCountry: [],
  stopoverState: [],
  stopoverCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

function LuggageToTraveller(props) {
  const { t } = useTranslation();
  const [data, setData] = useState({
    travel_id: props?.allData.id,
    title: "",
    terms_and_condition: "",
    type: "",
    location: "",
    luggage_estimation: "",
    weight: "",
    height: "",
    length: "",
    width: "",
    volume: "",
    colour: "",
    warranty: false,
    receiver_name: "",
    receiver_phone_code: "",
    receiver_contact_number: "",
    receiver_relation: "",
    self_pickup: false,
    description: "",
    images: [],
    imagesURL: ["", "", "", ""],
    sender_signature: "",
    sender_type: "",
    country_departure: props.allData.country_departure,
    state_departure: props.allData.state_departure,
    city_departure: props.allData.city_departure,
    country_arrival: props.allData.country_arrival,
    state_arrival: props.allData.state_arrival,
    city_arrival: props.allData.city_arrival,
    country_arrival_name: props.allData.country_arrival_name,
    country_departure_name: props.allData.country_departure_name,
    departure_date: props.allData.departure_date,
    departure_time: props.allData.departure_time,
    arrival_date: props.allData.arrival_date,
    arrival_time: props.allData.arrival_time,
  });

  const [details, setDetails] = useState(format);
  const [validator, showMessage] = useValidator();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [load, setLoad] = useState(false);
  const [luggageUserData, setLuggageUserData] = useState();
  const [Profiledetails, setProfiledetails] = useState();
  const [imageArray, setImageArray] = useState(["", "", "", ""]);
  const [imgmassage, setImgmassage] = useState(["", "", "", ""]);
  const { i18n } = useTranslation();
  const [tempSignature, setTempSignature] = useState();

  useEffect(() => {
    getData();
    getAllLuggage();
    // getIdData();
    if (!contextObj.requestedLuggage) {
      // handleSetData('receiver_phone_code', "+93");
    } else if (
      !contextObj.requestedLuggage?.active_status &&
      contextObj.requestedLuggage?.active_status !== true
    ) {
      setData(contextObj.requestedLuggage);
    }
  }, []);

  useEffect(() => {
    getAllData();
  }, [contextObj.login]);

  useEffect(() => {
    findPhoneCode();
  }, [details.arriavalCountry])

  const getAllData = () => {
    auth
      .getUser()
      .then((responseData) => {
        if (responseData) {
          setProfiledetails(responseData);
        }
      })
      .catch((error) => {
        console.log("Here is error luggageToTraveller page");
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details);
        handleData("arriavalCountry", details);
        handleData("stopoverCountry", details);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const getAllLuggage = () => {
    luggage.allData().then((respons) => {
      setLuggageUserData(
        respons.filter(
          (item) =>
            item.country_departure === props.allData.country_departure &&
            item.country_arrival === props.allData.country_arrival &&
            item.city_arrival_name === props.allData.city_arrival_name &&
            item.city_departure_name === props.allData.city_departure_name &&
            item.isComplete === false
        )
      );
    });
  };

  const handleData = (name, value) => {
    setDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const convertToBase641 = (e, index) => {
    let array = [...data.imagesURL];
    let tempFile = e.target.files[0];
    let arrayMsg = [...imgmassage];
    if (tempFile.size > 4000000) {
      arrayMsg[index] = t("form.File_allowed");
      setImgmassage(arrayMsg);
      array[index] = "";
      setImageArray(array);
      let remove = data?.imagesURL ? [...data?.images] : ["", "", "", ""];
      remove[index] = "";
      handleSetData("imagesURL", remove);
      return false;
    } else {
      let tempFiles = URL.createObjectURL(e.target.files[0]);
      // props.setImage(tempFiles)
      // setExterior1(tempFiles)
      array[index] = tempFiles;
      setImageArray(array);
      handleSetData("imagesURL", array);

      getBase64Image(tempFile)
        .then((responseBase64) => {
          // array[index]=responseBase64;
          let arr = data?.images ? [...data?.images] : ["", "", "", ""];
          arr[index] = responseBase64;
          handleSetData("images", arr);
          arrayMsg[index] = " ";
          setImgmassage(arrayMsg);
        })
        .catch((err) => {
          console.log("Error While convert to base64: ", err);
        });
    }
  };

  useEffect(() => {
    contextObj.setSenderSignature(false);
    handleSetData("terms_and_condition", false);
  }, []);

  const getBase64Image = (fileParam) => {
    return new Promise((resolve) => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };

  const error = {
    title: validator.message(
      t("title"),
      props.luggageIdData ? props.luggageIdData?.title : data?.title,
      "required"
    ),
    type: validator.message(
      t("type"),
      props.luggageIdData ? props.luggageIdData?.type : data?.type,
      "required"
    ),
    location: validator.message(
      t("location"),
      props.luggageIdData ? props.luggageIdData?.location : data?.location,
      "required"
    ),
    luggage_estimation: validator.message(
      t("luggage_estimation"),
      props.luggageIdData
        ? props.luggageIdData?.luggage_estimation
        : data?.luggage_estimation,
      "required"
    ),
    weight: validator.message(
      t("weight"),
      props.luggageIdData ? props.luggageIdData?.weight : data?.weight,
      "required"
    ),
    warranty: validator.message(
      t("warranty"),
      props.luggageIdData ? props.luggageIdData?.warranty : data?.warranty,
      "required"
    ),
    receiver_name: validator.message(
      t("receiver_name"),
      props.luggageIdData
        ? props.luggageIdData?.receiver_name
        : data?.receiver_name,
      "required"
    ),
    receiver_contact_number: validator.message(
      t("receiver_contact_number "),
      props.luggageIdData
        ? props.luggageIdData?.receiver_contact_number
        : data?.receiver_contact_number,
      "required|numeric|max:20"
    ),
    receiver_phone_code: validator.message(
      t("receiver_phone_code"),
      props.luggageIdData
        ? props.luggageIdData?.receiver_phone_code
        : data?.receiver_phone_code,
      "required"
    ),
    receiver_relation: validator.message(
      t("receiver_relation"),
      props.luggageIdData
        ? props.luggageIdData?.receiver_relation
        : data?.receiver_relation,
      "required"
    ),
    self_pickup: validator.message(
      t("self_pickup"),
      props.luggageIdData
        ? props.luggageIdData?.self_pickup
        : data?.self_pickup,
      "required"
    ),
    sender_type: validator.message(
      t("sender_type"),
      props.luggageIdData
        ? props.luggageIdData?.sender_type
        : data?.sender_type,
      "required"
    ),
  };

  const handleSubmit = (event) => {
    if (validator.allValid()) {
      if (
        data?.images?.length >= 3 ||
        props?.luggageIdData?.images?.length >= 3
      ) {
        if (props?.luggageIdData?.active_status) {
          // contextObj.setRequestedLuggage(props?.luggageIdData);
          // data.title && props.setLuggageIdData(data)
        } else {
          contextObj.setRequestedLuggage(data);
        }
        props.setHide(2);
      } else {
        notistack.error(t("form.minimum_3maximum_4images"));
      }
    } else {
      showMessage(true);
    }
  };

  const getIdData = (id) => {
    contextObj.setRequestedLuggage(null);
    luggage
      .get(id)
      .then((item) => {
        console.log("item response: ", item);
        contextObj.setRequestedLuggage(item);
        props.setLuggageIdData(item);
      })
      .catch((err) => {
        console.log("luggagerrrrrid", err);
      });
  };
  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  const findPhoneCode = () => {
    let arr;
    (
      details.arriavalCountry.map((item) => {
        if (item.id == props.allData.country_arrival) {
          arr = item.phone_code
        }
      })
    )
    handleSetData("receiver_phone_code", arr);
    return
  }
  // console.log("findPhoneCode",findPhoneCode());


  return (
    <>
      {load ? (
        <Loading />
      ) : (
        <>
          <form onSubmit={(e) => e.preventDefault()} action="#" method="post">
            <div className="">
              <div className="pt-3">
                <h6 style={{ color: "red" }}>{t("form.PLEASE_VERIFIED_PROFILES")}</h6>
              </div>
              <div className="col-lg-6">
                <div className="custom-input-group">
                  <select
                    id="luggageId"
                    onChange={(e) => {
                      getIdData(e.target.value);
                    }}
                    name="luggageId"
                  >
                    <option value="" selected disabled>
                      {t("homePage.Select")}
                    </option>
                    {luggageUserData?.map((item) => (
                      <option value={item.id} key={item.id}>
                        {item.title}
                      </option>
                    ))}
                  </select>
                  <spna>
                    <i class="bi bi-caret-down-fill"></i>
                  </spna>
                  {/* <span style={{ color: "red" }}>{error.type}</span> */}
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.title")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("form.title")}
                      id="title"
                      onChange={handleChange}
                      name="title"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData?.title
                          : data?.title
                      }
                    />
                    <span style={{ color: "red" }}>{error.title}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Type_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="type"
                      onChange={handleChange}
                      name="type"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData?.type
                          : data?.type
                            ? data?.type
                            : 0
                      }
                    >
                      <option value={0} selected disabled>
                        {t("form.Type_luggage")}
                      </option>
                      <option value={1}>{t("form.New_Never_Used")}</option>
                      <option value={2}>{t("form.Used_Secound_hand")}</option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6 row">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_Of_departure")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="">
                    <h4>{props.allData.country_departure_name}</h4>
                  </div>
                </div>
                <div className="col-lg-6 row">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.country_of_arrival")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                  </div>
                  <div className="">
                    <h4>{props.allData.country_arrival_name}</h4>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row col-lg-12" style={{ marginTop: "20px" }}>
                  <label htmlFor="name">{t("form.Luggage_Dimensions")}:</label>
                  <div className="col-12 col-sm-4 ">
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.height")}</label>
                      <input
                        className="numberType"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        placeholder={t("form.height")}
                        id="height"
                        min={1}
                        onChange={handleChange}
                        name="height"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.height
                            : data?.height
                        }
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: "15px",
                          fontWeight: "500",
                          opacity: 0.6,
                        }}
                      >
                        cm
                      </span>
                      {/* <span style={{ color: "red" }}>{error.height}</span> */}
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-4 "
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.length")}</label>
                      <input
                        className="numberType"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        placeholder="length"
                        id="length"
                        min={1}
                        onChange={handleChange}
                        name="length"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.length
                            : data?.length
                        }
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: "15px",
                          fontWeight: "500",
                          opacity: 0.6,
                        }}
                      >
                        cm
                      </span>
                      {/* <span style={{ color: "red" }}>{error.length}</span> */}
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-4 "
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.width")}</label>
                      <input
                        className="numberType"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        placeholder="width"
                        id="width"
                        min={1}
                        onChange={handleChange}
                        name="width"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.width
                            : data?.width
                        }
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: "15px",
                          fontWeight: "500",
                          opacity: 0.6,
                        }}
                      >
                        cm
                      </span>
                      {/* <span style={{ color: "red" }}>{error.width}</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row col-lg-12">
                  <div className="col-12 col-sm-4 ">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {t("form.Weight")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        className="numberType"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        placeholder={t("form.Weight")}
                        id="weight"
                        min={1}
                        onChange={handleChange}
                        name="weight"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.weight
                            : data?.weight
                        }
                      />
                      <span
                        style={{
                          position: "absolute",
                          right: 15,
                          bottom: error.weight ? "37px" : "15px",
                          fontWeight: "500",
                          opacity: 0.6,
                        }}
                      >
                        kg
                      </span>
                      <span style={{ color: "red" }}>{error.weight}</span>
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-4 "
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.Colour")}</label>
                      <input
                        type="text"
                        placeholder={t("form.Colour")}
                        id="colour"
                        onChange={handleChange}
                        name="colour"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.colour
                            : data?.colour
                        }
                      />
                      {/* <span style={{ color: "red" }}>{error.colour}</span> */}
                    </div>
                  </div>
                  <div
                    className="col-12 col-sm-4 "
                    style={{ paddingRight: "0px" }}
                  >
                    <div className="custom-input-group">
                      <label htmlFor="name">{t("form.Volume")}</label>
                      <input
                        className="numberType"
                        type="number"
                        onWheel={numberInputOnWheelPreventChange}
                        placeholder={t("form.Volume")}
                        id="volume"
                        min={1}
                        onChange={handleChange}
                        name="volume"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.volume
                            : data?.volume
                        }
                      />
                      {/* <span style={{ position: 'absolute', right: 15, bottom: '15px', fontWeight: '500', opacity: 0.6 }}>cm</span> */}
                      {/* <span style={{ color: "red" }}>{error.volume}</span> */}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.contents_under_warranty")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  handleSetData("warranty", false);
                                }}
                                disabled={
                                  contextObj?.requestedLuggage?.active_status ||
                                  props.luggageIdData?.active_status
                                }
                                checked={
                                  data.warranty === 0
                                    ? props?.luggageIdData
                                      ? !props?.luggageIdData?.warranty
                                      : false
                                    : !data.warranty
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="warranty"
                                name="warranty"
                                required
                                onChange={(e) => {
                                  handleSetData("warranty", true);
                                }}
                                // checked={data?.warranty}
                                disabled={
                                  contextObj?.requestedLuggage?.active_status ||
                                  props.luggageIdData?.active_status
                                }
                                checked={
                                  props?.luggageIdData
                                    ? props?.luggageIdData?.warranty
                                    : data.warranty
                                }
                              />
                              <label
                                className="form-check-label"
                                htmlFor="warranty"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.estimate_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      className="numberType"
                      type="number"
                      onWheel={numberInputOnWheelPreventChange}
                      min={1}
                      placeholder={t("form.estimate_of_luggage")}
                      id="luggage_estimation"
                      onChange={handleChange}
                      name="luggage_estimation"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData.luggage_estimation
                          : data?.luggage_estimation
                      }
                    />
                    <span
                      style={{
                        position: "absolute",
                        right: 15,
                        bottom: error.luggage_estimation ? "37px" : "15px",
                        fontWeight: "500",
                        opacity: 0.6,
                      }}
                    >
                      {contextObj?.loginUser?.symbol}
                    </span>
                    <span style={{ color: "red" }}>
                      {error.luggage_estimation}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="row col-lg-12">
                  <label htmlFor="name">{t("form.receiver_details")}:</label>
                  <div className="col-lg-6">
                    <div className="custom-input-group">
                      <label htmlFor="name">
                        {t("form.Name_of_receiver")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        type="text"
                        placeholder={t("form.enter_pick_up_place")}
                        id="receiver_name"
                        onChange={handleChange}
                        name="receiver_name"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.receiver_name
                            : data?.receiver_name
                        }
                      />
                      <span style={{ color: "red" }}>
                        {error.receiver_name}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-2">
                    <div
                      className="custom-input-group"
                      style={{
                        marginTop: i18n.language === "frFR" ? "7px" : "30px",
                      }}
                    >
                      <label htmlFor="name">
                        {t("form.Phone_code")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        required
                        id="receiver_phone_code"
                        onChange={(e) => {
                          handleChange(e, "receiver_phone_code");
                        }}
                        name="receiver_phone_code"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status || data?.receiver_phone_code
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.receiver_phone_code
                            : data?.receiver_phone_code
                        }
                      >
                        <option value="" selected disabled>
                          {t("form.Phone_code")}
                        </option>
                        {details.arriavalCountry?.map((item) => (
                          <option value={item.phone_code} key={item.id}>
                            {item.phone_code}
                          </option>
                        ))}
                      </select>
                      <span style={{ color: "red" }}>
                        {error.receiver_phone_code}
                      </span>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="custom-input-group ">
                      <label htmlFor="name">
                        {t("form.Phone_Contact_number")}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        required
                        className="numberType"
                        type="Text"
                        id="receiver_contact_number"
                        onChange={handleChange}
                        name="receiver_contact_number"
                        disabled={
                          contextObj?.requestedLuggage?.active_status ||
                          props.luggageIdData?.active_status
                        }
                        value={
                          props.luggageIdData
                            ? props.luggageIdData.receiver_contact_number
                            : data?.receiver_contact_number
                        }
                      />
                      {/* <span>{t("form.Delivery_communicated")}</span> */}
                      <span style={{ color: "red" }}>
                        {error.receiver_contact_number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <div className="">
                      <label>
                        {t("form.getting_your_luggage")}?
                        <span style={{ color: "red" }}>*</span>
                      </label>
                    </div>
                    <div className="package-sidebar">
                      <aside
                        className="package-widget widget-duration "
                        style={{ boxShadow: "none" }}
                      >
                        <div className="widget-body ">
                          <ul className="row">
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", false);
                                }}
                                disabled={
                                  contextObj?.requestedLuggage?.active_status ||
                                  props.luggageIdData?.active_status
                                }
                                checked={
                                  data.self_pickup === 0
                                    ? props?.luggageIdData
                                      ? !props?.luggageIdData?.self_pickup
                                      : false
                                    : !data.self_pickup
                                }
                              // checked={isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.no")}
                              </label>
                            </li>
                            <li className="deration-check col-lg-3 col-md-3">
                              <input
                                className="form-check-input"
                                type="radio"
                                id="self_pickup"
                                name="self_pickup"
                                required
                                onChange={(e) => {
                                  handleSetData("self_pickup", true);
                                }}
                                disabled={
                                  contextObj?.requestedLuggage?.active_status ||
                                  props.luggageIdData?.active_status
                                }
                                checked={
                                  props?.luggageIdData
                                    ? props?.luggageIdData?.self_pickup
                                    : data.self_pickup
                                }
                              // checked={!isShow}
                              />
                              <label
                                className="form-check-label"
                                htmlFor="insurance"
                                style={{ fontWeight: "600" }}
                              >
                                {t("form.yes")}
                              </label>
                            </li>
                          </ul>
                        </div>
                      </aside>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Your_relationship_receiver")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      type="text"
                      placeholder=""
                      id="receiver_relation"
                      onChange={handleChange}
                      name="receiver_relation"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData.receiver_relation
                          : data?.receiver_relation
                      }
                    />
                    <span style={{ color: "red" }}>
                      {error.receiver_relation}
                    </span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Location_of_luggage")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      required
                      type="text"
                      placeholder={t("location")}
                      id="location"
                      onChange={handleChange}
                      name="location"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData.location
                          : data?.location
                      }
                    />
                    <span style={{ color: "red" }}>{error.location}</span>
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.Luggage_Sender_Type")}
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <select
                      id="sender_type"
                      onChange={handleChange}
                      name="sender_type"
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      // value={data.sender_type ? data.sender_type : 0}
                      value={
                        props.luggageIdData
                          ? props.luggageIdData.sender_type
                          : data?.sender_type
                            ? data.sender_type
                            : 0
                      }
                    >
                      <option value={0} selected disabled>
                        {t("form.Luggage_Sender_Type")}
                      </option>
                      <option value={1}>
                        {t("form.RegularI_send_luggages_reguler")}
                      </option>
                      <option value={2}>
                        {t("form.Periodic_send_luggages_periodically")}
                      </option>
                      <option value={3}>
                        {t("form.One_off_luggage_sender")}
                      </option>
                    </select>
                    <spna>
                      <i
                        class="bi bi-caret-down-fill"
                        style={{ marginTop: "21px" }}
                      ></i>
                    </spna>
                    <span style={{ color: "red" }}>{error.sender_type}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="custom-input-group">
                    <label htmlFor="name">
                      {t("form.detail_content_luggage_carried")}
                    </label>
                    <textarea
                      rows={4}
                      id="description"
                      onChange={handleChange}
                      name="description"
                      maxLength={160}
                      disabled={
                        contextObj?.requestedLuggage?.active_status ||
                        props.luggageIdData?.active_status
                      }
                      value={
                        props.luggageIdData
                          ? props.luggageIdData.description
                          : data?.description
                      }
                      placeholder="description"
                    ></textarea>
                    {/* <span style={{ color: "red" }}>{error.description}</span> */}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mb-lg-3 mt-lg-3">
                  <p>{t("profile.Luggage_images")}</p>
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="col-lg-12 d-flex justify-content-center">
                    Exterior 1
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {props?.luggageIdData || data?.imagesURL[0] ? (
                      <img
                        src={
                          props?.luggageIdData?.image
                            ? props?.luggageIdData?.image[0]
                            : data?.imagesURL[0]
                        }
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file"
                      onChange={(e) => {
                        convertToBase641(e, 0);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[0]}
                    </label>
                  </div>
                  {data?.imagesURL[0] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {" "}
                    Exterior 2
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {props?.luggageIdData || data?.imagesURL[1] ? (
                      <img
                        src={
                          props?.luggageIdData?.image
                            ? props?.luggageIdData?.image[1]
                            : data.imagesURL[1]
                        }
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file2"
                      onChange={(e) => {
                        convertToBase641(e, 1);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[1]}
                    </label>
                  </div>
                  {data?.imagesURL[1] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file2"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {" "}
                    Interior 1
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {props?.luggageIdData || data?.imagesURL[2] ? (
                      <img
                        src={
                          props?.luggageIdData?.image
                            ? props?.luggageIdData?.image[2]
                            : data.imagesURL[2]
                        }
                        for="file"
                        role="button"
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                      ></img>
                    ) : (
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file3"
                      onChange={(e) => {
                        convertToBase641(e, 2);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[2]}
                    </label>
                  </div>
                  {data?.imagesURL[2] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file3"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-lg-3 col-sm-12">
                  <div className="col-lg-12 d-flex justify-content-center">
                    {" "}
                    Interior 2
                  </div>
                  <div
                    className="col-lg-12"
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      height: "145px",
                      alignItems: "center",
                      border: "2px dotted black",
                    }}
                  >
                    {props?.luggageIdData || data?.imagesURL[3] ? (
                      <img
                        src={
                          props?.luggageIdData?.image
                            ? props?.luggageIdData?.image[3]
                            : data.imagesURL[3]
                        }
                        style={{
                          aspectRatio: "4/2",
                          objectFit: "contain",
                          width: "100%",
                        }}
                        for="file"
                        role="button"
                      ></img>
                    ) : (
                      <label
                        for="file4"
                        style={{ color: "#1e98d7", fontSize: "14px" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    )}
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="file4"
                      onChange={(e) => {
                        convertToBase641(e, 3);
                      }}
                    ></input>
                  </div>
                  <div className="col-lg-12">
                    <label
                      style={{
                        color: "red",
                        fontSize: "9px",
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {imgmassage[3]}
                    </label>
                  </div>
                  {data?.imagesURL[3] ? (
                    <div className="col-lg-12 d-flex justify-content-center">
                      <label
                        for="file4"
                        style={{ color: "#1e98d7" }}
                        role="button"
                      >
                        {t("form.click_drag_image_here")}
                      </label>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="package-sidebar">
                <aside
                  className="package-widget widget-tour-categoris"
                  style={{ boxShadow: "none" }}
                >
                  <div className="widget-body">
                    <div className="d-flex row">
                      <div className="col-lg-4">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="cate"
                          onChange={(e) => {
                            contextObj.setSenderSignature(
                              e.target.checked ? true : false
                            );
                          }}
                          checked={contextObj.senderSignature}
                          style={{ marginTop: "3px" }}
                        />{" "}
                        <span>{t("form.digital_signature")}</span>
                      </div>
                      {contextObj?.senderSignature == true ? (
                        <div className="col-lg-8 row">
                          <div
                            className="col-lg-12"
                            style={{
                              height: "100px",
                              width: "100px",
                              backgroundImage: `url(${signatureImage})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                              marginLeft: "50px",
                            }}
                          ></div>
                          <div
                            className="col-lg-12"
                            style={{ position: "absolute" }}
                          >
                            <b>{t("form.signature_valid")} : </b>
                            <span style={{ color: "rgb(30, 152, 215)" }}>
                              <b>{Profiledetails?.full_name}</b>
                            </span>
                          </div>
                        </div>
                      ) : // <div class="digital"> <svg class="animated-check" viewBox="0 0 24 24">
                        //     <path d="M4.1 12.7L9 17.6 20.3 6.3" fill="none" /> </svg>
                        // </div>
                        null}
                    </div>
                  </div>
                </aside>
              </div>
              <div className="package-sidebar">
                <aside
                  className="package-widget widget-tour-categoris"
                  style={{ boxShadow: "none" }}
                >
                  <div className="widget-body">
                    <div className="">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="cate"
                        onChange={(e) => {
                          handleSetData(
                            "terms_and_condition",
                            e.target.checked ? true : false
                          );
                        }}
                        checked={
                          props?.luggageIdData?.terms_and_condition ||
                          data?.terms_and_condition
                        }
                      />{" "}
                      <span style={{ marginTop: "2px", color: '#ff4838' }}>
                        {t("form.I_CONFIRM_THAT")}
                      </span>
                    </div>
                  </div>
                </aside>
              </div>
              <div className="custom-input-group row col-lg-12">
                <div className="submite-btn">
                  <button
                    type="submit"
                    disabled={
                      props?.luggageIdData
                        ? !contextObj?.senderSignature
                        : !data.terms_and_condition ||
                        !contextObj.senderSignature
                    }
                    onClick={() => {
                      handleSubmit();
                      // openpop();
                    }}
                    className="btn btn-secondary"
                  >
                    {t("form.Save_review_request")}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </>
      )}
    </>
  );
}
export default LuggageToTraveller;
