import React, { createContext, useEffect, useState } from "react";
import { auth } from "../../models";

export const AppContext = createContext();

export const ApplicationDataProvider = ({ children }) => {
  const [login, setLogin] = useState(false);
  const [userEmail, setUserEmail] = useState();
  const [loginUser, setloginUser] = useState();
  const [travel, setTravel] = useState();
  const [luggage, setluggage] = useState();
  const [travelDetails, setTravelDetails] = useState();
  const [luggageDetails, setLuggageDetails] = useState();
  const [articleDetails, setArticleDetails] = useState();
  const [tabValue, setTabValue] = useState(0);
  const [requestedLuggage, setRequestedLuggage] = useState();
  const [requestedTraveller, setRequestedTraveller] = useState();
  const [radioValue, setRadioValue] = useState();
  const [loginUserType, setLoginUserType] = useState();
  const [loginUserCountry, setLoginUserCountry] = useState();
  const [inVoiceData, setInVoiceData] = useState();
  const [senderSignature, setSenderSignature] = useState(false);
  const functionSetUserEmail = (email) => setUserEmail(email);
  const vehicleTypeEn = [{ value: 1, name: 'Bike' }, { value: 2, name: 'Boat' },
  { value: 3, name: 'Bus' }, { value: 4, name: 'Car' },
  { value: 5, name: 'Kick scooter' }, { value: 6, name: 'Metro' },
  { value: 8, name: 'Plane' },
  { value: 9, name: 'Scooter' }, { value: 10, name: 'Tgv' },{ value: 12, name: 'Train' },
  { value: 11, name: 'Tramway' },];
  const vehicleTypeFr=[{ value: 8, name: 'Avion' }, { value: 2, name: 'Bateau' },{ value: 3, name: 'Bus' },{ value: 6, name: 'Métro' },{ value: 9, name: 'Scooter' },{ value: 12, name: 'Train' },{ value: 10, name: 'TGV' }, { value: 11, name: 'Tramway' },{ value: 5, name: 'Trottinette' },{ value: 1, name: 'Vélo' },{ value: 4, name: 'Voiture' }];
  useEffect(() => {
    if (auth.getToken()) {
      setLogin(true);
      !loginUser && getAuthUser();
    }
  }, [login]);

  const functionLogin = () => {
    setLogin(true);
  };

  const functionLogout = () => {
    setLogin(false);
    setloginUser();
  };

  const getAuthUser = () =>
    auth
      .getUser()
      .then((data) => {
        setloginUser(data);
      })
      .catch((error) => {
        console.log("Here is error in app context");
      });

  const providerState = {
    login,
    userEmail,
    loginUser,
    travel,
    luggage,
    travelDetails,
    luggageDetails,
    articleDetails,
    tabValue,
    requestedLuggage,
    requestedTraveller,
    radioValue,
    loginUserType,
    loginUserCountry,
    setLogin,
    setRadioValue,
    setTabValue,
    setTravelDetails,
    setRequestedLuggage,
    setRequestedTraveller,
    setLuggageDetails,
    setArticleDetails,
    setLoginUserCountry,
    setluggage,
    setTravel,
    senderSignature,
    setSenderSignature,
    inVoiceData,
    setLoginUserType,
    functionLogin: functionLogin,
    functionLogout: functionLogout,
    setInVoiceData: setInVoiceData,
    functionSetUserEmail: functionSetUserEmail,
    functionsetloginUser: () => getAuthUser(),
    vehicleTypeFr,
    vehicleTypeEn
  };

  return (
    <AppContext.Provider value={providerState}>{children}</AppContext.Provider>
  );
};

export const AppDataConsumer = AppContext.Consumer;
export default AppContext;
