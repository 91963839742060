import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { useEffect } from "react";
import Loading from "../../../../common/Loading";
import { Button, Modal, Form } from "react-bootstrap";
import Chat from "../../../../common/Chat";
import AppContext from "../../../../../store/context/app-context";

export default function InprogressTravelLugggage(props) {


  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const contextObj = useContext(AppContext);

  const filterTravelDetails = () => {
    console.log(
      "filtered data: ",
      (props.articleISold == 1
        ? props.received == 1
          ? props?.iReceived[0]
          : props?.iReceived[1]
        : props.received == 1
          ? props?.iSent[0]
          : props?.iSent[1]
      )?.filter(
        (item) =>
          item?.isComplete == false &&
          item?.status == 1 &&
          item?.payment_status == true
      )
    );
    return (
      props.articleISold == 1
        ? props.received == 1
          ? props?.iReceived[0]
          : props?.iReceived[1]
        : props.received == 1
          ? props?.iSent[0]
          : props?.iSent[1]
    )?.filter(
      (item) =>
        item?.isComplete == false &&
        item?.status == 1 &&
        item?.payment_status == true
    );
  };

  const handleClose = () => {
    setShow(false);
  };

  const handleShow = (chatValue) => {
    setShow(chatValue);
  };

  return (
    <>
      <div class="row d-flex justify-content-center align-items-center h-100 pt-1">
        <div class=" col mb-4 mb-lg-0">
          {filterTravelDetails()?.map((item) => (
            <div
              class="package-card-delta card mb-3"
              style={{ borderRadius: "8px" }}
            >
              {/* <div class="ribbon red"><span>{t("profile.not_completed")}</span></div> */}
              <div class="row g-0">
                <h4 className="p-2" style={{ backgroundColor: "" }}>
                  {props.received == 1
                    ? props.articleISold == 1
                      ? item?.title
                      : item?.luggage_title
                    : props.articleISold == 2
                      ? item?.title
                      : item?.luggage_title}
                </h4>
                <hr class="mt-0 mb-4" />
                <div class="col-md-7">
                  <div class="card-body pt-0 pb-0">
                    <div class="row">
                      <div class="col-5 mb-3">
                        <h6>
                          <img
                            src={item?.departure_flag}
                            style={{
                              width: "25px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          {item?.city_departure_name}
                        </h6>
                      </div>
                      <div class="col-2 mb-3">
                        <h6>
                          <i class="bi bi-arrow-right"></i>
                        </h6>
                      </div>
                      <div class="col-5 mb-3">
                        <h6>
                          <img
                            src={item?.arrival_flag}
                            style={{
                              width: "25px",
                              height: "20px",
                              marginRight: "5px",
                            }}
                          />{" "}
                          {item?.city_arrival_name}
                        </h6>
                      </div>
                    </div>
                    <div class="row pt-1">
                      <div class="col-6 mb-3">
                        <h6>
                          <i class="bi bi-calendar-event" />{" "}
                          {t("profile.departure")}
                        </h6>
                        <p class="text-muted">
                          {item?.departure_date} | {item?.departure_time}
                        </p>
                      </div>
                      <div class="col-6 mb-3">
                        <h6>
                          <i class="bi bi-calendar-event" />{" "}
                          {t("profile.arrival")}
                        </h6>
                        <p class="text-muted">
                          {" "}
                          {item?.arrival_date} | {item?.arrival_time}
                        </p>
                      </div>
                    </div>
                    <div class="row pt-1">
                      <div class="col-12 mb-3">
                        <h6>
                          <i class="bi bi-cart-fill"></i> {t("profile.weight")}:{" "}
                          {props.received == 1
                            ? props.articleISold == 1
                              ? item?.weight
                              : item?.luggage_weight
                            : props.articleISold == 2
                              ? item?.weight
                              : item?.luggage_weight}{" "}
                          Kg
                        </h6>
                        {props.received == 1 ? (
                          <>
                            <h6 className="pt-2">
                              <i class="bi bi-arrow-down-left-circle-fill"></i>{" "}
                              {t("profile.luggage_sender")}:{" "}
                              <span style={{ color: "#1e98d7" }}>
                                {props.articleISold == 1
                                  ? item?.request_user_name
                                  : item?.luggager_name}
                              </span>
                            </h6>
                            <h6 className="pt-2">
                              <i class="bi bi-telephone-outbound"></i>{" "}
                              {t("profile.Phone_Number")}{' '}:{" "}
                              <span style={{ color: "#1e98d7" }}>
                                {/* {props.received == 1
                                  ? props.articleISold == 1
                                    ? item?.title
                                    : item?.luggage_title
                                  : props.articleISold == 2
                                    ? item?.title
                                    : item?.luggage_title} */}
                                {props.received == 1 && props.articleISold == 1 && item.payment_status == true && item?.luggager_sender_contact_number}
                                {props.received == 1 && props.articleISold == 2 && item.payment_status == true && item?.luggager_sender_contact_number}

                              </span>
                            </h6>
                          </>
                        ) : (
                          <>
                            <h6 className="pt-2">
                              <i class="bi bi-truck"></i> {t("profile.traveller")}
                              :{" "}
                              <span style={{ color: "#1e98d7" }}>
                                {props.articleISold == 1
                                  ? item?.traveler_name
                                  : item?.travel_user_name}
                              </span>
                            </h6>
                            <h6 className="pt-2">
                              <i class="bi bi-telephone-outbound"></i>{' '}{t("profile.Phone_Number")}{' '}
                              :{" "}
                              <span style={{ color: "#1e98d7" }}>
                                {props.received == 2 && props.articleISold == 1 && item.payment_status == true && item?.contact_number}
                                {props.received == 2 && props.articleISold == 2 && item.payment_status == true && item?.traveller_contact_number}
                              </span>
                            </h6>
                          </>
                        )}
                      </div>
                    </div>
                    <div style={{ color: "#ff4838" }} className="my-2 my-md-0">
                      <br className="d-none d-md-block" />
                      <br className="d-none d-md-block" />
                      {t("profile.signature_code")}
                    </div>
                  </div>
                </div>
                <div class="col-md-5 col-lg-5 col-12">
                  <div class="card-body pt-0 pb-0">
                    <div class="earning">
                      <div class="plan">
                        <header>
                          <i class="bi bi-currency-dollar" />
                          <h6 class="plan-title">
                            {props.received == 1
                              ? t("profile.earning_oppurtunity")
                              : t("profile.trip_Cost_paid")}
                          </h6>
                          <div class="plan-cost">
                            <span class="plan-price">
                              {props.articleISold == 1
                                ? item?.fees
                                : props.received == 1
                                  ? item?.earning
                                  : item?.total_fees}{" "}
                              {item.currency}
                            </span>
                          </div>
                        </header>
                      </div>
                      <div class="plan-select pt-2">
                        {props.received == 1 && props.articleISold == 1 && (
                          <a
                            href={`luggageCarrying/${item?.id}/${props.received == 1
                              ? props.articleISold == 1
                                ? item?.request_type
                                : item?.product_type
                              : props.articleISold == 1
                                ? item?.product_type
                                : item?.request_type
                              }/${props.articleISold}/2`}
                            style={{
                              cursor: "pointer",
                              padding: "12px 14px",
                              fontWeight: "700",
                            }}
                          >
                            {t("profile.view_order_details")}
                          </a>
                        )}
                        {props.received == 1 && props.articleISold == 2 && (
                          <a
                            href={`luggageCarrying/${item?.id}/${item?.product_id
                              }/${props.received == 1
                                ? props.articleISold == 1
                                  ? item?.request_type
                                  : item?.product_type
                                : props.articleISold == 1
                                  ? item?.product_type
                                  : item?.request_type
                              }/${props.articleISold}/2`}
                            style={{
                              cursor: "pointer",
                              padding: "12px 14px",
                              fontWeight: "700",
                            }}
                          >
                            {t("profile.view_order_details")}
                          </a>
                        )}
                        {/* {(props.received == 1 && props.articleISold == 2) && <a href={`luggageCarrying/${item?.id}/${item?.product_id}/${props.articleISold == 1 ? item?.product_type : item?.request_type}/${props.articleISold}/3`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>} */}
                        {props.received == 2 && props.articleISold == 2 && (
                          <a
                            href={`luggageCarrying/${item?.id}/${props.articleISold == 1
                              ? item?.product_type
                              : item?.request_type
                              }/${props.articleISold}/3`}
                            style={{
                              cursor: "pointer",
                              padding: "12px 14px",
                              fontWeight: "700",
                            }}
                          >
                            {t("profile.view_order_details")}
                          </a>
                        )}
                        {props.received == 2 && props.articleISold == 1 && (
                          <a
                            href={`luggageCarrying/${item?.id}/${item?.product_id
                              }/${props.articleISold == 1
                                ? item?.product_type
                                : item?.request_type
                              }/${props.articleISold}/3`}
                            style={{
                              cursor: "pointer",
                              padding: "12px 14px",
                              fontWeight: "700",
                            }}
                          >
                            {t("profile.view_order_details")}
                          </a>
                        )}
                      </div>
                      <div className="col-12 d-flex justify-content-center ">
                        <p
                          style={{ color: "blue", cursor: "pointer" }}
                          onClick={() => { handleShow(item) }}
                        >
                          {t("profile.send_the_message")}
                        </p>
                      </div>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        size="small"
                        centered
                      >
                        <Modal.Header closeButton>
                          <Modal.Title>{t("profile.Chat_box")}</Modal.Title>
                        </Modal.Header>
                        <Modal.Body style={{ padding: "0px", margin: "0px" }}>
                          <Chat
                            senderID={
                              item.requestedby == contextObj.loginUser.id
                                ? item.requestedby
                                : item.requestedto
                            }
                            receiverID={
                              item.requestedto == contextObj.loginUser.id
                                ? item.requestedby
                                : item.requestedto
                            }
                            requestedby={item.requestedby}
                            requestedto={item.requestedto}
                            product_type={item.product_type || item.request_type}
                            product_id={show.product_id}
                          />
                        </Modal.Body>
                      </Modal>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
