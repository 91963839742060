import axios from "../plugin/axios";

const chat = {
  getChatList(product_id, product_type) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .get(`Chat/Get/${product_id}/${product_type}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  sendMessage(messageData) {
    console.log("messageData in model: ", messageData);
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`Chat/SendMessage`, messageData)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  saveConnectionId(connectionId) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .put(`Chat/SaveConnectionId/${connectionId}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default chat;
