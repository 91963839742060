import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import FaqWrapper from "./FaqWrapper";
import FaqPoster from "../../common/FaqPoster";

function FaqPage() {
  return (
    <>
      <FaqPoster name="FAQ" />
      <FaqWrapper />
    </>
  );
}

export default FaqPage;
