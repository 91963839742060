import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { languages } from '../lang';
import SimpleReactValidator from 'simple-react-validator';
import 'simple-react-validator/dist/locale/fr';

const useValidator = (customMessage = {}, customValidator = {}) => {
    const [show, setShow] = useState(false);
    const { t, i18n } = useTranslation();
    const validators = {
        password: {
            message: t('error.validators_password'),
            rule: (val, params, validator) => {
                return validator.helpers.testRegex(
                    val, '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})'
                ) && params.indexOf(val) === -1;
            }
        },
    };
    const validator = new SimpleReactValidator({
        locale: languages[i18n.language].code,
        element: (message) => message,
        messages: customMessage,
        validators: { ...validators, ...customValidator },
    });

    if (show) {
        validator.showMessages();
    }

    return [validator, setShow];
};

export default useValidator;
