import React from 'react'
import { useTranslation } from 'react-i18next'

export default function PrivacyPolicyWrap() {
    const { t } = useTranslation()
    return (
        <>
            <div className='container pt-80  ' style={{ height: '100vh', overflowY: 'scroll', }}>
                <div className='row'>
                    <div className='col-12 text-center '>
                        <h4 className='fs-1'>{t('privacyPolicy.title')}</h4>
                        <h5 className='fs-4'>www.cobagage.com</h5>
                        <p className='fs-5' style={{ fontStyle: 'italic' }}>{t('privacyPolicy.applicable_from')}</p>
                    </div>
                    <div className='col-12 mt-5'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>{t('privacyPolicy.preamble')} </h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>{t('privacyPolicy.this_confedentially_policy_informs')}</p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>{t('privacyPolicy.article1_particle')}</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.this_confidential_policy_applicable_between')}{' '}<strong>{t('privacyPolicy.cobagage')}</strong>
                            {' '}{t('privacyPolicy.any_person_connecting')}{' '} <strong>{t('privacyPolicy.the_user')}</strong>
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>ARTICLE 2.	DEFINITIONS</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            <strong>  {t('privacyPolicy.site_content')}</strong>{' '}
                            {t('privacyPolicy.element_of_any_kind')}
                        </p>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            <strong>{t('privacyPolicy.user')}</strong>
                            {' '}{t('privacyPolicy.any_person_to_site')}
                        </p>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            <strong>{t('privacyPolicy.site')}</strong>
                            {' '}{t('privacyPolicy.website_accessible')}
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>{t('privacyPolicy.article3')}</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.browsing_the_site_will_constitute')}
                        </p>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.the_user_recognizes')}
                        </p>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.acceptance_of_this_confedential_policy')}
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>{t('privacyPolicy.article4')}</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.in_accordance_with_general_data')}
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4>4.1 {' '}{t('privacyPolicy.identy_of_data_controller')}</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.the_person_responsible_for_connection_of')}
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4>4.2 {' '}{t('privacyPolicy.identy_of_data_protection_officer')}</h4>
                        <p className='fs-5' style={{ lineHeight: '30px' }}>
                            {t('privacyPolicy.the_data_protection_delegates')}
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4>4.3 {' '}{t('privacyPolicy.data_collection_by_cobagage')}</h4>
                        <p className='mt-3 fs-5'>4.3.1 {'  '}<span style={{ textDecorationLine: 'underline', }}>{t('privacyPolicy.data_collected')}</span></p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic', }}>4.3.1.1 {'  '}{t('privacyPolicy.data_collection_during_navigation')}</p>
                        <p className='fs-5'>{t('privacyPolicy.user_consent_to')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.1.2 {'  '}{t('privacyPolicy.data_collected_while_creating_user_account')}</p>
                        <p className='fs-5'>{t('privacyPolicy.as_part_of_user_registration')}</p>
                        <p className='fs-5'>{t('privacyPolicy.user_who_do_not_wish_to_provide')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.1.3 {'  '}{t('privacyPolicy.data_collected_using_contact_form')}</p>
                        <p className='fs-5'>{t('privacyPolicy.the_use_of_contact_form')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.1.4 {'  '}{t('privacyPolicy.data_collected_when_using_newsletter_form')}</p>
                        <p className='fs-5'>{t('privacyPolicy.as_part_of_use_of_newsletter')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.2 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.purpose_of_collecting_personal_data')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.data_collected_via_cookies')}</p>
                        <ul className='ps-4 list-unstyled'>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.verify_identy_of_users')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.ensure_and_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.develops_oprates_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.send_information_and_contact_the_user')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.share_content_from_site')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.improve_user_experience')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.target')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.avoid_any_illicit_or_illegal')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.enforce_the_condition')}</div></li>
                        </ul>
                        <p className='mt-3 fs-5'>
                            {t('privacyPolicy.automate_processing_with_aim_of')}
                        </p>
                        <ul className='ps-4 list-unstyled'>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.verify_identity_of_people')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.communicate_with_people')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.proceed_with_user_registration')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.ensure_and_improve_service')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.develops_oprates_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.send_information_and_contact_the_user')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.share_the_content_from_site_with_other_people')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.improve_user_experience')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.target')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.avoid_any_illicit_or_illegal')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.enforce_the_condition')}</div></li>
                        </ul>
                        <p className='mt-3 fs-5'>
                            {t('privacyPolicy.contact_form_proceeded_for_the_purpose_of')}
                        </p>
                        <ul className='ps-4 list-unstyled'>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.verify_identy_of_users')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.ensure_and_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.develops_oprates_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.share_content_from_site')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.enforce_the_condition')}</div></li>
                        </ul>
                        <p className='mt-3 fs-5'>
                            {t('privacyPolicy.data_collected_when_subscribing')}
                        </p>
                        <ul className='ps-4 list-unstyled'>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.communicate_about_new_features')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.communicate_about_cobagae_product')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.develops_oprates_improve')}</div></li>
                            <li className='d-flex gap-2 '><div>-</div> <div>{t('privacyPolicy.enforce_the_condition')}</div></li>
                        </ul>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.3 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.legal_bases_for_processing')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.data_collected_during_navigation_via_cookies')} <br />
                            {t('privacyPolicy.data_collected_during_creation_of_user_account')} <br />
                            {t('privacyPolicy.data_collected_legal_basis')}
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.4 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.data_recipent')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.data_collected_can_only_be_viewed')}  </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.5 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.duration_of_retention_of_personal_data')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.personal_data_collected_during_navigation_kept_reasonable_period')} <br />
                            {t('privacyPolicy.data_collected_during_user_creation_kept_duration')} <br />
                            {t('privacyPolicy.data_collected_from_newssletter_kept_until')}
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.6 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.security_and_confidentiality_of_personal_data')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.personal_data_is_stored_in_secure_condition')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.7 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.minimization_of_personal_data')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.cobagage_may_also_collect_and_process')} <br />
                            {t('privacyPolicy.cobagage_guides_users_as_much_as_possible')} <br />
                            {t('privacyPolicy.cobagage_may_also_collect_and_process')}
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.8 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.respect_for_rights')}</span></p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_the_following_right')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.1 {'  '}{t('privacyPolicy.right_to_information')}</p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_the_possiblity_of_accessing')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.2 {'  '}{t('privacyPolicy.right_torectification')}</p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_the_possiblity_of_requesting')} <br />
                            {t('privacyPolicy.to_help_you_in_your_process')}
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.3 {'  '}{t('privacyPolicy.right_to_object_data_processing')}</p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_the_possiblity_to_object_the_processing_of')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.4 {'  '}{t('privacyPolicy.right_to_data_portablity')}</p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_the_right_to_receive_personal_data')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.5 {'  '}{t('privacyPolicy.right_to_restriction_of')}</p>
                        <p className='fs-5'>{t('privacyPolicy.you_have_right_that_requesting_processing_of_you')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.6 {'  '}{t('privacyPolicy.reply_duration')}</p>
                        <p className='fs-5'>{t('privacyPolicy.cobagage_undertakes_to_respond')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.8.7 {'  '}{t('privacyPolicy.complaint_to_the_competent_authoruty')}</p>
                        <p className='fs-5'>{t('privacyPolicy.if_you_consider_that_cobagage_is_not_responding')}</p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className='mt-3 fs-4'>4.3.9 {'  '}<span style={{ textDecorationLine: 'underline' }}>{t('privacyPolicy.transfer_of_collected_data')}</span></p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.9.1 {'  '}{t('privacyPolicy.transfer_to_partners')}</p>
                        <p className='fs-5'>
                            {t('privacyPolicy.cobagage_informs_that_we_use_authorized')} <br />
                            {t('privacyPolicy.cobagage_has_previously_ensured_the_implementation')} <br />
                            {t('privacyPolicy.our_site_uses_social_media_plugin')} <br />
                            {t('privacyPolicy.user_consent_to_data_collected')}
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <table className='table table-bordered text-center '>
                            <thead >
                                <tr>
                                    <th className='text-center' colSpan={5}>{t('privacyPolicy.partner_directory')}</th>
                                </tr>
                                <tr>
                                    <th>{t('privacyPolicy.partner')}</th>
                                    <th>{t('privacyPolicy.quality')}</th>
                                    <th>{t('privacyPolicy.recipent_country')}</th>
                                    <th>{t('privacyPolicy.treatment_carried_out')}</th>
                                    <th>{t('privacyPolicy.garanties')}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>AWS</td>
                                    <td>{t('privacyPolicy.sub_contractor')}</td>
                                    <td>France</td>
                                    <td>{t('privacyPolicy.web_hosting')}</td>
                                    <td><a href="https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#">https://eu-west-3.console.aws.amazon.com/console/home?nc2=h_ct&region=eu-west-3&src=header-signin#</a></td>
                                </tr>
                                <tr>
                                    <td>Google Suite</td>
                                    <td>{t('privacyPolicy.sub_contractor_and_co_contractor')}</td>
                                    <td>USA (Privacy
                                        Shield)
                                    </td>
                                    <td>{t('privacyPolicy.management_of_people')}</td>
                                    <td><a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></td>
                                </tr>
                                <tr>
                                    <td>Google Analytics</td>
                                    <td>{t('privacyPolicy.sub_contractor_and_co_contractor')}</td>
                                    <td>USA (Privacy
                                        Shield)
                                    </td>
                                    <td>{t('privacyPolicy.development_of_commercial')}</td>
                                    <td><a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></td>
                                </tr>
                                <tr>
                                    <td>Universal Analytics</td>
                                    <td>{t('privacyPolicy.sub_contractor_and_co_contractor')}</td>
                                    <td>USA (Privacy
                                        Shield)
                                    </td>
                                    <td>{t('privacyPolicy.development_of_commercial')}</td>
                                    <td><a href="https://policies.google.com/privacy">https://policies.google.com/privacy</a></td>
                                </tr>
                                <tr>
                                    <td>Youtube</td>
                                    <td>{t('privacyPolicy.co_responsable')}</td>
                                    <td>USA (Privacy
                                        Shield)
                                    </td>
                                    <td>{t('privacyPolicy.video_hosting')}</td>
                                    <td><a href="https://policies.google.com/privacy?hl=fr&gl=fr">https://policies.google.com/privacy?hl=fr&gl=fr</a></td>
                                </tr>
                                <tr>
                                    <td>Spidrontech</td>
                                    <td>{t('privacyPolicy.co_responsable')}</td>
                                    <td>USA (Privacy
                                        Shield)
                                    </td>
                                    <td>{t('privacyPolicy.digital_marketing_and_web_development')}</td>
                                    <td><a href="https://policies.google.com/privacy?hl=fr&gl=fr">https://policies.google.com/privacy?hl=fr&gl=fr</a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.9.2 {'  '}{t('privacyPolicy.transfer_upon_requsition_or_juditial_decesion')}</p>
                        <p className='fs-5'>
                            {t('privacyPolicy.the_user_is_also_consent_to')} 
                        </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <p className=' fs-5' style={{ fontStyle: 'italic' }}>4.3.9.3 {'  '}{t('privacyPolicy.transfer_upon_requsition_or_juditial_decesion')}</p>
                        <p className='fs-5'>
                            {t('privacyPolicy.transfer_in_context_of_merger')} 
                        </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>5 {' '}{t('privacyPolicy.intellectual_property')}</h4>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>5.1 {'  '} {t('privacyPolicy.legal_protection_of_site')}</h5>
                        <p className='fs-5'>{t('privacyPolicy.the_content_of_site_may_be_protected_by')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>5.2 {'  '} {t('privacyPolicy.contractual_protection_of_software_content')}</h5>
                        <p className='fs-5'>{t('privacyPolicy.the_user_undertakes_contactually_with_cobagage')} </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>6 {' '}{t('privacyPolicy.final_stipulation')}</h4>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>6.1 {'  '} Modifications </h5>
                        <p className='fs-5'>{t('privacyPolicy.this_privacy_policy_modified_at_any_time')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>6.2 {'  '}  {t('privacyPolicy.entirely')} </h5>
                        <p className='fs-5'>{t('privacyPolicy.the_nulity_of_one_of_the_clauses')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>6.3 {'  '}  {t('privacyPolicy.non_waiver')} </h5>
                        <p className='fs-5'>{t('privacyPolicy.the_absence_of_exercise_by')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>6.4 {'  '}  {t('privacyPolicy.LANGUAGES')} </h5>
                        <p className='fs-5'>{t('privacyPolicy.this_privacy_policy_available_in_french')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>6.5 {'  '}  {t('privacyPolicy.unfair_clauses')} </h5>
                        <p className='fs-5'>{t('privacyPolicy.the_stipulity_of_confedentiality')} </p>
                    </div>
                    <div className='col-12 mt-3'>
                        <h4 style={{ textDecorationLine: 'underline', color: '#f0592b' }}>7 {' '}{t('privacyPolicy.disputes')}</h4>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>7.1 {'  '} {t('privacyPolicy.applicable_right')}  </h5>
                        <p className='fs-5'>{t('privacyPolicy.privacy_policy_subjected_to')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>7.2 {'  '} {t('privacyPolicy.Litiges')}  </h5>
                        <p className='fs-5'>{t('privacyPolicy.pursuant_to_order_no')} </p>
                    </div>
                    <div className='col-12 mt-2'>
                        <h5 className='mt-3 fs-4'>7.3 {'  '} {t('privacyPolicy.arbitration')}  </h5>
                        <p className='fs-5'>{t('privacyPolicy.any_dispute_related_to_this')} </p>
                        <br /> <br /> <br /> <br />
                    </div>
                </div>
            </div>
        </>
    );
}