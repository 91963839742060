import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import { Rating } from 'react-simple-star-rating';
import { useMediaQuery } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import RecentArticleCard from '../profile/swipersData/RecentArticleCard';
import articles from '../../../models/articles';
import AppContext from '../../../store/context/app-context';

export default function UpcomingTour() {
  const history = useHistory();
  const [articalSale, setArticalSale] = useState([]);
  const contextObj = useContext(AppContext);
  const [userId, setUserId] = useState(
    contextObj?.login ? contextObj?.loginUser?.id : "0"
  );
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();
  const upcommingTour = {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 10,
    loop: true,
    roundLengths: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.testi-next6',
      prevEl: '.testi-prev6',
    },
    breakpoints: {
      480: {
        slidesPerView: 1
      },
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 4
      },
    }
  }
  const upcommingTour2 = {
    slidesPerView: 1,
    speed: 1500,
    spaceBetween: 10,
    // loop: true,
    roundLengths: true,
    autoplay: {
      delay: 5000,
    },
    navigation: {
      nextEl: '.testi-next6',
      prevEl: '.testi-prev6',
    },
    breakpoints: {
      480: {
        slidesPerView: 1
      },
      576: {
        slidesPerView: 2
      },
      992: {
        slidesPerView: 2
      },
      1200: {
        slidesPerView: 4
      },

    }
  }

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    articles
      .list(userId)
      .then((item) => {
        setArticalSale(item?.filter((item) => item?.isComplete == false));
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const viewDetails = (type) => {
    history.push(`/cobagage_articles/${type}`);
  }

  const addTraveller = (radioData) => {
    scrollTop();
    contextObj.setTabValue(0)
    contextObj.setRadioValue(radioData);
    history.push({
      pathname: "/myProfile",
      // state: {
      //   data: radioData,
      //   tab: 0,
      // }
    });
  }

  const setTologin = ()=>{
    scrollTop();
    history.push("/login")
  }

  return (
    <div>
      <div class="package-area package-style-two chain" style={{ background: '#f4f4f4', paddingBottom: '20px' }}>
        <div className="container">
          <div className="testimonial-area testimonial-style-three  p-0" style={{ backgroundImage: 'none' }}>
            <div className="container ps-1 pe-2">
              <div className="row d-flex justify-content-center align-items-center">
                <div className="col-lg-6 col-sm-12 col-sm-6 col-sm-10">
                  <div className="section-head-alpha text-lg-start text-center pb-0">
                    {/* <h2 class='fs-5'>Recent travel announcements</h2> */}
                    <p class='fs-4 fw-bold'>
                      {t("homePage.Recent_articles_listed")}
                      {/* {t("homePage.Recent_announcements")} */}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-sm-10 d-flex justify-content-end align-items-center mt-lg-2">
                  <div className="d-flex d-lg-block d-none" style={{ marginRight: "20px" }}>
                    <button
                      className="nav-link active"
                      type="button"
                      onClick={() => { contextObj.login ? addTraveller(3) : setTologin(); }}
                      style={{ borderRadius: '5px', color: '#fff', border: '1px solid gray', backgroundColor: 'gray', padding: '8px 25px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                    >
                      {t("homePage.I_sell_Article")}
                    </button>
                  </div>
                  {/* <div className="slider-arrows text-center d-lg-flex flex-row d-none gap-3">
                    <div
                      className="testi-prev6"
                      tabIndex={0}
                      role="button"
                      aria-label="Previous slide"
                    >
                      {" "}
                      <i className="bi bi-arrow-left" />
                    </div>
                    <div
                      className="testi-next6"
                      tabIndex={0}
                      role="button"
                      aria-label="Next slide"
                    >
                      <i className="bi bi-arrow-right" />
                    </div>
                  </div> */}
                </div>
              </div>
              <div class='row p-3'>
                <div class=' col-12 text-end'>
                  <button class='fs-6' onClick={() => (viewDetails(1), scrollTop())} style={{ color: '#ff4838', border: '0px', backgroundColor: '#fff', background: '#f4f4f4' }}>{t('homePage.View_articles_sale')} {' '}<i class="bi bi-arrow-right-circle" /></button>
                </div>
              </div>
              <div className="row align-items-center justify-content-center">
                <div className="col-lg-12 p-0">
                  <Swiper
                    className="swiper testimonial-slider-three"
                    slidesPerView={1}
                    speed={500}
                    spaceBetween={5}
                    loop={false}
                    roundLengths={true}
                    autoplay={{
                      delay: 5000,
                    }}
                    navigation={{
                      nextEl: ".testi-next4",
                      prevEl: ".testi-prev4",
                    }}
                    breakpoints={{
                      480: {
                        slidesPerView: 1,
                      },
                      768: {
                        slidesPerView: 2,
                      },
                      992: {
                        slidesPerView: 2,
                      },
                      1200: {
                        slidesPerView: 4,
                      },
                    }}
                  >
                    <div className="swiper-wrapper">
                      {articalSale?.map(item => (
                        <SwiperSlide className="swiper-slide h-100" style={{ padding: "10px" }}>
                          <RecentArticleCard className="h-100" item={item} data={'articleSale'} />
                        </SwiperSlide>
                      ))}
                    </div>
                  </Swiper>
                </div>
              </div>
              <div className='mt-2 mb-3 d-flex  justify-content-end d-lg-none d-block'>
                <button
                  className="nav-link active"
                  type="button"
                  onClick={() => { contextObj.login ? addTraveller(3) :setTologin();; }}
                  style={{ borderRadius: '5px', color: '#fff', border: '1px solid gray', backgroundColor: 'gray', padding: '8px 25px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}
                >
                  {t("homePage.I_sell_Article")}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
