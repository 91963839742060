import React, { useContext, useEffect, useState } from "react";
import { Modal, Button, Form } from 'react-bootstrap';
import { useTranslation } from "react-i18next";
import { BsFillCheckCircleFill } from 'react-icons/bs';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useParams } from "react-router-dom";
import paymentSent from "../../../models/paymentSent";
import notistack from "../../../plugin/notistack";
import AppContext from "../../../store/context/app-context";
import Loading from "../../common/Loading";

export default function ShoppingStripDetails() {
    const contextObj = useContext(AppContext);
    const { id } = useParams();
    const { t } = useTranslation();
    const history = useHistory();
    const [load, setLoad] = useState();
    const [data, setData] = useState(
        {
            "basket_id ": id,
            "sessionId": window.localStorage.getItem('sessionId'),
        }
    );

    const paymentHistroy = () => {
        setLoad(true)
        paymentSent.paymentHistory(data).then((resp) => {
            notistack.success(t("cobagage.payment"))
            window.localStorage.removeItem('sessionId')
            contextObj.setTabValue(7);
            history.push('/myProfile');
            setLoad(false)
        }).catch((error) => {
            setLoad(false)
            console.log('shopping payment', error);
        })
    }

    return (
        <>
            {load ? <Loading /> :
                <>
                    <div className='' style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                        <div className='d-flex justify-content-center'>
                            <i class='bi bi-hourglass-split' style={{ fontSize: "85px", color: "orange" }} />
                        </div>
                        <div className='d-flex justify-content-center pt-3'>
                            <h4>{t('form.complete_your_Transaction')}</h4>
                        </div>
                        <div className='d-flex justify-content-center pt-2'>
                            <h4>{t('form.Click_button')}</h4>
                        </div>
                        <div className='d-flex justify-content-center'>
                            <Button style={{ backgroundColor: 'green', borderColor: 'green', borderRadius: '5px', marginTop: "34px" }}
                                onClick={() => paymentHistroy()}
                            >
                                {t('form.Continue_Order')}{' '}<i class="bi bi-arrow-right-circle" />
                            </Button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
