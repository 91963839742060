import axios from '../plugin/axios';

const country = {
  currency() {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        .get('Country/GetCurrency')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  addOtherCity(data) {
    return new Promise((resolve, reject) => {
      axios.setToken();
      axios
        .post(`Country/CreateCity/${data.state_id}/${data.city_name}`)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  country() {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        .get('Country/GetContries')
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  state(id) {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        .get('Country/GetStates', id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
  city(id) {
    return new Promise((resolve, reject) => {
      axios.setLanguage();
      axios
        .get('Country/GetCities', id)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          reject(response);
        });
    });
  },
};
export default country;
