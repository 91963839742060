import React from "react";
import Breadcrumb from "../../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import ViewLuggageWrapperArea from "./ViewLuggageWrapperArea";

function ViewLuggage() {
  const { t } = useTranslation();
  return (
    <>
      <Breadcrumb name={t('form.Cobagage_Announcements')} />
      <ViewLuggageWrapperArea />
    </>
  );
}

export default ViewLuggage;
