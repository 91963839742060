import React from "react";
import MoreOnCobagageWrapperArea from "./MoreOnCobagageWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";

function MoreOnCobagage() {
  return (
    <>
      <Breadcrumb name="Why Cobagage?" />
      <MoreOnCobagageWrapperArea/>
     
    </>
  );
}

export default MoreOnCobagage;
