import React, { useContext } from "react";
import TravelAnnouncementDetails from "./TravelAnnouncementDetails";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import AppContext from "../../../../../store/context/app-context";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function TravelDetails() {
    const { t } = useTranslation();
    const contextObj = useContext(AppContext);
    const { id, type } = useParams();
    return (
        <>
            <Breadcrumb name={type == 0 ?  t('form.Travel_Details') : type == 1 ? t('form.Cobagage_Announcements'): type == 2 ? t('form.Cobagage_Sale'): type == 3 ? t('form.Donation_page'):""} />
            <TravelAnnouncementDetails />
        </>
    );
}

export default TravelDetails;
