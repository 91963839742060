const profile = {
  post_an_advert: "Publier une annonce",
  dashboard: "Tableau de bord",
  my_profile: "Mon profil",
  change_password: "Changer de mot de passe",
  my_subscription_plan: "Mon abonnement",
  my_requests: "MES DEMANDES",
  my_pending_payments: "Mes paiements en attente",
  my_orders: "Mes commandes",
  my_travels: "Mes voyages",
  my_luggages: "Mes bagages",
  my_articles: "Mes articles",
  my_article_donations: "Mes dons d'articles",
  earn_spends_report: "Rapport sur les revenus et les dépenses",
  cobagage_wallet: "Portefeuille Cobagage",
  subscription_balance: "Solde d'abonnement",
  payments_sent: "Paiements envoyés",
  payments_received: "Paiements reçus",
  subscribe_history: "L'historique de l'abonnement",
  delete_account: "Supprimer le compte",
  travels_completed: "Voyages effectués",
  recent_travel_announcements: "Annonces récentes de voyages",
  recent_luggage_sending_requests: "Demandes récentes d'envoi de bagages",
  view_all_luggage_requests: "Afficher toutes les demandes de bagages",
  luggages_sent: "Bagages envoyés",
  recent_luggage_requests: "Demandes récentes de bagages",
  article_on_sale: "Article en vente",
  articles_sold: "Articles vendus",
  articles_listed_for_sale: "Articles à vendre",
  article_for_donation: "Article pour don",
  articles_donated: "Articles donnés",
  articles_listed_for_donations: "Articles listés pour les dons",

  // ----------------My Profile-----------------
  profile_information: "Informations sur le profil",
  id_verification: "Vérification d'identité",
  id_verification_documents: "Documents de vérification d'identité",
  my_cards_and_payment_methods: "Mes cartes et modes de paiement",
  your_subscribe_plan: "Votre plan d'abonnement",
  personal_information: "Informations personnelles",
  full_name: "Nom complet",
  account_type: "Type de compte",
  passport_id: "Numero d'identification du passeport",
  passport_document: "Passport",
  user_name: "Nom d'utilisateur",
  country: "Pays",
  state: "État",
  contact_number: "Numéro de téléphone",
  city: "Ville",
  address: "Adresse",
  zipcode: "Code postal",
  phone_code: "Code téléphonique",
  verify_your_id: "Vérifiez votre identité",
  select_document_type: "Sélectionnez le type de document",
  front_page: "Recto",
  back_page: "Verso",
  submit_and_access_my_profile: "Soumettre et accéder à mon profil",
  please_upload_your_ID_verification_document_you_may_upload_any_of_the_below_listed_documents:
    "Veuillez télécharger votre document de vérification d'identité. Vous pouvez télécharger l'un des documents suivants",
  national_identity_card: "Carte d'identité nationale",
  passport: "Passport",
  residence_card: "Carte de séjour",
  green_card: "Carte verte",
  drive_licence: "Green card",
  residence_permit: "Permis de séjour",
  other_valide_document: "Autres documents valides",
  postal_code: "Code postal",
  geo_location: "Adresse de localisation",
  youTube_video_link_for_showing_your_presentation:
    "Lien vidéo YouTube pour voir votre présentation",
  video_link: "lien vidéo",
  video_of_presentation: "Vidéo de la présentation",
  presentation_video_link: "Lien vidéo de la présentation",
  my_cards: "Mes cartes",
  add_new_card: "Ajouter un mode de paiement",
  select_Paymet_Method:"Mode de paiement",
  card_type: "Type de carte",
  card_holders_name: "Nom du titulaire de la carte",
  card_number: "Numéro de la carte",
  Mobile_number: "Numéro de portable",
  number_added_uccessfully:"Numéro de mobile ajouté avec succès",
  expiry_date: "Date d'expiration",
  expiry_month: "Mois d'expiration",
  expiry_year: "Année d'expiration",
  enter_paypal_id: "Saisir l'identifiant Paypal",
  paypal_id: "Identifiant Paypal",
  add_paypal_id: "Ajouter l'identifiant Paypal",
  Edit_paypal_id: "Modifier l'identifiant Paypal",
  document_type: "Type de documents",
  my_preferred_payment_method_for_receiving_payments:
    "Mon mode de paiement préféré pour recevoir les paiements",
  my_preferred_payment_method_for_spending_money_on_the_site:
    "Mon mode de paiement préféré pour dépenser de l'argent sur le site",
  // ----------------------Change Password--------------------
  old_password: "Ancien mot de passe",
  new_password: "Nouveau mot de passe",
  email: "Email",
  change_password_request: "Demande de changement de mot de passe",
  // -------------My Subscription Plan----------------------
  my_current_plan: "Mon plan actuel",
  applicable_site_service_fees_as_follows:
    "Les frais de service applicables sont les suivants",
  for_sending_luggages: "Pour l'envoi de bagages",
  for_selling_articles: "Pour la vente d'articles",
  for_carrying_luggages_to_earn_money:
    "Transportez des bagages pour gagner de l'argent",
  For_carrying_sold_articles_to_earn_money:
    "Transportez des articles vendus pour gagner de l'argent",
  upgrade_to_our_club_membership_and_enjoy_0_service_fees:
    "Passez à l'adhésion à club et bénéficiez de 0 % de frais de service",
  upgrade_membership: "Mise à niveau de l'adhésion",
  //----------------My Request-------------------
  travels: "Voyages",
  luggages: "Bagages",
  sale: "Ventes",
  sales: 'Ventes',
  donations: "Dons",
  i_received: "J'ai reçu",
  i_sent: "J'ai envoyé",
  requests_I_received_for_carrying_luggages:
    "Demandes que j'ai reçues pour le transport de bagages",
  requests_I_sent_for_carrying_luggages:
    "Demandes que j'ai envoyées pour transporter des bagages ",
  departure: "Départ",
  arrival: "Arrivée",
  weight: "Poids",
  height: "Hauteur",
  length: "Longueur",
  request_received_from: "Demande reçue de",
  requested_sent_to: "Demande envoyée à",
  earning_oppurtunity: "Opportunité de gagner de l'argent",
  view_request_details: "Voir les détails de la demande",
  travelling_by: "Voyager par",
  quantity_requested: "Quantité demandée",
  seller_location: "Localisation du vendeur",
  requests_I_received_from_travellers_for_sending_my_luggages:
    "Demandes que j'ai reçues de voyageurs pour l'envoi de mes bagages",
  requests_I_sent_to_travellers_for_sending_my_luggages:
    "Demandes que j'ai adressées aux voyageurs pour l'envoi de mes bagages",
  requests_I_received_from_buyers_for_my_articles:
    "Demandes que j'ai reçues de la part d'acheteurs pour mes articles",
  requests_I_sent_to_article_sellers_for_buying_their_articles:
    "Demandes d'achat d'articles que j'ai envoyées aux vendeurs d'articles",
  requester_location: "Localisation du demandeur",
  accept: "Accepter",
  reject: "Rejeter",
  accepted: "Accepté",
  rejected: "Rejeté",
  your_are_about_to_accept_the_request_from_buyer_to_sell_your_Item:
    "Vous êtes sur le point d'accepter la demande de l'acheteur pour vendre article.",
  your_are_about_to_reject_the_request_from_buyer_who_wants_to_buy_your_item:
    "Vous êtes sur le point de rejeter la demande de l'acheteur qui souhaite acquérir votre bien.",
  accept_the_request_from_user_to_donate:
    "Vous êtes sur le point d’accepter de donner votre article au demandeur.",
  reject_the_request_from_user_who_wants_to_receive_your_donation:
    "Vous êtes sur le point de rejeter la demande d'un utilisateur qui souhaite recevoir votre don d'article..",
  please_select_a_reason_for_rejection_and_confirm_your_action:
    "Veuillez sélectionner un motif de rejet et confirmer votre action.",
  your_are_about_to_cancel_the_luggage_sending_request:
    "Vous êtes sur le point d'annuler la demande d'envoi de bagages que vous avez reçue du voyageur. Veuillez sélectionner un motif d'annulation de cette commande et confirmer votre action.",
  the_traveller_successfully_notified_cancelation:
    "Le voyageur a été informé avec succès de l'annulation avec motif de votre commande.",
  select_reasone_to_reject: "Sélectionner le motif du rejet",
  I_changed_my_mind: "J'ai changé d'avis",
  price_too_high: "Le prix est trop élevé",
  required_quantity_unavailable: "Quantité requise Indisponible",
  I_did_not_received_the_item_at_all: "Je n'ai pas du tout reçu l'article",
  item_is_not_as_described: "L'article n'est pas conforme à la description",
  item_is_defective: "L'article est défectueux",
  request_you_will_allow_buyer_to_pay_you_the_requested_money:
    "En acceptant cette demande, vous autorisez l'acheteur à vous verser la somme demandée. Vous devrez ensuite expédier l'article au lieu de livraison indiquée dans votre annonce.    ",
  request_you_will_confirm_requester_to_give_away_this_item_as_a_donation:
    "En acceptant cette demande de don d’article, vous confirmez le remettre à l'utilisateur et de faciliter sa récupération à un lieu convenu.",
  requests_I_received_for_my_donation_items:
    "Demandes que j'ai reçues pour mes dons",
  requests_I_sent_for_receiving_donation_items:
    "Demandes que j'ai envoyées pour recevoir des dons",
  i_accept_confirm: "J'accepte et je confirme",
  reject_request: "Rejeter la demande",
  accept_request: "Accepter la demande",
  accept_confimation: "Acceptation de demande ",
  reject_confirmation: "Rejeter la confirmation",
  Pending_acceptance: "En attente d'acceptation par : l'expéditeur du bagage",
  Pending_acceptances: "En attente d'acceptation par : Voyageur",
  donated_to: "Donné à",
  weight_carriage_capacity: "Capacité de transport de poids",
  back_to_travel_requests: "Retour aux demandes de voyage",
  luggage_title: "Titre du bagage",
  type_of_luggage: "Type de bagage",
  luggage_dimensions: "Dimensions des bagages",
  current_price_estimate: "Estimation du prix actuel",
  luggage_receivers_details: "Coordonnées du destinataire des bagages",
  name_of_receiver: "Nom du destinataire",
  phone: "Téléphone",
  your_relationship_with_receiver: "Votre relation avec le récepteur",
  luggage_location: "Localisation des bagages",
  trip_type: "Type de voyage",
  round_way: "Voyage aller-retour",
  price_to_carry_weight: "Prix du poids à transporter",
  Is_a_stopver_planned_in_middle_of_your_trip:
    "Une escale est-elle prévue au cours de votre voyage ?",
  luggage_collection_place: "Lieu de récupération des bagages",
  Luggage_drop_off_place: "Lieu de dépôt des bagages",
  does_your_trip_benefit_from_travel_insurance:
    "Votre voyage bénéficie-t-il d'une assurance voyage ?",
  luggage_images: "Images des bagages",
  Your_luggage_instructions: "Instructions relatives à vos bagages",
  the_Luggage_Must_Not_Be_closed:
    "LES BAGAGES NE DOIVENT PAS ÊTRE FERMÉS DE MANIÈRE À CE QUE LE VOYAGEUR PUISSE CONTRÔLER ENTIÈREMENT LE CONTENU",
  your_other_instructions: "Vos autres instructions",
  your_are_about_to_reject_the_request:
    "Vous êtes sur le point de rejeter la demande de l'expéditeur des bagages et de les livrer à leur destination.",
  your_are_about_to_reject_the_request2:
    "Vous êtes sur le point de refuser la demande d'un voyageur de livrer vos bagages à destination.",
  overWeight: "Poids excessif",
  i_changed_my_travel_plan: "J'ai changé mon plan de voyage",
  improper: "Inadéquat",
  luggage_not_taken_into_account: "Bagages non pris en compte",
  about_your_rejection_with_reason:
    "L'expéditeur du bagage a été informé avec succès de votre rejet avec motif.",
  happy_travelling_while_earning: "Voyager en gagnant de l'argent",
  request_sent_by: "Demande envoyée par",
  request_sent_to: "Demande envoyée à",
  member_since: "Membre depuis",
  luggage_carrying_request: "Demande de transport de bagages",
  cancellation_date: "Date d'annulation",
  cancle_order: "Annuler la commande",
  // ---------------My pending Payments----------
  payments_due_receiving: "Paiements à recevoir des expéditeurs de bagages",
  payment_due: "Paiement dû",
  view_order_details: "Voir les détails de la commande",
  Payments_due_receiving_from_article_buyers:
    "Paiements dus par les acheteurs d'articles",
  payment_Pending_from_buyer: "Paiement en attente de l'acheteur",
  due_amount: "Montant dû",
  luggage_sender: "Expéditeur de bagages",
  // ------------- My Orders---------------------
  in_progress: "En cours",
  completed: "Achevé(s)",
  cancelled: "Annulé(s)",
  donated: "Donnés",
  articles_I_sold: "Articles que j'ai vendus",
  articles_I_bought: "Articles que j'ai achetés",
  articles_I_confirmed_to_donate: "Articles dont j'ai confirmé la donation",
  articles_I_requested_to_have_in_donation:
    "Articles dont j'ai demandé la donation",
  my_In_Progress_luggage_carrying_orders:
    "Mes commandes de transport de bagages en cours",
  Luggage_sending_order_Details_In_progress:
    "Détails de la commande d'envoi de bagages (en cours)",
  my_Pending_luggage_orders: "Mes commandes de bagages en attente",
  Back_to_pending_luggage_sending_orders_list:
    "Retour à la liste des commandes d'envoi de bagages en attente",
  payments_received_from_article_buyers:
    "Paiements reçus des acheteurs d'articles",
  items_I_bought_pending_delivery: "Articles achetés (en attente de livraison)",
  requests_I_confirmed_to_donate_my_items:
    "Demandes que j'ai confirmées pour faire don de mes objets",
  items_I_want_in_donation_pending_delivery:
    "Articles que je souhaite recevoir en donation (en attente de livraison)",
  item_sold_to: "Objet vendu à:",
  item_bought_from: "Article acheté auprès de :",
  item_given_to: "Don à remettre:",
  i_want_in_donation_from: "Don demandé:",
  payment_received_from_buyer: "Paiement reçu de l'acheteur",
  item_delivered: "Objet livré",
  delivered_to: "Livré à",
  delivery_date: "Date de livraison",
  mark_this_item_as_sent: "Confirmez la livraison",
  confirm_receipt_of_item: "Confirmer la réception de l'article",
  confirm_delivery_of_sold_item: "Confirmer la livraison de l'article vendu",
  confirm_receipt_of_item_you_bought:
    "Confirmer la réception de l'article acheté",
  confirm_delivery_of_donated_item: "Confirmer la livraison de l'objet donné'",
  confirm_receipt_of_donated_item: "Confirmer la réception de l'objet donné",
  raise_dispute: "Lancer une procédure de contestation",
  success: "Succès",
  your_are_about_to_confirm_that_the_sold_item:
    "Vous êtes sur le point de confirmer que l'article vendu a bien été livré à l'acheteur.",
  confirm_that_the_item_you_bought:
    "Vous êtes sur le point de confirmer que vous avez bien reçu l'article que vous avez acheté auprès du vendeur.",
  confirm_that_the_donated_item_has_been_successfully_delivered:
    "Vous êtes sur le point de confirmer que l'objet donné a bien été remis à la personne intéressée.",
  confirm_that_the_item_you_wanted_to_have_in_donation:
    "Vous êtes sur le point de confirmer la réception du don d'article que vous souhaitiez recevoir.",
  your_are_about_to_raise_a_dispute_for_the_item:
    "Vous êtes sur le point d'introduire un litige pour l'objet que vous avez acheté au vendeur. Veuillez sélectionner un motif de litige.",
  congratulations: "Toutes nos félicitations!!",
  admin_will_be_notified:
    "L'administrateur en sera informé et le vendeur sera contacté pour rembourser ou résoudre le problème dans les plus brefs délais.",
  buyer_receiving_the_order_along_with_leaving_his_feedback:
    "L’acheteur a été informé de votre livraison et confirmera celle-ci. Veuillez laisser un commentaire pour votre expérience de vente sur le site Cobagage.",
  user_has_been_notified_about_your_delivery:
    "L'utilisateur a été informé de la livraison du don d’article. Veuillez laisser un commentaire sur l’utilisateur.",
  received_by: "Reçu par",
  donated_by: "Donné par",
  received_date: "Date de réception",
  bought_from: "Acheté auprès de",
  sold_to: "Vendu à",
  i_received_in_donation_from: "Reçu un don de:",
  traveller: "Voyageur",
  trip_Cost_paid: "Coût du voyage payé",
  my_Completed_travels_orders: "Mes commandes de voyages terminées",
  my_completed_luggage_orders: "Mes commandes de bagages achevées",
  my_cancelled_travels_orders: "Mes commandes de voyages annulées",
  my_cancelled_luggage_orders: "Mes commandes de bagages annulées",
  confirmation_sent_to_requester: "Confirmation envoyée au demandeur",
  item_pending_delivery: "Article en attente de livraison",
  cancel_order_confirmation: "Annuler la confirmation de la commande",
  cancel: "Annuler",
  update: "Mise à jour",
  Cancel: "Annuler",
  Filter: "Filtre",
  Previous: "Précédent",
  Next: "Suivant",
  Subscription_Buying: "Achat d'abonnement",
  Subscription_Balance: "Solde d'abonnement",
  Transaction_ID: "Identifiant de transaction",
  Transaction_Date_Time: "Date et heure de la transaction",
  Plan_name: "Nom du forfait",
  Amount: "Montant",
  intermedite: "Intermédiaire",
  expert: "Expert",
  Description: "Description",
  Balance_Change: "Mouvement du solde",
  Balance_remaining: "Solde restant",
  Individual_Travellers: "Voyageur individuel",
  Professional_Travellers: "Voyageur professionnel",
  // ---------------my travels-------------------
  from: "De",
  froms: "de",
  to: "Vers",
  published: "Publié(s)",
  departure_date: "Date de départ",
  arrival_date: "Date d'arrivée",
  carriage_capacity: "Capacité du chargement",
  price: "Prix",
  per: "Par",
  request: "Demande",
  edit: "Editer",
  details: "Détails",
  pending_signatures: "En attente de Signatures",
  not_completed: "Non achevé",
  Remaining: "Restant",
  remaining: "restant(s)",
  out_of: "sur",
  view_trip_details: "Voir les détails du voyage",
  VIEW_ARTICLE_DETAILS: "AFFICHER LES DETAILS DE L’ARTICLE",
  VIEW_ARTICLE_DONATION_DETAILS: "AFFICHER LES DETAILS DU DON D’ARTICLE",
  VIEW_LUGGAGE_DETAILS: "AFFICHER LES DETAILS DU BAGAGE",
  // --------------my articles-----------
  quantity: "Quantité",
  material: "Matériel",
  color: "Couleur",
  delete: "Supprimer",
  articles: "Articles",
  article_donation: "Donation d'articles",
  location: "Lieu",
  title: "Titre",
  // -----------my tracking Travels------
  add_tracking: "Ajouter un suivi",
  // ----------Cobagage Wallet----------
  withdrawal_history: "Historique des retraits",
  Enter_Amount_Withdraw: "Entrez le montant à retirer",
  Select_Card: "Numéro de carte",
  Select_Card_Number:"Sélectionnez le numéro de carte",
  Card: "Carte",
  Withdraw: "Retirer",
  all: "Tous",
  on_hold: "En attente",
  complete: "Achevé",
  Date_and_time: "Date et heure",
  icon: "Icône",
  status: "Statut",
  amount: "Montant",
  transfer_to: "Transfert vers",
  time: "L'heure'",
  description: "Description",
  received_from: "Reçu de",
  money_received_for_carrying_luggage:
    "Montant reçu pour le transport des bagages",
  money_received_from_sold_article: "Montant reçu de la vente d'articles",
  Money_paid_for_sending: "Montant versé pour l'envoi",
  received: "Reçu",
  wallet_history: "Historique du portefeuille",
  cobagage_wallet_balance: "Solde du portefeuille Cobagage",
  pending: "En attente",
  pendingbyadmin: "En cours de traitement par l'administrateur",

  your_total_balance: "Votre solde total",
  your_withdrawal_balance: "Votre solde de retrait",
  your_remaining_balance: "Votre solde restant",
  your_escrow_balance: "Votre solde de séquestre",
  payment_in_progress: "Paiement en cours",
  send_payment: "Envoyer le paiement",
  // ------------Payment sent----------

  order_id: "Identification de la commande",
  qty: "Qté",
  payment_type: "Type de paiement",
  payment_status: "Statut des paiements",
  transaction_id: "Identité de la transaction",
  view: "Voir",
  // -----------Subscribe History-----
  type: "Type",
  total_transactions: "Total des transactions",
  remaining_transaction: "Transaction restante",
  payment_method: "Mode de paiement",
  created_date: "Date de création",
  Please_content_carried:
    " Veuillez préciser le contenu du bagage à transporter",
  product_warranty: "Le produit est-il couvert par la garantie ?",
  Luggage_location: "Localisation des bagages",
  ShoppingCard: "Carte d'achat",
  Received_Request: "Demande(s) reçue(s)",
  Sent_Request: "Demande(s) envoyée(s)",
  // new changes
  ShoppingCart: "Panier",
  froms: "de",
  Seller: "Vendeur",
  We_cookies: "Nous utilisons des cookies",
  We_selected_third_parties:
    "Nous et des tiers sélectionnés utilisons des cookies (ou similaires",
  technologies_marketing_efforts:
    "technologies) à des fins techniques, pour améliorer et analyser l'utilisation du site afin de soutenir nos efforts de marketing",
  Accept_All: "Accepter les cookies",
  click_image: "+ cliquez ici télécharger l'image",
  Article_dimensions:
    "Images de l'article (Télécharger l'image dans des dimensions de 1280 px * 720 px)",
  Luggage_images:
    "Images de bagages (Télécharger l'image dans des dimensions de 1280 px * 720 px)",
  Exterior_1: "Extérieur 1",
  Exterior_2: "Extérieur 2",
  Interior_1: "Intérieur 1",
  Interior_2: "Intérieur 2",
  EXPIRE_PIN: "EXPIRER LE NIP",
  Are_delete_account: "Voulez-vous supprimer ce compte ?",
  Close: "Fermer",
  Delete_an_account:
    "Vous souhaitez supprimer toutes vos données et votre compte Cobagage.",
  Delete_account: "Compte supprimé avec succès",
  I_TRAVELLER: "VOUS ETES UN VOYAGEUR",
  TRANSPORT_LUGGAGES_MONEY: "EXPEDIER DES BAGAGES ET GAGNER DE L'ARGENT",
  SELL_ARTICLES: "VENDEZ VOS ARTICLES",
  DONATE_ARTICLES: "FAITES DON DE VOS ARTICLES",
  Post_details: "Publier votre bagage",
  Explore_Cobagage: "Visitez Cobagage ",
  Sell_luggages: "Vendre des kg de bagages",
  Sell_article: "Vendre un article",
  Post_donation: "Faire un don d’article",
  I_SEND_LUGGAGES: "VOUS VOULEZ ENVOYER DES BAGAGES",
  SEND_TRAVELLERS_COST: "ENVOYER DES BAGAGES AVEC DES VOYAGEURS À COÛT RÉDUIT",
  Worth: "Valeur",
  Getting_Cobagage: "Premiers pas sur Cobagage",
  Discover_Cobagage: "Découvrir Cobagage",
  Cobagage_service_connects:
    "Cobagage est un service de mise en relation entre les co-bagageurs, voyageurs et expéditeurs",
  You_weight_bags:
    "Que vous soyez un particulier ou un professionnel, vous pouvez choisir de devenir expéditeur en envoyant vos bagages, ou voyageur en acheminant le bagage confié par l'expéditeur grâce aux kilos disponibles à monétiser dans vos bagages",
  Registration_identification: "Inscription et identification",
  Cobagage_forms:
    "Les co-bagageurs doivent s'inscrire sur la plateforme en remplissant les formulaires d'inscription.",
  This_email:
    "Celle-ci se fait soit par l'intermédiaire d'une conexion via les réseaux sociaux (Facebook, Twitter, Linkedin, Instagram...), soit par email.",
  Once_valid_passport:
    "Une fois l'inscription réalisée, il est possible d'accéder au profil détaillé dans son compte utilisateur afin de fournir des renseignements plus complets : Nom, prénoms, téléphone, adresse postale, professionnel (siret, siren) ou particulier, numéro de pièce d’identité, passeport, titre de séjour...",
  password_username:
    "Ils devront fournir une photocopie de leur carte d’identité, titre de séjour et passeport en cours de validité.Un mot de passe ainsi qu'un pseudo seront choisis lors de l'inscription et demandé pour s'identifier sur Cobagage.",
  Profile_verification: "Vérification des profils",
  Each_registration_Cobagagers:
    "Chaque inscription est vérifiée par notre équipe. Les faux profils pourront être supprimés ou bloqués à tout moment par nos services ou par signalement des Cobagageurs.",
  registration_will_finalized:
    "Aucune inscription ne sera finalisée sans l'apport des documents obligatoires à soumettre.",
  Reviews: "Les avis",
  When_completed:
    "Lorsqu'une transaction s'est achevée, à savoir le voyageur a remis le bagage au destinataire, des avis, notes et commentaires pourront être laissés afin d'évaluer le voyageur et l'expéditeur.",
  Security_Follow: "Les mesures de sécurité à observer",
  As_cobagger_site:
    "En tant que co-bagageur, vous avez la responsabilité du bagage transporté, Cobagage faisant office de site de mise en relation.",
  The_Cobagage_website_allows:
    "Le site Cobagage permet via la messagerie du site un échange clair entre le voyageur et l'expéditeur.",
  Through_ervice_exchanges:
    "Par cet intermédiaire, les cobagageurs pourront péciser le service de Cobagage au-travers de leurs échanges.",
  Thus_clarified_messaging:
    "Ainsi, de nouveaux détails n'ayant pas pu apparaître dans l'annonce pourront être précisés par le bais de la messagerie.",
  The_order_recipient:
    "L'ordre de commande doit obligatoirement être signé par les parties intervenant dans la transaction à savoir le voyageur, l'expéditeur ainsi que le récepteur.",
  It_attests_that:
    "Il atteste que le bagage a bien été contrôlé et qu'il est conforme à l'annonce déposée sur le site.",
  Throughout_cessary:
    "Tout au long de la procédure, du lieu de ramassage à la destination finale, les intervenants se doivent de prendre des photos si nécessaire du bagage transporté",
  Payments: "Paiements",
  Receive_payment: "Recevoir un paiement",
  Payment_conditions:
    "Le paiement est dû à l'expéditeur d'après les conditions suivantes",
  The_order_luggage:
    "L’ordre de commande est signé par toutes les parties (Le voyageur, l’expéditeur, et le destinataire ou récepteur du bagage)",
  The_traveler_recipient:
    "Le voyageur à livré le bagage à son destinataire final",
  Then_just_Cobagage:
    "suffit ensuite de faire une demande de paiement par l'intermédiaire du compte créé sur Cobagage",
  Simply_Payment_conditions:
    "Le paiement est dû au voyageur d'après les conditions suivantes",
  Delivery_has:
    "La livraison a été effectuée auprès du destinataire final (destinataire ou récepteur)",
  Cancellation_pickup: "Annulation de la transaction le jour du ramassage",
  Payment_shipped:
    "Le paiement est dû au voyageur à partir du moment où le bagage a été ramassé et est en cours d'acheminement.",
  Online_payments: "Paiements en ligne",
  When_PayPal_credit_card:
    "Lorsque l'expéditeur a choisi le voyageur pour transporter son bagage par l'intermédiaire des annonces sur la plateforme Cobagage, il effectue le paiement directement via le site, le taux de commission étant élevé à 7% HT.Les paiements peuvent être réalisés par Paypal ou carte bancaire",
  Refunds: "Remboursements",
  The_cases:
    "L'expéditeur peut recevoir un reboursement dans les 4 cas suivants",
  The_traveler_operation: "Le voyageur a lui-même annulé l’opération.",
  The_traveler_luggage: "Le voyageur a perdu le bagage",
  The_travele_updates: "Le voyageur n'a plus donné de nouvelles",
  The_canceled_traveler:
    "L’expéditeur a annulé le service avant le jour de ramassage du bagage auprès du voyageur.",
  Cancellation_meeting: "Annulation et Absence au rendez-vous",
  It_baggage_pickup:
    "Il est possible d'annuler un rendez-vous directement sur le site jusqu'à 24 heures avant le jour de ramassage du bagage.",
  Simply_service_traveler:
    "Il vous suffit de vous rendre dans votre espace client et dans la rubrique demande de retrait solliciter auprès du site Cobagage l'annulation d’une prestation auprès d’un voyageur.",
  In_case_traveler:
    "Dans le cas d'une absence lors de la remise du bagage,  le paiement est dû au voyageur",
  It_happen_reasons:
    "Il peut arriver que le rendez-vous de récupération du bagage soit annulé ou reporté pour diverses raisons.",
  In_delivery_appointment:
    "Dans ce cas, le destinataire et/ou l'expéditeur pourront s'entendre avec le voyageur afin de prévoir un nouveau rendez-vous de livraison du bagage.",
  If_delivery_date:
    "Si l’annulation résulte du fait du voyageur, aucun paiement ne peut être effectué à son profit tant que le bagage n’est pas livré. L’expéditeur peut alors solliciter le site Cobagage pour intervenir auprès du voyageur 24 heures après le jour de la livraison.",
  Responsibilities: "Responsabilités",
  Type_content_transported: "Type et contenu du bagage à transporter",
  The_discretion_user:
    "Le type et la nature du bagage sont laissées à l’appréciation des utilisateurs",
  In_case_doubt_service:
    "En cas de doute, le voyageur peut demander des informations complémentaires sur les bagages via la messagerie du site. Chaque partie peut librement annuler ou refuser le service de Cobagage.",
  Cobagage_contents:
    "Le bon de commande ou l’ordre de Cobagage est le seul et unique document justifiant que toutes les parties intervenantes ont bien pris connaissance du type de bagage à transporter et de son contenu.",
  Responsibilities_Luggage: "Responsabilités du bagage",
  Once_carries:
    "Une fois que le bagage lui a été remis, le voyageur devient seul responsable de la marchandise qu'il transporte.",
  The_latter:
    "La responsabilité de ce dernier prend fin lors de la remise du bagage au destinataire ou au récepteur.",
  Accident_Traveler: "Accident ou décès du voyageur",
  In_accident:
    "En cas d’accident au cours du voyage ou de décès du voyageur, les frais de Cobagage sont remboursés à l'expéditeur.",
  The_baggage_lost:
    "Le bagage étant alors perdu, le prix d'estimation de celui-ci pourra être remboursé à l'expéditeur s'il a souscrit une assurance au préalable.",
  aboutus_text1:
    "“S'unir pour concevoir des voyages et des expéditions de bagages sous un seul service gagnant-gagnant”",
  aboutus_text2:
    "Vous êtes nouveau chez Cobagage et souhaitez en savoir plus sur nous ? Vous êtes au bon endroit !",
  aboutus_text3:
    "Découvrez les coulisses de notre équipe et partagez nos idées et notre objectif, celui de faciliter toujours plus la vie de millions de personnes.",
  aboutus_text4: "Le cobagage comme site de référence",
  aboutus_text5:
    "En choisissant d'utiliser le site Cobagage, vous avez l'assurance de bénéficier d'un service fiable et avantageux.",
  aboutus_text6:
    "Né en 2020, Cobagage a été conçu pour faciliter la vie des personnes voyageant ou ayant des bagages à expédier en conciliant les deux.",
  aboutus_text7: "Le but",
  aboutus_text8:
    "Permettez au voyageur de gagner de l’argent et permettez à l’expéditeur d’économiser sur les frais d’expédition.",
  aboutus_text9:
    "Nos équipes aux quatre coins du monde ont travaillé avec rigueur pour vous offrir le meilleur service sur internet en matière de co-bagage.",
  aboutus_text10: "Pourquoi choisir Cobagage pour réaliser vos expéditions ?",
  aboutus_text11:
    "Tout simplement parce que vous êtes assuré d'un service rapide à des prix très avantageux.",
  aboutus_text12:
    "Vous avez également la possibilité de trouver rapidement un co-bagagiste pour vos envois, que vous soyez voyageur ou expéditeur, Cobagage se chargera de vous mettre en relation selon vos profils.",
  aboutus_text13:
    "Une relation axée sur la simplicité et l'efficacité pour ne plus perdre de temps.",
  aboutus_text14: "Les chiffres clés de Cobagage",
  aboutus_text15:
    "La qualité des services de connexion que nous vous proposons est au cœur de notre motivation.",
  aboutus_text16:
    "C'est dans ce sens que Cobagage a été créé, ces quelques chiffres vous permettant d'en savoir plus sur notre équipe et notre fonctionnement :",
  aboutus_text17:
    "15 pays dans lesquels Cobagage est actif et vous permet de trouver le co-bagage idéal.",
  aboutus_text18:
    "2020 : Création de l'entreprise et du site Cobagage permettant aux cobageurs de s'inscrire et de créer leurs profils ainsi que leurs annonces.",
  aboutus_text19:
    "Trois personnes sont impliquées lors de l'envoi des bagages :",
  Luggage_Senders: "Expéditeur de bagages",
  Recipient: "Destinataire",
  aboutus_text20:
    "Les personnes qui composent notre équipe et s'efforcent de vous aider à offrir la meilleure expérience sur notre site Web.",
  aboutus_text21:
    "Mais le co-bagage représente aussi une grosse économie en termes d'émissions de C02 pour l'association sur un seul trajet du voyageur et du bagage à envoyer au destinataire.",
  aboutus_text22:
    "Un gain de plus en plus important au fil du temps grâce à des co-baggers de plus en plus nombreux à travers le monde.",
  aboutus_text23: "Naissance du site du cobagage",
  aboutus_text24:
    "Au cours de différents voyages, une idée nous est venue qui pourrait révolutionner non seulement la façon de voyager mais aussi d'acheminer des marchandises d'un endroit à un autre.",
  aboutus_text25: "Le cobagage est né",
  aboutus_text26:
    "Voyager est un réel plaisir pour beaucoup, mais cela coûte généralement assez cher, surtout lorsque l'on parcourt de longues distances.",
  aboutus_text27:
    "Pouvoir bénéficier d'une somme d'argent supplémentaire en participant à ce voyage rentable était la solution pour voyager en toute sérénité.",
  aboutus_text28:
    "Utilisez vos kilos de bagages vides pour transporter des affaires d'un expéditeur à un destinataire, une idée garantissant que les deux parties sont gagnantes.",
  aboutus_text29:
    "C'est dans cet esprit que le co-bagage est devenu un nouveau mode de vie, une nouvelle façon d'envisager le voyage d'une part, et l'envoi de colis d'autre part, que l'on soit particulier ou professionnel.",
  aboutus_text30:
    "Grâce à ce site, vous pouvez désormais trouver le co-bagage idéal et commencer à gagner de l'argent en monétisant vos kilos de bagages vides ou en expédiant votre marchandise.",
  sending_luggage_fees1:
    "Des frais de 25% seront ajoutés à titre de frais de service sur le montant total du voyage. [(Prix unitaire : 10€ * 10kg) + 25€ (pour frais de service) = 125€]",
  sending_luggage_fees2:
    "En cas d'annulation par l'une des parties, les frais de service ne seront pas remboursables. Vous ne recevrez que 100% du coût du voyage soit 100€ seulement au lieu de 125€.",
  selling_article_fee1:
    "15% de frais de service seront ajoutés pour l'envoi des articles vendus aux acheteurs [(Poids ou article vendu - 10 kg) * (prix unitaire / kg 10 € )+ 15€ (de frais de service) = 115 €]",
  selling_article_fee2: "",
  carrying_luggage_fee1: "",
  carrying_luggage_fee2: "",
  carrying_sold_article: "",
  carrying_sold_article_fee: "",
  Getting: "Démarrer",
  Started_with: "avec",
  luggage_carrying_order_detailes:
    "Détails de la commande de transport de bagages (Terminé)",
  luggage_carrying_order_detailes_In_Progress:
    "Détails de la commande de transport de bagages (en cours)",
  luggage_carrying_order_detailes_cancel:
    "Détails de la commande de transport de bagages (Annulé)",
  back_to_luggage_carrying_order_list:
    "Retour à la liste des commandes de transport de bagages",
  please_check_luggage_content_and_upload:
    "Veuillez vérifier le contenu des bagages et télécharger votre signature afin de valider la commande avant de commencer à voyager avec les bagages.",
  please_note_after_you_upload_signature:
    "Remarque** : Veuillez noter qu'après avoir téléchargé votre signature, vous ne pourrez plus annuler la commande. En cas d'annulation, aucun remboursement des frais de service ne pourra intervenir pour aucune des parties.",
  luggage_sender_signature: "Signature de l'expéditeur des bagages",
  traveller_signature: "Signature du voyageur :",
  please_enter_delivery_code_recieved:
    "Veuillez saisir le code de livraison reçu du destinataire des bagages et le soumettre pour marquer la commande comme terminée.",
  enter_delivery_code: "Entrez le code de livraison",
  my_travel_detailes: "Mes détails de voyage",
  luggage_detailes: "Détails des bagages:",
  used_second_hand: "Occasion - Seconde main",
  contact: "le contact",
  will_you_be_getting_your_luggage_delivery:
    "Allez-vous vous faire livrer vos bagages ?",
  yes: "Oui",
  exterior: "extérieur",
  interior: "intérieur",
  the_order_has_been_successfuly_marked:
    "La commande a été marquée avec succès comme terminée. Vous pouvez consulter les détails de la commande dans la section « Commande terminée ».",
  you_may_also_view:
    "Vous pouvez également consulter la facture associée à la commande dans les détails de la commande complétée.",
  please_take_some_time_to_rate_your_experience:
    "Veuillez prendre le temps d'évaluer votre expérience avec l'expéditeur des bagages. Cela aidera la communauté à identifier les bonnes pistes",
  please_rate_your_experience_with_luggage_sender:
    "Veuillez évaluer votre expérience avec l'expéditeur de bagages",
  rating: "Notation",
  comment: "Commentaire",
  payment_due_by_luggage_sender: "Paiement dû par l'expéditeur du bagage",
  order: "Commande",
  cancellation_by: "Annulé par:",
  cancellation_reason: "Raison de l'annulation:",
  reciever_signature: "Signature du destinataire:",
  view_invoice: "Voir la facture",
  rate_the_traveller: "Évaluez le voyageur",
  rate_the_Sender:"Évaluer l'expéditeur",
  rate_the_Article: "Évaluez l'article",
  rate_the_Donation: "Evaluer le donateur",
  rate_your_experience: "Évaluez votre expérience",
  please_rate_your_experience_with_luggage_traveller_sender:
    "Veuillez évaluer votre expérience avec le porte-bagages/voyageur",
  please_rate_your_experience_with_Article_sender: "Veuillez évaluer votre expérience avec le donateur d'articles",
  complete: "Complète",
  earned: "Gagné",
  cost_to_carry_luggage: "Coût du transport des bagages",
  service_fee: "Frais de service",
  total_fees: "Total des frais",
  Setup: "l' installation",
  credit_card: "Carte de crédit",
  debit_card: "Carte de débit",
  prepaid_card: "Carte prépayée",
  visa: "las Visa",
  master_card: "Carte maîtresse",
  discover: "Découvrir",
  e_wallet: "Portefeuille électronique",
  view_all_travel_announcement: "Voir toutes les annonces de voyage",
  view_all_article_on_sale: "Voir tous les articles en vente",
  view_all_article_for_donation: "Voir tous les articles pour un don",
  Print_Invoice: "La facture d'impression",
  Invoice_for_carrying_luggage: "Facture pour le transport des bagages",
  Invoice_for_sending_luggage: "Facture d'envoi des bagages",
  Invoice_Date: "Date de facture",
  Invoice_Amount: "Montant de la facture",
  Luggage_Title: "Titre du bagage",
  Drop_off_Destination: "Destination de dépôt",
  Delivery_Date: "La date de livraison",
  Luggage_Sender: "Expéditeur de bagages",
  Luggage_sent_by: "Bagage envoyé par",
  Invoice_type: "Type de facture",
  Luggage_Weight: "Poids des bagages",
  Unit_Price_Kg: "Prix ​​unitaire/Kg",
  Cost_to_carry_Luggage: "Coût du transport des bagages",
  Admin_Fees: "Frais d'administration",
  Net_Earnings: "Bénéfice net",
  Invoice: "Facture",
  Total_Paid_Amount: "Montant total payé",
  change_profile_photo: "Changer la photo de profil",
  complete_your_profile: "complète ton profil",
  Luggage_sending_request: "Demande d'envoi de bagages (j'ai envoyé)",
  Luggage_sending_request_resive: "Demande d'envoi de bagages (je répond)",
  Luggage_sending_request2: "Demande d'envoi de bagages (j'ai reçu)",
  Legals: "Mentions légales",
  from_the_website: "Du site internet",
  legal_text: "applicables à compter du 1 décembre 2023",
  legal_text1: "Vous êtes actuellement connecté au site internet",
  legal_text2:
    "édité par Cobagage Network, SASU, capital social : 1000 €, RCS de Paris : 848 506 861, siège social : 78 avenue des Champs Elysées-Bureau, Bureau 326 - 75008 Paris, France, téléphone : +336 75 68 10 28, email :",
  legal_text3:
    "n° de TVA intracommunautaire : FR44914381116, directeur de la publication et responsable de la rédaction : Ludovic YAO.",
  EDITOR: "EDITEUR",
  HOST: "HEBERGEUR",
  legal_text4: "Le Site est hébergé par AWS  : Amazon Web Services (AWS)",
  Cobagage_copyright_2019_All_rights_reserved:
    "©Cobagage Copyright 2019-2024 Tous droits réservés.",
  Rate_our_website: "Évaluez notre site Web",
  Accept_Confirmation: "Accepter la confirmation",
  Cancel_Order_Confirmation: "Annuler la confirmation de commande",
  acceptText1:
    "Vous êtes sur le point d'accepter la demande du voyageur d'envoyer vos bagages.",
  acceptText2:
    "Dès votre confirmation, nous vous facturons le coût du voyage comme détaillé ci-dessous :",
  Price_to_pay: "Prix ​​à payer",
  Traveller_fees: "Frais de voyage",
  Service_fees: "Frais de service",
  Total_fees: "Total des frais",
  I_Accept_Confirm: "J'accepte et confirme",
  successText:
    "Votre confirmation a été envoyée avec succès au voyageur. Veuillez garder à l'esprit les instructions suivantes à suivre le jour du voyage lors de la remise de vos bagages au voyageur :",
  successText1: "Veuillez vérifier la validité du contenu des bagages",
  successText2: "Veuillez télécharger votre signature comme preuve de validité",
  successText3:
    "Une fois vos bagages acceptés par le voyageur, vous recevrez un OTP, que vous devrez partager avec le destinataire des bagages. Le voyageur livrera les bagages après avoir reçu l'OTP de sa part.",
  successText4:
    "Vous pouvez vérifier l'état de cette commande dans la section Mes commandes - En cours",
  I_received: "(j'ai reçu)",
  i_would_like_to_publish:
    "Je souhaite également publier les détails de mon voyage de retour",
  luggage_carrying_request1: "Demande de transport de bagages (j'ai reçu)",
  back_to_luggaage_request: "Retour à la demande de bagages",
  luggage_carrying_request2: "Demande de portage de bagages (j'envoie)",
  Luggages_I_want_to_sent: "Bagages que je veux envoyer",
  Articles_I_want_to_buy: "Articles que je souhaite acheter",
  Pending_acceptance_seller: "En attente d'acceptation par : Donneuse",
  Pending_acceptance_seller2: "Pending acceptance by:Vendeur",
  travel_confirm_text:
    "  Vous êtes sur le point d'accepter la livraison des bagages du demandeur d'expédition. Veuillez confirmer votre action. Après avoir accepté, votre capacité de transport restante sera de :",
  travel_confirm_text1:
    "Après avoir accepté cette demande, votre capacité de transport restante sera de",
  Select_payment_method: "Sélectionnez le mode de paiement",
  Your_transport_capacity_weight:
    "Votre capacité de transport est inférieure au poids demandé.",
  Rating_sent_sucessfully: "Note envoyée avec succès",
  tavelText1:
    "Votre confirmation a été envoyée avec succès à l'expéditeur des bagages.:",
  tavelText2:
    "Veuillez vérifier et valider le contenu des bagages à récupérer (bagage ouvert, le contenu, le poids total payé). .",
  tavelText3:
    "Une fois que vous avez livré les bagages au destinataire, n'oubliez pas valider la livraison avec le code OTP donné par le récepteur. Ce code est à saisir sur le site  Cela marquera la fin de la livraison des bagages et après 3 jours, vous recevrez votre paiement via le mode de paiement préféré.    .",
  tavelText4: "Prochaines étapes:",
  tavelText5:
    "1. Vous devez récupérer les bagages. Convenez avec l’expéditeur grâce à la messagerie d’un lieu de prise en charge simple et précis",
  tavelText6:
    "2. Lorsque les bagages sont récupérés, veuillez signer la prise en charge dans la rubrique « Mes commandes/Voyages/encours»",
  tavelText7:
    "3. Dès que vous signer, l’expéditeur reçoit le code de livraison qui validera votre livraison (livraison sur validation du code OTP par sms ou mail)",
  tavelText8: "Attention : vérifier absolument le poids, le contenu, et la conformité des bagages - ne soyez pas distrait car vous êtes l’unique responsable du transport des bagages.",
  You_have_already_rated_this_product: "Vous avez déjà noté ce produit",
  Back_to_travel_announcements: "Retour aux annonces de voyage",
  Back_to_luggage_listing_page: "Retour à la page de liste des bagages",
  Back_to_listing_page: "Retour à la page d'annonce",
  add_submitted_successfully: "Annonce soumise avec succès...",
  Skip_and_go_my_profile: "Passer l'identifiant",
  Note_residence: "NB : Votre adresse est nécessaire afin de convertir les prix des annonces à la monnaie de votre pays de résidence.",
  Unauthorized: "Non autorisé",
  Customer: "Cliente",
  Money_received_from: "Argent reçu de",
  Enter_Amount: "Entrer le montant",
  Click_Here: "Cliquez ici",
  Price_to_high: "Prix ​​trop élevé",
  I_Changed_My_Mind: "j'ai changé d'avis",
  Required_Quantity_Unavailable: "Quantité requise indisponible",
  Raise_a_dispute: "Soumettre un litige",
  submit_an_ad: "Déposer une annonce",
  new_password_check:
    "Le nouveau mot de passe doit être différent de l'ancien.",

  //  chat
  send_the_message: "Envoyer un message ",
  Chat_box: "Boîte de discussion",
  Type_message: "Tapez un message...",
  English: "Anglais",
  French: "Français",
  card_Deleted: "Carte supprimée....",
  card_update: "Carte mise à jour....",
  card_add: "Carte ajoutée....",
  no_rows: "Aucune ligne",
  rows_per_page: "Lignes par page",
  Otp_Verification: "Vérification OTP",
  More_cobagage: "En savoir plus sur le cobagage",
  refund: "Remboursement terminé",
  acceptance_request: 'Accepter la confirmation ',
  signature_code: "Votre signature - code de livraison",
  proceed: "Procéder au paiement",
  Your_ticket_has_business_days: "Votre ticket a été envoyé avec succès, l'administrateur vous contactera dans les 3 prochains jours ouvrables.",
  Phone_Number: "Numéro de téléphone",
  International_payment: "Paiement international",
};
export default profile;
