import React, { useState, useEffect, useMemo, useContext } from 'react'
import { auth, country, user } from '../../../../models';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useValidator from '../../../../plugin/validator';
import notistack from '../../../../plugin/notistack';
import { Modal, Button, Form } from 'react-bootstrap';
import card from '../../../../models/card';
import payPal from '../../../../models/payPal';
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import Geocode from "react-geocode";
import ReactPlayer from 'react-player';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import AppContext from '../../../../store/context/app-context';
import i18n from '../../../../plugin/i18n';

const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
};
const cardDetails = {
  card_type: '',
  card_holder_name: '',
  card_number: '',
  cvv: '',
  card_expiry_month: '',
  card_expiry_year: '',
  paypal_id: '',
}
const otherDataCityArray = {
  state_id: "",
  city_name: "",
};
export default function MyProfile(User_id) {
  const { t } = useTranslation();
  const [validator, showMessage] = useValidator();
  const [cardValidator, cardFieldsMessage] = useValidator();
  const [profileImg, setProfileImg] = useState();
  const [identityImg, setIdentityImg] = useState(['0', '0']);
  const [image, setImage] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [details, setDetails] = useState(format)
  const [show, setShow] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [imgType, setImgType] = useState();
  const [IsShowpayPalId, setShowPayPalId] = useState(false);
  const [cardData, setCardData] = useState(cardDetails);
  const [userCards, setUserCards] = useState([]);
  const [isFocusedAddress, setIsFocusedAddress] = useState(false);
  const [otherCityData, setOtherCityData] = useState(otherDataCityArray);
  const history = useHistory();
  const contextObj = useContext(AppContext);
  // Geocode.setApiKey(" ");
  // Geocode.setLanguage("en");
  const [paypalId, setPayPalId] = useState({
    id: '',
    paypal_id: ''
  });
  const [data, setData] = useState({
    full_name: '',
    account_type: '',
    contact_number: '',
    email: '',
    country_id: '',
    address: '',
    video_link: '',
    city: '',
    state: '',
    postal_code: '',
    geolocation: '',
    identity_id: '',
    identity_images: [],
    profile_image: '',
    identity_imagesPath: [],
    contact_type: "",
    offical_company_number: "",
    company_contact_name: ""
  });

  console.log("data", data);

  const fullAddress = `${data.address}, ${data.state}, ${data.country}, ${data.postal_code}`;

  const getAddress = (e) => {
    let lat = e.latLng.lat();
    let lng = e.latLng.lng();
    Geocode.fromLatLng(lat, lng).then(
      (response) => {
        const geolocation = response.results[0].formatted_address;
        handleSetData("geolocation", geolocation);
        handleSetData("Latitude", lat);
        handleSetData("Longitude", lng);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const center = useMemo(() => ({ lat: 18.52043, lng: 73.856743 }), []);

  const handleAddressFocus = () => {
    setIsFocusedAddress(true);
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyCNvA08BkKGrqP2Ao4ji75Q-3KMw_pPQLs",
  });

  const getLatLng = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
    Geocode.fromAddress(value).then(
      (response) => {
        handleSetData("Latitude", response.results[0].geometry?.location?.lat);
        handleSetData("Longitude", response.results[0].geometry?.location?.lng);
      },
      (error) => {
        console.error(error);
      }
    );
  };

  const handleClose = () => {
    setCardData(cardDetails);
    setShow(false);
    cardFieldsMessage(false);
  }
  const handleShow = () => setShow(true);

  const handleCloseDelete = () => {
    setDeleteOpen(false);
  }

  const handleShowDelete = () => setDeleteOpen(true);

  const handleSetDataOtherCity = (name, value) => {
    setOtherCityData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const error = {
    full_name: validator.message(t("auth.full_Name"), data?.full_name, 'required|string|max:200'),
    contact_number: validator.message(t("profile.contact_number"), data?.contact_number, 'required|phone|max:20'),
    email: validator.message(t('auth.email'), data?.email, 'required|email|string|max:200'),
    country_id: validator.message(t("auth.country"), data?.country_id, 'required|integer'),
    state: validator.message(t("profile.state"), data?.state, 'required|integer'),
    city: validator.message(t("profile.city"), data?.city, 'required|integer'),
    address: validator.message(t("profile.address"), data?.address, 'required|string|max:200'),
    postal_code: validator.message(t("profile.zipcode"), data?.postal_code, 'required|string|max:200'),
    identity_id: validator.message(t("profile.contact_number"), data?.identity_id, 'required|integer'),
    account_type: validator.message(t("auth.account_type"), data?.account_type, 'required|integer'),
  };
  const cardError = {
    card_holder_name: cardValidator.message(t('profile.card_holders_name'), cardData?.card_holder_name, 'required|alpha_space'),
    card_number: cardValidator.message('Card Number', cardData?.card_number, 'required'),
    cvv: cardValidator.message("CVV", cardData?.cvv, 'required|numeric|min:3'),
    card_expiry_month: cardValidator.message("Month", cardData?.card_expiry_month, 'required|integer'),
    card_expiry_year: cardValidator.message("Year", cardData?.card_expiry_year, 'required|numeric|min:4'),
    card_type: cardValidator.message("Card Type", cardData?.card_type, 'required|'),
  }

  useEffect(() => {
    if (User_id.User_id) {
      getUserData();
    }
    else {
      getAllData();
    }
    getData();
  }, [])
  const getAllData = () => {
    auth.getUser().then(responseData => {
      responseData.profile_image = '';
      setData(responseData);
      handleSetDataOtherCity(
        "state_id",
        responseData.state
      )
      setProfileImg(responseData.profile_imagePath);
      setIdentityImg(responseData.identity_imagesPath)
      setImage(responseData.identity_imagesPath)
      getDepartureStateData(responseData.country_id);
      getDepartureCity(responseData.state);
      getCardData(responseData.id);
      getPayPalId(responseData.id);
    }).catch((error) => {
      console.log('Here is error myprofille 1st');
    })
  }
  const getCardData = (id) => {
    card.get(id).then(item => {
      setUserCards(item);
    }).catch(error => {
      console.log("error", error);
    })
  }
  const getData = () => {
    country
      .country()
      .then((details) => {
        handleData("departureCountry", details)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const getUserData = () => {
    user.get(User_id.User_id).then((respons) => {
      setData(respons)
      handleSetDataOtherCity(
        "state_id",
        respons.state
      )
      setProfileImg(respons.profile_imagePath);
      setIdentityImg(respons.identity_imagesPath)
    }).catch((error) => {
      console.log('Here is error myprofile 2nd function');
    })
  }

  const getDepartureStateData = (id) => {
    country
      .state(id)
      .then((details) => {
        handleData("departureState", details)
      })
  };

  const getDepartureCity = (id) => {
    country
      .city(id)
      .then((details) => {
        handleData("departureCity", details)
      })
  };
  const handleData = (name, value) => {
    setDetails(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };

  const convertToBase64 = (e) => {
    let tempFile = e.target.files[0];
    getBase64Image(tempFile).then(responseBase64 => {
      let arr = data?.identity_images ? [...data?.identity_images] : ['0', '0'];
      if (imgType == 1 || imgType == 2) {
        if (imgType == 1) {
          arr[0] = responseBase64;
        }
        else if (imgType == 2) {
          arr[1] = responseBase64;
        }
        handleSetData('identity_images', arr);
      }
      else {
        handleSetData('profile_image', responseBase64);
      }

    }).catch((err) => {
      console.log('Error While convert to base64: ', err);
    })
  }

  const getBase64Image = (fileParam) => {
    return new Promise(resolve => {
      let fileInfo;
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(fileParam);
      reader.onload = () => {
        baseURL = reader.result;
        console.log(baseURL);
        resolve(baseURL);
      };
      console.log(fileInfo);
    })
  }

  const handleSubmit = () => {
    if (validator.allValid()) {
      auth.profile(data).then((item) => {
        setProfileImg(item.profile_imagePath);
        setIdentityImg(item.identity_imagesPath);
        showMessage(false);
        notistack.success(t('form.updated_successfully'));
        contextObj?.functionsetloginUser()
        setIsOpen(true);
      })
        .catch((error) => {
          notistack.error(error.response.data.message)
          console.log(error);
        })
    }
    else {
      showMessage(true);
    }
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  }
  const handlePostalCode = (e) => {
    const { name, value } = e.target;
    const value1 = (value.replace(/\D/g, ''));
    handleSetData(name, value1);
  }
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const handleCardChange = (e) => {
    const { name, value } = e.target;
    if (name === "card_number") {
      console.log("value", value);
      const formattedCardNumber = value.replace(/\D/g, '').replace(/(.{4})/g, '$1 ');
      console.log("formattedCardNumber", formattedCardNumber);
      handleCardData(name, formattedCardNumber);
    } else {
      handleCardData(name, value);
    }
  };
  const handleCardData = (name, value) => {
    setCardData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handlePaypalIdChange = (e) => {

    const { name, value } = e.target;
    handlePayPalIdData(name, value);
  }
  const handlePayPalIdData = (name, value) => {
    setPayPalId((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleCardSubmit = () => {
    if (cardValidator.allValid()) {
      if (cardData?.card_id) {
        card.update(cardData?.card_id, cardData).then((item) => {
          notistack.success(t('profile.card_update'));
          setCardData(cardDetails);
          getCardData(data.id);
          handleClose();
        })
          .catch((error) => {
            console.log(error);
          })
      }
      else {
        card.create(cardData).then((item) => {
          notistack.success(t('profile.card_add'));
          handleClose();
          getCardData(data.id);
          cardFieldsMessage(false);
          setCardData(cardDetails);
        })
          .catch((error) => {
            notistack.error(error.data.errors.card_expiry_year);
            console.log(error);
          })
      }
    }
    else {
      cardFieldsMessage(true);
    }
  }
  const deleteCard = (id) => {
    card.delete(id).then((item) => {
      notistack.success(t("profile.card_Deleted"));
      getCardData(data.id);
    })
      .catch((error) => {
        console.log(error);
      })
  }
  const handlePayPalId = () => {
    if (paypalId?.id != '') {
      let id = paypalId?.id;
      payPal.update(id, paypalId).then((item) => {
        notistack.success(t('form.updated_Successfully'));
        setPayPalId(item);
        setShowPayPalId(!IsShowpayPalId);
        setCardData(cardDetails);
      })
        .catch((error) => {
          notistack.error(error.data.errors.paypal_id);
          console.log(error);
        })
    }
    else {
      let id = data.id;
      payPal.create(id, { paypal_id: cardData?.paypal_id }).then((item) => {
        notistack.success('Added....');
        getPayPalId(item.user_id);
        setShowPayPalId(!IsShowpayPalId);
        setCardData(cardDetails);
      })
        .catch((error) => {
          console.log(error);
        })
    }
  }
  const getPayPalId = (id) => {
    payPal.get(id).then(responseData => {
      setPayPalId(responseData);
    })
      .catch((error) => {
        console.log("paypal error");
      })
  }
  const convert = () => {
    let arr = [];
    image?.map((item) => {
      fetch(item)
        .then((response) => {
          let blob = response.blob();
          blob.then((blob) => {
            let reader = new FileReader();
            reader.readAsDataURL(blob);
            reader.onload = () => {
              let baseURL = reader.result;
              arr.push(baseURL);
              console.log("baseURL", baseURL);
              // setdecodedString(baseURL);
            };
          });
        })
        .catch((error) => {
          console.log("converterrorArticle:", error);
        });
    });
    handleSetData("identity_images", arr);
  };
  const handleDeteleAccount = () => {
    user.delete(data.id).then((respons) => {
      notistack.success(t("form.Account_deleted_successfully"))
      contextObj.setLogin();
      history.push('/login');
    }).catch((error) => {
      console.log("delete the account", error);
    })
  }
  const handleSubmitOtherCity = () => {
    country
      .addOtherCity(otherCityData)
      .then((response) => {
        data?.city == "other_city" && handleSetData("city", "");
        getDepartureCity(otherCityData?.state_id);
      })
      .catch((error) => {
        console.log("error", error);
        notistack.error(error?.data.message);
      });
  };

  const numberInputOnWheelPreventChange = (e) => {
    e.target.blur()
    e.stopPropagation()
    setTimeout(() => {
      e.target.focus()
    }, 0)
  }

  return (
    <div className='package-details-wrapper pb-110'>
      <div className='tour-package-details'>
        <div className="package-details-tabs">
          <ul
            className="nav nav-pills  gap-xxl-4 gap-3"
            id="myTab"
            role="tablist"
            style={{ marginBottom: '0px' }}
          >
            <li className="nav-item travel" role="presentation">
              <button
                className="nav-link active"
                id="pills-package1"
                data-bs-toggle="pill"
                data-bs-target="#pill-body1"
                type="button"
                role="tab"
                aria-controls="pill-body1"
                aria-selected="true"
                style={{ fontSize: '16px', borderRadius: '5px' }}
              >
                {t("profile.profile_information")}
              </button>
              {/* nav nav-pills tab-switchers id- pills-tab*/}
            </li>
            <li className="nav-item travel" role="presentation">
              <button
                className="nav-link"
                id="pills-package2"
                data-bs-toggle="pill"
                data-bs-target="#pill-body2"
                type="button"
                role="tab"
                aria-controls="pill-body2"
                aria-selected="false"
                style={{ fontSize: '16px', borderRadius: '5px' }}
              >
                {t("profile.id_verification_documents")}
              </button>
            </li>
            <li className="nav-item travel" role="presentation">
              <button
                className="nav-link"
                id="pills-package3"
                data-bs-toggle="pill"
                data-bs-target="#pill-body3"
                type="button"
                role="tab"
                aria-controls="pill-body3"
                aria-selected="false"
                style={{ fontSize: '16px', borderRadius: '5px' }}
              >
                {t("profile.my_cards_and_payment_methods")}
              </button>
            </li>
          </ul>
          <div className="tab-content" id="pills-tabContent">
            {/* Profile Information */}
            <div
              className="tab-pane fade show active package-info-tab "
              id="pill-body1"
              role="tabpanel"
              aria-labelledby="pills-package1"
              style={{ overflowX: 'auto' }}
            >
              <form
                onSubmit={(e) => e.preventDefault()}
                action="#"
                method="post" >
                <div className="contact-form-wrap pb-110">
                  <div className="">
                    <div class="row">
                      <div class="col">
                        <img src={profileImg && profileImg} class="rounded-circle" style={{ minWidth: '140px', minHeight: '140px' }} height={140} width="140" />
                        <div class="mt-2" >
                          {!isOpen &&
                            <button type="button" onClick={() => setImgType(3)} style={{ border: '0px', backgroundColor: '#fff' }}>
                              <label for="file" style={{ color: '#1e98d7' }}>
                                {t('profile.change_profile_photo')}
                              </label>
                            </button>}
                          <input style={{ display: "none" }}
                            type="file"
                            id="file"
                            onChange={(e) => {
                              convertToBase64(e);
                              if (imgType == 3) {
                                setProfileImg(URL.createObjectURL(e.target.files[0]))
                              }
                              else {
                                let arr = [...identityImg];
                                arr[imgType === 1 ? 0 : 1] = URL.createObjectURL(e.target.files[0]);
                                setIdentityImg(arr);
                              }
                            }}
                          />
                        </div>
                      </div>


                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name"> {t("auth.full_Name")}  <span style={{ color: 'red' }}>*</span></label>
                          <input
                            required
                            disabled={isOpen}
                            type="text"
                            placeholder={t("auth.full_Name")}
                            id="full_name"
                            onChange={handleChange}
                            name='full_name'
                            value={data?.full_name}
                          />
                          <span style={{ color: "red" }}>{error.full_name}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("auth.account_type")}<span style={{ color: 'red' }}> *</span></label>
                          <select
                            required
                            disabled={isOpen}
                            id="account_type"
                            onChange={handleChange}
                            name='account_type'
                            value={data?.account_type}
                          >
                            <option value="" disabled>-</option>
                            <option value="1">{t("form.Individual")}</option>
                            <option value="2">{t("form.Professional")}</option>
                          </select>
                          <span style={{ color: "red" }}>{error.account_type}</span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("auth.email")}  <span style={{ color: 'red' }}>*</span></label>
                          <input
                            required
                            disabled={isOpen}
                            type="email"
                            placeholder={t("auth.email")}
                            onChange={handleChange}
                            name='email'
                            value={data?.email}
                          />
                          <span style={{ color: "red" }}>{error.email}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("auth.country")}<span style={{ color: 'red' }}> *</span></label>
                          <select
                            disabled={data?.country_id || isOpen}
                            id="country_id"
                            onChange={(e) => {
                              handleChange(e, "country");
                              handleSetData("state", "");
                              handleSetData("city", "");
                              handleData(e, 'departureCountry');
                              getDepartureStateData(e.target.value);
                            }}
                            name='country_id'
                            value={data?.country_id}
                            required
                          >
                            <option value="" disabled>-</option>
                            {details?.departureCountry?.map((item) => <option value={item.id} key={item.id} >{item.name}</option>)}
                          </select>
                          <span style={{ color: "red" }}>{error.country_id}</span>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("auth.phone")} <span style={{ color: 'red' }}>*</span></label>
                          <input
                            required
                            disabled={isOpen}
                            type="text"
                            className='numberType'
                            placeholder={t("auth.phone")}
                            id="contact_number"
                            onChange={handleChange}
                            name='contact_number'
                            value={data?.contact_number}
                          />
                          <span style={{ color: "red" }}>{error.contact_number}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("profile.address")} <span style={{ color: 'red' }}>*</span></label>
                          <input
                            required
                            disabled={isOpen}
                            type="text"
                            placeholder={t("profile.address")}
                            id="address"
                            onChange={handleChange}
                            name='address'
                            value={data?.address}
                          />
                          <span style={{ color: "red" }}>{error.address}</span>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("profile.zipcode")} <span style={{ color: 'red' }}>*</span></label>
                          <input
                            required
                            disabled={isOpen}
                            type="text"
                            maxLength={6}
                            className='numberType'
                            placeholder={t("profile.zipcode")}
                            id="postal_code"
                            onChange={handlePostalCode}
                            name='postal_code'
                            inputmode="numeric"
                            value={data?.postal_code}
                          />
                          <span style={{ color: "red" }}>{error.postal_code}</span>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <label htmlFor="name">{t("profile.state")}<span style={{ color: 'red' }}> *</span></label>
                          <select
                            required
                            disabled={isOpen}
                            id="state_departure"
                            onChange={(e) => {
                              handleChange(e, "state");
                              handleSetData("city", "");
                              handleData(e, 'state_of_Departure');
                              handleData('city_of_Departure', '');
                              handleSetDataOtherCity(
                                "state_id",
                                e.target.value
                              );
                              getDepartureCity(e.target.value);
                            }}
                            name='state'
                            value={data?.state}
                          >
                            <option value="" disabled>-</option>
                            {details?.departureState?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                          </select>
                          <span style={{ color: "red" }}>{error.state}</span>
                        </div>
                      </div>

                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <h4 style={{ fontSize: '19px', visibility: 'hidden' }}>{t('profile.presentation_video_link')}</h4>
                          <label htmlFor="name">{t("profile.city")}<span style={{ color: 'red' }}> *</span></label>
                          <select
                            required
                            disabled={isOpen}
                            id="city_departure"
                            onChange={(e) => {
                              handleChange(e, "city");
                              handleData(e, 'city_of_Departure');
                            }}
                            name='city'
                            value={data?.city}
                          >
                            <option value="" disabled>-</option>
                            {details?.departureCity?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                            <option value="other_city">
                              {t("form.Add_your_City")}
                            </option>
                          </select>
                          <span style={{ color: "red" }}>{error.city}</span>
                        </div>
                        {data.city == "other_city" && (
                          <>
                            <div className="col-lg-4 col-12">
                              <div className="custom-input-group">
                                <label htmlFor="name">{t("form.Add_your_City")}</label>
                                <input
                                  required
                                  disabled={!otherCityData?.state_id}
                                  type="text"
                                  placeholder={t("form.Add_your_City")}
                                  id="city_name"
                                  onChange={(e) => {
                                    handleSetDataOtherCity(
                                      "city_name",
                                      e.target.value
                                    );
                                  }}
                                  name="city_name"
                                  value={otherCityData?.city_name}
                                />
                              </div>
                            </div>
                            <div
                              className="col-lg-2 col-12"
                              style={{ display: "flex", alignItems: "end" }}
                            >
                              <div className="custom-input-group">
                                <button
                                  type="submit"
                                  onClick={handleSubmitOtherCity}
                                  // className="btn btn-secondary"

                                  style={{
                                    fontSize: "14px",
                                  }}
                                >
                                  {t("form.Add")}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                      <div className="col-lg-6">
                        <div className="custom-input-group">
                          <h4 style={{ fontSize: '19px' }}>{t('profile.presentation_video_link')}</h4>
                          <label htmlFor="name">{t("profile.youTube_video_link_for_showing_your_presentation")}</label>
                          <input
                            type="text"
                            disabled={isOpen}
                            placeholder={t("profile.video_link")}
                            id="video_link"
                            onChange={handleChange}
                            name='video_link'
                            value={data?.video_link}
                          />
                        </div>
                      </div>
                    </div>
                    {data.account_type == 2 ? <div className="col-lg-12">
                      <div className="custom-input-group">
                        <label htmlFor="name"> {t("auth.company_contacts_name")} </label>
                        <input
                          type="text"
                          disabled={isOpen}
                          placeholder={t("auth.full_Name")}
                          id="company_contact_name"
                          onChange={handleChange}
                          name='company_contact_name'
                          value={data.company_contact_name}
                        />
                      </div>
                    </div> : ""}

                    {data.account_type == 2 ? <div className="row" style={{ marginTop: "30px" }}>
                      <label htmlFor="name" style={{ fontWeight: '500', color: '#2d373c' }}> {t("auth.official_comapany_number_wich_can_be_verified")} </label>
                      <div className="col-5 col-sm-4 col-lg-4">
                        <div className="custom-input-group" style={{ marginTop: "0px" }}>
                          <select
                            id="contact_type"
                            onChange={handleChange}
                            disabled={isOpen}
                            name='contact_type'
                            value={data.contact_type}
                          >
                            <option value='' selected>select option</option>
                            <option value='1'>SIREN</option>
                            <option value='2'>SIRET</option>
                            <option value='3'>{t("form.Company_Number")}</option>
                            <option value='4'>N° RC</option>
                            <option value='5'>{t("form.Other_Number")}</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-7 col-sm-8 col-lg-8 " style={{ paddingRight: '0px' }}>
                        <div className="custom-input-group" style={{ marginTop: "0px" }}>
                          <input
                            required
                            type="number"
                            onWheel={numberInputOnWheelPreventChange}
                            // placeholder={t("auth.phone")}
                            disabled={isOpen}
                            id="offical_company_number"
                            onChange={handleChange}
                            name='offical_company_number'
                            value={data.offical_company_number}
                            className='numberType'
                          />
                        </div>
                      </div>
                    </div> : ""}

                    <div className="row">
                      <div className="col-lg-6">
                        <div className="container-fluid p-0">
                          <div className="contact-map">
                            <div className="mapouter">
                              <div className="gmap_canvas">
                                <iframe
                                  style={{ height: '250px' }}
                                  title="map"
                                  id="gmap_canvas"
                                  src={`https://maps.google.com/maps?q=${encodeURIComponent(fullAddress)}&t=&z=9&ie=UTF8&iwloc=&output=embed`}

                                />
                                <Link to={"https://123movies-to.org"} />
                                <br />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6" style={{ marginTop: "40px" }}>
                        <div style={{ height: "100%" }}>
                          <ReactPlayer height='100%' width='100%' url={data.video_link} />
                        </div>
                      </div>
                    </div>
                    <div className=''>
                      <div className='col-12 col-lg-7 col-sm-6'>

                        <div className="custom-input-group">
                          <span>
                            <p style={{ color: "orange", cursor: "pointer", paddingBottom: "10px", textDecoration: "underline" }} onClick={() => handleShowDelete()}>
                              {t('profile.Delete_an_account')}
                            </p>
                          </span>
                          <div
                            className="submite-btn"
                            style={{ display: User_id.User_id ? 'none' : 'block' }}
                          >
                            {!isOpen ? <button type="submit" onClick={() => handleSubmit()}>{t("save")}</button>
                              : <button type="submit" onClick={() => { setIsOpen(false); convert() }} >{t("edit")}</button>}
                          </div>
                        </div>
                      </div>
                    </div>
                    <Modal
                      show={deleteOpen}
                      onHide={handleCloseDelete}
                      centered
                    >
                      <Modal.Header closeButton>
                        <Modal.Title>{t('profile.delete_account')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body >
                        <Form>
                          {t('profile.Are_delete_account')}
                        </Form>
                      </Modal.Body>
                      <Modal.Footer
                        style={{ padding: "0px" }}
                      >
                        <div className="custom-input-group">
                          <div
                            className="submite-btn"
                          >
                            <button type="submit"
                              style={{ padding: "9px 38px", marginBottom: '10px' }}
                              onClick={handleCloseDelete}

                            >{t("profile.Close")}</button>
                          </div>
                        </div>
                        <div className="custom-input-group">
                          <div
                            className="submite-btn"
                          >
                            <button type="submit"
                              style={{ padding: "9px 38px", marginBottom: '10px', marginRight: '5px' }}
                              onClick={handleDeteleAccount}

                            >{t("profile.delete")}</button>
                          </div>
                        </div>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </form >
            </div>
            {/* Id verification Documents */}
            <div
              className="tab-pane fade package-info-tab"
              id="pill-body2"
              role="tabpanel"
              aria-labelledby="pills-package2"
              style={{ overflowX: 'auto' }}
            >
              <div className="">
                <div className="contact-form-wrap pt-30">
                  <h5>{t("profile.verify_your_id")} <span style={{ color: 'red' }}>*</span></h5>
                  <p>
                    <span style={{ fontWeight: '600' }}> {t("profile.please_upload_your_ID_verification_document_you_may_upload_any_of_the_below_listed_documents")} :</span><br />
                    {t("profile.national_identity_card")} <br />
                    {t("profile.passport")} <br />
                    {t("profile.residence_card")}<br />
                    {t("profile.green_card")} <br />
                    {t("profile.drive_licence")}<br />
                    {t("profile.residence_permit")}<br />
                    {t("profile.other_valide_document")}
                  </p>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <select
                          disabled={isOpen}
                          id="identity_id"
                          onChange={handleChange}
                          name='identity_id'
                          value={data?.identity_id}
                        >
                          <option value="" selected disabled>-{t("profile.select_document_type")}-</option>
                          <option value={'1'}>{t("profile.national_identity_card")}</option>
                          <option value={'2'}> {t("profile.passport")}</option>
                          <option value={'3'}> {t("profile.residence_card")}</option>
                          <option value={'4'}>{t("profile.green_card")}</option>
                          <option value={'5'}> {t("profile.drive_licence")}</option>
                          <option value={'6'}>{t("profile.residence_permit")}</option>
                          <option value={'7'}> {t("profile.other_valide_document")}</option>
                        </select>
                        <span style={{ color: "red" }}>{error.identity_id}</span>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="custom-input-group">
                        <label htmlFor="name" style={{ fontWeight: '600' }}>{t("profile.front_page")}</label>
                        <div className="container-fluid p-0">
                          <div className="contact-map ">
                            <div className="mapouter">
                              <div className="gmap_canvas">
                                <img src={identityImg && identityImg[0]} width="100%" height='250px' />
                                <div class="mt-2" >
                                  {!isOpen && <button type="button" onClick={() => setImgType(1)} style={{ border: '0px', backgroundColor: '#fff' }}>
                                    <label for="file" style={{ color: '#1e98d7' }}>
                                      {t("edit")} / {t("profile.front_page")}
                                    </label>
                                  </button>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="custom-input-group ">
                        <label htmlFor="email" style={{ fontWeight: '600' }} >{t("profile.back_page")}</label>
                        <div className="container-fluid p-0">
                          <div className="contact-map ">
                            <div className="mapouter">
                              <div className="gmap_canvas">
                                <img src={identityImg && identityImg[1]} width="100%" height='250px' />
                                <div class="mt-2" >
                                  {!isOpen && <button type="button" onClick={() => setImgType(2)} style={{ border: '0px', backgroundColor: '#fff' }}>
                                    <label for="file" style={{ color: '#1e98d7' }}>
                                      {t("edit")} / {t("profile.back_page")}
                                    </label>
                                  </button>}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="custom-input-group">
                    <div
                      className="submite-btn"
                      style={{ display: User_id.User_id ? 'none' : 'block' }}
                    >
                      {!isOpen ? <button type="submit" onClick={() => handleSubmit()}>{t("save")}</button>
                        : <button type="submit" onClick={() => setIsOpen(false)} >{t("edit")}</button>}
                    </div>
                  </div>

                </div>
              </div>
            </div>
            {/* My Cards & Payment Methods */}
            <div
              className="tab-pane fade package-info-tab"
              id="pill-body3"
              role="tabpanel"
              aria-labelledby="pills-package3"
              style={{ overflowX: 'auto' }}
            >
              <div class={`container ${userCards?.length >= 1 && 'package-card-delta card'} p-3 mt-5`} style={{ overflowX: 'auto' }}>
                <div className="custom-input-group">
                  <div className="row">
                    <h4 className='col' style={{ fontWeight: '700', fontSize: '22px', }}>{userCards?.length >= 1 && t('profile.my_cards')} </h4>
                    <span className='text-end col' style={{ fontSize: '18px', color: '#1e98d7', cursor: 'pointer' }} onClick={handleShow}>{t('profile.add_new_card')} +</span>
                    <Modal show={show} onHide={handleClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>{t('profile.add_new_card')}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body >
                        <Form>
                          <div class="container-fluid">
                            <div class="row justify-content-center">
                              <div class="card my-4 p-3">

                                <div class="row justify-content-center mrow">
                                  <div class="col-lg-8 col-12 col-sm-6 col-md-8">
                                    {/* <img src="https://img.icons8.com/color/48/000000/mastercard-logo.png" width="35px" height="35px" />
                                    <img src="https://img.icons8.com/color/48/000000/visa.png" width="35px" height="35px" /> */}
                                    {/* <img src="https://img.icons8.com/color/48/000000/paypal.png" width="35px" height="35px" /> */}
                                    <div className='row'>
                                      <div className='col-2'>
                                        <img src={"/images/vehicles/visa.png"} alt="" width="100%" height="100%" />
                                      </div>
                                      <div className='col-2'>
                                        <img src={"/images/vehicles/mscard.jpg"} alt="" width="100%" height="100%" />
                                      </div>
                                      <div className='col-2'>
                                        <img src={"/images/vehicles/maestro.png"} alt="" width="100%" height="100%" />
                                      </div>
                                      <div className='col-2'>
                                        <img src={"/images/vehicles/cb.jpg"} alt="" width="100%" height="100%" />
                                      </div>
                                      <div className='col-2'>
                                        <img src={"/images/vehicles/card.png"} alt="" width="100%" height="100%" />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="form-group col-lg-4 col-12 col-sm-6 col-md-4">
                                    <label class="form-control-placeholder p-0" for="expdate">{t('profile.card_type')}{' '}<i class="bi bi-credit-card"></i><span style={{ color: 'red' }}> *</span></label>
                                    <select type="text" id="expdate" name='card_type' onChange={handleCardChange} value={cardData?.card_type} required style={{ border: '1px solid #eee' }} >
                                      <option value='' selected disabled>-</option>
                                      <option value={'1'}>Credit Card </option>
                                      <option value={'2'}>Debit Card </option>
                                      <option value={'3'}>Prepaid Card </option>
                                      <option value={'4'}>Visa</option>
                                      <option value={'5'}>Master Card</option>
                                      <option value={'6'}>Discover</option>
                                    </select>
                                    <span style={{ color: "red" }}>{cardError.card_type}</span>
                                  </div>
                                </div>
                                <form class="form-card">
                                  <div class="row">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label class="form-control-placeholder p-0" for="number">{t('profile.card_holders_name')}<span style={{ color: 'red' }}> *</span></label>
                                        <input type="text"
                                          class=""
                                          placeholder={t('profile.card_holders_name')}
                                          name='card_holder_name'
                                          onChange={handleCardChange}
                                          value={cardData?.card_holder_name}
                                          required
                                          style={{ border: '1px solid #eee' }}
                                        />
                                        <span style={{ color: "red" }}>{cardError.card_holder_name}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-2">
                                    <div class="col-12">
                                      <div class="form-group">
                                        <label class="form-control-placeholder p-0" for="name">{t('profile.card_number')}<span style={{ color: 'red' }}> *</span></label>
                                        <input
                                          maxLength={19}
                                          type="text"
                                          className='numberType'
                                          placeholder="****  ****  ****  **** "
                                          name='card_number'
                                          onChange={handleCardChange}
                                          value={cardData.card_number.trim()}
                                          required />
                                        <span style={{ color: "red" }}>{cardError.card_number}</span>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row pb-30 mt-2">
                                    <div class="col-sm-4 col-12 col-lg-4">
                                      <div class="form-group">
                                        <label class="form-control-placeholder p-0" for="sdate">{t('profile.expiry_month')}<span style={{ color: 'red' }}> *</span></label>
                                        <select required type="text" class="" id="sdate" style={{ border: '1px solid #eee' }}
                                          name='card_expiry_month'
                                          onChange={handleCardChange}
                                          value={cardData?.card_expiry_month} >
                                          <option value='' selected disabled>-</option>
                                          <option value={'1'}>Jan</option>
                                          <option value={'2'}>Feb</option>
                                          <option value={'3'}>Mar</option>
                                          <option value={'4'}>Apr</option>
                                          <option value={'5'}>May</option>
                                          <option value={'6'}>Jun</option>
                                          <option value={'7'}>July</option>
                                          <option value={'8'}>Aug</option>
                                          <option value={'9'}>Sep</option>
                                          <option value={'10'}>Oct</option>
                                          <option value={'11'}>Nov</option>
                                          <option value={'12'}>Dec</option>
                                        </select>
                                        <span style={{ color: "red" }}>{cardError.card_expiry_month}</span>
                                      </div>
                                    </div>
                                    <div class="col-sm-4 col-12 col-lg-4 mt-2 mt-md-0">
                                      <div class="form-group">
                                        <label class="form-control-placeholder p-0" for="expdate">{t('profile.expiry_year')}<span style={{ color: 'red' }}> *</span></label>
                                        <input
                                          type="text"
                                          placeholder="YYYY"
                                          maxLength={4}
                                          onChange={handleCardChange}
                                          className='numberType'
                                          name='card_expiry_year'
                                          value={cardData?.card_expiry_year}
                                          required />
                                        <span style={{ color: "red" }}>{cardError.card_expiry_year}</span>
                                      </div>
                                    </div>
                                    <div class="col-sm-4 col-12 col-lg-3">
                                      <div class="form-group">
                                        <label class="form-control-placeholder p-0" for="passw">CVV<span style={{ color: 'red' }}> *</span></label>
                                        <br className={`d-none ${i18n.language === 'frFR' ? 'd-lg-block' : 'd-lg-none'} `} /> <br className={`d-none ${i18n.language === 'frFR' ? 'd-lg-block' : 'd-lg-none'} `} />
                                        <input
                                          maxLength={3}
                                          type="password"
                                          onChange={handleCardChange}
                                          name='cvv'
                                          placeholder='* * *'
                                          id="passw"
                                          value={cardData?.cvv}
                                          required />
                                        <span style={{ color: "red" }}>{cardError.cvv}</span>
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </Form>
                      </Modal.Body>
                      <Modal.Footer >
                        <Button variant="secondary" onClick={handleClose} >
                          {t('close')}
                        </Button>
                        <Button variant="primary" onClick={handleCardSubmit}>
                          {t('save')}
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
                <br />

                {userCards?.length >= 1 && <table class="table table-borderless table-responsive card-1 p-4">
                  <thead>
                    <tr class="border-bottom">
                      <th>
                        <span class="ml-2">{t('profile.card_type')}</span>
                      </th>
                      <th>
                        <span class="ml-2">{t('profile.card_number')}</span>
                      </th>
                      <th>
                        <span class="ml-2">{t('profile.card_holders_name')}</span>
                      </th>
                      <th>
                        <span class="ml-2">{t('profile.expiry_date')}</span>
                      </th>
                      {/* <th>
                        <span class="ml-4">CVV</span>
                      </th> */}
                      <th>
                        <span class="ml-4">{t('profile.edit')}</span>
                      </th>
                      <th>
                        <span class="ml-4">{t('profile.delete')}</span>
                      </th>
                    </tr>
                  </thead>
                  {userCards?.map((item) =>
                    <tbody>
                      <tr class="border-bottom">
                        <td>
                          <div class="p-2">
                            <span class="d-block font-weight-bold">
                              {item.card_type === 1 ? t('profile.credit_card') :
                                item.card_type === 2 ? t('profile.debit_card') :
                                  item.card_type === 3 ? t('profile.prepaid_card') :
                                    item.card_type === 4 ? t('profile.visa') :
                                      item.card_type === 5 ? t('profile.master_card') :
                                        item.card_type === 6 ? t('profile.discover') : ''}</span>
                          </div>
                        </td>
                        <td>
                          <div class="p-2 d-flex flex-row align-items-center mb-2">
                            <div class="d-flex flex-column ml-2">
                              <span class="d-block font-weight-bold">{item.card_number}</span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <div class="p-2">
                            <span class="font-weight-bold">{item.card_holder_name}</span>
                          </div>
                        </td>
                        <td>
                          <div class="p-2 d-flex flex-column">
                            <span>
                              {
                                item.card_expiry_month === 1 ? 'Jan' :
                                  item.card_expiry_month === 2 ? 'Feb' :
                                    item.card_expiry_month === 3 ? 'Mar' :
                                      item.card_expiry_month === 4 ? 'Apr' :
                                        item.card_expiry_month === 5 ? 'May' :
                                          item.card_expiry_month === 6 ? 'Jun' :
                                            item.card_expiry_month === 7 ? 'July' :
                                              item.card_expiry_month === 8 ? 'Aug' :
                                                item.card_expiry_month === 9 ? 'Sep' :
                                                  item.card_expiry_month === 10 ? 'Oct' :
                                                    item.card_expiry_month === 11 ? 'Nov' :
                                                      item.card_expiry_month === 12 ? 'Dec' : ''}/{item.card_expiry_year}  </span>

                          </div>
                        </td>
                        {/* <td>
                          <div class="p-2">
                            <span class="font-weight-bold">{item.cvv}</span>
                          </div>
                        </td> */}
                        <td>
                          <div class="p-2 icons">
                            <i class="bi bi-pen-fill text-danger" onClick={() => {
                              item.cvv = item.cvv.toString();
                              item.card_expiry_year = item.card_expiry_year.toString();
                              setCardData(item);
                              handleShow();
                            }} />
                          </div>
                        </td>
                        <td>
                          <div class="p-2 icons">
                            <i class="bi bi-trash text-danger ms-3"
                              onClick={() => deleteCard(item?.card_id)}
                            />
                          </div>
                        </td>
                      </tr>
                    </tbody>)}
                </table>}

              </div>
              <div class={`container package-card-delta card ${userCards?.length >= 1 && 'mt-5'}`}>
                <div className='row d-flex flex-row  mt-5 pb-5  gap-3 gap-md-0 p-2'>
                  {/* <div className='col-lg-6'>
                    <div class="card p-3" style={{ height: '100%' }}>
                      <h4 className='col' style={{ fontWeight: '700', fontSize: '22px' }}>Paypal </h4>
                      <div class="img-box">
                        <img src="https://img.icons8.com/color/48/000000/paypal.png" alt="" width="60px" height="60px" />
                      </div>
                      {IsShowpayPalId ?
                        (<div class="d-flex align-items-center justify-content-between">
                          <small>
                            <span class="fw-bold">
                              <label class="form-control-placeholder p-0" for="name">{t('profile.enter_paypal_id')}</label></span>
                            <span>
                              <input
                                type="text"
                                placeholder={t('profile.paypal_id')}
                                name='paypal_id'
                                value={paypalId?.paypal_id}
                                onChange={handlePaypalIdChange}
                              />
                              <button type="button" class="btn btn-primary mt-2" style={{ backgroundColor: '#1e98c9' }} onClick={() => handlePayPalId()}>{t('form.submit')}</button>
                            </span>
                          </small>
                          <small>
                            <button type="button" class="btn btn-primary  me-1" style={{ backgroundColor: '#1e98c9', marginTop: '70px' }} onClick={() => {
                              setShowPayPalId(false);
                              handleCardData('paypal_id', '');
                            }}>{t('profile.Cancel')}</button>
                             <button type="button" class="btn btn-primary mt-3 me-1" style={{ backgroundColor: '#1e98c9' }} onClick={() => setShowPayPalId(false)}>Back</button>
                          </small>
                        </div>) :
                        (<div class="d-flex align-items-center justify-content-between">
                          <small><span class="fw-bold">{t('profile.paypal_id')}:</span><span>{paypalId?.paypal_id}</span></small>
                          {paypalId?.paypal_id == '' ? <small><span class="fw-bold" style={{ cursor: 'pointer', color: '#1e98d7' }} onClick={() => setShowPayPalId(true)}>{t('profile.add_paypal_id')}</span></small> :
                            <small><span class="fw-bold" style={{ cursor: 'pointer', color: '#1e98d7' }} onClick={() => setShowPayPalId(true)}> {t('profile.Edit_paypal_id')}</span></small>}
                        </div>)
                      }
                    </div>
                  </div> */}
                  <div className='col-lg-6'>
                    <div class="card p-3" style={{ height: '100%', justifyContent: 'center' }}>
                      <div className='row' style={{ justifyContent: "center" }}>
                        <div className='col-2'>
                          <img src={"/images/vehicles/visa.png"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/mscard.jpg"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/maestro.png"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/cb.jpg"} alt="" width="100%" height="100%" />
                        </div>
                        <div className='col-2'>
                          <img src={"/images/vehicles/card.png"} alt="" width="100%" height="100%" />
                        </div>
                      </div>


                    </div>
                  </div>
                </div>
                {/* <div className='row d-flex flex-row mt-5 mb-2'>
                  <div className='col-lg-12 p-3'>
                    <h4 style={{ fontWeight: '700', fontSize: '20px' }} className='mb-3'>{t('profile.my_preferred_payment_method_for_receiving_payments')}</h4>
                    <select type="text" class="form-control" id="expdate" required style={{ width: '50%' }} >
                      <option value='' selected disabled>-</option>
                      {userCards?.map((item) => <option value={'1'}>
                        {item.card_type === 1 ? t('profile.credit_card') :
                          item.card_type === 2 ? t('profile.debit_card') :
                            item.card_type === 3 ? t('profile.prepaid_card') :
                              item.card_type === 4 ? t('profile.visa') :
                                item.card_type === 5 ? t('profile.master_card') :
                                  item.card_type === 6 ? t('profile.discover') : ''}{' '}{item.card_number}</option>)}
                      <option value='2' >{paypalId?.paypal_id}</option>
                    </select>
                  </div>
                  <div className='col-lg-12 p-3'>
                    <h4 style={{ fontWeight: '700', fontSize: '20px' }} className='mb-3'>{t('profile.my_preferred_payment_method_for_spending_money_on_the_site')}</h4>
                    <select type="text" class="form-control" id="expdate" required style={{ width: '50%' }} >
                      <option value='' selected disabled>-</option>
                      <option value={'1'}>{t('profile.credit_card')}</option>
                      <option value={'2'}>{t('profile.debit_card')}</option>
                      <option value={'3'}>{t('profile.prepaid_card')}</option>
                      <option value={'4'}>{t('profile.visa')}</option>
                      <option value={'5'}>{t('profile.master_card')}</option>
                      <option value={'6'}>{t('profile.discover')}</option>
                      <option value={'7'}>{t('profile.e_wallet')}</option>
                    </select>
                    <br />
                    <button type="button" class="btn btn-primary" style={{ backgroundColor: '#1e98c9' }}>{t('save')}</button>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  )
}
