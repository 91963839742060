import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { auth } from "../../../../models";
import notistack from "../../../../plugin/notistack";
import useValidator from "../../../../plugin/validator";

const format={
  old_password: '',
  password: '',
  confirm_password: '',
}

function ChangePassword() {
  const { t } = useTranslation();
  const [data, setData] = useState(format);
  const [validator, showMessage] = useValidator();
  // const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const error = {
    old_password: validator.message(t("auth.old_password"), data.old_password, 'required|password|max:200|'),
    password: validator.message(t("profile.new_password"), data.password, `required|password|max:200|not_in:${data.old_password}`),
    password2: validator.message(t("profile.new_password_check"), data.password, `not_in:${data.old_password}`),
    confirm_password: validator.message(t("auth.confirm_password"), data.confirm_password, `required|password|max:200|in:${data.password}`),
    confirm_password2: validator.message(t("auth.confirm_password"), data.confirm_password, `in:${data.password}`),
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };
  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }
  const handleSubmit = (event) => {
    event.preventDefault();
    if (validator.allValid()) {
      auth.changePassword(data).then((item) => {
        notistack.success(item?.message);
        showMessage(false);
        setData(format);
      }).catch((error) => {
        notistack.error(error?.data.message);
        console.log(error);
      });
    }
    else {
      showMessage(true);
    }
  }
  return (
    <>
      <div className='pt-20'>
        <div className='container'>
          <div className='row justify-content-center'>
            <div className='col-lg-7'>
              <div className='package-sidebar'>
                <aside className='package-widget-style-2 widget-form'>
                  <div className='widget-title text-center d-flex justify-content-between'>
                    <h4>{t('profile.change_password')}</h4>
                  </div>
                  <div className='widget-body'>
                    <form onSubmit={(e) => handleSubmit(e)} >
                      <div className='booking-form-wrapper'>
                        <div className='custom-input-group'>
                          <input
                            type='text'
                            id='old_password'
                            name='old_password'
                            value={data.old_password}
                            placeholder={t("auth.old_password")}
                            className={!!error.old_password ? 'is-invalid' : ''}
                            onChange={handleChange}
                          />
                          <div className='invalid-feedback'>{error.old_password}</div>
                        </div>
                        <div className='custom-input-group'>
                          <input
                            type='text'
                            id='password'
                            name='password'
                            value={data.password}
                            placeholder={t("profile.new_password")}
                            className={!!error.password ? 'is-invalid' : ''}
                            onChange={handleChange}
                          />
                          <div className='invalid-feedback'>{error.password2?t("profile.new_password_check"):error.password}</div>
                        </div>
                        <div className='custom-input-group'>
                          <input
                            type='text'
                            id='confirm_password'
                            name='confirm_password'
                            value={data.confirm_password}
                            placeholder={t("auth.confirm_password")}
                            className={!!error.confirm_password ? 'is-invalid' : ''}
                            onChange={handleChange}
                          />
                          <div className='invalid-feedback'>{error.confirm_password2?t("form.Passwords_are_not_same"):error.confirm_password}</div>
                        </div>
                        <div className='custom-input-group'>
                          <div className='submite-btn d-flex'>
                            <button type='submit' className='button-fill-round' > {t('save')}</button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </aside>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ChangePassword;
