import axios from '../plugin/axios';

const request = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Luggage/AvailableLuggage')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    allData(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Request/GetAllRequestReceived', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    allRequestSend(id) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('Request/GetAllRequestSend', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createArticle(data, id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Request/BuyArticle/' + id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createLuggage(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Request/LuggageRequestToTravel', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createTraveller(data, id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Request/TravelRequestToLuggageSender/' + id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createArticleDonar(data, id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Request/SendRequestToArticleDonar/' + id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    respondToRequestReceived(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Request/RespondToRequestReceived', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Request/GetRequestSend/` + id, type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getRequestReceived(id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Request/GetRequestReceived/` + id, type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    createRequestBuyArticle(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Request/GetRequestReceived`, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Luggage/Put', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('Luggage/Delete', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    valid(id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('Request/ValidatePost/' + id, type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    cancle(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/Cancel', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    uploadSignature(id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/UploadTravelerSignature/' + 'true/' + id + '/' + type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    StatusReject(type, id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get(`Request/GetStatus/${type}/${id}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    otpVerify(otp, id, type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/DeliveryOtpVerify/' + otp + '/' + id + '/' + type)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    resendOtp(request_id, request_type) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post(`Request/ResendOtp/${request_id}/${request_type}`)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    soldItem(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/SoldItem', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    confirmDelivery(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/ConfirmDelivery/', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    raisedisputeData(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Request/RaiseDisputed', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
};
export default request;
