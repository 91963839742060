import React from 'react'
import HowWorkWrap from './HowWorkWrap'
import BradcomeHowWork from '../../common/BradcomeHowWork'
import { useTranslation } from 'react-i18next'

export default function HowWork() {
    const { t } = useTranslation()
    return (
        <div>
            <BradcomeHowWork
                name={t('works.title')}
            />
            <HowWorkWrap />
        </div>
    )
}
