import React from 'react'
import Breadcrumb from "../../common/Breadcrumb";
// import { useTranslation } from "react-i18next";
import PaymentDoneDetails from './paymentDoneDetails';
import { useTranslation } from "react-i18next";
export default function PaymentDone() {
    const { t } = useTranslation();
    // const { t } = useTranslation();
    return (
        <div>
            <Breadcrumb name={t('profile.Payments')}/>
            <PaymentDoneDetails/>
        </div>
    )
}
