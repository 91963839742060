import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Form } from 'react-bootstrap';
import request from '../../../models/request';
import notistack from '../../../plugin/notistack';
import { t } from 'i18next';
import luggage from '../../../models/luggage';
import error from '../../../lang/enUS/error';
import AppContext from '../../../store/context/app-context';
import Loading from '../../common/Loading';
import travel from '../../../models/travel';

export default function TravllerData(props) {
    const contextObj = useContext(AppContext);
    const [decodedString, setdecodedString] = useState("");
    const [load, setLoad] = useState(false);

    useEffect(() => {
        convert();
    }, []);

    const backToPage = () => {
        props.setHide(1);
    }

    const convert = () => {
        const response = fetch('https://cobagage.com/uploads/article/a3qk2bet.jpeg');
        let blob = '';
        response.then((response) => {
            blob = response.blob();
            blob.then((blob) => {
                let reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = () => {
                    let baseURL = reader.result;
                    setdecodedString(baseURL);
                };
            });
        });
    }

    const handleSubmit = () => {
        setLoad(true)



        const formData = new FormData();
        formData.append('title', props?.travelIdData?.title);
        formData.append('luggage_id', props?.allData?.id);
        formData.append('pickup_place', props?.travelIdData?.pickup_place);
        formData.append('delivery_place', props?.travelIdData?.delivery_place);
        formData.append('departure_date', props?.travelIdData?.departure_date);
        formData.append('return_departure_date', props?.travelIdData?.return_departure_date);
        formData.append('arrival_date', props?.travelIdData?.arrival_date);
        formData.append('return_arrival_date', props?.travelIdData?.return_arrival_date);
        formData.append('departure_time', props?.travelIdData?.departure_time);
        formData.append('return_departure_time', props?.travelIdData?.return_departure_time);
        formData.append('arrival_time', props?.travelIdData?.arrival_time);
        formData.append('return_arrival_time', props?.travelIdData?.return_arrival_time);
        formData.append('carriage_capacity', props?.travelIdData?.carriage_capacity);
        formData.append('price_per_kg', props?.travelIdData?.price_per_kg);
        formData.append('trip_type', props?.travelIdData?.trip_type);
        formData.append('vehicle_type', props?.travelIdData?.vehicle_type);
        formData.append('vehicle_details', props?.travelIdData?.vehicle_details);
        formData.append('packing_instruction', props?.travelIdData?.packing_instruction);
        formData.append('description', props?.travelIdData?.description);
        formData.append('terms_and_condition', props?.travelIdData?.terms_and_condition);
        formData.append('stopover_in_trip', props?.travelIdData?.stopover_in_trip);
        formData.append('country_stopover', props?.travelIdData?.country_stopover);
        formData.append('state_stopover', props?.travelIdData?.state_stopover);
        formData.append('city_stopover', props?.travelIdData?.city_stopover);
        formData.append('insurance', props?.travelIdData?.insurance);
        formData.append('sender_signature', contextObj?.requestedTraveller?.sender_signature);
        request.createTraveller(contextObj?.requestedTraveller.luggage_id ? (contextObj?.requestedTraveller) : (formData), contextObj?.requestedTravellerprops?.luggage_id ? (contextObj?.requestedTravellerprops?.luggage_id) : (props?.allData?.id)).then((respons) => {
            if (!props.travelIdData) {
                travel.create(contextObj?.requestedTraveller).then((respons) => {
                    travel.valid(respons.id).then((resp) => {
                    }).catch((error) => {
                        console.log("requestTravller", error);
                    })
                })
            }
            setLoad(false)
            notistack.success(t("form.Request_successfully"))
            props.setHide(3);
        })
            .catch(error => {
                setLoad(false)
                notistack.error(error?.data.message);
            })

    }
    return (
        <>
            {load ? <Loading /> :
                <>
                    <div class="p-4">
                        <div className="row">
                            <div className='col-lg-12'>
                                {t('form.you_are_about_send_travel_plan_to_luggage_sender_shipper')} {''} "{props.allData.title}" {''} {t('form.you_are_about_send_travel_plan_to_luggage_sender_shipper1')}
                            </div>
                            <div className="col-lg-12">
                                <div className="row">
                                    <div class="table-responsive col-lg-7 col-md-8 col-12 pb-3">
                                        <div class='earning pt-3'>
                                            <div style={{ background: 'white' }}>
                                                <div className='col-12 row'>
                                                    <div className="col-4 d-flex">
                                                        <div className="col-3">
                                                            <img
                                                                src={props.allData?.departure_flag}
                                                                style={{ aspectRatio: "4/2", objectFit: "contain", width: "70%", height: "60%", marginTop: "-7px" }}
                                                            ></img>
                                                        </div>
                                                        <div className="col-8">
                                                            <h6 style={{ color: "black" }}>{props.allData?.city_departure_name}</h6>
                                                        </div>
                                                    </div>
                                                    <div className="col-2"><h5 style={{ color: "black" }}><i class="bi bi-arrow-right" ></i></h5></div>
                                                    <div className="col-5 d-flex ">
                                                        <div className="col-3">
                                                            <img
                                                                src={props.allData?.arrival_flag}
                                                                style={{ aspectRatio: "4/2", objectFit: "contain", width: "70%", height: "60%", marginTop: "-7px" }}
                                                            ></img>
                                                        </div>
                                                        <div className="col-8"><h6 style={{ color: "black" }}>{props.allData?.city_arrival_name}</h6></div>
                                                    </div>
                                                </div>
                                                <table class="table">
                                                    <tbody class='border-0'>
                                                        <tr class='border-0'>
                                                            <td class='border-0 fw-normal ps-0'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{contextObj?.requestedTraveller?.departure_date || props.travelIdData?.departure_date} |{props.requestData?.departure_time || props.travelIdData?.departure_time} hrs</td>
                                                            <td class='border-0'></td>
                                                            <td class='border-0 fw-normal ps-0'><i class="bi bi-calendar" style={{ color: '#f39c12' }} />{' '}{contextObj?.requestedTraveller?.arrival_date || props?.travelIdData?.arrival_date} | {props.requestData?.arrival_time || props.travelIdData?.arrival_time} hrs</td>
                                                        </tr>
                                                        <tr class='border-0'>
                                                            <td class='border-0 fw-normal ps-0'>{' '}{t('profile.departure')}</td>
                                                            <td class='border-0'></td>
                                                            <td class='border-0 fw-normal ps-0'>{' '}{t('profile.arrival')}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                <div className='row'>
                                                    <div className='col-12 col-md-6'>
                                                        {props?.travelIdData?.vehicle_type ?
                                                            <img
                                                                src={
                                                                    props?.travelIdData?.vehicle_type == 1 ? "/images/vehicles/Bike.jpg" : props?.travelIdData?.vehicle_type == 2 ? "/images/vehicles/boat.jpg" : props?.travelIdData?.vehicle_type == 3 ? "/images/vehicles/bus.png" : props?.travelIdData?.vehicle_type == 4 ? "/images/vehicles/car.png" : props?.travelIdData?.vehicle_type == 5 ? "/images/vehicles/kickscooter.jpg" : props?.travelIdData?.vehicle_type == 6 ? "/images/vehicles/train.png" : props?.travelIdData?.vehicle_type == 7 ? "/images/vehicles/MoterBike.png" : props?.travelIdData?.vehicle_type == 8 ? "/images/vehicles/plan.jpg" : props?.travelIdData?.vehicle_type == 9 ? "/images/vehicles/scooter.jpg" : props?.travelIdData?.vehicle_type == 10 ? "/images/vehicles/Tvg.png" : props?.travelIdData?.vehicle_type == 11 ? "/images/vehicles/Tramway.jpg" : ""
                                                                }
                                                                height="100px"
                                                            /> :
                                                            <img
                                                                src={
                                                                    contextObj?.requestedTraveller?.vehicle_type == 1 ? "/images/vehicles/Bike.jpg" : contextObj?.requestedTraveller?.vehicle_type == 2 ? "/images/vehicles/boat.jpg" : contextObj?.requestedTraveller?.vehicle_type == 3 ? "/images/vehicles/bus.png" : contextObj?.requestedTraveller?.vehicle_type == 4 ? "/images/vehicles/car.png" : contextObj?.requestedTraveller?.vehicle_type == 5 ? "/images/vehicles/kickscooter.jpg" : contextObj?.requestedTraveller?.vehicle_type == 6 ? "/images/vehicles/train.png" : contextObj?.requestedTraveller?.vehicle_type == 7 ? "/images/vehicles/MoterBike.png" : contextObj?.requestedTraveller?.vehicle_type == 8 ? "/images/vehicles/plan.jpg" : contextObj?.requestedTraveller?.vehicle_type == 9 ? "/images/vehicles/scooter.jpg" : contextObj?.requestedTraveller?.vehicle_type == 10 ? "/images/vehicles/Tvg.png" : contextObj?.requestedTraveller?.vehicle_type == 11 ? "/images/vehicles/Tramway.jpg" : ""
                                                                }
                                                                height="100px"
                                                            />
                                                        }
                                                    </div>
                                                    <div className='col-12 col-md-6 d-flex  flex-column justify-content-center ps-0'>
                                                        <h6 className='fs-6' style={{ color: 'black' }}>{t('profile.travelling_by')}:</h6>
                                                        <h5 className='fs-4' style={{ color: 'black' }}>{contextObj?.requestedTraveller?.vehicle_details || props.travelIdData?.vehicle_details}</h5>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div >
                                        <div class="table-responsive">
                                            <table class="table table-borderless">
                                                <tbody class='border-0'>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.trip_type')}:</td>
                                                        <td class='border-0 fw-bold'>{(contextObj?.requestedTraveller?.trip_type || props?.travelIdData?.trip_type) == 1 ? t('form.one_way') : t('form.Round_Way')}</td>
                                                    </tr>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.Weight_carriage_capacity')}:</td>
                                                        <td class='border-0 fw-bold'>{contextObj?.requestedTraveller?.carriage_capacity || props?.travelIdData?.carriage_capacity}</td>
                                                    </tr>
                                                </tbody>
                                                <tbody class='border-0'>
                                                    <tr class='border-0'>
                                                        <td class='border-0 '>{t('form.Price_arry_weight')}:</td>
                                                        <td class='border-0 fw-bold'>{contextObj?.requestedTraveller?.price_per_kg || props?.travelIdData?.price_per_kg} {contextObj?.loginUser?.symbol}/kg</td>
                                                    </tr>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.planned_middle_trip')}</td>
                                                        <td class='border-0 fw-bold'>{!(contextObj?.requestedTraveller?.stopover_in_trip || props?.travelIdData?.stopover_in_trip) ? t('form.no') : t('form.yes')}</td>
                                                    </tr>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.Luggage_collection_place')}</td>
                                                        <td class='border-0 fw-bold'>{contextObj?.requestedTraveller?.pickup_place || props?.travelIdData?.pickup_place}</td>
                                                    </tr>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.Luggage_drop_place')}</td>
                                                        <td class='border-0 fw-bold'>{contextObj?.requestedTraveller?.delivery_place || props?.travelIdData?.delivery_place}</td>
                                                    </tr>
                                                    <tr class='border-0'>
                                                        <td class='border-0 fw-normal'>{t('form.Does_benefit_travel_insurance')}</td>
                                                        <td class='border-0 fw-bold'>{!(contextObj?.requestedTraveller?.insurance || props?.travelIdData?.insurance) ? t('form.no') : t('form.yes')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-12'>
                                <h4 style={{ textDecorationLine: 'underline' }}>
                                    {t('form.Your_luggage_instructions')}:
                                </h4>
                                <p>{contextObj?.requestedTraveller?.packing_instruction || props?.travelIdData?.packing_instruction}</p>
                            </div>
                            <div className='col-lg-12 pt-2'>

                            </div>
                            <div className='row pb-3'>
                                <h4 style={{ textDecorationLine: 'underline' }}>{t('form.Description')}:</h4>
                                <p>{contextObj?.requestedTraveller?.description || props?.travelIdData?.description}</p>
                            </div>
                            <div className='col-lg-6 pt-4'>
                                <Button style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', margin: '2px' }}
                                    onClick={backToPage}
                                >
                                    <i class="bi bi-arrow-left-circle" />{' '} {t("form.edit_luggage_details")}
                                </Button>
                            </div>
                            <div className='col-lg-6 pt-4'>
                                <Button style={{ backgroundColor: '#ff4838', borderColor: '#ff4838', borderRadius: '5px', margin: '2px' }}
                                    onClick={handleSubmit}
                                >
                                    {t("form.validate_send_request")}
                                </Button>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}
