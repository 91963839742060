import React from "react";
import Submit from "./Submit";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";

function SubmitDetails() {
    const { t } = useTranslation();
    const { id, type } = useParams();
    return (
        <>
            <Breadcrumb name={type == 0 ? t('form.Travel_Details') : type == 1 ? t('form.Cobagage_Announcements') : type == 2 ? t('form.Cobagage_Sale') : type == 3 ? t('form.Donation_page') : ""} />
            <Submit />
        </>
    );
}

export default SubmitDetails;