import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from "react-i18next";

function TipsAndAdviceWrapperArea() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="col-12 mt-5 mb-5">
        <h3 className="align-item-center">{t("menu.Tips_And_AdviceText1")}
          <span style={{fontWeight:'bold'}}>{t("menu.Tips_And_AdviceText2")}</span></h3>
      </div>
      <div className="col-12 row mt-2 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6 mt-sm-2">
          <img
            src={"/images/vehicles/u53.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "50%", height: "180px" }}
          >
          </img>
          <img
            src={"/images/vehicles/u55.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "50%", height: "180px" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6">
          <h4 className="pt-2">{t("menu.Tips_And_AdviceText3")}</h4>
          <p className="pt-4">{t("menu.Tips_And_AdviceText4")}</p>
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6">
          <h2 className="pt-2">{t("menu.Tips_And_AdviceText5")}</h2>
          <p className="pt-4">{t("menu.Tips_And_AdviceText6")}</p>
  
        </div>
        <div className="col-12 col-lg-6">
          <img
          className="mt-sm-2"
            src={"/images/vehicles/p1.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/p2.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="d-flex justify-content-center">{t("menu.Tips_And_AdviceText7")}</h2>
          <p className="pt-4">{t("menu.Tips_And_AdviceText8")}</p>
          
          
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6">
          <h3 className="d-flex justify-content-center align-items-center">{t("menu.Tips_And_AdviceText9")}</h3>
          <p className="pt-4">{t("menu.Tips_And_AdviceText10")}</p>
         
        </div>
        <div className="col-12 col-lg-6">
          <img
          className="mt-sm-2"
            src={"/images/vehicles/p5.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6">
          <img
          className="mt-sm-2"
            src={"/images/vehicles/p6.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="d-flex justify-content-center">{t("menu.Tips_And_AdviceText11")}</h2>
          <p className="pt-4">{t("menu.Tips_And_AdviceText12")}<span style={{fontWeight:'bold'}}>{t("menu.Tips_And_AdviceText13")}</span>{t("menu.Tips_And_AdviceText14")}</p>
          
          
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6">
          <h3 className="d-flex justify-content-center align-items-center">{t("menu.Tips_And_AdviceText15")}</h3>
          <p className="pt-4">{t("menu.Tips_And_AdviceText16")}</p>
         
        </div>
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/p7.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/p8.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="d-flex justify-content-center">{t("menu.DELIVERY")}</h2>
          <p className="pt-4">{t("menu.Tips_And_AdviceText17")}</p>
          
          
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6">
          <h3 className="d-flex justify-content-center align-items-center">{t("menu.Tips_And_AdviceText18")}</h3>
          <p className="pt-4">{t("menu.Tips_And_AdviceText19")}</p>
         
        </div>
        <div className="col-12 col-lg-6">
          <img
          className="mt-sm-2"
            src={"/images/vehicles/p9.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6">
          <img
          className="mt-sm-2"
            src={"/images/vehicles/subplan.jpg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6">
          <h2 className="d-flex justify-content-center">{t("menu.Tips_And_AdviceText20")}</h2>
          <p className="pt-4">{t("menu.Tips_And_AdviceText21")}</p>
          <p className="pt-4">{t("menu.Tips_And_AdviceText22")}<span style={{fontWeight:'bold'}}>{t("menu.Tips_And_AdviceText23")}</span>{t("menu.Tips_And_AdviceText24")}</p>
          
          
        </div>
      </div>

      <h2 className="text-center mt-4">{t('menu.Finally_on_Cobagage_you_can')}</h2>
      <div className="col-12 col-lg-12 row mt-3 mb-5 justify-content-around text-center" style={{marginLeft:'1px'}}>
        <div className="col-12 col-lg-3 col-sm-3 mt-2" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10,}}>
          <img
            src={"/images/vehicles/u53.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "55%", padding: "20px" }}
          />
          <p className="d-flex justify-content-center pb-2" style={{fontWeight:'bold'}}>{t("menu.finallyText1")}</p>
        </div>
        <div className="col-12 col-lg-3 col-sm-3 mt-2" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10,}}>
          <img
            src={"/images/vehicles/u55.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "55%", padding: "20px" }}
          />
          <p className="d-flex justify-content-center pb-2" style={{fontWeight:'bold'}}>{t("menu.finallyText2")}</p>
        </div>
        <div className="col-12 col-lg-3 col-sm-3 mt-2" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10,}}>
          <img
            src={"/images/vehicles/u57.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "55%", padding: "20px" }}
          />
          <p className="d-flex justify-content-center pb-2" style={{fontWeight:'bold'}}>{t("menu.finallyText3")}</p>
        </div>
      </div>
      
      <div className="col-12 mt-5 mb-5">
        <h2 className="text-center">{t('menu.Excellent_transactions_Cobagage_website')}</h2>
       
      </div>
      
      
    </div>
  );
}

export default TipsAndAdviceWrapperArea;
