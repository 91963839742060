import React from "react";
import TipsAndAdviceWrapperArea from "./TipsAndAdviceWrapperArea";
import AboutPage from "../../common/AboutPage";
import { useTranslation } from "react-i18next";
function TipsAndAdvice() {
  const { t } = useTranslation();
  return (
    <>
      <AboutPage name={t("menu.Tips_And_Advice")} />
      <TipsAndAdviceWrapperArea />
  
    </>
  );
}

export default TipsAndAdvice;
