import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'

export default function RatingComp(props) {
    return (
        <div style={{ paddingLeft: "20px", marginTop: "-4px" }}>
            <Rating
                initialValue={props?.rate}
                size={18}
                readonly
            />
        </div>
    )
}
