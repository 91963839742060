import axios from '../plugin/axios';
import notistack from '../plugin/notistack';
const ID_TOKEN_KEY = 'tokens';

const auth = {
    login(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/Login', data)
                .then(({ data }) => {
                    this.saveToken(data.token);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    otpVerified(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/OtpVerified', data)
                .then(({ data }) => {
                    this.saveToken(data.token);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    resendOtp(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/ResendOtp', data)
                .then(({ data }) => {
                    this.saveToken(data.token);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getUser() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Auth/User')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    logout() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('Auth/Logout')
                .then(({ data }) => {
                    notistack.success(data.message);
                    this.destroyToken();
                    resolve(data);
                })
                .catch(({ response }) => {
                    this.destroyToken();
                    reject(response);
                });
        });
    },
    forgotPassword(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/ForgotPassword', data)
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    notistack.error(response.data.message);
                    reject(response);
                });
        });
    },
    resetPassword(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/ResetPassword', data)
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    resend() {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('resend')
                .then(({ data }) => {
                    notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    verify(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('auths/emailVerify', data)
                .then(({ data }) => {
                    // notistack.success(data.message);
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    profile(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Auth/ProfileUpdate', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch((response) => {
                    reject(response);
                });
        });
    },
    userProfileUpdate(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .put('Auth/UserProfileUpdate', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log("response", response);
                    reject(response);
                });
        });
    },
    changePassword(data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .post('Auth/ChangePassword', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    getToken() {
        return window.localStorage.getItem(ID_TOKEN_KEY);
    },
    saveToken(token) {
        window.localStorage.setItem(ID_TOKEN_KEY, token);
    },
    destroyToken() {
        window.localStorage.removeItem(ID_TOKEN_KEY);
        window.localStorage.removeItem('sessionId')
        window.localStorage.removeItem('paymentId')
        window.localStorage.removeItem('subscription_id')
        window.sessionStorage.removeItem('firstPage');
        window.sessionStorage.removeItem("contact_number");
        window.sessionStorage.removeItem("email");
    },
};
export default auth;