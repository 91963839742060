import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link, useHistory } from "react-router-dom";
import { Button, Modal, Form } from "react-bootstrap";
import request from "../../../../models/request";
import notistack from "../../../../plugin/notistack";
import viewCount from "../../../../models/viewCount";
import AppContext from "../../../../store/context/app-context";
import { BiWorld } from "react-icons/bi";

export default function RecentArticleCard(props) {
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const { t } = useTranslation();
  const [data, setData] = useState({
    article_id: props.item.id,
    article_quantity: props.item.quantity,
    requester_location: props.item.location,
  });
  const contextObj = useContext(AppContext);
  const [show, setShow] = useState(false);
  const [componentKey, setComponentKey] = useState(0);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [details, setDetails] = useState();
  const history = useHistory();
  useEffect(() => {
    getData();
  }, [history, data.article_id]);
  const handleSubmit = () => {
    request.createRequestBuyArticle(data).then((item) => {
      notistack.success(t("form.Request_successfully"));
    });
  };

  const openpop = () => handleShow();

  const getData = () => {
    viewCount
      .get(props.item.id, 2)
      .then((item) => {
        setDetails(item);
      })
      .catch((error) => {
        console.log("error", error);
      });
  };
  const createViews = () => {
    viewCount
      .create(props.item.id, 2)
      .then((item) => {
        // setDetails(item);
        getData();
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  const navigateToDetails = () => {
    const newPath = `${process.env.PUBLIC_URL}/ad_details/${props?.item?.id}/${props?.data == "articleSale" ? "6" : "7"
      }`;
    history.push(newPath);
    createViews();
    scrollTop();
    setComponentKey((prevKey) => prevKey + 1);
  };

  return (
    <div
      key={componentKey}
      className="package-card-delta"
      style={{ cursor: "pointer" }}
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        history.push(
          contextObj.login == true
            ? `${process.env.PUBLIC_URL}/ad_details/${props?.item?.id}/${props?.data == "articleSale" ? "6" : "7"
            }`
            : "/login"
        );
      }}
    >
      <div className="package-thumb">
        <div className="guide-card-beta">
          <div className="guide-image">
            {/* <Link to={contextObj.login == true ? `${process.env.PUBLIC_URL}/ad_details/${props?.item?.id}/${props?.data == 'articleSale' ? '6' : '7'}` : "/login"}> */}
            <img
              src={props.item.images[0]}
              alt=""
              onClick={() => createViews()}
              style={{
                aspectRatio: "3/2",
                objectFit: "contain",
                padding: "15px",
                borderRadius: "20px",
              }}
            />
            {/* </Link> */}
            <p
              className="card-lavel me-2"
              style={{
                fontSize: "10px",
                textAlign: "end",
                minWidth: "10px",
                borderRadius: "10px",
                padding: "8px",
              }}
            >
              {props.item.verification == true
                ? t("cobagage.verified")
                : t("cobagage.unverified")}
            </p>
          </div>
        </div>
      </div>
      <div className="package-card-body p-2">
        <div class="icons_container">
          {!props?.item?.price && (
            <h5 className="fw-bold icons_container icon facebook">
              {props?.item?.title?.length > 15
                ? props?.item?.title.slice(0, 15).trimEnd() + "..."
                : props?.item?.title}
              <div class="tooltip">{props?.item?.title}</div>
            </h5>
          )}
          <table
            className="col-lg-12 col-12 col-md-12 col-sm-12 icons_container"
            style={{ fontWeight: "700" }}
          >
            <tr>
              {props?.item?.price && (
                <td className="icons_container icon facebook">
                  {props?.item?.title?.length > 15
                    ? props?.item?.title.slice(0, 15).trimEnd() + "..."
                    : props?.item?.title}
                  <div class="tooltip">{props?.item?.title}</div>
                </td>
              )}
              {/* {props?.item?.price && <td ></td>} */}
              {props?.item?.price && (
                <td
                  class="text-end"
                  style={{
                    position: "absolute",
                    right: "20px",
                    backgroundColor: "rgb(243, 156, 18)",
                    borderRadius: "7px",
                    padding: "5px",
                  }}
                >
                  {" "}
                  {props?.item?.currency} {props?.item?.price}
                </td>
              )}
            </tr>
            <tr>
              <td style={{ fontSize: "12px" }}>
                <i
                  class="bi bi-basket3-fill"
                  style={{ fontSize: "14px", fontWeight: "700" }}
                />{" "}
                {t("form.Remaining_Qty")}: {props?.item?.remaining_quantity}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "12px" }}>
                <BiWorld style={{ fontSize: "14px", color: "red" }} />
                {" "}
                {props?.item?.country_name}
              </td>
            </tr>
            <tr>
              <td style={{ fontSize: "12px" }}>
                <i
                  class="bi bi-geo-alt-fill "
                  style={{ fontSize: "14px", fontWeight: "700", color: "red" }}
                />{" "}
                {props?.item?.location}
              </td>
            </tr>
            <hr className="m-0" style={{ color: "#f35312", height: "2px" }} />
            <tr>
              <td style={{ fontSize: "12px" }}>
                <i class="bi bi-eye-fill" /> {t("form.Views")} :{" "}
                <span style={{ color: "red" }}>{details?.totalcount}</span>
              </td>
            </tr>
          </table>

          {/* {props?.hide === true ? "" :
                        <div className='d-flex gap-1'> */}
          {/* {(props?.data === "articlesOnSale1" || props?.data == 'recentArticleDonation' || props?.data == 'donationData1' || props?.data == 'articleSale') &&
                                <div className="p-card-bottom mt-1">
                                    <div
                                        className="book-btn"
                                    >
                                        <Link
                                            to={contextObj.login == true ? `${process.env.PUBLIC_URL}/ad_details/${props?.item?.id}/${props?.data == 'articleSale' ? '6' : '7'}` : "/login"}
                                            style={{ fontSize: '11px', fontWeight: '600' }}
                                        >
                                            {t('form.view_details')}{' '}<i className="bx bxs-right-arrow-alt" />
                                        </Link>
                                    </div>
                                </div>
                            } */}
          {/* {(
                        props.data === "donationData1" &&
                        <div className=''>
                            <button
                                className="nav-link active"
                                type="button"
                                // onClick={() => addTraveller()}
                                style={{
                                    borderRadius: '30px',
                                    color: '#fff',
                                    border: '1px solid purple',
                                    backgroundColor: 'purple',
                                    padding: '5px 25px',
                                    height: "38px"
                                    // boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'
                                }}
                            >
                                {t("Donation")}
                            </button>
                        </div>
                    )} */}
          {/* </div> */}
          {/* } */}

          <div
            className="custom-input-group mt-1"
            style={{ display: "flex", justifyContent: "center" }}
          >
            <div className="submite-btn">
              {/* {(props.data === "articlesOnSale" || props.data === "donationData") &&
                                <button type="submit"
                                    style={{ padding: '8px 35px' }}
                                    onClick={openpop}
                                >
                                    {props.data === "articlesOnSale" ? t("form.request_buy") : t("form.Request_get_item")}
                                </button>} */}
              <Modal show={show} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                  <Modal.Title>Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <label>
                      You are about to send buying request to{" "}
                      {props?.item.user_name} for buying the following
                    </label>
                  </div>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <label>
                      {props?.item.title} bags worth {props.item.price}€
                    </label>
                  </div>
                </Modal.Body>
                <Modal.Footer className="justify-content-center">
                  <Button onClick={handleSubmit}>{t("send request")}</Button>
                </Modal.Footer>
              </Modal>
            </div>
          </div>

          {
            <div className="p-card-bottom" style={{ marginTop: "0px" }}>
              <div className="book-btn">
                <Link
                  to={
                    contextObj.login == true
                      ? `${process.env.PUBLIC_URL}/ad_details/${props?.item?.id
                      }/${props?.data == "articleSale" ? "6" : "7"}`
                      : "/login"
                  }
                  style={{
                    fontSize: "7px",
                    fontWeight: "600",
                    borderRadius: "5px",
                  }}
                  onClick={() => {
                    if (contextObj.login == true) {
                      createViews();
                      scrollTop();
                    } else {
                      scrollTop();
                    }
                    setComponentKey((prevKey) => prevKey + 1);
                  }}
                //   onClick={navigateToDetails}
                >
                  {props?.data == "articleSale"
                    ? t("profile.VIEW_ARTICLE_DETAILS")
                    : t("profile.VIEW_ARTICLE_DONATION_DETAILS")}{" "}
                  <i className="bx bxs-right-arrow-alt" />
                </Link>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
}
