import React from "react";
import LuggageRequestWrapperArea from "./LuggageRequestWrapperArea";
import Breadcrumb from "../../../../common/Breadcrumb";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
function LuggageRequest() {
  const { t } = useTranslation();
  const { request_type} = useParams();
  return (
    <>
      <Breadcrumb name={request_type==1?t('profile.luggage_carrying_request1'):t('profile.luggage_carrying_request2')}/>
      <LuggageRequestWrapperArea />
    </>
  );
}

export default LuggageRequest;
