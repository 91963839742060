import common from './common';
import auth from './auth';
import menu from './menu';
import error from './error';
import homePage from './homePage';
import form from './form';
import cobagage from './cobagage';
import profile from './profile';
import works from './works';
import privacyPolicy from './privacyPolicy';
import cgvu from './cgvu';
const enUS = {
    ...common,
    ...{
        auth,
        error,
        menu,
        homePage,
        form,
        cobagage,
        profile,
        works,
        privacyPolicy,
        cgvu
        
    }
}
export default enUS;