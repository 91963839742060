import React from 'react'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom'
function BlogArea3() {
  const { t } = useTranslation();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" })
  return (
    <>
      <div className="blog-area blog-style-two pb-110" style={{ background: '#f4f4f4',}}>
        <div className="container" >
          <div className="row d-flex justify-content-center align-items-center">
            <div className="col-lg-6 col-sm-10">
              <div className="section-head-alpha" style={{marginTop:'20px'}}>
                <h2>{t("homePage.why_choosing_us")}</h2>
              </div>
            </div>
            <div className="col-lg-6 col-sm-10">
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="blog-card-beta" style={{ height: "450px" }}>
                <div className="">
                  {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}> */}
                  <img
                    style={{ width: "100%", height: "242px" }}
                    src={process.env.PUBLIC_URL + "images/blog/home3-blog1.jpg"} alt=""
                  />
                  {/* </Link> */}
                  {/* <div className="blog-lavel">
                    <Link to={"#"}>{t("homePage.read_more")}</Link>
                  </div> */}
                </div>
                <div className="blog-content">
                  <h4>{t("homePage.international_shipment")}</h4><br />
                  <div style={{ height: '80px', overflow: 'hidden' }}>
                    <h6 className="">
                      {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}></Link> */}
                      {t('form.Faire_voyageurs')}
                    </h6>
                  </div>
                  <p className='pt-1' style={{ textDecoration: "underline", color: "blue", display: "flex", justifyContent: "end", fontStyle: "italic" }}><Link to={'/internationl'}onClick={scrollTop}>{t("homePage.read_more")}</Link></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mb-4 mb-lg-0">
              <div className="blog-card-beta" style={{ height: "450px" }}>
                <div className="">
                  {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}> */}
                  <img
                    style={{ width: "100%", height: "243px" }}
                    src={process.env.PUBLIC_URL + "/images/blog/home3-blog3.jpg"} alt="" />
                  {/* </Link> */}
                </div>
                <div className="blog-content">
                  <div className="blog-body-top">
                  </div>
                  <h4>{t("homePage.quick_or_secure_payment")}</h4><br />
                  <div style={{ height: '80px', overflow: 'hidden' }}>
                    <h6 className="">
                      {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}>
                  </Link> */}
                      {t('form.En_transactions')}
                    </h6>
                  </div>
                  <p className='pt-1' style={{ textDecoration: "underline", color: "blue", display: "flex", justifyContent: "end", fontStyle: "italic" }}><Link to={'/Payment'} onClick={scrollTop}>{t("homePage.read_more")}</Link></p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 mt-lg-0 mt-sm-3">
              <div className="blog-card-beta" style={{ height: "450px" }}>
                <div className="">
                  {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}> */}
                  <img
                    style={{ width: "100%", height: "243px" }}
                    src={process.env.PUBLIC_URL + "/images/blog/home3-blog2.jpg"} alt="" />
                  {/* </Link> */}
                </div>
                <div className="blog-content">
                  <div className="blog-body-top">
                  </div>
                  <h4>{t("homePage.eco_responsive")}</h4><br />
                  <div style={{ height: '80px', overflow: 'hidden' }}>
                    <h6 className="">
                      {/* <Link to={`${process.env.PUBLIC_URL}/blog-details`} onClick={scrollTop}>
                    </Link> */}
                      {t('form.En_cobagageur')}</h6>
                  </div>
                  <p className='pt-1' style={{ textDecoration: "underline", color: "blue", display: "flex", justifyContent: "end", fontStyle: "italic" }}><Link to={'/ecoresponsive'} onClick={scrollTop}>{t("homePage.read_more")}</Link></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default BlogArea3