import axios from '../plugin/axios';
const ID_TOKEN_KEY = 'tokens';

const user = {
    list() {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .get('User/GetUsers')
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    create(data) {
        return new Promise((resolve, reject) => {
            axios.setLanguage();
            axios
                .post('Auth/Registration', data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    get(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .get('User/Get', id)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    update(id, data) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .update('/User/Put', id, data)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            axios.setToken();
            axios
                .delete('User/Delete', id)
                .then(({ data }) => {
                    this.destroyToken();
                    resolve(data);
                })
                .catch(({ response }) => {
                    reject(response);
                });
        });
    },
    destroyToken() {
        window.localStorage.removeItem(ID_TOKEN_KEY);
    },
};
export default user;
