import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { auth } from "../../../../models";
import UpgradeMemberShip from "./UpgradeMemberShip";
import AppContext from "../../../../store/context/app-context";

const defaultData = {
  type_of_traveler: '',
  title: '',
  country_departure: '',
  country_arrival: '',
  state_departure: '',
  state_arrival: '',
  city_departure: '',
  city_arrival: '',
  pickup_place: '',
  delivery_place: '',
  departure_date: '',
  arrival_date: '',
  departure_time: '',
  arrival_time: '',
  carriage_capacity: '',
  price_per_kg: '',
  trip_type: '',
  height: '',
  weight: '',
  length: '',
  transport_type: '',
  packing_instruction: '',
  description: '',
}
const format = {
  country_of_Departure: {},
  state_of_Departure: {},
  city_of_Departure: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};
function SubscribeAds() {
  const { t } = useTranslation();
  const [data, setData] = useState(defaultData);
  const [traveldetails, settraveldetails] = useState([])
  const [details, setDetails] = useState(format);
  const [isShow, setIsShow] = useState(false);
  const contextObj = useContext(AppContext);
  const scrollTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>

      {!isShow ?
        <div class="">
          <div class="container">
            <div class="row">
              <div class="col-lg-12 text-center">
                <h3 class="section-title fw-bold">{t('profile.my_current_plan')}</h3>
                <h6 class="text-start pb-2 fw-bold">{t('profile.applicable_site_service_fees_as_follows')}:</h6>
              </div>
              <div class="col-lg-6 col-sm-6 mb-4">
                <div class="card border-0 shadow rounded-xs pt-5" style={{ height: '100%' }}>
                  <div class="card-body">
                    <i class="bi bi-people-fill icon-lg icon-primary icon-bg-primary icon-bg-circle mb-3" />
                    <h5 class="mt-4 mb-3 fw-bold">{t('profile.for_sending_luggages')}</h5>
                    <ul className="p-3" style={{ listStyleType: 'initial' }}>
                      <li>{t("homePage.fees_will_added_service")}10{' '}{contextObj.loginUser?.symbol} * 10kg{')'} + 25{contextObj.loginUser?.symbol}{' '}{t("homePage.fees_will_added_service2")}{contextObj.loginUser?.symbol}{' ]'}</li>
                      <li>{t("homePage.case_cancellation_non_refundabl")} {contextObj.loginUser?.symbol}{' '} {t("homePage.case_cancellation_non_refundabl2")} {contextObj.loginUser?.symbol} {'.'}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 mb-4">
                <div class="card border-0 shadow rounded-xs pt-5" style={{ height: '100%' }}>
                  <div class="card-body">
                    <i class="bi bi-people-fill icon-lg icon-yellow icon-bg-yellow icon-bg-circle mb-3"></i>
                    <h5 class="mt-4 mb-3 fw-bold">{t('profile.for_selling_articles')}</h5>
                    <ul className="p-3" style={{ listStyleType: 'initial' }}>
                      <li>{t("homePage.sending_sold_articles_to_buyers")}{contextObj.loginUser?.symbol} {')'}+ 15{contextObj.loginUser?.symbol}{' '}{t("homePage.sending_sold_articles_to_buyers2")}  {contextObj.loginUser?.symbol}{' ].'}</li>
                      <li>{t("homePage.cancellation_either_parties")}{contextObj.loginUser?.symbol}{' '}{t("homePage.cancellation_either_parties2")}{contextObj.loginUser?.symbol}{'.'}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 mb-4">
                <div class="card border-0 shadow rounded-xs pt-5" style={{ height: '100%' }}>
                  <div class="card-body">
                    <i class="bi bi-cash-stack icon-lg icon-purple icon-bg-purple icon-bg-circle mb-3"></i>
                    <h5 class="mt-4 mb-3 fw-bold">{t('profile.for_carrying_luggages_to_earn_money')}</h5>
                    <ul className="p-3" style={{ listStyleType: 'initial' }}>
                      <li>{t("homePage.Total_earned_trip_amount")} 10{' '}{contextObj.loginUser?.symbol} * 10kg {')'} - 25{' '}{contextObj.loginUser?.symbol}{t("homePage.Total_earned_trip_amount2")}{' '}{contextObj.loginUser?.symbol}{' '}{t("homePage.Total_earned_trip_amount3")}</li>
                      <li>{t("homePage.fees_will_be_refundable")} +75{' '}{contextObj.loginUser?.symbol} - 75{' '}{contextObj.loginUser?.symbol} = 0{' '}{contextObj.loginUser?.symbol}</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-sm-6 mb-4">
                <div class="card border-0 shadow rounded-xs pt-5" style={{ height: '100%' }}>
                  <div class="card-body">
                    <i class="bi bi-box icon-lg icon-cyan icon-bg-cyan icon-bg-circle mb-3"></i>
                    <h5 class="mt-4 mb-3 fw-bold">{t('profile.For_carrying_sold_articles_to_earn_money')}</h5>
                    <ul className="p-3" style={{ listStyleType: 'initial' }}>
                      <li>{t("homePage.delivering_sold_articles_recepient")} {' '} {contextObj.loginUser?.symbol} {')'} - 15{' '}{contextObj.loginUser?.symbol}{' '}{t("homePage.delivering_sold_articles_recepient2")} {contextObj.loginUser?.symbol}{' ].'}</li>
                      <li>{t("homePage.In_cancellation_either")} +85{' '}{contextObj.loginUser?.symbol} - 85{' '}{contextObj.loginUser?.symbol} = 0{' '}{contextObj.loginUser?.symbol}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="social-buttons mt-4 text-center pb-5">
            <div class="container d-flex justify-content-center">
              <div class="subscribecard p-4" >
                <h5 class="mt-2 " >{t('profile.upgrade_to_our_club_membership_and_enjoy_0_service_fees')}</h5>
                <div className="d-flex justify-content-center mt-2">
                  <h6 class="mt-2 fw-bold" style={{ color: '#ff4838' }} >{t('profile.upgrade_membership')}</h6>{' '}
                  <button class="neo-button ms-1" onClick={() => (setIsShow(true), scrollTop())}><i class="bi bi-arrow-right-circle-fill bi-1x" /></button>
                </div>
              </div>
            </div>
          </div>
        </div> :
        <UpgradeMemberShip />
      }
    </>
  );
}

export default SubscribeAds;
