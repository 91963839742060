import React, { useContext, useEffect, useState } from "react";
import Select from "react-select";
import { Link } from "react-router-dom";
import { country } from "../../../models";
import { useTranslation } from "react-i18next";
import AppContext from "../../../store/context/app-context";
import { useMediaQuery } from "@mui/material";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
import i18n from "../../../plugin/i18n";
SwiperCore.use([Navigation, Pagination, EffectFade]);

const format = {
  city_of_Departure: {},
  country_of_Arriaval: {},
  state_of_Arriaval: {},
  city_of_Arriaval: {},
  departureCountry: [],
  departureState: [],
  departureCity: [],
  arriavalCountry: [],
  arriavalState: [],
  arriavalCity: [],
};

const travelSearch = {
  country: '',
  state: '',
  city: '',
  oneWayTicket: '',
  roundTrip: '',
  particular: '',
  professional: '',
}
const luggageSearch = {
  country: '',
  state: '',
  city: '',
  particular: '',
  professional: '',
}


function SearchBarFour() {
  const { t } = useTranslation();
  const contextObj = useContext(AppContext)
  const [data, setData] = useState(format);
  const [travelData, setTravelData] = useState(travelSearch);
  const [luggageData, setluggageData] = useState(luggageSearch);
  const mediumViewport = useMediaQuery('(min-width:995px)');

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    country
      .country()
      .then((data) => {
        handleData("departureCountry", data)
        handleData("arriavalCountry", data)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const getStateData = (id) => {
    country
      .state(id)
      .then((data) => {
        handleData("departureState", data)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  const getArrivalStateData = (id) => {
    country
      .state(id)
      .then((data) => {
        handleData("arriavalState", data)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const getCityData = (id) => {
    country
      .city(id)
      .then((data) => {
        handleData("departureCity", data)
      })
      .catch(error => {
        console.log("error", error);
      })
  };
  const getArrivalCityData = (id) => {
    country
      .city(id)
      .then((data) => {
        handleData("arriavalCity", data)
      })
      .catch(error => {
        console.log("error", error);
      })
  };

  const handleData = (name, value) => {
    setData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  };

  const handleTravel = (name, value) => {
    console.log('country');
    setTravelData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const handleLuggage = (name, value) => {
    setluggageData(prevState => ({
      ...prevState,
      [name]: value,
    }))
  }

  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };
  const getCountries = () => {
    let arrivalCountry = [];
    data.arriavalCountry?.map((item) => {
      arrivalCountry.push({ value: item.id, label: item.name });
    })
    return arrivalCountry
  }

  const getState = () => {
    let arrivalState = [];
    data.departureState?.map((item) => {
      arrivalState.push({ value: item.id, label: item.name });
    })
    return arrivalState
  }
  const getArrivalState = () => {
    let arrivalState = [];
    data.arriavalState?.map((item) => {
      arrivalState.push({ value: item.id, label: item.name });
    })
    return arrivalState
  }

  const getCity = () => {
    let arrivalCity = [];
    data.departureCity?.map((item) => {
      arrivalCity.push({ value: item.id, label: item.name });
    })
    return arrivalCity
  }
  const getArrivalCity = () => {
    let arrivalCity = [];
    data.arriavalCity?.map((item) => {
      arrivalCity.push({ value: item.id, label: item.name });
    })
    return arrivalCity
  }
  const noOption=()=>{return t('homePage.no_option')};

  return (
    <>
      <div className="searchbar-section home4 d-none d-lg-block">
        <div className="container" >
          <div className="multi-main-searchber px-3 px-lg-0 my-md-2" style={{ background: mediumViewport ? null : "white" }}>
            <label className="mb-2" style={{ fontSize: '14px', fontWeight: '600', color: mediumViewport ? "white" : "black" }}>{t('homePage.lugagger_where_do_you_want_to_send')}​ <br />
              ({t('homePage.enter_the_country_where_you_want_to_send_your_luggage')})</label>
            <div className="row g-4">
              <div className="col-lg-10 ">
                <div className="row gx-0 gy-4" >
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input" >
                        <label  htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black"}}>{t('homePage.country')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          value={travelData.country}
                          onChange={(e) => {
                            handleTravel("country", e);
                            handleTravel("state", '');
                            handleTravel("city", '');
                            getStateData(e.value);
                          }}
                          options={getCountries()}
                          placeholder={t('homePage.Select')}
                          className="selectbox pt-1"
                        >
                        </Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input">
                        <label htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black" }}>{t('homePage.state')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          value={travelData.state}
                          onChange={(e) => {
                            handleTravel("state", e);
                            handleTravel("city", '');
                            getCityData(e.value);
                          }}
                          options={getState()}
                          noOptionsMessage={noOption}
                          placeholder={t('homePage.Select')}
                          // placeholder={t('homePage.state_of_arrival')}
                          className="selectbox pt-1"
                        ></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input ">
                        <label htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black" }}>{t('homePage.city')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          className="selectbox pt-1"
                          value={travelData.city}
                          onChange={(e) => {
                            handleTravel("city", e)
                          }}
                          options={getCity()}
                          noOptionsMessage={noOption}
                          placeholder={t('homePage.Select')}
                        // placeholder={t('homePage.city_of_arrival')}
                        ></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6"
                    style={{ marginTop: "44px" }}
                  >
                    <div class="form-check form-check-inline d-flex " style={{ justifyContent: 'space-between', paddingLeft: '0px',marginRight:'0px' }}>
                      <div class="d-flex pt-1" >
                        <input class="" type="checkbox" id="inlineCheckbox1" style={{ width: '15px', height: '15px',marginRight:'3px' }}
                          name='oneWayTicket'
                          onChange={(e) => {
                            handleTravel('oneWayTicket', e.target.checked ? '1' : '');
                          }} />
                        <label style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.one_way_ticket')}</label>
                      </div>
                      <div class="d-flex pt-1" style={{ marginRight: '18px' }}>
                        <input class="" type="checkbox" style={{ width: '15px', height: '15px',marginRight:'3px',flexShrink:0 }}
                          name='particular'
                          onChange={(e) => {
                            handleTravel('particular', e.target.checked ? 2 : '');
                          }} />
                        <label style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.particular')}</label>
                      </div>
                    </div>
                    <div class="form-check form-check-inline d-flex " style={{ justifyContent: 'space-between', paddingLeft: '0px',marginRight:'0px' }} >
                      <div class="d-flex pt-1" >
                      <input class="" type="checkbox" id="inlineCheckbox2"  style={{ width: '15px', height: '15px',marginRight:'3px',flexShrink:0 }}
                          name='roundTrip'
                          onChange={(e) => {
                            handleTravel('roundTrip', e.target.checked ? '2' : '');
                          }} />
                        <label style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.round_trip')}</label>
                      </div>
                      <div class={`d-flex pt-1 ${i18n.language==='frFR'?'ms-md-4':''}`} style={{paddingLeft: i18n.language==='frFR'?'13px':'0px'}}>
                        <input class="" type="checkbox" id="inlineCheckbox2" style={{ width: '15px', height: '15px',marginRight:'3px' }}
                          name='professional'
                          onChange={(e) => {
                            handleTravel('professional', e.target.checked ? 1 : '');
                          }} />
                        <label style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.professional')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-12" style={{display:'flex',alignItems:"end"}}>
                <div className="main-form-submit col-lg-12 col-12" style={{ height: "auto",marginBottom:'7px' }}>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/cobagage_travel_announcements`}
                  >
                    <button type="submit" onClick={() => { contextObj.setTravel(travelData) }} style={{ background: "linear-gradient(to bottom, #ff9933 0%, #cc6600 100%)", borderRadius: "5px" }}>Cobagage</button>
                  </Link>
                </div>
              </div>
            </div>
            {/* <div className="multi-main-searchber "> */}
            <label className="mb-2" style={{ fontSize: '14px', fontWeight: '600', marginTop: '10px', color: mediumViewport ? "white" : "black" }}>{t('homePage.traveler_find_out_who_is_shipping_to_your_destination')}<br />
              ({t('homePage.enter_the_country_of_your_destination')})</label>
            {/* <label style={{ fontSize: '14px', fontWeight: '600' }}>Lugagger, where do you want to send?​ <br />
              (enter the country where you want to send your luggage)</label> */}
            <div className="row g-4 ">
              <div className="col-lg-10 ">
                <div className="row gx-0 gy-4">
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input" >
                        <label htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black" }}>{t('homePage.country')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          value={luggageData.country}
                          onChange={(e) => {
                            handleLuggage("country", e);
                            handleLuggage("state", '');
                            handleLuggage("city", '');
                            getArrivalStateData(e.value);
                          }}
                          options={getCountries()}
                          placeholder={t('homePage.Select')}
                          // placeholder={t('homePage.country_of_arrival')}
                          className="selectbox pt-1"
                        ></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input">
                        <label htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black" }}>{t('homePage.state')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          className="selectbox pt-1"
                          value={luggageData.state}
                          onChange={(e) => {
                            handleLuggage("state", e);
                            handleLuggage("city", '');
                            getArrivalCityData(e.value);
                          }}
                          placeholder={t('homePage.Select')}
                          // placeholder={t('homePage.state_of_arrival')}
                          options={getArrivalState()}
                          noOptionsMessage={noOption}
                        ></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6">
                    <div className="select-box ps-0">
                      <div className="searchbox-input ">
                        <label htmlFor="activity-dropdown" style={{ color: mediumViewport ? "white" : "black" }}>{t('homePage.city')}</label>
                        <Select
                          styles={{
                            control: (baseStyles) => ({
                              ...baseStyles,
                              border: 0
                            }),
                          }}
                          className="selectbox pt-1"
                          value={luggageData.city}
                          onChange={(e) => {
                            handleLuggage("city", e)
                          }}
                          options={getArrivalCity()}
                          placeholder={t('homePage.Select')}
                          noOptionsMessage={noOption}
                        // placeholder={t('homePage.city_of_arrival')}
                        ></Select>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-6"
                    style={{display:'flex',alignItems:"end"}}
                  >
                    <div class="form-check form-check-inline d-flex col-12" style={{ justifyContent: 'space-between', paddingLeft: '0px'}}>
                      <div class="d-flex pt-1 col-6" >
                        <input class="" type="checkbox" id="inlineCheckbox1" style={{ width: '15px', height: '15px',marginRight:'3px' }}
                          name='particular'
                          onChange={(e) => {
                            handleLuggage('particular', e.target.checked ? 2 : '');
                          }} />
                        <label class="form-check-label" style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.particular')}</label>
                      </div>
                      <div class={`d-flex justify-content-end pt-1 col-6`} style={{marginLeft:'0px'}}>
                        <input class="" type="checkbox" id="inlineCheckbox2" style={{ width: '15px', height: '15px',marginRight:'3px',flexShrink:0 }}
                          name='professional'
                          onChange={(e) => {
                            handleLuggage('professional', e.target.checked ? 1 : '');
                          }} />
                        <label class="form-check-label" style={{ fontSize: '14px', color: mediumViewport ? "white" : "black", marginTop: "-4px" }}>{t('homePage.professional')}</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-2 col-12" style={{display:'flex',alignItems:'end'}}>
                <div className="main-form-submit col-lg-12 col-12" style={{ height: "auto",marginBottom:'7px'}}>
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/cobagage_luggage_announcements`}
                  >
                    <button type="submit" onClick={() => { contextObj.setluggage(luggageData) }} style={{ backgroundColor: "#53bde2", borderRadius: "5px" }}>{t('homePage.luggages')}</button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchBarFour;
