import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import subscribeHistory from "../../../../models/subscribeHistory";
import moment from "moment/moment";
import AppContext from "../../../../store/context/app-context";

export default function SubscriptionBalance() {
  const { t } = useTranslation();
  const [subscriptionBuy, setSubscriptionBuy] = useState();
  const [subscriptionAllData, setSubscriptionAllData] = useState();
  const contextObj = useContext(AppContext);

  useEffect(() => {
    getsubscriptionData();
    getSubscriptionBalance();
  }, []);

  const getsubscriptionData = () => {
    subscribeHistory.list().then((respons) => {
      console.log("rsponse data for getsubscriptionData: ", respons);
      setSubscriptionBuy(respons);
    });
  };

  const getSubscriptionBalance = () => {
    subscribeHistory.getAllTransction().then((respons) => {
      setSubscriptionAllData(respons);
    });
  };

  return (
    <div>
      <div className="package-details-wrapper p-0">
        <div className="tour-package-details">
          <div className="package-details-tabs row">
            <ul
              className="nav nav-pills gap-xxl-4 gap-3"
              id="pills-tab"
              role="tablist"
              style={{ marginBottom: "0px", display:"flex", flexWrap:"nowrap" }}
            >
              <li className="nav-item travel" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-package1"
                  data-bs-toggle="pill"
                  data-bs-target="#pill-body1"
                  type="button"
                  role="tab"
                  aria-controls="pill-body1"
                  aria-selected="true"
                  style={{ fontSize: "15px", borderRadius: "5px" }}
                >
                  {t("profile.Subscription_Buying")}
                </button>
              </li>
              <li className="nav-item travel" role="presentation">
                <button
                  className="nav-link"
                  id="pills-package2"
                  data-bs-toggle="pill"
                  data-bs-target="#pill-body2"
                  type="button"
                  role="tab"
                  aria-controls="pill-body2"
                  aria-selected="false"
                  style={{ fontSize: "15px", borderRadius: "5px" }}
                >
                  {t("profile.Subscription_Balance")}
                </button>
              </li>
            </ul>
            <div className="tab-content" id="pills-tabContent">
              {/*   Subscription Buying*/}
              <div
                className="tab-pane fade show active package-info-tab pt-4"
                id="pill-body1"
                role="tabpanel"
                aria-labelledby="pills-package1"
              >
                <div
                  class="container package-card-delta card mt-5 pb-2"
                  style={{ overflowX: "auto" }}
                >
                  {subscriptionBuy?.transaction_id ? (
                    <table class="table table-borderless table-responsive card-1 p-4 mt-4">
                      <thead>
                        <tr class="border-bottom">
                          <th>
                            <span class="ml-2">
                              {t("profile.Transaction_ID")}
                            </span>
                          </th>
                          <th>
                            <span class="ml-2">
                              {t("profile.Transaction_Date_Time")}
                            </span>
                          </th>
                          <th>
                            <span class="ml-2">{t("profile.Plan_name")}</span>
                          </th>
                          <th>
                            <span class="ml-2">{t("profile.Amount")}</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr class="border-bottom">
                          <td>
                            <div class="p-2">
                              <span
                                class="font-weight-bold"
                                style={{ color: "#f39c12" }}
                              >
                                {subscriptionBuy?.transaction_id}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div class="p-2">
                              <span
                                class="font-weight-bold"
                                style={{ color: "#f39c12" }}
                              >
                                {moment(subscriptionBuy?.created_date)?.format(
                                  "DD/MM/YYYY"
                                )}{" "}
                                |{" "}
                                {moment(subscriptionBuy?.created_date)?.format(
                                  "hh:mm a"
                                )}
                              </span>
                            </div>
                          </td>
                          <td>
                            {subscriptionBuy ? (
                              <div class="p-2">
                                <span class="font-weight-bold">
                                  {subscriptionBuy?.type === 1
                                    ? t("homePage.SILVER")
                                    : subscriptionBuy?.type === 2
                                      ? t("homePage.GOLD")
                                      : subscriptionBuy?.type === 3
                                        ? t("homePage.DIAMOND")
                                        : ""}
                                  /
                                  {subscriptionBuy?.plan_type === 1
                                    ? t("homePage.BASIC")
                                    : subscriptionBuy?.plan_type === 2
                                      ? t("profile.intermedite")
                                      : subscriptionBuy?.plan_type === 3
                                        ? t("profile.expert")
                                        : ""}
                                  /{subscriptionBuy?.price}{contextObj?.loginUser?.symbol} for{" "}
                                  {subscriptionBuy?.total_kgs}kg
                                </span>
                              </div>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            <div class="">
                              {/* <span class="font-weight-bold"> */}
                                {subscriptionBuy?.price} {contextObj?.loginUser?.symbol}
                              {/* </span> */}
                            </div>
                          </td>
                        </tr>
                        {/* // ))} */}
                      </tbody>
                    </table>
                  ) : (
                    <div style={{ padding: "10px", paddingTop: "20px" }}>
                      {t("form.subscribe_message")}
                    </div>
                  )}
                  {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" >1</a></li>
                                            <li class="page-item"><a class="page-link" >2</a></li>
                                            <li class="page-item"><a class="page-link" >3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav> */}
                </div>
              </div>
              {/*  Subscription Balance */}
              <div
                className="tab-pane fade package-info-tab"
                id="pill-body2"
                role="tabpanel"
                aria-labelledby="pills-package2"
                style={{ overflowX: "auto" }}
              >
                <div
                  class="container package-card-delta card mt-5 pb-2"
                  style={{ overflowX: "auto" }}
                >
                  <table class="table table-borderless table-responsive card-1 p-4 mt-4">
                    <thead>
                      <tr class="border-bottom">
                        <th>
                          <span class="ml-2">
                            {t("profile.Transaction_Date_Time")}
                          </span>
                        </th>
                        <th>
                          <span class="ml-2">{t("profile.Description")}</span>
                        </th>
                        <th>
                          <span class="ml-2">
                            {t("profile.Balance_Change")}
                          </span>
                        </th>
                        <th>
                          <span class="ml-2">
                            {t("profile.Balance_remaining")}
                          </span>
                        </th>
                      </tr>
                    </thead>

                    <tbody>
                      {/* {filterWalletHistory()?.map((item => */}
                      {subscriptionAllData?.map((item) => {
                        return (
                          <tr class="border-bottom">
                            <td>
                              <div class="p-2">
                                <span
                                  class="font-weight-bold"
                                  style={{ color: "#f39c12" }}
                                >
                                  {moment(item?.created_date)?.format(
                                    "DD/MM/YYYY"
                                  )}{" "}
                                  |{" "}
                                  {moment(item?.created_date)?.format(
                                    "hh:mm a"
                                  )}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <span
                                  class="font-weight-bold"
                                  style={{ color: "#f39c12" }}
                                >
                                  {t("menu.Sent")} {item?.balance_change} kg
                                </span>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <span
                                  class="font-weight-bold"
                                  style={{ color: "red" }}
                                >
                                  {item?.balance_change}
                                </span>
                              </div>
                            </td>
                            <td>
                              <div class="p-2">
                                <span class="font-weight-bold">
                                  {item?.balance_remaining}kg
                                </span>
                              </div>
                            </td>
                          </tr>
                        );
                      })}

                      {/* // ))} */}
                    </tbody>
                  </table>
                  {/* <nav aria-label="Page navigation example">
                                        <ul class="pagination justify-content-end">
                                            <li class="page-item disabled">
                                                <a class="page-link" href="#" tabindex="-1" aria-disabled="true">Previous</a>
                                            </li>
                                            <li class="page-item"><a class="page-link" >1</a></li>
                                            <li class="page-item"><a class="page-link" >2</a></li>
                                            <li class="page-item"><a class="page-link" >3</a></li>
                                            <li class="page-item">
                                                <a class="page-link" href="#">Next</a>
                                            </li>
                                        </ul>
                                    </nav> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
