const homePage = {
    how_it_works: 'Comment ça marche?',
    economics: 'ÉCONOMIQUE',
    ecological: 'ÉCOLOGIQUE',
    secured: 'SÉCURISÉ',
    the_service_between_travellers: 'business entre voyageurs',
    with_cobagage_win_money_while_travelling: "Avec Cobagage, gagnez de l'argent en voyageant",
    lugagger_where_do_you_want_to_send: "Expéditeur, dans quel pays souhaitez-vous envoyer ",
    enter_the_country_where_you_want_to_send_your_luggage: "Saisissez le pays vers lequel vous souhaitez envoyer vos bagages",
    country: 'Pays',
    country_of_arrival: "Pays d'arrivée",
    state: "État",
    state_of_arrival: "État d'arrivée ",
    city: 'Ville',
    city_of_arrival: "Ville arrivée",
    one_way_ticket: "Billet aller simple",
    round_trip: "Aller-retour",


    particular: 'Particulier',
    professional: 'Professionnel',
    traveler_find_out_who_is_shipping_to_your_destination: "Voyageur, découvrez qui expédie vers votre destination finale",
    enter_the_country_of_your_destination: "Saisissez le pays de destination finale",
    luggages: "Bagages",

    imminent_departures: "Départs imminents",
    user: 'Utilisateur',
    availables: "Disponibles",
    price: "Prix",
    submit_my_availables_kg: "Vendre mes kg disponibles",
    last_luggage_ads: "Dernières annonces de bagages",
    send_luggage: "Envoyer des bagages",
    why_choosing_us: "Pourquoi nous choisir?",
    read_more: 'En savoir plus',
    international_shipment: "Envoi international",
    cobagging_takes_many_forms_both_by_means_of_transport_and_the_various_destinations_of_travelers: "C'est très important, cela sera suivi par le client, mais cela arrive à un moment tel que cela demande beaucoup de travail et de peine. Qui a suspendu lui-même les creusets? Rires.",
    quick_or_secure_payment: 'Paiement rapide/sécurisé',
    by_choosing_cobagage_you_have_the_assurance_of_a_secure_payment_platform_for_your_transactions: "est important pour le client, il sera suivi par la société ad-piscing, mais cela se produit en même temps que beaucoup de travail et de douleur. Qui a lui-même suspendu le ballon de basket ? Le rire est une bonne caricature",
    eco_responsive: 'Éco-responsable',
    by_becoming_a_co_gamer_you_directly_participate_in_reducing_the_carbon_footprint: "Il est suivi par le développement du client, mais en même temps il se produit comme beaucoup de travail et de douleur. Qui a suspendu lui-même les creusets ? Rires",
    tips_and_tricks: 'Astuces et Conseils',
    subscribe: "S'abonner",
    Cobagage_travelers: "Cobagage le premier site web où les voyageurs font du business",
    With_traveling: "Ici, vous pouvez : Voyager - Vendre - Livrer",
    here_only_travelers_can_decide_what_to_transport:"Ici, seuls les voyageurs décident de ce qu'ils peuvent transporter",
    cobagage_the_first_website_where_travelers_earn_money:"Cobagage, le premier site où les voyageurs font du business",
    here_you_can_travel_sell_deliver:"Ici, vous pouvez : Voyager - Vendre - Livrer",
    type_of_transports_used_plane_bus_boat_car_bike_rain :'Type de transport utilisé : Avion - Bus - Bateau - Voiture - Vélo - Train',
    welcome: 'Bienvenue',
    Cobagage_Subscription: "Abonnement au club Cobagage",
    Our_Pricing_Members: "Nos tarifs pour les non-membres",
    Become_service_fees: "Devenez membre de notre club et payez 0% de frais de service",
    Applicable_members: "Frais de service du site applicables pour les non-membres",
    luggage_senders: "Pour les expéditeurs de bagages",
    For_Travellers: "Pour les voyageurs",
    SILVER:"ARGENT",
    GOLD:"OR",
    DIAMOND:"DIAMOND",
    BASIC:"BASIQUE",
    for:'Pour',
    free:'Gratuit',
    service_fees_luggages:"0% de frais de service, le voyageur ne paie que le prix du voyage pour l'envoi des bagages.",
    service_earnings_customer:"0% de frais de service sur vos gains - recevez 100% du prix du voyage reçu de votre client",
    articles_included_applied:"kg de bagages et d'articles ne sont soumis à aucun frais Cobagage",
    Proceed_Buy:"Procéder à l'achat",
    INTERMEDIATE:"INTERMÉDIAIRE",
    included_selling_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    EXPERT:"EXPERT",
    articles_selling_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    service_fees_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    free_service_fees_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    kg_55_of_articles_included_for_selling_for_free_with_no_service_fees_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    articles_service_fees_applied:"kg d'articles inclus pour la vente gratuite sans frais de service",
    articles_applied:" kg d'articles inclus pour la vente gratuite sans frais de service",
    SENDING_LUGGAGES:"POUR L'ENVOI DE BAGAGES",
    fees_will_added_service:"25% de frais seront ajoutés comme frais de service sur le montant total du voyage. (Prix unitaire :",
    fees_will_added_service2:'(pour les frais de service) = 125',
    case_cancellation_non_refundabl:"En cas d'annulation par l'une ou l'autre des parties, les frais de service ne seront pas remboursés. Vous ne recevrez que 100 % du coût du voyage, c'est-à-dire 100",
    case_cancellation_non_refundabl2:'au lieu de 125 ',
    SELLING_ARTICLES:"POUR LA VENTE D'ARTICLES",
    sending_sold_articles_to_buyers:"Des frais de service de 15% seront ajoutés pour l'envoi des articles vendus aux acheteurs[(Poids de l'article vendu - 10 kg) * (prix unitaire / kg 10 ",
    sending_sold_articles_to_buyers2:'(pour les frais de service) = 115',
    cancellation_either_parties:"En cas d'annulation par l'une ou l'autre des parties, les frais de service ne seront pas remboursés. Vous ne recevrez que 100 % des frais d'envoi de l'article, c'est-à-dire 100",
    cancellation_either_parties2:'seulement au lieu de 115 ',
    FOR_CARRYING_LUGGAGES:"POUR LE TRANSPORT DES BAGAGES",
    Total_earned_trip_amount:"25% de frais seront déduits en tant que frais de service sur le montant total du voyage gagné. [(Prix unitaire :",
    Total_earned_trip_amount2:' (pour les frais de service) = 75',
    Total_earned_trip_amount3:'sera le montant que vous recevrez comme gain net sur le voyage].',
    fees_will_be_refundable:"En cas d'annulation par l'une ou l'autre des parties, les frais de service ne seront pas remboursés. Ajustement du portefeuille en cas d'annulation - ",
    FOR_SELLING_ARTICLES:"POUR LA VENTE D'ARTICLES",
    delivering_sold_articles_recepient:"15% de frais de service seront déduits des frais de service sur le gain pour la livraison des articles vendus au destinataire. [(Poids de l'article vendu - 10 kg) * (prix unitaire / kg 10 ",
    delivering_sold_articles_recepient2:'(pour les frais de service) = 85',
    In_cancellation_either:"En cas d'annulation par l'une ou l'autre des parties, les frais de service ne seront pas remboursés. Ajustement du portefeuille en cas d'annulation - ",
    item_sale:"Article en vente",
    item_Donation:"Article pour don",
    Recent_announcements:"Annonces récentes de voyages",
    Recent_articles_listed:"Articles récemment mis en vente",
    Recent_articles_donation:"Articles récents listés pour don",
    Travel:"Voyage",
    Luggage:"Bagage",
    Travels:"VOYAGES",
    Luggages:"BAGAGES",
    Select:"Sélectionner",
    View_all_travel_announcements:"Afficher toutes les annonces de voyages",
    View_articles_sale:"Voir tous les articles en vente",
    View_articles_donation:"Voir tous les articles pour don",

    Add_New_card:" Je vends mes kg de voyage",
    l_send_luggages:"J'envoie des bagages",
    I_sell_Article:"Je vends un/des article(s)",
    I_make_Donation:"Je fais un don",
    For:'pour',
    no_option:'Aucune option'
}
export default homePage;