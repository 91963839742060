import React from 'react'
import { useTranslation } from 'react-i18next';

export default function CancelledLuggage(props) {
    const { t } = useTranslation();
    const filterTravelDetails = () => {
        return (props.articleISold == 1 ? (props.received == 1 ? props?.iReceived[0] : props?.iReceived[1]) : (props.received == 1 ? props?.iSent[0] : props?.iSent[1]))?.filter((item) => 
        // props.articleISold == 1 ? item?.reason_to_cancel != 0 : item?.reason_of_cancel != 0
        item?.status == 2)
    }
 
    return (
        <div class="row d-flex justify-content-center align-items-center h-100 pt-1">
            <div class=" col mb-4 mb-lg-0">
                {filterTravelDetails()?.map((item =>
                    <div class="package-card-delta card mb-3" style={{ borderRadius: '8px' }}>
                        {/* <div class="ribbon red"><span>{t("profile.not_completed")}</span></div> */}
                        <div class="row g-0">
                            <h4 className='p-2' style={{ backgroundColor: '' }} >{props.received == 1 ? (props.articleISold == 1 ? item?.title : item?.luggage_title) : (props.articleISold == 2 ? item?.title : item?.luggage_title)}</h4>
                            <hr class="mt-0 mb-4" />
                            <div class="col-md-7">
                                <div class="card-body pt-0 pb-0">
                                    <div class="row">
                                        <div class="col-4 mb-3">
                                            <h6><img
                                src={item?.departure_flag}
                                style={{ width: "25px",height:"20px",marginRight:'5px' }}/>{' '}{item?.city_departure_name}</h6>
                                        </div>
                                        <div class="col-2 mb-3">
                                            <h6><i class="bi bi-arrow-right" ></i></h6>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <h6><img
                                src={item?.arrival_flag}
                                style={{ width: "25px",height:"20px",marginRight:'5px' }}/>{' '}{item?.city_arrival_name}</h6>
                                        </div>
                                    </div>
                                    <div class="row pt-1">
                                        <div class="col-6 mb-3">
                                            <h6><i class="bi bi-calendar-event" />{' '}{t('profile.departure')}</h6>
                                            <p class="text-muted">{item?.departure_date} | {item?.departure_time}</p>
                                        </div>
                                        <div class="col-6 mb-3">
                                            <h6><i class="bi bi-calendar-event" />{' '}{t('profile.arrival')}</h6>
                                            <p class="text-muted">{' '}{item?.arrival_date} | {item?.arrival_time}</p>
                                        </div>
                                    </div>
                                    <div class="row pt-1">
                                        <div class="col-12 mb-3">
                                            <h6><i class="bi bi-cart-fill"></i>{' '}{t('profile.weight')}: {props.received == 1 ? (props.articleISold == 1 ? item?.weight : item?.luggage_weight) : (props.articleISold == 2 ? item?.weight : item?.luggage_weight)} Kg</h6>
                                            {props.received == 1 ? <h6 className='pt-2'><i class="bi bi-arrow-down-left-circle-fill"></i>{' '}{t("profile.Customer")} : <span style={{ color: '#1e98d7' }}>{props.articleISold == 1 ? item?.request_user_name : item?.luggager_name}</span></h6> :
                                                <h6 className='pt-2'><i class="bi bi-truck"></i>{' '}{t('profile.traveller')}: <span style={{ color: '#1e98d7' }}>{props.articleISold == 1 ? item?.traveler_name : item?.travel_user_name}</span></h6>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-5 col-lg-5 col-12">
                                <div class="card-body pt-0 pb-0">
                                    <div class="earning">
                                        <div class="plan" >
                                            <header><i class="bi bi-currency-dollar"></i>
                                                <h6 class="plan-title">{props.received == 1 ? t('profile.earning_oppurtunity') : t('profile.trip_Cost_paid')}</h6>
                                                <div class="plan-cost"><span class="plan-price">{item.paid_amount} {item.currency}</span><span class="plan-type"></span></div>
                                            </header>
                                        </div>
                                        <div class="plan-select pt-2">
                                            {/* {props.received == 1 && <a href={`luggageCancelled/${item?.id}/${props.received == 1 ? (props.articleISold == 1 ? item?.request_type : item?.product_type) : (props.articleISold == 1 ? item?.product_type : item?.request_type)}/1`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>}
                                            {props.received == 2 && <a href={`luggageCancelled/${item?.id}/${props.articleISold == 1 ? item?.product_type : item?.request_type}/2`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>} */}
                                            {(props.received == 1 && props.articleISold == 1) && <a href={`luggageCancelled/${item?.id}/${props.received == 1 ? (props.articleISold == 1 ? item?.request_type : item?.product_type) : (props.articleISold == 1 ? item?.product_type : item?.request_type)}/1`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>}
                                            {(props.received == 1 && props.articleISold == 2) && <a href={`luggageCancelled/${item?.id}/${item?.product_id}/${props.received == 1 ? (props.articleISold == 1 ? item?.request_type : item?.product_type) : (props.articleISold == 1 ? item?.product_type : item?.request_type)}/1`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>}
                                            {(props.received == 2 && props.articleISold == 1) && <a href={`luggageCancelled/${item?.id}/${item?.product_id}/${props.articleISold == 1 ? item?.product_type : item?.request_type}/2`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>}
                                            {(props.received == 2 && props.articleISold == 2) && <a href={`luggageCancelled/${item?.id}/${props.articleISold == 1 ? item?.product_type : item?.request_type}/2`} style={{ cursor: 'pointer', padding: '12px 14px', fontWeight: '700' }} >{t('profile.view_order_details')}</a>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>))}
            </div>
        </div>
    )
}

