import React, { useContext, useEffect, useRef, useState } from "react";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from "react-i18next";
import { Link, useParams } from "react-router-dom";
import RecentArticleCard from "../../../swipersData/RecentArticleCard";
import articles from "../../../../../../models/articles";
import { country } from "../../../../../../models";
import articlesDonation from "../../../../../../models/articlesDonation";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import AppContext from "../../../../../../store/context/app-context";

const details = {
  country_departure: '',
  verification: '',
  price: '',
  sortBy: '',
  Ads_Category: ''
}

function ViewArticlesWrapperArea() {
  const { t } = useTranslation();
  const [load, setLoad] = useState(false);
  const [articlesListed, setArticlesListed] = useState();
  const [countries, setCountries] = useState();
  const [data, setData] = useState(details);
  const { id } = useParams();
  const history = useHistory();
  const contextObj = useContext(AppContext);
  const [hide, setHide] = useState(true);
  const [userId, setUserId] = useState(contextObj?.login ? contextObj?.loginUser?.id : "0")

  useEffect(() => {
    setLoad(true);
    setTimeout(() => {
      setLoad(false);
    }, 2000);
  }, []);

  useEffect(() => {
    getData();
  }, []);
  const getData = () => {
    if (id == 1) {
      articles
        .list(userId)
        .then((item) => {
          setArticlesListed(item?.filter((item) => item?.isComplete == false)); 
        })
        .catch(error => {
          console.log("error", error);
        })
    } else (
      articlesDonation
        .list()
        .then((item) => {
          setArticlesListed(item?.filter((item) => item?.isComplete == false));
        })
        .catch(error => {
          console.log("error", error);
        })
    )


    country
      .country()
      .then((details) => {
        setCountries(details);
      })
      .catch(error => {
        console.log("error", error);
      })

  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetData(name, value);
  };

  const handleSetData = (name, value) => {
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  }

  const filterArticleDetails = () => {
    return articlesListed?.filter((item) => data?.verification ? (data?.verification === 'true' ? item.verification : !item.verification) : item)
      .filter((item) => {
        if (data?.price) {
          if (data?.price === '1') return 10 > item.price;
          if (data?.price === '2') return 10 <= item.price && item.price <= 50;
          if (data?.price === '3') return 50 <= item.price && item.price <= 100;
          if (data?.price === '4') return 100 < item.price;
        }
        return item;
      }).sort((a, b) => {
        if (data?.sortBy == '1') {
          return parseFloat(a.price) - parseFloat(b.price);
        } else if (data?.sortBy == '2') {
          return parseFloat(b.price) - parseFloat(a.price);
        } else if (data?.sortBy == '3') {
          return b.id - a.id;
        } else if (data?.sortBy == '4') {
          return parseFloat(a.weight) - parseFloat(b.weight);
        } else if (data?.sortBy == '5') {
          return parseFloat(b.weight) - parseFloat(a.weight);
        } else {
          return a;
        }
      })
      .sort((a, b) => {
        if (data?.sortBy == '1') {
          return parseFloat(a.price) - parseFloat(b.price);
        } else if (data?.sortBy == '2') {
          return parseFloat(b.price) - parseFloat(a.price);
        } else if (data?.sortBy == '3') {
          return b.id - a.id;
        } else if (data?.sortBy == '4') {
          return parseFloat(a.weight) - parseFloat(b.weight);
        } else if (data?.sortBy == '5') {
          return parseFloat(b.weight) - parseFloat(a.weight);
        } else {
          return a;
        }
      }).sort((a, b) => {
        if (data.Ads_Category == '1') {
          return new Date(b.created_at) - new Date(a.created_at)
        }
        else if (data.Ads_Category == '2') {
          return new Date(a.created_at) - new Date(b.created_at)
        }
        else {
          return a;
        }
      })
      .filter((item) => !data?.country_departure ? item : item?.country == data?.country_departure && item)
    //  .filter((item) => data?.country_departure ? item?.country_departure == data?.country_departure : item)
    // .filter((item) => data?.trip_type ? item.trip_type == data?.trip_type : item)
    // .filter((item) => data?.traveller_account_type ? item.traveller_account_type == data?.traveller_account_type : item)
  }
  return (
    <>
      <div className="container">
        <div className="package-area package-style-two  pb-110 chain b">
          {/* <div className="p-4"> */}
          {/* <div className="row justify-content-center"> */}
          <div className="row">
            <div className='col-12 col-md-9 col-lg-9 mt-3'>
              <h2 class='fs-3 pb-4'>{filterArticleDetails()?.length} {t('form.results_found')}</h2>
            </div>
            <div className=' col-lg-12 col-12 col-md-12 col-xl-12 xol-xxl-12 row' style={{ marginBottom: articlesListed?.length == 1 ? '60px' : '30px' }}>
            <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='country_departure'
                    onChange={handleChange}
                    value={data.country_departure}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected>{t("form.all")}</option>
                    {countries?.map((item) => <option value={item.id} key={item.id}>{item.name}</option>)}
                  </select>
                </div>
              {id == 2 && <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                <select
                  id="Ads_Category"
                  name='Ads_Category'
                  onChange={handleChange}
                  style={{ border: '1px solid', borderColor: '#ff4838' }}
                >
                  <option value={''} selected disabled>-  {t('form.sort_by')} -</option>
                  <option value={1}> {t('form.newly_posted')}</option>
                  <option value={2}> {t('form.relavance')}</option>
                </select>
              </div>}
              {id == 1 ?
                <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='price'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>-  {t('form.select_price_range')} -</option>
                    <option value={1}> {t('form.below')} 10{contextObj?.loginUser?.symbol}</option>
                    <option value={2}> {t('form.between')} 10{contextObj?.loginUser?.symbol} - 50{contextObj?.loginUser?.symbol}</option>
                    <option value={3}> {t('form.between')} 50{contextObj?.loginUser?.symbol} - 100{contextObj?.loginUser?.symbol}</option>
                    <option value={4}> {t('form.More_than')} 100{contextObj?.loginUser?.symbol}</option>
                  </select>
                </div> :
                <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='verification'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>-{t('form.giver_status')}-</option>
                    <option value={true}> {t('form.verified')}</option>
                    <option value={false}> {t('form.Unverified')}</option>
                  </select>
                </div>
              }
              {id == 1 ?
                (<div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                  <select
                    id="Ads_Category"
                    name='verification'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>-{t('form.seller_status')}-</option>
                    <option value={true}>{t('form.verified')}</option>
                    <option value={false}>{t('form.Unverified')}</option>
                  </select>
                </div>) :
                (<div class='col-lg-3 col-md-4 col-sm-12 pt-1' />)
              }
              <div class='col-lg-3 col-md-4 col-sm-12 pt-1'>
                {id == 1 &&
                  <select
                    id="Ads_Category"
                    name='sortBy'
                    onChange={handleChange}
                    style={{ border: '1px solid', borderColor: '#ff4838' }}
                  >
                    <option value={''} selected disabled>- {t('form.sort_by')} -</option>
                    <option value={1}>{t('form.by_ascending_price')}</option>
                    <option value={2}>{t('form.by_decreasing_price')}</option>
                    <option value={3}>{t('form.recent_announcement')}</option>
                    <option value={4}>{t('form.per_kg_increasing')}</option>
                    <option value={5}> {t('form.per_kg_decreasing')}</option>
                  </select>
                }
              </div>
            </div>
          </div>
          {/* </div> */}
          <div className="row d-flex g-4">
            {
              filterArticleDetails()?.map((item) => {
                return (
                  <div className="col-lg-3 col-md-6 col-sm-10"
                    key={item.id}
                  >
                    <div className="package-card-delta">
                      <RecentArticleCard
                        item={item}
                        data={history.location?.state?.data === "ArticleDonation" ? 'ArticleDonation' : 'articleSale'}
                        hide={hide}
                      />
                    </div>
                  </div>
                )
              })
            }
          </div>
          {/* </div> */}
        </div>
      </div>
    </>
  );
}

export default ViewArticlesWrapperArea;
