import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";
import { useTranslation } from "react-i18next";

function AboutWrapperArea() {
  const { t } = useTranslation();

  return (
    <div className="container">
      <div className="col-12 mt-5 mb-5">
        <h3>{t('profile.aboutus_text1')}</h3>
      </div>
      <div className="col-12 row">
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/u11.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6 mt-lg-5">
          <h4 className="pt-2">{t('profile.aboutus_text2')}</h4>
          <p className="pt-4">{t('profile.aboutus_text3')}</p>
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6 row">
          <h2 className="d-flex justify-content-center align-items-center">{t('profile.aboutus_text4')}</h2>
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center align-items-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text5')}</p>
          </div>
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center align-items-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text6')}</p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/u16.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 d-flex flex-column-reverse d-lg-flex flex-lg-row">
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/u23.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
        <div className="col-12 col-lg-6 row">
          <h2 className="d-flex justify-content-center">{t('profile.aboutus_text7')}</h2>
          <div className="col-1 d-flex justify-content-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text8')}</p>
          </div>
          <div className="col-1 d-flex justify-content-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text9')}</p>
          </div>
        </div>
      </div>
      <div className="col-12 row mt-5">
        <div className="col-12 col-lg-6 row">
          <h3 className="d-flex justify-content-center align-items-center">{t('profile.aboutus_text10')}</h3>
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center align-items-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text11')}</p>
          </div>
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center align-items-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text12')}</p>
          </div>
          <div className="col-1 d-flex justify-content-center align-items-center">
            <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
          </div>
          <div className="col-11 d-flex justify-content-center align-items-center">
            <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text13')}</p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <img
            src={"/images/vehicles/u39.jpeg"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "100%" }}
          >
          </img>
        </div>
      </div>
      <div className="col-12 row mt-5 mb-3">
        <h2 className="d-flex justify-content-center align-items-center">{t('profile.aboutus_text14')}</h2>
        <p className="pt-3" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text15')}</p>
        <p className="pt-3" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text16')}</p>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text17')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text18')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text19')}</p>
        </div>
      </div>
      <div className="col-12 col-lg-12 row mt-5 mb-5 justify-content-around">
        <div className="col-12 col-lg-3 col-sm-4" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10 }}>
          <img
            src={"/images/vehicles/u53.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "80%", padding: "20px" }}
          />
          <h6 className="d-flex justify-content-center pb-2">{t('form.Traveller')}</h6>
        </div>
        <div className="col-12 col-lg-3 col-sm-4" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10 }}>
          <img
            src={"/images/vehicles/u55.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "80%", padding: "20px" }}
          />
          <h6 className="d-flex justify-content-center pb-2">{t('profile.Luggage_Senders')}</h6>
        </div>
        <div className="col-12 col-lg-3 col-sm-4" style={{ border: "1px solid gray", alignItems: "center", justifyContent: 'center', borderRadius: 10 }}>
          <img
            src={"/images/vehicles/u57.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "100%", height: "80%", padding: "20px" }}
          />
          <h6 className="d-flex justify-content-center pb-2">{t('profile.Recipient')}</h6>
        </div>
      </div>
      <div className="col-12 row">
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text20')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text21')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text22')}</p>
        </div>
      </div>
      <div className="col-12 row mt-5">
        <h2>{t('profile.aboutus_text23')}</h2>
        <h6 className="mt-2" style={{ fontSize: 20 }}>{t('profile.aboutus_text24')}</h6>
      </div>
      <div className="col-12 align-items-center">
        <h2 className="d-flex justify-content-center">{t('profile.aboutus_text25')}</h2>
        <div className="d-flex justify-content-center mt-3">
          <img
            src={"/images/vehicles/normal_u67.png"}
            style={{ aspectRatio: "4/2", objectFit: "contain", width: "60%", }}
          />
        </div>
      </div>
      <div className="col-12 row mt-5 mb-3">
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text26')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text27')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text28')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text29')}</p>
        </div>
        <div className="col-1 d-flex justify-content-center align-items-center">
          <i class="bi bi-chevron-double-right" style={{ fontSize: "25px" }}></i>
        </div>
        <div className="col-11">
          <p className="" style={{ fontSize: "17px", fontWeight: "500" }}>{t('profile.aboutus_text30')}</p>
        </div>
      </div>
    </div>
  );
}

export default AboutWrapperArea;
