import React from "react";
import TermsConditionsWrapperArea from "./TermsConditionsWrapperArea";
import Breadcrumb from "../../common/Breadcrumb";

function TermsConditions() {
  return (
    <>
      <Breadcrumb name="Terms&Conditions" />
      <TermsConditionsWrapperArea/>
     
    </>
  );
}

export default TermsConditions;
